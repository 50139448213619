import { Link } from '../Link'
import { routes } from '../../router'
import { ReactComponent as MenuIcon } from 'assets/svg/MenuPortalIcon.svg'
import { ReactComponent as LogoPortalNewIcon } from 'assets/svg/LogoPortalNew.svg'
import { ReactComponent as CloseIcon } from 'assets/svg/Close24.svg'
import './header.scss'
import { Drawer } from 'components/Drawer'
import useDrawer from 'hooks/useDrawer'
import Aside from './Aside'
import { useEffect, FC } from 'react'
import useRouter from '../../hooks/useRouter'
import cn from 'classnames'
import { HeaderProps } from './types'

const Header: FC<HeaderProps> = ({ isMobile }) => {
  const { pathname } = useRouter()
  const menuDrawerProps = useDrawer()
  const { visible } = menuDrawerProps.state
  const open = () => {
    menuDrawerProps.actions.open()
  }
  const close = () => {
    menuDrawerProps.actions.close()
  }
  useEffect(() => {
    menuDrawerProps.actions.close()
  }, [pathname])

  useEffect(() => {
    if (visible) {
      document.body.style.position = 'fixed'
      document.body.style.top = `-${window.scrollY}px`
    } else {
      document.body.style.position = ''
      document.body.style.top = ''
    }
  }, [visible])
  return (
    <>
      <div
        className={cn('container-portal_content-container__header', {
          open: menuDrawerProps?.state?.visible
        })}
      >
        <Link
          to={routes.portal}
          children={
            <LogoPortalNewIcon className="container-portal_content-container__header__logo" />
          }
        />
        {menuDrawerProps?.state?.visible ? (
          <CloseIcon
            onClick={close}
            className="container-portal_content-container__header__logo__close"
          />
        ) : (
          <MenuIcon onClick={open} />
        )}
      </div>
      <Drawer
        {...menuDrawerProps.state}
        placement="right"
        className="mobile-menu-portal-drawer"
        closeIcon={null}
        getContainer={false}
        push={{ distance: 0 }}
      >
        <Aside isMobile={isMobile} />
      </Drawer>
    </>
  )
}

export default Header
