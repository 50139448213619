import moment from 'moment/moment'
import { Tooltip } from '../../../../../components/Tooltip/Tooltip'
import { DateCell } from '../../../../../components/Table/Cells/DateCell/DateCell'
import { TextCell } from '../../../../../components/Table/Cells/TextCell/TextCell'
import { NumberCell } from '../../../../../components/Table/Cells/NumberCell/NumberCell'
import { getTooltipErrorMessage } from './utils'
import { ReactComponent as WarningRed16 } from 'assets/svg/WarningRed16.svg'
import { timelineDateFormat } from '../../../../../components/Timelines/constants'
import { ColumnsType } from 'antd/es/table'
import { RejectedInvoiceLine } from '../../types'
import { ChangeLineDataFunc } from './types'

const setCellClassName = (
  row: RejectedInvoiceLine,
  field: keyof RejectedInvoiceLine
) => ({
  className: !!row.errors[field]?.length ? 'cell-with-error' : ''
})

export const columns = (
  onChangeLineData: ChangeLineDataFunc
): ColumnsType<RejectedInvoiceLine> => [
  {
    dataIndex: 'errors',
    key: 'errors',
    width: 40,
    title: '',
    render: (data) => {
      const message = getTooltipErrorMessage(data)
      return (
        message && (
          <Tooltip title={message}>
            <WarningRed16 />
          </Tooltip>
        )
      )
    },
    fixed: true,
    sorter: false,
    showSorterTooltip: false
  },
  {
    dataIndex: 'health_system',
    key: 'health_system',
    width: 288,
    title: 'Health system name',
    render: (_, { uuid, health_system, errors }) => (
      <TextCell
        value={health_system}
        onChange={(val) => onChangeLineData(uuid, 'health_system', val)}
        error={!!errors.health_system}
      />
    ),
    onCell: (record) => setCellClassName(record, 'health_system'),
    sorter: false,
    showSorterTooltip: false
  },
  {
    dataIndex: 'ship_to_entity_hospital',
    key: 'ship_to_entity_hospital',
    width: 288,
    title: 'Ship To Entity (Hospital)',
    render: (_, { uuid, ship_to_entity_hospital, errors }) => (
      <TextCell
        value={ship_to_entity_hospital}
        onChange={(val) =>
          onChangeLineData(uuid, 'ship_to_entity_hospital', val)
        }
        error={!!errors.ship_to_entity_hospital}
      />
    ),
    onCell: (record) => setCellClassName(record, 'ship_to_entity_hospital'),
    sorter: false,
    showSorterTooltip: false
  },
  {
    dataIndex: 'hospital_gln',
    key: 'hospital_gln',
    width: 288,
    title: 'Hospital (GLN)',
    sorter: false,
    showSorterTooltip: false
  },
  {
    dataIndex: 'contract_number',
    key: 'contract_number',
    width: 140,
    title: 'Contract number',
    sorter: false,
    showSorterTooltip: false
  },
  {
    dataIndex: 'category',
    key: 'category',
    width: 288,
    title: 'Category',
    sorter: false,
    showSorterTooltip: false
  },
  {
    dataIndex: 'unspsc_code',
    key: 'unspsc_code',
    width: 128,
    title: 'UNSPSC Code',
    sorter: false,
    showSorterTooltip: false
  },
  {
    dataIndex: 'unspsc_segment_name',
    key: 'unspsc_segment_name',
    width: 240,
    title: 'UNSPSC segment name',
    sorter: false,
    showSorterTooltip: false
  },
  {
    dataIndex: 'unspsc_family_name',
    key: 'unspsc_family_name',
    width: 160,
    title: 'UNSPSC family name',
    sorter: false,
    showSorterTooltip: false
  },
  {
    dataIndex: 'unspsc_class_name',
    key: 'unspsc_class_name',
    width: 160,
    title: 'UNSPSC class name',
    sorter: false,
    showSorterTooltip: false
  },
  {
    dataIndex: 'unspsc_commodity_name',
    key: 'unspsc_commodity_name',
    width: 240,
    title: 'UNSPSC commodity name',
    sorter: false,
    showSorterTooltip: false
  },
  {
    dataIndex: 'document_key',
    key: 'document_key',
    width: 160,
    title: 'Document key',
    render: (_, { uuid, document_key, errors }) => (
      <TextCell
        value={document_key}
        onChange={(val) => onChangeLineData(uuid, 'document_key', val)}
        error={!!errors.document_key}
      />
    ),
    onCell: (record) => setCellClassName(record, 'document_key'),
    sorter: false,
    showSorterTooltip: false
  },
  {
    dataIndex: 'supplier_name',
    key: 'supplier_name',
    width: 240,
    title: 'Supplier',
    render: (_, { uuid, supplier_name, errors }) => (
      <TextCell
        value={supplier_name}
        onChange={(val) => onChangeLineData(uuid, 'supplier_name', val)}
        error={!!errors.supplier_name}
      />
    ),
    onCell: (record) => setCellClassName(record, 'supplier_name'),
    sorter: false,
    showSorterTooltip: false
  },
  {
    dataIndex: 'supplier_remit_to',
    key: 'supplier_remit_to',
    width: 240,
    title: 'Supplier Remit To',
    sorter: false,
    showSorterTooltip: false
  },
  {
    dataIndex: 'invoice_date',
    key: 'invoice_date',
    width: 140,
    title: 'Invoice Date',
    render: (_, { uuid, invoice_date, errors }) => (
      <DateCell
        value={invoice_date}
        onChange={(val) =>
          onChangeLineData(
            uuid,
            'invoice_date',
            moment(val).format(timelineDateFormat)
          )
        }
        error={!!errors.invoice_date}
      />
    ),
    onCell: (record) => setCellClassName(record, 'invoice_date'),
    sorter: false,
    showSorterTooltip: false
  },
  {
    dataIndex: 'due_date',
    key: 'due_date',
    width: 140,
    title: 'Due Date',
    render: (_, { due_date }) => moment(due_date).format(timelineDateFormat),
    sorter: false,
    showSorterTooltip: false
  },
  {
    dataIndex: 'supplier_invoice_number',
    key: 'supplier_invoice_number',
    width: 160,
    title: "Supplier's Invoice Number",
    render: (_, { uuid, supplier_invoice_number, errors }) => (
      <TextCell
        value={supplier_invoice_number}
        onChange={(val) =>
          onChangeLineData(uuid, 'supplier_invoice_number', val)
        }
        error={!!errors.supplier_invoice_number}
      />
    ),
    onCell: (record) => setCellClassName(record, 'supplier_invoice_number'),
    sorter: false,
    showSorterTooltip: false
  },
  {
    dataIndex: 'invoice_amount',
    key: 'invoice_amount',
    width: 140,
    title: 'Invoice Amount',
    render: (_, { uuid, invoice_amount, errors }) => (
      <NumberCell
        value={invoice_amount}
        onChange={(val) => onChangeLineData(uuid, 'invoice_amount', val)}
        error={!!errors.invoice_amount}
      />
    ),
    onCell: (record) => setCellClassName(record, 'invoice_amount'),
    sorter: false,
    showSorterTooltip: false
  },
  {
    dataIndex: 'purchase_order_number',
    key: 'purchase_order_number',
    width: 160,
    title: 'Purchase Order Number',
    sorter: false,
    showSorterTooltip: false
  },
  {
    dataIndex: 'purchase_order_release_number',
    key: 'purchase_order_release_number',
    width: 160,
    title: 'Purchase Order Release Number',
    sorter: false,
    showSorterTooltip: false
  },
  {
    dataIndex: 'company',
    key: 'company',
    width: 140,
    title: 'Company',
    sorter: false,
    showSorterTooltip: false
  },
  {
    dataIndex: 'company_description',
    key: 'company_description',
    width: 240,
    title: 'Company Description',
    sorter: false,
    showSorterTooltip: false
  },
  {
    dataIndex: 'cost_center',
    key: 'cost_center',
    width: 140,
    title: 'Cost Center',
    sorter: false,
    showSorterTooltip: false
  },
  {
    dataIndex: 'cost_center_description',
    key: 'cost_center_description',
    width: 240,
    title: 'Cost Center Description',
    sorter: false,
    showSorterTooltip: false
  },
  {
    dataIndex: 'spend_category',
    key: 'spend_category',
    width: 240,
    title: 'Spend Category',
    sorter: false,
    showSorterTooltip: false
  },
  {
    dataIndex: 'spend_category_description',
    key: 'spend_category_description',
    width: 240,
    title: 'Spend Category Description',
    sorter: false,
    showSorterTooltip: false
  },
  {
    dataIndex: 'item_number_hospital',
    key: 'item_number_hospital',
    width: 140,
    title: 'Item Number Hospital',
    sorter: false,
    showSorterTooltip: false
  },
  {
    dataIndex: 'item_description',
    key: 'item_description',
    width: 240,
    title: 'Item Description',
    render: (_, { uuid, item_description, errors }) => (
      <TextCell
        value={item_description}
        onChange={(val) => onChangeLineData(uuid, 'item_description', val)}
        error={!!errors.item_description}
      />
    ),
    onCell: (record) => setCellClassName(record, 'item_description'),
    sorter: false,
    showSorterTooltip: false
  },
  {
    dataIndex: 'supplier_item_number',
    key: 'supplier_item_number',
    width: 140,
    title: 'Supplier Item Number',
    render: (_, { uuid, supplier_item_number, errors }) => (
      <NumberCell
        value={supplier_item_number}
        onChange={(val) => onChangeLineData(uuid, 'supplier_item_number', val)}
        error={!!errors.supplier_item_number}
      />
    ),
    onCell: (record) => setCellClassName(record, 'supplier_item_number'),
    sorter: false,
    showSorterTooltip: false
  },
  {
    dataIndex: 'quantity',
    key: 'quantity',
    width: 140,
    title: 'Quantity',
    render: (_, { uuid, quantity, errors }) => (
      <NumberCell
        value={quantity}
        onChange={(val) => onChangeLineData(uuid, 'quantity', val)}
        error={!!errors.quantity}
      />
    ),
    onCell: (record) => setCellClassName(record, 'quantity'),
    sorter: false,
    showSorterTooltip: false
  },
  {
    dataIndex: 'unit_of_measure',
    key: 'unit_of_measure',
    width: 140,
    title: 'Unit of Measure',
    sorter: false,
    showSorterTooltip: false
  },
  {
    dataIndex: 'unit_price',
    key: 'unit_price',
    width: 140,
    title: 'Unit Price',
    render: (_, { uuid, unit_price, errors }) => (
      <NumberCell
        value={unit_price}
        onChange={(val) => onChangeLineData(uuid, 'unit_price', val)}
        error={!!errors.unit_price}
        isCurrency
      />
    ),
    onCell: (record) => setCellClassName(record, 'unit_price'),
    sorter: false,
    showSorterTooltip: false
  },
  {
    dataIndex: 'extended',
    key: 'extended',
    width: 160,
    title: 'Extended',
    render: (_, { uuid, extended, errors }) => (
      <NumberCell
        value={extended}
        onChange={(val) => onChangeLineData(uuid, 'extended', val)}
        error={!!errors.extended}
        isCurrency
      />
    ),
    onCell: (record) => setCellClassName(record, 'extended'),
    sorter: false,
    showSorterTooltip: false
  },
  {
    dataIndex: 'freight',
    key: 'freight',
    width: 140,
    title: 'Freight',
    sorter: false,
    showSorterTooltip: false
  },
  {
    dataIndex: 'sales_tax',
    key: 'sales_tax',
    width: 140,
    title: 'Sales Tax',
    sorter: false,
    showSorterTooltip: false
  },
  {
    dataIndex: 'payment_terms',
    key: 'payment_terms',
    width: 140,
    title: 'Payment Terms',
    sorter: false,
    showSorterTooltip: false
  },
  {
    dataIndex: 'customer_number',
    key: 'customer_number',
    width: 140,
    title: 'Customer Number',
    sorter: false,
    showSorterTooltip: false
  },
  {
    dataIndex: 'payment_credit_applied',
    key: 'payment_credit_applied',
    width: 140,
    title: 'Payment/Credit Applied',
    sorter: false,
    showSorterTooltip: false
  },
  {
    dataIndex: 'accounting_date',
    key: 'accounting_date',
    width: 140,
    title: 'Accounting Date',
    render: (_, { uuid, accounting_date, errors }) => (
      <DateCell
        value={accounting_date}
        onChange={(val) =>
          onChangeLineData(
            uuid,
            'accounting_date',
            moment(val).format(timelineDateFormat)
          )
        }
        error={!!errors.accounting_date}
      />
    ),
    onCell: (record) => setCellClassName(record, 'accounting_date'),
    sorter: false,
    showSorterTooltip: false
  },
  {
    dataIndex: 'invoice_status',
    key: 'invoice_status',
    width: 140,
    title: 'Invoice Status',
    render: (_, { uuid, invoice_status, errors }) => (
      <TextCell
        value={invoice_status}
        onChange={(val) => onChangeLineData(uuid, 'invoice_status', val)}
        error={!!errors.invoice_status}
      />
    ),
    onCell: (record) => setCellClassName(record, 'invoice_status'),
    sorter: false,
    showSorterTooltip: false
  }
]
