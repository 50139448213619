import { TAttachmentProps } from './types'
import { Default } from './attachment.default'
import { Table } from './attachment.table'
import { Block } from './attachment.block'

export const Attachment = (props: TAttachmentProps) => <Default {...props} />

Attachment.Default = Default
Attachment.Table = Table
Attachment.Block = Block
