import { FC, useCallback } from 'react'
import { Table } from 'components/Table'
import { Tooltip } from 'components/Tooltip/Tooltip'
import Search from 'features/ContractDetails/HSList/HSTable/Search/Search'
import { isTableHasParams } from 'helper/common'
import { TOOLTIP } from 'constants/txt'
import { IContractHealthSystem } from 'features/ContractDetails/HSList/types'
import { Props } from './types'
import './styles.scss'

const HSTable: FC<Props> = (props) => {
  const { Actions, canSelectRows, height, columns, ...hsTableProps } = props

  const {
    tableData,
    searchValue,
    firstLoad,
    totalNumber,
    pageInfo,
    selectedRowKeys,
    debouncedSearch,
    isContractSigned,
    handleTableChange,
    handleChangePageSize,
    handleSelectRow,
    setSearchValue
  } = hsTableProps

  const renderCell = useCallback(
    (checked, _record, _2, node) =>
      isContractSigned && !checked && selectedRowKeys.length > 0 ? (
        <Tooltip
          title={TOOLTIP.ONLY_ONE_HEALTH_SYSTEM_CAN_BE_SELECTED}
          overlayInnerStyle={{
            display: !checked ? 'block' : 'none',
            width: 254
          }}
          placement="topLeft"
        >
          {node}
        </Tooltip>
      ) : (
        node
      ),
    [isContractSigned, selectedRowKeys.length]
  )

  const getCheckboxProps = useCallback(
    (record) => ({
      disabled:
        isContractSigned &&
        !selectedRowKeys.find((i) => i === record.uuid) &&
        selectedRowKeys.length > 0
    }),
    [isContractSigned, selectedRowKeys]
  )

  return (
    <div className="table-wrapper hs-list">
      <div className="table-wrapper__header">
        <Search value={searchValue} onChange={setSearchValue} />
        <div className="table-wrapper__header-buttons">{Actions}</div>
      </div>
      {!firstLoad && (
        <Table<IContractHealthSystem>
          scroll={{ scrollToFirstRowOnChange: true, x: 'max-content' }}
          dataSource={tableData}
          className="contacts-hs-list-table"
          columns={columns()}
          onChange={handleTableChange}
          rowSelection={
            canSelectRows
              ? {
                  selectedRowKeys,
                  renderCell,
                  getCheckboxProps,
                  onChange: handleSelectRow,
                  hideSelectAll: true
                }
              : undefined
          }
          hasSearchOrFilters={isTableHasParams(debouncedSearch, false)}
          onChangePage={handleChangePageSize}
          pageSize={pageInfo.pageSize}
          pagination={{
            pageSize: pageInfo.pageSize,
            current: pageInfo.pageNumber,
            total: totalNumber
          }}
        />
      )}
    </div>
  )
}

export default HSTable
