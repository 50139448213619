import { ColumnsType } from 'antd/es/table'
import { SortOrder } from 'antd/es/table/interface'
import { Status, USER_STATUS_TYPES_TO_CLASSNAME } from 'components/Status'
import { Link } from 'components/Link'
import { Dropdown } from 'components/Dropdown'
import { CellWithSubtitle } from 'components/CellWithSubtitle/CellWithSubtitle'
import { Sorter } from 'components/Sorter/Sorter'
import { fullFormatPhoneNumber } from 'helper/common'
import { fetchHealthSystemsList } from 'features/HealthSystems/api'
import {
  USER_STATUSES_OPTIONS,
  COMMUNITY_ROLES_OPTIONS,
  ROLES_NAMES
} from 'constants/common'
import { SETTINGS_TABS } from 'pages/Settings/constants'
import { routes } from 'router'
import { ReactComponent as Filter16 } from 'assets/svg/Filter16.svg'
import { ReactComponent as FilterApplied16 } from 'assets/svg/FilterApplied16.svg'
import {
  TCommunityUsersList,
  TCommunityColumnsFunc,
  TCommunityUsersFilters,
  TCommunityUsersFiltersFunc
} from './types'

export const columns = ({
  applyFilters,
  filters,
  community
}: TCommunityColumnsFunc): ColumnsType<TCommunityUsersList> => [
  {
    dataIndex: 'first_name',
    key: 'first_name',
    width: 200,
    title: ({ sortOrder, sortColumn }) => (
      <Sorter<TCommunityUsersList>
        dataIndex="first_name"
        sortColumn={sortColumn}
        sortOrder={sortOrder as SortOrder}
      >
        User name
      </Sorter>
    ),
    render: (_, { first_name, last_name, uuid }) => (
      <CellWithSubtitle
        title={
          <Link
            to={`${routes.createUserProfile}/${uuid}`}
            state={{ redirectTab: SETTINGS_TABS[2].key }}
          >
            {first_name} {last_name}
          </Link>
        }
      />
    ),
    sorter: true,
    showSorterTooltip: false
  },
  {
    dataIndex: 'role',
    key: 'role',
    width: 180,
    title: ({ sortOrder, sortColumn }) => (
      <Sorter<TCommunityUsersList>
        dataIndex="role"
        sortColumn={sortColumn}
        sortOrder={sortOrder as SortOrder}
      >
        Role
      </Sorter>
    ),
    filterDropdown: (props) => (
      <Dropdown.Filter<
        TCommunityUsersFilters,
        TCommunityUsersFiltersFunc,
        unknown
      >
        items={COMMUNITY_ROLES_OPTIONS}
        applyFilters={applyFilters}
        appliedFilters={filters}
        field="roles"
        {...props}
      />
    ),
    filterIcon: !!filters?.roles?.length ? <FilterApplied16 /> : <Filter16 />,
    render: (text) => ROLES_NAMES[text],
    filterSearch: true,
    sorter: true,
    showSorterTooltip: false
  },
  {
    dataIndex: 'status',
    key: 'status',
    width: 130,
    title: ({ sortOrder, sortColumn }) => (
      <Sorter<TCommunityUsersList>
        dataIndex="status"
        sortColumn={sortColumn}
        sortOrder={sortOrder as SortOrder}
      >
        Status
      </Sorter>
    ),
    filterDropdown: (props) => (
      <Dropdown.Filter<
        TCommunityUsersFilters,
        TCommunityUsersFiltersFunc,
        unknown
      >
        items={USER_STATUSES_OPTIONS}
        applyFilters={applyFilters}
        appliedFilters={filters}
        field="statuses"
        {...props}
      />
    ),
    render: (_, { status }) => (
      <Status.Default className={USER_STATUS_TYPES_TO_CLASSNAME[status]}>
        {status}
      </Status.Default>
    ),
    filterIcon: !!filters?.statuses?.length ? (
      <FilterApplied16 />
    ) : (
      <Filter16 />
    ),
    filterSearch: true,
    sorter: true,
    showSorterTooltip: false
  },
  {
    dataIndex: 'email',
    key: 'email',
    width: 220,
    title: ({ sortOrder, sortColumn }) => (
      <Sorter<TCommunityUsersList>
        dataIndex="email"
        sortColumn={sortColumn}
        sortOrder={sortOrder as SortOrder}
      >
        Email
      </Sorter>
    ),
    sorter: true,
    showSorterTooltip: false
  },
  {
    dataIndex: 'phone_number',
    key: 'phone_number',
    width: 160,
    title: ({ sortOrder, sortColumn }) => (
      <Sorter<TCommunityUsersList>
        dataIndex="phone_number"
        sortColumn={sortColumn}
        sortOrder={sortOrder as SortOrder}
      >
        Phone number
      </Sorter>
    ),
    render: (_, { phone_number }) => fullFormatPhoneNumber(phone_number),
    sorter: true,
    showSorterTooltip: false
  },
  {
    dataIndex: 'health_system',
    key: 'health_system',
    width: 250,
    title: ({ sortOrder, sortColumn }) => (
      <Sorter<TCommunityUsersList>
        dataIndex="health_system"
        sortColumn={sortColumn}
        sortOrder={sortOrder as SortOrder}
      >
        Health system
      </Sorter>
    ),
    filterDropdown: (props) => (
      <Dropdown.Filter<
        TCommunityUsersFilters,
        TCommunityUsersFiltersFunc,
        unknown
      >
        applyFilters={applyFilters}
        appliedFilters={filters}
        searchCallback={() => fetchHealthSystemsList(community)}
        field="health_systems"
        asyncSearch
        {...props}
      />
    ),
    filterIcon: !!filters?.health_systems?.length ? (
      <FilterApplied16 />
    ) : (
      <Filter16 />
    ),
    filterSearch: true,
    sorter: true,
    showSorterTooltip: false
  }
]
