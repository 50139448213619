import { FC } from 'react'

import { BTN_TXT } from 'constants/txt'
import { Button, BUTTON_TYPES } from 'components/Button'

type Props = {
  onClick: VoidFunction
}

const OpenSurvey: FC<Props> = (props) => {
  const { onClick } = props

  return (
    <Button onClick={onClick} upperCase type={BUTTON_TYPES.DEFAULT}>
      {BTN_TXT.OPEN_SURVEY}
    </Button>
  )
}

export default OpenSurvey
