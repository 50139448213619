import Fetcher from '../../services/Fetcher'
import { API_DICTIONARY, HTTP_METHODS } from '../../constants'

const fetcher = new Fetcher({})

export const getShipToHospitalOptions = async () =>
  fetcher.request<unknown, unknown>({
    url: `${API_DICTIONARY.FINANCES}${API_DICTIONARY.INVOICES}${API_DICTIONARY.OPTIONS}${API_DICTIONARY.SHIP_TO_ENTITY_HOSPITAL}`,
    method: HTTP_METHODS.GET
  })
