import cn from 'classnames'
import { TPortalProps } from './types'
import './portal.scss'
import Header from './Header'
import Aside from './Aside'
import { useEffect, useState } from 'react'

const Portal = ({ children, className }: TPortalProps) => {
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 991)

  useEffect(() => {
    window.addEventListener('resize', checkIsMobile)
    return () => window.removeEventListener('resize', checkIsMobile)
  }, [])

  const checkIsMobile = (e) => {
    setIsMobile(e.target.innerWidth <= 991)
  }

  return (
    <div className={cn('container-portal', className)}>
      <div className="container-portal_content-container">
        {isMobile && <Header isMobile={isMobile} />}
        {!isMobile && <Aside isMobile={isMobile} />}
        <main className="container-portal__main">{children}</main>
      </div>
    </div>
  )
}

//here can be added other versions like Portal.Chat

export default Portal
