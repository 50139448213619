import './style.scss'
import SpendByMonth from './Charts/SpendByMonth'
import Typography from '../../components/Typography/Typography'
import { Datepicker } from '../../components/Datepicker'
import { requestMonthFormat } from '../../utils/moment'
import { TYPOGRAPHY_WEIGHT } from '../../components/Typography'
import { ReactComponent as ArrowRight16 } from 'assets/svg/ArrowRight16.svg'
import { ReactComponent as ArrowLeft16 } from 'assets/svg/ArrowBack16.svg'
import OverallConversionCompliance from './Charts/OverallConversionCompliance/OverallConversionCompliance'
import SpendByVendor from './Charts/SpendByVendor/SpendByVendor'
import SpendByCategory from './Charts/SpendByCategory/SpendByCategory'
import SpendByFacility from './Charts/SpenByFacility/SpendByFacility'
import SpendByProduct from './Charts/SpendByProduct/SpendByProduct'
import { useComplianceContext } from '../../pages/Compliance/Providers/ComplianceContextProvider'
import { currencyFormatter } from '../ContractDetails/ContractDetailsGeneral/ContractDetailsForm/utils'
import { safeNumber } from '../../helper/common'
import HeaderFilters from './HeaderFilters'
import { MyActivity } from './Charts/MyActivity/MyActivity'
import { Moment } from 'moment/moment'
import moment from 'moment'

export const Compliance = () => {
  const { state, actions } = useComplianceContext()
  const { date, totalSpend, showMyActivityChart, dataPerMonth } = state
  const { setDate } = actions

  const disabledDate = (current: Moment) => {
    if (!dataPerMonth?.length) {
      return false
    }
    const minDate = moment(dataPerMonth[0].month, 'MMM YYYY').startOf('month')
    const maxDate = moment(
      dataPerMonth[dataPerMonth.length - 1].month,
      'MMM YYYY'
    ).endOf('month')

    return current && (current < minDate || current > maxDate)
  }

  return (
    <div className="compliance-container">
      <HeaderFilters />
      <SpendByMonth />
      <div
        className="row space-between mt-24 align-center"
        data-testid="totalSpendLabel"
      >
        <Typography.HeadlineD4 weight={TYPOGRAPHY_WEIGHT.BOLD}>
          Total spend: ${currencyFormatter(safeNumber(totalSpend).toFixed(2))}
        </Typography.HeadlineD4>
        <div>
          <Typography.Label weight="semi-bold">Period</Typography.Label>
          <Datepicker
            className="period__date-picker"
            propsDate={{
              format: requestMonthFormat,
              value: date,
              onChange: (value) => setDate(value),
              picker: 'month',
              superNextIcon: <ArrowRight16 />,
              superPrevIcon: <ArrowLeft16 />,
              disabledDate
            }}
          />
        </div>
      </div>
      <div className="row space-between mt-24 gap-24">
        <OverallConversionCompliance />
        <SpendByCategory />
      </div>
      <div className="row space-between mt-24">
        <SpendByVendor />
        <SpendByFacility />
      </div>
      <div className="row space-between mt-24 gap-24">
        <SpendByProduct />
        {showMyActivityChart && <MyActivity />}
      </div>
    </div>
  )
}
