import { useMemo } from 'react'

import RFPVotingContent from 'features/RFP/RFPVoting/RFPVotingContent/RFPVotingContent'
import RFPVotingHeader from 'features/RFP/RFPVoting/RFPVotingHeader/RFPVotingHeader'
import PriceFileContent from 'features/RFP/RFPVoting/PriceFileContent/PriceFileContent'
import SurveyContent from 'features/RFP/RFPVoting/SurveyContent/SurveyContent'
import { useRFPVotingContext } from 'features/RFP/RFPDetails/Providers/RFPVotingContextProvider'

import './styles.scss'
import { useRFPDetailsContext } from 'features/RFP/RFPDetails/Providers/RFPDetailsContextProvider'

const RFPVoting = () => {
  const rfpDetailsContext = useRFPDetailsContext()
  const rfpVotingContext = useRFPVotingContext()

  const { isCurrentUserStakeholder } = rfpDetailsContext.state
  const { isVotingInProgress } = rfpVotingContext.state

  const CommunitySide = useMemo(
    () => (
      <>
        <RFPVotingHeader />
        <RFPVotingContent />
      </>
    ),
    []
  )
  const StakeholderSide = useMemo(
    () =>
      isVotingInProgress ? (
        <>
          <PriceFileContent />
          <SurveyContent />
        </>
      ) : null,
    [isVotingInProgress]
  )

  return (
    <div className="rfp-voting-container">
      {isCurrentUserStakeholder ? StakeholderSide : CommunitySide}
    </div>
  )
}

export default RFPVoting
