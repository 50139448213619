import { ModalProps } from './types'
import { Modal } from 'antd'
import { ReactComponent as Close24 } from '../../assets/svg/Close24.svg'
import cn from 'classnames'
import './popup.default.scss'
import { Button, BUTTON_TYPES } from '../Button'
import { BTN_TXT } from '../../constants'
import { Typography } from '../Typography'

export const Discard = ({
  closable = true,
  onCancel,
  onSubmit,
  children,
  editMode = true,
  ...props
}: ModalProps & {
  editMode?: boolean
}) => (
  <Modal
    centered
    closeIcon={<Close24 />}
    closable={closable}
    title="Discard changes?"
    getContainer="#root"
    onCancel={onCancel}
    width={442}
    destroyOnClose
    className={cn('popup-default', {
      'popup-default--closable': closable
    })}
    wrapClassName={cn('popup-default-wrap', 'popup-over')}
    footer={
      <>
        <Button type={BUTTON_TYPES.DEFAULT} upperCase onClick={onCancel}>
          {editMode ? BTN_TXT.BACK_TO_EDIT : BTN_TXT.BACK}
        </Button>
        <Button type={BUTTON_TYPES.PRIMARY} upperCase onClick={onSubmit}>
          {BTN_TXT.DISCARD_CHANGES}
        </Button>
      </>
    }
    {...props}
  >
    {children ? (
      children
    ) : (
      <Typography.Body1>
        Do you want to exit without saving data?
      </Typography.Body1>
    )}
  </Modal>
)
