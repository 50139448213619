export const itemsActions = [
  {
    key: 'terminate',
    label: 'Terminate evaluation'
  },
  {
    key: 'review_details',
    label: 'Clinical review details'
  },
  {
    key: 'accept',
    label: 'Accept contract category'
  }
]

export const emptyStateClinicalResults = {
  accepted: [],
  declined: [],
  not_reviewed: []
}
