import { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Form } from 'antd'
import cn from 'classnames'
import { Select } from 'components/Select'
import { Input } from 'components/Input'
import { LABELS, PLACEHOLDERS } from 'constants/txt'
import {
  addHospital,
  editHospital
} from 'features/HealthSystemDetails/HospitalsTable/api'
import { getUser } from 'redux/store/user/getters'
import { fetchDepartments } from 'pages/CRUDUser/api'
import { setLoading } from 'redux/store/common/slice'
import { formatOptionNameValue } from 'helper/optionsFormatters'
import {
  formatPhoneNumber,
  // parserPhoneNumber,
  validateMessages
} from 'helper/common'
import { TAddEditHospitalData } from 'features/HealthSystemDetails/types'
import {
  TAddEditHospitalForm,
  TAddEditHospitalProps,
  TAddEditHospitalErrors,
  TAddEditHospitalFormFields
} from './forms.addEditHospital.d'
import { TOption } from 'components/Select/types'
import { hospitalInitialValues } from './constants'

export const AddEditHospital = ({
  className,
  form,
  callback,
  hsId,
  data,
  onChange
}: TAddEditHospitalProps) => {
  const [departmentsList, setDepartmentsList] = useState<TOption[]>([])
  const [errors, setErrors] = useState<TAddEditHospitalErrors>({})
  const [fields, setFields] = useState<TAddEditHospitalFormFields>([])
  const [phoneNumber, setPhoneNumber] = useState('')
  const user = useSelector(getUser)
  const dispatch = useDispatch()

  useEffect(() => {
    if (!phoneNumber) {
      setPhoneNumber(fields.find((i) => i.name === 'phone_number')?.value)
    }
  }, [fields])

  useEffect(() => {
    if (user.community && hsId) {
      fetchDepartments(user.community, hsId)
        .then((resp) => {
          if (!resp.data?.results) {
            return
          }
          setDepartmentsList(resp.data.results.map(formatOptionNameValue))
        })
        .finally(() => dispatch(setLoading(false)))
    }
  }, [user.community, hsId])
  useEffect(() => {
    if (data?.uuid) {
      const formattedData = {}
      for (const key in data) {
        if (typeof data[key] === 'object' && key === 'address') {
          for (const item in data[key]) {
            formattedData[item] = data[key][item]
          }
        } else if (key === 'departments') {
          formattedData[key] = data[key].map((i) => ({
            label: i.name,
            value: i.uuid
          }))
        } else {
          formattedData[key] = data[key]
        }
      }
      const fields = Object.keys(formattedData).map((i) => ({
        name: i,
        value: formattedData[i]
      }))
      setFields(fields)
    }
  }, [data?.uuid])
  const handleAddEditHospital = (formData: TAddEditHospitalForm) => {
    dispatch(setLoading(true))
    const number = !formData?.phone_number?.toString()?.includes('+1')
      ? '+1' + formData.phone_number
      : formData.phone_number
    const hospitalData: TAddEditHospitalData = {
      name: formData.name,
      email: formData.email ? formData.email : undefined,
      tax_id: formData.tax_id ? formData.tax_id : undefined,
      phone_number: formData.phone_number ? number : '',
      departments: formData.departments
        ? typeof formData.departments[0] === 'object'
          ? formData.departments.map((i) => i.value)
          : formData.departments
        : undefined,
      address: {
        state: formData.state,
        street: formData.street,
        zip_code: formData.zip_code,
        city: formData.city
      }
    }
    if (data?.uuid) {
      editHospital(user.uuid, hsId, hospitalData, data.uuid)
        .then(() => {
          if (callback) {
            callback()
          }
        })
        .catch((e) => {
          setErrors(e.data)
          dispatch(setLoading(false))
        })
    } else {
      addHospital(user.uuid, hsId, hospitalData)
        .then(() => {
          if (callback) {
            callback()
          }
        })
        .catch((e) => {
          setErrors(e.data)
          dispatch(setLoading(false))
        })
    }
  }

  return (
    <Form<TAddEditHospitalForm>
      form={form}
      onFinish={handleAddEditHospital}
      fields={fields}
      initialValues={hospitalInitialValues}
      validateMessages={validateMessages}
      onValuesChange={(_, changedValues) => {
        phoneNumber !== changedValues?.phone_number &&
          setPhoneNumber(changedValues.phone_number.toString())
        onChange(changedValues)
      }}
      id="add-edit-hospital-form"
      className={cn('add-hospital-form', className)}
    >
      <div className="row two-columns">
        <Input
          propsItem={{
            name: 'name',
            label: LABELS.HOSPITAL_NAME,
            help: errors.name && errors.name[0],
            validateStatus: errors.name && 'error',
            rules: [
              {
                required: true
              }
            ]
          }}
          propsInput={{ maxLength: 100 }}
        />
        <Input
          propsItem={{
            name: 'tax_id',
            label: LABELS.TAX_ID,
            help: errors.tax_id && errors.tax_id[0],
            validateStatus: errors.tax_id && 'error'
          }}
        />
      </div>
      <div className="row two-columns">
        <Input
          propsInput={{ placeholder: PLACEHOLDERS.EMAIL }}
          propsItem={{
            name: 'email',
            label: LABELS.EMAIL,
            help: errors.email && errors.email[0],
            validateStatus: errors.email && 'error'
          }}
        />
        <Input.Phone
          propsInputNumber={{
            controls: false,
            formatter: formatPhoneNumber,
            prefix: phoneNumber ? '+1' : ''
            // parser: parserPhoneNumber
          }}
          propsItem={{
            name: 'phone_number',
            label: LABELS.PHONE_NUMBER,
            rules: [
              {
                // required: true,
                pattern: /^[+, 0-9]{10,12}$/,
                validateTrigger:
                  phoneNumber?.length === 10 ? ['onSubmit'] : ['onBlur']
              }
            ]
          }}
        />
      </div>
      <div className="row two-columns">
        <Input
          propsItem={{
            name: 'street',
            label: LABELS.STREET,
            rules: [
              {
                required: true
              }
            ]
          }}
        />
        <Input
          propsItem={{
            name: 'city',
            label: LABELS.CITY,
            rules: [
              {
                required: true
              }
            ]
          }}
        />
      </div>
      <div className="row two-columns">
        <Input
          propsItem={{
            name: 'state',
            label: LABELS.STATE,
            rules: [
              {
                required: true
              }
            ]
          }}
        />
        <Input
          className="column-half"
          propsItem={{
            name: 'zip_code',
            label: LABELS.ZIP_CODE,
            help: errors.address?.zip_code && errors.address?.zip_code[0],
            validateStatus: errors.address?.zip_code && 'error',
            rules: [
              {
                required: true
              }
            ]
          }}
        />
      </div>
      <div className="row">
        <Select.Multi
          options={departmentsList}
          propsItem={{
            label: LABELS.DEPARTMENTS,
            name: 'departments'
          }}
          propsSelect={{
            placeholder: PLACEHOLDERS.PLEASE_SELECT
          }}
        />
      </div>
    </Form>
  )
}
