import { ROLES_NAMES } from 'constants/common'
import Typography from 'components/Typography/Typography'
import { TYPOGRAPHY_WEIGHT } from 'components/Typography'
import { IPrivateChatDetails } from 'redux/store/chatDetails/types'
import ChatDetails from 'features/ChatDetails/ChatDetails'
import { useChatContext } from 'features/Chat/Providers/ChatContextProvider'
import { getNameInitials, safeString } from 'helper/common'

export const PrivateChatDetails = () => {
  const chatContext = useChatContext<IPrivateChatDetails>()

  const { chat } = chatContext.state

  return (
    <ChatDetails
      name={chat?.full_name ? getNameInitials(chat?.full_name) : 'Pc'}
      src={safeString(chat?.avatar?.file)}
      chatName={chat?.full_name}
    >
      {chat?.role && (
        <Typography.Label
          weight={TYPOGRAPHY_WEIGHT.SEMI_BOLD}
          className="chat-details-container__role"
        >
          {ROLES_NAMES[chat.role]}
        </Typography.Label>
      )}
      {chat?.organization_name && (
        <Typography.Label
          weight={TYPOGRAPHY_WEIGHT.SEMI_BOLD}
          className="chat-details-container__organization-name"
        >
          {chat.organization_name}
        </Typography.Label>
      )}
    </ChatDetails>
  )
}
