import { useDispatch } from 'react-redux'
import { Form } from 'antd'
import { useCallback, useEffect, useMemo, useState } from 'react'
import {
  TActualSavingPeriodItem,
  TActualSavingsModalList
} from '../ActualSavingsModal/types'
import { useDashboardPopup } from './DashboardPopupProvider'
import { setLoading } from '../../../redux/store/common/slice'
import { getActualSavingsList, setActualSavingsList } from '../api'
import { TUseEditSavingsGoalsModalProps } from './types'
import { notification } from '../../../components/Notification'
import { VALIDATION_MESSAGES } from '../../../constants'
import moment from 'moment'

const useEditSavingsGoalsModal = ({
  healthSystem,
  getChartData
}: TUseEditSavingsGoalsModalProps) => {
  const dispatch = useDispatch()
  const [form] = Form.useForm()
  const [actualSavingsResults, setActualSavingResults] = useState<
    TActualSavingPeriodItem[]
  >([])
  const [isChanged, setIsChanged] = useState(false)

  const { editSavingsGoalsPopup } = useDashboardPopup()
  const { actions } = editSavingsGoalsPopup

  const actualSavings = Form.useWatch('actual_savings', form)
  const handleSavingsData = useCallback(async () => {
    dispatch(setLoading(true))
    if (!healthSystem) {
      throw new Error('Health System id not provided')
    }
    const result = await getActualSavingsList({
      params: { health_system: healthSystem }
    })
    if (result?.data?.results) {
      setActualSavingResults(result?.data?.results)
      form.setFieldsValue({ actual_savings: result?.data?.results })
    }
    dispatch(setLoading(false))
  }, [healthSystem])

  useEffect(() => {
    handleSavingsData()
    form.setFieldsValue({ actual_savings: [] })
  }, [])
  const onSetPeriods = useCallback(
    async (values: TActualSavingsModalList) => {
      dispatch(setLoading(true))
      if (!healthSystem) {
        throw new Error('Health System id not provided')
      }
      await setActualSavingsList({
        health_system: healthSystem,
        goals: values.actual_savings
      })
        .then(() => {
          notification.success({ message: VALIDATION_MESSAGES.SM00123 })
          setIsChanged(false)
          actions.close()
          getChartData()
        })
        .catch((error) => notification.error({ message: error?.details }))
        .finally(() => dispatch(setLoading(false)))
    },
    [healthSystem]
  )

  const isDisabled = useMemo(() => {
    return !!actualSavings?.find(
      (goal: TActualSavingPeriodItem) =>
        goal.year === moment().add(9, 'year').year()
    )
  }, [actualSavings])

  return {
    onSetPeriods,
    form,
    actualSavings,
    actualSavingsResults,
    isChanged,
    setIsChanged,
    isDisabled
  }
}

export default useEditSavingsGoalsModal
