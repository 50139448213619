import { useState, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { Form } from 'antd'
import cn from 'classnames'
import { Input } from 'components/Input'
import { Link } from 'components/Link'
import { Typography } from 'components/Typography'
import { Button, BUTTON_TYPES } from 'components/Button'
import { TFormContactUsForm, TFormContactUs } from './forms.contactUs.d'
import { BTN_TXT, LABELS, PLACEHOLDERS } from 'constants/txt'
import { setLoading } from 'redux/store/common/slice'
import { contact } from 'features/SignPopup/api'
import { contactUsInitialValues } from './constants'

export const ContactUs: TFormContactUs = ({
  className,
  setOpenHelpPopup,
  email
}): JSX.Element => {
  const [error, setError] = useState('')
  const dispatch = useDispatch()
  const [form] = Form.useForm()
  useEffect(() => {
    dispatch(setLoading(false))
    form.setFieldsValue({ email: email })
  }, [])

  const handleContact = (values) => {
    dispatch(setLoading(true))
    contact(values)
      .then(() => {
        form.resetFields()
        setOpenHelpPopup(false)
      })
      .catch((e) => setError(e.data.email[0]))
      .finally(() => dispatch(setLoading(false)))
  }

  return (
    <Form<TFormContactUsForm>
      className={cn('forms-contact-us', className)}
      layout="vertical"
      onFinish={handleContact}
      initialValues={contactUsInitialValues}
      form={form}
    >
      {/* prettier-ignore */}
      <Typography.Body1>
        Write your message below or contact us via <Link to="//mailto:info@cognushealth.com">info@cognushealth.com</Link>
      </Typography.Body1>
      <div className="row mt-24">
        <Input
          propsInput={{
            placeholder: PLACEHOLDERS.EMAIL
          }}
          propsItem={{
            name: 'email',
            label: LABELS.EMAIL,
            help: error,
            validateStatus: error && 'error'
          }}
        />
      </div>
      <div className="row mt-24">
        <Input.TextArea
          propsTextArea={{
            maxLength: 255,
            autoSize: {
              minRows: 12,
              maxRows: 12
            },
            showCount: true
          }}
          propsItem={{ label: LABELS.MESSAGE, name: 'message' }}
        />
      </div>
      <div className="row mt-32">
        <Form.Item noStyle shouldUpdate>
          {({ getFieldsValue }) => {
            const { email, message } = getFieldsValue()
            return (
              <Button
                type={BUTTON_TYPES.PRIMARY}
                disabled={!email || !message}
                htmlType="submit"
                upperCase
              >
                {BTN_TXT.SEND}
              </Button>
            )
          }}
        </Form.Item>
      </div>
    </Form>
  )
}
