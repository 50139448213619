import cn from 'classnames'
import moment from 'moment/moment'
import Typography from '../../../components/Typography/Typography'
import { TYPOGRAPHY_WEIGHT } from '../../../components/Typography'
import { EventContentArg } from 'fullcalendar'
import { CalendarMeetingDetailsPopover } from '../CalendarMeetingDetailsPopover/CalendarMeetingDetailsPopover'
import { TCalendarEventDetails } from '../../../pages/Calendar/types'
import { EventOpenedId } from '../../../components/Calendar/types'
import { useMemo, useRef } from 'react'
import { useSelector } from 'react-redux'
import { getUser } from '../../../redux/store/user/getters'

export const CalendarCell = ({
  currentEventInfo,
  openedEventInfo,
  setPopoverOpened,
  isDeletePopoverOpened,
  isEditPopoverOpened,
  openedPopupUniqueId
}: {
  currentEventInfo: EventContentArg
  openedEventInfo: TCalendarEventDetails | null
  setPopoverOpened: (v: EventOpenedId) => void
  isDeletePopoverOpened: boolean | undefined
  isEditPopoverOpened: boolean | undefined
  openedPopupUniqueId: string
}) => {
  const user = useSelector(getUser)
  const componentRef = useRef<HTMLDivElement>(null as any)

  const isCurrentEventOpened = useMemo(() => {
    const isCurrentOpened = Boolean(
      openedPopupUniqueId &&
        componentRef.current &&
        (componentRef.current?.parentElement?.parentElement?.id ===
          openedPopupUniqueId ||
          componentRef.current?.parentElement?.id === openedPopupUniqueId)
    )
    return (
      openedEventInfo?.uuid === currentEventInfo.event.id &&
      isCurrentOpened &&
      !isDeletePopoverOpened &&
      !isEditPopoverOpened
    )
  }, [
    openedEventInfo,
    currentEventInfo,
    isDeletePopoverOpened,
    isEditPopoverOpened,
    componentRef,
    openedPopupUniqueId
  ])

  return (
    <CalendarMeetingDetailsPopover
      isOpen={isCurrentEventOpened}
      setIsOpen={setPopoverOpened}
      event={openedEventInfo}
    >
      <div
        className={cn(
          'calendar-event__card row full-width',
          currentEventInfo.event.classNames.join(' ')
        )}
        ref={componentRef}
      >
        <div className="calendar-event__badge" />
        <div className={cn('calendar-event__context row full-width')}>
          {currentEventInfo.event?.startStr && (
            <Typography.Caption
              weight={TYPOGRAPHY_WEIGHT.NORMAL}
              className="calendar-time-title"
            >
              {moment(currentEventInfo.event?.startStr)
                .zone(user.timezone.offset)
                .format('h:mma')}
            </Typography.Caption>
          )}
          <Typography.Caption
            weight={TYPOGRAPHY_WEIGHT.BOLD}
            className="calendar-event-name text-ellipsis"
          >
            {currentEventInfo.event.title}
          </Typography.Caption>
        </div>
      </div>
    </CalendarMeetingDetailsPopover>
  )
}
