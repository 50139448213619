import { RadialBar } from '@ant-design/plots'
import {
  convertToFixed,
  currencyFormatter
} from 'features/ContractDetails/ContractDetailsGeneral/ContractDetailsForm/utils'
import { TActualPaymentsData } from './types'
import { useMemo } from 'react'

type TProps = {
  data: TActualPaymentsData
}
export const ActualPaymentsChart = ({ data }: TProps) => {
  const newData = useMemo(() => {
    const isNotEmpty = data.find((i) => !!i.percentage)
    let newData
    if (isNotEmpty) {
      newData = [
        ...data.slice().reverse(),
        {
          status: '',
          payments: 0,
          percentage: 1,
          expected_payments: 0
        }
      ]
    } else newData = data.slice().reverse()
    return newData
  }, [data])
  const config = {
    data: newData,
    appendPadding: 0,
    xField: 'status',
    yField: 'percentage',
    radius: 0.9,
    innerRadius: 0.4,
    colorField: 'status',
    xAxis: false,
    maxAngle: 360,
    color: data.find((i) => !!i.percentage && i.status)
      ? ['#73A8D3', '#FAD16B', '#62DA84', '#fff']
      : ['#F3F5F6', '#F3F5F6', '#F3F5F6', '#fff'],
    startAngle: Math.PI * 0.5,
    endAngle: Math.PI * 2.5,
    indicator: false,
    interactions: [],
    barStyle: {
      lineCap: 'round' as const
    },
    barBackground: {
      style: {
        fill: '#d8dee1'
      }
    },
    tooltip: {
      customContent: (_title, data) =>
        data.map(
          (i, idx) =>
            i.data.status && (
              <div className="chart__tooltip" key={idx}>
                {i.data.status} payments <br />
                Paid: $
                {currencyFormatter(convertToFixed(i.data.payments)) + ' '}(
                {convertToFixed(+i.data.percentage * 100)}%) <br />
                of $
                {currencyFormatter(convertToFixed(i.data.expected_payments))}
              </div>
            )
        )
    }
  }
  return <RadialBar {...config} />
}
