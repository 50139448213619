import Typography from '../../../../components/Typography/Typography'
import { TChartItem } from './types'
import { ReactElement } from 'react'

const ChartItem = ({ color, chartData }: TChartItem): ReactElement => {
  return chartData ? (
    <div
      style={{
        backgroundColor: color,
        height: '24px',
        width: `${8.98 * chartData}px`
      }}
      className={'clinical-review-results-item'}
    >
      {!!chartData && (
        <Typography.Caption className={'clinical-review-results-item-per'}>
          {chartData}%
        </Typography.Caption>
      )}
    </div>
  ) : (
    <></>
  )
}

export default ChartItem
