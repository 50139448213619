import { EmptyState } from '../CheckInsList/EmptyState'
import { useParams } from 'react-router-dom'
import Details from './Details'

export const CheckInsDetails = () => {
  const { id } = useParams()

  return (
    <div className="check-ins-container__details">
      {!id ? <EmptyState text="Select check-in to see details" /> : <Details />}
    </div>
  )
}
