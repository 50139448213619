import SingleType from './SingleType'
import MultiType from './MultiType'
import TextType from './TextType'
import Typography from '../../../../../components/Typography/Typography'
import './style.scss'
import cn from 'classnames'
import { useCallback } from 'react'
import {
  initialStateForSurveyCreation,
  QUESTION_TYPE
} from '../Survey/constants'
import { TTypesUnion } from './types'
import { Form, Space } from 'antd'
import { TQuestionsForm } from 'components/Forms/forms.surveyQuestions.d'
import { pageSize } from '../../General/constants'
import { FormInstance } from 'antd/lib/form/hooks/useForm'

export type TProps = {
  votingData: TQuestionsForm
  isError: boolean
  isAbleToVote: boolean
  vote?: boolean
  page?: number
  form: FormInstance<TQuestionsForm>
}

const Preview = ({
  votingData,
  isError,
  isAbleToVote,
  vote,
  page,
  form
}: TProps) => {
  const component = useCallback(
    (name: number) => {
      const type: TTypesUnion = votingData?.questions?.[name].type
      const question: string =
        votingData?.questions?.[name].uuid || votingData?.questions?.[name].text
      const options = votingData?.questions?.[name].options
      const selectedOptions =
        votingData?.questions?.[name].selectedOptions || []
      const has_other = options?.find((i) => !!i?.is_other)
      const newOptions = options?.map((i) => ({
        ...i,
        label: i?.text || 'Other',
        value: i?.uuid || i?.text || ''
      }))
      switch (type) {
        case QUESTION_TYPE.SINGLE:
          return (
            <SingleType
              options={newOptions}
              isOther={!!has_other}
              name={name}
              vote={vote}
              option={!!selectedOptions?.length ? selectedOptions[0] : null}
              question={question}
              form={form}
            />
          )
        case QUESTION_TYPE.MULTI:
          return (
            <MultiType
              question={question}
              options={newOptions}
              isOther={!!has_other}
              form={form}
              name={name}
              vote={vote}
              selectedOptions={selectedOptions}
            />
          )
        case QUESTION_TYPE.TEXT:
          return (
            <TextType
              question={question}
              name={name}
              vote={vote}
              option={options[0]?.uuid || null}
            />
          )
        default:
          return (
            <TextType
              question={question}
              name={name}
              option={options[0]?.uuid || null}
            />
          )
      }
    },
    [votingData]
  )

  return (
    <div
      className={cn('response-container column', { preview: !isAbleToVote })}
    >
      <Form.List
        name="questions"
        initialValue={initialStateForSurveyCreation.questions}
      >
        {(fields) => {
          return (
            <div className="">
              {fields.map(({ key, name }, _id) => {
                if (
                  vote &&
                  typeof page === 'number' &&
                  (pageSize[page].start > name || pageSize[page].end <= name)
                ) {
                  return
                }
                return (
                  <Space
                    key={key}
                    className={cn('response-container__item full-width mt-24', {
                      error:
                        isError &&
                        votingData?.questions?.[name]?.is_required &&
                        !votingData?.questions?.[name]?.selectedOptions?.length
                    })}
                    direction="vertical"
                  >
                    {votingData?.questions?.[name]?.type && (
                      <div>
                        <Typography.Body1
                          className={cn('response-container__item__question', {
                            isRequired:
                              votingData?.questions?.[name].is_required
                          })}
                        >
                          {votingData?.questions?.[name].text}
                        </Typography.Body1>
                        {component(name)}
                      </div>
                    )}
                  </Space>
                )
              })}
            </div>
          )
        }}
      </Form.List>
    </div>
  )
}

export default Preview
