import { Form, Input } from 'antd'
import cn from 'classnames'
import { Props } from './types'
import './input.textarea.scss'
import { ReactElement } from 'react'

const { TextArea: TextAreaAntd } = Input

export const TextArea = ({ className, ...props }: Props): ReactElement => (
  <Form.Item
    colon={false}
    className={cn('custom-input', 'input-textarea', className)}
    {...props.propsItem}
  >
    <TextAreaAntd {...props.propsTextArea} />
  </Form.Item>
)
