import Popup from '../../components/Popup/Popup'
import { Button, BUTTON_TYPES } from '../../components/Button'
import { BTN_TXT, VALIDATION_MESSAGES } from '../../constants'
import { Form } from 'antd'
import { Typography, TYPOGRAPHY_WEIGHT } from '../../components/Typography'
import Input from '../../components/Input/Input'
import { FINANCIAL_STATUS, initialPopupData } from './constants'
import { acceptDeclineReport, resendRebate } from './api'
import { notification } from '../../components/Notification'
import { TDeclineValue, TFinancialsPopup } from './types'

export const FinancialsPopup = ({
  setPopupOpen,
  selectedRows,
  setIsUpdate,
  isPopupOpen,
  contractId,
  onPayHandler,
  setSelectedRows,
  setSelectedRowKeys
}: TFinancialsPopup) => {
  const [declineForm] = Form.useForm()

  const decline_reason = Form.useWatch('decline_reason', declineForm)
  const onCancelHandler = () => {
    setPopupOpen(initialPopupData)
    setSelectedRowKeys([])
    setSelectedRows([])
    if (declineForm) declineForm.resetFields()
  }
  const onDeclineReportHandler = (value: TDeclineValue) => {
    acceptDeclineReport(selectedRows[0].uuid, {
      status: FINANCIAL_STATUS.DECLINED,
      ...value
    })
      .then(() => {
        declineForm.resetFields()
        setIsUpdate((prev) => !prev)
        notification.success({
          message: VALIDATION_MESSAGES.SM0062 + ' declined.'
        })
      })
      .finally(() => setPopupOpen({ declinePopup: false }))
  }

  const onAcceptReportHandler = () => {
    acceptDeclineReport(selectedRows[0].uuid, {
      status: FINANCIAL_STATUS.PENDING_FOR_PAYMENT,
      decline_reason: 'accept'
    })
      .then(() => {
        setIsUpdate((prev) => !prev)
        notification.success({
          message:
            VALIDATION_MESSAGES.SM0062 + ' accepted and pending for payment.'
        })
      })
      .finally(() => setPopupOpen({ acceptPopup: false }))
  }

  const onResendReportHandler = () => {
    resendRebate(contractId)
      .then(() => {
        notification.success({
          message: VALIDATION_MESSAGES.SM0066
        })
      })
      .catch((err: any) => notification.error({ message: err?.data[0] }))
      .finally(() => setPopupOpen({ resendPopup: false }))
  }
  const declinePopup = () => (
    <Popup
      visible={isPopupOpen.declinePopup}
      onCancel={onCancelHandler}
      title="Decline report?"
      width={442}
      footer={
        <>
          <Button
            onClick={onCancelHandler}
            type={BUTTON_TYPES.DEFAULT}
            upperCase
          >
            {BTN_TXT.CANCEL}
          </Button>
          <Button
            form="decline-contract-form"
            disabled={!decline_reason}
            htmlType="submit"
            type={BUTTON_TYPES.PRIMARY}
            upperCase
            danger
          >
            {BTN_TXT.DECLINE}
          </Button>
        </>
      }
    >
      <Form
        id="decline-contract-form"
        form={declineForm}
        onFinish={onDeclineReportHandler}
        layout="vertical"
      >
        <Typography.Headline6
          weight={TYPOGRAPHY_WEIGHT.NORMAL}
          className="financials-table__subtitle"
        >
          Please, provide the reason of decline. Email will be sent to the
          person responsible for the contract.
        </Typography.Headline6>
        <Input.TextArea
          propsItem={{
            label: 'Reason',
            name: 'decline_reason',
            rules: [
              {
                max: 250
              }
            ]
          }}
          propsTextArea={{
            autoSize: {
              maxRows: 12
            },
            showCount: true,
            maxLength: 250
          }}
        />
      </Form>
    </Popup>
  )

  const acceptPopup = () => (
    <Popup
      visible={isPopupOpen.acceptPopup}
      onCancel={onCancelHandler}
      title="Do you want to accept the report?"
      width={442}
      footer={
        <>
          <div className="financials-table__modal-button-container">
            <Button
              onClick={onCancelHandler}
              type={BUTTON_TYPES.DEFAULT}
              upperCase
            >
              {BTN_TXT.CANCEL}
            </Button>
            <Button
              type={BUTTON_TYPES.DEFAULT}
              className="financials-table__accept-button"
              upperCase
              onClick={onAcceptReportHandler}
            >
              {BTN_TXT.ACCEPT}
            </Button>
          </div>
          <Button type={BUTTON_TYPES.PRIMARY} upperCase onClick={onPayHandler}>
            {BTN_TXT.ACCEPT_AND_PAY}
          </Button>
        </>
      }
    >
      <>
        <Typography.Headline6 weight={TYPOGRAPHY_WEIGHT.NORMAL}>
          If you accept the report, it will not be possible to decline it later.
          Accept report?
        </Typography.Headline6>
        <Typography.Caption className="mt-16 accept-pay-subtitle">
          If you click on pay, you will be redirected to the payment form.
        </Typography.Caption>
      </>
    </Popup>
  )

  const resendPopup = () => (
    <Popup
      visible={isPopupOpen.resendPopup}
      onCancel={onCancelHandler}
      title="Do you want to resend the report?"
      width={442}
      footer={
        <>
          <Button
            onClick={onCancelHandler}
            type={BUTTON_TYPES.DEFAULT}
            upperCase
          >
            {BTN_TXT.CANCEL}
          </Button>
          <Button
            type={BUTTON_TYPES.PRIMARY}
            upperCase
            onClick={onResendReportHandler}
          >
            {BTN_TXT.RESEND_REPORT}
          </Button>
        </>
      }
    >
      <Typography.Headline6 weight={TYPOGRAPHY_WEIGHT.NORMAL}>
        The report will be generated again and sent to vendor. Resend report?
      </Typography.Headline6>
    </Popup>
  )
  return (
    <>
      {declinePopup()}
      {acceptPopup()}
      {resendPopup()}
    </>
  )
}
