import { ReactNode, useEffect, useMemo, useRef, useState } from 'react'
import cn from 'classnames'
import { Collapse } from 'components/Collapse'
import 'components/InfiniteList/styles.scss'
import { UseInfiniteScrollHookArgs } from 'hooks/useInfiniteScroll'
import { HeaderPanel } from 'features/CheckIns/CheckInsDetails/PrevCheckIns/HeaderPanel'
import { PrevContent } from 'features/CheckIns/CheckInsDetails/PrevCheckIns/PrevContent'
import { TCheckInsVendorList } from '../../Providers/types'
import { LoadingOutlined } from '@ant-design/icons'
import { Collapse as CollapseAntd } from 'antd'
const { Panel } = CollapseAntd
type Props = {
  items: TCheckInsVendorList[]
  listEmptyComponent?: ReactNode
  inverted?: boolean
  className?: string
  rootMargin?: string
} & UseInfiniteScrollHookArgs

const ListPevCheckIns = (props: Props) => {
  const { items, className, listEmptyComponent, ...hookArgs } = props
  const sentinelRef = useRef(null)

  const [activeKey, setActiveKey] = useState('')
  const onChange = (uuid) => setActiveKey(uuid)

  const hasItems = items.length !== 0

  useEffect(() => {
    const sentinelElement = sentinelRef.current
    if (!sentinelElement) return

    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          hookArgs.onLoadMore()
        }
      },
      {
        root: null,
        rootMargin: '100px',
        threshold: 1.0
      }
    )
    observer.observe(sentinelElement)
    return () => {
      observer.unobserve(sentinelElement)
    }
  }, [hookArgs.onLoadMore])

  const Loader = useMemo(
    () => (
      <div
        className={cn('infinite-list__sentry', {
          'infinite-list__sentry--fill': !items.length
        })}
      >
        <LoadingOutlined className="chat-list-feature-list__spinner" spin />
      </div>
    ),
    [items.length]
  )

  const ListEmptyComponent = useMemo(
    () => (hookArgs.loading ? null : listEmptyComponent),
    [hookArgs.loading, listEmptyComponent]
  )

  const RenderListItems = useMemo(() => {
    return (
      <Collapse activeKey={activeKey} onChange={onChange}>
        {items.map((checkin) => (
          <Panel
            key={checkin.uuid}
            header={<HeaderPanel checkin={checkin} key={checkin.uuid} />}
          >
            <PrevContent checkin={checkin} key={checkin.uuid} />
          </Panel>
        ))}
      </Collapse>
    )
  }, [onChange, activeKey, items])

  return (
    <div className={cn('infinite-list-container', className)}>
      {hasItems ? RenderListItems : ListEmptyComponent}
      <div ref={sentinelRef} style={{ height: '10px' }} />
      {hookArgs.loading && Loader}
    </div>
  )
}

export default ListPevCheckIns
