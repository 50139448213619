import { useCallback, useLayoutEffect, useMemo } from 'react'
import { useDispatch } from 'react-redux'
import { CHAT_TYPE } from 'constants/common'
import { routes } from 'router'
import { Forms } from 'components/Forms'
import { Drawer } from 'components/Drawer'
import { add as addToChatList } from 'redux/store/chats/slice'
import {
  createChatRequest,
  getGroupAvailableUsers
} from 'features/ChatList/api'
import { StartPVPChat } from 'features/ChatList/Sidebar/StartPVPChat'
import { useChatListPopup } from 'features/ChatList/Providers/ChatListPopupProvider'
import { SIDEBAR_TYPE } from 'features/ChatList/constants'
import { useChatListContext } from '../Providers/ChatListContextProvider'
import useRouter from 'hooks/useRouter'
import './styles.scss'
import { notification } from 'components/Notification'
import { NOTIFICATIONS } from '../../../constants'

const Sidebar = () => {
  const router = useRouter()
  const dispatch = useDispatch<any>()

  const { sidebarDrawer } = useChatListPopup()
  const chatListContext = useChatListContext()

  const sidebarType = useMemo(
    () => sidebarDrawer.state.payload?.type,
    [sidebarDrawer.state.payload?.type]
  )

  const updateTitle = useCallback(
    (title: string) => {
      sidebarDrawer.actions.setState((prevState) => ({
        ...prevState,
        title
      }))
    },
    [sidebarDrawer.actions]
  )

  const openCreatedChatById = useCallback(
    (id: string) => {
      if (id) {
        switch (sidebarType) {
          case SIDEBAR_TYPE.NEW_PRIVATE:
            chatListContext.actions.setActiveTab(CHAT_TYPE.PRIVATE)
            break
          case SIDEBAR_TYPE.NEW_GROUP:
            chatListContext.actions.setActiveTab(CHAT_TYPE.GROUP)
            break
        }
        router.navigate(`${routes.chat}/${id}`, { replace: true })
        sidebarDrawer.actions.close()
      }
    },
    [router, sidebarType, sidebarDrawer.actions, chatListContext.actions]
  )

  const createGroupChatAsync = useCallback(
    async (values) =>
      createChatRequest(values)
        .then((response) => {
          if (response?.data) {
            dispatch(addToChatList(response.data))
            notification.success({
              message:
                response?.data.type === 'private'
                  ? NOTIFICATIONS.PRIVATE_CHAT_CREATED
                  : NOTIFICATIONS.GROUP_CHAT_CREATED
            })
            openCreatedChatById(response.data.uuid)
          }
        })
        .catch((err) => {
          if (!!err?.data?.details?.length) {
            notification.error({ message: err?.data?.details[0] })
          }
        }),
    [dispatch, openCreatedChatById]
  )

  const DrawerContent = useMemo(() => {
    switch (sidebarType) {
      case SIDEBAR_TYPE.NEW_PRIVATE:
        return <StartPVPChat onClose={openCreatedChatById} />
      case SIDEBAR_TYPE.NEW_GROUP:
        return (
          <Forms.CreateGroupChat
            onSubmit={createGroupChatAsync}
            getAvailableUsers={getGroupAvailableUsers}
            onCancel={sidebarDrawer.state.onClose}
            onClose={sidebarDrawer.state.onClose}
          />
        )
      default:
        return null
    }
  }, [
    createGroupChatAsync,
    openCreatedChatById,
    sidebarDrawer.state.onClose,
    sidebarType
  ])

  useLayoutEffect(() => {
    switch (sidebarType) {
      case SIDEBAR_TYPE.NEW_PRIVATE:
        updateTitle('Start private chat')
        break
      case SIDEBAR_TYPE.NEW_GROUP:
        updateTitle('Create group chat')
        break
    }
  }, [sidebarType, updateTitle])

  return (
    <Drawer {...sidebarDrawer.state} className="chat-creation-modal">
      {DrawerContent}
    </Drawer>
  )
}

export default Sidebar
