import cn from 'classnames'
import Typography from 'components/Typography/Typography'
import { QUESTION_TYPE } from '../Survey/constants'
import { TCommonResponseProps } from './types'
import Multi from './IndividualTypes/Multi'
import Single from './IndividualTypes/Single'
import Text from './IndividualTypes/Text'

const IndividualResponse = ({
  question,
  isClinicalRationale
}: TCommonResponseProps) => {
  return (
    <div
      className={cn('individual-response-wrapper column mt-32', {
        isRequired: question.is_required
      })}
      key={question.uuid}
    >
      <Typography.Body1 className="individual-response-wrapper__question">
        {question.text}
      </Typography.Body1>
      {question.type === QUESTION_TYPE.MULTI && (
        <div className="individual-response-wrapper__multi column">
          <Multi
            isClinicalRationale={isClinicalRationale}
            options={question.options}
          />
        </div>
      )}
      {question.type === QUESTION_TYPE.SINGLE && (
        <div className="individual-response-wrapper__single">
          <Single
            isClinicalRationale={isClinicalRationale}
            options={question.options}
          />
        </div>
      )}
      {question.type === QUESTION_TYPE.TEXT && (
        <div className="individual-response-wrapper__text">
          <Text
            isClinicalRationale={isClinicalRationale}
            options={question.options}
          />
        </div>
      )}
    </div>
  )
}

export default IndividualResponse
