import { useSelector } from 'react-redux'
import { getIsLoading } from '../../../redux/store/chats/getters'
import { useMemo } from 'react'
import { LoadingOutlined } from '@ant-design/icons'
import InfiniteList from '../../../components/InfiniteList/InfiniteList'
import { useDashboardHistoryContext } from '../Providers/DashboardHistoryContextProvider'
import { IHistoryGoalsItem } from '../Providers/types'
import HistoryItem from './HistoryItem'
import { Typography } from '../../../components/Typography'

const HistoryList = () => {
  const isLoading = useSelector(getIsLoading)

  const { state, actions } = useDashboardHistoryContext()
  const { history } = state

  const Loader = useMemo(
    () => <LoadingOutlined className="chat-list-feature-list__spinner" spin />,
    []
  )

  return (
    <InfiniteList<IHistoryGoalsItem>
      items={history}
      loader={Loader}
      renderItem={HistoryItem}
      loading={isLoading}
      keyExtractor={(item) => item.uuid}
      hasNextPage={state.hasNextPage}
      listEmptyComponent={
        <div className="savings-history-empty row align-center justify-center full-height full-width">
          <Typography.Headline6 className="savings-history-empty__text">
            No savings target updates to show.
          </Typography.Headline6>
        </div>
      }
      onLoadMore={actions.handleLoadMore}
      className="chat-list-feature-list"
    />
  )
}

export default HistoryList
