import { useCallback, useEffect, useMemo, useState } from 'react'
import { Form } from 'antd'

import './styles.scss'

import { PLACEHOLDERS } from 'constants/txt'
import { Input } from 'components/Input'
import { Typography } from 'components/Typography'
import useDetailsForm from 'features/VendorDetails/General/DetailsForm/hooks/useDetailsForm'
import { useVendorDetailsContext } from 'features/VendorDetails/Providers/ContextProvider'
import { formatName, formatPhoneNumber } from 'helper/common'

type Props = ReturnType<typeof useDetailsForm>

const ContractAdminForm = (props: Props) => {
  const { form } = props

  const vendor_permission = Form.useWatch(['vendor', 'vendor_permission'], form)

  const vendorDetailsContext = useVendorDetailsContext()

  const phoneNumber = Form.useWatch(['contractAdmin', 'phone_number'], form)
  const phoneNumberGuest = Form.useWatch(
    ['contractSteward', 'phone_number'],
    form
  )

  const [isPhoneNumberValid, setIsPhoneNumberValid] = useState<boolean>(false)

  const getContractAdminError = useCallback(
    (query: string[]) =>
      vendorDetailsContext.actions.getError(['contract_admin', ...query]),
    [vendorDetailsContext.actions]
  )
  useEffect(() => {
    if (vendor_permission === 'guest') {
      phoneNumberGuest?.toString().length !== 10
        ? setIsPhoneNumberValid(true)
        : setIsPhoneNumberValid(false)
    } else {
      phoneNumber?.toString().length !== 10
        ? setIsPhoneNumberValid(true)
        : setIsPhoneNumberValid(false)
    }
  }, [phoneNumber, vendor_permission, phoneNumberGuest])
  const getValidationStatus = useCallback(
    (error?: string) => (error ? 'error' : undefined),
    []
  )

  const FirstNameField = useMemo(
    () => (
      <Input
        propsItem={{
          name: [
            vendor_permission === 'guest' ? 'contractSteward' : 'contractAdmin',
            'first_name'
          ],
          label: 'First name',
          help: getContractAdminError(['first_name']),
          validateStatus: getValidationStatus(
            getContractAdminError(['first_name'])
          ),
          rules: [
            {
              required: true,
              pattern: /^[-\w\s]+$/,
              max: 40
            }
          ],
          normalize: formatName
        }}
        propsInput={{
          showCount: true,
          maxLength: 40
        }}
      />
    ),
    [getContractAdminError, getValidationStatus, vendor_permission]
  )

  const LastNameField = useMemo(
    () => (
      <Input
        propsItem={{
          name: [
            vendor_permission === 'guest' ? 'contractSteward' : 'contractAdmin',
            'last_name'
          ],
          label: 'Last name',
          help: getContractAdminError(['last_name']),
          validateStatus: getValidationStatus(
            getContractAdminError(['last_name'])
          ),
          rules: [
            {
              required: true,
              pattern: /^[-\w\s]+$/,
              max: 40
            }
          ]
        }}
        propsInput={{
          showCount: true,
          maxLength: 40
        }}
      />
    ),
    [getContractAdminError, getValidationStatus, vendor_permission]
  )

  const EmailField = useMemo(
    () => (
      <Input
        propsItem={{
          name: [
            vendor_permission === 'guest' ? 'contractSteward' : 'contractAdmin',
            'email'
          ],
          label: 'Email',
          help: getContractAdminError(['email']),
          validateStatus: getValidationStatus(getContractAdminError(['email'])),
          validateTrigger: 'onBlur',
          rules: [
            {
              required: true,
              type: 'email',
              validateTrigger: 'onSubmit'
            }
          ]
        }}
        propsInput={{
          placeholder: PLACEHOLDERS.EMAIL
        }}
      />
    ),
    [getContractAdminError, getValidationStatus, vendor_permission]
  )

  const PhoneNumberField = useMemo(
    () => (
      <Input.Phone
        propsItem={{
          name: [
            vendor_permission === 'guest' ? 'contractSteward' : 'contractAdmin',
            'phone_number'
          ],
          label: 'Phone number',
          help: getContractAdminError(['phone_number']),
          validateStatus: getValidationStatus(
            getContractAdminError(['phone_number'])
          ),
          rules: [
            {
              pattern: /^[0-9]{10}$/,
              validateTrigger: isPhoneNumberValid ? ['onSubmit'] : ['onBlur']
            }
          ]
        }}
        propsInputNumber={{
          controls: false,
          formatter: formatPhoneNumber,
          prefix: phoneNumber || phoneNumberGuest ? '+1' : ''
        }}
      />
    ),
    [
      getContractAdminError,
      getValidationStatus,
      phoneNumber,
      vendor_permission,
      phoneNumberGuest
    ]
  )

  return (
    <div className="contract-admin-details-form">
      <Typography.Headline6>
        {vendor_permission === 'full_access'
          ? 'Contract admin'
          : 'Vendor contract steward'}
      </Typography.Headline6>
      <div className="contract-admin-details-form__grid-column">
        {/*<Avatar className="user-form_avatar-icon" name={nameInitials} />*/}
        <div className="contract-admin-details-form__grid-row two-columns">
          {FirstNameField}
          {LastNameField}
        </div>
        <div className="contract-admin-details-form__grid-row two-columns">
          {EmailField}
          {PhoneNumberField}
        </div>
      </div>
    </div>
  )
}

export default ContractAdminForm
