import Typography from '../../../../components/Typography/Typography'
import { usePRSDetailsContext } from '../Providers/PRSDetailsContextProvider'
import './style.scss'
import Link from 'components/Link/Link'
import { routes } from 'router'
import { useSelector } from 'react-redux'
import { getUser } from '../../../../redux/store/user/getters'
import { canSeeMyContracts } from '../../../../helper/common'
const Results = () => {
  const { state } = usePRSDetailsContext()
  const user = useSelector(getUser)
  const { prsContract } = state
  return (
    <div className="prs-results-tab">
      <Typography.Headline6 className="prs-results-tab__vendor">
        {prsContract.vendor_name}
      </Typography.Headline6>
      <Link
        className=""
        to={`${routes.contracts}${
          canSeeMyContracts(user.role) ? '/my_contracts' : ''
        }/${prsContract.uuid}`}
      >
        {prsContract.name}
      </Link>
      <Typography.Caption className="prs-results-tab__name mt-4">
        {prsContract.number}
      </Typography.Caption>
      <Typography.Caption className="prs-results-tab__name mt-4">
        {prsContract.contract_category_name}
      </Typography.Caption>
    </div>
  )
}

export default Results
