import { BTN_TXT } from 'constants/txt'
import { Button, BUTTON_TYPES } from 'components/Button'
import { ReactComponent as Plus16 } from 'assets/svg/Plus16.svg'
import { CONTRACT_STATUS } from '../../constants'
import { useContractDetailsContext } from '../../Providers/ContractDetailsContextProvider'
import {
  ACTIONS,
  contractSubject,
  SUBJECTS
} from 'features/ContractDetails/Abilities'
import { Can } from 'features/ContractDetails/Providers/ContractDetailsAbilityProvider'
import { useContractDetailsPopup } from '../../Providers/ContractDetailsPopupProvider'
import AddResponsibleModal from './AddResponsibleModal'

const AddResponsibleBtn = () => {
  const contractDetails = useContractDetailsContext()
  const { details, isAllResponsibleUsersAdded } = contractDetails.state
  const { addResponsibleUserPopup } = useContractDetailsPopup()
  const { open } = addResponsibleUserPopup.actions

  const isAvailableStatus =
    details.status !== CONTRACT_STATUS.TERMINATED && !isAllResponsibleUsersAdded

  return (
    <>
      {isAvailableStatus ? (
        <Can
          I={ACTIONS.ADD}
          a={contractSubject(SUBJECTS.RESPONSIBLE_USER, details)}
        >
          <Button
            icon={<Plus16 />}
            type={BUTTON_TYPES.DEFAULT}
            onClick={open}
            upperCase
          >
            {BTN_TXT.ADD_TEAM_MEMBERS}
          </Button>
        </Can>
      ) : null}
      <AddResponsibleModal />
    </>
  )
}

export default AddResponsibleBtn
