import { API_DICTIONARY, HTTP_METHODS } from 'constants/api'
import {
  HSAmendmentRequestData,
  TGetHealthSystemsRequest,
  TGetHealthSystemsResponse,
  THSAmendmentFile
} from 'features/ContractDetails/HSList/types'
import Fetcher from 'services/Fetcher'

const fetcher = new Fetcher({})

export const getContractHealthSystems: TGetHealthSystemsRequest = async (
  id,
  data
) =>
  fetcher.request<unknown, TGetHealthSystemsResponse>({
    url: `${API_DICTIONARY.CONTRACTS}${id}/${API_DICTIONARY.HEALTH_SYSTEMS}`,
    method: HTTP_METHODS.GET,
    ...data
  })

export const getContractAvailableHealthSystems: TGetHealthSystemsRequest =
  async (id, data) =>
    fetcher.request<unknown, TGetHealthSystemsResponse>({
      url: `${API_DICTIONARY.CONTRACTS}${id}/${API_DICTIONARY.AVAILABLE_HEALTH_SYSTEMS}`,
      method: HTTP_METHODS.GET,
      ...data
    })

export const getContractAvailableHealthSystemsForCommons: TGetHealthSystemsRequest =
  async (id, data) =>
    fetcher.request<unknown, TGetHealthSystemsResponse>({
      url: `${API_DICTIONARY.COMMONS}${API_DICTIONARY.HEALTH_SYSTEMS}?for_contract=${id}`,
      method: HTTP_METHODS.GET,
      ...data
    })

export const uploadHSAmendmentDocumentRequestAsync = async (data: FormData) =>
  fetcher.request<FormData, THSAmendmentFile>({
    url: `${API_DICTIONARY.FILES}${API_DICTIONARY.CONTRACT_AMENDMENT_DOCUMENTS}`,
    method: HTTP_METHODS.POST,
    data
  })

export const addHSAmendmentRequestAsync = async (
  id: string,
  data: HSAmendmentRequestData
) =>
  fetcher.request<HSAmendmentRequestData, unknown>({
    url: `${API_DICTIONARY.CONTRACTS}${id}/${API_DICTIONARY.HS_AMENDMENT}`,
    method: HTTP_METHODS.POST,
    data
  })
