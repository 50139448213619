import { State } from 'redux/type'
import { ICommunityInfoState, ICommunityInfo } from './types'
import { moduleName } from './constants'

const getCommunityInfoState = (state: State): ICommunityInfoState =>
  state[moduleName]

export const getCommunityInfo = (state: State): ICommunityInfo => {
  const CommunityInfoState = getCommunityInfoState(state)
  return CommunityInfoState.communityInfo
}
export const getError = (state: State): any | null => {
  const CommunityInfoState = getCommunityInfoState(state)
  return CommunityInfoState.error
}
export const getCommunityInfoIsLoaded = (state: State): boolean => {
  const CommunityInfoState = getCommunityInfoState(state)
  return CommunityInfoState.isCommunityInfoLoaded
}
export const getCommunityInfoIsLoading = (state: State): boolean => {
  const CommunityInfoState = getCommunityInfoState(state)
  return CommunityInfoState.isCommunityInfoLoading
}
