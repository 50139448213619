import ComplianceContextContextProvider from '../Compliance/Providers/ComplianceContextProvider'
import { Container } from '../../components/Container/Container'
import { Compliance } from '../../features/Compliance/Compliance'

const MarketInsights = () => {
  return (
    <ComplianceContextContextProvider>
      <Container title="Market Insights">
        <Compliance />
      </Container>
    </ComplianceContextContextProvider>
  )
}
export default MarketInsights
