import { useState } from 'react'
import { Button, BUTTON_TYPES } from '../../components/Button'
import { HistoryModal } from './HistoryModal/HistoryModal'
import { BTN_TXT } from '../../constants'
import { ReactComponent as More16 } from 'assets/svg/More16.svg'
import { HistoryCellProps } from './types'

export const HistoryCell = ({ documentKey }: HistoryCellProps) => {
  const [isHistoryOpen, setIsHistoryOpen] = useState(false)

  const actions = [
    {
      text: BTN_TXT.VIEW_INVOICE_HISTORY,
      onClick: () => setIsHistoryOpen(true)
    }
  ]

  return (
    <>
      <Button.Menu
        icon={<More16 />}
        type={BUTTON_TYPES.GHOST}
        options={actions}
        iconOnly
        hideArrow
        medium
      />
      <HistoryModal
        isOpen={isHistoryOpen}
        documentKey={documentKey}
        onClose={() => setIsHistoryOpen(false)}
      />
    </>
  )
}
