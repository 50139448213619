import {
  createContext,
  Dispatch,
  FC,
  PropsWithChildren,
  SetStateAction,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState
} from 'react'
import { DEFAULT_PAGE } from '../../../components/Table/constants'
import { TableProps } from 'antd'
import {
  TUncategorizedInvoicesRequestData,
  TUncategorizedInvoiceWithKey
} from '../PendingClassification/types'
import { useDebounce } from '../../../hooks/hooks'
import { TPageInfo, TQueryParams } from '../../../components/Table/types'
import { useDispatch } from 'react-redux'
import { setLoading } from '../../../redux/store/common/slice'
import {
  getUncategorizedInvoiceData,
  getUncategorizedInvoiceDataInfo
} from '../../../pages/InvoiceData/api'
import { usePendingClassificationEditContext } from './PendingClassificationEditProvider'

type ContextProps = {
  state: {
    pageInfo: TPageInfo
    totalNumber: number
    tableData: TUncategorizedInvoiceWithKey[]
    search: string
    firstLoad: boolean
  }
  actions: {
    onPageSizeChange: (pageSize: number) => void
    handleTableChange: TableProps<TUncategorizedInvoiceWithKey>['onChange']
    setSearch: Dispatch<SetStateAction<string>>
  }
}

const PendingClassificationTableContext = createContext<ContextProps>({
  state: null!,
  actions: null!
})

const PendingClassificationTableContextProvider: FC<PropsWithChildren> = ({
  children
}) => {
  const { reloadIndicator, tableData } =
    usePendingClassificationEditContext().state
  const { setTableData } = usePendingClassificationEditContext().actions
  const dispatch = useDispatch()
  const [pageInfo, setPageInfo] = useState<TPageInfo>({
    ...DEFAULT_PAGE
  })
  const timeoutRef = useRef<ReturnType<typeof setTimeout>>()
  const [totalNumber, setTotalNumber] = useState<number>(0)
  const [search, setSearch] = useState<string>('')
  const [firstLoad, setFirstLoad] = useState(true)
  const [sortParams, setSortParams] =
    useState<TUncategorizedInvoicesRequestData>()
  const debouncedSearch = useDebounce(search, 500)

  useEffect(() => {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current)
    }
    timeoutRef.current = setTimeout(
      () => {
        getTableData({ ...sortParams, page: { ...DEFAULT_PAGE } })
        setPageInfo({ ...DEFAULT_PAGE })
      },
      firstLoad ? 0 : 500
    )
  }, [debouncedSearch])

  useEffect(() => {
    if (reloadIndicator) {
      getTableData({ ...sortParams, page: { ...DEFAULT_PAGE } })
      setPageInfo({ ...DEFAULT_PAGE })
      getUncategorizedInvoicesCount()
    }
  }, [reloadIndicator])

  const onPageSizeChange = useCallback((pageSize: number) => {
    const page = {
      pageNumber: 1,
      pageSize
    }
    getTableData({
      ...sortParams,
      page
    })
    setPageInfo(page)
  }, [])

  const getTableData = useCallback(
    ({ sortField, sortOrder, page }: TUncategorizedInvoicesRequestData) => {
      dispatch(setLoading(true))
      const params: TQueryParams = {}
      if (search) {
        params.search = search
      }
      if (sortField) {
        if (sortOrder) {
          params.ordering = `${sortOrder === 'descend' ? '-' : ''}${sortField}`
        }
        setSortParams({
          sortField,
          sortOrder
        })
      }
      const dataPage = page ? page : pageInfo
      params.limit = dataPage.pageSize
      params.offset = (dataPage.pageNumber - 1) * dataPage.pageSize
      getUncategorizedInvoiceData({ params })
        .then((resp) => {
          if (!resp.data?.results) {
            return
          }
          setTableData(
            resp.data.results.map((i) => ({
              ...i,
              key: i.uuid,
              contract_category: i.contract_category?.uuid ?? null
            }))
          )
          if (firstLoad) {
            setFirstLoad(false)
          }
        })
        .finally(() => dispatch(setLoading(false)))
    },
    [dispatch, firstLoad, pageInfo, search]
  )

  const getUncategorizedInvoicesCount = useCallback(() => {
    getUncategorizedInvoiceDataInfo().then((resp) => {
      if (!resp.data) {
        return
      }
      setTotalNumber(resp.data.total_count)
    })
  }, [])

  const handleTableChange = useCallback(
    (pagination, _, sorter) => {
      const page = {
        pageNumber: pagination.current,
        pageSize: pagination.pageSize
      }
      getTableData({
        sortField: sorter.field,
        sortOrder: sorter.order,
        page
      })
      setPageInfo(page)
    },
    [getTableData]
  )

  useEffect(() => {
    getUncategorizedInvoicesCount()
  }, [getUncategorizedInvoicesCount])

  const context = useMemo(
    () => ({
      state: {
        pageInfo,
        totalNumber,
        tableData,
        search,
        firstLoad
      },
      actions: {
        onPageSizeChange,
        handleTableChange,
        setSearch
      }
    }),
    [
      firstLoad,
      pageInfo,
      totalNumber,
      tableData,
      search,
      onPageSizeChange,
      handleTableChange
    ]
  )

  return (
    <PendingClassificationTableContext.Provider value={context}>
      {children}
    </PendingClassificationTableContext.Provider>
  )
}

export const usePendingClassificationTableContext = () =>
  useContext(PendingClassificationTableContext)

export default PendingClassificationTableContextProvider
