import { Form, RadioChangeEvent } from 'antd'
import cn from 'classnames'
import { Button, BUTTON_TYPES } from 'components/Button'
import { BTN_TXT, VALIDATION_MESSAGES } from '../../../constants'
import useForm from './hooks/useForm'
import { Popup } from 'components/Popup'
import { useShowPopup } from 'hooks/useShowPopup'
import './style.scss'
import FormItem from './FormItem'
import {
  TClinicalReviewFormProps,
  TFormClinicalReview,
  TFormItemClinicalReview
} from './types'
import { updateOrAddDecision } from './helper'
import { useEffect, useState } from 'react'
import { FormListFieldData } from 'antd/lib/form/FormList'
import { notification } from 'components/Notification'
import SubmitFormModal from '../SubmitFormModal'

const ClinicalReviewForm = ({
  review,
  setClinicalReviewForStakeholder
}: TClinicalReviewFormProps) => {
  const {
    form,
    handleCancelForm,
    handleFinishForm,
    handleChangeForm,
    isActive,
    hasFormChanges,
    setIsActive,
    setIsSubmit
  } = useForm(setClinicalReviewForStakeholder)
  const [showPopup, confirmNavigation, cancelNavigation] =
    useShowPopup(isActive)
  const [hasError, setHasError] = useState(false)

  useEffect(() => {
    if (review?.length) {
      form.setFieldsValue({
        results: review.map((item) => ({
          uuid: item.uuid,
          decision: undefined
        }))
      })
    }
  }, [review])

  const onChange = (e: RadioChangeEvent, uuid: string) => {
    setIsActive(true)
    const existedData = form.getFieldValue('results') || []
    const newData = updateOrAddDecision({
      data: existedData,
      uuid: uuid,
      decision: e.target.value
    })
    form.setFieldsValue({
      results: newData
    })
  }
  const validateDecisions = (_, value: TFormItemClinicalReview[]) => {
    if (!value || value.some((item) => item.decision === undefined)) {
      setHasError(true)
      notification.error({ message: VALIDATION_MESSAGES.SM0039 })
      return Promise.reject(VALIDATION_MESSAGES.SM0039)
    } else {
      setHasError(false)
      return Promise.resolve()
    }
  }

  return (
    <>
      <Form<TFormClinicalReview>
        id="clinical-review-form"
        form={form}
        onChange={handleChangeForm}
        onFinish={handleFinishForm}
        initialValues={{
          results: review.map((item) => ({
            uuid: item.uuid,
            decision: undefined
          }))
        }}
        className="clinical-review-form-container"
      >
        <Form.List name="results" rules={[{ validator: validateDecisions }]}>
          {(fields) => (
            <div>
              {fields.map((field: FormListFieldData) => {
                const item = review[field.key]
                if (!item) return
                return (
                  <FormItem
                    key={item.uuid}
                    label={item.contract_category}
                    onChange={onChange}
                    vendors={item.vendors.join(', ')}
                    hasError={
                      hasError &&
                      !form.getFieldValue('results')[field.key]?.decision
                    }
                    led_by={item.led_by}
                    uuid={item.uuid}
                    value={form.getFieldValue('results')[field.key]?.decision}
                  />
                )
              })}
            </div>
          )}
        </Form.List>
        <div className={cn('community-info-form_buttons')}>
          <Button
            onClick={handleCancelForm}
            type={BUTTON_TYPES.DEFAULT}
            upperCase
          >
            {BTN_TXT.CANCEL}
          </Button>
          <Button
            type={BUTTON_TYPES.PRIMARY}
            disabled={!hasFormChanges}
            htmlType="submit"
            upperCase
          >
            {BTN_TXT.SUBMIT}
          </Button>
        </div>
      </Form>
      <SubmitFormModal setIsSubmit={setIsSubmit} />
      <Popup.Discard
        visible={isActive && showPopup}
        onCancel={cancelNavigation}
        onSubmit={confirmNavigation}
      />
    </>
  )
}

export default ClinicalReviewForm
