import { Key, useCallback, useEffect, useRef, useState } from 'react'
import { useDispatch } from 'react-redux'
import axios, { CancelTokenSource } from 'axios'
import { RequestArgs } from 'constants/types'
import { TQueryParams } from 'components/Table/types'
import { DEFAULT_PAGE } from 'components/Table/constants'
import { setLoading } from 'redux/store/common/slice'
import { IContractHealthSystem } from 'features/ContractDetails/HSList/types'
import { useDebounce } from 'hooks/hooks'
import { useContractDetailsContext } from 'features/ContractDetails/Providers/ContractDetailsContextProvider'
import { useContractDetailsPopup } from 'features/ContractDetails/Providers/ContractDetailsPopupProvider'
import { CONTRACT_STATUS } from 'features/ContractDetails/constants'
import { Props } from './types'

const useHSTable = (props: Props) => {
  const { getContractHealthSystems } = props

  const dispatch = useDispatch()

  const contractDetailsContext = useContractDetailsContext()
  const { addHealthSystemPopup } = useContractDetailsPopup()

  const { details } = contractDetailsContext.state

  const [tableData, setTableData] = useState<IContractHealthSystem[]>([])
  const [searchValue, setSearchValue] = useState('')
  const [sortParams, setSortParams] =
    useState<RequestArgs<IContractHealthSystem>>()
  const [firstLoad, setFirstLoad] = useState(true)
  const [totalNumber, setTotalNumber] = useState(0)
  const [pageInfo, setPageInfo] = useState({ ...DEFAULT_PAGE })

  const [selectedRowKeys, setSelectedRowKeys] = useState<Key[]>([])

  const debouncedSearch = useDebounce(searchValue, 500)
  const timeoutRef = useRef<ReturnType<typeof setTimeout>>()
  const tableDataTokenRef = useRef<CancelTokenSource>()

  const isContractSigned =
    details.status === CONTRACT_STATUS.EXECUTED ||
    details.status === CONTRACT_STATUS.ACTIVE

  const getTableData = useCallback(
    ({ sortField, sortOrder, page }: RequestArgs<IContractHealthSystem>) => {
      if (!details.uuid) {
        return
      }

      dispatch(setLoading(true))

      if (tableDataTokenRef.current) {
        tableDataTokenRef.current.cancel()
      }

      const params: TQueryParams = {}

      if (searchValue) {
        params.search = searchValue
      }

      if (sortField) {
        if (sortOrder) {
          params.ordering = `${sortOrder === 'descend' ? '-' : ''}${sortField}`
        }
        setSortParams({
          sortField,
          sortOrder
        })
      }

      const dataPage = page ? page : pageInfo

      params.limit = dataPage.pageSize
      params.offset = (dataPage.pageNumber - 1) * dataPage.pageSize
      tableDataTokenRef.current = axios.CancelToken.source()

      getContractHealthSystems(details.uuid, {
        cancelToken: tableDataTokenRef.current?.token,
        params
      })
        .then((resp) => {
          if (!resp.data?.results) {
            return
          }
          setTableData(
            resp.data.results.map((i) => ({
              ...i,
              key: i.uuid
            }))
          )
          setTotalNumber(resp.data.count)
          if (firstLoad) {
            setFirstLoad(false)
          }
        })
        .finally(() => dispatch(setLoading(false)))
    },
    [details.uuid, searchValue, getContractHealthSystems]
  )

  const handleTableChange = (pagination, _filters, sorter) => {
    const page = {
      pageNumber: pagination.current,
      pageSize: pagination.pageSize
    }

    getTableData({
      sortField: sorter.field,
      sortOrder: sorter.order,
      page
    })
    setPageInfo(page)
  }

  const handleChangePageSize = (pageSize) => {
    const page = {
      pageNumber: 1,
      pageSize
    }

    getTableData({
      ...sortParams,
      page
    })
    setPageInfo(page)
  }

  const handleSelectRow = (selectedRowKeys: Key[]) => {
    setSelectedRowKeys(selectedRowKeys)
  }

  const refreshTableData = useCallback(async () => {
    getTableData({ ...sortParams, ...pageInfo })
  }, [getTableData, pageInfo, sortParams])

  useEffect(() => {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current)
    }

    timeoutRef.current = setTimeout(
      () => getTableData({ ...sortParams }),
      firstLoad ? 0 : 500
    )
  }, [getTableData, debouncedSearch])

  useEffect(() => {
    return () => {
      if (!!tableDataTokenRef.current) {
        tableDataTokenRef.current.cancel()
      }
    }
  }, [])

  return {
    tableData,
    searchValue,
    sortParams,
    firstLoad,
    totalNumber,
    pageInfo,
    selectedRowKeys,
    debouncedSearch,
    isContractSigned,
    handleTableChange,
    handleChangePageSize,
    handleClickAddSystem: addHealthSystemPopup.actions.open,
    handleSelectRow,
    setSearchValue,
    getTableData,
    refreshTableData
  }
}

export type UseHSTableReturnType = ReturnType<typeof useHSTable>

export default useHSTable
