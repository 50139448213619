import { useMemo } from 'react'

import 'features/ContractDetails/ContractDetailsGeneral/ContractDetailsDocuments/ContractVersionHistory/styles.scss'
import { Attachment } from 'components/Attachment/Attachment'
import { fileNameFormatter } from 'helper/common'
import { TVersionHistory } from './types'
export const VersionHistory = ({ documents }: TVersionHistory) => {
  const Documents = useMemo(
    () =>
      documents.map((document, idx) => (
        <div
          className="contract-version-history__document"
          key={document.uuid || idx}
        >
          <div className="contract-version-history__node" />
          <Attachment
            link={document.file}
            user={document.creator}
            createdAt={document.created_at}
            filename={fileNameFormatter(document.file)}
            showDetails
            disableActions
          />
        </div>
      )),
    [documents]
  )

  return <div className="contract-version-history">{Documents}</div>
}
