import { useCallback, useEffect, useRef, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useParams } from 'react-router-dom'
import axios, { CancelTokenSource } from 'axios'
import { Button, BUTTON_TYPES } from 'components/Button'
import { Table } from 'components/Table'
import { Input } from 'components/Input'
import { Popup } from 'components/Popup'
import { Typography, TYPOGRAPHY_WEIGHT } from 'components/Typography'
import { AddPopup } from './AddPopup/AddPopup'
import { columns } from './constants'
import { getAssignedVendors } from './api'
import { isTableHasParams } from 'helper/common'
import { useDebounce } from 'hooks/hooks'
import { setLoading } from 'redux/store/common/slice'
import { BTN_TXT, PLACEHOLDERS } from 'constants/txt'
import { ReactComponent as Search16 } from 'assets/svg/Search16.svg'
import { ReactComponent as Plus16 } from 'assets/svg/Plus16.svg'
import { ReactComponent as Delete16 } from 'assets/svg/Delete16.svg'
import {
  TRFPVendorUser,
  TRFPVendorUsersFilters,
  TRFPVendorUsersRequestData,
  TRFPVendorUsersFiltersFunc,
  TRFPVendorsParams,
  TRFPVendorsProps
} from './types'
import { updateRFP } from 'features/RFP/RFPDetails/api'
import { DEFAULT_PAGE } from 'components/Table/constants'
import { useRFPDetailsContext } from 'features/RFP/RFPDetails/Providers/RFPDetailsContextProvider'
import { ReactComponent as Close16 } from '../../../assets/svg/Clos16.svg'
import { ResendBidModal } from './ResendBidContainer'

export const RFPVendorUsers = ({ disableActions }: TRFPVendorsProps) => {
  const rfpDetailsContext = useRFPDetailsContext()

  const [tableData, setTableData] = useState<TRFPVendorUser[]>([])
  const [selectedRowKeys, setSelectedRowKeys] = useState([])
  const [searchValue, setSearchValue] = useState('')
  const [sortParams, setSortParams] = useState<TRFPVendorUsersRequestData>()
  const [filters, setFilters] = useState<TRFPVendorUsersFilters>({})
  const [isAddPopupOpen, setIsAddPopupOpen] = useState(false)
  const [isDeletePopupOpen, setIsDeletePopupOpen] = useState(false)
  const [firstLoad, setFirstLoad] = useState(true)
  const [totalNumber, setTotalNumber] = useState(0)
  const [pageInfo, setPageInfo] = useState({ ...DEFAULT_PAGE })
  const timeoutRef = useRef<ReturnType<typeof setTimeout>>()
  const tableDataTokenRef = useRef<CancelTokenSource>()
  const [vendorId, setVendorId] = useState('')
  const dispatch = useDispatch()
  const { id } = useParams()
  const debouncedSearch = useDebounce(searchValue, 500)
  useEffect(() => {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current)
    }
    if (id) {
      timeoutRef.current = setTimeout(
        () => getTableData({ ...sortParams, ...filters }),
        firstLoad ? 0 : 500
      )
    }
  }, [debouncedSearch, id])
  useEffect(() => {
    return () => {
      if (!!tableDataTokenRef.current) {
        tableDataTokenRef.current.cancel()
      }
    }
  }, [])
  const getTableData = ({
    sortField,
    sortOrder,
    filters,
    page
  }: TRFPVendorUsersRequestData) => {
    dispatch(setLoading(true))
    if (!!tableDataTokenRef.current) {
      tableDataTokenRef.current.cancel()
    }
    const params: TRFPVendorsParams = { ...filters }
    if (searchValue) {
      params.search = searchValue
    }
    if (sortField) {
      if (sortOrder) {
        params.ordering = `${sortOrder === 'descend' ? '-' : ''}${sortField}`
      }
      setSortParams({
        sortField,
        sortOrder
      })
    }
    const dataPage = page ? page : pageInfo
    params.limit = dataPage.pageSize
    params.offset = (dataPage.pageNumber - 1) * dataPage.pageSize
    tableDataTokenRef.current = axios.CancelToken.source()
    getAssignedVendors(id as string, {
      cancelToken: tableDataTokenRef.current?.token,
      params
    })
      .then((resp) => {
        if (resp.data?.results) {
          setTableData(
            resp.data.results.map((i) => ({
              ...i,
              key: i.uuid
            }))
          )
          setTotalNumber(resp.data.count)
          if (firstLoad) {
            setFirstLoad(false)
          }
        }
      })
      .finally(() => dispatch(setLoading(false)))
  }
  const handleSelectRow = (selectedRowKeys) =>
    setSelectedRowKeys(selectedRowKeys)
  const handleTableChange = (pagination, _filters, sorter) => {
    const page = {
      pageNumber: pagination.current,
      pageSize: pagination.pageSize
    }
    getTableData({
      sortField: sorter.field,
      sortOrder: sorter.order,
      filters,
      page
    })
    setPageInfo(page)
  }
  const handleChangePageSize = (pageSize) => {
    const page = {
      pageNumber: 1,
      pageSize
    }
    getTableData({
      ...sortParams,
      filters,
      page
    })
    setPageInfo(page)
  }
  const applyFilters: TRFPVendorUsersFiltersFunc = (field, appliedFilters) => {
    const newFilters = { ...filters, [field]: appliedFilters }
    setFilters(newFilters)
    getTableData({ ...sortParams, filters: newFilters })
  }
  const handleCloseDeletePopup = () => {
    setIsDeletePopupOpen(false)
    setSelectedRowKeys([])
  }
  const handleDeleteUsers = () => {
    dispatch(setLoading(true))
    updateRFP(id as string, {
      vendor_contract_stewards:
        tableData.length !== selectedRowKeys.length
          ? tableData
              .map((i) => i.uuid)
              .filter((i) => !selectedRowKeys.find((j) => i === j))
          : []
    })
      .then(() => {
        getTableData({ ...sortParams, filters })
        handleCloseDeletePopup()
      })
      .catch(() => dispatch(setLoading(false)))
    handleCloseDeletePopup()
  }
  const refreshTableData = useCallback(async () => {
    getTableData({ ...sortParams, ...pageInfo })
  }, [getTableData, pageInfo, sortParams])
  useEffect(() => {
    if (!firstLoad) {
      refreshTableData()
    }
  }, [rfpDetailsContext?.state?.data?.status, firstLoad])
  return (
    <div className="table-wrapper">
      <div className="table-wrapper__header">
        {!!selectedRowKeys.length ? (
          <Typography.Body1
            className="table-wrapper__select-title"
            weight={TYPOGRAPHY_WEIGHT.SEMI_BOLD}
          >
            Selected {selectedRowKeys.length} of {totalNumber}
          </Typography.Body1>
        ) : (
          <Input
            className="allow-clear"
            propsInput={{
              allowClear: { clearIcon: <Close16 /> },
              placeholder: PLACEHOLDERS.SEARCH,
              prefix: <Search16 />,
              value: searchValue,
              onChange: (e) => setSearchValue(e.target.value)
            }}
          />
        )}
        <div className="table-wrapper__header_buttons">
          {rfpDetailsContext.state.canEditRfp &&
            !selectedRowKeys.length &&
            !disableActions &&
            rfpDetailsContext.state.canManageVendors && (
              <Button
                type={BUTTON_TYPES.PRIMARY}
                icon={<Plus16 />}
                onClick={() => setIsAddPopupOpen(true)}
                upperCase
              >
                {BTN_TXT.ADD_VENDOR}
              </Button>
            )}
          {!!selectedRowKeys.length &&
            !disableActions &&
            rfpDetailsContext.state.canManageVendors && (
              <Button
                type={BUTTON_TYPES.PRIMARY}
                icon={<Delete16 />}
                onClick={() => setIsDeletePopupOpen(true)}
                upperCase
                danger
              >
                {BTN_TXT.DELETE}
              </Button>
            )}
        </div>
      </div>
      <Table<TRFPVendorUser>
        dataSource={tableData}
        columns={columns({
          applyFilters,
          filters,
          canResendBid: rfpDetailsContext.state.canResendBid,
          status: rfpDetailsContext.state.data.status,
          setVendorId
        })}
        className="vendors-users-data-list-table"
        onChange={handleTableChange}
        hasSearchOrFilters={isTableHasParams<TRFPVendorUsersFilters>(
          searchValue,
          filters
        )}
        onChangePage={handleChangePageSize}
        pageSize={pageInfo.pageSize}
        pagination={{
          pageSize: pageInfo.pageSize,
          current: pageInfo.pageNumber,
          total: totalNumber
        }}
        createButton={
          rfpDetailsContext.state.canEditRfp &&
          !disableActions &&
          rfpDetailsContext.state.canManageVendors
            ? {
                buttonText: BTN_TXT.ADD_VENDOR,
                availability: true,
                action: () => setIsAddPopupOpen(true)
              }
            : undefined
        }
        rowSelection={
          !disableActions &&
          rfpDetailsContext.state.canEditRfp &&
          rfpDetailsContext.state.canManageVendors
            ? {
                selectedRowKeys,
                onChange: handleSelectRow
              }
            : undefined
        }
      />
      <AddPopup
        isAddPopupOpen={isAddPopupOpen}
        setIsAddPopupOpen={setIsAddPopupOpen}
        updateAssignedValues={() => getTableData({ ...sortParams, ...filters })}
        selectedUsers={tableData}
      />
      <Popup
        visible={isDeletePopupOpen}
        onCancel={handleCloseDeletePopup}
        title={`Delete user${selectedRowKeys.length > 1 ? 's' : ''}?`}
        width={442}
        footer={
          <>
            <Button
              type={BUTTON_TYPES.DEFAULT}
              onClick={handleCloseDeletePopup}
              upperCase
            >
              {BTN_TXT.CANCEL}
            </Button>
            <Button
              type={BUTTON_TYPES.PRIMARY}
              onClick={handleDeleteUsers}
              upperCase
              danger
            >
              {BTN_TXT.DELETE}
            </Button>
          </>
        }
      >
        <Typography.Body1>
          {selectedRowKeys.length} user{selectedRowKeys.length > 1 ? 's' : ''}{' '}
          will be removed from RFP.
          <br />
          Do you really want to delete user
          {selectedRowKeys.length > 1 ? 's' : ''} from the list?
        </Typography.Body1>
      </Popup>
      <ResendBidModal
        uuid={rfpDetailsContext.state.data.uuid}
        vendorId={vendorId}
        callback={refreshTableData}
      />
    </div>
  )
}
