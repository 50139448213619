import {
  createContext,
  FC,
  PropsWithChildren,
  useContext,
  useMemo
} from 'react'
import useDrawer, {
  DrawerReturnTypeWithPayload
} from '../../../hooks/useDrawer'
import usePopup, {
  PopupReturnType,
  PopupReturnTypeWithPayload
} from '../../../hooks/usePopup'
import {
  TBulkUpdatePayload,
  TSimilarItemsPayload
} from '../PendingClassification/types'

type ContextProps = {
  similarItemsSidebarDrawer: DrawerReturnTypeWithPayload<TSimilarItemsPayload>
  bulkClassificationPopup: PopupReturnTypeWithPayload<TBulkUpdatePayload>
  confirmSavePopup: PopupReturnType
}

const basePopup = (title: string) => ({
  title
})

const PendingClassificationPopupContext = createContext<ContextProps>({
  similarItemsSidebarDrawer: null!,
  bulkClassificationPopup: null!,
  confirmSavePopup: null!
})

const PendingClassificationPopupContextProvider: FC<PropsWithChildren> = ({
  children
}) => {
  const similarItemsSidebarDrawer = useDrawer<TSimilarItemsPayload>(
    basePopup('Similar items')
  )
  const bulkClassificationPopup = usePopup<TBulkUpdatePayload>(
    basePopup('Bulk Edit')
  )
  const confirmSavePopup = usePopup(basePopup('Confirm Changes'))

  const context = useMemo(
    () => ({
      similarItemsSidebarDrawer,
      bulkClassificationPopup,
      confirmSavePopup
    }),
    [bulkClassificationPopup, similarItemsSidebarDrawer, confirmSavePopup]
  )

  return (
    <PendingClassificationPopupContext.Provider value={context}>
      {children}
    </PendingClassificationPopupContext.Provider>
  )
}

export const usePendingClassificationPopupContext = () =>
  useContext(PendingClassificationPopupContext)

export default PendingClassificationPopupContextProvider
