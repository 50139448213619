import { Popover } from 'antd'
import { ReactComponent as Close24 } from 'assets/svg/Close24.svg'
import { Typography } from 'components/Typography'
import './style.scss'
import { RowInfo } from './RowInfo'
import { ReactComponent as Clock } from 'assets/svg/Clock.svg'
import { ReactComponent as Location } from 'assets/svg/Location.svg'
import { ReactComponent as Paragraph } from 'assets/svg/Paragraph.svg'
import { ReactComponent as Users } from 'assets/svg/Users.svg'
import ShowMore from '../../../components/ShowMore/ShowMore'
import IconNameItem from '../../../components/IconNameItem/IconNameItem'
import { BTN_TXT, LABELS, ROLES_NAMES } from '../../../constants'
import { Button, BUTTON_TYPES } from '../../../components/Button'
import { CalendarMeetingDetailsPopoverType } from './types'
import { useCalendarPopup } from '../../../pages/Calendar/Provider/CalendarPopupProvider'
import { DeleteContainer } from './DeleteContainer'
import { ACTIONS, Can, SUBJECTS } from '../../Permission'
import { subject } from '@casl/ability'
import { ReactComponent as Check16 } from 'assets/svg/Check16.svg'
import { ReactComponent as Close16 } from 'assets/svg/Clos16.svg'
import { ReactComponent as Question16 } from 'assets/svg/Question16.svg'
import { useCalendarEventContext } from 'pages/Calendar/CalendarEventsProvider'
import { RespondToTheMeeting } from '../../../pages/Calendar/types'
import { LinkHighlighter } from '../../../components/LinkHighlighter/LinkHighlighter'
import cn from 'classnames'
import { useSelector } from 'react-redux'
import { getUser } from '../../../redux/store/user/getters'

export const CalendarMeetingDetailsPopover = ({
  children,
  isOpen,
  setIsOpen,
  event,
  placement,
  className
}: CalendarMeetingDetailsPopoverType) => {
  const { addEventPopup } = useCalendarPopup()
  const { actions } = useCalendarEventContext()
  const user = useSelector(getUser)
  const handleOpenEditModal = () => {
    addEventPopup.actions.open()
  }
  const { creator } = event ?? {}

  const isAlreadyAccepted =
    event?.participants?.find((p) => p.uuid === user.uuid)?.respond ===
    RespondToTheMeeting.ACCEPT
  const isAlreadyRespondedMaybe =
    event?.participants?.find((p) => p.uuid === user.uuid)?.respond ===
    RespondToTheMeeting.MAYBE

  if (!event) return <>{children}</>

  const onAccept = () => {
    if (isAlreadyAccepted) return
    actions.respondToTheMeeting(event.uuid, RespondToTheMeeting.ACCEPT)
  }
  const onMaybe = () => {
    if (isAlreadyRespondedMaybe) return
    actions.respondToTheMeeting(event.uuid, RespondToTheMeeting.MAYBE)
  }

  const onDecline = () =>
    actions.respondToTheMeeting(event.uuid, RespondToTheMeeting.DECLINE)

  const onMouseDown = (evt) => {
    evt.stopPropagation()
    evt.preventDefault()
  }

  return (
    <Popover
      overlayClassName={cn('calendar__meeting-details-popover', className)}
      trigger="click"
      placement={placement ?? 'topLeft'}
      visible={isOpen}
      zIndex={10000}
      onVisibleChange={(open) => setIsOpen(open ? event.uuid : null)}
      title={
        <div className="calendar__meeting-details-popover__content-header row space-between p-16">
          <div className="column overflow-wrap-break-word calendar__meeting-details-popover__content-header__block">
            <Typography.Headline5>{event.name}</Typography.Headline5>
            {event.event_type && (
              <Typography.Body2>{event.event_type?.value}</Typography.Body2>
            )}
          </div>

          <div
            className="calendar__meeting-details-popover__content-header__close"
            onClick={() => setIsOpen(null)}
          >
            <Close24 />
          </div>
        </div>
      }
      content={
        <div className="calendar__meeting-details-popover__content">
          <div className="calendar__meeting-details-popover__content__info">
            {event.formattedDate && (
              <RowInfo icon={<Clock />}>{event.formattedDate}</RowInfo>
            )}
            {event.location && (
              <RowInfo icon={<Location />}>
                <ShowMore>
                  <LinkHighlighter
                    text={event.location}
                    linkClassName="overflow-wrap-break-word font-size-14"
                  />
                </ShowMore>
              </RowInfo>
            )}
            {event.summary && (
              <RowInfo icon={<Paragraph />}>
                <ShowMore>{event.summary}</ShowMore>
              </RowInfo>
            )}
            <RowInfo icon={<Users />}>
              <div>
                {creator && (
                  <IconNameItem
                    className="mb-8"
                    firstName={creator.first_name}
                    lastName={creator.last_name}
                    src={creator.avatar?.file_preview ?? null}
                    status={RespondToTheMeeting.ACCEPT}
                    roleName={ROLES_NAMES[creator.role]}
                    tagText={LABELS.ORGANIZER}
                  />
                )}
                {event.participants?.map(
                  ({ uuid, first_name, last_name, avatar, role, respond }) => {
                    return (
                      <IconNameItem
                        key={uuid}
                        className="mb-8"
                        firstName={first_name}
                        lastName={last_name}
                        src={avatar?.file_preview ?? null}
                        status={respond}
                        roleName={ROLES_NAMES[role]}
                      />
                    )
                  }
                )}
              </div>
            </RowInfo>
          </div>
          <Can
            I={ACTIONS.CRUD}
            a={subject(SUBJECTS.CALENDAR_EVENT, { ...event })}
          >
            <div className="calendar__meeting-details-popover__content-footer row justify-end">
              <DeleteContainer />
              <Button
                type={BUTTON_TYPES.DEFAULT}
                upperCase
                medium
                onClick={handleOpenEditModal}
                onMouseDown={onMouseDown}
              >
                {BTN_TXT.EDIT}
              </Button>
            </div>
          </Can>
          <Can
            I={ACTIONS.CRUD}
            a={subject(SUBJECTS.CALENDAR_EVENT_RESPONSE, { ...event })}
          >
            <div className="calendar__meeting-details-popover__content-footer row justify-end">
              <Button
                type={
                  isAlreadyAccepted
                    ? BUTTON_TYPES.PRIMARY
                    : BUTTON_TYPES.DEFAULT
                }
                upperCase
                medium
                className="mr-8"
                onClick={onAccept}
                icon={<Check16 />}
                onMouseDown={onMouseDown}
              >
                {BTN_TXT.ACCEPT}
              </Button>
              <Button
                type={
                  isAlreadyRespondedMaybe
                    ? BUTTON_TYPES.PRIMARY
                    : BUTTON_TYPES.DEFAULT
                }
                upperCase
                medium
                className="mr-8"
                onClick={onMaybe}
                icon={<Question16 />}
                onMouseDown={onMouseDown}
              >
                {BTN_TXT.MAYBE}
              </Button>
              <Button
                type={BUTTON_TYPES.DEFAULT}
                upperCase
                danger
                medium
                onClick={onDecline}
                className="decline-event"
                icon={<Close16 />}
                onMouseDown={onMouseDown}
              >
                {BTN_TXT.DECLINE}
              </Button>
            </div>
          </Can>
        </div>
      }
    >
      {children}
    </Popover>
  )
}
