import { ReactComponent as CognusLogo } from 'assets/svg/Logo.svg'
import { ReactComponent as MenuLogo } from 'assets/svg/Menu16.svg'
import { ReactComponent as CloseIcon } from 'assets/svg/CloseIconDrawer.svg'
import './style.scss'
import LandingBanner from 'features/Landing/Banner/Banner'
import FirstSection from 'features/Landing/Sections/FirstSection'
import WhoWeAre from '../../features/Landing/Sections/WhoWeAre'
import ContactUs from 'features/Landing/ContactUs/ContactUs'
import Footer from '../../features/Landing/Footer/Footer'
import Header from '../../features/Landing/Header/Header'
import Drawer from '../../components/Drawer/Drawer'
import useDrawer from '../../hooks/useDrawer'
const LandingPage = () => {
  const menuDrawerProps = useDrawer()
  const scroll = (link: string) => {
    const section = document.querySelector('#' + link)
    section?.scrollIntoView({ behavior: 'smooth', block: 'start' })
  }
  const handleDrawer = (link: string) => {
    menuDrawerProps.actions.close()
    scroll(link)
  }
  const open = () => {
    menuDrawerProps.actions.open()
  }
  return (
    <div className="landing-page column align-center">
      <div className="landing-page__header row space-between align-center full-width">
        <CognusLogo className="landing-page__header__logo" />
        <Header scroll={scroll} />
        <MenuLogo onClick={open} className="landing-page__header__menu" />
      </div>
      <LandingBanner scroll={scroll} />
      <FirstSection />
      <WhoWeAre />
      <ContactUs />
      <Footer scroll={scroll} />
      <Drawer
        {...menuDrawerProps.state}
        placement="top"
        className="mobile-menu-drawer"
        closeIcon={<CloseIcon />}
        title={<CognusLogo className="landing-page__header__logo" />}
      >
        <Header scroll={handleDrawer} />
      </Drawer>
    </div>
  )
}

export default LandingPage
