// import { useRFPVotingContext } from 'features/RFP/RFPVoting/Providers/RFPVotingContextProvider'
import RFPSurvey from 'features/RFP/RFPSurvey/RFPSurvey'

const SurveyContent = () => {
  // const rfpVotingContext = useRFPVotingContext()

  return <RFPSurvey />
}

export default SurveyContent
