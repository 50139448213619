import { useState } from 'react'
import { Forms } from 'components/Forms'
import SignPopup from 'features/SignPopup/SignPopup'

const FANumber = (): JSX.Element => {
  const [email, setEmail] = useState('')
  const emailChange = (e) => setEmail(e.target.value)
  return (
    <SignPopup email={email}>
      <Forms.FANumber emailChange={emailChange} />
    </SignPopup>
  )
}

export default FANumber
