import { IUser } from 'redux/store/user/types'
import { PrsStatusUnion } from '../../../pages/ProductRequests/types'

export const isOwnerCondition = (user: IUser) => ({
  creator: user.uuid
})

export const isInStatuses = (inStatuses: PrsStatusUnion[]) => ({
  status: {
    $in: inStatuses
  }
})
export const isInTrialStatuses = (inStatuses: PrsStatusUnion[]) => ({
  'trial.status': {
    $in: inStatuses
  }
})

export const isCommunityResponsible = (user: IUser) => ({
  'community_responsible.uuid': user.uuid
})
export const isClinicalSponsor = (user: IUser) => ({
  'clinical_sponsor_stakeholder.uuid': user.uuid
})
export const isRcaResponsible = (user: IUser) => ({
  'rca.uuid': user.uuid
})
export const RcaStatusUnion = (inStatuses: PrsStatusUnion[]) => ({
  rca_decision: {
    $in: inStatuses
  }
})
