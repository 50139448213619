import cn from 'classnames'
import { FieldWithLabel } from 'components/FieldWithLabel/FieldWithLabel'
import { Attachment } from 'components/Attachment/Attachment'
import { Separator } from 'components/Separator/Separator'
import { fileNameFormatter } from 'helper/common'
import { TAttachmentsListProps } from '../types'
import './styles.scss'

const Default = ({
  className,
  files,
  label,
  disableActions,
  onDelete,
  hideSeparator,
  names,
  icon,
  withCreatedAt
}: TAttachmentsListProps) => {
  const deleteHandler = (uuid: string) => {
    if (onDelete) {
      onDelete(uuid)
    }
  }
  return (
    <div className={cn('attachments-list', className)}>
      <FieldWithLabel title={label}>
        <ul className="attachments-list__files">
          {files.map((i, idx) => (
            <li key={i.uuid}>
              <Attachment
                filename={
                  !!names?.length ? names[idx] : fileNameFormatter(i.file)
                }
                icon={icon}
                link={i.file}
                showDetails={withCreatedAt}
                createdAt={withCreatedAt ? i.created_at : undefined}
                disableActions={disableActions || i.isCannotBeDeleted}
                onDelete={() => deleteHandler(i.uuid)}
              />
            </li>
          ))}
        </ul>
      </FieldWithLabel>
      {!hideSeparator && (
        <>{!!files.length && !disableActions && <Separator />}</>
      )}
    </div>
  )
}

export default Default
