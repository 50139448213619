import { State } from 'redux/type'
import { IUserState, IUser } from './types'
import { moduleName } from './constants'

const getUserState = (state: State): IUserState => state[moduleName]

export const getUser = (state: State): IUser => {
  const userState = getUserState(state)
  return userState.user
}
export const getToken = (state: State): string | null => {
  const userState = getUserState(state)
  return userState.token
}
export const getError = (state: State): any | null => {
  const userState = getUserState(state)
  return userState.error
}
export const getUserIsLoaded = (state: State): boolean => {
  const userState = getUserState(state)
  return userState.isUserLoaded
}
export const getUserIsLoading = (state: State): boolean => {
  const userState = getUserState(state)
  return userState.isUserLoading
}
