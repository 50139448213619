import { Form, RadioChangeEvent, Space } from 'antd'
import Typography from 'components/Typography/Typography'
import Radio from 'antd/lib/radio'
import { TYPOGRAPHY_WEIGHT } from 'components/Typography'
import { TClinicalReviewItemProps } from './types'
import classNames from 'classnames'
import { LED_BY_LABEL } from '../../../components/LeadByIndicator/constants'

const ClinicalReviewItem = ({
  onChange,
  label,
  vendors,
  led_by,
  uuid,
  value,
  hasError
}: TClinicalReviewItemProps) => {
  return (
    <Form.Item
      label={label}
      colon={false}
      className={classNames('clinical-review-form-container__item', {
        error: hasError
      })}
    >
      <div className="clinical-review-form-container__item__assigned mt-4 gap-16 row">
        <div className="row clinical-review-form-container__item__assigned__row vendors">
          <Typography.Body2 className="clinical-review-form-container__item__assigned__row__role">
            Vendor(s):&nbsp;
          </Typography.Body2>
          <Typography.Body2>{vendors}</Typography.Body2>
        </div>
        <div className="row clinical-review-form-container__item__assigned__row">
          <Typography.Body2 className="clinical-review-form-container__item__assigned__row__role">
            Led by:&nbsp;
          </Typography.Body2>
          <Typography.Body2>{LED_BY_LABEL[led_by]}</Typography.Body2>
        </div>
      </div>
      <Radio.Group
        onChange={(e: RadioChangeEvent) => onChange(e, uuid)}
        value={value}
        className="clinical-review-form-container__item__value mt-12"
      >
        <Space direction="vertical">
          <Radio value={'accepted'}>
            <Typography.Headline6 weight={TYPOGRAPHY_WEIGHT.NORMAL}>
              Accept
            </Typography.Headline6>
          </Radio>
          <Radio value={'declined'}>
            <Typography.Headline6 weight={TYPOGRAPHY_WEIGHT.NORMAL}>
              Decline
            </Typography.Headline6>
          </Radio>
        </Space>
      </Radio.Group>
      {hasError && (
        <Typography.Caption className={'error-message mt-8'}>
          One of the options must be selected
        </Typography.Caption>
      )}
    </Form.Item>
  )
}

export default ClinicalReviewItem
