import { useCallback, useMemo } from 'react'
import { subject } from '@casl/ability'
import { Button, BUTTON_TYPES } from 'components/Button'
import { SendRFPAction } from 'features/RFP/RFPDetails/Actions/SendRFP'
import { SendSurveyAction } from 'features/RFP/RFPDetails/Actions/SendSurvey'
import { AwardVendorsAction } from 'features/RFP/RFPDetails/Actions/AwardVendors'
import { StartVendorsSelectionAction } from 'features/RFP/RFPDetails/Actions/StartVendorsSelection'
import { useRFPDetailsContext } from 'features/RFP/RFPDetails/Providers/RFPDetailsContextProvider'
import { useRFPDetailsPopupContext } from 'features/RFP/RFPDetails/Providers/RFPDetailsPopupProvider'
import { ACTIONS, Can, SUBJECTS } from 'features/Permission'
import { STATUS_SCHEME, STATUS_SCHEME_CLC } from './constants'
import { BTN_TXT } from 'constants/txt'
import { RFP_STATUSES } from 'features/RFP/constants'
import SendToCommonsAction from './SendToCommons/SendToCommonsAction'

export const Actions = () => {
  const rfpDetailsContext = useRFPDetailsContext()
  const { cancelRFPPopup, sendRFPPopup } = useRFPDetailsPopupContext()

  const { data } = rfpDetailsContext.state
  const { checkIsDeadlineMissed, handleChangeRFPStatus } =
    rfpDetailsContext.actions
  const { open } = cancelRFPPopup.actions

  const handleChangeAction = useCallback(() => {
    if (checkIsDeadlineMissed()) {
      sendRFPPopup.actions.open()
      return
    }
    if (data.participation_type === 'clinically_led_self') {
      handleChangeRFPStatus(STATUS_SCHEME[data.status].next)
    } else {
      handleChangeRFPStatus(STATUS_SCHEME_CLC[data.status].next)
    }
  }, [
    checkIsDeadlineMissed,
    data.status,
    data.participation_type,
    handleChangeRFPStatus,
    sendRFPPopup.actions
  ])
  const actionBtnText = useMemo(
    () => STATUS_SCHEME[data.status]?.actionBtnText,
    [data]
  )
  const hideActions = useMemo(
    () =>
      data.status === RFP_STATUSES.CANCELED ||
      data.status === RFP_STATUSES.ARCHIVED,
    [data]
  )

  const renderStatusAction = useCallback(() => {
    switch (data.status) {
      case RFP_STATUSES.CREATED:
        return <SendRFPAction />
      case RFP_STATUSES.IN_CS_QUEUE:
        return <SendRFPAction />
      case RFP_STATUSES.PRICE_FILES_ANALYSIS:
        return <SendSurveyAction />
      case RFP_STATUSES.VOTING_IN_PROGRESS:
        return <StartVendorsSelectionAction />
      case RFP_STATUSES.VENDORS_SELECTION:
        return <AwardVendorsAction />
      default:
        return data.status && actionBtnText ? (
          <Can I={ACTIONS.EDIT} a={subject(SUBJECTS.RFP, { ...data })}>
            <Button
              onClick={handleChangeAction}
              type={BUTTON_TYPES.DEFAULT}
              upperCase
            >
              {actionBtnText}
            </Button>
          </Can>
        ) : null
    }
  }, [actionBtnText, data, handleChangeAction])

  return hideActions ? null : (
    <>
      <Can I={ACTIONS.CANCEL} a={subject(SUBJECTS.RFP, { ...data })}>
        <Button type={BUTTON_TYPES.DEFAULT} onClick={open} danger upperCase>
          {BTN_TXT.CANCEL_RFP}
        </Button>
      </Can>
      <SendToCommonsAction />
      {renderStatusAction()}
    </>
  )
}
