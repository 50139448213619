import { useMemo } from 'react'
import Typography from 'components/Typography/Typography'
import { ReactComponent as WarningIcon } from 'assets/svg/WarningIcon.svg'
import './styles.scss'

export type TProps = {
  usedNames: string[]
  search: string
}

function findCommonPart(inputString, vendorList) {
  if (
    vendorList.find((i) => i.toLowerCase().includes(inputString.toLowerCase()))
  ) {
    return inputString
  }
  return null
}

const WarningLabel = ({ usedNames, search }: TProps) => {
  const regex = useMemo(() => {
    return new RegExp(findCommonPart(search, usedNames), 'gi')
  }, [search, usedNames])

  return (
    <div className="warning-label-container row">
      <WarningIcon />
      <div className="warning-label-container__content">
        <Typography.Body2>
          Please make sure that you are creating a unique vendor
        </Typography.Body2>
        <div className="row">
          <Typography.Caption
            dangerouslySetInnerHTML={{
              __html:
                `Vendors with a similar name in the system: ${usedNames.map(
                  (i) => i
                )}.`.replaceAll(
                  regex,
                  (match) => `<span class="search-param">${match}</span>`
                )
            }}
          />
        </div>
      </div>
    </div>
  )
}

export default WarningLabel
