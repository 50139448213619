import { useRFPDetailsPopupContext } from '../../Providers/RFPDetailsPopupProvider'
import { useCallback, useEffect, useState } from 'react'
import { TResponsibleUser } from '../../types'
import { Popup } from '../../../../../components/Popup'
import { Button, BUTTON_TYPES } from '../../../../../components/Button'
import { BTN_TXT, PLACEHOLDERS } from '../../../../../constants'
import { Form } from 'antd'
import { FieldWithLabel } from '../../../../../components/FieldWithLabel/FieldWithLabel'
import { Select } from '../../../../../components/Select'
import { useDispatch, useSelector } from 'react-redux'
import { getIsLoading } from '../../../../../redux/store/common/getters'
import { useRFPDetailsContext } from '../../Providers/RFPDetailsContextProvider'
import { useRFPResponsibleContext } from '../Providers/RFPResponsibleContextProvider'
import { setLoading } from '../../../../../redux/store/common/slice'
import { getAvailableResponsibleCommonsUsersRequestAsync } from '../../api'
import { returnSelectUsers } from './helper'
import { getUser } from '../../../../../redux/store/user/getters'
import { IPartialUserInfo } from '../../../../../redux/store/user/types'
import Typography from '../../../../../components/Typography/Typography'

const AddCommonsResponsibleModal = () => {
  const currenUser = useSelector(getUser)
  const { addResponsibleCommonsUserPopup } = useRFPDetailsPopupContext()
  const [selectedlUuid, setSelectedUuid] = useState<
    TResponsibleUser['uuid'] | null
  >(null)
  const { visible, onCancel, title } = addResponsibleCommonsUserPopup.state
  const { close } = addResponsibleCommonsUserPopup.actions
  const dispatch = useDispatch()
  const isLoading = useSelector(getIsLoading)

  const rfpDetailsContext = useRFPDetailsContext()
  const rfpResponsibleContext = useRFPResponsibleContext()

  const { data } = rfpDetailsContext.state
  const { addResponsibleUserAsync } = rfpResponsibleContext.actions
  const [filteredOptions, setFilteredOptions] = useState<IPartialUserInfo[]>([])

  const [availableUsers, setAvailableUsers] = useState<IPartialUserInfo[]>([])

  const getAvailableResponsibleUsersAsync = useCallback(async () => {
    if (!data.uuid) {
      throw new Error('RFP ID not provided')
    }

    try {
      dispatch(setLoading(true))

      const response = await getAvailableResponsibleCommonsUsersRequestAsync(
        data.uuid
      )
      if (!!response?.data?.results?.length) {
        setFilteredOptions(response?.data?.results)
        setAvailableUsers(response?.data?.results)
      }
    } catch (e) {
      console.error(e)
    } finally {
      dispatch(setLoading(false))
    }
  }, [data.uuid, dispatch])

  const handleFinishForm = useCallback(() => {
    if (!selectedlUuid) return
    addResponsibleUserAsync([selectedlUuid], true)
  }, [addResponsibleUserAsync, selectedlUuid])

  useEffect(() => {
    getAvailableResponsibleUsersAsync()
  }, [data.uuid])

  const handleSearch = (searchText) => {
    const filteredUsers: IPartialUserInfo[] = availableUsers?.filter(
      (option) => {
        const fullName =
          `${option.first_name} ${option.last_name}`.toLowerCase()
        return fullName.includes(searchText.toLowerCase())
      }
    )
    setFilteredOptions(filteredUsers || [])
  }

  return (
    <Popup
      visible={visible}
      onCancel={onCancel}
      className="responsible-popup"
      title={title}
      width={442}
      footer={
        <>
          <Button type={BUTTON_TYPES.DEFAULT} onClick={close} upperCase>
            {BTN_TXT.CANCEL}
          </Button>
          <Button
            htmlType="submit"
            form="rfp-add-responsible"
            type={BUTTON_TYPES.PRIMARY}
            upperCase
            disabled={!selectedlUuid}
          >
            {BTN_TXT.ADD}
          </Button>
        </>
      }
    >
      <Form
        id="rfp-add-responsible"
        className="rfp-details-add-responsible"
        onFinish={handleFinishForm}
      >
        <Typography.Body1>
          Status will be changed as soon as responsible user is assigned.
        </Typography.Body1>
        {!isLoading && (
          <>
            <FieldWithLabel title={`Responsible User`}>
              <Select
                options={returnSelectUsers(filteredOptions, currenUser.uuid)}
                propsSelect={{
                  value: selectedlUuid,
                  placeholder: PLACEHOLDERS.PLEASE_SELECT,
                  onChange: (val) =>
                    typeof val === 'string'
                      ? setSelectedUuid(val)
                      : setSelectedUuid(val.value),
                  filterOption: false,
                  showSearch: true,
                  labelInValue: true,
                  onSearch: handleSearch
                }}
              />
            </FieldWithLabel>
          </>
        )}
      </Form>
    </Popup>
  )
}
export default AddCommonsResponsibleModal
