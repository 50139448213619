import {
  createContext,
  FC,
  PropsWithChildren,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState
} from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  getCheckIns,
  getIsLoading,
  getSearch,
  getTotal
} from 'redux/store/checkIns/getters'
import useInfiniteData, {
  UseInfiniteDataHookResult
} from 'hooks/useInfiniteData'
import {
  ICheckInsState,
  ICheckInWithHeaderCondition,
  TCheckInsFilters
} from 'redux/store/checkIns/types'
import { getCheckInsListActionAsync } from '../../../redux/store/checkIns/actions'
import { loading } from '../../../redux/store/checkIns/slice'
import { useParams } from 'react-router-dom'
import { setLoading } from '../../../redux/store/common/slice'
import { notification } from '../../../components/Notification'
import {
  getCheckInsDetailsRequestAsync,
  getCheckInsVendorInfoRequestAsync
} from '../api'
import { TCheckInsDetails } from './types'

type ContextProps = {
  state: {
    hasNextPage: boolean
    isLoading: boolean
    filters: TCheckInsFilters
    isLoadingPrevCheckIns: boolean
    checkInsDetails: TCheckInsDetails | null
    prevCheckIns: UseInfiniteDataHookResult
  } & Pick<ICheckInsState, 'checkIns' | 'search'>
  actions: {
    handleLoadMore: VoidFunction
    setSearch: (value: string) => void
    resetFilters: () => void
    applyFilters: (filters: TCheckInsFilters) => void
  }
}

export const filtersInitialState = {
  health_systems: [],
  hospitals: [],
  departments: [],
  vendors: [],
  visitors: []
}

const CheckInsContext = createContext<ContextProps>({
  state: null!,
  actions: null!
})

const CheckInsContextProvider: FC<PropsWithChildren> = ({ children }) => {
  const dispatch = useDispatch<any>()
  const { id } = useParams()
  const _search = useSelector(getSearch)
  const _checkIns = useSelector(getCheckIns)
  const total = useSelector(getTotal)
  const isLoading = useSelector(getIsLoading)

  const [search, setSearch] = useState<string | null>(_search)
  const [filters, setFilters] = useState<TCheckInsFilters>(filtersInitialState)
  const [checkIns, setCheckIns] =
    useState<ICheckInWithHeaderCondition[]>(_checkIns)
  const [checkInsDetails, setCheckInsDetails] =
    useState<TCheckInsDetails | null>(null)
  const [totalPrevCheckIns, setTotalPrevCheckIns] = useState<number>(0)
  const [isLoadingPrevCheckIns, setIsLoadingPrevCheckIns] =
    useState<boolean>(false)

  const timeoutRef = useRef<NodeJS.Timeout>()

  const loadMore = useCallback(
    async (args) => {
      await dispatch(getCheckInsListActionAsync({ ...args }))
    },
    [dispatch]
  )

  const loadMorePrevCheckIns = useCallback(
    async (args) => {
      if (!checkInsDetails) return
      setIsLoadingPrevCheckIns(true)
      const results = await getCheckInsDetailsRequestAsync({ ...args, id: id })
      setIsLoadingPrevCheckIns(false)

      setCheckInsDetails({
        info: checkInsDetails?.info,
        list: [...(checkInsDetails?.list || []), ...results.data.results]
      })
    },
    [dispatch, checkInsDetails, id]
  )

  const { limit, hasNextPage, handleLoadMore, reset } = useInfiniteData({
    total,
    loadMore
  })
  const prevCheckIns = useInfiniteData({
    total: totalPrevCheckIns,
    limit: 20,
    loadMore: loadMorePrevCheckIns
  })

  const getCheckInsDetails = useCallback(async () => {
    if (!id) {
      throw new Error('check ins ID not provided')
    }
    try {
      dispatch(setLoading(true))
      const results = await Promise.all([
        getCheckInsDetailsRequestAsync({ id: id, limit: 20, offset: 0 }),
        getCheckInsVendorInfoRequestAsync(id)
      ])
      setCheckInsDetails({
        info: results[1].data,
        list: results[0]?.data?.results
      })
      setTotalPrevCheckIns(results[0]?.data?.count)
    } catch (e: any) {
      console.error(e)
      notification.error({ message: e?.data.details })
    } finally {
      dispatch(setLoading(false))
    }
  }, [id])

  useEffect(() => {
    if (id) getCheckInsDetails()
  }, [id])

  const applyFilters = useCallback(
    (filters: TCheckInsFilters) => {
      setFilters(filters)
      dispatch(
        getCheckInsListActionAsync({
          limit,
          search: search || undefined,
          ...filters
        })
      )
    },
    [filters, search, limit, dispatch]
  )

  const resetFilters = useCallback(() => {
    applyFilters(filtersInitialState)
  }, [filtersInitialState, search, limit])

  useEffect(() => {
    return setSearch('')
  }, [])

  useEffect(() => {
    dispatch(loading())
  }, [search])

  const _listenSearchChange = useCallback(() => {
    dispatch(
      getCheckInsListActionAsync({
        limit,
        search: search || undefined,
        ...filters
      })
    )
  }, [dispatch, limit, search])

  useEffect(() => {
    setCheckIns(_checkIns)
  }, [_checkIns])

  useEffect(() => {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current)
    }
    timeoutRef.current = setTimeout(_listenSearchChange, 500)
    reset()
    setCheckIns([])
  }, [_listenSearchChange, reset])

  const context = useMemo(
    () => ({
      state: {
        search,
        checkIns,
        hasNextPage,
        isLoading,
        checkInsDetails,
        prevCheckIns,
        isLoadingPrevCheckIns,
        filters
      },
      actions: {
        handleLoadMore,
        setSearch,
        resetFilters,
        applyFilters
      }
    }),
    [
      search,
      checkIns,
      hasNextPage,
      handleLoadMore,
      setSearch,
      isLoading,
      checkInsDetails,
      prevCheckIns,
      isLoadingPrevCheckIns,
      filters,
      resetFilters,
      applyFilters
    ]
  )

  return (
    <CheckInsContext.Provider value={context}>
      {children}
    </CheckInsContext.Provider>
  )
}

export const useCheckInsListContext = () => useContext(CheckInsContext)

export default CheckInsContextProvider
