import {
  PASSWORD_STRENGTH,
  specialChars
} from '../components/PasswordStrength/constants'

export const definePasswordStrength = (value: string) => {
  if (!value.length) {
    return PASSWORD_STRENGTH.EMPTY
  }

  let score = 0

  if (value.length >= 8) {
    score += 1
  }

  if (value !== value.toLowerCase() && value !== value.toUpperCase()) {
    score += 2
  } else {
    score += 1
  }

  if (/\d/.test(value)) {
    score += 1
  }

  if (specialChars.test(value)) {
    score += 1
  }

  if (score <= 2) {
    return PASSWORD_STRENGTH.WEAK
  }

  if (score > 2 && score < 5) {
    return PASSWORD_STRENGTH.MEDIUM
  }

  return PASSWORD_STRENGTH.STRONG
}

export const capitalizeFirstLetter = (text: string) => {
  return text.charAt(0).toUpperCase() + text.slice(1)
}

export const isDeepEqualObjects = (object1: Object, object2: Object) => {
  if (Object.keys(object1).length !== Object.keys(object2).length) return false

  return Object.keys(object1).reduce((res, key) => {
    return res && object1[key] === object2[key]
  }, true)
}
