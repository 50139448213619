import { subject } from '@casl/ability'
import { useAbility } from '@casl/react'

import { BTN_TXT } from 'constants/txt'
import { Button, BUTTON_TYPES } from 'components/Button'
import { ReactComponent as Plus16 } from 'assets/svg/Plus16.svg'
import { useRFPDetailsContext } from 'features/RFP/RFPDetails/Providers/RFPDetailsContextProvider'
import { AbilityContext, ACTIONS, SUBJECTS } from 'features/Permission'
import { useRFPDetailsPopupContext } from 'features/RFP/RFPDetails/Providers/RFPDetailsPopupProvider'
import { RFP_STATUSES } from 'features/RFP/constants'
import { RFP_DETAILS_TABS } from 'features/RFP/RFPDetails/constants'
import { useRFPResponsibleContext } from 'features/RFP/RFPDetails/ResponsibleForRFP/Providers/RFPResponsibleContextProvider'

const generalTab = RFP_DETAILS_TABS[0].key
const biddingTab = RFP_DETAILS_TABS[6].key

export default ({ isCommons }: { isCommons?: boolean }) => {
  const ability = useAbility<any>(AbilityContext)

  const rfpDetailsContext = useRFPDetailsContext()
  const rfpResponsibleContext = useRFPResponsibleContext()
  const { addResponsibleUserPopup } = useRFPDetailsPopupContext()

  const { data, activeTab, isVendor, isVendorCanSeeAddResponsibleOnBidding } =
    rfpDetailsContext.state
  const { isAllResponsibleUsersAdded } = rfpResponsibleContext.state
  const { open } = addResponsibleUserPopup.actions

  const rfpSubject = {
    ...data,
    vendor_responsibles: data.vendor_contract_steward
      ? [data.vendor_contract_steward, ...(data.vendor_responsibles || [])]
      : data.vendor_responsibles || []
  }

  const isCanAddResponsible =
    (isVendor
      ? isVendorCanSeeAddResponsibleOnBidding
      : !isCommons
      ? ability.can(
          ACTIONS.CREATE,
          subject(SUBJECTS.RESPONSIBLE_USER, isCommons ? data : rfpSubject)
        )
      : ability.can(
          ACTIONS.CREATE,
          subject(SUBJECTS.COMMONS_RESPONSIBLE_USERS, data)
        )) &&
    data.status !== RFP_STATUSES.ARCHIVED &&
    data.status !== RFP_STATUSES.CANCELED &&
    !!data.status.length &&
    (isVendor ? activeTab === biddingTab : activeTab === generalTab) &&
    !isAllResponsibleUsersAdded

  return isCanAddResponsible ? (
    <Button
      icon={<Plus16 />}
      type={BUTTON_TYPES.DEFAULT}
      onClick={open}
      upperCase
    >
      {isCommons ? BTN_TXT.ADD_RESPONSIBLE : BTN_TXT.ADD_TEAM_MEMBERS}
    </Button>
  ) : null
}
