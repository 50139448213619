import { ColumnsType } from 'antd/es/table'
import { Sorter } from 'components/Sorter/Sorter'
import { SortOrder } from 'antd/es/table/interface'
import { Dropdown } from 'components/Dropdown'
import { Status, USER_STATUS_TYPES_TO_CLASSNAME } from 'components/Status'
import { fullFormatPhoneNumber } from 'helper/common'
import { USER_STATUSES_OPTIONS } from 'constants/common'
import { ReactComponent as FilterApplied16 } from 'assets/svg/FilterApplied16.svg'
import { ReactComponent as Filter16 } from 'assets/svg/Filter16.svg'
import {
  TRFPAddVendorUser,
  TRFPVendorUsersColumnsFunc,
  TRFPVendorUsersFilters,
  TRFPVendorUsersFiltersFunc
} from '../types'

export const columns = ({
  applyFilters,
  filters
}: TRFPVendorUsersColumnsFunc): ColumnsType<any> => [
  {
    dataIndex: 'first_name',
    key: 'first_name',
    title: ({ sortOrder, sortColumn }) => (
      <Sorter<TRFPAddVendorUser>
        dataIndex="first_name"
        sortColumn={sortColumn}
        sortOrder={sortOrder as SortOrder}
      >
        Vendor Contract Steward
      </Sorter>
    ),
    onCell: (record) => ({
      colSpan: record.name ? 2 : 1
    }),
    render: (_, { first_name, last_name }) =>
      `${first_name}${last_name ? ` ${last_name}` : ''}`,
    sorter: true,
    showSorterTooltip: false
  },
  {
    dataIndex: 'status',
    key: 'status',
    title: ({ sortOrder, sortColumn }) => (
      <Sorter<TRFPAddVendorUser>
        dataIndex="status"
        sortColumn={sortColumn}
        sortOrder={sortOrder as SortOrder}
      >
        Status
      </Sorter>
    ),
    filterDropdown: (props) => (
      <Dropdown.Filter<
        TRFPVendorUsersFilters,
        TRFPVendorUsersFiltersFunc,
        unknown
      >
        items={USER_STATUSES_OPTIONS}
        applyFilters={applyFilters}
        appliedFilters={filters}
        field="statuses"
        {...props}
      />
    ),
    render: (_, { status }) => (
      <Status.Default className={USER_STATUS_TYPES_TO_CLASSNAME[status]}>
        {status}
      </Status.Default>
    ),
    filterIcon: !!filters?.statuses?.length ? (
      <FilterApplied16 />
    ) : (
      <Filter16 />
    ),
    filterSearch: true,
    sorter: true,
    showSorterTooltip: false
  },
  {
    dataIndex: 'email',
    key: 'email',
    title: ({ sortOrder, sortColumn }) => (
      <Sorter<TRFPAddVendorUser>
        dataIndex="email"
        sortColumn={sortColumn}
        sortOrder={sortOrder as SortOrder}
      >
        Email
      </Sorter>
    ),
    sorter: true,
    showSorterTooltip: false
  },
  {
    dataIndex: 'phone_number',
    key: 'phone_number',
    title: ({ sortOrder, sortColumn }) => (
      <Sorter<TRFPAddVendorUser>
        dataIndex="phone_number"
        sortColumn={sortColumn}
        sortOrder={sortOrder as SortOrder}
      >
        Phone number
      </Sorter>
    ),
    render: (_, { phone_number }) => fullFormatPhoneNumber(phone_number),
    sorter: true,
    showSorterTooltip: false
  }
]
