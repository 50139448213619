import { Navigate, Route, Routes } from 'react-router-dom'
import { invoiceDataRoutes } from './routes'
import { INVOICE_DATA_TABS } from '../constants'

export const InvoiceDataRouter = () => (
  <Routes>
    {invoiceDataRoutes.map((route, index) => (
      <Route key={index} path={route.path} element={<route.component />} />
    ))}
    <Route index element={<Navigate to={INVOICE_DATA_TABS[0].key} replace />} />
  </Routes>
)
