import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router'
import { Popup } from '../../../components/Popup'
import { Button, BUTTON_TYPES } from '../../../components/Button'
import { Typography } from '../../../components/Typography'
import { deleteChatAction } from '../../../redux/store/chats/actions'
import { useChatPopup } from '../Providers/ChatPopupProvider'
import { useChatContext } from '../Providers/ChatContextProvider'
import { BTN_TXT } from '../../../constants'
import { routes } from '../../../router'

export const DeleteModal = () => {
  const navigate = useNavigate()
  const dispatch = useDispatch<any>()
  const { deleteModal } = useChatPopup()
  const { state } = useChatContext()

  const onDeleteChat = () => {
    if (state.chat?.uuid) {
      dispatch(deleteChatAction(state.chat))
      navigate(`${routes.chat}`, { replace: true })
      deleteModal.actions.close()
    }
  }

  return (
    <Popup
      title="Delete chat?"
      onCancel={deleteModal.actions.close}
      visible={deleteModal.state.visible}
      width={442}
      zIndex={1000}
      footer={
        <>
          <Button
            type={BUTTON_TYPES.DEFAULT}
            onClick={deleteModal.actions.close}
            upperCase
          >
            {BTN_TXT.CANCEL}
          </Button>
          <Button
            type={BUTTON_TYPES.PRIMARY}
            onClick={onDeleteChat}
            upperCase
            danger
          >
            {BTN_TXT.DELETE_CHAT}
          </Button>
        </>
      }
    >
      <Typography.Body1>
        {state.chat?.type === 'private'
          ? `Are you sure you want to delete this chat? All messages will be gone, but you can start a new chat anytime without the history.`
          : `Are you sure you want to delete this group chat? This action cannot be undone, and all messages will be permanently deleted for all participants.`}
      </Typography.Body1>
    </Popup>
  )
}
