import Fetcher from '../../services/Fetcher'
import { API_DICTIONARY, HTTP_METHODS } from '../../constants'
import {
  TClinicalReviewDataListResponse,
  TClinicalReviewDecisionRequest
} from './types'

const fetcher = new Fetcher({})

export const getClinicalReview = async () =>
  fetcher.request<unknown, TClinicalReviewDataListResponse>({
    url: `${API_DICTIONARY.ANALYSIS}${API_DICTIONARY.STAKEHOLDER_REVIEW}`,
    method: HTTP_METHODS.GET
  })

export const setClinicalReview = async (data: TClinicalReviewDecisionRequest) =>
  fetcher.request<
    TClinicalReviewDecisionRequest,
    TClinicalReviewDataListResponse
  >({
    url: `${API_DICTIONARY.ANALYSIS}${API_DICTIONARY.STAKEHOLDER_REVIEW}`,
    method: HTTP_METHODS.POST,
    data: data
  })
