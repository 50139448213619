import cn from 'classnames'
import { Steps as StepsAntd } from 'antd'
import { TSteps } from './types'
import './styles.scss'

const { Step } = StepsAntd

export const Steps = ({ className, steps, ...props }: TSteps) => (
  <StepsAntd className={cn('custom-steps', className)} progressDot {...props}>
    {steps.map((step) => (
      <Step {...step} key={step.description} />
    ))}
  </StepsAntd>
)
