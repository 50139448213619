import { useCallback } from 'react'

import 'features/ContractDetails/ContractDetailsGeneral/ResponsibleUsers/DeleteUserPopup/styles.scss'
import { BTN_TXT } from 'constants/txt'
import { Popup } from 'components/Popup'
import { Typography } from 'components/Typography'
import { Button, BUTTON_TYPES } from 'components/Button'
import { useContractDetailsPopup } from 'features/ContractDetails/Providers/ContractDetailsPopupProvider'
import { useContractResponsibleContext } from 'features/ContractDetails/ContractDetailsGeneral/ResponsibleUsers/Providers/ContractResponsibleContextProvider'

export default () => {
  const { deleteResponsibleUserPopup } = useContractDetailsPopup()
  const contractResponsibleContext = useContractResponsibleContext()

  const { visible, onCancel, title, payload } = deleteResponsibleUserPopup.state
  const { close } = deleteResponsibleUserPopup.actions

  const { deleteResponsibleUserAsync } = contractResponsibleContext.actions

  const handleDeleteResponsible = useCallback(() => {
    if (payload.user?.uuid) {
      deleteResponsibleUserAsync(payload.user?.uuid)
    }
  }, [deleteResponsibleUserAsync, payload.user?.uuid])

  return (
    <Popup
      visible={visible}
      onCancel={onCancel}
      title={title}
      width={442}
      footer={
        <>
          <Button type={BUTTON_TYPES.DEFAULT} onClick={close} upperCase>
            {BTN_TXT.CANCEL}
          </Button>
          <Button
            type={BUTTON_TYPES.PRIMARY}
            onClick={handleDeleteResponsible}
            upperCase
            danger
          >
            {BTN_TXT.DELETE_FROM_CONTRACT}
          </Button>
        </>
      }
    >
      <Typography.Body1>
        {`${payload.user?.first_name} ${payload.user?.last_name} is team member for the contract. Do you really want to delete user from the contract?`}
      </Typography.Body1>
    </Popup>
  )
}
