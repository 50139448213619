import {
  FC,
  createContext,
  PropsWithChildren,
  useContext,
  useMemo
} from 'react'

import useDrawer, { DrawerReturnTypeWithPayload } from 'hooks/useDrawer'
import { TChatTypeUnion } from 'constants/types'
import usePopup, { PopupReturnType } from '../../../hooks/usePopup'

type ContextProps = {
  deleteModal: PopupReturnType
  sidebarDrawer: DrawerReturnTypeWithPayload<{
    type: TChatTypeUnion
  }>
}

const basePopup = (title: string) => ({
  title
})

const ChatPopupContext = createContext<ContextProps>({
  sidebarDrawer: null!,
  deleteModal: null!
})

const ChatPopupProvider: FC<PropsWithChildren> = ({ children }) => {
  const sidebarDrawerProps = useDrawer<{
    type: TChatTypeUnion
  }>(basePopup('Chat details'))
  const deletePopupProps = usePopup(basePopup('Delete chat?'))

  const context = useMemo(
    () => ({
      sidebarDrawer: sidebarDrawerProps,
      deleteModal: deletePopupProps
    }),
    [sidebarDrawerProps, deletePopupProps]
  )

  return (
    <ChatPopupContext.Provider value={context}>
      {children}
    </ChatPopupContext.Provider>
  )
}

export const useChatPopup = () => useContext(ChatPopupContext)

export default ChatPopupProvider
