import { Providers } from 'features/VendorDetails'

import Container from 'features/VendorDetails/Container/Container'
import General from 'features/VendorDetails/General/General'

const VendorDetails = () => (
  <Providers.AbilityProvider>
    <Providers.ContextProvider>
      <Container>
        <General />
      </Container>
    </Providers.ContextProvider>
  </Providers.AbilityProvider>
)

export default VendorDetails
