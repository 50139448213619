import { FC } from 'react'
import cn from 'classnames'
import Typography from '../Typography/Typography'
import { ReactComponent as ErrorIcon } from 'assets/svg/ErrorIcon.svg'
import { BannerProps } from './types'
import './banner.error.scss'

const Error: FC<BannerProps> = ({ text, className }) => (
  <div className={cn('error-banner row gap-12 align-center', className)}>
    <ErrorIcon />
    <Typography.Body1 className="error-banner__content__text">
      {text}
    </Typography.Body1>
  </div>
)

export default Error
