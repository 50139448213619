import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { Routes, Route, Navigate, useParams } from 'react-router-dom'
import { ItemType } from 'antd/lib/menu/hooks/useItems'
import { Container } from 'components/Container/Container'
import { UserProfile } from 'features/UserProfile/UserProfile'
import { CommunityInfo } from 'features/ChapterInfo/ChapterInfo'
import { CommunityUsers } from 'features/ChapterUsers/ChapterUsers'
import { HealthSystems } from 'features/HealthSystems/HealthSystems'
import { VendorInfo } from 'features/VendorInfo/VendorInfo'
import { HealthSystemDetails } from 'features/HealthSystemDetails/HealthSystemDetails'
import { Menu } from 'components/Menu/Menu'
import { getUser } from 'redux/store/user/getters'
import useRouter from 'hooks/useRouter'
import { ACTIONS, ability, updateAbility } from 'features/Permission'
import { SETTINGS_TABS } from './constants'
import { safeString } from '../../helper/common'
import { getVendorInfo } from '../../redux/store/vendorInfo/getters'

const Settings = () => {
  const [tabs, setTabs] = useState<ItemType[]>([])
  const { tab } = useParams()
  const [activeTab, setActiveTab] = useState(tab || SETTINGS_TABS[0].key)
  const user = useSelector(getUser)
  const { push } = useRouter()
  const vendorInfo = useSelector(getVendorInfo)

  useEffect(() => {
    if (user.uuid) {
      updateAbility(ability, user, vendorInfo)
      setTabs(
        SETTINGS_TABS.map((i) => ({
          ...i,
          danger: ability.cannot(ACTIONS.VIEW, i.label)
        }))
      )
    }
  }, [user.uuid, ability, vendorInfo])

  useEffect(() => {
    setActiveTab(safeString(tab))
  }, [tab])
  return (
    <Container
      className="settings"
      title="Settings"
      menu={
        <Menu
          mode="horizontal"
          onSelect={(e) => {
            setActiveTab(e.key)
            push(e.key)
          }}
          selectedKeys={[activeTab]}
          defaultSelectedKeys={[activeTab]}
          items={tabs}
        />
      }
    >
      <Routes>
        <Route
          path={SETTINGS_TABS[0].key}
          element={<UserProfile setActiveTab={setActiveTab} />}
        />
        <Route path={SETTINGS_TABS[1].key} element={<CommunityInfo />} />
        <Route path={SETTINGS_TABS[2].key} element={<CommunityUsers />} />
        <Route path={SETTINGS_TABS[3].key} element={<HealthSystems />} />
        <Route path={SETTINGS_TABS[4].key} element={<VendorInfo />} />
        <Route path={SETTINGS_TABS[5].key} element={<HealthSystemDetails />} />
        <Route index element={<Navigate to={SETTINGS_TABS[0].key} replace />} />
      </Routes>
    </Container>
  )
}

export default Settings
