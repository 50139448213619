import {
  API_DICTIONARY,
  HTTP_METHODS,
  ResponseWithPagination,
  TOptionsResponse
} from '../../constants'
import Fetcher from '../../services/Fetcher'
import {
  TQueryLedByParams,
  TQueryContractCategoriesParams,
  TContractCategories
} from './types'
import { TCreateContractPipelineFormTypeRequest } from './CreateContractPipelineModal/types'

const fetcher = new Fetcher({})

export const getContractCategoriesOptions = (
  params: TQueryContractCategoriesParams
) =>
  fetcher.request<unknown, ResponseWithPagination<TContractCategories>>({
    url: `${API_DICTIONARY.CONTRACTS}${API_DICTIONARY.PIPELINES}${API_DICTIONARY.OPTIONS}${API_DICTIONARY.CONTRACT_CATEGORIES}`,
    method: HTTP_METHODS.GET,
    params
  })

export const getLedByOptions = (params: TQueryLedByParams) =>
  fetcher.request<unknown, TOptionsResponse[]>({
    url: `${API_DICTIONARY.CONTRACTS}${API_DICTIONARY.PIPELINES}${API_DICTIONARY.OPTIONS}${API_DICTIONARY.LED_BY}`,
    method: HTTP_METHODS.GET,
    params
  })

export const createContractPipeline = async (
  data: TCreateContractPipelineFormTypeRequest
) =>
  fetcher.request<TCreateContractPipelineFormTypeRequest, any>({
    url: `${API_DICTIONARY.CONTRACTS}${API_DICTIONARY.PIPELINES}`,
    method: HTTP_METHODS.POST,
    data
  })

export const editContractPipeline = async (
  id: string,
  data: TCreateContractPipelineFormTypeRequest
) =>
  fetcher.request<TCreateContractPipelineFormTypeRequest, any>({
    url: `${API_DICTIONARY.CONTRACTS}${API_DICTIONARY.PIPELINES}${id}/`,
    method: HTTP_METHODS.PUT,
    data
  })
