import * as Sentry from '@sentry/react'
import { createRoot } from 'react-dom/client'
import { Store } from 'redux'
import createStore from 'redux/createStore'
import App, { AppProps } from 'App'
import Logger, { severityLevels } from 'services/Logger'
import 'styles/styles.scss'
import 'antd/dist/antd.less'

const ENV = window.ENV || 'local'
const NODE_ENV = 'development'
const SENTRY_DSN = window.SENTRY_DSN || ''

if (NODE_ENV !== 'development') {
  Sentry.init({
    dsn: SENTRY_DSN,
    environment: ENV
  })
}

type RenderApp = (props: AppProps) => JSX.Element

export const store = createStore()

const AppRender = (RenderApp: RenderApp, appStore: Store) => {
  const logger = new Logger({ severityLevel: severityLevels.error })
  const root = createRoot(document.getElementById('root') as HTMLElement)
  root.render(<RenderApp store={appStore} logger={logger} />)
}

AppRender(App, store)
