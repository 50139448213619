import { Container } from 'components/Container/Container'
import { Compliance } from '../../features/Compliance/Compliance'
import './styles.scss'
import ComplianceContextContextProvider from './Providers/ComplianceContextProvider'

export const CompliancePage = () => {
  return (
    <ComplianceContextContextProvider>
      <Container title="Compliance">
        <Compliance />
      </Container>
    </ComplianceContextContextProvider>
  )
}
