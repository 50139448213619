import { useCallback, useEffect, useMemo, useState } from 'react'

import './styles.scss'
import { FieldWithLabel } from 'components/FieldWithLabel/FieldWithLabel'
import { Select } from 'components/Select'
import { PLACEHOLDERS } from 'constants/txt'
import { TOption } from 'components/Select/types'
import { TResponsibleUser } from 'features/RFP/RFPDetails/types'
import { useRFPDetailsContext } from 'features/RFP/RFPDetails/Providers/RFPDetailsContextProvider'
import { getAvailableResponsibleUsersRequestAsync } from 'features/RFP/RFPDetails/api'
import { useDispatch, useSelector } from 'react-redux'
import { setLoading } from 'redux/store/common/slice'
import { RFP_DETAILS_TABS } from 'features/RFP/RFPDetails/constants'
import { ROLES } from 'features/Permission'
import { Form } from 'antd'
import { useRFPResponsibleContext } from 'features/RFP/RFPDetails/ResponsibleForRFP/Providers/RFPResponsibleContextProvider'
import { getIsLoading } from 'redux/store/common/getters'

export const convertAvailableUsersToOptions = (
  availableUsers: TResponsibleUser[],
  filterByRole: TResponsibleUser['role']
): TOption[] =>
  availableUsers
    .filter((u) => u.role === filterByRole)
    .map((u) => ({
      label: u.first_name + ' ' + u.last_name,
      value: u.uuid
    }))

const generalTab = RFP_DETAILS_TABS[0].key
const biddingTab = RFP_DETAILS_TABS[6].key

const AddResponsibleForRFP = ({
  selectedLegalUuid,
  setSelectedLegal,
  selectedAnalystUuid,
  setSelectedAnalyst,
  isCommonsRFP
}: any) => {
  const dispatch = useDispatch()
  const isLoading = useSelector(getIsLoading)

  const rfpDetailsContext = useRFPDetailsContext()
  const rfpResponsibleContext = useRFPResponsibleContext()

  const { data, activeTab, isVendor, isCommons } = rfpDetailsContext.state
  const { responsibleUsers } = rfpResponsibleContext.state
  const { addResponsibleUserAsync } = rfpResponsibleContext.actions

  const [availableUsers, setAvailableUsers] = useState<TResponsibleUser[]>([])

  const updateState = (dispatch) => (value) => dispatch(value)

  const availableAnalystUsers = useMemo(() => {
    switch (activeTab) {
      case generalTab:
        return convertAvailableUsersToOptions(
          availableUsers,
          isCommons || isCommonsRFP ? ROLES.COMMONS_ANALYST : ROLES.ANALYST
        )
      case biddingTab:
        return convertAvailableUsersToOptions(
          availableUsers,
          ROLES.VENDOR_ANALYST
        )
      default:
        return []
    }
  }, [activeTab, availableUsers])

  const availableLegalUsers = useMemo(() => {
    switch (activeTab) {
      case generalTab:
        return convertAvailableUsersToOptions(
          availableUsers,
          isCommons || isCommonsRFP ? ROLES.COMMONS_LEGAL : ROLES.LEGAL
        )
      case biddingTab:
        return convertAvailableUsersToOptions(
          availableUsers,
          ROLES.VENDOR_LEGAL
        )
      default:
        return []
    }
  }, [activeTab, availableUsers])

  const getSelectedAvailableUsers = useCallback(() => {
    switch (activeTab) {
      case generalTab:
        setSelectedAnalyst(
          responsibleUsers.find(
            (u) =>
              u.role ===
              (isCommons || isCommonsRFP
                ? ROLES.COMMONS_ANALYST
                : ROLES.ANALYST)
          )?.uuid ?? null
        )
        setSelectedLegal(
          responsibleUsers.find(
            (u) =>
              u.role ===
              (isCommons || isCommonsRFP ? ROLES.COMMONS_LEGAL : ROLES.LEGAL)
          )?.uuid ?? null
        )
        break
      case biddingTab:
        setSelectedAnalyst(
          responsibleUsers.find((u) => u.role === ROLES.VENDOR_ANALYST)?.uuid ??
            null
        )
        setSelectedLegal(
          responsibleUsers.find((u) => u.role === ROLES.VENDOR_LEGAL)?.uuid ??
            null
        )
        break
      default:
        return []
    }
  }, [activeTab, responsibleUsers])

  const getAvailableResponsibleUsersAsync = useCallback(async () => {
    if (!data.uuid) {
      throw new Error('RFP ID not provided')
    }

    try {
      dispatch(setLoading(true))

      const response = await getAvailableResponsibleUsersRequestAsync(data.uuid)

      if (response?.data?.length > 0) {
        setAvailableUsers(response?.data)
      }
    } catch (e) {
      console.error(e)
    } finally {
      dispatch(setLoading(false))
    }
  }, [data.uuid, dispatch])

  const handleFinishForm = useCallback(() => {
    const responsibleUsers = [selectedAnalystUuid, selectedLegalUuid].filter(
      (i) => i !== null
    ) as Array<TResponsibleUser['uuid']>

    addResponsibleUserAsync(responsibleUsers)
  }, [addResponsibleUserAsync, selectedAnalystUuid, selectedLegalUuid])

  useEffect(() => {
    getAvailableResponsibleUsersAsync()
  }, [getAvailableResponsibleUsersAsync])

  useEffect(() => {
    getSelectedAvailableUsers()
  }, [getSelectedAvailableUsers])

  return (
    <Form
      id="rfp-add-responsible"
      className="rfp-details-add-responsible"
      onFinish={handleFinishForm}
    >
      {!isLoading && (
        <>
          <FieldWithLabel
            title={`${
              isVendor ? 'Vendor ' : isCommonsRFP ? 'Commons ' : ''
            }Analyst`}
          >
            <Select
              options={availableAnalystUsers}
              propsSelect={{
                value: selectedAnalystUuid,
                placeholder: PLACEHOLDERS.PLEASE_SELECT,
                onChange: updateState(setSelectedAnalyst)
              }}
            />
          </FieldWithLabel>
          <FieldWithLabel
            title={`${
              isVendor ? 'Vendor ' : isCommonsRFP ? 'Commons ' : ''
            }Legal`}
          >
            <Select
              options={availableLegalUsers}
              propsSelect={{
                value: selectedLegalUuid,
                placeholder: PLACEHOLDERS.PLEASE_SELECT,
                onChange: updateState(setSelectedLegal)
              }}
            />
          </FieldWithLabel>
        </>
      )}
    </Form>
  )
}

export default AddResponsibleForRFP
