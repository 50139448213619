import { FC, useMemo } from 'react'
import cn from 'classnames'

import './style.scss'
import { ILRenderItem } from 'components/InfiniteList/InfiniteList'
import { useSelector } from 'react-redux'
import { Avatar } from 'components/Avatar/Avatar'
import { ReactComponent as ArrowRight24 } from 'assets/svg/ArrowRight24.svg'
import Typography from 'components/Typography/Typography'
import { defaultDateFormat, defaultTimeFormat } from 'helper/dates'
import { TYPOGRAPHY_WEIGHT } from 'components/Typography'
import { IHistoryGoalsItem } from '../Providers/types'
import { getUser } from '../../../redux/store/user/getters'
import { routes } from '../../../router'
import { Link } from 'react-router-dom'
import { HISTORY_GOALS_TITLES } from './constants'
import { currencyFormatter } from '../../ContractDetails/ContractDetailsGeneral/ContractDetailsForm/utils'
import { getCapitalCharName } from '../../../helper/common'

const HistoryItem: FC<ILRenderItem<IHistoryGoalsItem>> = (props) => {
  const { item } = props
  const { uuid } = item

  const user = useSelector(getUser)
  const isCurrentUser = user.uuid === item?.user?.uuid

  const { changes } = item

  const mappedChanges = useMemo(() => {
    const fromValue = changes.before.reduce((acc, item) => {
      acc[item.year] = item.goal
      return acc
    }, {})
    const toValue = changes.after.reduce((acc, item) => {
      acc[item.year] = item.goal
      return acc
    }, {})
    const allYears = new Set([
      ...Object.keys(fromValue),
      ...Object.keys(toValue)
    ])

    return Array.from(allYears).map((year) => {
      return {
        fromValue: fromValue[year] || 0,
        toValue: toValue[year],
        year: year
      }
    })
  }, [changes])

  const capitalCharName = getCapitalCharName(
    item.user?.first_name,
    item.user?.last_name
  )

  return (
    <div
      className={cn('prs-history-item column')}
      key={uuid}
      data-testid="actualSavingHistoryItem"
    >
      <div className="row prs-history-item__acton-title">
        <Avatar
          name={capitalCharName}
          src={item?.user?.avatar?.file_preview || undefined}
          alt="user image"
        />
        <Link to={`${routes.createUserProfile}/${item?.user?.uuid}`}>
          <Typography.Body1
            className="prs-history-item__acton-title__user full-width"
            weight={TYPOGRAPHY_WEIGHT.SEMI_BOLD}
          >
            {isCurrentUser
              ? 'You'
              : `${item?.user?.first_name} ${item?.user?.last_name}`}
          </Typography.Body1>
        </Link>
        <Typography.Body1 weight={TYPOGRAPHY_WEIGHT.SEMI_BOLD}>
          &nbsp;{isCurrentUser ? 'have ' : 'has '}
          {HISTORY_GOALS_TITLES['saving_goal_changed']}
        </Typography.Body1>
      </div>
      <div className="prs-history-item__status-wrapper mt-8 column">
        {mappedChanges?.map((change) => {
          return (
            <div className="row gap-12 align-center">
              <Typography.Body1 weight={TYPOGRAPHY_WEIGHT.SEMI_BOLD}>
                {change.year}
              </Typography.Body1>
              <Typography.Body2>
                ${currencyFormatter(change.fromValue)}
              </Typography.Body2>
              <ArrowRight24 />
              <Typography.Body2>
                ${currencyFormatter(change.toValue)}
              </Typography.Body2>
            </div>
          )
        })}
      </div>
      <div className="row prs-history-item__date-wrapper mt-8 align-center">
        <Typography.Caption className="message-item-container__author__current-time">
          {defaultDateFormat(item.created_at)}
        </Typography.Caption>
        <Typography.Caption className="message-item-container__author__current-time">
          {defaultTimeFormat(item.created_at)}
        </Typography.Caption>
      </div>
    </div>
  )
}

export default HistoryItem
