import { Can } from '../Providers/PrsDetailsAbilityProvider'
import { ACTIONS, SUBJECTS } from '../../Abilities'
import { subject } from '@casl/ability'
import { Button, BUTTON_TYPES } from 'components/Button'
import { BTN_TXT } from 'constants/index'
import { Typography, TYPOGRAPHY_WEIGHT } from 'components/Typography'
import Popup from 'components/Popup/Popup'
import { usePRSDetailsPopupContext } from '../Providers/PRSDetailsPopupProvider'
import { usePRSDetailsContext } from '../Providers/PRSDetailsContextProvider'
import { PRS_RESPOND } from 'pages/ProductRequests/constants'
import { setLoading } from 'redux/store/common/slice'
import { respondPrs } from 'pages/ProductRequests/api'
import { notification } from 'components/Notification'
import { useDispatch } from 'react-redux'

const RespondPrs = () => {
  const prsDetailsContext = usePRSDetailsContext()

  const { data } = prsDetailsContext.state
  const { handleGetPRSData } = prsDetailsContext.actions
  const { respondPrsPopup } = usePRSDetailsPopupContext()

  const dispatch = useDispatch()

  const { state, actions } = respondPrsPopup
  const openCancelModal = () => actions.open()
  const closeCancelModal = () => actions.close()

  const handleRespond = (status: string) => {
    dispatch(setLoading(true))
    respondPrs(data.uuid, { decision: status })
      .then(() => {
        handleGetPRSData()
        notification.success({
          message: `Your response has been submitted.`
        })
      })
      .catch((err) => {
        notification.error({
          message: err.data?.details[0]
        })
      })
      .finally(() => {
        dispatch(setLoading(false))
        closeCancelModal()
      })
  }

  const onSubmit = () => {
    handleRespond(PRS_RESPOND.APPROVED)
  }
  const onReject = () => {
    handleRespond(PRS_RESPOND.REJECTED)
  }
  return (
    <>
      <Can I={ACTIONS.RESPOND} a={subject(SUBJECTS.PRS, { ...data })}>
        <Button type={BUTTON_TYPES.PRIMARY} onClick={openCancelModal} upperCase>
          {BTN_TXT.RESPOND}
        </Button>
      </Can>
      <Popup
        visible={state.visible}
        onCancel={actions.close}
        title={`Do you really need trial period for ${data.name}?`}
        width={442}
        footer={
          <>
            <Button
              onClick={onReject}
              type={BUTTON_TYPES.PRIMARY}
              upperCase
              danger
            >
              {BTN_TXT.REJECT_TRIAL}
            </Button>
            <Button type={BUTTON_TYPES.PRIMARY} upperCase onClick={onSubmit}>
              {BTN_TXT.APPROVE_TRIAL}
            </Button>
          </>
        }
      >
        <Typography.Headline6 weight={TYPOGRAPHY_WEIGHT.NORMAL}>
          Your response is important for future product purchasing. Please,
          respond whether you need time to test the product.
        </Typography.Headline6>
      </Popup>
    </>
  )
}

export default RespondPrs
