import {
  Ability,
  AbilityBuilder,
  ForcedSubject,
  MongoQuery,
  subject
} from '@casl/ability'
import { ACTIONS, SUBJECTS } from 'features/ContractDetails/Abilities/constants'
import { IContractDetails } from 'features/ContractDetails/types'

export type Actions = ValueOfObject<typeof ACTIONS>
export type Subjects = ValueOfObject<typeof SUBJECTS>

export type PossibleAbilities = [
  Actions,
  Subjects | (IContractDetails & ForcedSubject<Subjects>)
]

export type Conditions =
  | Record<PropertyKey, number | string | MongoQuery>
  | MongoQuery<Record<PropertyKey, number | string | MongoQuery>>

export type ContractAbility = Ability<PossibleAbilities, Conditions>

export type ContractAbilityBuilder = AbilityBuilder<ContractAbility>

export const contractSubject = (type, object) =>
  subject<Subjects, IContractDetails>(type, { ...object })
