import { Bar } from '@ant-design/plots'
import { Select } from 'components/Select'
import { TOption } from 'components/Select/types'
import { useEffect, useState } from 'react'
import {
  getAgregatedSurveyResults,
  getIndividualSurveyResults,
  getVotedStakeholdersList
} from './api'
import { IRFPStakeholder } from './types'
import './styles.scss'
import { Typography } from 'components/Typography'
import { useDispatch, useSelector } from 'react-redux'
import { setLoading } from 'redux/store/common/slice'
import { getIsLoading } from 'redux/store/common/getters'

export const SurveyResults = ({ rfpID }: { rfpID: string }) => {
  const [stakeholders, setStakeholders] = useState([])
  const [selectedStakeholder, setSelectedStakeHolder] = useState('')
  const [surveyData, setSurveyData] = useState([] as any)

  const dispatch = useDispatch()

  const isLoading = useSelector(getIsLoading)

  useEffect(() => {
    dispatch(setLoading(true))
    Promise.all([
      getVotedStakeholdersList(rfpID),
      getAgregatedSurveyResults(rfpID)
    ])
      .then((res) => {
        setStakeholders(res[0]?.data?.results)
        setSurveyData(res[1]?.data?.results)
      })
      .finally(() => dispatch(setLoading(false)))
  }, [rfpID])

  const returnSelectOptions = (arr: IRFPStakeholder[]): TOption[] => {
    if (arr?.length) {
      return [
        ...[{ label: `All responses (${arr.length})`, value: 'all' }],
        ...arr.map((item) => ({
          label: `${item.first_name} ${item.last_name}`,
          value: item.uuid
        }))
      ]
    }
    return []
  }

  const returnSurveyData = (arr: any[]): any[] => {
    if (arr?.length) {
      return arr?.map((item) => ({
        vendor: item?.vendor?.name,
        grade: item?.grade
      }))
    }
    return []
  }

  const onChange = (e) => {
    setSelectedStakeHolder(e)
    if (e === 'all') {
      getAgregatedSurveyResults(rfpID).then((res) => {
        setSurveyData(res?.data?.results)
      })
    } else {
      getIndividualSurveyResults(rfpID, e).then((res) => {
        setSurveyData(res?.data?.results)
      })
    }
  }

  const config = {
    data: returnSurveyData(surveyData),
    yField: 'vendor',
    xField: 'grade',
    tooltip: false as const,
    yAxis: {
      grid: {
        line: {
          style: {
            stroke: '#F3F8FB',
            lineWidth: 1,
            lineDash: [1, 0],
            shadowColor: '#D0D7DC',
            shadowBlur: 1,
            shadowOffsetY: 44,
            cursor: 'pointer'
          }
        }
      },
      tickLine: {
        length: 0
      },
      subTickLine: {
        count: 1,
        length: 100,
        style: {
          stroke: '#F3F8FB',
          lineWidth: 2,
          lineDash: [1, 0],
          shadowColor: '#D0D7DC',
          shadowBlur: 0,
          shadowOffsetY: -0.5,
          cursor: 'pointer'
        }
      },
      label: {
        labelLine: true,
        position: 'start',
        style: {
          fill: '#323C43',
          fontSize: 14,
          testAlign: 'left'
        }
      }
    },
    label: {
      style: {
        width: '100%',
        fill: 'white',
        fontSize: 12,
        testAlign: 'left'
      }
    },
    color: '#3B85BA',
    minBarWidth: 20,
    maxBarWidth: 20,
    maxHeight: 160
  }

  const isSurveyHasTotal = surveyData?.every((survey) => !!survey?.grade)

  return (
    <>
      {isLoading ||
        (!isSurveyHasTotal ? (
          <div className="create-survey__content">
            <Typography.Body1>No responses yet.</Typography.Body1>
          </div>
        ) : (
          <div className="survey-results__container">
            <Select
              propsItem={{
                name: 'select'
              }}
              className="survey-results__select"
              options={returnSelectOptions(stakeholders)}
              propsSelect={{
                defaultActiveFirstOption: true,
                loading: !stakeholders?.length,
                onChange: onChange,
                value: selectedStakeholder,
                placeholder: `All responses (${stakeholders?.length})`
              }}
            />
            <div
              className="survey-results__bar-container"
              style={{
                height: `calc(${surveyData?.length} * 44px + 28px)`
              }}
            >
              <Bar className="survey-results__bar" {...config} />
            </div>
          </div>
        ))}
    </>
  )
}
