import React, { FunctionComponent, Key, ReactNode, useMemo } from 'react'
import cn from 'classnames'

import './styles.scss'

import useInfiniteScroll, {
  UseInfiniteScrollHookArgs
} from 'hooks/useInfiniteScroll'

export type ILRenderItem<T> = { item: T; key: Key }

type Props<T> = {
  items: T[]
  loader: ReactNode
  listEmptyComponent?: ReactNode
  renderItem: FunctionComponent<ILRenderItem<T>>
  inverted?: boolean
  className?: string
  rootMargin?: string
  keyExtractor?: (item: T) => Key
} & UseInfiniteScrollHookArgs

const InfiniteList = <T extends object>(props: Props<T>) => {
  const {
    items,
    loader,
    renderItem,
    inverted = false,
    className,
    listEmptyComponent,
    keyExtractor,
    ...hookArgs
  } = props

  const [sentryRef, { rootRef }] = useInfiniteScroll(hookArgs)

  const hasItems = items.length !== 0

  const Loader = useMemo(
    () => (
      <div
        className={cn('infinite-list__sentry', {
          'infinite-list__sentry--fill': !items.length
        })}
        ref={sentryRef}
      >
        {loader}
      </div>
    ),
    [items.length, loader, sentryRef]
  )

  const ListItems = useMemo(
    () =>
      items.map((item, index) =>
        React.createElement<ILRenderItem<T>>(
          renderItem,
          { item, key: keyExtractor ? keyExtractor(item) : index },
          null
        )
      ),
    [items, keyExtractor, renderItem]
  )

  const ListEmptyComponent = useMemo(
    () => (hookArgs.loading ? null : listEmptyComponent),
    [hookArgs.loading, listEmptyComponent]
  )

  return (
    <div className={cn('infinite-list-container', className)} ref={rootRef}>
      {inverted && (hookArgs.loading || hookArgs.hasNextPage) && Loader}
      {hasItems ? ListItems : ListEmptyComponent}
      {!inverted && (hookArgs.loading || hookArgs.hasNextPage) && Loader}
    </div>
  )
}

export default InfiniteList
