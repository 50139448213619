import { ACTIONS, Can, SUBJECTS } from '../Permission'
import { Button, BUTTON_TYPES } from '../../components/Button'
import { BTN_TXT } from 'constants/txt'
import { ReactComponent as Plus16 } from 'assets/svg/Plus16.svg'
import useRouter from '../../hooks/useRouter'
import { routes } from '../../router'

const BulkCreate = () => {
  const { push, query } = useRouter()

  const bulkCreation = () =>
    push(
      `${routes.contracts}/${query?.type ? query.type : ''}${
        routes.bulkContractCreation
      }`
    )
  return (
    <Can I={ACTIONS.CREATE} a={SUBJECTS.BULK_CONTRACTS}>
      <Button
        type={BUTTON_TYPES.DEFAULT}
        icon={<Plus16 />}
        onClick={bulkCreation}
        upperCase
      >
        {BTN_TXT.BULK_CREATE}
      </Button>
    </Can>
  )
}

export default BulkCreate
