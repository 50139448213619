import { useMemo } from 'react'

import './styles.scss'

import { UserListItem } from 'features/RFP/RFPDetails/ResponsibleForRFP/UserList/UserListItem'
import { useRFPResponsibleContext } from 'features/RFP/RFPDetails/ResponsibleForRFP/Providers/RFPResponsibleContextProvider'
import { useRFPDetailsContext } from 'features/RFP/RFPDetails/Providers/RFPDetailsContextProvider'
import { RFP_DETAILS_TABS } from 'features/RFP/RFPDetails/constants'

const generalTab = RFP_DETAILS_TABS[0].key
const biddingTab = RFP_DETAILS_TABS[6].key

const UserList = () => {
  const rfpDetailsContext = useRFPDetailsContext()
  const rfpResponsibleContext = useRFPResponsibleContext()

  const { activeTab, data } = rfpDetailsContext.state
  const { responsibleUsers } = rfpResponsibleContext.state

  const responsibleUsersToDisplay = useMemo(() => {
    switch (activeTab) {
      case generalTab:
        return data.creator
          ? [data.creator, ...responsibleUsers]
          : responsibleUsers
      case biddingTab:
        return data.vendor_contract_steward
          ? [data.vendor_contract_steward, ...responsibleUsers]
          : responsibleUsers
      default:
        return []
    }
  }, [activeTab, data.creator, data.vendor_contract_steward, responsibleUsers])

  return (
    <div className="rfp-details-responsible__user-list">
      {responsibleUsersToDisplay.map((user) => (
        <UserListItem key={user.uuid} user={user} />
      ))}
    </div>
  )
}

export default UserList
