import { Typography } from '../../../components/Typography'
import { Button } from '../../../components/Button'
import { ReactComponent as Delete16 } from 'assets/svg/Delete16.svg'
import { BTN_TXT } from '../../../constants'
import { FC } from 'react'
import { ContractCategoryProps } from './types'
import './styles.scss'

export const ContractCategory: FC<ContractCategoryProps> = ({
  isPending,
  label,
  onRemoveCategory,
  onApproveCategory,
  id,
  disabled
}) => (
  <div className="contract-category row space-between align-center">
    <Typography.Body1>{label}</Typography.Body1>
    <div className="contract-category__actions row gap-8">
      {onApproveCategory && isPending && (
        <Button
          onClick={() => onApproveCategory(id)}
          disabled={disabled}
          upperCase
          medium
        >
          {BTN_TXT.APPROVE}
        </Button>
      )}
      <Button.Icon
        icon={<Delete16 />}
        onClick={() => onRemoveCategory(id, isPending)}
        disabled={disabled}
        medium
        danger
      />
    </div>
  </div>
)
