import { Default } from './table.default'
import { TTableProps } from './types'

const Table = <T extends Object>(props: TTableProps<T>) => (
  <Default<T> {...props} />
)

Table.Default = Default

export default Table
