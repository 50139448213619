import { useCallback, useMemo, useState } from 'react'

export type UseInfiniteDataHookResult = {
  offset: number
  limit: number
  hasNextPage: boolean
  handleLoadMore: VoidFunction
  reset: VoidFunction
}

export type UseInfiniteDataHookArgs = {
  total: number
  limit?: number
  loadMore: (args: object) => Promise<void>
}

const useInfiniteData = ({
  total,
  limit = 10,
  loadMore
}: UseInfiniteDataHookArgs): UseInfiniteDataHookResult => {
  const [offset, setOffset] = useState(0)

  const hasNextPage = useMemo(
    () => total > offset + limit,
    [limit, offset, total]
  )

  const handleLoadMore = useCallback(async () => {
    if (hasNextPage) {
      const nextOffset = offset + limit

      try {
        await loadMore({ offset: nextOffset, limit })

        setOffset(nextOffset)
      } catch (e) {
        console.error(e)
      }
    }
  }, [hasNextPage, limit, loadMore, offset])

  const reset = useCallback(() => {
    setOffset(0)
  }, [])

  return {
    offset,
    limit,
    hasNextPage,
    handleLoadMore,
    reset
  }
}

export default useInfiniteData
