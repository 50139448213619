import { useState, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { useParams } from 'react-router-dom'
import { Form } from 'antd'
import cn from 'classnames'
import { Select } from 'components/Select'
import { setLoading } from 'redux/store/common/slice'
import { LABELS, PLACEHOLDERS } from 'constants/txt'
import { formatPhoneNumber, validateMessages } from 'helper/common'
import { TOption } from 'components/Select/types'
import {
  TFormPRSParticipantsForm,
  TFormPRSParticipantsFormProps
} from './forms.prsParticipants.d'
import './forms.prsParticipants.scss'
import {
  formatOptionNameValue,
  formatUserNameOption
} from '../../helper/optionsFormatters'
import { TPRSData } from '../../features/PRS/PRSDetails/types'
import {
  createPRS,
  fetchCommunityList,
  fetchResponsibleCommunityUsersList,
  updatePRS
} from '../../pages/ProductRequests/api'
import { fetchHealthSystemsList } from 'features/HealthSystems/api'
import Typography from '../Typography/Typography'
import { Input } from '../Input'
import { usePRSDetailsContext } from 'features/PRS/PRSDetails/Providers/PRSDetailsContextProvider'
import useRouter from 'hooks/useRouter'
import { routes } from 'router/Config/config.routes'
import { ReactComponent as RemoveIcon } from 'assets/svg/Clos16.svg'
import { PRS_STATUSES } from 'pages/ProductRequests/constants'
import Popups from 'features/PRS/PRSDetails/Popups/Popups'
import { usePRSDetailsPopupContext } from 'features/PRS/PRSDetails/Providers/PRSDetailsPopupProvider'

export const PRSParticipants = ({
  className,
  onValueChange,
  data,
  form,
  setShowButtons,
  callback
}: TFormPRSParticipantsFormProps): JSX.Element => {
  const [communities, setCommunities] = useState<TOption[]>([])
  const [healthSystems, setHealthSystems] = useState<TOption[]>([])
  const [responsibles, setResponsibles] = useState<TOption[]>([])
  const { state, actions } = usePRSDetailsContext()
  const { generalInfo, fields, participantsInfo, isNew, changedValues } = state
  const { setFields, resetCRR, resetFields, resetChanged } = actions
  const { resendPopup } = usePRSDetailsPopupContext()
  const { id } = useParams()

  const { push } = useRouter()

  const phoneNumber = Form.useWatch(['clinical_sponsor', 'phone_number'], form)
  const communityField = Form.useWatch('community', form) as string
  const hssField = Form.useWatch('health_systems', form) as string[]

  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(setLoading(true))
    getOptions().finally(() => dispatch(setLoading(false)))
  }, [])

  const returnHsForCS = () => {
    return healthSystems?.filter((i) => hssField?.find((hs) => hs === i?.value))
  }

  useEffect(() => {
    if (!!communityField) {
      dispatch(setLoading(true))
      getHSOptions().finally(() => dispatch(setLoading(false)))
    }
  }, [communityField])

  useEffect(() => {
    if (!!communityField && hssField?.length) {
      dispatch(setLoading(true))
      getACUOptions().finally(() => dispatch(setLoading(false)))
    }
  }, [communityField, hssField])

  useEffect(() => {
    const objectData = id === 'new' ? participantsInfo : data
    if (objectData) {
      const formattedData = {}
      for (const key in objectData) {
        if (key === 'health_systems') {
          formattedData[key] = objectData[key].map((i) => i?.uuid || i)
        } else if (Array.isArray(objectData[key])) {
          formattedData[key] = objectData[key].map(formatOptionNameValue)
        } else {
          formattedData[key] = objectData[key]
        }
      }
      const fields = Object.keys(formattedData).map((i) => ({
        name: i,
        value: formattedData[i]
      }))
      setFields(fields)
    }
  }, [data, participantsInfo])
  const getOptions = async () => {
    await fetchCommunityList().then((resp) => {
      if (!resp.data?.results) {
        return
      }
      setCommunities(resp.data.results.map(formatOptionNameValue))
    })
  }
  const getHSOptions = async () => {
    await fetchHealthSystemsList(communityField).then((resp) => {
      if (!resp.data?.results) {
        return
      }
      setHealthSystems(resp.data.results.map(formatOptionNameValue))
    })
  }

  const getACUOptions = async () => {
    const category =
      id === 'new' ? generalInfo.contract_category : data?.contract_category
    await fetchResponsibleCommunityUsersList({
      params: {
        contract_category: category,
        health_systems: hssField
      }
    }).then((resp) => {
      if (!resp.data?.results) {
        return
      }
      setResponsibles(resp.data.results.map(formatUserNameOption))
    })
  }

  const handleSubmitForm = async (formData) => {
    dispatch(setLoading(true))
    const prsData: TPRSData = {
      health_systems: formData.health_systems
        ? typeof formData.health_systems[0] === 'object'
          ? formData.health_systems.map((i) => i?.value || i?.uuid)
          : formData.health_systems
        : undefined,
      ...formData
    }

    const generalData = { ...generalInfo }
    if (!isNew && data.status === PRS_STATUSES.DECLINED) {
      delete generalData['contract_category']
      delete prsData['contract_category']
      delete prsData['contract_category_name']
      delete prsData['creator']
    }
    if (!!generalData?.prs_request_images) {
      if (isNew) delete generalData['prs_request_images']
      else {
        generalData['prs_request_images'] = generalData['prs_request_image_ids']
        delete generalData['prs_request_image_ids']
      }
    }
    if (!!prsData?.prs_request_images) {
      if (!isNew) {
        prsData['prs_request_images'] = prsData['prs_request_images']?.map(
          (i) => i?.uuid || i
        ) as any
        delete prsData['prs_request_image_ids']
      }
    }
    if (
      !!generalData?.prs_request_attachments?.length ||
      !!generalData?.prs_request_attachment_ids?.length
    ) {
      if (isNew) delete generalData['prs_request_attachments']
      else {
        generalData['prs_request_attachments'] =
          generalData['prs_request_attachment_ids']
        delete generalData['prs_request_attachment_ids']
      }
    }
    if (!!prsData?.prs_request_attachments) {
      if (!isNew) {
        prsData['prs_request_attachments'] = prsData[
          'prs_request_attachments'
        ]?.map((i) => i?.uuid || i) as any
        delete prsData['prs_request_attachment_ids']
      }
    }
    if (!!prsData.clinical_sponsor?.phone_number) {
      const number = !prsData.clinical_sponsor.phone_number
        ?.toString()
        ?.includes('+1')
        ? '+1' + prsData.clinical_sponsor.phone_number
        : prsData.clinical_sponsor.phone_number
      prsData.clinical_sponsor.phone_number = number
    }

    if (data.status === PRS_STATUSES.DECLINED)
      prsData['status'] = PRS_STATUSES.WAITING_FOR_REVIEW

    setShowButtons(false)
    try {
      if (id === 'new') {
        await createPRS({ ...generalData, ...prsData }).then((resp) => {
          setShowButtons(false)
          push(`${routes.productRequests}/${resp.data.uuid}`)
        })
      } else {
        const updatedData = generalInfo?.name
          ? { ...generalData, ...prsData }
          : { ...prsData }
        await updatePRS(
          id as string,
          { ...updatedData },
          'PRS has been successfully saved.'
        ).then(() => {
          resetChanged()
          if (callback) {
            callback()
          }
        })
      }
    } catch {
      setShowButtons(true)
    } finally {
      dispatch(setLoading(false))
    }
  }
  const checkIfSubmit = (formData) => {
    if (data.status === PRS_STATUSES.DECLINED) {
      resendPopup.actions.open()
      onValueChange(formData)
    } else handleSubmitForm(formData)
  }

  const onResendHandler = () => {
    resendPopup.actions.close()
    handleSubmitForm(changedValues)
  }

  return (
    <>
      <Form<TFormPRSParticipantsForm>
        onFinish={checkIfSubmit}
        fields={fields}
        form={form}
        onValuesChange={(changed) => {
          onValueChange(changed)
          if (changed?.health_systems) resetCRR()
          if (changed?.community) resetFields()
        }}
        validateMessages={validateMessages}
        className={cn('prs-participants-form', className)}
        id="prs-participants"
      >
        <div className="clinic-sponsor-container row two-columns">
          <Select
            options={communities}
            propsItem={{
              name: 'community',
              label: LABELS.COMMUNITY,
              rules: [{ required: true }]
            }}
            propsSelect={{
              placeholder: PLACEHOLDERS.PLEASE_SELECT
            }}
          />
        </div>
        <div className="long-row-participants row two-columns mt-24">
          <Select.Multi
            options={healthSystems}
            propsItem={{
              name: 'health_systems',
              label: LABELS.HEALTH_SYSTEMS,
              rules: [{ required: true }]
            }}
            propsSelect={{
              placeholder: PLACEHOLDERS.PLEASE_SELECT,
              disabled: !communityField,
              allowClear: true,
              clearIcon: <RemoveIcon />
            }}
          />
          <div className="prs-creation-form__half-row row two-columns mt-0">
            <Select
              options={responsibles}
              propsItem={{
                name: 'community_responsible',
                label: LABELS.RESPONSIBLE_COMMUNITY_USER,
                rules: [{ required: true }]
              }}
              propsSelect={{
                placeholder: PLACEHOLDERS.PLEASE_SELECT,
                disabled: !communityField || !hssField?.length
              }}
            />
          </div>
        </div>
        <div className="row mt-32">
          <Typography.Headline6 className="clinic-sponsor-title">
            Clinical sponsor
          </Typography.Headline6>
        </div>
        <div className="clinic-sponsor-container clinic-sponsor-container__not-required row two-columns mt-24">
          <Select
            options={returnHsForCS()}
            propsItem={{
              name: ['clinical_sponsor', 'health_system'],
              label: LABELS.HEALTH_SYSTEM,
              rules: [{ required: true }]
            }}
            propsSelect={{
              placeholder: PLACEHOLDERS.PLEASE_SELECT,
              allowClear: true,
              clearIcon: <RemoveIcon />,
              disabled: !communityField || !hssField?.length
            }}
          />
          <Input
            propsItem={{
              name: ['clinical_sponsor', 'name'],
              label: LABELS.REQUESTER_NAME,
              rules: [
                {
                  pattern: /^[-\w\s]+$/,
                  max: 80,
                  required: true
                }
              ]
            }}
            propsInput={{
              maxLength: 80,
              disabled: !communityField || !hssField?.length
            }}
          />
        </div>
        <div className="clinic-sponsor-container clinic-sponsor-container__not-required row two-columns mt-24">
          <Input
            propsInput={{
              placeholder: PLACEHOLDERS.EMAIL,
              disabled: !communityField || !hssField?.length
            }}
            propsItem={{
              label: LABELS.EMAIL,
              rules: [
                {
                  required: true,
                  type: 'email',
                  validateTrigger: 'onSubmit',
                  message: 'Enter a valid email'
                }
              ],
              name: ['clinical_sponsor', 'email']
              // validateStatus: !!error ? 'error' : undefined
            }}
          />
          <Input.Phone
            propsInputNumber={{
              controls: false,
              formatter: formatPhoneNumber,
              prefix: phoneNumber ? '+1' : '',
              disabled: !communityField || !hssField?.length
            }}
            propsItem={{
              name: ['clinical_sponsor', 'phone_number'],
              label: LABELS.PHONE_NUMBER,
              rules: [{ required: true }]
            }}
          />
        </div>
      </Form>
      <Popups
        onResendHandler={onResendHandler}
        title={`Resend ${data.name}?`}
      />
    </>
  )
}
