import Typography from '../../../components/Typography/Typography'
import { Button, BUTTON_TYPES } from '../../../components/Button'
import { BTN_TXT } from '../../../constants'
import useRouter from '../../../hooks/useRouter'
import { routes } from '../../../router'
type TProps = {
  scroll: (link: string) => void
}
const Header = ({ scroll }: TProps) => {
  const { push } = useRouter()
  const handleLogIn = () => {
    push(routes.login)
  }
  return (
    <>
      {' '}
      <div className="landing-page__header__links row">
        <Typography.Headline6 onClick={() => scroll('about')}>
          About
        </Typography.Headline6>
        <Typography.Headline6 onClick={() => scroll('who-we-serve')}>
          Who we serve
        </Typography.Headline6>
        <Typography.Headline6 onClick={() => scroll('contact-us')}>
          Contact us
        </Typography.Headline6>
      </div>
      <div className="landing-page__header__actions row">
        <Button type={BUTTON_TYPES.DEFAULT} upperCase onClick={handleLogIn}>
          {BTN_TXT.LOG_IN}
        </Button>
      </div>
    </>
  )
}

export default Header
