import {
  CellTemplate,
  Compatible,
  Uncertain,
  Cell,
  UncertainCompatible,
  getCellProperty
} from '@silevis/reactgrid'
import { Input } from '../../../../components/Input'
import cn from 'classnames'
import { useEffect, useState } from 'react'

export interface TextCellInterface extends Cell {
  type: 'text'
  key?: string
  text: string
  placeholder: string
  focusCallback?: () => void
  unFocusCallback?: () => void
  isError?: boolean
  maxLength?: number
}

export class CustomCellTextTemplate implements CellTemplate<TextCellInterface> {
  getCompatibleCell(
    uncertainCell: Uncertain<TextCellInterface>
  ): Compatible<TextCellInterface> {
    const text = getCellProperty(uncertainCell, 'text', 'string')
    const placeholder = getCellProperty(uncertainCell, 'placeholder', 'string')
    const value = parseFloat(text)
    return { ...uncertainCell, text, value, placeholder }
  }

  update(
    cell: Compatible<TextCellInterface>,
    cellToMerge: UncertainCompatible<TextCellInterface>
  ): Compatible<TextCellInterface> {
    return this.getCompatibleCell({ ...cell, text: cellToMerge.text })
  }

  render(
    cell: Compatible<TextCellInterface>,
    _isInEditMode: boolean,
    onCellChanged: (
      cell: Compatible<TextCellInterface>,
      commit: boolean
    ) => void
  ) {
    return (
      <InputPicker
        onChangedVal={(val) =>
          onCellChanged(
            this.getCompatibleCell({
              ...cell,
              text: val
            }),
            true
          )
        }
        {...cell}
      />
    )
  }

  isFocusable() {
    return true
  }
}

const InputPicker = ({
  key,
  isError,
  text,
  placeholder,
  focusCallback,
  unFocusCallback,
  onChangedVal,
  maxLength
}: Compatible<TextCellInterface> & { onChangedVal: (val: string) => void }) => {
  const [value, setValue] = useState(text)

  useEffect(() => {
    setValue(text)
  }, [text])
  return (
    <div className={cn('input-wrapper full-width', { errored: isError })}>
      <Input.DefaultNoForm
        key={key}
        hasCustomError={isError}
        propsInput={{
          value: value,
          placeholder: placeholder,
          maxLength: maxLength ?? 100,
          onCopy: (e) => e.stopPropagation(),
          onCut: (e) => e.stopPropagation(),
          onPointerDown: (e) => e.stopPropagation(),
          onKeyDown: (e) => {
            if (e.key === 'Backspace') {
              e.stopPropagation()
            }
          },
          onPaste: (e) => {
            e.preventDefault()
          },
          onChange: (e) => setValue(e.currentTarget.value),
          onFocus: () => focusCallback?.(),
          onBlur: () => {
            onChangedVal(value)
            unFocusCallback?.()
          }
        }}
      />
    </div>
  )
}
