import { useEffect, useMemo } from 'react'
import { useSelector } from 'react-redux'
import { Popup } from 'components/Popup'
import AddHealthSystem from 'features/ContractDetails/HSList/AddHealthSystem/AddHealthSystem'
import { Button, BUTTON_TYPES } from '../../../../components/Button'
import { ACTIONS, contractSubject, SUBJECTS } from '../../Abilities'
import { getUser } from '../../../../redux/store/user/getters'
import { useContractDetailsPopup } from 'features/ContractDetails/Providers/ContractDetailsPopupProvider'
import useHSTable from '../hooks/useHSTable'
import {
  getContractAvailableHealthSystems,
  getContractAvailableHealthSystemsForCommons
} from '../api'
import { BTN_TXT } from '../../../../constants'
import './styles.scss'
import { useContractDetailsContext } from '../../Providers/ContractDetailsContextProvider'
import { useContractDetailsAbility } from '../../Providers/ContractDetailsAbilityProvider'

export default () => {
  const { addHealthSystemPopup } = useContractDetailsPopup()
  const user = useSelector(getUser)
  const hsTableProps = useHSTable({
    getContractHealthSystems: user.role.includes('commons')
      ? getContractAvailableHealthSystemsForCommons
      : getContractAvailableHealthSystems
  })
  const { state } = useContractDetailsContext()
  const { details } = state
  const ability = useContractDetailsAbility()
  const disableAddHealthSystemButton = useMemo(
    () => !hsTableProps.selectedRowKeys.length,
    [hsTableProps.selectedRowKeys.length]
  )

  useEffect(() => {
    if (addHealthSystemPopup.state.visible) {
      hsTableProps.refreshTableData()
    }
  }, [addHealthSystemPopup.state.visible])

  return (
    <Popup
      className="add-health-system"
      {...addHealthSystemPopup.state}
      width={1376}
      footer={
        <>
          <Button onClick={addHealthSystemPopup.actions.close} upperCase>
            {BTN_TXT.CANCEL}
          </Button>
          {ability.can(
            ACTIONS.ADD,
            contractSubject(SUBJECTS.HEALTH_SYSTEM, details)
          ) && (
            <Button
              type={BUTTON_TYPES.PRIMARY}
              form="add-health-system-form-id"
              htmlType="submit"
              disabled={disableAddHealthSystemButton}
              upperCase
            >
              {hsTableProps.isContractSigned ? BTN_TXT.CONTINUE : BTN_TXT.ADD}
            </Button>
          )}
        </>
      }
    >
      <AddHealthSystem {...hsTableProps} />
    </Popup>
  )
}
