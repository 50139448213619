export const formatUserNameOption = (option) => ({
  ...option,
  label: `${option.first_name} ${option.last_name}`,
  value: option.uuid
})

export const formatOptionNameValue = (option) => ({
  ...option,
  label: option?.name,
  value: option?.uuid
})

export const formatOptionPortfolio = (option) => ({
  label: option?.portfolio?.name,
  value: option?.portfolio?.uuid
})
