export const MY_COMMUNITY_TABS = [
  {
    key: 'communities',
    label: 'Communities'
  },
  {
    key: 'vendors',
    label: 'Vendors'
  },
  {
    key: 'commons',
    label: 'Сommons'
  }
]

export const MY_COMMUNITY_USER_STATUSES = [
  {
    label: 'Active',
    value: 'Active'
  },
  {
    label: 'Invited',
    value: 'Invited'
  },
  {
    label: 'Archived',
    value: 'Archived'
  },
  {
    label: 'Pending',
    value: 'Pending'
  }
]
