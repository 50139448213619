import { Button, BUTTON_TYPES } from 'components/Button'
import { BTN_TXT } from 'constants/txt'
import { ReactComponent as Plus16 } from 'assets/svg/Plus16.svg'
import { ACTIONS, SUBJECTS } from 'features/PRS/Abilities'
import { subject } from '@casl/ability'
import { usePRSDetailsContext } from '../Providers/PRSDetailsContextProvider'
import { Can } from '../Providers/PrsDetailsAbilityProvider'
import { usePRSDetailsPopupContext } from '../Providers/PRSDetailsPopupProvider'
import './style.scss'

const AddClinicalSponsor = () => {
  const prsDetailsContext = usePRSDetailsContext()
  const { assignClinicalSponsorPopup } = usePRSDetailsPopupContext()
  const { actions } = assignClinicalSponsorPopup
  const { open } = actions

  const { data } = prsDetailsContext.state

  return (
    <Can I={ACTIONS.ASSIGN} a={subject(SUBJECTS.CLINICAL_SPONSOR, { ...data })}>
      <Button
        type={BUTTON_TYPES.DEFAULT}
        icon={<Plus16 />}
        onClick={open}
        upperCase
        className="mt-4"
      >
        {BTN_TXT.ADD_CLINICAL_SPONSOR}
      </Button>
    </Can>
  )
}

export default AddClinicalSponsor
