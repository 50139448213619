import { Popup } from '../../../components/Popup'
import { Button, BUTTON_TYPES } from 'components/Button'
import { BTN_TXT } from '../../../constants'
import useCreateContractPipelineModal from '../hooks/useCreateContractPipelineModal'
import { useContractPagePopup } from 'pages/Contracts/Providers/ContractPagePopupProvider'
import './styles.scss'
import { CreateContractPipelineForm } from './CreateContractPipelineForm'
import { useEffect } from 'react'

export const CreateContractPipelineModal = ({
  successCreateCallback
}: {
  successCreateCallback: () => void
}) => {
  const { addPipelinePopup } = useContractPagePopup()
  const { state, actions } = addPipelinePopup

  const formProps = useCreateContractPipelineModal({
    successCallback: () => {
      successCreateCallback()
      actions.close()
    }
  })

  const { form, findChangesHandler, setDiscardChanges, onFirstLoad } = formProps

  useEffect(() => {
    if (state.visible) {
      onFirstLoad()
    }
  }, [state.visible])

  const onClose = () => {
    if (findChangesHandler()) {
      setDiscardChanges(true)
      return
    }
    actions.close()
    form.resetFields()
  }

  return (
    <>
      <Popup
        {...state}
        className="create-contract-pipeline-modal"
        onCancel={onClose}
        width={442}
        footer={
          <>
            <Button type={BUTTON_TYPES.DEFAULT} upperCase onClick={onClose}>
              {BTN_TXT.CANCEL}
            </Button>
            <Button
              type={BUTTON_TYPES.PRIMARY}
              upperCase
              form="add-edit-contract-pipeline-form"
              htmlType="submit"
            >
              {BTN_TXT.CREATE}
            </Button>
          </>
        }
      >
        <CreateContractPipelineForm
          successCloseConfirmationCallback={actions.close}
          {...formProps}
        />
      </Popup>
    </>
  )
}
