import CreateSurvey from '../CreateSurvey'
import './style.scss'
import { usePRSDetailsPopupContext } from '../../Providers/PRSDetailsPopupProvider'
import SurveyForm from './SurveyForm'
import { usePRSVotingContext } from '../../Providers/PRSVotingContext'
import EditSurvey from '../EditSurvey'
import Typography from '../../../../../components/Typography/Typography'
import OpenSurvey from '../OpenSurvey'
import { PRS_SURVEY_TABS } from './constants'
import { subject } from '@casl/ability'
import { ACTIONS, SUBJECTS } from 'features/PRS/Abilities'
import { Can } from '../../Providers/PrsDetailsAbilityProvider'
import { usePRSDetailsContext } from '../../Providers/PRSDetailsContextProvider'
import OpenMyResults from '../OpenMyResults'
import { Button, BUTTON_TYPES } from '../../../../../components/Button'
import { BTN_TXT } from '../../../../../constants'
import { PRS_STATUSES } from '../../../../../pages/ProductRequests/constants'

const Survey = () => {
  const prsContext = usePRSDetailsContext()
  const { data } = prsContext.state
  const { createSurveyPopup } = usePRSDetailsPopupContext()
  const { state, actions } = usePRSVotingContext()
  const { isEditMode, votingResponse } = state
  const openSurveyCreation = () => createSurveyPopup.actions.open()

  const handleOpenMode = () => {
    actions.setIsOpen(true)
    actions.setActiveTab(PRS_SURVEY_TABS[1].key)
    createSurveyPopup.actions.open()
  }

  return (
    <div className="create-survey-prs-container mt-24">
      <div className="create-survey-prs-container__btn-wrapper">
        <Typography.Headline6 className="mb-16">Survey</Typography.Headline6>
        {isEditMode ? (
          <>
            <EditSurvey onClick={openSurveyCreation} />
          </>
        ) : (
          <CreateSurvey onClick={openSurveyCreation} />
        )}
        <Can I={ACTIONS.OPEN} a={subject(SUBJECTS.VOTING, { ...data })}>
          {!!votingResponse?.size ||
          data.status === PRS_STATUSES.DECISION_MAKING ? (
            <OpenMyResults />
          ) : (
            <Button
              onClick={handleOpenMode}
              upperCase
              type={BUTTON_TYPES.PRIMARY}
            >
              {BTN_TXT.OPEN_SURVEY}
            </Button>
          )}
        </Can>
        <Can
          I={ACTIONS.VIEW}
          a={subject(SUBJECTS.VOTING_RESPONSE, { ...data })}
        >
          <OpenSurvey onClick={handleOpenMode} />
        </Can>
      </div>
      {createSurveyPopup.state.visible && <SurveyForm />}
    </div>
  )
}

export default Survey
