import Fetcher from 'services/Fetcher'
import { API_DICTIONARY, HTTP_METHODS } from 'constants/api'
import { TRFPVendorUsersRequest } from './types'

const fetcher = new Fetcher({})

export const getVendorTeamUsers: TRFPVendorUsersRequest = async (id, data) =>
  fetcher.request({
    url: `${API_DICTIONARY.RFP}${id}/${API_DICTIONARY.RFP_VENDOR_TEAM}`,
    method: HTTP_METHODS.GET,
    ...data
  })
