import { useState, useMemo } from 'react'
import { useDispatch } from 'react-redux'
import { Button, BUTTON_TYPES } from 'components/Button'
import { Typography } from 'components/Typography'
import { Popup } from 'components/Popup'
import { archiveUsers } from './api'
import useRouter from 'hooks/useRouter'
import { setLoading } from 'redux/store/common/slice'
import { BTN_TXT } from 'constants/txt'
import { USER_STATUSES } from 'constants/common'
import { routes } from 'router/Config/config.routes'
import { ReactComponent as Delete16 } from 'assets/svg/Delete16.svg'
import { TArchivePopup, TSelectedUser } from './types'

export const UserArchivePopup = <T extends TSelectedUser>({
  selected,
  callback,
  isTable = false
}: TArchivePopup<T>) => {
  const [isOpen, setIsOpen] = useState(false)
  const dispatch = useDispatch()
  const { push } = useRouter()
  const archiveCommunityUser = () => {
    dispatch(setLoading(true))
    let uuids: (string | undefined)[] = []
    if (Array.isArray(selected)) {
      uuids = selected.map((i) => i?.uuid)
    } else {
      if (selected.uuid) {
        uuids.push(selected.uuid)
      }
    }
    archiveUsers({ users: uuids })
      .then(() => {
        if (callback) {
          callback()
        }
        if (!isTable) {
          push(routes.settings)
        }
        setIsOpen(false)
      })
      .catch(() => dispatch(setLoading(false)))
  }
  const userName = useMemo(() => {
    if (Array.isArray(selected) && selected.length === 1) {
      return `${selected[0].first_name} ${selected[0].last_name}`
    } else if (!Array.isArray(selected)) {
      return `${selected.first_name} ${selected.last_name}`
    }
  }, [selected])
  return (
    <>
      {(Array.isArray(selected) ||
        (!Array.isArray(selected) &&
          selected.status !== USER_STATUSES.ARCHIVED)) && (
        <Button
          type={isTable ? BUTTON_TYPES.PRIMARY : BUTTON_TYPES.DEFAULT}
          icon={isTable && <Delete16 />}
          onClick={() => setIsOpen(true)}
          upperCase
          danger
        >
          {BTN_TXT.ARCHIVE}
        </Button>
      )}
      <Popup
        visible={isOpen}
        onCancel={() => setIsOpen(false)}
        title={`Archive ${!!userName ? userName : 'users'}?`}
        width={442}
        footer={
          <>
            <Button
              onClick={() => setIsOpen(false)}
              type={BUTTON_TYPES.DEFAULT}
              upperCase
            >
              {BTN_TXT.CANCEL}
            </Button>
            <Button
              type={BUTTON_TYPES.PRIMARY}
              onClick={archiveCommunityUser}
              upperCase
              danger
            >
              {BTN_TXT.ARCHIVE}
            </Button>
          </>
        }
      >
        <div className="user-archive_text">
          <Typography.Body1>
            {Array.isArray(selected) && selected.length > 1
              ? `${selected?.length} users`
              : 'User'}{' '}
            might have assigned contracts.
            <br />
            Do you really want to archive?
          </Typography.Body1>
        </div>
      </Popup>
    </>
  )
}
