import { privacyTable } from './contstants'
import './style.scss'
import Typography from '../Typography/Typography'
import { TYPOGRAPHY_WEIGHT } from '../Typography'
const PrivacyPolicyTable = () => {
  return (
    <table className="table-policy">
      <thead>
        <tr>
          <th>
            <Typography.Body2 weight={TYPOGRAPHY_WEIGHT.SEMI_BOLD}>
              Category
            </Typography.Body2>
          </th>
          <th>
            <Typography.Body2 weight={TYPOGRAPHY_WEIGHT.SEMI_BOLD}>
              Examples
            </Typography.Body2>
          </th>
          <th>
            <Typography.Body2 weight={TYPOGRAPHY_WEIGHT.SEMI_BOLD}>
              Collected
            </Typography.Body2>
          </th>
        </tr>
      </thead>
      <tbody>
        {privacyTable.map((i, idx) => {
          return (
            <tr key={`table-row${idx}`}>
              <td>{i.category}</td>
              <td>{i.examples}</td>
              <td>{i.collected ? 'YES' : 'NO'}</td>
            </tr>
          )
        })}
      </tbody>
    </table>
  )
}

export default PrivacyPolicyTable
