import RFPDetailsContextProvider from 'features/RFP/RFPDetails/Providers/RFPDetailsContextProvider'
import RFPDetailsPopupProvider from 'features/RFP/RFPDetails/Providers/RFPDetailsPopupProvider'
import RFPDetailsActionsProvider from 'features/RFP/RFPDetails/Providers/RFPDetailsActionsProvider'
import RFPVotingContextProvider from 'features/RFP/RFPDetails/Providers/RFPVotingContextProvider'
import RFPSurveyContextProvider from 'features/RFP/RFPDetails/Providers/RFPSurveyContextProvider'

import { RFPDetails } from 'features/RFP/RFPDetails/RFPDetails'
import { SendRFPPopup } from 'features/RFP/RFPDetails/Actions/SendRFP'
import CancelRFPPopup from 'features/RFP/RFPDetails/Actions/CancelRFPPopup'
import { AwardVendorsPopup } from 'features/RFP/RFPDetails/Actions/AwardVendors'

export default () => (
  <RFPDetailsPopupProvider>
    <RFPDetailsContextProvider>
      <RFPVotingContextProvider>
        <RFPSurveyContextProvider>
          <RFPDetailsActionsProvider>
            <RFPDetails />
            <CancelRFPPopup />
            <SendRFPPopup />
            <AwardVendorsPopup />
          </RFPDetailsActionsProvider>
        </RFPSurveyContextProvider>
      </RFPVotingContextProvider>
    </RFPDetailsContextProvider>
  </RFPDetailsPopupProvider>
)
