import { AppThunk } from '../../type'

import {
  error,
  finish,
  loading,
  resetChatDetails,
  setDetails,
  setMembers,
  updateDetails,
  updateMembers,
  resetChatMembers
} from './slice'

import {
  getChatDetails as getChatDetailsRequest,
  getChatMembers as getChatMembersRequest,
  updateChatDetails as updateChatDetailsRequest
} from 'features/ChatDetails/api'
import { getChat } from 'redux/store/chats/getters'
import { TChatDetails } from 'features/ChatDetails/types'
import { ChatMember } from 'redux/store/chatDetails/types'
import { updateChatMembersRequest } from 'features/ChatDetails/Members/api'
import { chatListItemToDetailsConvertor } from 'redux/store/chatDetails/utils'
import { getChatMembers } from 'redux/store/chatDetails/getters'
import { updateChatMembers } from '../chats/slice'

export const setChatDetailsAsync =
  (id: string): AppThunk =>
  async (dispatch, getState) => {
    try {
      const chatListItem = getChat(id)(getState())
      dispatch(resetChatDetails())
      if (chatListItem) {
        dispatch(setDetails(chatListItemToDetailsConvertor(chatListItem)))
      }

      dispatch(loading())

      const { data } = await getChatDetailsRequest(id)

      if (data) {
        dispatch(updateDetails(data))
      }
    } catch (err) {
      dispatch(error({ error: err }))
    } finally {
      dispatch(finish())
    }
  }

export const setChatMembersAsync =
  (id: string): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(loading())
      dispatch(resetChatMembers())
      const { data } = await getChatMembersRequest(id)

      if (data.results) {
        dispatch(setMembers(data.results))
      }

      await Promise.resolve()
    } catch (err) {
      dispatch(error({ error: err }))
      await Promise.reject(err)
    } finally {
      dispatch(finish())
    }
  }

export const updateChatDetailsAsync =
  (id: string, details: TChatDetails): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(loading())

      const { data } = await updateChatDetailsRequest(id, details)

      if (data) {
        dispatch(updateDetails(data))
      }

      await Promise.resolve()
    } catch (err) {
      dispatch(error({ error: err }))
      await Promise.reject(err)
    } finally {
      dispatch(finish())
    }
  }

export const addChatMembersAsync =
  (id: string, members: ChatMember[]): AppThunk =>
  async (dispatch, getState) => {
    try {
      dispatch(loading())

      const chatMembers = getChatMembers(getState()) || []

      const requestData = {
        members: [...chatMembers, ...members].map((member) => member.uuid)
      }

      await updateChatMembersRequest(id, requestData)

      dispatch(updateMembers(members))
      const { data } = await getChatDetailsRequest(id)

      if (data) {
        dispatch(updateDetails(data))
      }
      dispatch(
        updateChatMembers({
          uuid: id,
          members: [...chatMembers, ...members].length
        })
      )
      await Promise.resolve()
    } catch (err) {
      dispatch(error({ error: err }))
      await Promise.reject(err)
    } finally {
      dispatch(finish())
    }
  }

export const deleteChatMembersAsync =
  (id: string, memberToDelete: ChatMember): AppThunk =>
  async (dispatch, getState) => {
    try {
      dispatch(loading())

      const chatMembers = getChatMembers(getState())

      if (!chatMembers.length) {
        return Promise.reject(new Error('Chat members are empty'))
      }

      const filteredChatMembers = chatMembers.filter(
        (member) => member.uuid !== memberToDelete.uuid
      )

      const requestData = {
        members: filteredChatMembers.map((member) => member.uuid)
      }

      await updateChatMembersRequest(id, requestData)

      dispatch(setMembers(filteredChatMembers))

      const { data } = await getChatDetailsRequest(id)

      if (data) {
        dispatch(updateDetails(data))
      }

      await Promise.resolve()
    } catch (err) {
      dispatch(error({ error: err }))
      await Promise.reject(err)
    } finally {
      dispatch(finish())
    }
  }
