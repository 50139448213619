import { useMemo } from 'react'
import { BTN_TXT } from 'constants/txt'
import { Popup } from 'components/Popup'
import { Typography } from 'components/Typography'
import { Button, BUTTON_TYPES } from 'components/Button'
import { usePendingClassificationPopupContext } from '../../Providers/PendingClassificationPopupProvider'
import { usePendingClassificationEditContext } from '../../Providers/PendingClassificationEditProvider'

const ConfirmSavePopup = () => {
  const { confirmSavePopup } = usePendingClassificationPopupContext()
  const { actions } = usePendingClassificationEditContext()

  const { close } = confirmSavePopup.actions

  const Footer = useMemo(
    () => (
      <>
        <Button type={BUTTON_TYPES.DEFAULT} onClick={close} upperCase>
          {BTN_TXT.BACK_TO_EDIT}
        </Button>
        <Button
          onClick={() => {
            actions.saveChanges()
            close()
          }}
          type={BUTTON_TYPES.PRIMARY}
          upperCase
        >
          {BTN_TXT.SAVE_CHANGES}
        </Button>
      </>
    ),
    [actions, close]
  )

  return (
    <Popup {...confirmSavePopup.state} width={442} footer={Footer}>
      <Typography.Body1>
        Make sure everything is correct before proceeding. Once submitted, these
        changes will be applied.
      </Typography.Body1>
    </Popup>
  )
}

export default ConfirmSavePopup
