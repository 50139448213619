import { CheckInsListHeader } from './CheckInsListHeader'
import { CheckInsListItemsList } from './CheckInsListItemsList'
import { CheckInsListFilterModal } from './CheckInsListFilters/CheckInsListFilterModalContainer'

export const CheckInsList = () => {
  return (
    <div className="check-ins-container__list column">
      <CheckInsListHeader />
      <CheckInsListItemsList />
      <CheckInsListFilterModal />
    </div>
  )
}
