import { subject } from '@casl/ability'

import { ACTIONS, Can, SUBJECTS } from 'features/Permission'
import { Button, BUTTON_TYPES } from 'components/Button'
import { BTN_TXT } from 'constants/txt'
import { useRFPDetailsActions } from 'features/RFP/RFPDetails/Providers/RFPDetailsActionsProvider'
import { useRFPDetailsContext } from 'features/RFP/RFPDetails/Providers/RFPDetailsContextProvider'

export default () => {
  const rfpDetailsContext = useRFPDetailsContext()
  const rfpDetailsActions = useRFPDetailsActions()

  const { data } = rfpDetailsContext.state

  return (
    <Can I={ACTIONS.SUBMIT} a={subject(SUBJECTS.VOTING, { ...data })}>
      <Button
        onClick={rfpDetailsActions.handleStartVendorsSelection}
        upperCase
        type={BUTTON_TYPES.DEFAULT}
      >
        {BTN_TXT.START_VENDORS_SELECTION}
      </Button>
    </Can>
  )
}
