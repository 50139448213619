import { STATUS_TYPES_CLASSNAMES } from '../../components/Status'
import { SegmentOption } from '../../constants'

export const PRS_STATUSES = {
  CREATED: 'created',
  WAITING_FOR_REVIEW: 'waiting_for_review',
  IN_REVIEW: 'in_review',
  RCA_REVIEW: 'rca_review',
  SURVEY_CREATION: 'survey_creation',
  VOTING_IN_PROGRESS: 'voting_in_progress',
  DECISION_MAKING: 'decision_making',
  ARCHIVED: 'archived',
  DECLINED: 'declined',
  CANCELED: 'canceled',
  CLINICAL_SPONSOR_APPROVAL: 'clinical_sponsor_approval',
  TRIAL_PRE_APPROVAL: 'trial_pre_approval',
  TRIAL_REQUESTED: 'trial_requested',
  TRIAL_IN_PROGRESS: 'trial_in_progress',
  REVENUE_ASSESSMENT: 'revenue_assessment',
  RCA_APPROVED: 'rca_approved',
  RCA_DECLINED: 'rca_declined',
  TRIAL_COMPLETED: 'trial_completed'
} as const
export const PRS_STATUSES_TIMERS = {
  ...PRS_STATUSES,
  OVERALL: 'overall'
} as const

export const PRS_STATUSES_TRIAL = {
  TRIAL_PRE_APPROVAL: 'trial_pre_approval',
  TRIAL_REQUESTED: 'trial_requested',
  TRIAL_IN_PROGRESS: 'trial_in_progress',
  TRIAL_COMPLETED: 'trial_completed'
} as const

export const RCA_DECISION = {
  RCA_APPROVED: 'rca_approved',
  RCA_DECLINED: 'rca_declined'
} as const

export const PRS_RESPOND = {
  APPROVED: 'approved',
  REJECTED: 'rejected'
} as const

export const PRS_STATUSES_VALUES = {
  created: 'created',
  waiting_for_review: 'waiting_for_review',
  in_review: 'in_review',
  rca_review: 'rca_review',
  survey_creation: 'survey_creation',
  voting_in_progress: 'voting_in_progress',
  decision_making: 'decision_making',
  archived: 'archived',
  declined: 'declined',
  canceled: 'canceled',
  clinical_sponsor_approval: 'clinical_sponsor_approval',
  revenue_assessment: 'revenue_assessment'
} as const

export const PRS_STATUSES_NAME = {
  [PRS_STATUSES.CREATED]: 'Created',
  [PRS_STATUSES.WAITING_FOR_REVIEW]: 'Waiting for review',
  [PRS_STATUSES.IN_REVIEW]: 'In review',
  [PRS_STATUSES.RCA_REVIEW]: 'RCA review',
  [PRS_STATUSES.SURVEY_CREATION]: 'Survey creation',
  [PRS_STATUSES.VOTING_IN_PROGRESS]: 'Voting in progress',
  [PRS_STATUSES.DECISION_MAKING]: 'Decision making',
  [PRS_STATUSES.ARCHIVED]: 'Archived',
  [PRS_STATUSES.DECLINED]: 'Declined',
  [PRS_STATUSES.CANCELED]: 'Canceled',
  [PRS_STATUSES.CLINICAL_SPONSOR_APPROVAL]: 'Clinical sponsor approval',
  [PRS_STATUSES.REVENUE_ASSESSMENT]: 'Revenue assessment',
  [PRS_STATUSES.TRIAL_PRE_APPROVAL]: 'Trial pre-approval',
  [PRS_STATUSES.TRIAL_REQUESTED]: 'Trial requested',
  [PRS_STATUSES.TRIAL_IN_PROGRESS]: 'Trial in progress'
} as const
export const PRS_STATUSES_NAME_TIMERS = {
  ...PRS_STATUSES_NAME,
  [PRS_STATUSES_TIMERS.OVERALL]: 'Overall timer'
} as const
export const PRS_STATUSES_MESSAGES = {
  [PRS_STATUSES.CREATED]: 'Created',
  [PRS_STATUSES.WAITING_FOR_REVIEW]: 'PRS request has been sent for review.',
  [PRS_STATUSES.IN_REVIEW]: 'Product request  has been successfully approved.',
  [PRS_STATUSES.RCA_REVIEW]: 'RCA review',
  [PRS_STATUSES.SURVEY_CREATION]: 'Survey creation has been started.',
  [PRS_STATUSES.VOTING_IN_PROGRESS]: 'Voting in progress has been started.',
  [PRS_STATUSES.DECISION_MAKING]: 'Decision making has been started.',
  [PRS_STATUSES.ARCHIVED]: 'PRS request has been successfully archived.',
  [PRS_STATUSES.CLINICAL_SPONSOR_APPROVAL]:
    'Clinical sponsor review has been started',
  [PRS_STATUSES.CANCELED]: 'PRS request has been canceled. ',
  [PRS_STATUSES.DECLINED]: 'PRS has been successfully declined.',
  [PRS_STATUSES.TRIAL_PRE_APPROVAL]:
    'Trial period pre-approval has been requested.',
  [PRS_STATUSES.REVENUE_ASSESSMENT]:
    'Revenue assessment has been successfully started.',
  [PRS_STATUSES.RCA_DECLINED]: 'Response has been saved.',
  [PRS_STATUSES.RCA_APPROVED]: 'Response has been saved.',
  [PRS_STATUSES.TRIAL_REQUESTED]: 'Trial request has been sent to Vendor.',
  [PRS_STATUSES.TRIAL_IN_PROGRESS]: 'Trial period has been started.',
  [PRS_STATUSES.TRIAL_COMPLETED]: 'Trial has been completed.'
} as const

export const PRS_STATUSES_CLASSNAME = {
  [PRS_STATUSES.CREATED]: STATUS_TYPES_CLASSNAMES.GREEN,
  [PRS_STATUSES.WAITING_FOR_REVIEW]: STATUS_TYPES_CLASSNAMES.LIGHTBLUE,
  [PRS_STATUSES.IN_REVIEW]: STATUS_TYPES_CLASSNAMES.YELLOW,
  [PRS_STATUSES.RCA_REVIEW]: STATUS_TYPES_CLASSNAMES.GRAY,
  [PRS_STATUSES.SURVEY_CREATION]: STATUS_TYPES_CLASSNAMES.DARKBLUE,
  [PRS_STATUSES.VOTING_IN_PROGRESS]: STATUS_TYPES_CLASSNAMES.YELLOW,
  [PRS_STATUSES.DECISION_MAKING]: STATUS_TYPES_CLASSNAMES.DARKBLUE,
  [PRS_STATUSES.ARCHIVED]: STATUS_TYPES_CLASSNAMES.RED,
  [PRS_STATUSES.DECLINED]: STATUS_TYPES_CLASSNAMES.RED,
  [PRS_STATUSES.CANCELED]: STATUS_TYPES_CLASSNAMES.RED,
  [PRS_STATUSES.CLINICAL_SPONSOR_APPROVAL]: STATUS_TYPES_CLASSNAMES.GRAY,
  [PRS_STATUSES.REVENUE_ASSESSMENT]: STATUS_TYPES_CLASSNAMES.GRAY,
  [PRS_STATUSES.TRIAL_PRE_APPROVAL]: STATUS_TYPES_CLASSNAMES.GRAY,
  [PRS_STATUSES.TRIAL_REQUESTED]: STATUS_TYPES_CLASSNAMES.LIGHTBLUE,
  [PRS_STATUSES.TRIAL_IN_PROGRESS]: STATUS_TYPES_CLASSNAMES.YELLOW
}

export const PRS_DETAILS_BREADCRUMBS = (prsName) => [
  {
    children: 'Product Requests',
    href: '/product-requests'
  },
  {
    children: prsName
  }
]

export const PRS_DETAILS_TABS = [
  {
    key: '0',
    label: 'General'
  },
  {
    key: '1',
    label: 'Facilities'
  },
  {
    key: '2',
    label: 'Facility users'
  },
  {
    key: '3',
    label: 'Voting'
  },
  {
    key: '4',
    label: 'Trial pre-approval'
  },
  {
    key: '5',
    label: 'Result'
  }
]

export const INITIAL_HEADER_OPTIONS: SegmentOption[] = [
  {
    value: 1,
    label: 'Survey'
  },
  {
    value: 2,
    label: 'Responses',
    disabled: true
  }
]
