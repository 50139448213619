import {
  createContext,
  FC,
  PropsWithChildren,
  useContext,
  useMemo
} from 'react'

import usePopup, { PopupReturnType } from 'hooks/usePopup'

type ContextProps = {
  startPrsPopup: PopupReturnType
  createSurveyPopup: PopupReturnType
}

const PRSPopupContext = createContext<ContextProps>({
  startPrsPopup: null!,
  createSurveyPopup: null!
})

const basePopup = (title: string) => ({
  title
})

const PRSPopupProvider: FC<PropsWithChildren> = ({ children }) => {
  const startPrsPopupProps = usePopup(basePopup('Send'))
  const createSurveyPopupProps = usePopup(basePopup('Create survey'))

  const context = useMemo(
    () => ({
      startPrsPopup: startPrsPopupProps,
      createSurveyPopup: createSurveyPopupProps
    }),
    [startPrsPopupProps]
  )

  return (
    <PRSPopupContext.Provider value={context}>
      {children}
    </PRSPopupContext.Provider>
  )
}

export const usePRSPopupContext = () => useContext(PRSPopupContext)

export default PRSPopupProvider
