import Typography from '../../../components/Typography/Typography'
import { Button, BUTTON_TYPES } from '../../../components/Button'
import { BTN_TXT } from '../../../constants'
import { ReactComponent as HospitalIcon } from 'assets/svg/HospitalWithouBG.svg'
type TProps = {
  scroll: (link: string) => void
}
const LandingBanner = ({ scroll }: TProps) => {
  return (
    <div className="landing-page__banner row" id="about">
      <div className="landing-page__banner__action">
        <Typography.Headline1 className="landing-page__banner__action__title">
          Build your own Community of Value Based Contracting
        </Typography.Headline1>
        <Button
          type={BUTTON_TYPES.PRIMARY}
          upperCase
          onClick={() => scroll('contact-us')}
          className="mt-36 landing-page__banner__action__contact-us"
        >
          {BTN_TXT.CONTACT_US}
        </Button>
      </div>
      <HospitalIcon />
    </div>
  )
}

export default LandingBanner
