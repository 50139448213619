import Typography from '../../../components/Typography/Typography'

const FirstSection = () => {
  return (
    <div className="landing-page__section-first">
      <Typography.HeadlineD4 className="landing-page__section-first__title">
        Accelerate System Savings Opportunities While Maximizing Automation and
        Productivity
      </Typography.HeadlineD4>
      <Typography.Body1 className="landing-page__section-first__subtitle">
        Limited resources, increased supply costs and an ever-changing landscape
        in and around healthcare supply chain demands technology and process
        improvements. CognusHealth provides innovative solutions and promotes a
        network of highly functioning supply chain professionals.
      </Typography.Body1>
    </div>
  )
}
export default FirstSection
