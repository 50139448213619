import Typography from '../../components/Typography/Typography'
import { TYPOGRAPHY_WEIGHT } from '../../components/Typography'
import AddBankAccount from './AddAction/AddBankAccount'
import './style.scss'
import { TPropsAccount } from './types'
import { Button, BUTTON_TYPES } from '../../components/Button'
import { Tooltip } from 'components/Tooltip/Tooltip'
import { ReactComponent as Redirect16 } from 'assets/svg/Redirect16.svg'
import { TOOLTIP } from '../../constants'
import Details from './Details/Details'
import cn from 'classnames'
import { Status, STATUS_TYPES_CLASSNAMES } from '../../components/Status'
const BankAccountWrapper = ({
  linkAccount,
  dataAccount,
  ...props
}: TPropsAccount) => {
  const handleBankAccount = () => {
    window.open(linkAccount.url, '_blank')?.focus()
  }
  return (
    <div className={cn('bank-account-wrapper ant-row', props.className)}>
      <div className="bank-account-wrapper__header row align-center">
        <Typography.Headline6 weight={TYPOGRAPHY_WEIGHT.BOLD}>
          Account details
        </Typography.Headline6>
        {linkAccount.type === 'login' && dataAccount && (
          <>
            <Status.Default
              className={
                dataAccount?.payout_enabled
                  ? STATUS_TYPES_CLASSNAMES.GREEN
                  : STATUS_TYPES_CLASSNAMES.GRAY
              }
            >
              {dataAccount?.payout_enabled
                ? 'Payouts enabled'
                : 'Payouts disabled'}
            </Status.Default>
            <Tooltip
              title={TOOLTIP.OPEN_ACCOUNT_DETAILS}
              overlayInnerStyle={{
                display: 'block',
                width: 154
              }}
              placement="topLeft"
            >
              <Button
                type={BUTTON_TYPES.GHOST}
                onClick={handleBankAccount}
                upperCase
                className="bank-account-btn"
              >
                <Redirect16 />
              </Button>
            </Tooltip>
          </>
        )}
      </div>
      {linkAccount.type === 'account_onboarding' && (
        <AddBankAccount linkAccount={linkAccount} />
      )}
      {linkAccount.type === 'login' && dataAccount && (
        <Details dataAccount={dataAccount} />
      )}
    </div>
  )
}

export default BankAccountWrapper
