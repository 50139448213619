import { TCreateContractPipelineFormType } from './types'
import { requestDateFormat } from '../../../utils/moment'
import { TOption } from '../../../components/Select/types'

export const parsePipelineData = (
  values: TCreateContractPipelineFormType,
  ledByOptions: TOption[]
) => {
  const resultedData = {
    led_by: values.led_by ?? (ledByOptions[0]?.value as string),
    contract_category: values.contract_category,
    bid_start_date: values.bid_start_date.format(requestDateFormat),
    estimated_savings: values.estimated_savings
  }

  return resultedData
}
