// import { AppThunk } from 'redux/store/createRootReducer'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { moduleName } from './constants'
import { THealthSystem } from 'features/HealthSystems/types'
import { THealthSystemsListState } from './types'
import { fetchHealthSystemsList as fetchHealthSystemsListAPI } from 'features/HealthSystems/api'

const initialState: THealthSystemsListState = {
  healthSystemsList: [],
  isHealthSystemsListLoading: false,
  isHealthSystemsListLoaded: false,
  error: null
}

const healthSystemsListSlice = createSlice({
  name: moduleName,
  initialState,
  reducers: {
    getHealthSystemsListStart(state: THealthSystemsListState) {
      state.isHealthSystemsListLoading = true
      state.error = null
    },
    getHealthSystemsListFinish(state: THealthSystemsListState) {
      state.isHealthSystemsListLoading = false
      state.isHealthSystemsListLoaded = true
    },
    getHealthSystemsListError(
      state: THealthSystemsListState,
      action: PayloadAction<{ error: string }>
    ) {
      const { error } = action.payload
      state.error = error
    },
    setHealthSystemsListAction(
      state: THealthSystemsListState,
      action: PayloadAction<{ healthSystemsList: THealthSystem[] }>
    ) {
      const { healthSystemsList } = action.payload
      state.healthSystemsList = healthSystemsList
    },
    // updateHealthSystemsListAction(
    //   state: IHealthSystemsListState,
    //   action: PayloadAction<{
    //     updatedHealthSystemsList: IUpdateHealthSystem[]
    //   }>
    // ) {
    //   const { updatedHealthSystemsList } = action.payload
    //   state.healthSystemsList = {
    //     ...(state.healthSystemsList || {}),
    //     ...updatedHealthSystemsList
    //   }
    // },
    resetHealthSystemsListAction(state: THealthSystemsListState) {
      Object.keys(initialState).forEach(
        (key) =>
          ((state[key] as THealthSystemsListState[typeof key]) =
            initialState[key])
      )
    }
  }
})

export default healthSystemsListSlice.reducer
export const {
  getHealthSystemsListStart,
  getHealthSystemsListError,
  getHealthSystemsListFinish,
  setHealthSystemsListAction,
  resetHealthSystemsListAction
} = healthSystemsListSlice.actions

export const fetchHealthSystemsList =
  (uuid: string, completedCallback?: Function): any =>
  async (dispatch) => {
    try {
      dispatch(getHealthSystemsListStart())
      const result = await fetchHealthSystemsListAPI(uuid)
      if (result) {
        if (!result.data?.results) {
          return
        }

        dispatch(
          setHealthSystemsListAction({
            healthSystemsList: result.data.results as THealthSystem[]
          })
        )
        if (completedCallback) {
          completedCallback(result.data)
        }
      }
    } catch (err: any) {
      dispatch(
        getHealthSystemsListError({ error: err?.response.message as string })
      )
    } finally {
      dispatch(getHealthSystemsListFinish())
    }
  }
