import { ColumnsType } from 'antd/es/table'
import { SortOrder } from 'antd/es/table/interface'
import { Status } from 'components/Status'
import { Dropdown } from 'components/Dropdown'
import { CellWithSubtitle } from 'components/CellWithSubtitle/CellWithSubtitle'
import { Sorter } from 'components/Sorter/Sorter'
import {
  PRS_STATUSES_OPTIONS_FILTER,
  PRS_STATUSES_OPTIONS_FILTER_VENDOR
} from 'constants/common'
import { ReactComponent as Filter16 } from 'assets/svg/Filter16.svg'
import { ReactComponent as FilterApplied16 } from 'assets/svg/FilterApplied16.svg'
import { fetchContractCategories } from 'pages/CRUDUser/api'
import {
  PRS_STATUSES,
  PRS_STATUSES_CLASSNAME,
  PRS_STATUSES_NAME,
  PRS_STATUSES_TRIAL
} from '../../pages/ProductRequests/constants'
import {
  TProductRequestsColumnsFunc,
  TProductRequestsList,
  TProductRequestsListFilters,
  TProductRequestsListFiltersFunc
} from './api'
import { durationAsString } from '../../helper/dates'
import moment from 'moment/moment'
import { currencyFormatter } from '../ContractDetails/ContractDetailsGeneral/ContractDetailsForm/utils'
import { safeNumber } from '../../helper/common'

export const columns = ({
  applyFilters,
  filters,
  isVendor,
  handleSelectedRow
}: TProductRequestsColumnsFunc): ColumnsType<TProductRequestsList> => [
  {
    dataIndex: 'name',
    key: 'name',
    width: 240,
    title: ({ sortOrder, sortColumn }) => (
      <Sorter<TProductRequestsList>
        dataIndex="name"
        sortColumn={sortColumn}
        sortOrder={sortOrder as SortOrder}
      >
        PRS name
      </Sorter>
    ),
    render: (_, { name, number, uuid }) => (
      <div>
        <CellWithSubtitle
          title={
            <div
              className="prs-link-row"
              onClick={() => handleSelectedRow(uuid)}
            >
              {name}
            </div>
          }
          subtitle={number}
        />
      </div>
    ),
    sorter: true,
    showSorterTooltip: false
  },
  {
    dataIndex: 'contract_category_name',
    key: 'contract_category_name',
    title: ({ sortOrder, sortColumn }) => (
      <Sorter<TProductRequestsList>
        dataIndex="contract_category_name"
        sortColumn={sortColumn}
        sortOrder={sortOrder as SortOrder}
      >
        Contract category
      </Sorter>
    ),
    width: 240,
    filterDropdown: (props) => (
      <Dropdown.Filter<
        TProductRequestsListFilters,
        TProductRequestsListFiltersFunc,
        unknown
      >
        applyFilters={applyFilters}
        appliedFilters={filters}
        searchCallback={fetchContractCategories}
        field="contract_categories"
        asyncSearch
        {...props}
      />
    ),
    render: (_, { contract_category_name }) => (
      <CellWithSubtitle title={contract_category_name} />
    ),
    filterIcon: !!filters?.contract_categories?.length ? (
      <FilterApplied16 />
    ) : (
      <Filter16 />
    ),
    filterSearch: true,
    sorter: true,
    showSorterTooltip: false
  },
  {
    dataIndex: isVendor ? 'community_name' : 'vendor_name',
    key: isVendor ? 'community_name' : 'vendor_name',
    width: 240,
    title: ({ sortOrder, sortColumn }) => (
      <Sorter<TProductRequestsList>
        dataIndex={isVendor ? 'community_name' : 'vendor_name'}
        sortColumn={sortColumn}
        sortOrder={sortOrder as SortOrder}
      >
        {isVendor ? 'Community name' : 'Vendor name'}
      </Sorter>
    ),
    render: (_, { vendor_name, community_name }) => (
      <CellWithSubtitle title={isVendor ? community_name : vendor_name} />
    ),
    filterSearch: true,
    sorter: true,
    showSorterTooltip: false
  },
  {
    dataIndex: 'time_left',
    key: 'time_left',
    width: 123,
    title: ({ sortOrder, sortColumn }) => (
      <Sorter<TProductRequestsList>
        dataIndex="time_left"
        sortColumn={sortColumn}
        sortOrder={sortOrder as SortOrder}
      >
        Time left
      </Sorter>
    ),
    render: (_, { time_left }) =>
      time_left ? durationAsString(moment(), time_left) : '–',
    sorter: true,
    showSorterTooltip: false
  },
  {
    dataIndex: 'status',
    key: 'status',
    width: 148,
    title: ({ sortOrder, sortColumn }) => (
      <Sorter<TProductRequestsList>
        dataIndex="status"
        sortColumn={sortColumn}
        sortOrder={sortOrder as SortOrder}
      >
        Status
      </Sorter>
    ),
    filterDropdown: (props) => (
      <Dropdown.Filter<
        TProductRequestsListFilters,
        TProductRequestsListFiltersFunc,
        unknown
      >
        items={
          isVendor
            ? PRS_STATUSES_OPTIONS_FILTER_VENDOR
            : PRS_STATUSES_OPTIONS_FILTER
        }
        applyFilters={applyFilters}
        appliedFilters={filters}
        field="statuses"
        {...props}
      />
    ),
    render: (_, { status, trial_status }) => (
      <div className="column prs-list-status-content" style={{ gap: 4 }}>
        {!(
          trial_status &&
          status === PRS_STATUSES.IN_REVIEW &&
          trial_status !== PRS_STATUSES_TRIAL.TRIAL_COMPLETED
        ) && (
          <Status.Default className={PRS_STATUSES_CLASSNAME[status]}>
            {PRS_STATUSES_NAME[status]}
          </Status.Default>
        )}
        {trial_status && (
          <Status.Default className={PRS_STATUSES_CLASSNAME[trial_status]}>
            {PRS_STATUSES_NAME[trial_status]}
          </Status.Default>
        )}
      </div>
    ),
    filterIcon: !!filters?.statuses?.length ? (
      <FilterApplied16 />
    ) : (
      <Filter16 />
    ),
    filterSearch: true,
    sorter: true,
    showSorterTooltip: false
  },
  {
    dataIndex: 'price',
    key: 'price',
    width: 150,
    title: ({ sortOrder, sortColumn }) => (
      <Sorter<TProductRequestsList>
        dataIndex="price"
        sortColumn={sortColumn}
        sortOrder={sortOrder as SortOrder}
      >
        Price, $
      </Sorter>
    ),
    render: (_, { price }) => (
      <CellWithSubtitle
        className="prs-table-price-row"
        title={currencyFormatter(safeNumber(price).toFixed(2))}
      />
    ),
    filterSearch: true,
    sorter: true,
    showSorterTooltip: false
  }
]
