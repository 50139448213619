import Button from 'components/Button/Button'
import { BUTTON_TYPES } from 'components/Button'
import { BTN_TXT } from '../../../constants'
import { useCalendarEventContext } from 'pages/Calendar/CalendarEventsProvider'
import Popup from 'components/Popup/Popup'
import Typography from 'components/Typography/Typography'
import { Checkbox } from 'components/Checkbox'
import { useEffect, useState } from 'react'
import { useCalendarPopup } from 'pages/Calendar/Provider/CalendarPopupProvider'

export const DeleteContainer = () => {
  const { deleteEventPopup } = useCalendarPopup()

  const handleModal = () => {
    deleteEventPopup.actions.open()
  }
  return (
    <Button
      type={BUTTON_TYPES.DEFAULT}
      onClick={handleModal}
      upperCase
      danger
      medium
      className="mr-8"
      onMouseDown={(evt) => {
        evt.stopPropagation()
        evt.preventDefault()
      }}
    >
      {BTN_TXT.DELETE}
    </Button>
  )
}

export const DeleteModal = () => {
  const { deleteEventPopup } = useCalendarPopup()
  const { state, actions } = deleteEventPopup

  const { actions: calendarActions, state: calendarState } =
    useCalendarEventContext()

  const { calendarEventDetails } = calendarState

  const onDelete = () => {
    if (calendarEventDetails)
      calendarActions.deleteEventDetails(
        {
          id: calendarEventDetails?.uuid,
          sendEmails: checked
        },
        () => {
          actions.close()
        }
      )
  }

  const [checked, setChecked] = useState<boolean>(false)

  const eventHasParticipants =
    calendarEventDetails?.participants &&
    calendarEventDetails?.participants?.length > 0

  useEffect(() => {
    if (!state.visible) setChecked(false)
  }, [state])

  return (
    <div className="delete-modal">
      <Popup
        visible={state.visible}
        onCancel={actions.close}
        title="Delete event?"
        width={440}
        zIndex={10000}
        footer={
          <>
            <Button
              type={BUTTON_TYPES.DEFAULT}
              onClick={actions.close}
              upperCase
            >
              {BTN_TXT.CANCEL}
            </Button>
            <Button
              type={BUTTON_TYPES.PRIMARY}
              onClick={onDelete}
              upperCase
              danger
            >
              {BTN_TXT.DELETE}
            </Button>
          </>
        }
      >
        <div className="column">
          <Typography.Body1>
            {calendarEventDetails?.name} will be permanently deleted. Once
            deleted, it will be removed from the list
            {eventHasParticipants &&
              ' and all guests will no longer be able to see it'}
            . Do you want to proceed with deleting the event?
          </Typography.Body1>
          {eventHasParticipants ? (
            <Checkbox
              propsCheckbox={{
                checked,
                onChange: (e) => setChecked(e.target.checked)
              }}
              className="mt-8"
            >
              Send emails to the invited guests
            </Checkbox>
          ) : null}
        </div>
      </Popup>
    </div>
  )
}
