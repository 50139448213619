import { RFP_STATUS_VALUES } from 'features/RFP/constants'
import { BTN_TXT } from 'constants/txt'

export const STATUS_SCHEME = {
  [RFP_STATUS_VALUES.created]: {
    next: RFP_STATUS_VALUES.vendor_acceptance,
    actionBtnText: BTN_TXT.SEND_RFP
  },
  [RFP_STATUS_VALUES.vendor_acceptance]: {
    next: RFP_STATUS_VALUES.bidding_qa,
    actionBtnText: BTN_TXT.START_BIDDING_QA
  },
  [RFP_STATUS_VALUES.bidding_qa]: {
    next: RFP_STATUS_VALUES.bidding_in_progress,
    actionBtnText: BTN_TXT.START_BIDDING
  },
  [RFP_STATUS_VALUES.bidding_in_progress]: {
    next: RFP_STATUS_VALUES.price_files_analysis,
    actionBtnText: BTN_TXT.START_PRICE_FILE_ANALYSIS
  },
  [RFP_STATUS_VALUES.price_files_analysis]: {
    next: RFP_STATUS_VALUES.voting_in_progress,
    actionBtnText: null
  },
  [RFP_STATUS_VALUES.voting_in_progress]: {
    next: RFP_STATUS_VALUES.vendors_selection,
    actionBtnText: null
  }
}
export const STATUS_SCHEME_CLC = {
  [RFP_STATUS_VALUES.created]: {
    next: RFP_STATUS_VALUES.waiting_for_assignment,
    actionBtnText: BTN_TXT.SEND_TO_COMMONS
  },
  [RFP_STATUS_VALUES.waiting_for_assignment]: {
    next: RFP_STATUS_VALUES.in_cs_queue,
    actionBtnText: BTN_TXT.SEND_TO_COMMONS
  },
  [RFP_STATUS_VALUES.vendor_acceptance]: {
    next: RFP_STATUS_VALUES.bidding_qa,
    actionBtnText: BTN_TXT.START_BIDDING_QA
  },
  [RFP_STATUS_VALUES.bidding_qa]: {
    next: RFP_STATUS_VALUES.bidding_in_progress,
    actionBtnText: BTN_TXT.START_BIDDING
  },
  [RFP_STATUS_VALUES.bidding_in_progress]: {
    next: RFP_STATUS_VALUES.price_files_analysis,
    actionBtnText: BTN_TXT.START_PRICE_FILE_ANALYSIS
  },
  [RFP_STATUS_VALUES.price_files_analysis]: {
    next: RFP_STATUS_VALUES.voting_in_progress,
    actionBtnText: null
  },
  [RFP_STATUS_VALUES.voting_in_progress]: {
    next: RFP_STATUS_VALUES.vendors_selection,
    actionBtnText: null
  }
}
