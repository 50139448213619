import moment from 'moment'
import { SortOrder } from 'antd/es/table/interface'
import { ColumnsType } from 'antd/es/table'
import CreatorCell from './CreatorCell'
import { Sorter } from '../../components/Sorter/Sorter'
import { DescriptionCell } from '../../components/DescriptionCell/DescriptionCell'
import { Datepicker } from '../../components/Datepicker'
import { Dropdown } from '../../components/Dropdown'
import { HistoryCell } from './HistoryCell'
import { ReactComponent as Filter16 } from '../../assets/svg/Filter16.svg'
import { ReactComponent as FilterApplied16 } from '../../assets/svg/FilterApplied16.svg'
import { getShipToHospitalOptions } from './api'
import { currencyFormatter } from '../ContractDetails/ContractDetailsGeneral/ContractDetailsForm/utils'
import { timelineDateFormat } from '../../components/Timelines/constants'
import {
  TInvoiceFiltersFunc,
  TInvoiceList,
  TInvoicesColumnsFunc,
  TInvoicesFilters
} from './types'

export const columns = ({
  applyFilters,
  filters
}: TInvoicesColumnsFunc): ColumnsType<TInvoiceList> => [
  {
    dataIndex: 'document_key',
    key: 'document_key',
    width: 160,
    title: ({ sortOrder, sortColumn }) => (
      <Sorter<TInvoiceList>
        dataIndex="document_key"
        sortColumn={sortColumn}
        sortOrder={sortOrder as SortOrder}
      >
        Document key
      </Sorter>
    ),
    sorter: true,
    showSorterTooltip: false
  },
  {
    dataIndex: 'ship_to_entity_hospital',
    key: 'ship_to_entity_hospital',
    width: 420,
    title: 'Ship to Entity (Hospital)',
    render: (_, { ship_to_entity_hospital }) => {
      const text = ship_to_entity_hospital.map((i) => i.name).join(', ')
      return <DescriptionCell content={text} text={text} />
    },
    filterDropdown: (props) => (
      <Dropdown.Filter<TInvoicesFilters, TInvoiceFiltersFunc, unknown>
        applyFilters={applyFilters}
        appliedFilters={filters}
        searchCallback={getShipToHospitalOptions}
        field="ship_to_entity_hospital"
        asyncSearch
        {...props}
      />
    ),
    filterIcon: !!filters?.ship_to_entity_hospital?.length ? (
      <FilterApplied16 />
    ) : (
      <Filter16 />
    ),
    filterSearch: true
  },
  {
    dataIndex: 'creator',
    key: 'creator',
    width: 504,
    title: 'Uploaded by',
    render: (_, { creator }) => <CreatorCell creator={creator} />,
    showSorterTooltip: false
  },
  {
    dataIndex: 'invoice_date',
    key: 'invoice_date',
    width: 168,
    title: ({ sortOrder, sortColumn }) => (
      <Sorter<TInvoiceList>
        dataIndex="invoice_date"
        sortColumn={sortColumn}
        sortOrder={sortOrder as SortOrder}
      >
        Invoice date
      </Sorter>
    ),
    render: (_, { invoice_date }) =>
      invoice_date ? moment(invoice_date).format(timelineDateFormat) : '-',
    filterDropdown: (props) => (
      <div className="filter-dropdown-table-range">
        <Datepicker.Filter<TInvoicesFilters, TInvoiceFiltersFunc, unknown>
          applyFilters={applyFilters}
          appliedFilters={filters}
          field="invoice_date"
          {...props}
          prefixCls="range-picker-custom ant-picker"
          open={props.visible}
          rangeClassName="filter-dropdown-table-range-picker"
          placement="topLeft"
        />
      </div>
    ),
    filterIcon: !!filters?.invoice_date?.length ? (
      <FilterApplied16 />
    ) : (
      <Filter16 />
    ),
    filterSearch: true,
    sorter: true,
    showSorterTooltip: false
  },
  {
    dataIndex: 'invoice_amount',
    key: 'invoice_amount',
    width: 168,
    title: ({ sortOrder, sortColumn }) => (
      <Sorter<TInvoiceList>
        dataIndex="invoice_amount"
        sortColumn={sortColumn}
        sortOrder={sortOrder as SortOrder}
      >
        Invoice Amount, $
      </Sorter>
    ),
    render: (_, { invoice_amount }) => currencyFormatter(invoice_amount),
    sorter: true,
    showSorterTooltip: false
  },
  {
    dataIndex: 'actions',
    key: 'actions',
    width: 64,
    render: (_, { document_key }) => <HistoryCell documentKey={document_key} />,
    showSorterTooltip: false
  }
]

export const INVOICE_DATA_TABS = [
  {
    key: '0',
    label: 'Invoices'
  },
  {
    key: '1',
    label: 'Rejected Invoices'
  },
  {
    key: '2',
    label: 'Non-PO Invoices'
  },
  {
    key: '3',
    label: 'Pending Classification'
  }
]
