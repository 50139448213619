import {
  createContext,
  FC,
  PropsWithChildren,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState
} from 'react'
import { useDispatch } from 'react-redux'

import useInfiniteData from 'hooks/useInfiniteData'
import { THistoryGoals } from './types'
import { setLoading } from '../../../redux/store/common/slice'
import { getHistorySavings } from '../api'
import { TQueryParams } from '../../../components/Table/types'
import { useDashboardPopup } from './DashboardPopupProvider'

type ContextProps = {
  state: {
    hasNextPage: boolean
    history: THistoryGoals
    total: number
  }
  actions: {
    handleLoadMore: VoidFunction
    setHealthSystem: (val: string) => void
  }
}

const DashboardHistoryContext = createContext<ContextProps>({
  state: null!,
  actions: null!
})

const DashboardHistoryContextProvider: FC<PropsWithChildren> = ({
  children
}) => {
  const dispatch = useDispatch()

  const { historyGoalsPopup } = useDashboardPopup()

  const [total, setTotal] = useState(0)
  const [healthSystem, setHealthSystem] = useState<string>('')

  const [history, setHistory] = useState<THistoryGoals>([])

  const loadMore = useCallback(
    async (args: TQueryParams) => {
      if (!healthSystem) {
        throw new Error('Health System ID not provided')
      }

      const resp = await getHistorySavings({
        params: { ...args, health_system: healthSystem }
      })
      if (resp.data?.results) {
        setHistory((prev) => [...prev, ...resp.data.results])
      }
    },
    [healthSystem]
  )
  const { limit, hasNextPage, handleLoadMore } = useInfiniteData({
    total,
    limit: 50,
    loadMore
  })
  const handleGetHistory = useCallback(async () => {
    dispatch(setLoading(true))
    if (!healthSystem) {
      throw new Error('Health System ID not provided')
    }
    try {
      const resp = await getHistorySavings({
        params: {
          health_system: healthSystem,
          limit: limit || 0
        }
      })
      if (resp.data?.results) {
        setHistory(resp.data.results)
        setTotal(resp.data.count)
      }
    } catch (e) {
      console.error(e)
    } finally {
      dispatch(setLoading(false))
    }
  }, [dispatch, healthSystem])

  useEffect(() => {
    if (healthSystem && historyGoalsPopup.state.visible) handleGetHistory()
  }, [healthSystem, historyGoalsPopup])

  const context = useMemo(
    () => ({
      state: {
        history,
        hasNextPage,
        total
      },
      actions: {
        handleLoadMore,
        setHealthSystem
      }
    }),
    [history, hasNextPage, handleLoadMore, total, setHealthSystem]
  )

  return (
    <DashboardHistoryContext.Provider value={context}>
      {children}
    </DashboardHistoryContext.Provider>
  )
}

export const useDashboardHistoryContext = () =>
  useContext(DashboardHistoryContext)

export default DashboardHistoryContextProvider
