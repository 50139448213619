import {
  createContext,
  FC,
  PropsWithChildren,
  useCallback,
  useContext,
  useMemo
} from 'react'
import { usePRSDetailsContext } from './PRSDetailsContextProvider'

import { usePRSDetailsPopupContext } from './PRSDetailsPopupProvider'
import { updatePRS } from '../../../../pages/ProductRequests/api'
import { useDispatch } from 'react-redux'
import { setLoading } from '../../../../redux/store/common/slice'
import { useParams } from 'react-router-dom'

type ContextProps = {
  handleSendSurvey: VoidFunction
  handleSendPrs: VoidFunction
  assignClinicalSponsor: (data) => void
}

const PRSDetailsActionsContext = createContext<ContextProps>({
  handleSendSurvey: () => {},
  handleSendPrs: () => {},
  assignClinicalSponsor: () => {}
})

const PRSDetailsActionsProvider: FC<PropsWithChildren> = ({ children }) => {
  const { sendPrsPopup } = usePRSDetailsPopupContext()
  const { assignClinicalSponsorPopup } = usePRSDetailsPopupContext()

  const dispatch = useDispatch()

  const { id } = useParams()

  const detailsContext = usePRSDetailsContext()

  const handleSendSurvey = useCallback(() => {
    sendPrsPopup.actions.open()
  }, [detailsContext.actions, sendPrsPopup.actions])

  const handleSendPrs = useCallback(() => {
    sendPrsPopup.actions.open()
  }, [detailsContext.actions, sendPrsPopup.actions])

  const assignClinicalSponsor = useCallback(
    async (uuid: string) => {
      dispatch(setLoading(true))
      await updatePRS(
        id as string,
        { clinical_sponsor_stakeholder: uuid },
        'Clinical Sponsor has been assigned.'
      )
        .then(() => {
          assignClinicalSponsorPopup.actions.close()
          detailsContext.actions.handleGetPRSData()
        })
        .finally(() => dispatch(setLoading(false)))
    },
    [detailsContext.actions, sendPrsPopup.actions]
  )

  const context = useMemo(
    () => ({
      handleSendSurvey,
      handleSendPrs,
      assignClinicalSponsor
    }),
    [handleSendSurvey, handleSendPrs, assignClinicalSponsor]
  )

  return (
    <PRSDetailsActionsContext.Provider value={context}>
      {children}
    </PRSDetailsActionsContext.Provider>
  )
}

export const usePRSDetailsActions = () => useContext(PRSDetailsActionsContext)

export default PRSDetailsActionsProvider
