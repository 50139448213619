import { useEffect, useMemo } from 'react'
import { useSelector } from 'react-redux'
import { Routes, Route, Navigate, useParams } from 'react-router-dom'
import { Container } from 'components/Container/Container'

import { General } from './General/General'
import { Menu } from 'components/Menu/Menu'
import { Actions } from 'features/RFP/RFPDetails/Actions/Actions'
import { RFPItemList } from 'features/RFP/RFPItemList/RFPItemList'
import { RFPVendorUsers } from 'features/RFP/RFPVendorUsers/RFPVendorUsers'
import { VendorActions } from 'features/RFP/RFPDetails/VendorActions/VendorActions'
import { RFPHealthSystemUsers } from 'features/RFP/RFPHealthSystemUsers/RFPHealthSystemUsers'
import { useRFPDetailsContext } from 'features/RFP/RFPDetails/Providers/RFPDetailsContextProvider'
import { RFPBidding } from 'features/RFP/RFPBidding/RFPBidding'
import useRouter from 'hooks/useRouter'
import { useRFPDetailsPopupContext } from './Providers/RFPDetailsPopupProvider'
import { getUser } from 'redux/store/user/getters'
import { RFP_STATUSES, RFP_ACCEPTANCE_STATUSES } from '../constants'
import {
  initialUpdateData,
  RFP_DETAILS_BREADCRUMBS,
  RFP_DETAILS_TABS
} from './constants'
import { RFPVoting } from 'features/RFP/RFPVoting'
import { ACTIONS, Can, SUBJECTS } from 'features/Permission'
import { subject } from '@casl/ability'
import { RFPResults } from '../RFPResults/RFPResults'
import { RFPVendorsUsers } from '../RFPVendorsUsers/RFPVendorsUsers'
import { safeString } from '../../../helper/common'
import { RFPDetailsStatus } from './RFPDetailsStatus'
import ReasonComponent from './ReasonComponent/ReasonComponent'

const HIDE_TIMER_IN_STATUSES = [
  RFP_STATUSES.BIDDING_QA,
  RFP_STATUSES.BIDDING_IN_PROGRESS,
  RFP_STATUSES.PRICE_FILES_ANALYSIS,
  RFP_STATUSES.VOTING_IN_PROGRESS,
  RFP_STATUSES.VENDORS_SELECTION,
  RFP_STATUSES.ARCHIVED
] as ValueOfObject<typeof RFP_STATUSES>[]

export const RFPDetails = () => {
  const { push } = useRouter()
  const { state, actions } = useRFPDetailsContext()
  const { sendRFPPopup } = useRFPDetailsPopupContext()
  const {
    data,
    tabs,
    activeTab,
    biddingData,
    isVendorNotAnswerRfp,
    isCanManageTimelines
  } = state
  const { setActiveTab, setIsUpdate } = actions
  const user = useSelector(getUser)
  const { tab } = useParams()
  const isVendor = !!user.vendor
  const renderedActions = useMemo(
    () => (isVendor ? <VendorActions /> : <Actions />),
    [isVendor]
  )

  useEffect(() => {
    setActiveTab(safeString(tab))
  }, [tab])

  useEffect(() => {
    setIsUpdate(
      data.status === RFP_STATUSES.VENDOR_ACCEPTANCE ||
        data.status === RFP_STATUSES.BIDDING_QA ||
        data.status === RFP_STATUSES.BIDDING_IN_PROGRESS ||
        data.status === RFP_STATUSES.PRICE_FILES_ANALYSIS
        ? { biddingUpdate: true }
        : data.status === RFP_STATUSES.VOTING_IN_PROGRESS ||
          data.status === RFP_STATUSES.VENDORS_SELECTION
        ? { surveyUpdate: true }
        : initialUpdateData
    )
  }, [data])

  if (isVendor && data.status === RFP_STATUSES.CREATED) {
    return null
  }

  const hideMainStatusAndTimer =
    isVendor &&
    biddingData?.acceptance_status !== RFP_ACCEPTANCE_STATUSES.ACCEPTED &&
    HIDE_TIMER_IN_STATUSES.includes(data.status)

  const openPopup = () => {
    sendRFPPopup.actions.open()
  }

  return (
    <Container
      title={data.uuid ? data.name : 'RFP Creation'}
      subtitle={data.number}
      breadcrumbs={RFP_DETAILS_BREADCRUMBS(
        data.uuid ? data.name : 'RFP creation'
      )}
      reasonComponent={<ReasonComponent />}
      expiresAt={hideMainStatusAndTimer ? '' : data.expire_at}
      onClickTimer={isCanManageTimelines ? openPopup : () => {}}
      className="rfp-details"
      status={
        <RFPDetailsStatus
          vendorStatus={
            biddingData?.acceptance_status as ValueOfObject<
              typeof RFP_ACCEPTANCE_STATUSES
            >
          }
          rfpStatus={hideMainStatusAndTimer ? undefined : data.status}
        />
      }
      actions={!isVendorNotAnswerRfp && renderedActions}
      menu={
        <Menu
          mode="horizontal"
          onSelect={(e) => {
            setActiveTab(e.key)
            push(e.key)
          }}
          selectedKeys={[activeTab]}
          defaultSelectedKeys={[activeTab]}
          items={tabs}
        />
      }
    >
      <Routes>
        <Route
          path={RFP_DETAILS_TABS[0].key}
          element={<General isVendor={isVendor} />}
        />
        <Route
          path={RFP_DETAILS_TABS[1].key}
          element={
            <RFPItemList
              disableActions={
                data.status !== RFP_STATUSES.CREATED &&
                data.status !== RFP_STATUSES.WAITING_FOR_ASSIGNMENT &&
                data.status !== RFP_STATUSES.IN_CS_QUEUE
              }
            />
          }
        />
        <Route
          path={RFP_DETAILS_TABS[2].key}
          element={
            <RFPVendorUsers
              disableActions={
                data.status !== RFP_STATUSES.CREATED &&
                data.status !== RFP_STATUSES.WAITING_FOR_ASSIGNMENT &&
                data.status !== RFP_STATUSES.IN_CS_QUEUE
              }
            />
          }
        />
        <Route
          path={RFP_DETAILS_TABS[3].key}
          element={
            <RFPHealthSystemUsers
              disableActions={
                data.status !== RFP_STATUSES.CREATED &&
                data.status !== RFP_STATUSES.WAITING_FOR_ASSIGNMENT &&
                data.status !== RFP_STATUSES.IN_CS_QUEUE
              }
            />
          }
        />
        <Route
          path={RFP_DETAILS_TABS[5].key}
          element={
            <RFPVendorsUsers
              disableActions={
                data.status !== RFP_STATUSES.ARCHIVED &&
                data.status !== RFP_STATUSES.CANCELED
              }
            />
          }
        />
        <Route
          path={RFP_DETAILS_TABS[4].key}
          element={
            <Can I={ACTIONS.VIEW} a={subject(SUBJECTS.VOTING, { ...data })}>
              <RFPVoting />
            </Can>
          }
        />
        <Route
          path={RFP_DETAILS_TABS[7].key}
          element={
            data.status === RFP_STATUSES.ARCHIVED && (
              <Can
                I={ACTIONS.VIEW}
                a={subject(SUBJECTS.RFP_RESULTS, { ...data })}
              >
                <RFPResults />
              </Can>
            )
          }
        />
        <Route path={RFP_DETAILS_TABS[6].key} element={<RFPBidding />} />
        <Route
          index
          element={<Navigate to={RFP_DETAILS_TABS[0].key} replace />}
        />
      </Routes>
    </Container>
  )
}
