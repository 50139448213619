import { Typography, TYPOGRAPHY_WEIGHT } from '../Typography'
import { termsOfServicesText } from './contstants'
import './style.scss'

const TermsOfServices = () => {
  return (
    <>
      {termsOfServicesText.map((i, idx) => (
        <div key={`terms-wrapper-${idx}`}>
          {i.title && (
            <Typography.Headline5
              weight={TYPOGRAPHY_WEIGHT.SEMI_BOLD}
              key={`terms-title-${idx}`}
            >
              {i.title}
            </Typography.Headline5>
          )}
          {i.text && (
            <Typography.Body1
              className="terms-subtitle"
              key={`terms-text-${idx}`}
              dangerouslySetInnerHTML={{ __html: i.text }}
            />
          )}
        </div>
      ))}
    </>
  )
}

export default TermsOfServices
