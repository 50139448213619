import { useMemo } from 'react'
import { useSelector } from 'react-redux'
import { LoadingOutlined } from '@ant-design/icons'
import { getIsLoading } from 'redux/store/chats/getters'
import InfiniteList from 'components/InfiniteList/InfiniteList'
import ListItem from 'features/ChatList/ListItem/ListItem'
import { useChatListContext } from 'features/ChatList/Providers/ChatListContextProvider'
import { EmptyChats } from './Empty'
import { IChat } from 'redux/store/chats/types'
import './styles.scss'

const List = () => {
  const isLoading = useSelector(getIsLoading)
  const chatListContext = useChatListContext()
  const Loader = useMemo(
    () => <LoadingOutlined className="chat-list-feature-list__spinner" spin />,
    []
  )

  return (
    <InfiniteList<IChat>
      items={chatListContext.state.chats}
      loader={Loader}
      renderItem={ListItem}
      loading={isLoading}
      keyExtractor={(item) => item.uuid}
      hasNextPage={chatListContext.state.hasNextPage}
      listEmptyComponent={<EmptyChats />}
      onLoadMore={chatListContext.actions.handleLoadMore}
      className="chat-list-feature-list"
    />
  )
}

export default List
