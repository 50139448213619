import { createContext, FC, PropsWithChildren, useMemo } from 'react'
import { useSelector } from 'react-redux'
import { createContextualCan, useAbility } from '@casl/react'

import { getUser } from 'redux/store/user/getters'
import {
  ability,
  defineAbility,
  ContractAbility
} from 'features/ContractsPipelineDetails/Abilities'

export const ContractPipelineDetailsAbilityContext =
  createContext<ContractAbility>(ability)

const ContractPipelineDetailsAbilityProvider: FC<PropsWithChildren> = ({
  children
}) => {
  const user = useSelector(getUser)

  const ability = useMemo(() => defineAbility(user), [user])

  return (
    <ContractPipelineDetailsAbilityContext.Provider value={ability}>
      {children}
    </ContractPipelineDetailsAbilityContext.Provider>
  )
}

export const useCPRDetailsAbility = () =>
  useAbility<ContractAbility>(ContractPipelineDetailsAbilityContext)

export const Can = createContextualCan<any>(
  ContractPipelineDetailsAbilityContext.Consumer
)

export default ContractPipelineDetailsAbilityProvider
