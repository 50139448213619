import { Input } from '../../../../../../components/Input'
import { TComponent } from './types'
import { usePRSVotingContext } from '../../../Providers/PRSVotingContext'
import { usePRSDetailsContext } from '../../../Providers/PRSDetailsContextProvider'
import { useMemo } from 'react'

const Text = ({ options, isClinicalRationale }: TComponent) => {
  const { state } = usePRSVotingContext()
  const { votingResponse } = state

  const prsDetailsContext = usePRSDetailsContext()
  const { clinicalRationaleResponse } = prsDetailsContext.state

  const response = useMemo(() => {
    return isClinicalRationale ? clinicalRationaleResponse : votingResponse
  }, [votingResponse, clinicalRationaleResponse])
  return (
    <Input.TextArea
      propsTextArea={{
        disabled: true,
        value: response.get(options[0].uuid)?.text
      }}
    />
  )
}

export default Text
