import {
  currencyFormatter,
  currencyParser
} from 'features/ContractDetails/ContractDetailsGeneral/ContractDetailsForm/utils'

export const REBATE_CONDITIONS_TYPE = {
  NO_REBATE: 'no_rebate',
  FIXED_REBATE: 'fixed',
  PERCENTAGE_REBATE: 'percentage',
  GROWTH_REBATE: 'growth'
} as const
export const REBATE_CONDITIONS_TITLE = {
  no_rebate: 'No rebate',
  fixed: 'Fixed',
  percentage: 'Percentage',
  growth: 'Growth'
} as const

export const REBATE_CONDITIONS_TYPE_NAME = {
  [REBATE_CONDITIONS_TYPE.NO_REBATE]: 'No rebate',
  [REBATE_CONDITIONS_TYPE.FIXED_REBATE]: 'Fixed rebate',
  [REBATE_CONDITIONS_TYPE.PERCENTAGE_REBATE]: 'Percentage rebate',
  [REBATE_CONDITIONS_TYPE.GROWTH_REBATE]: 'Growth rebate'
}

export const REBATE_CONDITIONS_TYPE_OPTIONS = Object.values(
  REBATE_CONDITIONS_TYPE
).map((type) => ({
  label: REBATE_CONDITIONS_TYPE_NAME[type],
  value: type
}))

export const INITIAL_REBATE_CONDITIONS = {
  [REBATE_CONDITIONS_TYPE.NO_REBATE]: {},
  [REBATE_CONDITIONS_TYPE.FIXED_REBATE]: { fixed_amount: 0 },
  [REBATE_CONDITIONS_TYPE.PERCENTAGE_REBATE]: { percentage: 0 },
  [REBATE_CONDITIONS_TYPE.GROWTH_REBATE]: {
    amount_from: 0,
    amount_to: 0,
    percentage: 0
  }
}

export const PROPS_INPUT_NUMBER = {
  min: '0',
  maxLength: 64,
  controls: false,
  formatter: currencyFormatter,
  parser: currencyParser
}
