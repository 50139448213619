import { Tag } from 'antd'
import cn from 'classnames'
import { Typography } from 'components/Typography'
import { TChipsProps } from './types'
import './styles.scss'

export const Default = ({
  children,
  className,
  uuid,
  onDelete,
  grey,
  ...props
}: TChipsProps) => {
  const { onClose } = props
  const onCloseTag = (e) => {
    if (uuid && onDelete) {
      onDelete(uuid)
      return
    }
    if (onClose) {
      onClose(e)
    }
  }
  return (
    <Tag
      className={cn('custom-chips', className, {
        'grey-chips text-12': grey
      })}
      {...props}
      onClose={onCloseTag}
    >
      {uuid ? children : <Typography.Body2>{children}</Typography.Body2>}
    </Tag>
  )
}
