import { useState, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { useParams } from 'react-router-dom'
import { Form } from 'antd'
import cn from 'classnames'
import { Input } from 'components/Input'
import { Select } from 'components/Select'
import { setLoading } from 'redux/store/common/slice'
import { fetchContractCategories } from 'pages/CRUDUser/api'
import { LABELS, PLACEHOLDERS } from 'constants/txt'
import { validateMessages } from 'helper/common'
import { TOption } from 'components/Select/types'
import {
  TFormPRSCreationFormProps,
  TFormPRSCreationForm
} from './forms.prsCreation.d'
import './forms.prsCreation.scss'
import { formatOptionNameValue } from '../../helper/optionsFormatters'
import { PROPS_INPUT_NUMBER } from '../../features/ContractDetails/RebateConditions/RebateConditionsForm/constants'
import { TPRSGeneralDetailsResponse } from '../../features/PRS/PRSDetails/types'
import { usePRSDetailsContext } from 'features/PRS/PRSDetails/Providers/PRSDetailsContextProvider'
import useRouter from '../../hooks/useRouter'
import { routes } from '../../router'
import { PRS_STATUSES } from '../../pages/ProductRequests/constants'

export const PRSCreation = ({
  className,
  onValueChange,
  // callback,
  data,
  form
}: TFormPRSCreationFormProps): JSX.Element => {
  const [contractCategories, setContractCategories] = useState<TOption[]>([])
  const { actions, state } = usePRSDetailsContext()
  const { generalInfo, fields, isNew } = state
  const { setActiveTab, handleGeneralInfo, setFields, resetFields } = actions

  const dispatch = useDispatch()
  const { id } = useParams()

  const { navigate } = useRouter()

  useEffect(() => {
    dispatch(setLoading(true))
    getOptions().finally(() => dispatch(setLoading(false)))
  }, [])
  useEffect(() => {
    const objectData = id === 'new' ? generalInfo : data
    if (objectData) {
      const formattedData = {}
      for (const key in objectData) {
        if (Array.isArray(objectData[key])) {
          formattedData[key] = objectData[key].map(formatOptionNameValue)
        } else {
          formattedData[key] = objectData[key]
        }
      }
      const fieldsL = Object.keys(formattedData).map((i) => ({
        name: i,
        value: formattedData[i]
      }))
      setFields(fieldsL)
    }
  }, [data, generalInfo])

  const getOptions = async () => {
    await fetchContractCategories().then((resp) => {
      if (!resp.data?.results) {
        return
      }

      setContractCategories(resp.data.results.map(formatOptionNameValue))
    })
  }
  const handleSubmitForm = async (formData) => {
    dispatch(setLoading(true))
    const prsData: TPRSGeneralDetailsResponse = {
      name: formData.name,
      ...formData
    }

    try {
      handleGeneralInfo(prsData)
      setActiveTab('1')
      // setIsChanged(false)
      if (id === 'new') {
        navigate(`${routes.productRequests}/new/1`, { replace: true })
      } else {
        navigate(`${routes.productRequests}/${id}/1`, { replace: true })
      }
    } finally {
      dispatch(setLoading(false))
    }
  }
  return (
    <>
      <Form<TFormPRSCreationForm>
        onFinish={handleSubmitForm}
        fields={fields}
        form={form}
        onValuesChange={(changed) => {
          onValueChange(changed)
          if (changed?.contract_category) resetFields()
        }}
        // onFieldsChange={() => setIsChanged(true)}
        validateMessages={validateMessages}
        className={cn('prs-creation-form mt-24', className)}
        id="prs-creation-general"
      >
        <div className="row two-columns">
          <Input
            propsItem={{
              name: 'name',
              label: LABELS.PRODUCT_NAME,
              rules: [
                {
                  max: 100,
                  required: true
                }
              ]
            }}
            propsInput={{
              showCount: true,
              maxLength: 100
            }}
          />
        </div>
        <div className="row two-columns mt-24">
          <Select
            options={contractCategories}
            propsItem={{
              name: 'contract_category',
              label: LABELS.CONTRACT_CATEGORY,
              rules: [{ required: true }]
            }}
            propsSelect={{
              placeholder: PLACEHOLDERS.PLEASE_SELECT,
              disabled: !isNew && data?.status === PRS_STATUSES.DECLINED
            }}
          />
          <div className="prs-creation-form__half-row row two-columns mt-0">
            <Input.Number
              propsItem={{
                name: 'anticipated_monthly_usage',
                label: LABELS.ANTICIPATED_MONTHLY_USAGE,
                rules: [{ required: true }]
              }}
              propsInputNumber={{
                ...PROPS_INPUT_NUMBER,
                max: '999999999.99'
              }}
            />
            <Input.Number
              propsItem={{
                name: 'price',
                label: LABELS.PRICE,
                rules: [{ required: true }]
              }}
              propsInputNumber={{
                ...PROPS_INPUT_NUMBER,
                max: '999999999.99'
              }}
            />
          </div>
        </div>
        <div className="row mt-24">
          <Input.TextArea
            propsItem={{
              label: LABELS.PRODUCT_DESCRIPTION,
              name: 'description',
              rules: [{ required: true, max: 2000 }]
            }}
            propsTextArea={{
              showCount: true,
              maxLength: 2000
            }}
          />
        </div>
        <div className="row mt-24">
          <Input.TextArea
            propsItem={{
              label: LABELS.JUSTIFICATION,
              name: 'justification',
              rules: [{ required: true, max: 2000 }]
            }}
            propsTextArea={{
              showCount: true,
              maxLength: 2000
            }}
          />
        </div>
      </Form>
    </>
  )
}
