import Fetcher from 'services/Fetcher'
import { API_DICTIONARY, HTTP_METHODS } from 'constants/api'
import {
  TGetPurchaseOrdertemplate,
  TPurchaseOrdersDeleteRequest,
  TPurchaseOrdersRequest,
  TUploadPurchaseOrders,
  TUploadPurchaseOrdersResponse
} from './types'

const fetcher = new Fetcher({})

export const getPurchaseOrderTemplate = async () =>
  fetcher.request<unknown, TGetPurchaseOrdertemplate>({
    url: `${API_DICTIONARY.FILES}${API_DICTIONARY.PURCHASE_ORDER_TEMPLATE}`,
    method: HTTP_METHODS.GET
  })

export const uploadPurchaseOrders = async (data: TUploadPurchaseOrders) =>
  fetcher.request<TUploadPurchaseOrders, TUploadPurchaseOrdersResponse>({
    url: `${API_DICTIONARY.FINANCES}${API_DICTIONARY.PURCHASE_ORDER}`,
    method: HTTP_METHODS.POST,
    headers: {
      'Content-Type': 'multipart/form-data'
    },
    data
  })

export const getPurchaseOrders: TPurchaseOrdersRequest = async (data) =>
  fetcher.request<unknown, any>({
    url: `${API_DICTIONARY.FINANCES}${API_DICTIONARY.PURCHASE_ORDERS}`,
    method: HTTP_METHODS.GET,
    ...data
  })

export const deletePurchaseOrder: TPurchaseOrdersDeleteRequest = async (data) =>
  fetcher.request<unknown, any>({
    url: `${API_DICTIONARY.FINANCES}${API_DICTIONARY.PURCHASE_ORDERS}${API_DICTIONARY.BULK_REMOVE}`,
    method: HTTP_METHODS.POST,
    data
  })

export const editPurchaseOrder = async (uuid: string, data: any) =>
  fetcher.request<unknown, any>({
    url: `${API_DICTIONARY.FINANCES}${API_DICTIONARY.PURCHASE_ORDERS}${uuid}/`,
    method: HTTP_METHODS.PATCH,
    data
  })
