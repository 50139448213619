import Fetcher from '../../../services/Fetcher'
import {
  API_DICTIONARY,
  HTTP_METHODS,
  ResponseWithPagination,
  TOptionsResponseUuidValue,
  TOptionsWithNameResponse
} from '../../../constants'
import { TNonPOInvoicesRequest, TNonPOInvoicesResponse } from './types'

const fetcher = new Fetcher({})

export const getNonPOInvoices: TNonPOInvoicesRequest = async (data) =>
  fetcher.request<unknown, TNonPOInvoicesResponse>({
    url: `${API_DICTIONARY.FINANCES}${API_DICTIONARY.INVOICES}${API_DICTIONARY.NON_PO}`,
    method: HTTP_METHODS.GET,
    ...data
  })

export const getNonPOInvoicesCreatorOptions = async () =>
  fetcher.request<unknown, ResponseWithPagination<TOptionsResponseUuidValue>>({
    url: `${API_DICTIONARY.FINANCES}${API_DICTIONARY.INVOICES}${API_DICTIONARY.NON_PO}${API_DICTIONARY.OPTIONS}${API_DICTIONARY.CREATOR}`,
    method: HTTP_METHODS.GET
  })

export const getNonPOInvoicesHospitalsOptions = async () =>
  fetcher.request<unknown, ResponseWithPagination<TOptionsWithNameResponse>>({
    url: `${API_DICTIONARY.FINANCES}${API_DICTIONARY.INVOICES}${API_DICTIONARY.NON_PO}${API_DICTIONARY.OPTIONS}${API_DICTIONARY.SHIP_TO_ENTITY_HOSPITAL}`,
    method: HTTP_METHODS.GET
  })
