import { useState, useEffect } from 'react'
import { Form } from 'antd'
import cn from 'classnames'
import { Input } from 'components/Input'
import { LABELS, VALIDATION_MESSAGES } from 'constants/txt'
import { validateMessages } from 'helper/common'
import {
  TEditPurchaseOrdersProps,
  TPurchaseOrders,
  TPurchaseOrdersFormFields
} from './forms.editPurchaseOrder.d'
import { PROPS_INPUT_NUMBER } from '../../features/ContractDetails/RebateConditions/RebateConditionsForm/constants'
import './forms.editPurchaseOrder.scss'
import { Typography, TYPOGRAPHY_WEIGHT } from '../Typography'
import { Datepicker } from '../Datepicker'
import { timelineDateFormat } from '../Timelines/constants'
import moment, { Moment } from 'moment'
import { convertDateToRequestFormat } from '../../utils/moment'
import { editPurchaseOrder } from '../../features/PurchaseOrders/api'
import { notification } from '../Notification'
import { purchaseOrderInitialValues } from './constants'

export const EditPurchaseOrders = ({
  className,
  form,
  data,
  onChange,
  callback,
  checkChanges
}: TEditPurchaseOrdersProps) => {
  const [fields, setFields] = useState<TPurchaseOrdersFormFields>([])
  const [orderDate, setOrderDate] = useState<Moment | null>(null)

  useEffect(() => {
    if (data?.uuid) {
      const formattedData = {}
      for (const key in data) {
        if (typeof data[key] === 'object' && key === 'address') {
          for (const item in data[key]) {
            formattedData[item] = data[key][item]
          }
        } else if (key === 'departments') {
          formattedData[key] = data[key].map((i) => ({
            label: i.name,
            value: i.uuid
          }))
        } else {
          formattedData[key] = data[key]
        }
      }
      const fields = Object.keys(formattedData).map((i) => ({
        name: i,
        value: formattedData[i]
      }))
      setFields(fields)
      setOrderDate(moment(data.order_date))
    }
  }, [data])

  const handleOrderDate = (e: Moment | null) => {
    setOrderDate(e)
    onChange({
      name: 'order_date',
      value: convertDateToRequestFormat(e || moment())
    })
  }

  const editPurchaseOrdersPopupAction = (formData: TPurchaseOrders) => {
    if (!checkChanges()) return
    const POData: TPurchaseOrders = {
      quantity: formData.quantity,
      price: formData.price,
      total_cost: formData.total_cost,
      order_date: convertDateToRequestFormat(moment(orderDate))
    }
    if (data.uuid) {
      editPurchaseOrder(data.uuid, POData)
        .then(() => {
          notification.success({ message: VALIDATION_MESSAGES.SM0070 })
          if (callback) {
            callback()
          }
        })
        .catch((e: any) => {
          notification.error({ message: e?.data.errors })
          console.error(e)
        })
    }
  }

  return (
    <Form<TPurchaseOrders>
      form={form}
      onFinish={editPurchaseOrdersPopupAction}
      fields={fields}
      initialValues={purchaseOrderInitialValues}
      validateMessages={validateMessages}
      onValuesChange={(_, changedValues) => onChange(changedValues)}
      id="edit-purchase-orders-form"
      className={cn('edit-purchase-orders', className)}
    >
      <div className="row two-columns edit-purchase-orders__pack-wrapper">
        <Input
          propsItem={{
            name: 'quantity',
            label: LABELS.USAGE
          }}
          propsInput={{ maxLength: 20, showCount: true }}
        />
        <Typography.Body1 className="pack-title">Pack</Typography.Body1>
      </div>
      <div className="row">
        <Input.Number
          propsItem={{
            name: 'price',
            label: LABELS.PRICE
          }}
          propsInputNumber={{
            ...PROPS_INPUT_NUMBER,
            maxLength: 20
          }}
        />
      </div>
      <div className="row">
        <Input.Number
          propsItem={{
            name: 'total_cost',
            label: LABELS.TOTAL_COST
          }}
          propsInputNumber={{
            ...PROPS_INPUT_NUMBER,
            maxLength: 20
          }}
        />
      </div>
      <div className="column edit-purchase-orders__date">
        <Typography.Caption weight={TYPOGRAPHY_WEIGHT.SEMI_BOLD}>
          Purchase order date
        </Typography.Caption>
        <div className="row two-columns">
          <Datepicker
            propsDate={{
              format: timelineDateFormat,
              placeholder: 'mm/dd/yyyy',
              value: orderDate,
              name: 'order_date',
              onChange: handleOrderDate
            }}
          />
        </div>
      </div>
    </Form>
  )
}

export default EditPurchaseOrders
