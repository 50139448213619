import Popup from 'components/Popup/Popup'
import Typography from '../../../../components/Typography/Typography'
import { useCognusRepositoryPopup } from '../../Providers/CognusRepositoryPopupProvider'
import { Button, BUTTON_TYPES } from '../../../../components/Button'
import { BTN_TXT } from 'constants/txt'
import { useCognusRepositoryContext } from '../../Providers/CognusRepositoryActions'

type TProps = {
  callbackAfterAccept: VoidFunction
}
export const AcceptModal = ({ callbackAfterAccept }: TProps) => {
  const { acceptContractPopup } = useCognusRepositoryPopup()
  const { state, actions } = acceptContractPopup
  const { handleAcceptContract } = useCognusRepositoryContext().actions
  return (
    <Popup
      visible={state.visible}
      onCancel={actions.close}
      title={state.title}
      width={460}
      footer={
        <>
          <Button type={BUTTON_TYPES.DEFAULT} onClick={actions.close} upperCase>
            {BTN_TXT.CANCEL}
          </Button>
          <Button
            type={BUTTON_TYPES.PRIMARY}
            htmlType="submit"
            onClick={() => handleAcceptContract(callbackAfterAccept)}
            upperCase
          >
            {BTN_TXT.ACCEPT}
          </Button>
        </>
      }
    >
      <Typography.Body1>
        After accepting the contract category, you will proceed with the LOP
        signing process.
      </Typography.Body1>
    </Popup>
  )
}
