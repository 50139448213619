import Fetcher from 'services/Fetcher'
import { API_DICTIONARY, HTTP_METHODS } from 'constants/api'
import { THealthSystemUsersResponse, THealthSystemUsersRequest } from './types'

const fetcher = new Fetcher({})

export const getHealthSystemUsers: THealthSystemUsersRequest = async (
  uuid,
  hsId,
  data
) =>
  fetcher.request<unknown, THealthSystemUsersResponse>({
    url: `${API_DICTIONARY.COMMUNITIES}${uuid}/${API_DICTIONARY.HEALTH_SYSTEMS}${hsId}/${API_DICTIONARY.USERS}`,
    method: HTTP_METHODS.GET,
    ...data
  })
