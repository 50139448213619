import InvoiceDataContextProvider from './Providers/InvoiceDataContextProvider'
import { InvoiceDataContainer } from '../../features/InvoiceData/InvoiceDataContainer/InvoiceDataContainer'
import { InvoiceDataRouter } from '../../features/InvoiceData/InvoiceDataRouter/InvoiceDataRouter'

export const InvoiceData = () => (
  <InvoiceDataContextProvider>
    <InvoiceDataContainer>
      <InvoiceDataRouter />
    </InvoiceDataContainer>
  </InvoiceDataContextProvider>
)
