import { Default } from './input.default'
import { Password } from './input.password'
import { TextArea } from './input.textarea'
import { Props } from './types'
import './styles.scss'
import { NumberInput } from './input.number'
import { InputFile } from './input.file'
import { PhoneInput } from './input.phone'
import { DefaultNoForm } from './input.default.form'
import { NumberInputNoForm } from './input.number.form'

const Input = (props: Props) => <Default {...props} />

Input.Default = Default
Input.DefaultNoForm = DefaultNoForm
Input.Password = Password
Input.TextArea = TextArea
Input.Number = NumberInput
Input.NumberNoForm = NumberInputNoForm
Input.File = InputFile
Input.Phone = PhoneInput

export default Input
