import { ReactComponent as InfoIcon } from 'assets/svg/Information.svg'
import './styles.scss'
import { FieldWithLabel } from 'components/FieldWithLabel/FieldWithLabel'
import AddResponsibleUserAction from './UserListLedCentral/AddCommonsResponsible'
import AddResponsibleUsersAction from './AddUserPopup/Action'
import { useRFPDetailsContext } from 'features/RFP/RFPDetails/Providers/RFPDetailsContextProvider'
import UserListLedCentral from 'features/RFP/RFPDetails/ResponsibleForRFP/UserListLedCentral/UserListLedCentral'
import { Can, ACTIONS, SUBJECTS } from 'features/Permission'
import RFPResponsibleContextProvider from 'features/RFP/RFPDetails/ResponsibleForRFP/Providers/RFPResponsibleContextProvider'
import {
  AddResponsibleUserPopup,
  DeleteResponsibleUserPopup
} from 'features/RFP/RFPDetails/ResponsibleForRFP/index'
import Typography from '../../../../components/Typography/Typography'
import AddCommonsResponsibleModal from './UserListLedCentral/AddCommonsResponsibleModal'
import { RFP_STATUSES } from '../../constants'
import { useRFPDetailsPopupContext } from '../Providers/RFPDetailsPopupProvider'

const ResponsibleForRFPCommons = () => {
  const rfpDetailsContext = useRFPDetailsContext()

  const {
    data,
    canSeeAssignBanner,
    canCRUDCommonsLegalAndAnalystsResponsibles,
    isAllCommonsResponsiblesWasAdded
  } = rfpDetailsContext.state

  const { addResponsibleCommonsUserPopup } = useRFPDetailsPopupContext()
  return (
    <Can I={ACTIONS.VIEW} a={SUBJECTS.RESPONSIBLE_USER}>
      <RFPResponsibleContextProvider>
        <div className="rfp-details-responsible">
          <FieldWithLabel title={'Commons responsible for RFP'}>
            <>
              {!!data?.commons_responsibles?.length ? (
                <UserListLedCentral isCommons />
              ) : !canSeeAssignBanner ? (
                <div className="rfp-details-responsible__commons-empty-state row align-start">
                  <InfoIcon />
                  <Typography.Caption>
                    {data?.status === RFP_STATUSES.CREATED
                      ? 'Responsible will be assigned after sending the RFP to Commons'
                      : 'Responsible will be assigned by Commons Vice President'}
                  </Typography.Caption>
                </div>
              ) : (
                <div />
              )}
              {canCRUDCommonsLegalAndAnalystsResponsibles &&
              !!data?.commons_responsibles?.length &&
              !isAllCommonsResponsiblesWasAdded ? (
                <div className="rfp-details-responsible__actions">
                  <AddResponsibleUsersAction isCommons />
                </div>
              ) : (
                <div />
              )}
            </>
          </FieldWithLabel>

          {canSeeAssignBanner && (
            <div className="rfp-details-responsible__actions">
              <AddResponsibleUserAction />
            </div>
          )}
          <div className="mt-24">
            <FieldWithLabel title={'Community responsible for RFP'}>
              <UserListLedCentral />
            </FieldWithLabel>
          </div>
        </div>
        <DeleteResponsibleUserPopup />
        <AddResponsibleUserPopup isCommonsRFP />
        {addResponsibleCommonsUserPopup.state.visible && (
          <AddCommonsResponsibleModal />
        )}
      </RFPResponsibleContextProvider>
    </Can>
  )
}

export default ResponsibleForRFPCommons
