import { Popup } from 'components/Popup'
import { usePendingClassificationPopupContext } from '../../Providers/PendingClassificationPopupProvider'
import BulkEditForm from './BulkEditForm'
import { usePendingClassificationEditContext } from '../../Providers/PendingClassificationEditProvider'
import { IChangedRows } from '../types'

const BulkEditPopup = () => {
  const { bulkClassificationPopup } = usePendingClassificationPopupContext()
  const { actions } = usePendingClassificationEditContext()

  const { contractCategories, keys } = bulkClassificationPopup.state.payload
  const { close } = bulkClassificationPopup.actions

  return (
    <Popup {...bulkClassificationPopup.state} width={442} footer={null}>
      <BulkEditForm
        contractCategories={contractCategories}
        onSubmit={(values) => {
          actions.setChangedRows((prev) => ({
            ...prev,
            ...keys.reduce((acc, key): IChangedRows => {
              return {
                ...acc,
                [key.toString()]: {
                  contract_category:
                    values.contract_category ??
                    prev[key.toString()]?.contract_category,
                  unspsc_code:
                    values.unspsc_code ?? prev[key.toString()]?.unspsc_code
                }
              }
            }, {} as IChangedRows)
          }))
          actions.setSelectedRows([])
          close()
        }}
        onCancel={close}
      />
    </Popup>
  )
}

export default BulkEditPopup
