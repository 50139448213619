import { IPartialUserInfo } from '../../redux/store/user/types'
import { Moment } from 'moment'

export interface TCalendarEventListType {
  uuid: string
  event_type: TCalendarEventType
  name: string
  summary: string
  location: string
  start_date: string
  start_time: string
  end_date: string
  end_time: string
  respond: RespondToTheMeeting.ACCEPT | RespondToTheMeeting.MAYBE | null
}

export interface TCalendarEvent {
  uuid: string
  event_type: TCalendarEventType
  name: string
  summary: string
  location: string
  start_date: string
  start_time: string
  end_date: string
  end_time: string
  creator: IPartialUserInfo
  participants: TParticipantDetails[]
}

export interface TCalendarEventType {
  id: string
  value: string
}

export type TQueryCalendarListParams = {
  start_date?: string
  end_date?: string
}

export type TCalendarDatePeriod = {
  startDate: Moment
  endDate: Moment
}

export type TOptionTypeEvent = {
  value: string
  id: string
}

export interface TCalendarEventDetails extends TCalendarEvent {
  formattedDate?: string
}

export type TDeleteEventDetailsProps = {
  id: string
  sendEmails?: boolean
}

export type TParticipantDetails = IPartialUserInfo & {
  respond?: RespondToTheMeeting | null
}

export enum RespondToTheMeeting {
  ACCEPT = 'accept',
  DECLINE = 'decline',
  MAYBE = 'maybe'
}
