import { RFP_STATUSES } from 'features/RFP/constants'
import { ITimelineSettings } from 'components/Timelines/types'

export const RFPTimelinesKeyTitleDictionary = {
  [RFP_STATUSES.VENDOR_ACCEPTANCE]: 'Vendor acceptance',
  [RFP_STATUSES.BIDDING_QA]: 'Bidding Q&A',
  [RFP_STATUSES.BIDDING_IN_PROGRESS]: 'Bidding in progress',
  [RFP_STATUSES.PRICE_FILES_ANALYSIS]: 'Price file analysis',
  [RFP_STATUSES.VOTING_IN_PROGRESS]: 'Voting in progress',
  [RFP_STATUSES.VENDORS_SELECTION]: 'Vendors selection',
  [RFP_STATUSES.CONTRACTING]: 'Contracting',
  [RFP_STATUSES.OUT_FOR_SIGNATURE]: 'Out for signature'
}

export const systemRFPTimelinesSettings: ITimelineSettings<
  typeof RFP_STATUSES
>[] = [
  {
    key: RFP_STATUSES.VENDOR_ACCEPTANCE,
    title: RFPTimelinesKeyTitleDictionary[RFP_STATUSES.VENDOR_ACCEPTANCE],
    diffDays: 2
  },
  {
    key: RFP_STATUSES.BIDDING_QA,
    title: RFPTimelinesKeyTitleDictionary[RFP_STATUSES.BIDDING_QA],
    diffDays: 10
  },
  {
    key: RFP_STATUSES.BIDDING_IN_PROGRESS,
    title: RFPTimelinesKeyTitleDictionary[RFP_STATUSES.BIDDING_IN_PROGRESS],
    diffDays: 30
  },
  {
    key: RFP_STATUSES.PRICE_FILES_ANALYSIS,
    title: RFPTimelinesKeyTitleDictionary[RFP_STATUSES.PRICE_FILES_ANALYSIS],
    diffDays: 10
  },
  {
    key: RFP_STATUSES.VOTING_IN_PROGRESS,
    title: RFPTimelinesKeyTitleDictionary[RFP_STATUSES.VOTING_IN_PROGRESS],
    diffDays: 7
  },
  {
    key: RFP_STATUSES.VENDORS_SELECTION,
    title: RFPTimelinesKeyTitleDictionary[RFP_STATUSES.VENDORS_SELECTION],
    diffDays: 3
  }
]
