import { useMemo, useState } from 'react'
import { useSelector } from 'react-redux'
import { subject } from '@casl/ability'
import { useAbility } from '@casl/react'
import { useParams } from 'react-router-dom'

import { Chips } from 'components/Chips/Chips'
import { Popup } from 'components/Popup'
import { Button, BUTTON_TYPES } from 'components/Button'
import { FieldWithLabel } from 'components/FieldWithLabel/FieldWithLabel'
import { Forms } from 'components/Forms'
import { BTN_TXT, LABELS } from 'constants/txt'
import { RFP_STATUSES } from 'features/RFP/constants'
import { TGeneralProps } from './types'
import './styles.scss'
import { useRFPDetailsContext } from 'features/RFP/RFPDetails/Providers/RFPDetailsContextProvider'
import { AbilityContext, ACTIONS, ROLES, SUBJECTS } from 'features/Permission'
import { getUser } from 'redux/store/user/getters'
import { ResponsibleForRFP } from 'features/RFP/RFPDetails/ResponsibleForRFP'
import { Form } from 'antd'
import {
  initialRfp,
  PARTICIPATION_TYPE_OPTIONS,
  RFP_TYPE_OPTIONS
} from '../constants'
import ResponsibleForRFPCommons from '../ResponsibleForRFP/ResponsibleForRFPCommons'
import { FilesUpload } from '../FilesUpload'

export const General = ({ isVendor }: TGeneralProps) => {
  const { id } = useParams()
  const ability = useAbility<any>(AbilityContext)
  const { state, actions } = useRFPDetailsContext()
  const { handleGetRfpData } = actions
  const { data } = state

  const [changedValues, setChangedValues] = useState({})
  const [isNextDisabled, setNextDisabled] = useState(true)
  const [isShowButtons, setShowButtons] = useState(false)
  const [isDiscard, setDiscard] = useState(false)
  const user = useSelector(getUser)

  const isStakeHolder = useMemo(() => {
    return Boolean(user.role === ROLES.STAKEHOLDER)
  }, [user])

  const [form] = Form.useForm()

  const isRFPCreationProcess = id === 'new'

  const isClinicallyLedCommons =
    data.participation_type === 'clinically_led_central'
  const onValueChange = (data) => {
    const isDisable = !!Object.entries(data).find((item: any) => {
      return (
        !item[1] ||
        (data.creation_type === 'health_system' && item[0] === 'health_systems'
          ? false
          : !item[1].length)
      )
    })
    setNextDisabled(isDisable)
    setShowButtons(true)
    setChangedValues(data)
  }

  const cancelHandler = () => {
    if (Object.entries(changedValues).length) {
      setDiscard(true)
    }
  }

  const isViewMode = useMemo(
    () =>
      (data.status !== RFP_STATUSES.CREATED &&
        data.status !== RFP_STATUSES.WAITING_FOR_ASSIGNMENT &&
        data.status !== RFP_STATUSES.IN_CS_QUEUE) ||
      isVendor ||
      ability.cannot(
        ACTIONS.UPLOAD,
        subject(SUBJECTS.RFP_DOCUMENTS, { ...data })
      ),
    [ability, data, isVendor]
  )

  const canEditOrCreateRfp =
    isRFPCreationProcess ||
    ability.can(ACTIONS.EDIT, subject(SUBJECTS.RFP, { ...data }))

  const discardSubmit = () => {
    setDiscard(false)
    setShowButtons(false)
    actions.resetInitQueryParams()
    form.setFieldsValue({
      name: data.name,
      health_systems: data.health_systems.map((i) => ({
        value: i.uuid,
        label: i.name
      })),
      contract_categories: data.contract_categories.map((i) => ({
        value: i.uuid,
        label: i.name
      })),
      creation_type: initialRfp.creation_type
    })
  }
  const isCreatorCommons = useMemo(() => {
    return Boolean(data?.creator?.role?.includes('commons'))
  }, [data])

  return (
    <div className="rfp-details-general">
      <div className="rfp-details-general__left">
        {(data.status === RFP_STATUSES.CREATED ||
          data.status === RFP_STATUSES.IN_CS_QUEUE ||
          data.status === RFP_STATUSES.WAITING_FOR_ASSIGNMENT) &&
        canEditOrCreateRfp ? (
          <Forms.RFPCreation
            form={form}
            data={data}
            callback={handleGetRfpData}
            onValueChange={onValueChange}
            setShowButtons={setShowButtons}
          />
        ) : (
          <>
            <FieldWithLabel
              title="Contract categories"
              className="rfp-details-general__chips-wrapper"
            >
              {data.contract_categories.map((i) => (
                <Chips key={i.uuid}>{i.name}</Chips>
              ))}
            </FieldWithLabel>
            <div className="row two-columns mt-24">
              {!isCreatorCommons &&
                data?.participation_type ===
                  PARTICIPATION_TYPE_OPTIONS[0]?.value && (
                  <FieldWithLabel
                    title={LABELS.PARTICIPATION_TYPE}
                    className="participation-type-view"
                  >
                    {
                      PARTICIPATION_TYPE_OPTIONS.find(
                        (i) => i.value === data?.participation_type
                      )?.label
                    }
                  </FieldWithLabel>
                )}
              <FieldWithLabel title={LABELS.RFP_TYPE} className="rfp-type-view">
                {
                  RFP_TYPE_OPTIONS.find((i) => i.value === data?.creation_type)
                    ?.label
                }
              </FieldWithLabel>
            </div>
            <FieldWithLabel
              title="Health systems"
              className="rfp-details-general__chips-wrapper mt-32"
            >
              {data.health_systems.map((i) => (
                <Chips key={i.uuid}>{i.name}</Chips>
              ))}
            </FieldWithLabel>
          </>
        )}
        {!!data.uuid && !isStakeHolder && !isVendor && (
          <FilesUpload
            isViewMode={isViewMode}
            data={data}
            isVendor={isVendor}
          />
        )}
        {(data.status === RFP_STATUSES.CREATED ||
          data.status === RFP_STATUSES.IN_CS_QUEUE ||
          data.status === RFP_STATUSES.WAITING_FOR_ASSIGNMENT) &&
          !user.vendor &&
          canEditOrCreateRfp &&
          isShowButtons && (
            <div className="rfp-details-general__buttons mt-32">
              <Button onClick={cancelHandler} upperCase>
                {BTN_TXT.CANCEL}
              </Button>
              <Button
                type={BUTTON_TYPES.PRIMARY}
                disabled={isNextDisabled}
                form="rfp-creation"
                htmlType="submit"
                upperCase
              >
                {!!data.uuid ? BTN_TXT.SAVE : BTN_TXT.CREATE}
              </Button>
            </div>
          )}
      </div>
      <div className="rfp-details-general__right">
        {isRFPCreationProcess ? null : (
          <>
            {isVendor && (
              <div className="rfp-details-general__community-info">
                <FieldWithLabel title="Community">
                  {data.community?.name}
                </FieldWithLabel>
                <FieldWithLabel className="mt-24" title="Description">
                  {data.community?.description}
                </FieldWithLabel>
                <FieldWithLabel className="mt-24" title="Community contacts" />
              </div>
            )}
            {isClinicallyLedCommons ? (
              <ResponsibleForRFPCommons />
            ) : (
              <ResponsibleForRFP />
            )}
          </>
        )}
      </div>
      <Popup.Discard
        visible={isDiscard}
        onCancel={() => setDiscard(false)}
        onSubmit={discardSubmit}
      />
    </div>
  )
}
