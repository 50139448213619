import { FC } from 'react'
import cn from 'classnames'
import { Container } from '../../../../../components/Container/Container'
import { BREADCRUMBS } from '../../constants'
import { useSelector } from 'react-redux'
import { getUser } from '../../../../../redux/store/user/getters'
import { VendorDetailsWrapperProps } from './types'

export const VendorDetailsWrapper: FC<VendorDetailsWrapperProps> = ({
  name,
  children
}) => {
  const user = useSelector(getUser)

  return user.vendor ? (
    <>{children}</>
  ) : (
    <Container
      className={cn('vendor-info-container', {
        'vendor-info-container--no-padding': user.vendor
      })}
      title={!user.vendor && name}
      breadcrumbs={!user.vendor ? BREADCRUMBS(name || '') : undefined}
    >
      {children}
    </Container>
  )
}
