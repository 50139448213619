import './styles.scss'
import { useMemo } from 'react'
import InfiniteList from '../../../components/InfiniteList/InfiniteList'
import { LoadingOutlined } from '@ant-design/icons'
import { TMessageGroup } from './MessageItem/types'
import MessagesGroup from './MessageItem/MessagesWrapper'
import EmptyState from './Empty'
import useHistory from './useHistory'

const History = () => {
  const historyProps = useHistory()

  const Loader = useMemo(
    () => (
      <LoadingOutlined className="chat-history-feature-list__spinner" spin />
    ),
    []
  )

  return (
    <div className="chat__history chat-history">
      <InfiniteList<TMessageGroup>
        items={historyProps.getGroupedHistory()}
        loader={Loader}
        rootMargin="120px 0px 0px 0px"
        renderItem={MessagesGroup}
        listEmptyComponent={<EmptyState />}
        keyExtractor={([label]) => label}
        loading={historyProps.isLoading}
        hasNextPage={historyProps.hasNextPage}
        onLoadMore={historyProps.handleLoadMore}
        className="chat-history-feature-list column"
      />
    </div>
  )
}

export default History
