import Default from 'components/AttachmentsList/Default/Default'
import WithHistory from 'components/AttachmentsList/WithHistory/WithHistory'
import { TAttachmentsListProps } from 'components/AttachmentsList/types'
import WithImages from './WithImages/WithImages'
import ChatAttachment from './ChatAttacment/ChatAttachment'

export const AttachmentsList = (props: TAttachmentsListProps) => (
  <Default {...props} />
)

AttachmentsList.WithHistory = WithHistory
AttachmentsList.WithImages = WithImages
AttachmentsList.ChatAttachment = ChatAttachment
