import Typography from '../../../components/Typography/Typography'
import { TYPOGRAPHY_WEIGHT } from '../../../components/Typography'
import { Button, BUTTON_TYPES } from '../../../components/Button'
import { BTN_TXT } from '../../../constants'
import { ReactComponent as CalendarEmptySvg } from 'assets/svg/EmptyCalendarView.svg'
import { ReactComponent as Plus16 } from 'assets/svg/Plus16.svg'
import { useCalendarPopup } from '../../../pages/Calendar/Provider/CalendarPopupProvider'

export const EmptyState = () => {
  const { addEventPopup } = useCalendarPopup()
  const openAddEventPopup = () => addEventPopup.actions.open()
  return (
    <div className="calendar-schedule-view column align-center full-height">
      <CalendarEmptySvg />
      <Typography.Body1 weight={TYPOGRAPHY_WEIGHT.BOLD} className="mt-24">
        No events
      </Typography.Body1>
      <Button
        type={BUTTON_TYPES.PRIMARY}
        icon={<Plus16 />}
        onClick={openAddEventPopup}
        upperCase
        className="mt-12"
      >
        {BTN_TXT.ADD_EVENT}
      </Button>
    </div>
  )
}
