import { ColumnsType } from 'antd/es/table'
import { SortOrder } from 'antd/es/table/interface'
import { Dropdown } from 'components/Dropdown'
import { Sorter } from 'components/Sorter/Sorter'
import { ROLES_NAMES, USER_STATUSES_OPTIONS } from 'constants/common'
import { ReactComponent as Filter16 } from 'assets/svg/Filter16.svg'
import { ReactComponent as FilterApplied16 } from 'assets/svg/FilterApplied16.svg'
import {
  TPRSFacilityUsersData,
  TPRSFacilityUsersFilters,
  TPRSFacilityUsersFiltersFunc,
  TPRSFacilityUsersColumnsFunc
} from './types'
import { fetchHealthSystemsList } from '../../HealthSystems/api'
import { CellWithSubtitle } from '../../../components/CellWithSubtitle/CellWithSubtitle'
import {
  Status,
  USER_STATUS_TYPES_TO_CLASSNAME
} from '../../../components/Status'

export const columns = ({
  applyFilters,
  filters,
  community
}: TPRSFacilityUsersColumnsFunc): ColumnsType<TPRSFacilityUsersData> => {
  const _columns: ColumnsType<TPRSFacilityUsersData> = [
    {
      dataIndex: 'first_name',
      key: 'first_name',
      width: '240px',
      title: ({ sortOrder, sortColumn }) => (
        <Sorter<TPRSFacilityUsersData>
          dataIndex="first_name"
          sortColumn={sortColumn}
          sortOrder={sortOrder as SortOrder}
        >
          User name
        </Sorter>
      ),
      render: (_, { first_name, last_name }) => `${first_name} ${last_name}`,
      sorter: true,
      showSorterTooltip: false
    },
    {
      dataIndex: 'role',
      key: 'role',
      width: '295px',
      title: ({ sortOrder, sortColumn }) => (
        <Sorter<TPRSFacilityUsersData>
          dataIndex="role"
          sortColumn={sortColumn}
          sortOrder={sortOrder as SortOrder}
        >
          Role
        </Sorter>
      ),
      render: (text) => ROLES_NAMES[text],
      sorter: true,
      showSorterTooltip: false
    },
    {
      dataIndex: 'status',
      key: 'status',
      width: 130,
      title: ({ sortOrder, sortColumn }) => (
        <Sorter<TPRSFacilityUsersData>
          dataIndex="status"
          sortColumn={sortColumn}
          sortOrder={sortOrder as SortOrder}
        >
          Status
        </Sorter>
      ),
      filterDropdown: (props) => (
        <Dropdown.Filter<
          TPRSFacilityUsersFilters,
          TPRSFacilityUsersFiltersFunc,
          unknown
        >
          items={USER_STATUSES_OPTIONS}
          applyFilters={applyFilters}
          appliedFilters={filters}
          field="statuses"
          {...props}
        />
      ),
      render: (_, { status }) => (
        <Status.Default className={USER_STATUS_TYPES_TO_CLASSNAME[status]}>
          {status}
        </Status.Default>
      ),
      filterIcon: !!filters?.statuses?.length ? (
        <FilterApplied16 />
      ) : (
        <Filter16 />
      ),
      filterSearch: true,
      sorter: true,
      showSorterTooltip: false
    },
    {
      dataIndex: 'email',
      key: 'email',
      width: '295px',
      title: ({ sortOrder, sortColumn }) => (
        <Sorter<TPRSFacilityUsersData>
          dataIndex="email"
          sortColumn={sortColumn}
          sortOrder={sortOrder as SortOrder}
        >
          Email
        </Sorter>
      ),
      sorter: true,
      showSorterTooltip: false
    },
    {
      dataIndex: 'health_system',
      key: 'health_system',
      width: '295px',
      title: 'Health system',
      filterDropdown: (props) => (
        <Dropdown.Filter<
          TPRSFacilityUsersFilters,
          TPRSFacilityUsersFiltersFunc,
          unknown
        >
          applyFilters={applyFilters}
          appliedFilters={filters}
          searchCallback={() => fetchHealthSystemsList(community)}
          field="health_systems"
          asyncSearch
          {...props}
        />
      ),
      render: (_, { health_system }) => (
        <CellWithSubtitle title={health_system} />
      ),
      filterIcon: !!filters?.health_systems?.length ? (
        <FilterApplied16 />
      ) : (
        <Filter16 />
      ),
      filterSearch: true,
      showSorterTooltip: false
    }
  ]
  return _columns
}
