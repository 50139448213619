import {
  BASE_SOCKET_EVENT,
  BASE_SOCKET_READY_STATE,
  SOCKET_RECONNECT_DELAY
} from 'constants/socket'

class Socket {
  private _socket: WebSocket | undefined

  private _timer: NodeJS.Timeout | undefined

  private _reconnecting: boolean

  private _onmessageListeners: Array<(ev: MessageEvent) => any>

  constructor(public readonly socketUrl: string) {
    this._reconnecting = false

    this._onmessageListeners = []
  }

  public open() {
    if (!this.check()) {
      return
    }

    try {
      this._socket = new WebSocket(this.socketUrl)

      this._addEventListeners()
    } catch (err) {
      console.error(err)

      this._tryToReconnect()
    }
  }

  private _addEventListeners() {
    this._socket?.addEventListener(
      BASE_SOCKET_EVENT.OPEN_OR_RECONNECT,
      this._handleOpen.bind(this)
    )

    this._socket?.addEventListener(
      BASE_SOCKET_EVENT.CLOSE,
      this._handleClose.bind(this)
    )

    this._socket?.addEventListener(
      BASE_SOCKET_EVENT.ERROR,
      this._handleError.bind(this)
    )

    this._socket?.addEventListener(
      BASE_SOCKET_EVENT.MESSAGE,
      this._handleMessage.bind(this)
    )
  }

  private _removeAllListeners() {
    this._socket?.removeEventListener(
      BASE_SOCKET_EVENT.OPEN_OR_RECONNECT,
      this._handleOpen
    )

    this._socket?.removeEventListener(
      BASE_SOCKET_EVENT.CLOSE,
      this._handleClose
    )

    this._socket?.removeEventListener(
      BASE_SOCKET_EVENT.ERROR,
      this._handleError
    )

    this._socket?.removeEventListener(
      BASE_SOCKET_EVENT.MESSAGE,
      this._handleMessage
    )

    this.onopen = undefined
    this.onclose = undefined
    this.onerror = undefined

    this._onmessageListeners = []
  }

  private _handleOpen(event) {
    this.onopen?.(event)
  }

  private _handleClose(event) {
    if (
      this._socket?.readyState === BASE_SOCKET_READY_STATE.CONNECTING ||
      this._socket?.readyState === BASE_SOCKET_READY_STATE.OPEN ||
      this._socket?.readyState === BASE_SOCKET_READY_STATE.CLOSING
    ) {
      this._doClose()

      this.onclose?.(event)

      this._tryToReconnect()
    }
  }

  private _handleError(event) {
    this.onerror?.(event)

    this._handleClose(event)
  }

  private _handleMessage(event) {
    this._onmessageListeners.forEach((handler) => handler(event))
  }

  private _tryToReconnect() {
    if (this._reconnecting) {
      return
    }

    this._reconnecting = true

    if (this._timer) {
      clearTimeout(this._timer)
    }

    this._timer = setTimeout(() => {
      this._reconnecting = false

      this.open()
    }, SOCKET_RECONNECT_DELAY)
  }

  private _doClose() {
    // Stop event from firing again for transport
    this._socket?.removeEventListener(
      BASE_SOCKET_EVENT.CLOSE,
      this._handleClose
    )

    // Ensure socket won't stay open
    this._socket?.close()

    // Ignore further socket communication
    this._removeAllListeners()
  }

  public check() {
    return Boolean(WebSocket)
  }

  public close() {
    this._doClose()
  }

  public onopen: ((ev: Event) => any) | undefined

  public onclose: ((ev: CloseEvent) => any) | undefined

  public onerror: ((ev: Event) => any) | undefined

  public set onmessage(handler: (ev: MessageEvent) => any) {
    this._onmessageListeners.push(handler)
  }
}

export default Socket
