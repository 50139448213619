import { useCallback } from 'react'

import './styles.scss'
import { BTN_TXT } from 'constants/txt'
import { Popup } from 'components/Popup'
import { Typography } from 'components/Typography'
import { Button, BUTTON_TYPES } from 'components/Button'
import { useRFPDetailsPopupContext } from 'features/RFP/RFPDetails/Providers/RFPDetailsPopupProvider'
import { useRFPResponsibleContext } from 'features/RFP/RFPDetails/ResponsibleForRFP/Providers/RFPResponsibleContextProvider'

export default () => {
  const { deleteResponsibleUserPopup } = useRFPDetailsPopupContext()
  const rfpResponsibleContext = useRFPResponsibleContext()

  const { visible, onCancel, title, payload } = deleteResponsibleUserPopup.state
  const { close } = deleteResponsibleUserPopup.actions

  const { deleteResponsibleUserAsync } = rfpResponsibleContext.actions

  const handleDeleteResponsible = useCallback(() => {
    if (payload.user?.uuid) {
      deleteResponsibleUserAsync(payload.user.uuid)
    }
  }, [deleteResponsibleUserAsync, payload.user?.uuid])

  return (
    <Popup
      visible={visible}
      onCancel={onCancel}
      title={title}
      width={442}
      footer={
        <>
          <Button type={BUTTON_TYPES.DEFAULT} onClick={close} upperCase>
            {BTN_TXT.CANCEL}
          </Button>
          <Button
            type={BUTTON_TYPES.PRIMARY}
            onClick={handleDeleteResponsible}
            upperCase
            danger
          >
            {BTN_TXT.DELETE_FROM_RFP}
          </Button>
        </>
      }
    >
      <Typography.Body1>
        {`${payload.user?.first_name} ${payload.user?.last_name} is team member for the RFP. Do you really want to delete user from the RFP?`}
      </Typography.Body1>
    </Popup>
  )
}
