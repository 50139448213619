import Fetcher from 'services/Fetcher'
import { API_DICTIONARY, HTTP_METHODS } from 'constants/api'
import { TRFPListResponse, TRPFListRequest } from './types'

const fetcher = new Fetcher({})

export const getRfpList: TRPFListRequest = async (data) =>
  fetcher.request<unknown, TRFPListResponse>({
    url: API_DICTIONARY.RFP,
    method: HTTP_METHODS.GET,
    ...data
  })
