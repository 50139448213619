import { createContext, FC, PropsWithChildren, useMemo } from 'react'
import { createContextualCan, useAbility } from '@casl/react'
import { useSelector } from 'react-redux'

import { getUser } from 'redux/store/user/getters'
import { Abilities } from '../../VendorCreation'

export const ContractDetailsAbilityContext =
  createContext<Abilities.VendorAbility>(Abilities.ability)

const AbilityProvider: FC<PropsWithChildren> = ({ children }) => {
  const user = useSelector(getUser)

  const ability = useMemo(() => Abilities.defineAbility(user), [user])

  return (
    <ContractDetailsAbilityContext.Provider value={ability}>
      {children}
    </ContractDetailsAbilityContext.Provider>
  )
}

export const useContractDetailsAbility = () =>
  useAbility<Abilities.VendorAbility>(ContractDetailsAbilityContext)

export const Can = createContextualCan<any>(
  ContractDetailsAbilityContext.Consumer
)

export default AbilityProvider
