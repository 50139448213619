import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { moduleName } from './constants'
import { MyCommunityState } from './types'
import { AllowedUsersData } from '../../../features/MyCommunity/types'

const initialState: MyCommunityState = {
  allowedUsers: []
}

const slice = createSlice({
  name: moduleName,
  initialState,
  reducers: {
    setAllowedUsers: (
      state: MyCommunityState,
      action: PayloadAction<AllowedUsersData[]>
    ) => {
      state.allowedUsers = action.payload
    }
  }
})

export default slice.reducer
export const { setAllowedUsers } = slice.actions
