import { State } from 'redux/type'
import { IVendorInfoState, IVendorInfo, IVendorInfoErrors } from './types'
import { moduleName } from './constants'

const getVendorInfoState = (state: State): IVendorInfoState => state[moduleName]

export const getVendorInfo = (state: State): IVendorInfo => {
  const vendorInfoState = getVendorInfoState(state)
  return vendorInfoState.vendorInfo
}
export const getError = (state: State): IVendorInfoErrors | null => {
  const vendorInfoState = getVendorInfoState(state)
  return vendorInfoState.error
}
export const getVendorInfoIsLoaded = (state: State): boolean => {
  const vendorInfoState = getVendorInfoState(state)
  return vendorInfoState.isVendorInfoLoaded
}
export const getVendorInfoIsLoading = (state: State): boolean => {
  const vendorInfoState = getVendorInfoState(state)
  return vendorInfoState.isVendorInfoLoading
}
