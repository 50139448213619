import './style.scss'
import { TCalendarSchedule, TExtendedEvent } from './types'
import { EmptyState } from './EmptyState'
import { getMappedInDays, splitLongEventByDays } from './helper'
import { DayView } from './DayView'
import moment from 'moment'
import { useCalendarEventContext } from '../../../pages/Calendar/CalendarEventsProvider'
import { useEffect, useMemo } from 'react'
import { EventOpenedId } from '../../../components/Calendar/types'
import { useSelector } from 'react-redux'
import { getUser } from '../../../redux/store/user/getters'

const CalendarSchedule = ({
  events,
  onEventClick,
  openedPopupUniqueId,
  isHideActionsModals
}: TCalendarSchedule) => {
  const { currentMonthLabel, calendarEventDetails, queryEventId } =
    useCalendarEventContext().state
  const user = useSelector(getUser)
  const { getEventDetails } = useCalendarEventContext().actions
  const mappedData = useMemo(() => {
    const eventsList = [...events]
    eventsList.sort((a, b) => {
      return moment(a.start).diff(moment(b.start))
    })
    const extendedEvents = eventsList.flatMap((event) =>
      splitLongEventByDays(event, user.timezone.offset)
    ) as TExtendedEvent[]
    const extendedEventsWithUniqueId = extendedEvents.map((event) => ({
      ...event,
      uniqueId: Math.random().toString(16).slice(2)
    }))

    return getMappedInDays(
      extendedEventsWithUniqueId.filter((event) =>
        moment(currentMonthLabel, 'MMMM YYYY').isSame(event.start, 'month')
      )
    )
  }, [currentMonthLabel, events])

  useEffect(() => {
    if (!queryEventId) return
    const uniqueId = Object.values(mappedData)
      .flat()
      .find((event) => event.id === queryEventId)?.uniqueId
    if (!uniqueId) return
    onEventClick && onEventClick(queryEventId, uniqueId)
  }, [mappedData, queryEventId, onEventClick])

  const isEventOpened = useMemo(() => {
    return !!calendarEventDetails?.uuid && isHideActionsModals
  }, [calendarEventDetails, isHideActionsModals])

  return (
    <div className="calendar-schedule-container">
      {!Object.keys(mappedData)?.length ? (
        <EmptyState />
      ) : (
        <div>
          {Object.entries(mappedData).map(([label, eventsData]) => {
            return (
              <DayView
                label={label}
                eventsData={eventsData}
                openedPopupUniqueId={openedPopupUniqueId}
                key={label}
                onEventClick={onEventClick}
                openedEventInfo={calendarEventDetails}
                setPopoverOpened={(id: EventOpenedId) => {
                  getEventDetails && getEventDetails(id)
                }}
                isEventOpened={isEventOpened}
                offset={user.timezone.offset}
              />
            )
          })}
        </div>
      )}
    </div>
  )
}

export default CalendarSchedule
