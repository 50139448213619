import { Typography, TYPOGRAPHY_WEIGHT } from '../Typography'
import {
  privacyAnchor,
  privacyNotice,
  privacyTextWithLinks,
  privacyWithLinksSecond
} from './contstants'
import './style.scss'
import PrivacyPolicyTable from './PrivacyPolicyTable'

const PrivacyPolicyContent = () => {
  const scroll = (link: string) => {
    const section = document.querySelector('#' + link)
    section?.scrollIntoView({ behavior: 'smooth', block: 'start' })
  }
  return (
    <>
      {privacyNotice.map((i, idx) => (
        <div key={`policy-notice-wrap-${idx}`}>
          {i.title && (
            <Typography.Headline5
              weight={TYPOGRAPHY_WEIGHT.SEMI_BOLD}
              key={`block-${idx}`}
            >
              {i.title}
            </Typography.Headline5>
          )}
          {i.text && (
            <Typography.Body1
              className="terms-subtitle"
              key={`block-text-${idx}`}
              dangerouslySetInnerHTML={{ __html: i.text }}
            />
          )}
        </div>
      ))}
      <div className="top" />
      <ol className={'privacy-policy-links'}>
        {privacyAnchor.map((i, idx) => (
          <li key={`link-${idx}`}>
            <span
              className="privacy-policy-links__hash"
              onClick={() => scroll(i.link)}
            >
              {i.text}
            </span>
          </li>
        ))}
      </ol>
      {privacyTextWithLinks.map((i, idx) => (
        <div key={`policy-text-links-wrap-${idx}`}>
          {i.title && (
            <Typography.Headline5
              weight={TYPOGRAPHY_WEIGHT.SEMI_BOLD}
              id={i?.id}
              key={`privacy-links-${idx}`}
            >
              {i.title}
            </Typography.Headline5>
          )}
          {i.text && (
            <Typography.Body1
              className="terms-subtitle"
              key={`privacy-text-${idx}`}
              dangerouslySetInnerHTML={{ __html: i.text }}
            />
          )}
        </div>
      ))}
      <PrivacyPolicyTable />
      {privacyWithLinksSecond.map((i, idx) => (
        <div key={`policy-text-links-second-wrap-${idx}`}>
          {i.title && (
            <Typography.Headline5
              weight={TYPOGRAPHY_WEIGHT.SEMI_BOLD}
              id={i?.id}
              key={`privacy-link-1.${idx}`}
            >
              {i.title}
            </Typography.Headline5>
          )}
          {i.text && (
            <Typography.Body1
              className="terms-subtitle"
              dangerouslySetInnerHTML={{ __html: i.text }}
              key={`privacy-text-1.${idx}`}
            />
          )}
        </div>
      ))}
    </>
  )
}

export default PrivacyPolicyContent
