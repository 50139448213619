import { ReactComponent as DeleteIcon } from 'assets/svg/Delete24.svg'
import { Button, BUTTON_TYPES } from '../../../components/Button'
import { useContractDetailsPagePopup } from '../Providers/ContractDetailsPagePopupProvider'
import { TDeleteButtonProps } from './types'
import './style.scss'
import cn from 'classnames'

const DeleteButton = ({
  setSelectedHS,
  healthSystem,
  userHsUuid
}: TDeleteButtonProps) => {
  const { deleteHSPopup } = useContractDetailsPagePopup()
  const handleModal = () => {
    setSelectedHS(healthSystem)
    deleteHSPopup.actions.open()
  }
  const isDisabled = healthSystem.uuid === userHsUuid
  return (
    <Button
      type={BUTTON_TYPES.GHOST}
      onClick={handleModal}
      disabled={isDisabled}
      className={cn('cpr-delete-hs-button', { disabled: isDisabled })}
    >
      <DeleteIcon />
    </Button>
  )
}

export default DeleteButton
