import { DayInMonth } from './types'
import cn from 'classnames'
import Typography from '../../../components/Typography/Typography'
import moment from 'moment'
import { getTimeDifferenceInHoursAndMinutes } from '../../../helper/dates'
import { TYPOGRAPHY_WEIGHT } from '../../../components/Typography'
import { CalendarMeetingDetailsPopover } from '../CalendarMeetingDetailsPopover/CalendarMeetingDetailsPopover'

export const DayView = ({
  label,
  eventsData,
  onEventClick,
  openedEventInfo,
  setPopoverOpened,
  isEventOpened,
  openedPopupUniqueId,
  offset
}: DayInMonth) => {
  const separatedLabel = label.split(' ')
  const isToday = moment(eventsData[0].start)
    .utcOffset(offset)
    .isSame(moment().utcOffset(offset), 'day')

  return (
    <div className="day-view-container row">
      <div className={cn('day-view-container__wrapper', { active: isToday })}>
        <div className="day-view-container__day row align-center">
          <div className="day-view-container__day__number">
            {separatedLabel[0]}
          </div>
          <div className="day-view-container__day__weekday">
            {separatedLabel[1].toUpperCase()}
          </div>
        </div>
      </div>
      <div className="day-view-container__events column full-width">
        {eventsData.map((event) => {
          const startDate = moment(event.start).utcOffset(offset)
          return (
            <CalendarMeetingDetailsPopover
              isOpen={isEventOpened && openedPopupUniqueId === event.uniqueId}
              setIsOpen={setPopoverOpened}
              event={openedEventInfo}
              key={event.uniqueId}
              placement={'bottomRight'}
              className="schedule-popover"
            >
              <div
                className={cn(
                  'calendar-event__card row full-width row schedule-view__card',
                  event.className,
                  {
                    opened:
                      isEventOpened && openedPopupUniqueId === event.uniqueId,
                    past: startDate.isBefore(moment().utcOffset(offset), 'day')
                  }
                )}
                onClick={() => {
                  onEventClick && onEventClick(event.id, event?.uniqueId ?? '')
                }}
                key={event.uniqueId}
              >
                <div className="calendar-event__badge" />
                <div className="time-wrapper">
                  <div className="time-wrapper__time">
                    {startDate.format('hh:mma')}
                  </div>
                  <Typography.Caption className="time-wrapper__duration">
                    {getTimeDifferenceInHoursAndMinutes(
                      event.start,
                      event.end,
                      offset
                    )}
                  </Typography.Caption>
                </div>
                <div className="details-wrapper">
                  <Typography.Body2 weight={TYPOGRAPHY_WEIGHT.SEMI_BOLD}>
                    {event.title}
                  </Typography.Body2>
                  <Typography.Caption className="details-wrapper__location">
                    {!!event.location ? event.location : '-'}
                  </Typography.Caption>
                </div>
              </div>
            </CalendarMeetingDetailsPopover>
          )
        })}
      </div>
    </div>
  )
}
