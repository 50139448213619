import '../../styles.scss'

import { Select } from '../../../../components/Select'
import { LABELS, PLACEHOLDERS } from '../../../../constants'
import { AddTypeOfEventOption } from './AddTypeOfEventOption'
import { ReactElement, useEffect, useState } from 'react'
import { TypeOfEventProps } from './types'

const TypeOfEventSelect = ({
  onChangeValue,
  typesSelectOptions,
  setTypesSelectOptions,
  value,
  otherType
}: TypeOfEventProps): ReactElement => {
  const [otherLabel, setOtherLabel] = useState<string>('')

  useEffect(() => {
    setOtherLabel(otherType)
  }, [otherType])

  useEffect(() => {
    if (value && typesSelectOptions) {
      onChangeValue(
        'other',
        typesSelectOptions.find((type) => type.value === 'other')?.label
      )
    }
  }, [typesSelectOptions])
  return (
    <Select
      className="calendar__modal__type-of-event-select"
      options={typesSelectOptions}
      propsItem={{
        label: LABELS.TYPE_OF_EVENT,
        name: 'event_type',
        rules: [{ required: true }]
      }}
      propsSelect={{
        placeholder: PLACEHOLDERS.PLEASE_SELECT,
        onChange: (v) => {
          const newValue =
            typesSelectOptions.find((o) => o.value === v)?.value.toString() ??
            null
          onChangeValue(newValue, otherLabel)
        },
        dropdownRender: (originNode) => {
          return (
            <div className="calendar__modal__type-of-event-select__rendered-options">
              {originNode}
              <AddTypeOfEventOption
                onChangeOtherType={setOtherLabel}
                onChangeSelectedType={(v) => {
                  if (v.length) {
                    setTypesSelectOptions(
                      typesSelectOptions.map((sO) => {
                        if (sO.value === 'other')
                          return {
                            ...sO,
                            label: v
                          }
                        return sO
                      })
                    )
                  }
                }}
                otherType={otherLabel}
              />
            </div>
          )
        }
      }}
    />
  )
}

export default TypeOfEventSelect
