import { IPickerList, ITableCell, ITableItemsData } from './types'
import { CellLocation, CellTemplates } from '@silevis/reactgrid'
import { CustomCellIconTemplate } from './Table/Cells/CustomCellIconTemplate'
import { CustomCellTextTemplate } from './Table/Cells/CustomCellTextTemplate'
import { CustomCellNumberTemplate } from './Table/Cells/CustomCellNumberTemplate'
import { CustomCellSelectTemplate } from './Table/Cells/CustomCellSelectTemplate'
import { CustomCellDatepickerTemplate } from './Table/Cells/CustomCellDatepickerTemplate'
import { CONTRACT_TYPE_OPTIONS } from '../Contracts/constants'

export const customCellTemplates: CellTemplates = {
  icon: new CustomCellIconTemplate(),
  text: new CustomCellTextTemplate(),
  date: new CustomCellDatepickerTemplate(),
  number: new CustomCellNumberTemplate(),
  dropdown: new CustomCellSelectTemplate()
}

export const tableCells: ITableCell[] = [
  { cellType: 'icon', columnId: 'icon', width: 40 },
  {
    cellType: 'text',
    headerText: 'Contract Name',
    columnId: 'contract_name',
    width: 297,
    placeholder: 'Please type in'
  },
  {
    cellType: 'dropdown',
    headerText: 'Contract Type',
    columnId: 'contract_type',
    width: 297
  },
  {
    cellType: 'date',
    headerText: 'Start Date',
    columnId: 'start_date',
    width: 144,
    placeholder: 'MM/DD/YYYY'
  },
  {
    cellType: 'date',
    headerText: 'Finish Date',
    columnId: 'finish_date',
    width: 144,
    placeholder: 'MM/DD/YYYY'
  },
  {
    cellType: 'number',
    headerText: 'Admin Fee, %',
    columnId: 'admin_fee',
    width: 144,
    placeholder: '0'
  },
  {
    cellType: 'number',
    headerText: 'Expected Spend, $',
    columnId: 'expected_spend',
    width: 144,
    placeholder: '0'
  },
  {
    cellType: 'number',
    headerText: 'Expected Savings, $',
    columnId: 'expected_savings',
    width: 144,
    placeholder: '0'
  },
  {
    cellType: 'dropdown',
    headerText: 'Vendor',
    columnId: 'vendor',
    width: 297
  },
  {
    cellType: 'dropdown',
    headerText: 'Contract Category',
    columnId: 'contract_category',
    width: 297
  },
  {
    cellType: 'dropdown',
    headerText: 'Vendor Contract Steward',
    columnId: 'vendor_contract_steward',
    width: 297
  },
  { cellType: 'icon', columnId: 'deleteIcon', width: 40 }
]

export const defaultHeaderRowId = 'header'

export const defaultFocusPosition: CellLocation = {
  columnId: tableCells[0].columnId,
  rowId: defaultHeaderRowId
}

export const availableContractTypes: IPickerList[] = [
  ...CONTRACT_TYPE_OPTIONS,
  {
    label: 'Cognus Commons Contract',
    value: 'commons'
  }
]

export const defaultRowData: ITableItemsData = {
  contract_name: '',
  contract_type: 'community',
  start_date: null,
  finish_date: null,
  admin_fee: null,
  expected_spend: null,
  expected_savings: null,
  vendor: '',
  contract_category: '',
  vendor_contract_steward: ''
}
