import {
  createContext,
  FC,
  PropsWithChildren,
  useCallback,
  useContext,
  useEffect,
  useMemo
} from 'react'
import { notification, Spin } from 'antd'
import { LoadingOutlined } from '@ant-design/icons'
import { ReactComponent as SuccessIcon } from '../../assets/svg/SuccessIcon.svg'
import { ReactComponent as ErrorIcon } from '../../assets/svg/ErrorIcon.svg'
import moment from 'moment'

export enum TNotificationType {
  ERROR = 'error',
  SUCCESS = 'success',
  INFO = 'info'
}

type TNotification = {
  id: string
  type?: TNotificationType
  numberOfItems?: number
  date?: string
}

export enum TNotificationTypeName {
  LOADING_CONTRACTS = 'loadingContracts',
  EXPORT_REJECTED_INVOICES = 'exportRejectedInvoices'
}

type ContextProps = {
  actions: {
    openNotification: (
      t: TNotification,
      typeName: TNotificationTypeName,
      setNotificationToTheLocalStorage?: boolean
    ) => void
    onCloseNotification: (id: string, typeName: TNotificationTypeName) => void
    onChangeNotification: (
      t: TNotification,
      typeName: TNotificationTypeName
    ) => void
    destroyAllNotifications: () => void
  }
}

const BottomNotificationsContext = createContext<ContextProps>({
  actions: null!
})

const BottomNotificationsContextProvider: FC<PropsWithChildren> = ({
  children
}) => {
  const [api, contextHolder] = notification.useNotification()

  useEffect(() => {
    // get all notifications from local store on page refresh
    const allNotificationTypes = Object.keys(TNotificationTypeName)

    allNotificationTypes.forEach((notificationType) => {
      const notifications = localStorage.getItem(
        TNotificationTypeName[notificationType]
      )
      if (notifications) {
        JSON.parse(notifications).map((currentNotification) => {
          openNotification(
            currentNotification,
            TNotificationTypeName[notificationType]
          )
        })
      }
    })
  }, [])

  const getTitle = useCallback(
    (
      { type, numberOfItems }: Pick<TNotification, 'type' | 'numberOfItems'>,
      typeName: TNotificationTypeName
    ) => {
      if (typeName === TNotificationTypeName.LOADING_CONTRACTS) {
        return type === 'info'
          ? `Creating ${numberOfItems} contract${
              numberOfItems === 1 ? '' : 's'
            }`
          : type === 'success'
          ? `${numberOfItems} contract${numberOfItems === 1 ? '' : 's'} created`
          : 'Contracts creation failed.'
      } else if (typeName === TNotificationTypeName.EXPORT_REJECTED_INVOICES) {
        return type === 'info'
          ? `Exporting ${numberOfItems} invoice line${
              numberOfItems === 1 ? '' : 's'
            }`
          : type === 'success'
          ? `${numberOfItems} invoice line${
              numberOfItems === 1 ? '' : 's'
            } exported`
          : 'Invoices export failed.'
      }
    },
    []
  )

  const onCloseNotification = useCallback(
    (id: string, typeName: TNotificationTypeName) => {
      // remove notification from Local store when user closes the notification
      const notifications = localStorage.getItem(typeName)
      if (notifications) {
        const parsedNotifications = JSON.parse(notifications)
        const currentNotification = parsedNotifications.find((n) => n.id === id)

        if (currentNotification.type === TNotificationType.INFO) {
          // remove date to hide the notification
          delete currentNotification.date
          localStorage.setItem(
            typeName,
            JSON.stringify([
              ...parsedNotifications.filter((n) => n.id !== id),
              currentNotification
            ])
          )
        } else {
          localStorage.setItem(
            typeName,
            JSON.stringify(parsedNotifications.filter((n) => n.id !== id))
          )
        }
      }
    },
    []
  )

  const openNotification = useCallback(
    (
      {
        id,
        type = TNotificationType.SUCCESS,
        numberOfItems,
        date
      }: TNotification,
      typeName: TNotificationTypeName,
      setNotificationToLocalStorage?: boolean
    ) => {
      // open notification and add it to the Local Storage
      const icon =
        type === TNotificationType.INFO ? (
          <Spin indicator={<LoadingOutlined style={{ fontSize: 16 }} spin />} />
        ) : type === TNotificationType.SUCCESS ? (
          <SuccessIcon className="bottom-notification__icon" />
        ) : (
          <ErrorIcon className="bottom-notification__icon" />
        )
      const text = 'Please try again.'

      const title = getTitle({ type, numberOfItems }, typeName)

      const duration = date
        ? moment.duration(moment().diff(moment(date))).asMinutes()
        : 0

      const hideCloseButton = type === TNotificationType.INFO && duration <= 30

      if (type === TNotificationType.INFO ? !!date : true) {
        api.open({
          className: 'bottom-notification',
          message: title,
          description: type === TNotificationType.ERROR ? text : null,
          duration: 0,
          icon,
          placement: 'bottomRight',
          onClose: () => onCloseNotification(id, typeName),
          closeIcon: hideCloseButton ? <></> : undefined,
          key: id
        })
      }

      if (setNotificationToLocalStorage) {
        const notifications = localStorage.getItem(typeName) ?? '[]'
        const newNotification = {
          id,
          type,
          numberOfItems
        } as TNotification
        if (date) newNotification.date = date
        if (notifications) {
          // if we already have notification with this JOB id - remove it from the local store and add new item
          if (
            JSON.parse(notifications).find(
              (currentNotification) => currentNotification.id === id
            )
          ) {
            localStorage.setItem(
              typeName,
              JSON.stringify([
                ...JSON.parse(notifications).filter(
                  (currentNotification) => currentNotification.id !== id
                ),
                newNotification
              ])
            )
            // if not - just add it to the list
          } else {
            localStorage.setItem(
              typeName,
              JSON.stringify([...JSON.parse(notifications), newNotification])
            )
          }
        }
      }
    },
    [api]
  )

  const onChangeNotification = useCallback(
    ({ id, type }: TNotification, typeName: TNotificationTypeName) => {
      // if it is some response from the BE - close prev notification & open new with status from response
      const notifications = localStorage.getItem(typeName)
      if (notifications) {
        JSON.parse(notifications).forEach((currentNotification) => {
          if (currentNotification.id === id) {
            notification.close(id)
            openNotification(
              {
                id,
                type,
                numberOfItems: currentNotification.numberOfItems
              },
              typeName,
              true
            )
          }
        })
      }
    },
    [localStorage]
  )

  const destroyAllNotifications = useCallback(() => {
    const allNotificationTypes = Object.keys(TNotificationTypeName)

    allNotificationTypes.forEach((notificationType) => {
      const notifications = localStorage.getItem(
        TNotificationTypeName[notificationType]
      )
      if (notifications) {
        JSON.parse(notifications).forEach(({ id }) => {
          notification.close(id)
        })
        localStorage.setItem(TNotificationTypeName[notificationType], '[]')
      }
    })
  }, [])

  const context = useMemo(
    () => ({
      actions: {
        onCloseNotification,
        openNotification,
        onChangeNotification,
        destroyAllNotifications
      }
    }),
    [
      onCloseNotification,
      openNotification,
      onChangeNotification,
      destroyAllNotifications
    ]
  )

  return (
    <BottomNotificationsContext.Provider value={context}>
      {children}
      {contextHolder}
    </BottomNotificationsContext.Provider>
  )
}

export const useBottomNotificationsContext = () =>
  useContext(BottomNotificationsContext)

export default BottomNotificationsContextProvider
