import {
  createContext,
  FC,
  PropsWithChildren,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState
} from 'react'
import { useDispatch } from 'react-redux'

import useInfiniteData from 'hooks/useInfiniteData'
import { THistory } from '../types'
import { initialHistoryState } from '../General/constants'
import { setLoading } from '../../../../redux/store/common/slice'
import { getPrsHistory } from '../../../../pages/ProductRequests/api'
import { useParams } from 'react-router-dom'
import { usePRSDetailsContext } from './PRSDetailsContextProvider'

type ContextProps = {
  state: {
    hasNextPage: boolean
    history: THistory
    total: number
  }
  actions: {
    handleLoadMore: VoidFunction
  }
}

const PrsHistoryContext = createContext<ContextProps>({
  state: null!,
  actions: null!
})

const PrsHistoryContextProvider: FC<PropsWithChildren> = ({ children }) => {
  const dispatch = useDispatch<any>()

  const { id } = useParams()
  const { data } = usePRSDetailsContext().state

  const [total, setTotal] = useState(0)

  const [history, setHistory] = useState<THistory>(initialHistoryState)

  const loadMore = useCallback(
    async (args) => {
      await dispatch(getPrsHistory(id, { ...args }))
    },
    [dispatch]
  )
  const handleGetHistory = useCallback(async () => {
    dispatch(setLoading(true))
    try {
      const resp = await getPrsHistory(id as string, { limit })
      if (resp.data?.results) {
        setHistory(resp.data.results)
        setTotal(resp.data.count)
      }
    } catch (e) {
      console.error(e)
    } finally {
      dispatch(setLoading(false))
    }
  }, [dispatch, id])

  useEffect(() => {
    if (id && data.uuid) handleGetHistory()
  }, [id, data])

  const { limit, hasNextPage, handleLoadMore } = useInfiniteData({
    total,
    limit: 50,
    loadMore
  })

  const context = useMemo(
    () => ({
      state: {
        history,
        hasNextPage,
        total
      },
      actions: {
        handleLoadMore
      }
    }),
    [history, hasNextPage, handleLoadMore, total]
  )

  return (
    <PrsHistoryContext.Provider value={context}>
      {children}
    </PrsHistoryContext.Provider>
  )
}

export const usePrsHistoryContext = () => useContext(PrsHistoryContext)

export default PrsHistoryContextProvider
