import { Form, Radio, RadioChangeEvent, Space } from 'antd'
import cn from 'classnames'

import { Props as BaseProps } from 'components/RadioGroup/types'

import './radioGroup.default.scss'
import { Input } from '../Input'
import { PLACEHOLDERS } from '../../constants'
import { usePRSVotingContext } from '../../features/PRS/PRSDetails/Providers/PRSVotingContext'
import { usePRSDetailsContext } from '../../features/PRS/PRSDetails/Providers/PRSDetailsContextProvider'
import { FormInstance } from 'antd/lib/form/hooks/useForm'
import { TQuestionsForm } from '../Forms/forms.surveyQuestions.d'
import { useEffect } from 'react'

type Props = {
  range?: number
  rate?: number
  disabled?: boolean
  invalid?: boolean
  options: any
  isOther?: boolean
  number: number
  optionValue?: string | null
  question?: string
  vote?: boolean
  form: FormInstance<TQuestionsForm>
} & Omit<BaseProps, 'options'>

const Default = ({
  className,
  rate,
  disabled,
  invalid,
  options,
  number,
  optionValue,
  question,
  vote,
  form
}: Props) => {
  const { state, actions } = usePRSVotingContext()
  const { isError, votingData } = state
  const { setVotingData } = actions
  const prsContext = usePRSDetailsContext()

  useEffect(() => {
    if (!!question) {
      prsContext.actions.setClinicalRationaleData({
        questions: prsContext.state.clinicalRationaleData.questions.map((i) => {
          return i.uuid === question
            ? {
                ...i,
                options: i.options.map((i) =>
                  i.is_other
                    ? { ...i, text: '', placeholder: i.placeholder ?? i.text }
                    : i
                )
              }
            : i
        })
      })
    }
  }, [])

  const onChange = (event: RadioChangeEvent) => {
    if (!!question && event?.target?.value) {
      if (vote) {
        const res = prsContext.state.clinicalRationaleData.questions.map((i) =>
          i.uuid === question
            ? {
                ...i,
                selectedOptions: [event?.target?.value],
                options: i.options.map((i) =>
                  i.is_other && i.uuid !== event?.target?.value
                    ? { ...i, text: '' }
                    : i
                ),
                option: event.target.value
              }
            : i
        )
        prsContext.actions.setClinicalRationaleData({
          questions: res
        })
        form.setFieldsValue({ questions: res })
      } else {
        const res = votingData.questions.map((i) =>
          i.uuid === question
            ? {
                ...i,
                selectedOptions: [event?.target?.value],
                options: i.options.map((i) =>
                  i.is_other && event?.target?.value !== i.uuid
                    ? { ...i, text: '' }
                    : i
                ),
                option: event.target.value
              }
            : i
        )
        setVotingData({
          questions: res
        })
        form.setFieldsValue({ questions: res })
      }
    }
  }

  return (
    <div className="radio-group-default-wrapper ">
      <Form.Item name={[number, 'option']}>
        <Radio.Group
          className={cn('radio-group-default', className)}
          size="large"
          onChange={(event) => {
            onChange(event)
          }}
        >
          <Space direction="vertical" className={cn({ error: isError })}>
            {options.map((option, index) => {
              return (
                <div key={index} className="radio-group-default">
                  {option.is_other ? (
                    <Radio
                      className="radio-group-default__input"
                      disabled={disabled}
                      value={option.uuid}
                    >
                      {optionValue === option.uuid ? (
                        <Input
                          propsItem={{
                            name: [number, 'options', index, 'text'],
                            rules: [
                              {
                                max: 100,
                                required: optionValue === option.uuid,
                                message: 'The field cannot be empty'
                              }
                            ]
                          }}
                          propsInput={{
                            maxLength: 100,
                            disabled: optionValue !== option.uuid,
                            placeholder: option.is_other
                              ? option.placeholder ?? option.text
                              : PLACEHOLDERS.OTHER
                          }}
                        />
                      ) : (
                        <Input
                          propsInput={{
                            disabled: true,
                            placeholder: option.is_other
                              ? option.placeholder ?? option.text
                              : PLACEHOLDERS.OTHER
                          }}
                        />
                      )}
                    </Radio>
                  ) : (
                    <Radio
                      className={invalid ? 'radio-group__radio--error' : ''}
                      id={`radio-${rate}`}
                      disabled={disabled}
                      value={option.uuid}
                    >
                      {option.label}
                    </Radio>
                  )}
                </div>
              )
            })}
          </Space>
        </Radio.Group>
      </Form.Item>
    </div>
  )
}

export default Default
