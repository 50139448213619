import Typography from 'components/Typography/Typography'
import { Can } from '../Providers/PrsDetailsAbilityProvider'
import { ACTIONS, SUBJECTS } from '../../Abilities'
import { subject } from '@casl/ability'
import { Button, BUTTON_TYPES } from 'components/Button'
import { BTN_TXT } from 'constants/txt'
import { usePRSDetailsContext } from '../Providers/PRSDetailsContextProvider'
import { usePRSDetailsPopupContext } from '../Providers/PRSDetailsPopupProvider'
import './style.scss'
import ClinicalForm from './ClinicalForm'
import ClinicalResponse from './Responses/ClincialResponse'
import { TYPOGRAPHY_WEIGHT } from '../../../../components/Typography'

const ClinicalRationale = () => {
  const prsDetails = usePRSDetailsContext()
  const { openRationalePopup } = usePRSDetailsPopupContext()
  const { data, clinicalRationaleResponse, isClinicalSponsor } =
    prsDetails.state
  return (
    <Can I={ACTIONS.VIEW} a={subject(SUBJECTS.CLINICAL_RATIONALE, { ...data })}>
      <div className="clinical-rationale-container mt-24 column">
        <Typography.Headline6>Clinical rationale</Typography.Headline6>
        {!!clinicalRationaleResponse?.size ? (
          <ClinicalResponse />
        ) : (
          <>
            {isClinicalSponsor ? (
              <Can
                I={ACTIONS.OPEN}
                a={subject(SUBJECTS.CLINICAL_RATIONALE, { ...data })}
              >
                <Button
                  onClick={openRationalePopup.actions.open}
                  upperCase
                  type={BUTTON_TYPES.PRIMARY}
                  small
                >
                  {BTN_TXT.OPEN_SURVEY}
                </Button>
                {openRationalePopup.state.visible && <ClinicalForm />}
              </Can>
            ) : (
              <Typography.Headline6 weight={TYPOGRAPHY_WEIGHT.NORMAL}>
                No responses yet.
              </Typography.Headline6>
            )}
          </>
        )}
      </div>
    </Can>
  )
}

export default ClinicalRationale
