import { DatePicker } from 'antd'
import { KeyboardEvent } from 'react'
import cn from 'classnames'
import { ReactComponent as ArrowRight16 } from 'assets/svg/ArrowRight16.svg'
import { ReactComponent as ArrowLeft16 } from 'assets/svg/ArrowBack16.svg'
import { timelineDateFormat } from '../Timelines/constants'
import { TDatePickerDefault as Props } from './types'
import './datepicker.default.scss'

export const DefaultNoForm = ({ className, ...props }: Props) => {
  const onKeyDown = (e: KeyboardEvent<HTMLInputElement>) => {
    if (
      !/^(\d|Backspace|\/)$/.test(e.key) &&
      // support pasting into input when rewriting onPaste property
      // expect that noFormWrap will avoid pasting text in Datepicker by itself
      !(e.key === 'v' && (e.metaKey || e.ctrlKey))
    ) {
      e.preventDefault()
    }
  }

  return (
    <div
      className={cn('custom-datepicker datepicker-default', className, {
        'custom-picker-error': props.hasCustomError
      })}
    >
      <DatePicker
        format={timelineDateFormat}
        getPopupContainer={(node) => node}
        {...props.propsDate}
        nextIcon={<ArrowRight16 />}
        prevIcon={<ArrowLeft16 />}
        superNextIcon={false}
        superPrevIcon={false}
        allowClear={false}
        onKeyDown={onKeyDown}
        dropdownClassName="datepicker-dropdown"
      />
    </div>
  )
}
