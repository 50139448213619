import { Button, BUTTON_TYPES } from 'components/Button'
import { BTN_TXT } from 'constants/txt'
import { ReactComponent as Send16 } from 'assets/svg/Send16.svg'
import { ACTIONS, SUBJECTS } from 'features/PRS/Abilities'
import { subject } from '@casl/ability'
import { usePRSDetailsActions } from '../Providers/PRSDetailsActionsProvider'
import { usePRSDetailsContext } from '../Providers/PRSDetailsContextProvider'
import { Can } from '../Providers/PrsDetailsAbilityProvider'
import { usePRSDetailsPopupContext } from '../Providers/PRSDetailsPopupProvider'
import { STATUS_SCHEME } from './constants'
import Popup from 'components/Popup/Popup'
import { Typography, TYPOGRAPHY_WEIGHT } from 'components/Typography'
export default () => {
  const prsDetailsActions = usePRSDetailsActions()
  const prsDetailsContext = usePRSDetailsContext()
  const popup = usePRSDetailsPopupContext()
  const { sendPrsPopup } = popup

  const { data } = prsDetailsContext.state
  const onSend = () => {
    prsDetailsContext.actions.handleChangePrsStatus(
      STATUS_SCHEME[data.status].next
    )
  }
  return (
    <Can I={ACTIONS.SEND} a={subject(SUBJECTS.PRS, { ...data })}>
      <Button
        icon={<Send16 />}
        type={BUTTON_TYPES.PRIMARY}
        onClick={prsDetailsActions.handleSendPrs}
        upperCase
      >
        {BTN_TXT.SEND_PRS}
      </Button>
      <Popup
        visible={sendPrsPopup.state.visible}
        onCancel={sendPrsPopup.actions.close}
        title={`Send ${data.name}?`}
        width={442}
        footer={
          <>
            <Button
              onClick={sendPrsPopup.actions.close}
              type={BUTTON_TYPES.DEFAULT}
              upperCase
            >
              {BTN_TXT.CANCEL}
            </Button>
            <Button type={BUTTON_TYPES.PRIMARY} upperCase onClick={onSend}>
              {BTN_TXT.SEND_PRS}
            </Button>
          </>
        }
      >
        <Typography.Headline6 weight={TYPOGRAPHY_WEIGHT.NORMAL}>
          PRS request will be set for review to the selected Community
          responsible person. You will receive updates regarding review process.
        </Typography.Headline6>
      </Popup>
    </Can>
  )
}
