import { Error } from './notification.error'
import { Warning } from './notification.warning'
import { Success } from './notification.success'
import { TNotification } from './types'
import './styles.scss'

type TNotificationTypes = {
  error: (props: TNotification) => void
  warning: (props: TNotification) => void
  success: (props: TNotification) => void
}

export default {
  error: Error,
  warning: Warning,
  success: Success
} as TNotificationTypes
