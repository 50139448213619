import { useDashboardPopup } from '../Providers/DashboardPopupProvider'
import Popup from '../../../components/Popup/Popup'
import { Button, BUTTON_TYPES } from '../../../components/Button'
import { BTN_TXT, LABELS } from '../../../constants'
import { Form, Space } from 'antd'
import { TActualSavingsModalList, TEditSavingsGoalsProps } from './types'
import { ReactComponent as Plus16 } from 'assets/svg/Plus16.svg'
import { Input } from '../../../components/Input'
import { formatMoney, safeString } from '../../../helper/common'
import Typography from '../../../components/Typography/Typography'
import { TYPOGRAPHY_WEIGHT } from '../../../components/Typography'
import './style.scss'
import { FieldWithLabel } from '../../../components/FieldWithLabel/FieldWithLabel'
import useEditSavingsGoalsModal from '../Providers/useEditSavingsGoalsModal'
import moment from 'moment'

const EditSavingsGoals = ({
  healthSystem,
  getChartData
}: TEditSavingsGoalsProps) => {
  const { editSavingsGoalsPopup } = useDashboardPopup()
  const { state, actions } = editSavingsGoalsPopup

  const {
    form,
    onSetPeriods,
    actualSavingsResults,
    actualSavings,
    setIsChanged,
    isChanged,
    isDisabled
  } = useEditSavingsGoalsModal({ healthSystem: healthSystem, getChartData })

  const handleClose = () => {
    actions.close()
    form.resetFields()
    setIsChanged(false)
  }

  return (
    <Popup
      visible={state.visible}
      onCancel={handleClose}
      title={state.title}
      width={400}
      footer={
        <>
          <Button type={BUTTON_TYPES.DEFAULT} onClick={handleClose} upperCase>
            {BTN_TXT.CANCEL}
          </Button>
          <Button
            type={BUTTON_TYPES.PRIMARY}
            htmlType="submit"
            disabled={!isChanged}
            form="edit-actual-savings-modal"
            upperCase
          >
            {BTN_TXT.SAVE}
          </Button>
        </>
      }
    >
      <Form<TActualSavingsModalList>
        id="edit-actual-savings-modal"
        className="edit-actual-savings-container"
        form={form}
        onFinish={onSetPeriods}
        layout="vertical"
        onValuesChange={() => setIsChanged(true)}
        initialValues={{ actual_savings: actualSavingsResults }}
      >
        <Form.List name="actual_savings">
          {(fields, { add }) => (
            <>
              {fields.map(({ key, name }) => {
                return (
                  <Space
                    key={key}
                    className="row align-start actual-savings-period-row"
                    direction="horizontal"
                  >
                    <Form.Item name="year" className="actual-savings-period">
                      <FieldWithLabel title="Period">
                        {safeString(actualSavings[key]?.year)}
                      </FieldWithLabel>
                    </Form.Item>
                    <Input.Number
                      propsItem={{
                        label: LABELS.SAVINGS_GOALS,
                        name: [name, 'goal'],
                        rules: [
                          {
                            pattern: /^(\d*\.)?\d+$/
                          }
                        ]
                      }}
                      propsInputNumber={{
                        disabled: false,
                        controls: false,
                        defaultValue: 0,
                        maxLength: 19,
                        formatter: formatMoney,
                        prefix: (
                          <Typography.Headline6
                            weight={TYPOGRAPHY_WEIGHT.NORMAL}
                            className="prefix-actual-savings"
                          >
                            $
                          </Typography.Headline6>
                        )
                      }}
                    />
                  </Space>
                )
              })}
              <Button
                type={BUTTON_TYPES.GHOST}
                icon={<Plus16 />}
                disabled={isDisabled}
                className="user-form_add-hospital mt-24"
                data-testid="addPeriodBtn"
                onClick={() =>
                  add({
                    year: !!actualSavings?.length
                      ? actualSavings[actualSavings?.length - 1]?.year + 1
                      : moment().year(),
                    goal: 0
                  })
                }
                upperCase
              >
                {BTN_TXT.ADD_PERIOD}
              </Button>
            </>
          )}
        </Form.List>
      </Form>
    </Popup>
  )
}

export default EditSavingsGoals
