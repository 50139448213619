import { useState, useEffect, useRef, useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import axios, { CancelTokenSource } from 'axios'
import classNames from 'classnames'
import { Table } from 'components/Table'
import { setLoading } from 'redux/store/common/slice'
import { DEFAULT_PAGE } from 'components/Table/constants'
import { FINANCIAL_STATUS, columns, initialPopupData } from './constants'
import { acceptAndPayReport, getFinanceData } from './api'
import {
  TFinancialsList,
  TFinancialsListFilters,
  TFinancialsListFiltersFunc,
  TFinancialsListParams,
  TFinancialsTable,
  TFinancialsTableRequestData,
  TPopup
} from './types'
import { Button, BUTTON_TYPES } from '../../components/Button'
import { BTN_TXT, TOOLTIP } from '../../constants'
import { ReactComponent as Close16 } from '../../assets/svg/Clos16.svg'
import { ReactComponent as Check16 } from '../../assets/svg/Check16.svg'
import { ReactComponent as Money16 } from '../../assets/svg/Money16.svg'
import { getUser } from '../../redux/store/user/getters'
import { ROLES } from '../../features/Permission'
import { FinancialsPopup } from './FinancialsPopup'
import { notification } from '../../components/Notification'
import { Tooltip } from 'components/Tooltip/Tooltip'
import { convertDateToRequestFormat } from '../../utils/moment'
import './styles.scss'

export const FinancialsTable = ({
  period,
  selectedVendors,
  selectedFacilities,
  isVendor,
  isUpdate,
  setIsUpdate,
  getChartsData
}: TFinancialsTable) => {
  const [tableData, setTableData] = useState<TFinancialsList[]>([])
  const [sortParams, setSortParams] = useState<TFinancialsTableRequestData>()
  const [firstLoad, setFirstLoad] = useState(true)
  const [totalNumber, setTotalNumber] = useState(0)
  const [pageInfo, setPageInfo] = useState({ ...DEFAULT_PAGE })
  const [selectedRowKeys, setSelectedRowKeys] = useState([])
  const [selectedRows, setSelectedRows] = useState<TFinancialsList[]>([])
  const [isPopupOpen, setPopupOpen] = useState<TPopup>(initialPopupData)
  const [contractId, setContractId] = useState<string>('')
  const [filters, setFilters] = useState<TFinancialsListFilters>({})
  const timeoutRef = useRef<ReturnType<typeof setTimeout>>()
  const tableDataTokenRef = useRef<CancelTokenSource>()
  const dispatch = useDispatch()
  const user = useSelector(getUser)

  const isCanResend =
    user.role === ROLES.VICE_PRESIDENT ||
    user.role === ROLES.CONTRACT_STEWARD ||
    user.role === ROLES.ANALYST

  useEffect(() => {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current)
    }
    timeoutRef.current = setTimeout(
      () => getTableData({ ...sortParams, ...filters }),
      firstLoad ? 0 : 500
    )
  }, [period, selectedVendors, selectedFacilities, isUpdate])
  const handleUpdateData = () => {
    getTableData({ ...sortParams, ...filters })
  }
  useEffect(() => {
    return () => {
      if (!!tableDataTokenRef.current) {
        tableDataTokenRef.current.cancel()
      }
    }
  }, [])

  const getTableData = ({
    sortField,
    sortOrder,
    filters,
    page
  }: TFinancialsTableRequestData) => {
    dispatch(setLoading(true))
    if (!!tableDataTokenRef.current) {
      tableDataTokenRef.current.cancel()
    }
    const params: TFinancialsListParams = { ...filters }
    if (period && period[0] && period[1]) {
      params.date_from = convertDateToRequestFormat(period[0])
      params.date_to = convertDateToRequestFormat(period[1])
    }
    if (selectedVendors) {
      params.vendors = selectedVendors
    }
    if (selectedFacilities) {
      params.facilities = selectedFacilities
    }
    if (sortField) {
      if (sortOrder) {
        params.ordering = `${sortOrder === 'descend' ? '-' : ''}${sortField}`
      }
      setSortParams({
        sortField,
        sortOrder
      })
    }
    const dataPage = page ? page : pageInfo
    params.limit = dataPage.pageSize
    params.offset = (dataPage.pageNumber - 1) * dataPage.pageSize
    tableDataTokenRef.current = axios.CancelToken.source()
    getFinanceData({
      cancelToken: tableDataTokenRef.current?.token,
      params
    })
      .then((resp) => {
        if (!resp.data?.results) {
          return
        }
        setTableData(
          resp.data.results.map((i) => ({
            ...i,
            key: i.uuid
          }))
        )
        setSelectedRows([])
        setSelectedRowKeys([])
        setTotalNumber(resp.data?.count)
        if (firstLoad) {
          setFirstLoad(false)
        }
      })
      .finally(() => dispatch(setLoading(false)))
  }

  const handleSelectRow = (selectedRowKeys, selectedRow) => {
    setSelectedRowKeys(selectedRowKeys)
    setSelectedRows(selectedRow)
  }

  const handleTableChange = (pagination, _filters, sorter) => {
    const page = {
      pageNumber: pagination.current,
      pageSize: pagination.pageSize
    }
    getTableData({
      sortField: sorter.field,
      sortOrder: sorter.order,
      filters,
      page
    })
    setPageInfo(page)
  }
  const handleChangePageSize = (pageSize) => {
    const page = {
      pageNumber: 1,
      pageSize
    }
    getTableData({
      ...sortParams,
      filters,
      page
    })
    setPageInfo(page)
  }

  const onDeclineHandler = () => {
    setPopupOpen({ declinePopup: true })
  }

  const onAcceptHandler = () => {
    setPopupOpen({ acceptPopup: true })
  }
  const onPayHandler = () => {
    dispatch(setLoading(true))
    acceptAndPayReport(selectedRows[0].uuid)
      .then((resp) => {
        if (!!resp?.data?.session_url) {
          window.open(resp?.data?.session_url, '_blank')?.focus()
        }
        setSelectedRows([])
        setSelectedRowKeys([])
        handleUpdateData()
        getChartsData()
      })
      .catch((err: any) => notification.error({ message: err?.data?.details }))
      .finally(() => {
        dispatch(setLoading(false))
        setPopupOpen({ acceptPopup: false })
      })
  }

  const applyFilters: TFinancialsListFiltersFunc = (field, appliedFilters) => {
    const query = field === 'status' ? 'statuses' : field
    const newFilters = { ...filters, [query]: appliedFilters }
    setFilters(newFilters)
    getTableData({ ...sortParams, filters: newFilters })
  }

  const onResendHandler = (data) => {
    setPopupOpen({ resendPopup: true })
    setContractId(data)
  }

  const renderCell = useCallback(
    (checked, _record, _, node) =>
      checked && selectedRowKeys.length === 1 ? (
        <div className="financials-table__checked">
          <Check16 />
          {node}
        </div>
      ) : (
        <div className="financials-table__not-checked">
          <Tooltip
            title={TOOLTIP.CANT_BE_SELECTED_AS_PAYMENT_HAS_BEEN_ALREADY}
            overlayInnerStyle={{
              display:
                _record.status === FINANCIAL_STATUS.PAID ? 'block' : 'none',
              width: 272
            }}
            placement="topLeft"
            getPopupContainer={(node) => node}
          >
            <Check16 />
            {node}
          </Tooltip>
        </div>
      ),
    [selectedRowKeys.length]
  )

  return (
    <div className={classNames('table-wrapper')}>
      {selectedRows.length === 1 && (
        <div className="financials-table__button-container">
          {selectedRows[0].status === FINANCIAL_STATUS.SENT && (
            <Button
              icon={<Close16 />}
              onClick={onDeclineHandler}
              type={BUTTON_TYPES.DEFAULT}
              danger
              upperCase
              className="financials-table__decline"
            >
              {BTN_TXT.DECLINE}
            </Button>
          )}
          {(selectedRows[0].status === FINANCIAL_STATUS.SENT ||
            selectedRows[0].status === FINANCIAL_STATUS.DECLINED) && (
            <Button
              icon={<Check16 />}
              onClick={onAcceptHandler}
              type={BUTTON_TYPES.PRIMARY}
              upperCase
            >
              {BTN_TXT.ACCEPT}
            </Button>
          )}
          {(selectedRows[0].status === FINANCIAL_STATUS.PENDING_FOR_PAYMENT ||
            selectedRows[0].status === FINANCIAL_STATUS.PAYMENT_IN_PROGRESS ||
            selectedRows[0].status === FINANCIAL_STATUS.PAYMENT_FAILED) && (
            <Button
              icon={<Money16 />}
              onClick={onPayHandler}
              type={BUTTON_TYPES.PRIMARY}
              upperCase
            >
              {BTN_TXT.PAY}
            </Button>
          )}
        </div>
      )}
      {!firstLoad && (
        <Table<TFinancialsList>
          dataSource={tableData}
          className="financials-table-container"
          columns={columns({
            onResendHandler,
            isCanResend,
            isVendor,
            applyFilters,
            filters,
            user
          })}
          onChange={handleTableChange}
          onChangePage={handleChangePageSize}
          pageSize={pageInfo.pageSize}
          pagination={{
            pageSize: pageInfo.pageSize,
            current: pageInfo.pageNumber,
            total: totalNumber
          }}
          rowSelection={
            user.role === ROLES.VENDOR_FINANCE_OFFICER
              ? {
                  selectedRowKeys,
                  onChange: handleSelectRow,
                  hideSelectAll: true,
                  renderCell: renderCell,
                  type: 'radio',
                  getCheckboxProps: (record) => ({
                    disabled: record.status === FINANCIAL_STATUS.PAID
                  })
                }
              : undefined
          }
        />
      )}
      <FinancialsPopup
        setPopupOpen={setPopupOpen}
        selectedRows={selectedRows}
        setSelectedRows={setSelectedRows}
        setSelectedRowKeys={setSelectedRowKeys}
        setIsUpdate={setIsUpdate}
        contractId={contractId}
        isPopupOpen={isPopupOpen}
        onPayHandler={onPayHandler}
      />
    </div>
  )
}
