import { useCallback, useEffect, useMemo } from 'react'
import cn from 'classnames'

import { BTN_TXT } from 'constants/txt'
import { Button, BUTTON_TYPES } from 'components/Button'
import { Typography } from 'components/Typography'
import { useRFPSurveyContext } from 'features/RFP/RFPDetails/Providers/RFPSurveyContextProvider'
import CreateSurveyButton from 'features/RFP/RFPSurvey/CreateSurvey/CreateSurveyButton'
import RFPCreateSurveyContextProvider from 'features/RFP/RFPSurvey/CreateSurvey/Providers/RFPCreateSurveyContextProvider'
import {
  CreateSurveyPopup,
  SubmitSurveyPopup
} from 'features/RFP/RFPSurvey/CreateSurvey/index'
import { useRFPDetailsPopupContext } from 'features/RFP/RFPDetails/Providers/RFPDetailsPopupProvider'
import { useRFPDetailsContext } from 'features/RFP/RFPDetails/Providers/RFPDetailsContextProvider'
import { SurveyResults } from '../SurveyResults'
import { useRFPVotingContext } from 'features/RFP/RFPDetails/Providers/RFPVotingContextProvider'
import OpenSurveyButton from './OpenSurveyButton'
import './styles.scss'
import { ACTIONS, Can, SUBJECTS } from 'features/Permission'
import { subject } from '@casl/ability'
import { RFP_STATUSES } from '../../constants'

const CreateSurvey = () => {
  const rfpSurveyContext = useRFPSurveyContext()
  const rfpDetailsContext = useRFPDetailsContext()
  const rfpVotingContext = useRFPVotingContext()

  const { createSurveyPopup, submitSurveyPopup } = useRFPDetailsPopupContext()

  const {
    data,
    isCurrentUserStakeholder,
    isCurrentUserCommonsStakeholderAndFU
  } = rfpDetailsContext.state
  const { isSurveyCreated, isSurveySubmitted } = rfpSurveyContext.state
  const { isVotingInProgress } = rfpVotingContext.state

  const { getRfpAcceptedVendorListAsync, getStakeholderVoteAsync } =
    rfpSurveyContext.actions

  const handleClickOpenSurvey = useCallback(async (callback: VoidFunction) => {
    callback()
  }, [])

  const CreateSurveyActions = useMemo(() => {
    const handleClick = () => {
      handleClickOpenSurvey(createSurveyPopup.actions.open)
    }

    return (
      <div className="create-survey__actions">
        <CreateSurveyButton onClick={handleClick} />
      </div>
    )
  }, [createSurveyPopup.actions.open, handleClickOpenSurvey])

  const CreatedSurveyActions = useMemo(() => {
    if (
      (isCurrentUserStakeholder || isCurrentUserCommonsStakeholderAndFU) &&
      isSurveySubmitted
    ) {
      return null
    }

    const handleClick = () => {
      handleClickOpenSurvey(submitSurveyPopup.actions.open)
    }

    return (
      isVotingInProgress && (
        <div className="create-survey__actions">
          <OpenSurveyButton onClick={handleClick} />
        </div>
      )
    )
  }, [
    handleClickOpenSurvey,
    isCurrentUserStakeholder,
    isCurrentUserCommonsStakeholderAndFU,
    isSurveySubmitted,
    isVotingInProgress,
    submitSurveyPopup.actions.open
  ])

  const ViewSurveyActions = useMemo(() => {
    if (
      (isCurrentUserStakeholder || isCurrentUserCommonsStakeholderAndFU) &&
      !isSurveySubmitted
    ) {
      return null
    }

    const handleClick = async () => {
      const openAction = isSurveySubmitted
        ? submitSurveyPopup.actions.open
        : createSurveyPopup.actions.open

      await handleClickOpenSurvey(openAction)
    }

    return (
      <Button onClick={handleClick} type={BUTTON_TYPES.LINK} upperCase>
        {BTN_TXT.VIEW_SURVEY}
      </Button>
    )
  }, [
    isCurrentUserStakeholder,
    isSurveySubmitted,
    submitSurveyPopup.actions.open,
    createSurveyPopup.actions.open,
    handleClickOpenSurvey,
    isCurrentUserCommonsStakeholderAndFU
  ])

  useEffect(() => {
    getRfpAcceptedVendorListAsync()
  }, [getRfpAcceptedVendorListAsync])

  useEffect(() => {
    if (
      (isCurrentUserStakeholder || isCurrentUserCommonsStakeholderAndFU) &&
      (data.status === RFP_STATUSES.VENDORS_SELECTION ||
        data.status === RFP_STATUSES.VOTING_IN_PROGRESS ||
        data.status === RFP_STATUSES.ARCHIVED)
    ) {
      getStakeholderVoteAsync()
    }
  }, [
    getStakeholderVoteAsync,
    isCurrentUserStakeholder,
    data.status,
    isCurrentUserCommonsStakeholderAndFU
  ])

  return (
    <RFPCreateSurveyContextProvider>
      <div className="create-survey">
        <div className="create-survey__header">
          <Typography.Headline6
            className={cn('create-survey__title', {
              'create-survey__title--submitted':
                (isCurrentUserStakeholder ||
                  isCurrentUserCommonsStakeholderAndFU) &&
                isSurveySubmitted
            })}
          >
            Survey
          </Typography.Headline6>
          {isSurveyCreated && ViewSurveyActions}
        </div>
        <Can I={ACTIONS.VIEW} a={subject(SUBJECTS.VOTING_RESULTS, { ...data })}>
          {isSurveyCreated && <SurveyResults rfpID={data.uuid} />}
        </Can>
        {isSurveyCreated ? CreatedSurveyActions : CreateSurveyActions}
      </div>
      <CreateSurveyPopup />
      <SubmitSurveyPopup />
    </RFPCreateSurveyContextProvider>
  )
}

export default CreateSurvey
