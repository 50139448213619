import { Tooltip } from '../Tooltip/Tooltip'
import { Button } from '../Button'
import { ReactComponent as CreatChat16 } from 'assets/svg/CreatChat16.svg'
import { FC } from 'react'
import { StartChatProps } from './types'

export const StartChat: FC<StartChatProps> = ({ onClick, type }) => (
  <Tooltip title="Send Message">
    <Button.Icon type={type} icon={<CreatChat16 />} onClick={onClick} small />
  </Tooltip>
)
