import { State } from '../../type'
import { moduleName } from './constants'
import { IChatDetails, IChatDetailsState } from './types'

const getState = (state: State): IChatDetailsState => state[moduleName]

export const getChatDetails =
  <T extends IChatDetails>() =>
  (state: State) => {
    const stateSlice = getState(state)
    return stateSlice.details as T | null
  }

export const getChatMembers = (state: State) => {
  const stateSlice = getState(state)
  return stateSlice.members
}

export const getError = (state: State) => {
  const stateSlice = getState(state)
  return stateSlice.error
}

export const getIsLoaded = (state: State): boolean => {
  const stateSlice = getState(state)
  return stateSlice.isLoaded
}

export const getIsLoading = (state: State): boolean => {
  const stateSlice = getState(state)
  return stateSlice.isLoading
}

export const getCountOfUnreadMessages = (state: State): number => {
  const stateSlice = getState(state)
  return stateSlice.details?.unread_messages_count || 0
}
