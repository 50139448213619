import {
  Typography,
  TYPOGRAPHY_WEIGHT
} from '../../../../components/Typography'
import { Chart } from './Chart'
import { useComplianceContext } from '../../../../pages/Compliance/Providers/ComplianceContextProvider'

export const MyActivity = () => {
  const {
    state: { myActivity }
  } = useComplianceContext()

  return (
    <div className="compliance-container__chart-wrapper part charts_ongoing-contracts_container my-activity-container">
      <Typography.Body1
        className="compliance-container__chart-wrapper__title"
        weight={TYPOGRAPHY_WEIGHT.BOLD}
      >
        My Activity
      </Typography.Body1>
      {myActivity?.length ? (
        <Chart data={myActivity} />
      ) : (
        <div className="full-width full-height row align-center justify-center empty-state">
          <Typography.Body1>The list is empty</Typography.Body1>
        </div>
      )}
    </div>
  )
}
