import { FC } from 'react'

import { BTN_TXT } from 'constants/txt'
import { Button, BUTTON_TYPES } from 'components/Button'
import { usePRSDetailsContext } from '../Providers/PRSDetailsContextProvider'

type Props = {
  onClick: VoidFunction
}

const CreateSurvey: FC<Props> = (props) => {
  const { onClick } = props

  const prsCreateSurveyContext = usePRSDetailsContext()

  const { canCreateOrEditSurvey } = prsCreateSurveyContext.state

  return canCreateOrEditSurvey ? (
    <Button type={BUTTON_TYPES.PRIMARY} onClick={onClick} upperCase>
      {BTN_TXT.CREATE_SURVEY}
    </Button>
  ) : null
}

export default CreateSurvey
