import { ColumnsType } from 'antd/es/table'
import { SortOrder } from 'antd/es/table/interface'
import { Dropdown } from 'components/Dropdown'
import { Sorter } from 'components/Sorter/Sorter'
import { ROLES_NAMES, VENDOR_ROLES_OPTIONS } from 'constants/common'
import { ReactComponent as Filter16 } from 'assets/svg/Filter16.svg'
import { ReactComponent as FilterApplied16 } from 'assets/svg/FilterApplied16.svg'
import {
  TRFPVendorUsersData,
  TRFPVendorUsersFilters,
  TRFPVendorUsersFiltersFunc,
  TRFPVendorUsersColumnsFunc
} from './types'
import { fullFormatPhoneNumber } from 'helper/common'
import { DescriptionCell } from '../../../components/DescriptionCell/DescriptionCell'
import { fetchContractCategories } from '../../../pages/CRUDUser/api'

export const columns = ({
  applyFilters,
  filters
}: TRFPVendorUsersColumnsFunc): ColumnsType<TRFPVendorUsersData> => {
  const _columns: ColumnsType<TRFPVendorUsersData> = [
    {
      dataIndex: 'first_name',
      key: 'first_name',
      title: ({ sortOrder, sortColumn }) => (
        <Sorter<TRFPVendorUsersData>
          dataIndex="first_name"
          sortColumn={sortColumn}
          sortOrder={sortOrder as SortOrder}
        >
          User name
        </Sorter>
      ),
      render: (_, { first_name, last_name }) => `${first_name} ${last_name}`,
      sorter: true,
      showSorterTooltip: false
    },
    {
      dataIndex: 'role',
      key: 'role',
      title: ({ sortOrder, sortColumn }) => (
        <Sorter<TRFPVendorUsersData>
          dataIndex="role"
          sortColumn={sortColumn}
          sortOrder={sortOrder as SortOrder}
        >
          Role
        </Sorter>
      ),
      filterDropdown: (props) => (
        <Dropdown.Filter<
          TRFPVendorUsersFilters,
          TRFPVendorUsersFiltersFunc,
          unknown
        >
          items={VENDOR_ROLES_OPTIONS}
          applyFilters={applyFilters}
          appliedFilters={filters}
          field="roles"
          {...props}
        />
      ),
      render: (text) => ROLES_NAMES[text],
      filterIcon: !!filters?.roles?.length ? <FilterApplied16 /> : <Filter16 />,
      filterSearch: true,
      sorter: true,
      showSorterTooltip: false
    },
    {
      dataIndex: 'email',
      key: 'email',
      title: ({ sortOrder, sortColumn }) => (
        <Sorter<TRFPVendorUsersData>
          dataIndex="email"
          sortColumn={sortColumn}
          sortOrder={sortOrder as SortOrder}
        >
          Email
        </Sorter>
      ),
      sorter: true,
      showSorterTooltip: false
    },
    {
      dataIndex: 'phone_number',
      key: 'phone_number',
      title: ({ sortOrder, sortColumn }) => (
        <Sorter<TRFPVendorUsersData>
          dataIndex="phone_number"
          sortColumn={sortColumn}
          sortOrder={sortOrder as SortOrder}
        >
          Phone #
        </Sorter>
      ),
      render: (_, { phone_number }) => fullFormatPhoneNumber(phone_number),
      sorter: true,
      showSorterTooltip: false
    },
    {
      dataIndex: 'contract_categories',
      key: 'contract_categories',
      title: ({ sortOrder, sortColumn }) => (
        <Sorter<TRFPVendorUsersData>
          dataIndex="contract_categories"
          sortColumn={sortColumn}
          sortOrder={sortOrder as SortOrder}
        >
          Contract category
        </Sorter>
      ),
      filterDropdown: (props) => (
        <Dropdown.Filter<
          TRFPVendorUsersFilters,
          TRFPVendorUsersFiltersFunc,
          unknown
        >
          searchCallback={fetchContractCategories}
          asyncSearch
          applyFilters={applyFilters}
          appliedFilters={filters}
          field="contract_categories"
          {...props}
        />
      ),
      render: (_, { contract_categories }) => {
        const text = contract_categories.map((i) => i.name).join(', ')
        return <DescriptionCell content={text} text={text} />
      },
      filterIcon: !!filters?.contract_categories?.length ? (
        <FilterApplied16 />
      ) : (
        <Filter16 />
      ),
      filterSearch: true,
      sorter: true,
      showSorterTooltip: false
    }
  ]
  return _columns
}
