import { useCallback } from 'react'
import './style.scss'
import { usePRSVotingContext } from '../Providers/PRSVotingContext'
import Survey from './Survey/Survey'
import Response from './Responses/Response'

const VotingContent = () => {
  const prsVotingContext = usePRSVotingContext()

  const { headerOptions, activeHeaderOptionValue } = prsVotingContext.state

  const renderContent = useCallback(() => {
    switch (activeHeaderOptionValue) {
      case headerOptions[0].value:
        return <Survey />
      case headerOptions[1].value:
        return <Response />
      default:
        return null
    }
  }, [activeHeaderOptionValue, headerOptions])

  return <div className="rfp-voting-content">{renderContent()}</div>
}

export default VotingContent
