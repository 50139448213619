import { FC } from 'react'
import cn from 'classnames'
import { Button, BUTTON_TYPES } from '../Button'
import { Popup } from '../Popup'
import usePopup from '../../hooks/usePopup'
import { useShowPopup } from '../../hooks/useShowPopup'
import { BTN_TXT } from '../../constants'
import { FloatFooterProps } from './types'
import './styles.scss'

export const FloatFooter: FC<FloatFooterProps> = ({
  className,
  onCancel,
  isActive,
  onSave,
  withConfirm
}) => {
  const [showPopup, confirmNavigation, cancelNavigation] =
    useShowPopup(isActive)

  const discardChangesPopup = usePopup()
  const confirmChangesPopup = usePopup()

  const resetFields = () => {
    if (isActive) {
      discardChangesPopup.actions.open()
    } else {
      submitCancellation()
    }
  }

  const submitCancellation = () => {
    discardChangesPopup.actions.close()
    onCancel()
  }

  const onSaveChanges = () => {
    if (isActive && withConfirm) {
      confirmChangesPopup.actions.open()
    } else if (!withConfirm && onSave) {
      onSave()
    }
  }

  const onSaveAction = async () => {
    onSave()
    confirmChangesPopup.actions.close()
  }

  const cancelDiscardChanges = () => discardChangesPopup.actions.close()

  return (
    <>
      {isActive && (
        <div className={cn('float-footer row justify-end gap-8', className)}>
          <Button onClick={resetFields} upperCase>
            {BTN_TXT.CANCEL}
          </Button>
          <Button type={BUTTON_TYPES.PRIMARY} onClick={onSaveChanges} upperCase>
            {BTN_TXT.SAVE}
          </Button>
        </div>
      )}
      <Popup.Discard
        visible={showPopup ? showPopup : discardChangesPopup.state.visible}
        onCancel={showPopup ? cancelNavigation : cancelDiscardChanges}
        onSubmit={showPopup ? confirmNavigation : submitCancellation}
      />
      {withConfirm && (
        <Popup.ConfirmChanges
          visible={confirmChangesPopup.state.visible}
          onCancel={confirmChangesPopup.actions.close}
          onSubmit={onSaveAction}
        />
      )}
    </>
  )
}
