import {
  CellTemplate,
  Compatible,
  Uncertain,
  Cell,
  UncertainCompatible
} from '@silevis/reactgrid'
import { Input } from '../../../../components/Input'
import { PROPS_INPUT_NUMBER } from '../../../ContractDetails/RebateConditions/RebateConditionsForm/constants'
import { useEffect, useState } from 'react'

export interface NumberCellInterface extends Cell {
  type: 'number'
  value: number
  key?: string
  placeholder: string
  focusCallback?: () => void
  unFocusCallback?: () => void
  isError?: boolean
  max?: string
  parser?: (value: string | undefined) => string
}

export class CustomCellNumberTemplate
  implements CellTemplate<NumberCellInterface>
{
  getCompatibleCell(
    uncertainCell: Uncertain<NumberCellInterface>
  ): Compatible<NumberCellInterface> {
    const value = uncertainCell.value ? uncertainCell.value : NaN
    const placeholder = uncertainCell.placeholder
      ? uncertainCell.placeholder
      : ''
    return {
      ...uncertainCell,
      value,
      placeholder,
      text: !!value ? value.toString() : ''
    }
  }

  update(
    cell: Compatible<NumberCellInterface>,
    cellToMerge: UncertainCompatible<NumberCellInterface>
  ): Compatible<NumberCellInterface> {
    return this.getCompatibleCell({ ...cell, value: cellToMerge.value })
  }

  render(
    cell: Compatible<NumberCellInterface>,
    _isInEditMode: boolean,
    onCellChanged: (
      cell: Compatible<NumberCellInterface>,
      commit: boolean
    ) => void
  ) {
    return (
      <InputPicker
        onChangedVal={(val) =>
          onCellChanged(
            this.getCompatibleCell({
              ...cell,
              value: val
            }),
            true
          )
        }
        {...cell}
      />
    )
  }

  isFocusable() {
    return true
  }
}

const InputPicker = ({
  isError,
  value,
  placeholder,
  focusCallback,
  unFocusCallback,
  onChangedVal,
  max,
  parser
}: Compatible<NumberCellInterface> & {
  onChangedVal: (val: number) => void
}) => {
  const [numValue, setValue] = useState<number>(0)

  useEffect(() => {
    setValue(value)
  }, [value])
  return (
    <Input.NumberNoForm
      hasCustomError={isError}
      propsInputNumber={{
        value: numValue,
        ...PROPS_INPUT_NUMBER,
        placeholder: placeholder,
        max,
        parser: parser ? parser : PROPS_INPUT_NUMBER['parser'],
        maxLength: 64,
        onCopy: (e) => e.stopPropagation(),
        onCut: (e) => e.stopPropagation(),
        onPointerDown: (e) => e.stopPropagation(),
        onPaste: (e) => e.preventDefault(),
        onChange: (e) => setValue(Number(e)),
        onFocus: () => focusCallback?.(),
        onBlur: () => {
          onChangedVal(numValue)
          unFocusCallback?.()
        }
      }}
    />
  )
}
