import {
  API_DICTIONARY,
  HTTP_METHODS,
  ResponseWithPagination,
  TOptionsWithNameResponse
} from '../../constants'
import Fetcher from '../../services/Fetcher'
import {
  ICheckIn,
  TCheckInsFilters,
  TOptionsUserResponse
} from '../../redux/store/checkIns/types'
import { TCheckInsVendorList, TCheckInVendorInfo } from './Providers/types'
const fetcher = new Fetcher({})

export type GetCheckInsListRequestArgs = TCheckInsFilters & {
  limit?: number
  offset?: number
  search?: string
}

export const getCheckInsListRequestAsync = async (
  params: GetCheckInsListRequestArgs
) => {
  return fetcher.request<unknown, ResponseWithPagination<ICheckIn>>({
    url: `${API_DICTIONARY.COMMUNITIES}${API_DICTIONARY.CHECK_INS}`,
    method: HTTP_METHODS.GET,
    params
  })
}

export const getCheckInsFilterOptionsHospitals = async () =>
  fetcher.request<unknown, ResponseWithPagination<TOptionsWithNameResponse>>({
    url: `${API_DICTIONARY.VENDORS}${API_DICTIONARY.CHECK_INS}${API_DICTIONARY.OPTIONS}${API_DICTIONARY.HOSPITAL}`,
    method: HTTP_METHODS.GET
  })

export const getCheckInsFilterOptionsDepartments = async () =>
  fetcher.request<unknown, ResponseWithPagination<TOptionsWithNameResponse>>({
    url: `${API_DICTIONARY.VENDORS}${API_DICTIONARY.CHECK_INS}${API_DICTIONARY.OPTIONS}${API_DICTIONARY.DEPARTMENT}`,
    method: HTTP_METHODS.GET
  })

export const getCheckInsFilterOptionsVisitorName = async () =>
  fetcher.request<unknown, ResponseWithPagination<TOptionsUserResponse>>({
    url: `${API_DICTIONARY.VENDORS}${API_DICTIONARY.CHECK_INS}${API_DICTIONARY.OPTIONS}${API_DICTIONARY.VISITOR}`,
    method: HTTP_METHODS.GET
  })

export const getCheckInsFilterOptionsHealthSystems = async () =>
  fetcher.request<unknown, ResponseWithPagination<TOptionsWithNameResponse>>({
    url: `${API_DICTIONARY.VENDORS}${API_DICTIONARY.CHECK_INS}${API_DICTIONARY.OPTIONS}${API_DICTIONARY.HEALTH_SYSTEM}`,
    method: HTTP_METHODS.GET
  })

export const getCheckInsFilterOptionsVendorOrgName = async () =>
  fetcher.request<unknown, ResponseWithPagination<TOptionsWithNameResponse>>({
    url: `${API_DICTIONARY.VENDORS}${API_DICTIONARY.CHECK_INS}${API_DICTIONARY.OPTIONS}${API_DICTIONARY.VENDOR}`,
    method: HTTP_METHODS.GET
  })

export const getCheckInsDetailsRequestAsync = async ({ id, limit, offset }) =>
  fetcher.request<unknown, ResponseWithPagination<TCheckInsVendorList>>({
    url: `${API_DICTIONARY.VENDORS}${API_DICTIONARY.CHECK_INS}`,
    method: HTTP_METHODS.GET,
    params: { visitors: id, limit, offset }
  })

export const getCheckInsVendorInfoRequestAsync = async (id: string) =>
  fetcher.request<unknown, TCheckInVendorInfo>({
    url: `${API_DICTIONARY.VENDORS}${API_DICTIONARY.CHECK_INS}${API_DICTIONARY.OPTIONS}${API_DICTIONARY.VISITOR}${id}/`,
    method: HTTP_METHODS.GET
  })
