import { SetStateAction, useCallback, useMemo, useState, Dispatch } from 'react'

import { ModalProps } from 'components/Popup/types'

const INITIAL_POPUP_PROPS: Partial<ModalProps> = {
  visible: false,
  title: ''
}

const usePopup = <TPayload extends object = {}>(
  props: Partial<ModalProps> = INITIAL_POPUP_PROPS
) => {
  const [_state, _setState] = useState<ModalProps & { payload: TPayload }>({
    payload: {} as TPayload,
    ...props
  })

  const close = useCallback(() => {
    _setState((prev) => ({ ...prev, visible: false }))
  }, [])

  const open = useCallback((payload: TPayload = {} as TPayload) => {
    _setState((prev) => ({ ...prev, visible: true, payload }))
  }, [])

  const state = useMemo(
    () => ({
      visible: _state.visible,
      title: _state.title,
      width: _state.width,
      payload: _state.payload,
      onCancel: close
    }),
    [_state.visible, _state.title, _state.width, _state.payload, close]
  )
  const actions = useMemo(
    () => ({ close, open, setState: _setState }),
    [close, open]
  )

  return { state, actions }
}

export type PopupReturnType = ReturnType<typeof usePopup>
export type PopupReturnState = ReturnType<typeof usePopup>['state']
export type PopupReturnActions = ReturnType<typeof usePopup>['actions']
export type PopupReturnTypeWithPayload<TPayload extends object = {}> = {
  state: Omit<PopupReturnState, 'payload'> & { payload: TPayload }
  actions: Omit<PopupReturnActions, 'open' | 'setState'> & {
    open: (payload?: TPayload) => void
    setState: Dispatch<SetStateAction<ModalProps & { payload: TPayload }>>
  }
}

export default usePopup
