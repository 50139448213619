import { StatusIconProps } from './types'
import { ReactComponent as Maybe } from 'assets/svg/Maybe.svg'
import { ReactComponent as Accepted } from 'assets/svg/Accepted.svg'
import { ReactComponent as Declined } from 'assets/svg/Declined.svg'
import { RespondToTheMeeting } from '../../pages/Calendar/types'
import { VendorCheckInStatus } from '../../pages/CheckIns/types'

export const StatusIcon = ({ status, children }: StatusIconProps) => {
  if (!status) return <>{children}</>

  return (
    <div className="status-icon-wrapper">
      {children}
      <div className="status-icon">
        {status === RespondToTheMeeting.ACCEPT && <Accepted />}
        {status === RespondToTheMeeting.MAYBE && <Maybe />}
        {status === RespondToTheMeeting.DECLINE && <Declined />}
        {status === VendorCheckInStatus.ACTIVE && (
          <div className="status-icon-circle active" />
        )}
        {status === VendorCheckInStatus.INACTIVE && (
          <div className="status-icon-circle" />
        )}
      </div>
    </div>
  )
}
