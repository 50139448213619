import {
  FC,
  createContext,
  PropsWithChildren,
  useContext,
  useMemo
} from 'react'

import useDrawer, { DrawerReturnTypeWithPayload } from 'hooks/useDrawer'
import { SidebarTypeUnion } from 'features/ChatList/constants'

type ContextProps = {
  sidebarDrawer: DrawerReturnTypeWithPayload<{
    type: SidebarTypeUnion
  }>
}

const basePopup = (title: string) => ({
  title
})

const ChatListPopupContext = createContext<ContextProps>({
  sidebarDrawer: null!
})

const ChatListPopupProvider: FC<PropsWithChildren> = ({ children }) => {
  const sidebarDrawerProps = useDrawer<{
    type: SidebarTypeUnion
  }>(basePopup('Create chat'))

  const context = useMemo(
    () => ({
      sidebarDrawer: sidebarDrawerProps
    }),
    [sidebarDrawerProps]
  )

  return (
    <ChatListPopupContext.Provider value={context}>
      {children}
    </ChatListPopupContext.Provider>
  )
}

export const useChatListPopup = () => useContext(ChatListPopupContext)

export default ChatListPopupProvider
