import Typography from '../../../components/Typography/Typography'
import { TCheckInsVendorList } from '../Providers/types'
import { TYPOGRAPHY_WEIGHT } from '../../../components/Typography'
import moment from 'moment'
import { hoursMinutesFormat } from '../../../utils/moment'
import { getDurationHm } from '../../../helper/dates'
import { timelineDateFormat } from '../../../components/Timelines/constants'
import { useSelector } from 'react-redux'
import { getUser } from '../../../redux/store/user/getters'

export const RowItem = ({ name, value }: { name: string; value: string }) => {
  return (
    <div className="check-in-row row gap-24">
      <Typography.Body2
        className="check-in-row__name"
        weight={TYPOGRAPHY_WEIGHT.SEMI_BOLD}
      >
        {name}
      </Typography.Body2>
      <Typography.Body2>{value}</Typography.Body2>
    </div>
  )
}

export const ActiveCheckIn = ({ item }: { item: TCheckInsVendorList }) => {
  const user = useSelector(getUser)

  const time = moment(item.created_at)
    .zone(user.timezone.offset)
    .format(hoursMinutesFormat)

  const formattedDate = `${moment(item.created_at)
    .zone(user.timezone.offset)
    .format(timelineDateFormat)} • ${time}`

  const duration = getDurationHm(moment().diff(item.created_at))
  return (
    <div className="check-ins-details-container__active-check-in column mt-24">
      <Typography.Headline6>Active check-in</Typography.Headline6>
      <div className="check-ins-details-container__active-check-in__separator" />
      <RowItem value={formattedDate} name="Start date and time" />
      <RowItem value={item.visit_type} name="Visit type" />
      <RowItem value={duration} name="Duration" />
      <RowItem value={item.health_system} name="Health System" />
      <RowItem value={item.hospital} name="Hospital" />
      <RowItem value={item.department} name="Department" />
      <RowItem
        value={item.destination_person_full_name}
        name="Destination person"
      />
    </div>
  )
}
