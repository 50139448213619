import {
  FC,
  createContext,
  PropsWithChildren,
  useContext,
  useMemo
} from 'react'

import usePopup, { PopupReturnType } from 'hooks/usePopup'

type ContextProps = {
  addEventPopup: PopupReturnType
  deleteEventPopup: PopupReturnType
  notifyUsersPopup: PopupReturnType
}

const basePopup = (title: string) => ({
  title
})

const CalendarPopupContext = createContext<ContextProps>({
  addEventPopup: null!,
  deleteEventPopup: null!,
  notifyUsersPopup: null!
})

const CalendarPopupProvider: FC<PropsWithChildren> = ({ children }) => {
  const addEventPopupProps = usePopup(basePopup(' event'))
  const deleteEventPopupProps = usePopup(basePopup(''))
  const notifyUsersPopupProps = usePopup(
    basePopup('Do you want to notify guests?')
  )

  const context = useMemo(
    () => ({
      addEventPopup: addEventPopupProps,
      deleteEventPopup: deleteEventPopupProps,
      notifyUsersPopup: notifyUsersPopupProps
    }),
    [addEventPopupProps, deleteEventPopupProps, notifyUsersPopupProps]
  )

  return (
    <CalendarPopupContext.Provider value={context}>
      {children}
    </CalendarPopupContext.Provider>
  )
}

export const useCalendarPopup = () => useContext(CalendarPopupContext)

export default CalendarPopupProvider
