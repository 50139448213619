import { Form, Select as SelectAntd } from 'antd'
import cn from 'classnames'
import { DebounceSelectProps, TOption } from './types'
import { ReactComponent as ChevronDown16 } from 'assets/svg/ChevronDown16.svg'
import { useDispatch } from 'react-redux'
import { useEffect, useState } from 'react'
import { useDebounce } from 'hooks/hooks'
import { setLoading } from 'redux/store/common/slice'
import { ReactComponent as SelectClearIcon } from 'assets/svg/SelectClear.svg'
import { ReactComponent as Close16 } from 'assets/svg/Clos16.svg'
const { Option } = SelectAntd

const DebounceMulti = <T extends object>({
  ...props
}: DebounceSelectProps<T>): JSX.Element => {
  const [options, setOptions] = useState<T[] | TOption[] | undefined>()
  const [searchValue, setSearchValue] = useState<string | null>(null)
  const debouncedSearch = useDebounce(searchValue, 500)

  const dispatch = useDispatch()

  useEffect(() => {
    if (debouncedSearch?.length) handleFetchOptions()
    else setOptions([])
  }, [debouncedSearch])

  const handleFetchOptions = () => {
    dispatch(setLoading(true))
    props
      .onSearch(debouncedSearch)
      .then((resp) => {
        if (!resp.data?.results) {
          return
        }
        setOptions(resp.data.results)
      })
      .finally(() => dispatch(setLoading(false)))
  }

  return (
    <Form.Item
      className={cn('custom-select', 'select-multi', props.className)}
      colon={false}
      name={options?.length ? props.propsItem?.name : ''}
      {...props.propsItem}
    >
      <SelectAntd
        {...props.propsSelect}
        suffixIcon={<ChevronDown16 />}
        clearIcon={<SelectClearIcon />}
        removeIcon={<Close16 />}
        getPopupContainer={(node) => node}
        filterOption={false}
        showArrow
        showSearch
        labelInValue
        onSearch={setSearchValue}
        onChange={props.onChange}
        placement="bottomLeft"
      >
        {options?.map((option) => {
          const value = props.valueKey ? option[props.valueKey] : option.value
          return (
            <Option value={value} key={value}>
              {props.optionLabel ? props.optionLabel(option) : option.label}
            </Option>
          )
        })}
      </SelectAntd>
    </Form.Item>
  )
}

export default DebounceMulti
