import { useRFPDetailsPopupContext } from 'features/RFP/RFPDetails/Providers/RFPDetailsPopupProvider'
import { Popup } from 'components/Popup'
import { Button, BUTTON_TYPES } from 'components/Button'
import { BTN_TXT } from 'constants/txt'
import './style.scss'
import RFPVendorSelection from 'features/RFP/RFPVendorSelection/RFPVendorSelection'

export default () => {
  const { awardVendorsPopup } = useRFPDetailsPopupContext()

  const { title, visible, onCancel } = awardVendorsPopup.state
  const { close } = awardVendorsPopup.actions

  return (
    <Popup
      visible={visible}
      onCancel={onCancel}
      className="rfp-vendor-selection-popup"
      title={title}
      width={910}
      footer={
        <>
          <Button type={BUTTON_TYPES.DEFAULT} onClick={close} upperCase>
            {BTN_TXT.CANCEL}
          </Button>
          <Button
            type={BUTTON_TYPES.PRIMARY}
            htmlType="submit"
            form="rfp-vendor_selection-form-id"
            upperCase
          >
            {BTN_TXT.AWARD_VENDORS_AND_ARCHIVE_RFP}
          </Button>
        </>
      }
    >
      <RFPVendorSelection />
    </Popup>
  )
}
