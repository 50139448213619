import { FC, useCallback } from 'react'
import { Form } from 'antd'

import './styles.scss'

import Timelines from 'components/Timelines/Timelines'
import useTimelines from 'components/Timelines/hooks/useTimelines'
import {
  getTimelinesBySettings,
  getTimeOptionsStartFrom
} from 'components/Timelines/utils'
import { Props, TTimelinesStatues } from './types'
import { systemPrsTimelinesSettings } from './constants'
import { PRS_STATUSES_TIMERS } from '../../../pages/ProductRequests/constants'
import Typography from '../../../components/Typography/Typography'
import { usePRSDetailsContext } from '../PRSDetails/Providers/PRSDetailsContextProvider'

const systemTimelines = getTimelinesBySettings(systemPrsTimelinesSettings)

const timeOptions = getTimeOptionsStartFrom('00:00 AM')

const PrsDetailsTimelines: FC<Props> = ({ onFinish, ...useTimelinesProps }) => {
  const { data } = usePRSDetailsContext().state
  const { state, actions } = useTimelines<typeof PRS_STATUSES_TIMERS>({
    ...useTimelinesProps,
    systemTimelines,
    isPrs: true,
    data
  })

  const { getHasValidationError, getDisabledByKey } = actions

  const handleFormSubmit = useCallback(() => {
    if (!getHasValidationError()) {
      const onlyAvailableTimelines = state.filter(
        (timeline) => !getDisabledByKey(timeline.key)
      )

      onFinish(onlyAvailableTimelines)
    }
  }, [getDisabledByKey, getHasValidationError, onFinish, state])

  return (
    <Form
      id="prs-timeline-form-id"
      className="prs-timeline-form"
      onFinish={handleFormSubmit}
    >
      <Timelines<TTimelinesStatues>
        timelines={state}
        timeOptions={timeOptions}
        isPrs
        {...actions}
      >
        <Typography.Body2 className="prs-steps-modal-text">
          Steps below are included into the overall time. Some of them might be
          optional and not affect the overall timer.
        </Typography.Body2>
      </Timelines>
    </Form>
  )
}

export default PrsDetailsTimelines
