import ChatListAbilityProvider from 'features/ChatList/Providers/ChatListAbilityProvider'
import ChatListContextProvider from './Providers/ChatListContextProvider'
import ChatListPopupProvider from './Providers/ChatListPopupProvider'

import Header from 'features/ChatList/Header/Header'
import List from 'features/ChatList/List/List'
import Sidebar from 'features/ChatList/Sidebar/Sidebar'

const ChatList = () => (
  <ChatListAbilityProvider>
    <ChatListContextProvider>
      <ChatListPopupProvider>
        <Header />
        <List />
        <Sidebar />
      </ChatListPopupProvider>
    </ChatListContextProvider>
  </ChatListAbilityProvider>
)

export default ChatList
