import ContractDetailsContainer from 'features/ContractDetails/ContractDetailsContainer/ContractDetailsContainer'
import ContractDetailsRouter from 'features/ContractDetails/ContractDetailsRouter/ContractDetailsRouter'
import { TerminateContractPopup } from 'features/ContractDetails/ContractDetailsActions/TerminateContract'
import { ExtendContractPopup } from 'features/ContractDetails/ContractDetailsActions/ExtendContract'
import RenewFinishDatePopup from 'features/ContractDetails/ExtendContract/RenewFinishDatePopup'
import ContractDetailsTimelinesPopup from 'features/ContractDetails/ContractDetailsTimelines/ContractDetailsTimelinesPopup'
import AssignResponsiblePopup from 'features/ContractDetails/ContractDetailsGeneral/ContractDetailsForm/AssignResponsible/Popup'
import ContractDetailsAbilityProvider from './Providers/ContractDetailsAbilityProvider'
import ContractDetailsPopupProvider from './Providers/ContractDetailsPopupProvider'
import ContractDetailsContextProvider from './Providers/ContractDetailsContextProvider'
import { DeclineContractPopup } from './ContractDetailsActions/DeclineContract'

const ContractDetails = () => (
  <ContractDetailsAbilityProvider>
    <ContractDetailsPopupProvider>
      <ContractDetailsContextProvider>
        <ContractDetailsContainer>
          <ContractDetailsRouter />
        </ContractDetailsContainer>
        <TerminateContractPopup />
        <DeclineContractPopup />
        <ExtendContractPopup />
        <RenewFinishDatePopup />
        <ContractDetailsTimelinesPopup />
        <AssignResponsiblePopup />
      </ContractDetailsContextProvider>
    </ContractDetailsPopupProvider>
  </ContractDetailsAbilityProvider>
)

export default ContractDetails
