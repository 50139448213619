import Typography from '../../../../components/Typography/Typography'
import { TNonComplianceProducts } from '../../../../pages/Compliance/types'
import { currencyFormatter } from '../../../ContractDetails/ContractDetailsGeneral/ContractDetailsForm/utils'
type TProps = {
  item: TNonComplianceProducts
}
const RowItem = ({ item }: TProps) => {
  return (
    <div
      className="row-item-container row align-center full-width"
      data-testid="nonCompliantProductRow"
    >
      <Typography.Caption className="row-item-container__item">
        {item.vendor_name}
      </Typography.Caption>
      <Typography.Caption className="row-item-container__item">
        {item.product_name}
      </Typography.Caption>
      <Typography.Caption className="row-item-container__item">
        ${currencyFormatter(Number(item.spend_amount)?.toFixed(2))}
      </Typography.Caption>
    </div>
  )
}

export default RowItem
