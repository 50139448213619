import { Select } from 'components/Select'
import { LABELS, PLACEHOLDERS } from 'constants/txt'
import { returnSelectOptions } from 'helper/common'
import { useRFPCreateSurveyContext } from 'features/RFP/RFPSurvey/CreateSurvey/Providers/RFPCreateSurveyContextProvider'
import { useRFPSurveyContext } from 'features/RFP/RFPDetails/Providers/RFPSurveyContextProvider'
import { useRFPDetailsContext } from 'features/RFP/RFPDetails/Providers/RFPDetailsContextProvider'
import { RFP_STATUSES } from 'features/RFP/constants'

const VendorsSelection = () => {
  const rfpDetailsContext = useRFPDetailsContext()
  const rfpSurveyContext = useRFPSurveyContext()
  const createSurveyContext = useRFPCreateSurveyContext()

  const { data } = rfpDetailsContext.state
  const { vendors } = rfpSurveyContext.state
  const { localSelectedVendorValues } = createSurveyContext.state
  const { handleLocalVendorsChange } = createSurveyContext.actions

  return (
    <div className="rfp-create-survey-vendor-selection">
      <Select.Multi
        options={returnSelectOptions(vendors)}
        propsItem={{
          label: LABELS.SELECT_VENDORS_FOR_SURVEY
        }}
        propsSelect={{
          placeholder: PLACEHOLDERS.PLEASE_SELECT,
          onChange: handleLocalVendorsChange,
          value: localSelectedVendorValues,
          disabled: data.status !== RFP_STATUSES.PRICE_FILES_ANALYSIS
        }}
      />
    </div>
  )
}

export default VendorsSelection
