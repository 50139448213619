import {
  Typography,
  TYPOGRAPHY_WEIGHT
} from '../../../../components/Typography'
import { Avatar } from '../../../../components/Avatar/Avatar'
import { getUploadFile, getUploader } from './utils'
import { defaultDateFormat, defaultTimeFormat } from '../../../../helper/dates'
import { HistoryCardProps } from './types'
import './styles.scss'

export const HistoryCard = ({ data }: HistoryCardProps) => {
  const capitalCharName = `${data.updated_by.first_name?.charAt(0) || ''}${
    data.updated_by.last_name?.charAt(0) || ''
  }`.toUpperCase()

  return (
    <div className="history-card row gap-8">
      <Avatar
        name={capitalCharName}
        alt="User avatar"
        src={data.updated_by.avatar?.file_preview}
      />
      <div className="history-card__content column gap-8">
        <Typography.Body1 weight={TYPOGRAPHY_WEIGHT.BOLD}>
          {getUploader(data.updated_by)} has updated {data.document_key} invoice{' '}
          {getUploadFile(data.updated_via_file)}
        </Typography.Body1>
        <div className="history-card__date row gap-16">
          <Typography.Caption>
            {defaultDateFormat(data.updated_at)}
          </Typography.Caption>
          <Typography.Caption>
            {defaultTimeFormat(data.updated_at)}
          </Typography.Caption>
        </div>
      </div>
    </div>
  )
}
