import cn from 'classnames'
import { Menu as MenuAntd } from 'antd'
import { TMenuProps } from './types'
import './styles.scss'

export const Menu = ({ className, ...props }: TMenuProps) => (
  <MenuAntd
    mode="horizontal"
    className={cn('custom-menu', className)}
    inlineIndent={0}
    disabledOverflow={true}
    {...props}
  />
)
