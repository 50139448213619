import {
  Typography,
  TYPOGRAPHY_WEIGHT
} from '../../../../components/Typography'
import { Chart } from './Chart'
import { FinancialsChartLegendPart } from '../../../../pages/Financials/FinancialsChartLegendPart'
import { Tooltip } from '../../../../components/Tooltip/Tooltip'
import { ReactComponent as GreenFile } from 'assets/svg/GreenFile.svg'
import { useComplianceContext } from '../../../../pages/Compliance/Providers/ComplianceContextProvider'
import {
  SPEND_BY_VENDOR_COLORS,
  INITIAL_DATA_SPEND_BY_VENDOR_LEGEND
} from './constants'

const SpendByVendor = () => {
  const { spendByVendor } = useComplianceContext().state
  return (
    <div
      className="compliance-container__chart-wrapper part charts_ongoing-contracts_container"
      data-testid="spendByVendorContainer"
    >
      <Typography.Body1
        className="compliance-container__chart-wrapper__title"
        weight={TYPOGRAPHY_WEIGHT.BOLD}
      >
        Spend by Vendor
      </Typography.Body1>
      <div className="charts__description align-center">
        <div className="round-chart__reports">
          <Chart
            data={
              !!spendByVendor.length
                ? spendByVendor
                : INITIAL_DATA_SPEND_BY_VENDOR_LEGEND
            }
          />
        </div>
        {!!spendByVendor?.length ? (
          <div className="chart__legend">
            {spendByVendor?.map((i, idx) => {
              return (
                <div className="legend__part" key={idx}>
                  <FinancialsChartLegendPart
                    color={SPEND_BY_VENDOR_COLORS[idx]}
                    name={i.name}
                    price={i.amount}
                  >
                    {i.on_contract ? (
                      <Tooltip
                        title={<div>On-contract</div>}
                        placement="topLeft"
                      >
                        <div className="on-contract-icon">
                          <GreenFile />
                        </div>
                      </Tooltip>
                    ) : (
                      <div />
                    )}
                  </FinancialsChartLegendPart>
                </div>
              )
            })}
          </div>
        ) : (
          <div className="full-width full-height row align-center empty-state">
            <Typography.Body1>The list is empty</Typography.Body1>
          </div>
        )}
      </div>
    </div>
  )
}

export default SpendByVendor
