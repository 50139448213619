import { State } from '../../type'
import { moduleName } from './constants'
import { NotificationsState } from './types'

const getState = (state: State): NotificationsState => state[moduleName]

export const getIsModalOpen = (state: State) => {
  const stateSlice = getState(state)
  return stateSlice.isModalOpen
}

export const getUnreadNotificationsAmount = (state: State) => {
  const stateSlice = getState(state)
  return stateSlice.unreadNotifications
}

export const getToken = (state: State) => {
  const stateSlice = getState(state)
  return stateSlice.token
}
