import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Forms } from 'components/Forms'
import { getUser } from 'redux/store/user/getters'
import { fetchVendorInfo, updateVendorInfo } from 'redux/store/vendorInfo/slice'

export const VendorInfo = () => {
  const user = useSelector(getUser)
  const dispatch = useDispatch()
  useEffect(() => {
    if (user?.vendor) {
      dispatch(fetchVendorInfo(user?.vendor))
    }
  }, [])
  const onSubmit = (data) => {
    if (user?.vendor) {
      dispatch(updateVendorInfo(data, user?.vendor))
    }
  }
  return <Forms.VendorInfo onSubmit={onSubmit} />
}
