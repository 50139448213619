import Fetcher from 'services/Fetcher'
import { API_DICTIONARY, HTTP_METHODS } from 'constants/api'
import { ResponseWithPagination } from '../../constants'
import { IChatHistory } from '../../redux/store/chatHistory/types'
import { TRFPDocument, TRFPUploadFilesRequest } from '../RFP/RFPDetails/types'

const fetcher = new Fetcher({})

export type SendMessageRequestData = {
  body?: string
  reply?: string
  attachments?: string[]
}

export type getMessagesArguments = {
  limit?: number
  offset?: number
  query?: string
}

export const sendMessageRequest = async (
  id: string,
  data: SendMessageRequestData
) =>
  fetcher.request<unknown, IChatHistory>({
    url: `${API_DICTIONARY.CHATS}${id}/${API_DICTIONARY.MESSAGES}`,
    method: HTTP_METHODS.POST,
    data
  })

export const getChatHistoryRequest = async (
  id: string,
  args: getMessagesArguments
) =>
  fetcher.request<unknown, ResponseWithPagination<IChatHistory>>({
    url: `${API_DICTIONARY.CHATS}${id}/${API_DICTIONARY.MESSAGES}`,
    method: HTTP_METHODS.GET,
    params: args
  })

export const uploadChatAttachments: TRFPUploadFilesRequest = async (
  data,
  callback
) =>
  fetcher.request<unknown, TRFPDocument>({
    url: `${API_DICTIONARY.FILES}${API_DICTIONARY.CHAT_ATTACHMENTS}`,
    method: HTTP_METHODS.POST,
    headers: {
      'Content-Type': 'multipart/form-data'
    },
    data,
    onUploadProgress: (progressEvent) => {
      const { loaded, total } = progressEvent
      const percentage = Math.floor((loaded * 100) / total)
      !!callback && callback(percentage)
    }
  })
