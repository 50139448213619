import { BTN_TXT } from 'constants/txt'
import { PRS_STATUSES_VALUES } from '../../../../pages/ProductRequests/constants'

export const STATUS_SCHEME = {
  [PRS_STATUSES_VALUES.created]: {
    next: PRS_STATUSES_VALUES.waiting_for_review,
    actionBtnText: BTN_TXT.SEND_PRS
  },
  [PRS_STATUSES_VALUES.declined]: {
    next: PRS_STATUSES_VALUES.waiting_for_review,
    actionBtnText: BTN_TXT.SEND_PRS
  }
  // [PRS_STATUSES_VALUES.waiting_for_review]: {
  //   next: PRS_STATUSES_VALUES.in_review,
  //   actionBtnText: BTN_TXT.SEND_PRS
  // }
}

export const REVENUE_OPTIONS = [
  {
    label: 'Send for revenue assessment',
    value: 'revenue_assessment',
    disable: false,
    hide: false
  },
  {
    label: 'Send trial request to Vendor',
    value: 'trial_requested',
    disable: false
  },
  {
    label: 'Create survey for stakeholders to vote',
    value: 'survey_creation',
    disable: false,
    hide: false
  }
]
