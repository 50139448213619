import {
  FC,
  createContext,
  PropsWithChildren,
  useContext,
  useMemo
} from 'react'

import usePopup, { PopupReturnType } from 'hooks/usePopup'

type ContextProps = {
  filtersPopup: PopupReturnType
}

const basePopup = (title: string) => ({
  title
})

const CheckInsPopupContext = createContext<ContextProps>({
  filtersPopup: null!
})

const CheckInsPopupProvider: FC<PropsWithChildren> = ({ children }) => {
  const filtersPopupProps = usePopup(basePopup('Filter'))

  const context = useMemo(
    () => ({
      filtersPopup: filtersPopupProps
    }),
    [filtersPopupProps]
  )

  return (
    <CheckInsPopupContext.Provider value={context}>
      {children}
    </CheckInsPopupContext.Provider>
  )
}

export const useCheckInsPopup = () => useContext(CheckInsPopupContext)

export default CheckInsPopupProvider
