import cn from 'classnames'
import { InputNumber } from 'antd'
import { Props } from './types'
import './input.default.scss'
import { KeyboardEvent } from 'react'

export const NumberInputNoForm = ({ ...props }: Props): JSX.Element => {
  const onKeyDown = (e: KeyboardEvent<HTMLInputElement>) => {
    // avoid of propagation of backspace out from picker
    // works only with onPaste flag enabled
    if (props.propsInputNumber?.onPaste && e.key === 'Backspace') {
      e.stopPropagation()
    }
    if (
      !/^(?:\d|Backspace|[.])$/.test(e.key) &&
      // support pasting into input when rewriting onPaste property
      // expect that onPaste will avoid pasting text in InputNumber by itself
      !(
        e.key === 'v' &&
        (e.metaKey || e.ctrlKey) &&
        props.propsInputNumber?.onPaste
      )
    ) {
      e.preventDefault()
    }
  }

  return (
    <div
      className={cn('custom-input', 'input-default', props.className, {
        'custom-input-error': props.hasCustomError
      })}
    >
      <InputNumber
        maxLength={10}
        value={props.propsInputNumber?.value || ''}
        {...props.propsInputNumber}
        onKeyDown={onKeyDown}
      />
    </div>
  )
}
