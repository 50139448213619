import { useCallback, useEffect, useMemo, useState } from 'react'
import { Form } from 'antd'

import { useContractDetailsContext } from 'features/ContractDetails/Providers/ContractDetailsContextProvider'
import { IRebateConditionsForm } from 'features/ContractDetails/RebateConditions/RebateConditionsForm/types'
import {
  convertDetailsToForm,
  convertFormToRequestData,
  growthRebateIntersectionValidator
} from 'features/ContractDetails/RebateConditions/RebateConditionsForm/utils'
import {
  REBATE_CONDITIONS_TYPE,
  INITIAL_REBATE_CONDITIONS
} from 'features/ContractDetails/RebateConditions/RebateConditionsForm/constants'
import { notification } from 'components/Notification'

const useRebateConditionsForm = () => {
  const contractDetailsContext = useContractDetailsContext()
  const [isChanged, setIsChanged] = useState(false)

  const { details } = contractDetailsContext.state
  const { updateContractRebatesAsync } = contractDetailsContext.actions

  const [form] = Form.useForm<IRebateConditionsForm>()

  const type = Form.useWatch('rebate_type', form)
  const rebates = Form.useWatch('rebates', form)

  const [hasFormChanges, _setHasFormChanges] = useState(false)
  const [hasValidationError, _setHasValidationError] = useState(false)

  const canRemoveCondition = useMemo(
    () => rebates?.length > 1,
    [rebates?.length]
  )

  const triggerHasFormChanges = useCallback(() => {
    if (!hasFormChanges) {
      _setHasFormChanges(true)
    }
    if (hasValidationError) {
      _setHasValidationError(false)
    }
  }, [hasFormChanges, hasValidationError])

  const hasValidationErrors = useCallback(
    async (values: IRebateConditionsForm) => {
      try {
        if (type === REBATE_CONDITIONS_TYPE.GROWTH_REBATE) {
          await growthRebateIntersectionValidator(values.rebates)
        }
        return false
      } catch (error) {
        if (error instanceof Error) {
          notification.error(error)
        }

        _setHasValidationError(true)

        return true
      }
    },
    [type]
  )

  const handleFinishForm = useCallback(
    async (values: IRebateConditionsForm) => {
      setIsChanged(false)
      if (await hasValidationErrors(values)) {
        return
      }

      const successCallback = () => {
        _setHasFormChanges(false)
      }

      const requestData = convertFormToRequestData(values)

      await updateContractRebatesAsync(requestData, successCallback)
    },
    [hasValidationErrors, updateContractRebatesAsync]
  )

  const handleCancelForm = useCallback(() => {
    form.setFieldsValue(convertDetailsToForm(details))

    _setHasFormChanges(false)
  }, [details, form])

  const handleChangeForm = useCallback(() => {
    triggerHasFormChanges()
  }, [triggerHasFormChanges])

  useEffect(() => {
    if (details.rebate_type === type) {
      form.setFieldsValue(convertDetailsToForm(details))

      return
    }

    form.setFieldsValue({
      rebates: [INITIAL_REBATE_CONDITIONS[type]]
    })
  }, [details, form, type])

  useEffect(() => {
    form.setFieldsValue(convertDetailsToForm(details))
  }, [details, form])

  return {
    form,
    type,
    isChanged,
    rebates,
    hasFormChanges,
    hasValidationError,
    canRemoveCondition,
    handleFinishForm,
    handleCancelForm,
    handleChangeForm,
    setIsChanged
  }
}

export type UseRebateConditionsFormReturnType = ReturnType<
  typeof useRebateConditionsForm
>

export default useRebateConditionsForm
