import { BTN_TXT, LABELS, PLACEHOLDERS } from 'constants/txt'
import { Button, BUTTON_TYPES } from 'components/Button'
import { forgotPasswordInitialValues } from '../../../../components/Forms/constants'
import { Input } from '../../../../components/Input'
import { Form } from 'antd'
import { IUncategorizedInvoice, TEditableFields } from '../types'
import { Select } from '../../../../components/Select'
import { currencyParserInteger } from '../../../ContractDetails/ContractDetailsGeneral/ContractDetailsForm/utils'
import { TOption } from '../../../../components/Select/types'

interface IProps {
  onCancel: () => void
  onSubmit: (
    values: Pick<Partial<IUncategorizedInvoice>, TEditableFields>
  ) => void
  contractCategories: TOption[]
}

const BulkEditForm = ({ contractCategories, onSubmit, onCancel }: IProps) => {
  const [form] = Form.useForm()

  const category = Form.useWatch('contract_category', form)
  const code = Form.useWatch('unspsc_code', form)
  return (
    <Form<Pick<Partial<IUncategorizedInvoice>, TEditableFields>>
      layout="vertical"
      onFinish={onSubmit}
      initialValues={forgotPasswordInitialValues}
      form={form}
    >
      <div className="row">
        <Select
          options={contractCategories}
          propsItem={{
            label: LABELS.CATEGORY,
            name: 'contract_category'
          }}
          propsSelect={{
            getPopupContainer: (node) => document?.body ?? node,
            placeholder: PLACEHOLDERS.KEEP_CURRENT_VALUE
          }}
        />
      </div>
      <div className="row">
        <Input.Number
          propsItem={{
            name: 'unspsc_code',
            label: LABELS.UNSPSC_CODE
          }}
          propsInputNumber={{
            min: '0',
            controls: false,
            placeholder: PLACEHOLDERS.KEEP_CURRENT_VALUE,
            parser: currencyParserInteger
          }}
        />
      </div>
      <div className="row mt-32 flex row justify-end">
        <Button onClick={onCancel} type={BUTTON_TYPES.DEFAULT} upperCase>
          {BTN_TXT.CANCEL}
        </Button>
        <div className="ml-16">
          <Button
            type={BUTTON_TYPES.PRIMARY}
            htmlType="submit"
            upperCase
            disabled={!category && !code}
          >
            {BTN_TXT.SAVE}
          </Button>
        </div>
      </div>
    </Form>
  )
}

export default BulkEditForm
