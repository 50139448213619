import Typography from 'components/Typography/Typography'
import { ReactComponent as WarningIcon } from 'assets/svg/WarningIcon.svg'

const AssignRfpBanner = () => {
  return (
    <div className={'rfp-resend-bid-reason row mt-24 gap-12 align-center info'}>
      <WarningIcon />
      <Typography.Body1 className="rfp-resend-bid-reason__content__text">
        Assign RFP to the Commons Contract Steward or Commons Vice President for
        further processing.
      </Typography.Body1>
    </div>
  )
}

export default AssignRfpBanner
