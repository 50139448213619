export const COGNUS_REPOSITORY_OPTIONS = [
  { label: 'Available for evaluation', value: 'availabl_for_evaluation' },
  { label: 'Opportunity analysis', value: 'opportunity_analysis' }
]
export const COGNUS_REPOSITORY_OPTIONS_EXTRA = [
  { label: 'Requests for analysis', value: 'requests_for_analysis' }
]

export const COGNUS_REPOSITORY_STATUS = {
  IN_REVIEW: 'in_review',
  ACCEPTED: 'accepted',
  TERMINATED: 'terminated'
} as const

export const COGNUS_REPOSITORY_STATUS_NAME = {
  [COGNUS_REPOSITORY_STATUS.IN_REVIEW]: 'In review',
  [COGNUS_REPOSITORY_STATUS.ACCEPTED]: 'Accepted',
  [COGNUS_REPOSITORY_STATUS.TERMINATED]: 'Terminated'
} as const
