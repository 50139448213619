import { Tooltip } from '../../../../components/Tooltip/Tooltip'
import { ReactComponent as ReplyIcon16 } from '../../../../assets/svg/Reply16.svg'
import { TOOLTIP } from '../../../../constants'

type TProps = {
  hadLeReplyToMessage: () => void
}

const ReplyToMessage = ({ hadLeReplyToMessage }: TProps) => {
  return (
    <div
      className="message-item-container__wrapper__reply"
      onClick={hadLeReplyToMessage}
    >
      <Tooltip
        id="message-tooltip-reply"
        title={TOOLTIP.REPLY_TO_MESSAGE}
        overlayInnerStyle={{
          display: 'block',
          width: 130
        }}
        placement="topRight"
      >
        <ReplyIcon16 />
      </Tooltip>
    </div>
  )
}

export default ReplyToMessage
