import { CalendarControl } from '../../components/Calendar'
import { CalendarHeader } from './CalendarHeader'
import './styles.scss'
import { useCalendarEventContext } from '../../pages/Calendar/CalendarEventsProvider'
import AddEventModal from './AddEventPopup/AddEventModal'
import { DeleteModal } from './CalendarMeetingDetailsPopover/DeleteContainer'
import { useCallback, useMemo, useState } from 'react'
import { EventContentArg } from 'fullcalendar'
import { CalendarCell } from './CalendarCell/CalendarCell'
import { EventOpenedId } from '../../components/Calendar/types'
import { useCalendarPopup } from 'pages/Calendar/Provider/CalendarPopupProvider'
import { CALENDAR_MODE_SWITCH_OPTIONS } from './CalendarHeader/constants'
import CalendarSchedule from './CalendarSchedule/CalendarSchedule'

export const Calendar = () => {
  const { state, actions } = useCalendarEventContext()

  const { deleteEventPopup, addEventPopup, notifyUsersPopup } =
    useCalendarPopup()

  const [openedPopupUniqueId, setOpenedPopupUniqueId] = useState<string>('')

  const renderEventContent = useCallback(
    (eventInfo: EventContentArg) => {
      return (
        <CalendarCell
          currentEventInfo={eventInfo}
          openedEventInfo={state.calendarEventDetails}
          setPopoverOpened={(id: EventOpenedId) => {
            actions.getEventDetails && actions.getEventDetails(id)
          }}
          isDeletePopoverOpened={deleteEventPopup.state.visible}
          isEditPopoverOpened={
            addEventPopup.state.visible || notifyUsersPopup.state.visible
          }
          openedPopupUniqueId={openedPopupUniqueId}
        />
      )
    },
    [
      actions.getEventDetails,
      deleteEventPopup,
      state.calendarEventDetails,
      openedPopupUniqueId,
      addEventPopup,
      notifyUsersPopup
    ]
  )

  const isHideActionsModals = useMemo(() => {
    return (
      !deleteEventPopup.state.visible &&
      !addEventPopup.state.visible &&
      !notifyUsersPopup.state.visible
    )
  }, [deleteEventPopup, notifyUsersPopup, addEventPopup])

  const onEventClick = (id: string | null, popupUniqueId: string) => {
    actions.setQueryEventId('')
    actions.getEventDetails(id)
    setOpenedPopupUniqueId(popupUniqueId)
  }

  return (
    <div className="calendar-wrapper column full-height full-width">
      <CalendarHeader />
      <div className="calendar-wrapper__content">
        {state.calendarView === CALENDAR_MODE_SWITCH_OPTIONS[0].value ? (
          <CalendarControl
            calendarRef={state.calendarRef}
            events={state.calendarEvents}
            onEventClick={onEventClick}
            renderEventContent={renderEventContent}
          />
        ) : (
          <CalendarSchedule
            events={state.calendarEvents}
            openedPopupUniqueId={openedPopupUniqueId}
            onEventClick={onEventClick}
            isHideActionsModals={isHideActionsModals}
          />
        )}
      </div>
      {(addEventPopup.state.visible || notifyUsersPopup.state.visible) && (
        <AddEventModal />
      )}
      <DeleteModal />
    </div>
  )
}
