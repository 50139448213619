import { Can } from '../Providers/PrsDetailsAbilityProvider'
import { ACTIONS, SUBJECTS } from '../../Abilities'
import { subject } from '@casl/ability'
import { Tooltip } from 'components/Tooltip/Tooltip'
import cn from 'classnames'
import { Button, BUTTON_TYPES } from 'components/Button'
import { BTN_TXT } from 'constants/txt'
import { usePRSDetailsContext } from '../Providers/PRSDetailsContextProvider'
import { TProps } from './SendSurvey'
import Popup from 'components/Popup/Popup'
import { Form } from 'antd'
import { Typography, TYPOGRAPHY_WEIGHT } from 'components/Typography'
import Input from 'components/Input/Input'
import { usePRSDetailsPopupContext } from '../Providers/PRSDetailsPopupProvider'
import { setLoading } from 'redux/store/common/slice'
import { updateTrial } from 'pages/ProductRequests/api'
import {
  PRS_STATUSES,
  PRS_STATUSES_MESSAGES
} from 'pages/ProductRequests/constants'
import { notification } from 'components/Notification'
import { useDispatch } from 'react-redux'
import { TRequestTrialValue } from '../types'
export const RequestTrial = ({ stakeHoldersLength }: TProps) => {
  const dispatch = useDispatch()
  const { state, actions } = usePRSDetailsContext()

  const [trialForm] = Form.useForm()
  const { data } = state
  const { handleGetPRSData } = actions
  const message_for_stakeholder = Form.useWatch(
    'message_for_stakeholder',
    trialForm
  )

  const { requestTrialPopup } = usePRSDetailsPopupContext()

  const onOpen = () => requestTrialPopup.actions.open()
  const onCancel = () => {
    requestTrialPopup.actions.close()
    if (trialForm) trialForm.resetFields()
  }

  const onSubmitTrial = (value: TRequestTrialValue) => {
    dispatch(setLoading(true))
    updateTrial(data.uuid, {
      status: PRS_STATUSES.TRIAL_PRE_APPROVAL,
      ...value
    })
      .then(() => {
        handleGetPRSData()
        notification.success({
          message: `${PRS_STATUSES_MESSAGES.trial_pre_approval}`
        })
        trialForm.resetFields()
        onCancel()
      })
      .catch((err) => {
        notification.error({
          message: err.data?.details[0]
        })
      })
      .finally(() => {
        dispatch(setLoading(false))
        onCancel()
      })
  }

  return !data?.trial ? (
    <Can I={ACTIONS.REQUEST} a={subject(SUBJECTS.TRIAL, { ...data })}>
      <Tooltip
        id="disable-request-trial-btn"
        title={'Add at least 1 Stakeholder as Facility user to proceed'}
        overlayInnerStyle={{
          display: !stakeHoldersLength ? 'block' : 'none'
        }}
        className="disable-request-trial-btn-container"
        placement="topRight"
        mouseLeaveDelay={0}
      >
        <span
          className={cn({
            'send-prs-to-clinical-sponsor-disabled': !stakeHoldersLength
          })}
        >
          <Button
            type={BUTTON_TYPES.DEFAULT}
            onClick={onOpen}
            disabled={!stakeHoldersLength}
            className="send-prs-to-clinical-sponsor-btn"
            upperCase
          >
            {BTN_TXT.REQUEST_TRIAL}
          </Button>
        </span>
      </Tooltip>
      <Popup
        visible={requestTrialPopup.state.visible}
        onCancel={onCancel}
        title="Request trial period?"
        width={442}
        footer={
          <>
            <Button onClick={onCancel} type={BUTTON_TYPES.DEFAULT} upperCase>
              {BTN_TXT.CANCEL}
            </Button>
            <Button
              form="request-trial-modal"
              disabled={!message_for_stakeholder?.trim()}
              htmlType="submit"
              type={BUTTON_TYPES.PRIMARY}
              upperCase
            >
              {BTN_TXT.REQUEST_TRIAL}
            </Button>
          </>
        }
      >
        <Form<TRequestTrialValue>
          id="request-trial-modal"
          form={trialForm}
          onFinish={onSubmitTrial}
          layout="vertical"
        >
          <Typography.Headline6
            weight={TYPOGRAPHY_WEIGHT.NORMAL}
            className="financials-table__subtitle"
          >
            Firstly request will be sent to stakeholders to confirm that trial
            is really needed.
          </Typography.Headline6>
          <Input.TextArea
            propsItem={{
              label: 'Query to stakeholders',
              name: 'message_for_stakeholder',
              rules: [
                {
                  max: 1000
                }
              ]
            }}
            propsTextArea={{
              autoSize: {
                maxRows: 12
              },
              showCount: true,
              maxLength: 1000
            }}
          />
        </Form>
      </Popup>
    </Can>
  ) : null
}
export default RequestTrial
