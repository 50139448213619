import {
  Can,
  usePrsDetailsAbility
} from '../Providers/PrsDetailsAbilityProvider'
import { ACTIONS, SUBJECTS } from '../../Abilities'
import { subject } from '@casl/ability'
import { Button, BUTTON_TYPES } from 'components/Button'
import { BTN_TXT, LABELS, PLACEHOLDERS } from 'constants/txt'
import { Typography, TYPOGRAPHY_WEIGHT } from 'components/Typography'
import Popup from 'components/Popup/Popup'
import { usePRSDetailsPopupContext } from '../Providers/PRSDetailsPopupProvider'
import { usePRSDetailsContext } from '../Providers/PRSDetailsContextProvider'
import {
  PRS_STATUSES,
  PRS_STATUSES_MESSAGES
} from 'pages/ProductRequests/constants'
import Radio, { RadioChangeEvent } from 'antd/lib/radio'
import { Form, Space } from 'antd'
import { useEffect, useMemo, useState } from 'react'
import { REVENUE_OPTIONS } from './constants'
import cn from 'classnames'
import { Select } from 'components/Select'
import { ReactComponent as RenewIcon } from 'assets/svg/renew.svg'
import { ReactComponent as Plus16 } from 'assets/svg/Plus16.svg'
import './style.scss'
import { Tooltip } from 'components/Tooltip/Tooltip'
import { routes } from 'router'
import { useDispatch, useSelector } from 'react-redux'
import { getUser } from 'redux/store/user/getters'
import { setLoading } from 'redux/store/common/slice'
import { useParams } from 'react-router-dom'
import {
  getAvailableStepsOptions,
  getRcaList,
  patchTrial,
  updatePRS
} from 'pages/ProductRequests/api'
import { IRFPStakeholder } from '../../../RFP/RFPSurvey/types'
import { TOption } from '../../../../components/Select/types'
import { notification } from '../../../../components/Notification'
import Input from '../../../../components/Input/Input'
import { getIsLoading } from '../../../../redux/store/common/getters'

const RunNewStep = () => {
  const prsDetailsContext = usePRSDetailsContext()

  const { id } = useParams()

  const dispatch = useDispatch()

  const { data } = prsDetailsContext.state
  const { handleGetPRSData } = prsDetailsContext.actions
  const { runNewStepPopup } = usePRSDetailsPopupContext()

  const [rca, setRca] = useState([])

  const [form] = Form.useForm()

  const isLoading = useSelector(getIsLoading)

  const message_for_vendor = Form.useWatch('message_for_vendor', form)

  const ability = usePrsDetailsAbility()

  const [selectedRca, setSelectedRca] = useState('')
  const [availableOptions, setAvailableOptions] = useState(REVENUE_OPTIONS)

  const getRcaOptions = async () => {
    if (id) {
      await getRcaList(id).then((res) => {
        setRca(res.data.results)
      })
    }
  }
  const getStepOptions = async () => {
    if (id) {
      await getAvailableStepsOptions(id).then((res) => {
        setAvailableOptions(
          availableOptions.map((opt) => ({
            ...opt,
            disable: !res.data.includes(opt.value),
            hide:
              (opt.value === REVENUE_OPTIONS[0].value &&
                !!data?.rca_decision) ||
              (opt.value === REVENUE_OPTIONS[1].value &&
                data.status === PRS_STATUSES.IN_REVIEW &&
                !data.rca_decision &&
                !data.trial) ||
              (opt.value === REVENUE_OPTIONS[1].value &&
                data.status === PRS_STATUSES.IN_REVIEW &&
                !data.rca_decision &&
                data.trial?.status === PRS_STATUSES.TRIAL_COMPLETED) ||
              (opt.value === REVENUE_OPTIONS[2].value &&
                data.trial?.status === PRS_STATUSES.TRIAL_PRE_APPROVAL &&
                !data.rca_decision)
          }))
        )
      })
    }
  }

  const user = useSelector(getUser)

  const { state, actions } = runNewStepPopup
  const [value, setValue] = useState(null)

  useEffect(() => {
    if (
      ability.can(ACTIONS.RUN, subject(SUBJECTS.STEP, { ...data })) &&
      !!state.visible
    ) {
      dispatch(setLoading(true))
      Promise.all([getRcaOptions(), getStepOptions()]).finally(() =>
        dispatch(setLoading(false))
      )
    }
  }, [id, state.visible])

  const onChange = (e: RadioChangeEvent) => {
    setValue(e.target.value)
  }
  const openRunNewStepModal = () => actions.open()

  const onSend = () => {
    if (value === REVENUE_OPTIONS[0].value) {
      dispatch(setLoading(true))
      updatePRS(data.uuid, {
        status: PRS_STATUSES.REVENUE_ASSESSMENT,
        rca: selectedRca
      })
        .then(() => {
          handleGetPRSData()
          notification.success({
            message: `${PRS_STATUSES_MESSAGES.revenue_assessment}`
          })
          setSelectedRca('')
        })
        .catch((err) => {
          notification.error({
            message: err.data?.details[0]
          })
        })
        .finally(() => {
          dispatch(setLoading(false))
          onCancel()
        })
    } else if (value === REVENUE_OPTIONS[1].value) {
      dispatch(setLoading(true))
      patchTrial(data.uuid, {
        status: PRS_STATUSES.TRIAL_REQUESTED,
        message_for_vendor: message_for_vendor
      })
        .then(() => {
          handleGetPRSData()
          notification.success({
            message: `${PRS_STATUSES_MESSAGES.trial_requested}`
          })
          form.resetFields()
        })
        .catch((err) => {
          notification.error({
            message: err.data?.details[0]
          })
        })
        .finally(() => {
          dispatch(setLoading(false))
          form.resetFields()
          onCancel()
        })
    } else {
      prsDetailsContext.actions.handleChangePrsStatus(
        PRS_STATUSES.SURVEY_CREATION
      )
      onCancel()
    }
  }

  const returnSelectOptions = (arr: IRFPStakeholder[]): TOption[] => {
    if (arr?.length) {
      return [
        ...arr.map((item) => ({
          label: `${item.first_name} ${item.last_name}`,
          value: item.uuid
        }))
      ]
    }
    return []
  }

  const isDisable = useMemo(() => {
    if (value === REVENUE_OPTIONS[0].value) {
      return !selectedRca?.length
    } else if (value === REVENUE_OPTIONS[1].value) {
      return !message_for_vendor
    } else return !value
  }, [value, selectedRca, message_for_vendor])
  const handleNewUser = () => {
    window
      .open(
        window?.API_HOST +
          `${routes.createUserProfile}/new?hsUser=${user.health_system}&role=rca`,
        '_blank'
      )
      ?.focus()
  }

  const onCancel = () => {
    actions.close()
    setSelectedRca('')
    setValue(null)
  }
  return (
    <>
      <Can I={ACTIONS.RUN} a={subject(SUBJECTS.STEP, { ...data })}>
        <Button
          type={BUTTON_TYPES.PRIMARY}
          onClick={openRunNewStepModal}
          upperCase
        >
          {BTN_TXT.RUN_NEW_STEP}
        </Button>
      </Can>
      <Popup
        visible={state.visible}
        onCancel={onCancel}
        title={`Run the PRS step`}
        width={442}
        className="run-new-step-modal"
        footer={
          <>
            <Button
              onClick={actions.close}
              type={BUTTON_TYPES.DEFAULT}
              upperCase
            >
              {BTN_TXT.CANCEL}
            </Button>
            <Button
              type={BUTTON_TYPES.PRIMARY}
              disabled={isDisable}
              upperCase
              onClick={onSend}
            >
              {BTN_TXT.RUN_THE_STEP}
            </Button>
          </>
        }
      >
        <Typography.Body1 weight={TYPOGRAPHY_WEIGHT.NORMAL}>
          To start a new PRS step, please select:
          <Radio.Group
            onChange={onChange}
            value={value}
            className={cn('radio-group-default', 'mt-16')}
          >
            <Space direction="vertical">
              <>
                {isLoading
                  ? null
                  : availableOptions.map((i) => {
                      if (!i.hide)
                        return (
                          <>
                            <Radio value={i.value} disabled={i.disable}>
                              <Typography.Body1
                                weight={TYPOGRAPHY_WEIGHT.NORMAL}
                                className={cn('run-new-step-option', {
                                  disable: i.disable
                                })}
                              >
                                {i.label}
                              </Typography.Body1>
                            </Radio>
                            {value === REVENUE_OPTIONS[0].value &&
                            i.value === REVENUE_OPTIONS[0].value ? (
                              <div className="revenue-wrapper-select column align-start">
                                <div className="row align-center full-width">
                                  <Select
                                    options={returnSelectOptions(rca)}
                                    propsItem={{
                                      label: LABELS.RCA_REPRESENTATIVE,
                                      name: 'rca_representative'
                                    }}
                                    propsSelect={{
                                      defaultActiveFirstOption: true,
                                      loading: !rca?.length,
                                      onChange: setSelectedRca,
                                      value: selectedRca,
                                      placeholder: PLACEHOLDERS.PLEASE_SELECT
                                    }}
                                  />
                                  <Tooltip
                                    title="Refresh the RCA list"
                                    placement="topRight"
                                    id="run-new-step-tooltip"
                                  >
                                    <div
                                      className="renew-icon-wrapper"
                                      onClick={getRcaOptions}
                                    >
                                      <RenewIcon />
                                    </div>
                                  </Tooltip>
                                </div>
                                <Button
                                  type={BUTTON_TYPES.GHOST}
                                  onClick={handleNewUser}
                                  icon={<Plus16 />}
                                  upperCase
                                >
                                  {BTN_TXT.CREATE_RCA}
                                </Button>
                              </div>
                            ) : null}
                            {value === REVENUE_OPTIONS[1].value &&
                            i.value === REVENUE_OPTIONS[1].value ? (
                              <div className="message-for-vendor-field column align-start">
                                <div className="row align-center full-width">
                                  <Form
                                    id="requested-trial-form"
                                    form={form}
                                    layout="vertical"
                                  >
                                    <Input.TextArea
                                      propsItem={{
                                        label: 'Query to vendor',
                                        name: 'message_for_vendor',
                                        rules: [
                                          {
                                            max: 1000
                                          }
                                        ]
                                      }}
                                      propsTextArea={{
                                        autoSize: {
                                          maxRows: 12
                                        },
                                        showCount: true,
                                        maxLength: 1000
                                      }}
                                    />
                                  </Form>
                                </div>
                              </div>
                            ) : null}
                            {i.value === REVENUE_OPTIONS[1].value &&
                            i.disable ? (
                              <Typography.Caption className="run-new-step-option__message">
                                Please, wait for at least 1 answer to send trial
                                request to Vendor
                              </Typography.Caption>
                            ) : null}
                          </>
                        )
                    })}
              </>
            </Space>
          </Radio.Group>
        </Typography.Body1>
      </Popup>
    </>
  )
}

export default RunNewStep
