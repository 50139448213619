import { Typography } from 'components/Typography'
import './styles.scss'

export const EmptyChats = () => {
  return (
    <div className="empty-chat">
      <Typography.Headline6 className="empty-chat__text">
        No chats found
      </Typography.Headline6>
    </div>
  )
}
