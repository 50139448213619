import { InvoiceDataTable } from '../InvoiceDataTable'
import { RejectedInvoices } from '../RejectedInvoices/RejectedInvoices'
import { PendingClassificationContainer } from '../PendingClassification/PendingClassificationContainer'
import { NonPOInvoices } from '../NonPOInvoices/NonPOInvoices'

export const invoiceDataRoutes = [
  {
    path: '0',
    component: InvoiceDataTable
  },
  {
    path: '1',
    component: RejectedInvoices
  },
  {
    path: '2',
    component: NonPOInvoices
  },
  {
    path: '3',
    component: PendingClassificationContainer
  }
]
