import { State } from 'redux/type'
import { THealthSystem } from 'features/HealthSystems/types'
import { THealthSystemsListState } from './types'
import { moduleName } from './constants'

const getHealthSystemsListState = (state: State): THealthSystemsListState =>
  state[moduleName]

export const getHealthSystemsList = (state: State): THealthSystem[] => {
  const healthSystemsListState = getHealthSystemsListState(state)
  return healthSystemsListState.healthSystemsList
}
export const getError = (state: State): any | null => {
  const healthSystemsListState = getHealthSystemsListState(state)
  return healthSystemsListState.error
}
export const getHealthSystemsListIsLoaded = (state: State): boolean => {
  const healthSystemsListState = getHealthSystemsListState(state)
  return healthSystemsListState.isHealthSystemsListLoaded
}
export const getHealthSystemsListIsLoading = (state: State): boolean => {
  const healthSystemsListState = getHealthSystemsListState(state)
  return healthSystemsListState.isHealthSystemsListLoading
}
