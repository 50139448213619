import { VALIDATION_MESSAGES } from 'constants/txt'
import { IContractDetails } from 'features/ContractDetails/types'
import {
  IRebateCondition,
  IRebateConditionsForm
} from 'features/ContractDetails/RebateConditions/RebateConditionsForm/types'
import { REBATE_CONDITIONS_TYPE } from 'features/ContractDetails/RebateConditions/RebateConditionsForm/constants'
import { isIncreasingSequence, safeNumber, sortAscByKey } from 'helper/common'

export const convertDetailsToForm = (
  details: IContractDetails
): IRebateConditionsForm => {
  let rebates: IRebateCondition[] = details.rebates

  if (details.rebate_type === REBATE_CONDITIONS_TYPE.NO_REBATE) {
    rebates = []
  }

  if (details.rebate_type === REBATE_CONDITIONS_TYPE.FIXED_REBATE) {
    rebates = details.rebates.map((rebate) => ({
      fixed_amount: rebate.fixed_amount || 0
    }))
  }

  if (details.rebate_type === REBATE_CONDITIONS_TYPE.PERCENTAGE_REBATE) {
    rebates = details.rebates.map((rebate) => ({
      percentage: rebate.percentage || 0
    }))
  }

  if (details.rebate_type === REBATE_CONDITIONS_TYPE.GROWTH_REBATE) {
    rebates = details.rebates.map((rebate) => ({
      amount_from: rebate.amount_from || 0,
      amount_to: rebate.amount_to === 'inf' ? undefined : rebate.amount_to,
      percentage: rebate.percentage || 0
    }))
  }

  return {
    rebate_type: details.rebate_type,
    rebates
  }
}

export const convertFormToRequestData = (
  form: IRebateConditionsForm
): IRebateConditionsForm => {
  let rebates: IRebateCondition[] = form.rebates

  if (form.rebate_type === REBATE_CONDITIONS_TYPE.NO_REBATE) {
    rebates = []
  }

  if (form.rebate_type === REBATE_CONDITIONS_TYPE.FIXED_REBATE) {
    rebates = form.rebates.map((rebate) => ({
      fixed_amount: rebate.fixed_amount || 0
    }))
  }

  if (form.rebate_type === REBATE_CONDITIONS_TYPE.PERCENTAGE_REBATE) {
    rebates = form.rebates.map((rebate) => ({
      percentage: rebate.percentage || 0
    }))
  }

  if (form.rebate_type === REBATE_CONDITIONS_TYPE.GROWTH_REBATE) {
    rebates = form.rebates.map((rebate) => ({
      amount_from: safeNumber(rebate.amount_from) || 0,
      amount_to: rebate.amount_to ? safeNumber(rebate.amount_to) : 'inf',
      percentage: safeNumber(rebate.percentage)
    }))
  }

  return {
    rebate_type: form.rebate_type,
    rebates
  }
}

export const minRebateValueValidator = async (_, value) => {
  if (safeNumber(value) < 0.01) {
    return Promise.reject(new Error('Less than 0.01'))
  }

  return Promise.resolve()
}

export const percentageRebateValidator = async (_, value) => {
  await minRebateValueValidator(_, value)

  if (safeNumber(value) > 100) {
    return Promise.reject(new Error('More than 100'))
  }

  return Promise.resolve()
}

export const growthRebateIntersectionValidator = async (
  rebates: IRebateCondition[]
) => {
  const rebateSequence = [...rebates]
    .filter((rebate) => rebate.amount_from && rebate.amount_to)
    .sort(sortAscByKey<IRebateCondition>('amount_from'))
    .map((rebate) => [rebate.amount_from, rebate.amount_to])
    .flat() as number[]

  if (!isIncreasingSequence(rebateSequence)) {
    return Promise.reject(new Error(VALIDATION_MESSAGES.REBATE_INTERSECTION))
  }
}
