import { ReactComponent as Close16 } from '../../../assets/svg/Clos16.svg'
import { PLACEHOLDERS } from '../../../constants'
import { ReactComponent as Search16 } from '../../../assets/svg/Search16.svg'
import { Input } from '../../../components/Input'
import { useEffect, useMemo, useRef, useState } from 'react'
import {
  TNonPOInvoicesListParams,
  TNonPOInvoice,
  TNonPOInvoiceFilters,
  TNonPOInvoiceFiltersFunc,
  TNonPOInvoiceRequestData
} from './types'
import { useDispatch } from 'react-redux'
import { useDebounce } from '../../../hooks/hooks'
import { setLoading } from '../../../redux/store/common/slice'
import { getNonPOInvoices } from './api'
import { isTableHasParams } from '../../../helper/common'
import { Table } from '../../../components/Table'
import axios, { CancelTokenSource } from 'axios'
import { DEFAULT_PAGE } from '../../../components/Table/constants'
import { columns } from './constants'
import { ReactComponent as EmptyBox } from '../../../assets/svg/EmptyBox.svg'
import { Typography } from '../../../components/Typography'
import './styles.scss'
import { InfoLine } from '../../../components/InfoLine/InfoLine'

export const NonPOInvoices = () => {
  const [firstLoad, setFirstLoad] = useState(true)
  const [searchValue, setSearchValue] = useState('')
  const [nonPOInvoices, setNonPOInvoices] = useState<TNonPOInvoice[]>([])
  const tableDataTokenRef = useRef<CancelTokenSource>()
  const [sortParams, setSortParams] = useState<TNonPOInvoiceRequestData>()
  const [filters, setFilters] = useState<TNonPOInvoiceFilters>({})
  const [totalNumber, setTotalNumber] = useState(0)
  const [pageInfo, setPageInfo] = useState({ ...DEFAULT_PAGE })

  const timeoutRef = useRef<ReturnType<typeof setTimeout>>()
  const dispatch = useDispatch()
  const debouncedSearch = useDebounce(searchValue, 500)

  useEffect(() => {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current)
    }
    timeoutRef.current = setTimeout(
      () => {
        getTableData({ ...sortParams, ...filters, page: { ...DEFAULT_PAGE } })
        setPageInfo({ ...DEFAULT_PAGE })
      },
      firstLoad ? 0 : 500
    )
  }, [debouncedSearch])

  const getTableData = ({
    sortField,
    sortOrder,
    filters,
    page
  }: TNonPOInvoiceRequestData) => {
    dispatch(setLoading(true))
    if (!!tableDataTokenRef.current) {
      tableDataTokenRef.current.cancel()
    }
    const params: TNonPOInvoicesListParams = { ...filters }
    if (searchValue) {
      params.search = searchValue
    }
    if (sortField) {
      if (sortOrder) {
        params.ordering = `${sortOrder === 'descend' ? '-' : ''}${sortField}`
      }
      setSortParams({
        sortField,
        sortOrder
      })
    }
    const dataPage = page ? page : pageInfo
    params.limit = dataPage.pageSize
    params.offset = (dataPage.pageNumber - 1) * dataPage.pageSize
    tableDataTokenRef.current = axios.CancelToken.source()
    getNonPOInvoices({
      cancelToken: tableDataTokenRef.current?.token,
      params
    })
      .then((resp) => {
        if (!resp.data?.results) {
          return
        }
        setNonPOInvoices(
          resp.data.results.map((i) => ({
            ...i,
            key: i.uuid
          }))
        )
        setTotalNumber(resp.data.count)
        if (firstLoad) {
          setFirstLoad(false)
        }
      })
      .finally(() => dispatch(setLoading(false)))
  }

  const handleTableChange = (pagination, _filters, sorter) => {
    const page = {
      pageNumber: pagination.current,
      pageSize: pagination.pageSize
    }
    getTableData({
      sortField: sorter.field,
      sortOrder: sorter.order,
      filters,
      page
    })
    setPageInfo(page)
  }

  const handleChangePageSize = (pageSize) => {
    const page = {
      pageNumber: 1,
      pageSize
    }
    getTableData({
      ...sortParams,
      filters,
      page
    })
    setPageInfo(page)
  }

  const applyFilters: TNonPOInvoiceFiltersFunc = (field, appliedFilters) => {
    const newFilters = { ...filters, [field]: appliedFilters }
    const newPageInfo = { ...pageInfo, pageNumber: 1 }
    setFilters(newFilters)
    setPageInfo(newPageInfo)
    getTableData({ ...sortParams, filters: newFilters, page: newPageInfo })
  }

  const showEmptyState = useMemo(() => {
    return (
      !nonPOInvoices &&
      !searchValue &&
      !Object.keys(filters).find((key) => filters[key]?.length)
    )
  }, [nonPOInvoices, searchValue, filters])

  if (showEmptyState)
    return (
      <div className="non-po-invoices-table__empty-state flex justify-center align-center column gap-24">
        <EmptyBox />
        <Typography.Headline6>
          No Non-PO invoices—everything is in order!
        </Typography.Headline6>
      </div>
    )

  return (
    <div className="table-wrapper non-po-invoices-table">
      <div className="table-wrapper__header column gap-12 align-start">
        <InfoLine text="If the PO number is required, you can re-upload the file on the invoice tab" />
        <Input
          className="allow-clear"
          propsInput={{
            allowClear: { clearIcon: <Close16 /> },
            placeholder: PLACEHOLDERS.SEARCH,
            prefix: <Search16 />,
            value: searchValue,
            onChange: (e) => setSearchValue(e.target.value)
          }}
        />
      </div>
      {!firstLoad && (
        <Table<TNonPOInvoice>
          dataSource={nonPOInvoices}
          columns={columns({
            applyFilters,
            filters
          })}
          onChange={handleTableChange}
          hasSearchOrFilters={isTableHasParams<TNonPOInvoiceFilters>(
            debouncedSearch,
            filters
          )}
          onChangePage={handleChangePageSize}
          pageSize={pageInfo.pageSize}
          pagination={{
            pageSize: pageInfo.pageSize,
            current: pageInfo.pageNumber,
            total: totalNumber
          }}
        />
      )}
    </div>
  )
}
