import {
  createContext,
  FC,
  PropsWithChildren,
  useContext,
  useMemo
} from 'react'

import useSegments from 'hooks/useSegments'
import { SegmentOption } from 'constants/types'
import { INITIAL_HEADER_OPTIONS } from 'features/RFP/RFPVoting/constants'
import { TRFPDocument } from 'features/RFP/RFPDetails/types'
import { useRFPDetailsContext } from 'features/RFP/RFPDetails/Providers/RFPDetailsContextProvider'
import { RFP_STATUSES } from 'features/RFP/constants'
import { DOCUMENT_TYPE } from 'components/FileUpload/constants'

type ContextProps = {
  state: {
    isVotingInProgress: boolean
    headerOptions: SegmentOption[]
    activeHeaderOptionValue: SegmentOption['value']
    templateFiles: TRFPDocument[]
    summaryPriceFiles: TRFPDocument[]
  }
  actions: {
    setHeaderOptions: (options: SegmentOption[]) => void
    setActiveHeaderOptionValue: (value: SegmentOption['value']) => void
  }
}

const RFPVotingContext = createContext<ContextProps>({
  state: null!,
  actions: null!
})

const RFPVotingContextProvider: FC<PropsWithChildren> = ({ children }) => {
  const rfpDetailsContext = useRFPDetailsContext()

  const { data } = rfpDetailsContext.state
  const { getDocumentsByType } = rfpDetailsContext.actions

  const segmentsContext = useSegments({ options: INITIAL_HEADER_OPTIONS })

  const { options, activeOptionValue } = segmentsContext.state
  const { setOptions, setActiveOptionValue } = segmentsContext.actions

  const isVotingInProgress =
    data.status === RFP_STATUSES.VOTING_IN_PROGRESS ||
    data.status === RFP_STATUSES.VENDORS_SELECTION

  const state = useMemo(
    () => ({
      headerOptions: options,
      isVotingInProgress,
      activeHeaderOptionValue: activeOptionValue,
      templateFiles:
        data?.autogenerated_default_documents.filter(
          (i) => i.document_type === 'executive_summary'
        ) || [],
      summaryPriceFiles: getDocumentsByType(DOCUMENT_TYPE.EXECUTIVE_SUMMARY)
    }),
    [
      activeOptionValue,
      data?.autogenerated_default_documents,
      getDocumentsByType,
      isVotingInProgress,
      options
    ]
  )

  const actions = useMemo(
    () => ({
      setHeaderOptions: setOptions,
      setActiveHeaderOptionValue: setActiveOptionValue
    }),
    [setActiveOptionValue, setOptions]
  )

  return (
    <RFPVotingContext.Provider value={{ state, actions }}>
      {children}
    </RFPVotingContext.Provider>
  )
}

export const useRFPVotingContext = () => useContext(RFPVotingContext)

export default RFPVotingContextProvider
