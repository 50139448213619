import { Avatar } from 'antd'
import cn from 'classnames'
import './styles.scss'
import Typography from 'components/Typography/Typography'
import { CHAT_TYPE } from 'constants/common'
import { TChatIconName } from './types'

export const ChatIconName = ({
  firstName,
  lastName,
  src,
  roleName,
  className,
  uuid,
  organization,
  onClick,
  role
}: TChatIconName) => {
  const name = `${firstName?.charAt(0)}${lastName?.charAt(0)}`.toUpperCase()

  const onSelect = () => {
    onClick({
      uuid,
      name: `${firstName} ${lastName}`,
      type: CHAT_TYPE.PRIVATE,
      members_count: 2,
      pvp_user_role: role || ''
    })
  }
  return (
    <div
      className={cn('chat-person_icon-name_container', className)}
      onClick={onSelect}
    >
      <div className="chat-person_icon-name">
        <Avatar.Group>
          {src ? (
            <Avatar src={src} gap={1} alt="user image">
              {name}
            </Avatar>
          ) : (
            <Avatar gap={1} alt="user image">
              {name}
            </Avatar>
          )}
        </Avatar.Group>
        <div className="chat-person_name-container">
          <Typography.Body1 weight="semi-bold">
            {firstName} {lastName}
          </Typography.Body1>
          <Typography.Caption>{roleName}</Typography.Caption>
          <Typography.Caption>{organization}</Typography.Caption>
        </div>
      </div>
    </div>
  )
}
