import { CellWithSubtitle } from 'components/CellWithSubtitle/CellWithSubtitle'
import { ReactComponent as FilterApplied16 } from 'assets/svg/FilterApplied16.svg'
import { ReactComponent as Filter16 } from 'assets/svg/Filter16.svg'
import { ColumnsType } from 'antd/lib/table'
import {
  TRequestFAHsList,
  TRequestFAHsListColumnsFunc,
  TRequestFAHsListFilters,
  TRequestFAHsListFiltersFunc
} from './types'
import { Sorter } from 'components/Sorter/Sorter'
import { SortOrder } from 'antd/lib/table/interface'
import { Dropdown } from 'components/Dropdown'
import { fetchContractCategories } from 'pages/CRUDUser/api'
import {
  TAvailableForEvaluationListFilters,
  TAvailableForEvaluationListFiltersFunc
} from 'features/CognusRepository/AvailableForEvaluation/types'
import { formatOptionPortfolio } from 'helper/optionsFormatters'
import { DescriptionCell } from 'components/DescriptionCell/DescriptionCell'
import {
  OPPORTUNITY_ANALYSIS_STATUSES_TO_CLASSNAME,
  Status
} from '../../components/Status'
import {
  COGNUS_REPOSITORY_STATUS,
  COGNUS_REPOSITORY_STATUS_NAME
} from '../CognusRepository/constants'
import { EditableColumnType } from '../../components/Table/types'
import { Button, BUTTON_TYPES } from '../../components/Button'
import { BTN_TXT } from '../../constants'
import { ReactComponent as Edit16 } from 'assets/svg/Edit16.svg'
import { ReactComponent as SaveCheck24 } from 'assets/svg/SaveCheck24.svg'
import { ReactComponent as CancelCross24 } from 'assets/svg/CancelCross24.svg'
import { Input } from '../../components/Input'
import {
  currencyFormatter,
  currencyParserInteger
} from '../ContractDetails/ContractDetailsGeneral/ContractDetailsForm/utils'
import { ROLES } from '../Permission'

export const columns = ({
  applyFilters,
  filters,
  cancelEdit,
  isEditing,
  edit,
  onSubmit,
  user
}: TRequestFAHsListColumnsFunc): ColumnsType<TRequestFAHsList> => {
  const _columns: EditableColumnType<TRequestFAHsList>[] = [
    {
      dataIndex: 'contract_category',
      key: 'contract_category',
      width: 240,
      title: ({ sortOrder, sortColumn }) => (
        <Sorter<TRequestFAHsList>
          dataIndex={'contract_category'}
          sortColumn={sortColumn}
          sortOrder={sortOrder as SortOrder}
        >
          Contract category
        </Sorter>
      ),
      filterDropdown: (props) => (
        <Dropdown.Filter<
          TRequestFAHsListFilters,
          TRequestFAHsListFiltersFunc,
          unknown
        >
          applyFilters={applyFilters}
          appliedFilters={filters}
          searchCallback={fetchContractCategories}
          field="contract_category"
          asyncSearch
          {...props}
        />
      ),
      render: (_, { contract_category }) => (
        <CellWithSubtitle title={contract_category} />
      ),
      filterIcon: !!filters?.contract_category?.length ? (
        <FilterApplied16 />
      ) : (
        <Filter16 />
      ),
      filterSearch: true,
      sorter: true,
      showSorterTooltip: false
    },
    {
      dataIndex: 'portfolio',
      key: 'portfolio',
      width: 143,
      title: ({ sortOrder, sortColumn }) => (
        <Sorter<TRequestFAHsList>
          dataIndex={'portfolio'}
          sortColumn={sortColumn}
          sortOrder={sortOrder as SortOrder}
        >
          Portfolio
        </Sorter>
      ),
      filterDropdown: (props) => (
        <Dropdown.Filter<
          TAvailableForEvaluationListFilters,
          TAvailableForEvaluationListFiltersFunc,
          unknown
        >
          applyFilters={applyFilters}
          appliedFilters={filters}
          searchCallback={fetchContractCategories}
          optionFormatter={formatOptionPortfolio}
          field={'portfolio'}
          asyncSearch
          formatCallback={(data: { value: string; label: string }[]) => {
            const newData = Array.from(
              new Set(
                data
                  .filter((i) => i.value && i.label)
                  .map((i) => JSON.stringify(i))
              )
            )
            return newData.map((i) => JSON.parse(i))
          }}
          {...props}
        />
      ),
      filterIcon:
        !!filters?.portfolio?.length || !!filters?.portfolio?.length ? (
          <FilterApplied16 />
        ) : (
          <Filter16 />
        ),
      filterSearch: true,
      sorter: true,
      showSorterTooltip: false
    },
    {
      dataIndex: 'vendor',
      key: 'vendor',
      title: 'Vendor',
      width: 273,
      render: (_, { contracts }) => {
        const text = contracts.map((i) => i.vendor_name).join(', ')
        return <DescriptionCell content={text} text={text} />
      }
    },
    {
      dataIndex: 'status',
      key: 'status',
      title: 'Status',
      width: 90,
      render: (_, { status }) => (
        <Status.Default
          className={OPPORTUNITY_ANALYSIS_STATUSES_TO_CLASSNAME[status]}
        >
          {COGNUS_REPOSITORY_STATUS_NAME[status]}
        </Status.Default>
      ),
      showSorterTooltip: false
    }
  ]
  _columns.push(
    {
      dataIndex: 'current_compliance',
      key: 'current_compliance',
      title: 'Current compliance',
      width: 139,
      editable: true,
      render: (_, row: TRequestFAHsList) =>
        isEditing(row) ? (
          <Input.Number
            propsItem={{
              name: 'current_compliance',
              rules: [
                {
                  required: true,
                  message: ''
                }
              ]
            }}
            propsInputNumber={{
              min: '0',
              controls: false,
              formatter: currencyFormatter,
              parser: currencyParserInteger,
              max: 100
            }}
          />
        ) : (
          <span className="separate-item">
            <CellWithSubtitle
              title={
                row.current_compliance !== null
                  ? row.current_compliance + '%'
                  : '-'
              }
            />
          </span>
        )
    },
    {
      dataIndex: 'saving_estimates',
      key: 'saving_estimates',
      title: 'Saving estimates',
      width: 139,
      editable: true,
      render: (_, row) =>
        isEditing(row) ? (
          <Input.Number
            propsItem={{
              name: 'saving_estimates',
              rules: [
                {
                  required: true,
                  message: ''
                }
              ]
            }}
            propsInputNumber={{
              min: '0',
              maxLength: 100,
              controls: false,
              formatter: currencyFormatter,
              parser: currencyParserInteger
            }}
          />
        ) : (
          <span className="separate-item">
            <CellWithSubtitle
              title={
                row.saving_estimates
                  ? '$' + currencyFormatter(row.saving_estimates)
                  : '-'
              }
            />
          </span>
        )
    }
  )
  if (user.role !== ROLES.COMMONS_CLINICAL_COORDINATOR) {
    _columns.push({
      dataIndex: 'action',
      key: 'action',
      title: 'Operation',
      width: 104,
      editable: false,
      render: (_, row) => {
        const editable = isEditing(row)
        return editable ? (
          <span className="action-wrapper">
            <SaveCheck24 onClick={onSubmit} />
            <span className="action-wrapper-separator" />
            <CancelCross24 onClick={cancelEdit} />
          </span>
        ) : (
          <span className="action-wrapper">
            <Button
              type={BUTTON_TYPES.GHOST}
              onClick={() => edit(row)}
              disabled={row.status !== COGNUS_REPOSITORY_STATUS.IN_REVIEW}
              icon={<Edit16 />}
              upperCase
              className="table-wrapper__header_buttons__edit-btn"
            >
              {BTN_TXT.EDIT}
            </Button>
          </span>
        )
      }
    })
  }

  const mergedCol: EditableColumnType<TRequestFAHsList>[] = _columns.map(
    (col) => {
      if (!col?.editable) {
        return col
      }
      return {
        ...col,
        onCell: (record) => ({
          record,
          editing: isEditing(record)
        })
      }
    }
  ) as EditableColumnType<TRequestFAHsList>[]
  return mergedCol
}

export const initialHSHeader = {
  contact_person: {
    uuid: '',
    first_name: '',
    last_name: '',
    role: '',
    avatar: null
  },
  health_system: {
    name: '',
    community_name: ''
  }
}
