import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { moduleName } from './constants'
import { RejectedInvoicesState } from './types'
import { RejectedInvoiceLine } from '../../../features/InvoiceData/RejectedInvoices/types'

const initialState: RejectedInvoicesState = {
  changedLines: []
}

const slice = createSlice({
  name: moduleName,
  initialState,
  reducers: {
    setChangedLines: (
      state: RejectedInvoicesState,
      action: PayloadAction<RejectedInvoiceLine[]>
    ) => {
      state.changedLines = action.payload
    }
  }
})

export default slice.reducer
export const { setChangedLines } = slice.actions
