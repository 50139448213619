import {
  FC,
  createContext,
  PropsWithChildren,
  useContext,
  useMemo
} from 'react'

import usePopup, { PopupReturnType } from 'hooks/usePopup'

type ContextProps = {
  addPipelinePopup: PopupReturnType
  joinPopup: PopupReturnType
  deletePipelinePopup: PopupReturnType
  deleteHSPopup: PopupReturnType
}

const basePopup = (title: string) => ({
  title
})

const ContractDetailsPopupContext = createContext<ContextProps>({
  addPipelinePopup: null!,
  joinPopup: null!,
  deletePipelinePopup: null!,
  deleteHSPopup: null!
})

const ContractDetailsPagePopupProvider: FC<PropsWithChildren> = ({
  children
}) => {
  const addPipelinePopupProps = usePopup(basePopup(' event'))
  const joinPopupProps = usePopup(basePopup('join'))
  const deleteHSPopupProps = usePopup(basePopup('Delete Health system'))
  const deletePipelinePopupProps = usePopup(
    basePopup('Delete pipeline record?')
  )

  const context = useMemo(
    () => ({
      addPipelinePopup: addPipelinePopupProps,
      joinPopup: joinPopupProps,
      deletePipelinePopup: deletePipelinePopupProps,
      deleteHSPopup: deleteHSPopupProps
    }),
    [
      addPipelinePopupProps,
      deletePipelinePopupProps,
      joinPopupProps,
      deleteHSPopupProps
    ]
  )

  return (
    <ContractDetailsPopupContext.Provider value={context}>
      {children}
    </ContractDetailsPopupContext.Provider>
  )
}

export const useContractDetailsPagePopup = () =>
  useContext(ContractDetailsPopupContext)

export default ContractDetailsPagePopupProvider
