import { Input, Form } from 'antd'
import cn from 'classnames'
import { Props } from './types'
import './input.password.scss'

export const Password = ({ className, ...props }: Props): JSX.Element => (
  <Form.Item
    colon={false}
    className={cn('custom-input', 'input-password', className)}
    {...props.propsItem}
  >
    <Input.Password {...props.propsInput} />
  </Form.Item>
)
