import './styles.scss'

import ChatAbilityProvider from 'features/Chat/Providers/ChatAbilityProvider'
import ChatContextProvider from 'features/Chat/Providers/ChatContextProvider'
import ChatPopupProvider from 'features/Chat/Providers/ChatPopupProvider'

import Empty from 'features/Chat/Empty'

import { IChat } from 'redux/store/chats/types'
import ChatContext from './ChatContext'

export type ChatProps = {
  id: IChat['uuid']
}

const Chat = (props: ChatProps) => {
  return (
    <ChatAbilityProvider>
      <ChatContextProvider {...props}>
        <ChatPopupProvider>
          <ChatContext />
        </ChatPopupProvider>
      </ChatContextProvider>
    </ChatAbilityProvider>
  )
}

Chat.Empty = Empty

export default Chat
