import { useDispatch } from 'react-redux'
import { Form } from 'antd'
import cn from 'classnames'
import { Input } from 'components/Input'
import { Button, BUTTON_TYPES } from 'components/Button'
import { setLoading } from 'redux/store/common/slice'
import { logUser } from 'features/SignPopup/api'
import useRouter from 'hooks/useRouter'
import { routes } from 'router'
import { BTN_TXT, ERRORS_SIGN_IN, LABELS, PLACEHOLDERS } from 'constants/txt'
import { TFormSignIn, TFormSignInForm } from './forms.signIn.d'
import {
  setLocalConfirmToken,
  setLocalEmail,
  setLocalPass,
  setLocalPhoneNumber
} from '../../helper/common'
import { signInInitialValues } from './constants'

export const SignIn: TFormSignIn = ({
  className,
  openForgotPopup,
  emailChange
}): JSX.Element => {
  const dispatch = useDispatch()
  const [form] = Form.useForm()
  const { push } = useRouter()
  const handleLogin = (values) => {
    dispatch(setLoading(true))
    logUser(values)
      .then((resp) => {
        setLocalEmail(values.email)
        setLocalPass(values.password)
        if (resp?.data?.phone_number) {
          setLocalPhoneNumber(resp?.data?.phone_number)
          push(routes.faCode)
        } else if (resp?.data?.token) {
          setLocalConfirmToken(resp?.data?.token)
          push(routes.faNumber)
        }
      })
      .catch((e) => {
        if (e.data.code === ERRORS_SIGN_IN.SELF_SIGNUP_IS_IN_PROGRESS) {
          setLocalEmail(values.email)
          push(routes.verifyEmail)
        }
        if (e.data.code === ERRORS_SIGN_IN.WAITING_FOR_APPROVAL) {
          push(routes.pendingApproval)
        }
      })
      .finally(() => dispatch(setLoading(false)))
  }
  return (
    <Form<TFormSignInForm>
      className={cn('forms-sign-in', className)}
      layout="vertical"
      onFinish={handleLogin}
      initialValues={signInInitialValues}
      form={form}
    >
      <div className="row mt-32">
        <Input
          propsInput={{
            placeholder: PLACEHOLDERS.EMAIL,
            onChange: emailChange
          }}
          propsItem={{
            name: 'email',
            label: LABELS.EMAIL
          }}
        />
      </div>
      <div className="row mt-24">
        <Input.Password
          propsInput={{
            maxLength: 128
          }}
          propsItem={{
            name: 'password',
            label: LABELS.PASSWORD
          }}
        />
      </div>
      <div className="row mt-24">
        <Form.Item noStyle shouldUpdate>
          {({ getFieldsValue }) => {
            const { email, password } = getFieldsValue()
            return (
              <Button
                type={BUTTON_TYPES.PRIMARY}
                disabled={!email || !password}
                htmlType="submit"
                upperCase
              >
                {BTN_TXT.LOG_IN}
              </Button>
            )
          }}
        </Form.Item>
      </div>
      <div className="row mt-24 centered">
        <Button
          onClick={() => openForgotPopup(true)}
          type={BUTTON_TYPES.LINK}
          upperCase
          className="forgot-password-footer"
        >
          {BTN_TXT.FORGOT_PASSWORD}
        </Button>
      </div>
    </Form>
  )
}
