import { ReactComponent as HistoryIcon } from 'assets/svg/History.svg'
import './style.scss'
import { usePRSDetailsPopupContext } from '../Providers/PRSDetailsPopupProvider'
import { Popup } from 'components/Popup'
import List from './List'

const History = () => {
  const { historyPopup } = usePRSDetailsPopupContext()
  const onOpen = () => historyPopup.actions.open()
  return (
    <div className="prs-history">
      <HistoryIcon onClick={onOpen} />
      <Popup
        visible={historyPopup.state.visible}
        onCancel={historyPopup.actions.close}
        title={`History`}
        width={680}
        footer={null}
        className="prs-modal-create-survey"
      >
        <List />
      </Popup>
    </div>
  )
}

export default History
