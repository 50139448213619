import { Input } from 'components/Input'
import { usePRSVotingContext } from '../../Providers/PRSVotingContext'
import { usePRSDetailsContext } from '../../Providers/PRSDetailsContextProvider'

type TProps = {
  name: number
  question: string
  option: string | null
  vote?: boolean
}
const TextType = ({ name, question, option, vote }: TProps) => {
  const { state, actions } = usePRSVotingContext()
  const { isOpen, votingData, isCanViewSurveyResponse } = state
  const { setVotingData } = actions
  const prsContext = usePRSDetailsContext()

  return (
    <div className="row mt-16" key={question}>
      <Input.TextArea
        propsItem={{
          name: [name, 'options', 0, 'text'],
          rules: [
            {
              max: 100
            }
          ]
        }}
        propsTextArea={{
          maxLength: 100,
          disabled: !vote && (isCanViewSurveyResponse || !isOpen),
          onChange: (event) => {
            if (vote) {
              prsContext.actions.setClinicalRationaleData({
                questions: prsContext.state.clinicalRationaleData.questions.map(
                  (i) =>
                    i.uuid === question
                      ? {
                          ...i,
                          selectedOptions: !!event.target.value ? [option] : [],
                          options: [
                            { ...i.options[0], text: event.target.value }
                          ]
                        }
                      : i
                )
              })
            } else {
              setVotingData({
                questions: votingData.questions.map((i) =>
                  i.uuid === question
                    ? {
                        ...i,
                        selectedOptions: !!event.target.value ? [option] : [],
                        options: [{ ...i.options[0], text: event.target.value }]
                      }
                    : i
                )
              })
            }
          }
        }}
      />
    </div>
  )
}

export default TextType
