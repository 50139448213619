import { Route, Routes } from 'react-router-dom'

import { contractDetailsRoutes } from 'features/ContractDetails/ContractDetailsRouter/routes'
import ContractDetailsGeneral from 'features/ContractDetails/ContractDetailsGeneral/ContractDetailsGeneral'

const ContractDetailsRouter = () => (
  <Routes>
    {contractDetailsRoutes.map((route, index) => (
      <Route key={index} path={route.path} element={<route.component />} />
    ))}
    <Route index element={<ContractDetailsGeneral />} />
  </Routes>
)

export default ContractDetailsRouter
