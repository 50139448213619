import { Dropdown, Menu } from 'antd'
import { useState } from 'react'
import { Button, BUTTON_TYPES } from '../../components/Button'
import { BTN_TXT } from '../../constants'
import Typography from '../../components/Typography/Typography'
import { downloadQRCodeOptions } from './constants'
import { ReactComponent as DownIcon } from 'assets/svg/ArrowDown16.svg'
import { useDispatch } from 'react-redux'
import { setLoading } from '../../redux/store/common/slice'
import { getHealthSystemQRCOde } from './HospitalsTable/api'

const DownloadQRCode = ({ uuid }: { uuid: string }) => {
  const [visible, setVisible] = useState(false)
  const dispatch = useDispatch()
  const handleClick = async (key: string) => {
    setVisible(false)
    try {
      dispatch(setLoading(true))
      const response = await getHealthSystemQRCOde(uuid, key)
      if (!response?.data?.qr_code) return
      window.open(response?.data?.qr_code, '_blank')
    } catch (e) {
      console.error(e)
    } finally {
      dispatch(setLoading(false))
    }
  }
  return (
    <Dropdown
      onVisibleChange={() => setVisible(!visible)}
      visible={visible}
      className="menu-items"
      overlay={
        <Menu className="menu-items-wrapper">
          {downloadQRCodeOptions.map((i) => (
            <Menu.Item key={i.key} onClick={() => handleClick(i.key)}>
              <Typography.Body1>{i.label}</Typography.Body1>
            </Menu.Item>
          ))}
        </Menu>
      }
      placement="bottomRight"
      trigger={['click']}
    >
      <Button
        type={BUTTON_TYPES.PRIMARY}
        onClick={(e) => e.stopPropagation()}
        className="hs-details__edit-btn"
        upperCase
      >
        <div className="download-qr-code-icon row align-center gap-8">
          {BTN_TXT.GENERATE_QR_CODE}
          <DownIcon />
        </div>
      </Button>
    </Dropdown>
  )
}

export default DownloadQRCode
