import Typography from 'components/Typography/Typography'
import { TYPOGRAPHY_WEIGHT } from 'components/Typography'
import ChatDetails from 'features/ChatDetails/ChatDetails'
import { useChatContext } from 'features/Chat/Providers/ChatContextProvider'
import { IChannelChatDetails } from 'redux/store/chatDetails/types'
import { safeString } from 'helper/common'

export const ChannelDetails = () => {
  const chatContext = useChatContext<IChannelChatDetails>()

  const { chat } = chatContext.state

  return (
    <ChatDetails
      name="Ch"
      chatName={chat?.rfp_name}
      linkId={chat?.rfp_id}
      isLink={Boolean(chat)}
    >
      <Typography.Label
        weight={TYPOGRAPHY_WEIGHT.SEMI_BOLD}
        className="chat-details-container__organization-name"
      >
        {safeString(chat?.rfp_number)}
      </Typography.Label>
    </ChatDetails>
  )
}
