import { createContext, FC, PropsWithChildren, useMemo } from 'react'
import { useSelector } from 'react-redux'
import { createContextualCan, useAbility } from '@casl/react'

import { getUser } from 'redux/store/user/getters'
import {
  ability,
  defineAbility,
  ContractAbility
} from 'features/ContractDetails/Abilities'

export const ContractDetailsAbilityContext =
  createContext<ContractAbility>(ability)

const ContractDetailsAbilityProvider: FC<PropsWithChildren> = ({
  children
}) => {
  const user = useSelector(getUser)

  const ability = useMemo(() => defineAbility(user), [user])

  return (
    <ContractDetailsAbilityContext.Provider value={ability}>
      {children}
    </ContractDetailsAbilityContext.Provider>
  )
}

export const useContractDetailsAbility = () =>
  useAbility<ContractAbility>(ContractDetailsAbilityContext)

export const Can = createContextualCan<any>(
  ContractDetailsAbilityContext.Consumer
)

export default ContractDetailsAbilityProvider
