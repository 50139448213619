import { Popup } from 'components/Popup'
import { useRFPDetailsPopupContext } from 'features/RFP/RFPDetails/Providers/RFPDetailsPopupProvider'
import { useRFPDetailsContext } from 'features/RFP/RFPDetails/Providers/RFPDetailsContextProvider'
import { useEffect } from 'react'
import { Button, BUTTON_TYPES } from 'components/Button'
import { BTN_TXT } from 'constants/txt'
import { Typography } from 'components/Typography'

export default () => {
  const { state, actions } = useRFPDetailsContext()
  const { cancelRFPPopup } = useRFPDetailsPopupContext()

  const { data } = state
  const { handleCancelRFP } = actions

  const { title, visible, onCancel } = cancelRFPPopup.state
  const { setState, close } = cancelRFPPopup.actions

  useEffect(() => {
    setState((prevState) => ({ ...prevState, title: `Cancel ${data.name}?` }))
  }, [data.name, setState])

  return (
    <Popup
      visible={visible}
      onCancel={onCancel}
      title={title}
      width={442}
      footer={
        <>
          <Button type={BUTTON_TYPES.DEFAULT} onClick={close} upperCase>
            {BTN_TXT.BACK_TO_RFP_DETAILS}
          </Button>
          <Button
            type={BUTTON_TYPES.PRIMARY}
            onClick={handleCancelRFP}
            danger
            upperCase
          >
            {BTN_TXT.CANCEL_RFP}
          </Button>
        </>
      }
    >
      <Typography.Body1>
        All the RFP progress will be lost. HS users and Vendors will be notified
        about cancelation.
        <div style={{ marginTop: 15 }}>Do you really want to cancel RFP?</div>
      </Typography.Body1>
    </Popup>
  )
}
