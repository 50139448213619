import { FieldWithLabel } from '../../../components/FieldWithLabel/FieldWithLabel'
import { LABELS } from '../../../constants'
import { Typography } from '../../../components/Typography'
import { defaultDateFormat } from '../../../helper/dates'
import { useCPRDetailsContext } from '../Providers/CPRDetailsContextProvider'
import './style.scss'
import { LED_BY_LABEL } from '../../../components/LeadByIndicator/constants'
import AdditionalInfo from './AdditionalInfo'

const PreviewCPRDetails = () => {
  const contractDetailsContext = useCPRDetailsContext()

  const { details } = contractDetailsContext.state
  return (
    <>
      <div className="contract-details-general__details">
        <div className="cpr-details-preview">
          <div className="cpr-details-preview__container">
            <div className="cpr-details-preview__grid">
              <div className="cpr-details-preview__row">
                <FieldWithLabel title={LABELS.LED_BY}>
                  <Typography.Body1>
                    {LED_BY_LABEL[details.led_by]}
                  </Typography.Body1>
                </FieldWithLabel>
              </div>
              <div className="cpr-details-preview__row">
                <FieldWithLabel title={LABELS.CONTRACT_CATEGORIES}>
                  <Typography.Body1>
                    {details.contract_category_name}
                  </Typography.Body1>
                </FieldWithLabel>
              </div>
              <div className="cpr-details-preview__row row gap-24">
                <FieldWithLabel title={LABELS.ESTIMATED_BIT_START_DATE}>
                  <Typography.Body1>
                    {details.bid_start_date
                      ? defaultDateFormat(details.bid_start_date)
                      : '-'}
                  </Typography.Body1>
                </FieldWithLabel>
                <FieldWithLabel title={LABELS.ESTIMATED_SAVINGS}>
                  <Typography.Body1>
                    {details.estimated_savings}
                  </Typography.Body1>
                </FieldWithLabel>
              </div>
            </div>
          </div>
        </div>
      </div>
      <AdditionalInfo />
    </>
  )
}

export default PreviewCPRDetails
