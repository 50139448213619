import { FC, PropsWithChildren, ReactNode, useMemo } from 'react'
import cn from 'classnames'

import './styles.scss'

import usePopup from 'hooks/usePopup'
import { BTN_TXT } from 'constants/txt'
import { ModalProps } from 'components/Popup/types'
import VersionHistoryPopup from 'components/Container/WithHistory/VersionHistoryPopup/VersionHistoryPopup'
import { Button, BUTTON_TYPES } from 'components/Button'

type Props = {
  className?: string
  hasVersionHistory?: boolean
  hideVersionHistory?: boolean
  versionHistory?: ReactNode
  versionHistoryButtonText?: string
  popupProps?: Omit<Partial<ModalProps>, 'children'>
  title?: string
}

const INITIAL_POPUP_PROPS: Partial<ModalProps> = {
  title: 'Version history'
}

const WithHistory: FC<PropsWithChildren<Props>> = (props) => {
  const {
    children,
    className,
    hasVersionHistory,
    hideVersionHistory,
    versionHistory,
    versionHistoryButtonText,
    popupProps,
    title
  } = props

  const versionHistoryPopupProps = usePopup({
    ...INITIAL_POPUP_PROPS,
    ...popupProps,
    title: title || INITIAL_POPUP_PROPS.title
  })

  const ShowVersionHistory = useMemo(() => {
    const handleShowVersionHistoryClick = () => {
      versionHistoryPopupProps.actions.open()
    }

    return hideVersionHistory ? null : (
      <Button
        className="show-version-history-button"
        type={BUTTON_TYPES.LINK}
        onClick={handleShowVersionHistoryClick}
      >
        {versionHistoryButtonText || BTN_TXT.SHOW_VERSION_HISTORY}
      </Button>
    )
  }, [
    hideVersionHistory,
    versionHistoryButtonText,
    versionHistoryPopupProps.actions
  ])

  return (
    <div className={cn('container-with-history', className)}>
      {children}
      {hasVersionHistory && ShowVersionHistory}
      <VersionHistoryPopup
        {...versionHistoryPopupProps.state}
        {...versionHistoryPopupProps.actions}
        children={versionHistory}
      />
    </div>
  )
}

export default WithHistory
