import cn from 'classnames'
import { AutoComplete, Form, Select as SelectAntd } from 'antd'
import { ReactComponent as Check16 } from '../../assets/svg/Check16.svg'
import { ReactComponent as ChevronDown16 } from 'assets/svg/ChevronDown16.svg'
import { Props } from './types'
import { ReactElement } from 'react'
import './select.default.scss'

const { OptGroup, Option } = SelectAntd

const SelectAutocomplete = ({ ...props }: Props): ReactElement => {
  return (
    <Form.Item
      className={cn('custom-select', 'select-default', props.className)}
      colon={false}
      {...props.propsItem}
      name={
        props.options.length || props.alwaysName ? props.propsItem?.name : ''
      }
    >
      <AutoComplete
        {...props.propsSelect}
        placement="bottomLeft"
        menuItemSelectedIcon={!props.onPagination && <Check16 />}
        dropdownClassName={cn(
          'custom-select__dropdown autocomplete',
          props.dropdownClassName
        )}
        suffixIcon={<ChevronDown16 />}
        filterOption={false}
        showSearch
      >
        {props.options.map((option) =>
          !option?.options ? (
            <Option value={option.value} key={option.value}>
              {option.label}
            </Option>
          ) : (
            <OptGroup label={option.label} key={option.key}>
              {option.options.map((i) => (
                <Option value={i.value} key={i.value}>
                  {i.label}
                </Option>
              ))}
            </OptGroup>
          )
        )}
      </AutoComplete>
    </Form.Item>
  )
}

export default SelectAutocomplete
