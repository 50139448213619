import Link from 'components/Link/Link'
import { Typography } from 'components/Typography'
import { useEffect, useState } from 'react'
import { routes } from 'router'
import { getRFPResults } from './api'
import { TRFPResultResponce } from './types'
import './styles.scss'
import { canSeeMyContracts, groupByField } from 'helper/common'
import { useDispatch, useSelector } from 'react-redux'
import { getUser } from 'redux/store/user/getters'
import { useParams } from 'react-router-dom'
import { setLoading } from 'redux/store/common/slice'

export const RFPResults = () => {
  const [results, setResults] = useState([] as any)
  const params = useParams()
  const user = useSelector(getUser)
  const isVendor = user?.vendor
  const dispatch = useDispatch()

  const Contract = ({ ...props }: TRFPResultResponce) => {
    const { uuid, name, number, contract_category_name } = props
    return (
      <div className="rfp-results_contract">
        <Link
          to={`${routes.contracts}${
            canSeeMyContracts(user.role) ? '/my_contracts' : ''
          }/${uuid}`}
        >
          {name}
        </Link>
        <Typography.Caption>{number}</Typography.Caption>
        <Typography.Caption>{contract_category_name}</Typography.Caption>
      </div>
    )
  }

  useEffect(() => {
    dispatch(setLoading(true))
    getRFPResults(params?.id).then((responce) => {
      setResults(
        isVendor
          ? responce?.data.results
          : groupByField(responce?.data.results, 'vendor_name')
      )
      dispatch(setLoading(false))
    })
  }, [params?.id])

  return (
    <div className="rfp-results">
      {isVendor ? (
        <div className="rfp-results_contracts">
          {!!results && results?.map((result) => <Contract {...result} />)}
        </div>
      ) : (
        <>
          {!!results &&
            Object.keys(results).map((key) => (
              <div className="rfp-results_contracts" key={key}>
                <div className="rfp-results_vendor-name">{key}</div>
                {results[key].map((item, idx) => (
                  <Contract {...item} key={item?.uuid || idx} />
                ))}
              </div>
            ))}
        </>
      )}
    </div>
  )
}
