import { useEffect, useMemo } from 'react'
import { Routes, Route, Navigate, useParams } from 'react-router-dom'
import { Container } from 'components/Container/Container'
import { Status } from 'components/Status'
import { Menu } from 'components/Menu/Menu'
import useRouter from 'hooks/useRouter'
import { safeString } from '../../../helper/common'
import {
  PRS_DETAILS_BREADCRUMBS,
  PRS_DETAILS_TABS,
  PRS_STATUSES_NAME,
  PRS_STATUSES_CLASSNAME,
  PRS_STATUSES
} from '../../../pages/ProductRequests/constants'
import { usePRSDetailsContext } from './Providers/PRSDetailsContextProvider'
import FormsWrapper from './FormWrapper/FormsWrapper'
import Voting from './Voting/Voting'
import { Actions } from './Actions/Actions'
import ReasonComponent from './ReasonComponent/ReasonComponent'
import FacilityUsers from '../FacilityUsers/FacilityUsers'
import { ROLES } from 'features/Permission'
import { useSelector } from 'react-redux'
import { getUser } from '../../../redux/store/user/getters'
import cn from 'classnames'
import './style.scss'
import Results from './Results/Results'
import { ACTIONS, SUBJECTS } from '../Abilities'
import { subject } from '@casl/ability'
import { usePrsDetailsAbility } from './Providers/PrsDetailsAbilityProvider'
import TrialPreApproval from './TrialPreApproval/TrialPreApproval'
import History from './History/History'
import PrsDetailsTimelinesPopup from '../PrsDetailsTimelines/PrsDetailsTimelinesPopup'
import { usePRSDetailsPopupContext } from './Providers/PRSDetailsPopupProvider'
import { STATUSES_BAN_TIMER } from './constants'

export const PRSDetails = () => {
  const { push } = useRouter()
  const user = useSelector(getUser)
  const { state, actions } = usePRSDetailsContext()
  const { prsStepsTimePopup } = usePRSDetailsPopupContext()
  const {
    data,
    tabs,
    activeTab,
    isVCS,
    isCommunityResp,
    isClinicalSponsor,
    isRcaResponsible
  } = state
  // const user = useSelector(getUser)
  const { tab } = useParams()
  const { setActiveTab } = actions
  const ability = usePrsDetailsAbility()
  useEffect(() => {
    setActiveTab(safeString(tab))
  }, [tab])

  const canSeeActions = useMemo(() => {
    return (
      isVCS ||
      isCommunityResp ||
      user.role === ROLES.STAKEHOLDER ||
      isClinicalSponsor ||
      isRcaResponsible
    )
  }, [user, isVCS, isCommunityResp, isClinicalSponsor, isRcaResponsible])
  const renderedActions = useMemo(
    () => (canSeeActions && data.uuid ? <Actions /> : null),
    [isVCS, data.uuid]
  )
  const canSeeLabel = useMemo(() => {
    return (
      ability.can(
        ACTIONS.VIEW,
        subject(SUBJECTS.RCA_APPROVE_LABEL, { ...data })
      ) && data.rca_decision
    )
  }, [data, ability])

  const canSeeReasonComponent = useMemo(() => {
    return (
      user.role === ROLES.STAKEHOLDER ||
      data?.trial ||
      data?.decline_reason ||
      canSeeLabel ||
      isClinicalSponsor ||
      (ability.can(
        ACTIONS.SEE,
        subject(SUBJECTS.CS_APPROVAL_LABEL, { ...data })
      ) &&
        !data?.trial &&
        !data?.rca_decision)
    )
  }, [data, user, canSeeLabel, isClinicalSponsor])

  const canViewTimer = useMemo(() => {
    return data.status && !STATUSES_BAN_TIMER.find((i) => i === data.status)
  }, [data.status])

  const canManageTimer = useMemo(() => {
    return (
      data.status &&
      !STATUSES_BAN_TIMER.find((i) => i === data.status) &&
      ability.can(ACTIONS.MANAGE, subject(SUBJECTS.TIMER, { ...data }))
    )
  }, [data])

  const canSeeMainStatus = useMemo(() => {
    return (
      data.trial &&
      data?.trial?.status !== PRS_STATUSES.TRIAL_COMPLETED &&
      data.status === PRS_STATUSES.IN_REVIEW
    )
  }, [data])

  return (
    <Container
      title={data.uuid ? data.name : 'PRS Creation'}
      subtitle={data.number}
      breadcrumbs={PRS_DETAILS_BREADCRUMBS(
        data.uuid ? data.name : 'PRS Creation'
      )}
      expiresAt={
        canViewTimer
          ? data.prs_timers?.find((i) => i.status === data.status)
              ?.time_duration || data.prs_timers[0]?.time_duration
          : ''
      }
      onClickTimer={canManageTimer ? prsStepsTimePopup.actions.open : () => {}}
      className="prs-details"
      status={
        data.uuid && (
          <div
            className={cn('row', {
              'two-status':
                !canSeeMainStatus &&
                !!data?.trial &&
                data?.trial?.status !== PRS_STATUSES.TRIAL_COMPLETED
            })}
          >
            {!canSeeMainStatus && (
              <Status.Default className={PRS_STATUSES_CLASSNAME[data.status]}>
                {PRS_STATUSES_NAME[data.status]}
              </Status.Default>
            )}
            {!!data.trial &&
              data?.trial?.status !== PRS_STATUSES.TRIAL_COMPLETED && (
                <Status.Default
                  className={PRS_STATUSES_CLASSNAME[data?.trial?.status]}
                >
                  {PRS_STATUSES_NAME[data?.trial?.status]}
                </Status.Default>
              )}
            <History />
          </div>
        )
      }
      reasonComponent={canSeeReasonComponent ? <ReasonComponent /> : null}
      actions={renderedActions}
      menu={
        <Menu
          mode="horizontal"
          onSelect={(e) => {
            setActiveTab(e.key)
            push(e.key)
          }}
          selectedKeys={[activeTab]}
          defaultSelectedKeys={[activeTab]}
          items={tabs}
        />
      }
    >
      <Routes>
        <Route path={PRS_DETAILS_TABS[0].key} element={<FormsWrapper />} />
        <Route path={PRS_DETAILS_TABS[1].key} element={<FormsWrapper />} />
        <Route path={PRS_DETAILS_TABS[2].key} element={<FacilityUsers />} />
        <Route path={PRS_DETAILS_TABS[3].key} element={<Voting />} />
        <Route path={PRS_DETAILS_TABS[4].key} element={<TrialPreApproval />} />
        <Route path={PRS_DETAILS_TABS[5].key} element={<Results />} />
        <Route
          index
          element={<Navigate to={PRS_DETAILS_TABS[0].key} replace />}
        />
      </Routes>
      <PrsDetailsTimelinesPopup />
    </Container>
  )
}
