import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Button, BUTTON_TYPES } from 'components/Button'
import { Typography, TYPOGRAPHY_WEIGHT } from 'components/Typography'
import { Menu } from 'components/Menu/Menu'
import { Table } from 'components/Table'
import { UserArchivePopup } from 'pages/CRUDUser/UserArchivePopup'
import { getUser } from 'redux/store/user/getters'
import { getUsers } from './api'
import { setLoading } from 'redux/store/common/slice'
import { columns } from './constants'
import { fullFormatPhoneNumber } from 'helper/common'
import useRouter from 'hooks/useRouter'
import { ability } from 'features/Permission/ability'
import { ACTIONS, Can, SUBJECTS } from 'features/Permission'
import { HEALTH_SYSTEM_TABLES_TABS } from '../constants'
import { routes } from 'router'
import { BTN_TXT } from 'constants/txt'
import { SETTINGS_TABS } from 'pages/Settings/constants'
import { USER_STATUSES } from 'constants/common'
import { ReactComponent as Edit16 } from 'assets/svg/Edit16.svg'
import {
  TUsersTableRequestData,
  TTableUser,
  TUsersParams,
  TUsersFiltersFunc,
  TUsersFilters,
  TUsersTableProps
} from './types'
import { DEFAULT_PAGE } from 'components/Table/constants'
import { ReactComponent as Plus16 } from '../../../assets/svg/Plus16.svg'

export const UsersTable = ({
  hsId,
  activeTab,
  setActiveTab
}: TUsersTableProps) => {
  const [tableData, setTableData] = useState<TTableUser[]>([])
  const [filters, setFilters] = useState<TUsersFilters>()
  const [sortParams, setSortParams] = useState<TUsersTableRequestData>()
  const [selectedRowKeys, setSelectedRowKeys] = useState<string[]>([])
  const [selectedRows, setSelectedRows] = useState<TTableUser[]>([])
  const [totalNumber, setTotalNumber] = useState(0)
  const [pageInfo, setPageInfo] = useState({ ...DEFAULT_PAGE })
  const user = useSelector(getUser)
  const dispatch = useDispatch()
  const { push } = useRouter()
  useEffect(() => {
    if (user.uuid && hsId) {
      getTableData({ ...sortParams, filters })
    }
  }, [user.uuid, hsId])

  const handleRedirect = () => {
    push(`${routes.createUserProfile}/${selectedRowKeys[0]}`)
  }
  const getTableData = ({
    sortField,
    sortOrder,
    filters,
    page
  }: TUsersTableRequestData) => {
    dispatch(setLoading(true))
    const params: TUsersParams = { ...filters }
    if (sortField) {
      if (sortOrder) {
        params.ordering = `${sortOrder === 'descend' ? '-' : ''}${sortField}`
      }
      setSortParams({
        sortField,
        sortOrder
      })
    }
    const dataPage = page ? page : pageInfo
    params.limit = dataPage.pageSize
    params.offset = (dataPage.pageNumber - 1) * dataPage.pageSize
    getUsers(user.uuid, hsId, params)
      .then((resp) => {
        if (!resp.data?.results) {
          return
        }
        setTableData(
          resp.data.results.map((i) => ({
            ...i,
            phone_number: fullFormatPhoneNumber(i.phone_number),
            key: i.uuid
          }))
        )
        setTotalNumber(resp.data.count)
      })
      .finally(() => dispatch(setLoading(false)))
  }
  const handleSelectRow = (selectedRowKeys, selectedRows) => {
    setSelectedRowKeys(selectedRowKeys)
    setSelectedRows(selectedRows)
  }
  const handleTableChange = (pagination, _filters, sorter) => {
    const page = {
      pageNumber: pagination.current,
      pageSize: pagination.pageSize
    }
    getTableData({
      sortField: sorter.field,
      sortOrder: sorter.order,
      filters,
      page
    })
    setPageInfo(page)
  }
  const handleChangePageSize = (pageSize) => {
    const page = {
      pageNumber: 1,
      pageSize
    }
    getTableData({
      ...sortParams,
      filters,
      page
    })
    setPageInfo(page)
  }
  const AddBtn = () => (
    <Can I={ACTIONS.CRUD} a={SUBJECTS.HOSPITAL_USERS}>
      <>
        {selectedRowKeys.length === 1 && (
          <Can I={ACTIONS.EDIT} a={SUBJECTS.USER}>
            <Button
              type={BUTTON_TYPES.PRIMARY}
              onClick={handleRedirect}
              icon={<Edit16 />}
              upperCase
              className="table-wrapper__header_buttons__edit-btn"
            >
              {BTN_TXT.EDIT}
            </Button>
          </Can>
        )}
        {!selectedRowKeys.length && (
          <Button
            type={BUTTON_TYPES.PRIMARY}
            icon={<Plus16 />}
            onClick={() =>
              push(`${routes.createUserProfile}/new?hsHosUser=${hsId}`, {
                state: {
                  redirectTab: ability.can(
                    ACTIONS.VIEW,
                    SUBJECTS.HEALTH_SYSTEMS_TAB
                  )
                    ? SETTINGS_TABS[3].key
                    : SETTINGS_TABS[5].key
                }
              })
            }
            upperCase
          >
            {BTN_TXT.ADD_USER}
          </Button>
        )}
        {!!selectedRows.length &&
          selectedRows.filter((i) => i.status !== USER_STATUSES.ARCHIVED)
            .length === selectedRows.length && (
            <UserArchivePopup<TTableUser>
              selected={selectedRows}
              callback={() => {
                setSelectedRowKeys([])
                setSelectedRows([])
                getTableData({ ...sortParams, ...filters })
              }}
              isTable
            />
          )}
      </>
    </Can>
  )
  const applyFilters: TUsersFiltersFunc = (field, appliedFilters) => {
    const newFilters = { ...filters, [field]: appliedFilters }
    setFilters(newFilters)
    getTableData({ ...sortParams, filters: newFilters })
  }
  const onSelect = (e) => setActiveTab(e.key)
  return (
    <>
      <div className="hs-details__tables-menu">
        {!!selectedRowKeys.length ? (
          <Typography.Body1
            className="table-wrapper__select-title"
            weight={TYPOGRAPHY_WEIGHT.SEMI_BOLD}
          >
            Selected {selectedRowKeys.length} of {totalNumber}
          </Typography.Body1>
        ) : (
          <Menu
            mode="horizontal"
            onSelect={onSelect}
            defaultSelectedKeys={[activeTab]}
            items={HEALTH_SYSTEM_TABLES_TABS}
          />
        )}
        <div className="hs-details__tables-actions">{AddBtn()}</div>
      </div>
      <div className="table-wrapper hs-details__users-table">
        <Table<TTableUser>
          dataSource={tableData}
          onChange={handleTableChange}
          columns={columns({
            applyFilters,
            filters,
            userId: user.uuid,
            hsId
          })}
          className="users-list-table"
          pageSize={pageInfo.pageSize}
          pagination={{
            pageSize: pageInfo.pageSize,
            current: pageInfo.pageNumber,
            total: totalNumber
          }}
          onChangePage={handleChangePageSize}
          renderButton={AddBtn()}
          rowSelection={
            ability.can(ACTIONS.CRUD, SUBJECTS.HOSPITAL_USERS)
              ? {
                  selectedRowKeys,
                  onChange: handleSelectRow
                }
              : undefined
          }
        />
      </div>
    </>
  )
}
