import process from 'process'

export const API_HOST =
  process.env.NODE_ENV === 'development'
    ? (window as Window).API_HOST
    : document.location.origin

export const S3_NAME = process.env.REACT_APP_S3_BUCKET
export const WS_API_HOST = process.env.REACT_APP_HOST?.replace(
  /^https?/g,
  'wss'
)
export const BASE_SOCKET_URL = WS_API_HOST + '/api/ws/v1/chats/'

export const SOCKET_RECONNECT_DELAY = 1000

export const BASE_SOCKET_READY_STATE = {
  CONNECTING: 0,
  OPEN: 1,
  CLOSING: 2,
  CLOSED: 3
}

export const BASE_SOCKET_EVENT = {
  OPEN_OR_RECONNECT: 'open',
  MESSAGE: 'message',
  CLOSE: 'close',
  ERROR: 'error'
} as const

export const BASE_SOCKET_TYPE = {
  NEW_MESSAGE_ADDED: 'new_message_added',
  MESSAGE_READ: 'message_read'
} as const
