import Popup from 'components/Popup/Popup'
import { Button, BUTTON_TYPES } from 'components/Button'
import { BTN_TXT } from 'constants/txt'
import { Form } from 'antd'
import { TQuestionsForm } from 'components/Forms/forms.surveyQuestions.d'
import { validateMessages } from '../../../../helper/common'
import cn from 'classnames'
import { initialStateForSurveyCreation } from '../Voting/Survey/constants'
import { usePRSDetailsPopupContext } from '../Providers/PRSDetailsPopupProvider'
import Preview from '../Voting/Preview/Preview'
import { usePRSDetailsContext } from '../Providers/PRSDetailsContextProvider'
import Typography from 'components/Typography/Typography'
import './style.scss'
import { useMemo, useRef } from 'react'
import { pageSize } from '../General/constants'
import { TVotes } from '../types'
import { setSurveyResultsForCs } from 'pages/ProductRequests/api'
import { notification } from 'components/Notification'
import { useParams } from 'react-router-dom'

const ClinicalForm = () => {
  const { openRationalePopup } = usePRSDetailsPopupContext()
  const { state, actions } = usePRSDetailsContext()
  const { clinicalRationaleData, isError, page } = state
  const {
    setIsError,
    setClinicalRationaleData,
    setPage,
    getSurveyFormForCS,
    resetForm
  } = actions

  const modalRef = useRef<null | HTMLDivElement>(null)

  const [form] = Form.useForm()

  const { id } = useParams()

  const closeModal = () => {
    openRationalePopup.actions.close()
    resetForm()
    form.resetFields()
    setPage(0)
  }

  const onSubmitForm = () => {
    if (id) {
      const votes = [] as TVotes
      clinicalRationaleData.questions.forEach((i) => {
        i.options.forEach((option) => {
          if (option?.uuid && i.selectedOptions?.includes(option?.uuid)) {
            votes.push({ option: option?.uuid, text: option.text })
          }
        })
      })
      setSurveyResultsForCs({ uuid: id, data: { answers: votes } }).then(() => {
        openRationalePopup.actions.close()
        getSurveyFormForCS()
        notification.success({
          message: 'Clinical information has been saved.'
        })
      })
    }
  }

  const checkIsNextDisabled = useMemo(() => {
    const data = clinicalRationaleData?.questions.slice(0, pageSize[page].end)
    return data.find(
      (i) =>
        (!i?.selectedOptions?.length && i.is_required) ||
        !!i.options?.find(
          (opt) =>
            opt?.uuid &&
            !!i.selectedOptions?.find((k) => k === opt?.uuid) &&
            !opt?.text?.trim()
        )
    )
  }, [clinicalRationaleData, page])

  return (
    <Popup
      visible={openRationalePopup.state.visible}
      onCancel={closeModal}
      title={'Clinical rationale survey'}
      width={680}
      className="prs-modal-cs-survey"
      footer={
        <div className="prs-modal-create-survey__footer row space-between">
          <Button onClick={closeModal} type={BUTTON_TYPES.DEFAULT} upperCase>
            {BTN_TXT.CANCEL}
          </Button>
          <div className="prs-modal-create-survey__footer__actions row align-center">
            <Typography.Body1 className="prs-modal-create-survey__footer__actions__page-number">
              Page: {page + 1}/3
            </Typography.Body1>
            <Button
              type={BUTTON_TYPES.DEFAULT}
              upperCase
              disabled={page === 0}
              onClick={() => {
                setPage(page - 1)
                modalRef?.current?.scrollIntoView({
                  block: 'start'
                })
              }}
            >
              {BTN_TXT.PREVIOUS}
            </Button>
            {page !== 2 ? (
              <Button
                type={BUTTON_TYPES.PRIMARY}
                upperCase
                disabled={checkIsNextDisabled}
                onClick={() => {
                  setPage(page + 1)
                  modalRef?.current?.scrollIntoView({
                    block: 'start'
                  })
                }}
              >
                {BTN_TXT.NEXT}
              </Button>
            ) : (
              <>
                <Button
                  type={BUTTON_TYPES.PRIMARY}
                  upperCase
                  htmlType="submit"
                  disabled={checkIsNextDisabled}
                  form="prs-cs-form"
                >
                  <span className="btn-text">{BTN_TXT.SAVE_RESPONSE}</span>
                  <span className="mobile-text">{BTN_TXT.SAVE}</span>
                </Button>
              </>
            )}
          </div>
        </div>
      }
    >
      <Form<TQuestionsForm>
        validateMessages={validateMessages}
        form={form}
        className={cn('question-template-container')}
        layout="vertical"
        id="prs-cs-form"
        onFinish={onSubmitForm}
        initialValues={clinicalRationaleData || initialStateForSurveyCreation}
        onValuesChange={(_, _all) => {
          isError && setIsError(false)
          const res = _all?.questions?.map((i, idx) => {
            if (!!i?.options?.find((o) => o?.is_other)) {
              const newOptions = i.options?.filter((opt) => !opt.is_other)
              return {
                ...i,
                selectedOptions:
                  clinicalRationaleData?.questions[idx]?.selectedOptions,
                options: [
                  ...newOptions.filter((i) => !i.is_other),
                  {
                    ...i.options?.find((i) => i.is_other),
                    is_other: true
                  }
                ]
              }
            } else
              return {
                ...i,
                options: i?.options?.filter((i) => i?.text !== undefined)
              }
          })
          form.setFieldsValue({ questions: res?.filter((i) => i.type) })
          setClinicalRationaleData({ questions: res?.filter((i) => i.type) })
        }}
      >
        <div ref={modalRef}>
          <Typography.Body1 className="prs-modal-create-survey__footer__actions__page-number__mobile">
            Page: {page + 1}/3
          </Typography.Body1>
          <Preview
            votingData={clinicalRationaleData}
            isAbleToVote={true}
            vote={true}
            isError={false}
            page={page}
            form={form}
          />
        </div>
      </Form>
    </Popup>
  )
}

export default ClinicalForm
