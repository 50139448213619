import Fetcher from 'services/Fetcher'
import { API_DICTIONARY, HTTP_METHODS } from 'constants/api'
import { THospitalUsersRequest, TUsersRequest, TUsersResponse } from './types'

const fetcher = new Fetcher({})

export const getUsers: TUsersRequest = async (uuid, id, params) =>
  fetcher.request<unknown, TUsersResponse>({
    url: `${API_DICTIONARY.COMMUNITIES}${uuid}/${API_DICTIONARY.HEALTH_SYSTEMS}${id}/${API_DICTIONARY.HOSPITAL_USERS}`,
    method: HTTP_METHODS.GET,
    params
  })

export const getHospitalUsers: THospitalUsersRequest = async (
  uuid,
  id,
  hospitalId,
  params
) =>
  fetcher.request<unknown, TUsersResponse>({
    url: `${API_DICTIONARY.COMMUNITIES}${uuid}/${API_DICTIONARY.HEALTH_SYSTEMS}${id}/${API_DICTIONARY.HOSPITALS}${hospitalId}/${API_DICTIONARY.USERS}`,
    method: HTTP_METHODS.GET,
    params
  })
