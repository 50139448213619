import {
  API_DICTIONARY,
  HTTP_METHODS,
  ResponseWithPagination
} from '../../constants'
import Fetcher from 'services/Fetcher'
import {
  RespondToTheMeeting,
  TCalendarEvent,
  TCalendarEventDetails,
  TCalendarEventListType,
  TOptionTypeEvent,
  TQueryCalendarListParams
} from './types'
import { TAddEventFormTypeRequest } from '../../features/Calendar/AddEventPopup/types'
const fetcher = new Fetcher({})

export const getCalendarEventList = async (params: TQueryCalendarListParams) =>
  fetcher.request<unknown, ResponseWithPagination<TCalendarEventListType>>({
    url: `${API_DICTIONARY.CALENDARS}${API_DICTIONARY.SCHEDULE}`,
    method: HTTP_METHODS.GET,
    params
  })

export const getCalendarEventDetails = async (id: string) =>
  fetcher.request<unknown, TCalendarEvent>({
    url: `${API_DICTIONARY.CALENDARS}${API_DICTIONARY.SCHEDULE}${id}/`,
    method: HTTP_METHODS.GET
  })

export const getOptionsTypeList = async () =>
  fetcher.request<unknown, ResponseWithPagination<TOptionTypeEvent>>({
    url: `${API_DICTIONARY.CALENDARS}${API_DICTIONARY.OPTIONS}${API_DICTIONARY.EVENT_TYPE}`,
    method: HTTP_METHODS.GET
  })

export const createCalendarEvent = async (data: TAddEventFormTypeRequest) =>
  fetcher.request<TAddEventFormTypeRequest, TCalendarEventDetails>({
    url: `${API_DICTIONARY.CALENDARS}${API_DICTIONARY.SCHEDULE}`,
    method: HTTP_METHODS.POST,
    data: data
  })
export const editCalendarEvent = async (
  id: string,
  data: TAddEventFormTypeRequest
) =>
  fetcher.request<TAddEventFormTypeRequest, TCalendarEventDetails>({
    url: `${API_DICTIONARY.CALENDARS}${API_DICTIONARY.SCHEDULE}${id}/`,
    method: HTTP_METHODS.PUT,
    data
  })

export const deleteCalendarEvent = async (
  id: string,
  notify_participants: boolean
) =>
  fetcher.request<unknown, TCalendarEvent>({
    url: `${API_DICTIONARY.CALENDARS}${API_DICTIONARY.SCHEDULE}${id}/cancel/`,
    method: HTTP_METHODS.POST,
    data: { notify_participants }
  })

export const respondToTheEvent = async (
  id: string,
  status: RespondToTheMeeting
) =>
  fetcher.request<unknown, TCalendarEvent>({
    url: `${API_DICTIONARY.CALENDARS}${API_DICTIONARY.SCHEDULE}${id}/${API_DICTIONARY.RESPOND}`,
    method: HTTP_METHODS.POST,
    data: {
      respond: status
    }
  })
export const getCalendarEventAvailableParticipants = async (params) =>
  fetcher.request<unknown, ResponseWithPagination<any>>({
    url: `${API_DICTIONARY.CALENDARS}${API_DICTIONARY.OPTIONS}${API_DICTIONARY.AVAILABLE_PARTICIPANTS}`,
    method: HTTP_METHODS.GET,
    params
  })
