import { useMemo } from 'react'
import '../../ContractDetails/ContractDetailsGeneral/ResponsibleUsers/UserList/styles.scss'
import { useCPRDetailsContext } from '../Providers/CPRDetailsContextProvider'
import { UserListItem } from './UserListItem'

const UserList = () => {
  const contractDetailsContext = useCPRDetailsContext()

  const { details } = contractDetailsContext.state

  const responsibleUsers = useMemo(() => {
    if (details.responsible) {
      return [details?.responsible]
    } else return []
  }, [details?.responsible])

  return (
    <div className="rfp-details-responsible__user-list">
      {responsibleUsers.map((user) => (
        <UserListItem key={user.uuid} user={user} />
      ))}
    </div>
  )
}

export default UserList
