import { FC } from 'react'
import cn from 'classnames'
import Typography from '../Typography/Typography'
import { ReactComponent as ErrorIcon } from 'assets/svg/ErrorIcon.svg'
import { BannerProps } from './types'
import './banner.errorWithTitle.scss'

export const ErrorWithTitle: FC<BannerProps> = ({ text, title, className }) => (
  <div className={cn('error-banner-with-title row gap-12', className)}>
    <div className="error-banner-with-title__icon row align-center">
      <ErrorIcon />
    </div>
    <div className="column gap-8">
      {title && <Typography.Body2>{title}</Typography.Body2>}
      <Typography.Caption>{text}</Typography.Caption>
    </div>
  </div>
)
