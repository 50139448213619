import { ColumnsType } from 'antd/es/table'
import { SortOrder } from 'antd/es/table/interface'
import { Dropdown } from 'components/Dropdown'
import { Sorter } from 'components/Sorter/Sorter'
import { fetchHealthSystemsList } from 'features/HealthSystems/api'
import { fetchContractCategories } from 'pages/CRUDUser/api'
import { ReactComponent as Filter16 } from 'assets/svg/Filter16.svg'
import { ReactComponent as FilterApplied16 } from 'assets/svg/FilterApplied16.svg'
import {
  TRFPItemListData,
  TRFPItemListFilters,
  TRFPItemListFiltersFunc,
  TRFPItemListColumnsFunc
} from './types'
import { getVendors } from '../../../pages/Financials/api'

export const columns = ({
  applyFilters,
  filters,
  community
}: TRFPItemListColumnsFunc): ColumnsType<TRFPItemListData> => [
  {
    dataIndex: 'unspsc_code',
    key: 'unspsc_code',
    title: ({ sortOrder, sortColumn }) => (
      <Sorter<TRFPItemListData>
        dataIndex="unspsc_code"
        sortColumn={sortColumn}
        sortOrder={sortOrder as SortOrder}
      >
        UNSPSC
      </Sorter>
    ),
    sorter: true,
    showSorterTooltip: false
  },
  {
    dataIndex: 'vendor',
    key: 'vendor',
    title: ({ sortOrder, sortColumn }) => (
      <Sorter<TRFPItemListData>
        dataIndex="vendor"
        sortColumn={sortColumn}
        sortOrder={sortOrder as SortOrder}
      >
        Vendor
      </Sorter>
    ),
    filterDropdown: (props) => (
      <Dropdown.Filter<TRFPItemListFilters, TRFPItemListFiltersFunc, unknown>
        applyFilters={applyFilters}
        appliedFilters={filters}
        searchCallback={getVendors}
        asyncSearch
        field="vendors"
        {...props}
      />
    ),
    render: (_, { vendor }) => vendor.name,
    filterIcon: !!filters?.vendors?.length ? <FilterApplied16 /> : <Filter16 />,
    filterSearch: true,
    sorter: true,
    showSorterTooltip: false
  },
  {
    dataIndex: 'vendor_item_number',
    key: 'vendor_item_number',
    title: ({ sortOrder, sortColumn }) => (
      <Sorter<TRFPItemListData>
        dataIndex="vendor_item_number"
        sortColumn={sortColumn}
        sortOrder={sortOrder as SortOrder}
      >
        Vendor item number
      </Sorter>
    ),
    sorter: true,
    showSorterTooltip: false
  },
  {
    dataIndex: 'description',
    key: 'description',
    title: ({ sortOrder, sortColumn }) => (
      <Sorter<TRFPItemListData>
        dataIndex="description"
        sortColumn={sortColumn}
        sortOrder={sortOrder as SortOrder}
      >
        Description
      </Sorter>
    ),
    sorter: true,
    showSorterTooltip: false
  },
  {
    dataIndex: 'contract_category',
    key: 'contract_category',
    title: ({ sortOrder, sortColumn }) => (
      <Sorter<TRFPItemListData>
        dataIndex="contract_category"
        sortColumn={sortColumn}
        sortOrder={sortOrder as SortOrder}
      >
        Category
      </Sorter>
    ),
    filterDropdown: (props) => (
      <Dropdown.Filter<TRFPItemListFilters, TRFPItemListFiltersFunc, unknown>
        applyFilters={applyFilters}
        appliedFilters={filters}
        searchCallback={fetchContractCategories}
        field="contract_categories"
        asyncSearch
        {...props}
      />
    ),
    filterIcon: !!filters?.contract_categories?.length ? (
      <FilterApplied16 />
    ) : (
      <Filter16 />
    ),
    render: (_, { contract_category }) => contract_category.name,
    sorter: true,
    showSorterTooltip: false
  },
  {
    dataIndex: 'health_system',
    key: 'health_system',
    title: ({ sortOrder, sortColumn }) => (
      <Sorter<TRFPItemListData>
        dataIndex="health_system"
        sortColumn={sortColumn}
        sortOrder={sortOrder as SortOrder}
      >
        Health system
      </Sorter>
    ),
    filterDropdown: (props) => (
      <Dropdown.Filter<TRFPItemListFilters, TRFPItemListFiltersFunc, unknown>
        applyFilters={applyFilters}
        appliedFilters={filters}
        searchCallback={() => fetchHealthSystemsList(community)}
        field="health_systems"
        asyncSearch
        {...props}
      />
    ),
    render: (_, { health_system }) => health_system?.name,
    filterIcon: !!filters?.health_systems?.length ? (
      <FilterApplied16 />
    ) : (
      <Filter16 />
    ),
    filterSearch: true,
    sorter: true,
    showSorterTooltip: false
  },
  {
    dataIndex: 'hospital',
    key: 'hospital',
    title: ({ sortOrder, sortColumn }) => (
      <Sorter<TRFPItemListData>
        dataIndex="hospital"
        sortColumn={sortColumn}
        sortOrder={sortOrder as SortOrder}
      >
        Hospital
      </Sorter>
    ),
    render: (_, { hospital }) => hospital?.name,
    sorter: true,
    showSorterTooltip: false
  },
  {
    dataIndex: 'quantity',
    key: 'quantity',
    title: ({ sortOrder, sortColumn }) => (
      <Sorter<TRFPItemListData>
        dataIndex="quantity"
        sortColumn={sortColumn}
        sortOrder={sortOrder as SortOrder}
      >
        Usage
      </Sorter>
    ),
    sorter: true,
    showSorterTooltip: false
  },
  {
    dataIndex: 'units',
    key: 'units',
    title: ({ sortOrder, sortColumn }) => (
      <Sorter<TRFPItemListData>
        dataIndex="units"
        sortColumn={sortColumn}
        sortOrder={sortOrder as SortOrder}
      >
        UoM
      </Sorter>
    ),
    sorter: true,
    showSorterTooltip: false
  },
  {
    dataIndex: 'price',
    key: 'price',
    title: ({ sortOrder, sortColumn }) => (
      <Sorter<TRFPItemListData>
        dataIndex="price"
        sortColumn={sortColumn}
        sortOrder={sortOrder as SortOrder}
      >
        Cost
      </Sorter>
    ),
    sorter: true,
    showSorterTooltip: false
  }
]
