import { TVendorDetailsWithContractAdmin } from 'features/VendorDetails/types'

export const BREADCRUMBS = (title: string) => [
  {
    children: 'Vendors',
    href: '/vendors'
  },
  {
    children: title
  }
]

export const CREATION_ROUTE = 'create'

export const INITIAL_VENDOR_DETAILS: TVendorDetailsWithContractAdmin = {
  contract_admin: {
    email: '',
    role: '',
    status: '',
    first_name: '',
    last_name: '',
    phone_number: '',
    avatar: null
  },
  uuid: '',
  name: '',
  description: '',
  phone_number: '',
  address: {
    state: '',
    city: '',
    street: '',
    zip_code: ''
  },
  contract_steward: {
    email: '',
    role: '',
    status: '',
    first_name: '',
    last_name: '',
    phone_number: '',
    avatar: null
  }
}

export const validateMessages = {
  default: 'Validation error on field ${label}',
  required: '${label} is required',
  whitespace: '${label} cannot be empty',
  types: {
    email: 'Enter a valid ${label}'
  },
  pattern: {
    mismatch: 'Enter a valid ${label}'
  }
}
