import { useCallback, useEffect, useMemo, useState } from 'react'
import { STATUS_SCHEME } from './constants'
import { usePRSDetailsContext } from '../Providers/PRSDetailsContextProvider'
import { usePRSDetailsPopupContext } from '../Providers/PRSDetailsPopupProvider'
import { SendPRSAction } from './index'
import { PRS_STATUSES } from '../../../../pages/ProductRequests/constants'
import DeclinePrs from './DeclinePrs'
import CancelPrs from './CancelPrs'
import SendToClinicalSponsor from './SendToClinicalSponsor'
import RunNewStep from './RunNewStep'
import ApproveRequestCS from './ApproveRequestCS'
import SendSurvey from './SendSurvey'
import { getFacilityUsers } from '../../../../pages/ProductRequests/api'
import { ROLES } from '../../../Permission'
import { ACTIONS, SUBJECTS } from '../../Abilities'
import { subject } from '@casl/ability'
import { useParams } from 'react-router-dom'
import { usePrsDetailsAbility } from '../Providers/PrsDetailsAbilityProvider'
import RequestTrial from './RequestTrial'
import CompleteVoting from './CompleteVoting'
import RespondPrs from './RespondPrs'
import RcaApproveDecline from './RcaApproveDecline'
import ConfirmTrial from './ConfirmTrial'
import CompleteTrial from './CompleteTrial'
import SendToVendor from './SendToVendor'
import StartSurvey from './StartSurvey'
import StartRevenueAssessment from './StartRevenueAssessment'
import CreateContract from './CreateContract'

export const Actions = () => {
  const prsDetailsContext = usePRSDetailsContext()
  const { sendPrsPopup } = usePRSDetailsPopupContext()
  const ability = usePrsDetailsAbility()

  const { data, activeTab, isFacilityUser } = prsDetailsContext.state
  const { handleChangePrsStatus } = prsDetailsContext.actions

  const { id } = useParams()

  const [stakeHoldersLength, setStakeHoldersLength] = useState(0)

  const handleChangeAction = useCallback(() => {
    sendPrsPopup.actions.open()

    handleChangePrsStatus(STATUS_SCHEME[data.status].next)
  }, [data.status, handleChangePrsStatus, sendPrsPopup.actions])

  const getAvailableStakeholders = () => {
    getFacilityUsers(id as string, {
      params: {
        limit: 1,
        offset: 0,
        roles: [ROLES.STAKEHOLDER]
      }
    }).then((res) => {
      setStakeHoldersLength(res.data.results?.length)
    })
  }

  useEffect(() => {
    if (
      ability.can(ACTIONS.SEND, subject(SUBJECTS.SURVEY, { ...data })) ||
      (!data?.trial &&
        ability.can(ACTIONS.REQUEST, subject(SUBJECTS.TRIAL, { ...data })))
    )
      getAvailableStakeholders()
  }, [activeTab, ability, data])
  const actionBtnText = useMemo(
    () => STATUS_SCHEME[data.status]?.actionBtnText,
    [data.status]
  )
  const hideActions = useMemo(
    () =>
      data.status === PRS_STATUSES.CANCELED ||
      data.status === PRS_STATUSES.ARCHIVED,
    [data.status]
  )

  const renderStatusAction = useCallback(() => {
    switch (data.status) {
      case PRS_STATUSES.CREATED:
        return <SendPRSAction />
      case PRS_STATUSES.DECLINED:
        return <SendPRSAction />
      default:
        return null
    }
  }, [actionBtnText, data.status, handleChangeAction])

  const canViewRunNewStep = useMemo(() => {
    return (
      !(
        ability.can(ACTIONS.START, subject(SUBJECTS.SURVEY, { ...data })) &&
        (data.trial?.status === PRS_STATUSES.TRIAL_REQUESTED || !data.trial)
      ) &&
      !(
        data.status === PRS_STATUSES.IN_REVIEW &&
        data?.trial?.status === PRS_STATUSES.TRIAL_REQUESTED
      ) &&
      !(
        data.status === PRS_STATUSES.IN_REVIEW &&
        data?.trial?.status === PRS_STATUSES.TRIAL_COMPLETED &&
        !!data.rca_decision
      ) &&
      !(
        data.status === PRS_STATUSES.IN_REVIEW &&
        data.trial?.status === PRS_STATUSES.TRIAL_IN_PROGRESS
      ) &&
      !(
        data.status === PRS_STATUSES.IN_REVIEW &&
        data.trial?.status === PRS_STATUSES.TRIAL_PRE_APPROVAL &&
        !!data.rca_decision
      )
    )
  }, [ability, data])

  return hideActions ? null : (
    <>
      <DeclinePrs />
      {(data.trial?.status === PRS_STATUSES.TRIAL_COMPLETED || !data.trial) && (
        <StartSurvey />
      )}
      <CancelPrs />
      <SendToClinicalSponsor />
      <SendSurvey stakeHoldersLength={stakeHoldersLength} />
      <RequestTrial stakeHoldersLength={stakeHoldersLength} />
      <ConfirmTrial />
      <CompleteTrial />
      {!data.rca_decision && <StartRevenueAssessment />}
      <SendToVendor />
      {canViewRunNewStep && <RunNewStep />}
      <ApproveRequestCS />
      <CreateContract />
      {!data?.trial?.review_decision && isFacilityUser && <RespondPrs />}
      <RcaApproveDecline />
      <CompleteVoting />
      {renderStatusAction()}
    </>
  )
}
