import { useDashboardPopup } from '../Providers/DashboardPopupProvider'
import { Popup } from '../../../components/Popup'
import { TEditSavingsGoalsProps } from './types'
import HistoryList from './HistoryList'
import { useDashboardHistoryContext } from '../Providers/DashboardHistoryContextProvider'
import { useEffect } from 'react'
import './style.scss'

const HistoryGoals = ({ healthSystem }: TEditSavingsGoalsProps) => {
  const { historyGoalsPopup } = useDashboardPopup()
  const { setHealthSystem } = useDashboardHistoryContext().actions
  const { state, actions } = historyGoalsPopup
  const handleClose = () => actions.close()

  useEffect(() => {
    setHealthSystem(healthSystem || '')
  }, [healthSystem])
  return (
    <Popup
      visible={state.visible}
      onCancel={handleClose}
      title={state.title}
      width={528}
      footer={null}
    >
      <HistoryList />
    </Popup>
  )
}

export default HistoryGoals
