export const SETTINGS_TABS = [
  {
    key: '0',
    label: 'Profile'
  },
  {
    key: '1',
    label: 'Community'
  },
  {
    key: '2',
    label: 'Community users'
  },
  {
    key: '3',
    label: 'Health Systems'
  },
  {
    key: '4',
    label: 'Vendor'
  },
  {
    key: '5',
    label: 'Health System'
  }
]
