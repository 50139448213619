import { useCallback } from 'react'
import { useRFPVotingContext } from 'features/RFP/RFPDetails/Providers/RFPVotingContextProvider'
import PriceFileContent from 'features/RFP/RFPVoting/PriceFileContent/PriceFileContent'
import SurveyContent from 'features/RFP/RFPVoting/SurveyContent/SurveyContent'

import './styles.scss'

const RFPVotingContent = () => {
  const rfpVotingContext = useRFPVotingContext()

  const { headerOptions, activeHeaderOptionValue } = rfpVotingContext.state

  const renderContent = useCallback(() => {
    switch (activeHeaderOptionValue) {
      case headerOptions[0].value:
        return <PriceFileContent />
      case headerOptions[1].value:
        return <SurveyContent />
      default:
        return null
    }
  }, [activeHeaderOptionValue, headerOptions])

  return <div className="rfp-voting-content">{renderContent()}</div>
}

export default RFPVotingContent
