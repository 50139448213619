import cn from 'classnames'
import { ExternalLinkProps } from './types'

export const External = ({
  to,
  children,
  className,
  ...props
}: ExternalLinkProps) => (
  <a href={to} className={cn('custom-link', className)} {...props}>
    {children}
  </a>
)
