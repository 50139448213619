import { useCalendarPopup } from '../../../pages/Calendar/Provider/CalendarPopupProvider'
import Popup from '../../../components/Popup/Popup'
import { Button, BUTTON_TYPES } from '../../../components/Button'
import { BTN_TXT } from '../../../constants'
import { Typography, TYPOGRAPHY_WEIGHT } from '../../../components/Typography'
import { TNotifyUsersModal } from './types'

const NotifyUsersModal = ({ editEvent }: TNotifyUsersModal) => {
  const { notifyUsersPopup } = useCalendarPopup()
  const acceptNotification = () => {
    editEvent(true)
    notifyUsersPopup.actions.close()
  }
  const rejectNotification = () => {
    editEvent(false)
    notifyUsersPopup.actions.close()
  }

  return (
    <Popup
      visible={notifyUsersPopup.state.visible}
      onCancel={rejectNotification}
      title={notifyUsersPopup.state.title}
      width={442}
      footer={
        <>
          <Button
            onClick={rejectNotification}
            type={BUTTON_TYPES.DEFAULT}
            upperCase
          >
            {BTN_TXT.CANCEL}
          </Button>
          <Button
            type={BUTTON_TYPES.PRIMARY}
            upperCase
            onClick={acceptNotification}
          >
            {BTN_TXT.SEND}
          </Button>
        </>
      }
    >
      <Typography.Headline6 weight={TYPOGRAPHY_WEIGHT.NORMAL}>
        The email message with changes will be sent to guest emails.
      </Typography.Headline6>
    </Popup>
  )
}

export default NotifyUsersModal
