import { useMemo } from 'react'
import '../UserList/styles.scss'
import { useRFPDetailsContext } from 'features/RFP/RFPDetails/Providers/RFPDetailsContextProvider'
import { TUserListLedCentralProps } from './types'
import { UserListItemLedCentral } from './UserListItemLedCentral'

const UserListLedCentral = ({ isCommons }: TUserListLedCentralProps) => {
  const rfpDetailsContext = useRFPDetailsContext()

  const { data } = rfpDetailsContext.state

  const responsibleUsersToDisplay = useMemo(() => {
    if (isCommons) return data.commons_responsibles
    else {
      return data.creator?.role.includes('community')
        ? [data.creator, ...data.community_responsibles]
        : data.community_responsibles
    }
  }, [data, isCommons])

  return (
    <div className="rfp-details-responsible__user-list">
      {responsibleUsersToDisplay.map((user) => (
        <UserListItemLedCentral key={user.uuid} user={user} />
      ))}
    </div>
  )
}

export default UserListLedCentral
