import { State } from '../../type'
import { moduleName } from './constants'
import { MyCommunityState } from './types'

const getState = (state: State): MyCommunityState => state[moduleName]

export const getAllowedRolesList = (state: State) => {
  const stateSlice = getState(state)
  return stateSlice.allowedUsers
}
