import { FC } from 'react'
import cn from 'classnames'
import moment from 'moment'
import { Datepicker } from '../../../Datepicker'
import { DateCellProps } from './types'
import { timelineDateFormat } from '../../../Timelines/constants'
import './styles.scss'

export const DateCell: FC<DateCellProps> = ({ error, value, onChange }) =>
  error ? (
    <Datepicker.DefaultNoForm
      propsDate={{
        value: value && moment(value).isValid() ? moment(value) : undefined,
        placeholder: timelineDateFormat,
        getPopupContainer: () => document.body,
        placement: 'bottomLeft',
        onChange: (val) => onChange(val ? val.format('MM/DD/YYYY') : ''),
        className: cn('table-date-cell', {
          'table-date-cell--with-error': error
        })
      }}
    />
  ) : (
    <>{value ? moment(value).format(timelineDateFormat) : '-'}</>
  )
