import { ITimelineSettings } from 'components/Timelines/types'
import {
  PRS_STATUSES,
  PRS_STATUSES_NAME,
  PRS_STATUSES_TIMERS
} from '../../../pages/ProductRequests/constants'
import { TTimelinesStatues } from './types'

export const systemPrsTimelinesSettings: ITimelineSettings<TTimelinesStatues>[] =
  [
    {
      key: PRS_STATUSES_TIMERS.OVERALL,
      title: 'Overall timer',
      diffDays: 60
    },
    {
      key: PRS_STATUSES.CLINICAL_SPONSOR_APPROVAL,
      title: PRS_STATUSES_NAME[PRS_STATUSES.CLINICAL_SPONSOR_APPROVAL],
      diffDays: 0
    },
    {
      key: PRS_STATUSES.REVENUE_ASSESSMENT,
      title: PRS_STATUSES_NAME[PRS_STATUSES.REVENUE_ASSESSMENT],
      diffDays: 0
    },
    {
      key: PRS_STATUSES.TRIAL_PRE_APPROVAL,
      title: PRS_STATUSES_NAME[PRS_STATUSES.TRIAL_PRE_APPROVAL],
      diffDays: 0
    },
    {
      key: PRS_STATUSES.TRIAL_REQUESTED,
      title: PRS_STATUSES_NAME[PRS_STATUSES.TRIAL_REQUESTED],
      diffDays: 0
    },
    {
      key: PRS_STATUSES.TRIAL_IN_PROGRESS,
      title: PRS_STATUSES_NAME[PRS_STATUSES.TRIAL_IN_PROGRESS],
      diffDays: 0
    },
    {
      key: PRS_STATUSES.SURVEY_CREATION,
      title: PRS_STATUSES_NAME[PRS_STATUSES.SURVEY_CREATION],
      diffDays: 0
    },
    {
      key: PRS_STATUSES.VOTING_IN_PROGRESS,
      title: PRS_STATUSES_NAME[PRS_STATUSES.VOTING_IN_PROGRESS],
      diffDays: 0
    }
  ]
