import { ModalProps } from 'antd'

import { Popup } from 'components/Popup'

type Props = Partial<ModalProps>

const VersionHistoryPopup = ({ children, ...rest }: Props) => {
  return (
    <Popup width={442} footer={null} {...rest}>
      {children}
    </Popup>
  )
}

export default VersionHistoryPopup
