import Avatar from 'antd/lib/avatar'
import { BUTTON_TYPES } from 'components/Button/constants'
import Typography from 'components/Typography/Typography'
import { IconFullName } from './IconFullName'
import { TUser } from './types'
import Button from 'components/Button/Button'
import { useState } from 'react'
import Popup from 'components/Popup/Popup'

export const HSUsersListByRole = ({
  users,
  roleName
}: {
  users?: (TUser | undefined)[]
  roleName: string
}) => {
  const [isOpen, setIsOpen] = useState(false)
  const openUsersList = () => {
    setIsOpen(true)
  }
  return (
    <>
      {!!users && (
        <div className="hs-details_users-list">
          <Typography.Label>{roleName}</Typography.Label>
          {users.slice(0, 4)?.map((user, idx) => {
            if (idx === 4) return
            return (
              <IconFullName
                key={user?.uuid || idx}
                firstName={user?.first_name}
                lastName={user?.last_name}
                src={user?.avatar?.file_preview}
                uuid={user?.uuid}
              />
            )
          })}
          <div className="hs-details_avatar-group_container">
            {users?.length > 4 && (
              <>
                <Avatar.Group>
                  {users.slice(4, 8)?.map((user, idx) => {
                    if (idx === 8) return
                    return (
                      <Avatar
                        key={user?.uuid || idx}
                        alt="user image"
                        src={
                          user?.avatar?.file_preview || user?.small_image_url
                        }
                      >
                        {`${user?.first_name?.charAt(
                          0
                        )}${user?.last_name?.charAt(0)}`.toUpperCase()}
                      </Avatar>
                    )
                  })}
                </Avatar.Group>
                <Button
                  type={BUTTON_TYPES.LINK}
                  className="hs-details_more-icon"
                  onClick={openUsersList}
                >
                  + {users?.length - 4} more
                </Button>
              </>
            )}
          </div>
        </div>
      )}
      {!!users?.length && (
        <Popup
          visible={isOpen}
          onCancel={() => setIsOpen(false)}
          footer={<></>}
          className="hs-details_users-list_popup-container"
          title={
            <div>
              {`${roleName} list`}{' '}
              <span className="hs-details_count-gray">({users?.length})</span>
            </div>
          }
          width={910}
        >
          <div className="hs-details_users-list_popup">
            {users.map((user) => (
              <IconFullName
                firstName={user?.first_name}
                lastName={user?.last_name}
                src={user?.small_image_url || user?.avatar?.file_preview}
                uuid={user?.uuid}
                key={user?.uuid}
              />
            ))}
          </div>
        </Popup>
      )}
    </>
  )
}
