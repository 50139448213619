import { Button, BUTTON_TYPES } from 'components/Button'
import { Typography } from 'components/Typography'
import { Popup } from 'components/Popup'
import { useRFPDetailsPopupContext } from 'features/RFP/RFPDetails/Providers/RFPDetailsPopupProvider'
import { useRFPDetailsContext } from 'features/RFP/RFPDetails/Providers/RFPDetailsContextProvider'
import { BTN_TXT } from 'constants/txt'
import { RFP_ACCEPTANCE_STATUSES } from 'features/RFP/constants'

export const ChangeRFPResponsePopup = ({
  statusChangeTo
}: {
  statusChangeTo: string
}) => {
  const { actions, state } = useRFPDetailsContext()
  const { changeRFPResponsePopup } = useRFPDetailsPopupContext()
  const { biddingData } = state
  const { handleChangeRFPAcceptance } = actions
  const { title, visible, onCancel } = changeRFPResponsePopup.state
  const { close } = changeRFPResponsePopup.actions

  const acceptStatus = statusChangeTo !== RFP_ACCEPTANCE_STATUSES.ACCEPTED

  const currentStatus =
    biddingData.acceptance_status === RFP_ACCEPTANCE_STATUSES.ACCEPTED

  return (
    <Popup
      visible={visible}
      onCancel={onCancel}
      title={title}
      width={442}
      footer={
        <>
          <Button type={BUTTON_TYPES.DEFAULT} onClick={close} upperCase>
            {BTN_TXT.CANCEL}
          </Button>
          <Button
            type={BUTTON_TYPES.PRIMARY}
            onClick={() =>
              handleChangeRFPAcceptance(
                statusChangeTo === RFP_ACCEPTANCE_STATUSES.ACCEPTED
                  ? RFP_ACCEPTANCE_STATUSES.ACCEPTED
                  : RFP_ACCEPTANCE_STATUSES.DECLINED
              )
            }
            danger={acceptStatus}
            upperCase
          >
            {acceptStatus ? BTN_TXT.DECLINE_RFP : BTN_TXT.ACCEPT_RFP}
          </Button>
        </>
      }
    >
      <Typography.Body1>
        The RFP has been previously {currentStatus ? 'Accepted' : 'Declined'}.
        <br />
        Do you want to {acceptStatus ? 'Decline' : 'Accept'} it?
      </Typography.Body1>
    </Popup>
  )
}
