import Fetcher from '../../services/Fetcher'
import { API_DICTIONARY, HTTP_METHODS } from '../../constants'
import {
  TRequestFAHsListRequest,
  TRequestFAHsListResponse,
  TRequestSupportInfoRequest,
  TRequestSupportInfoResponse,
  TSavingRequest,
  TSavingResponse,
  TSavingsSetDataRequest
} from '../../features/RequestForAnalysisDetails/types'

const fetcher = new Fetcher({})

export const getHealthSystemDetails: TRequestFAHsListRequest = async (
  uuid: string,
  data
) =>
  fetcher.request<unknown, TRequestFAHsListResponse>({
    url: `${API_DICTIONARY.ANALYSIS}${API_DICTIONARY.HEALTH_SYSTEM}${uuid}/${API_DICTIONARY.CONTRACT_GROUPS}`,
    method: HTTP_METHODS.GET,
    ...data
  })

export const getSupportInformationDetails: TRequestSupportInfoRequest = async (
  uuid: string
) =>
  fetcher.request<unknown, TRequestSupportInfoResponse>({
    url: `${API_DICTIONARY.ANALYSIS}${API_DICTIONARY.HEALTH_SYSTEM}${uuid}/${API_DICTIONARY.HEADER}`,
    method: HTTP_METHODS.GET
  })

export const getSavingsDetails: TSavingRequest = async (uuid: string, data) =>
  fetcher.request<unknown, TSavingResponse>({
    url: `${API_DICTIONARY.ANALYSIS}${API_DICTIONARY.HEALTH_SYSTEM}${uuid}/${API_DICTIONARY.SAVINGS}`,
    method: HTTP_METHODS.GET,
    ...data
  })

export const setSavingsDataRequest = async (
  uuid: string,
  group_uuid: string,
  data: TSavingsSetDataRequest
) =>
  fetcher.request<TSavingsSetDataRequest, TSavingResponse>({
    url: `${API_DICTIONARY.ANALYSIS}${API_DICTIONARY.HEALTH_SYSTEM}${uuid}/${API_DICTIONARY.CONTRACT_GROUPS}${group_uuid}/`,
    method: HTTP_METHODS.PATCH,
    data
  })
