import Typography from 'components/Typography/Typography'
import { Input } from 'components/Input'
import { PLACEHOLDERS } from 'constants/txt'
import { ReactComponent as Close16 } from 'assets/svg/Clos16.svg'
import { ReactComponent as Search16 } from 'assets/svg/Search16.svg'
import { useEffect, useRef, useState } from 'react'
import { Table } from 'components/Table'
import { columns } from './constants'
import { isTableHasParams } from 'helper/common'
import {
  TTrialApprovalUsersData,
  TTrialApprovalUsersFilters,
  TTrialApprovalUsersFiltersFunc,
  TTrialApprovalUsersParams,
  TTrialUsersTableRequestData
} from './types'
import { useDispatch, useSelector } from 'react-redux'
import { getUser } from 'redux/store/user/getters'
import { DEFAULT_PAGE } from 'components/Table/constants'
import { useParams } from 'react-router-dom'
import axios, { CancelTokenSource } from 'axios'
import { useDebounce } from 'hooks/hooks'
import { setLoading } from 'redux/store/common/slice'
import PreApprovalChart from './PreApprovalChart'
import './style.scss'
import { getTrialStatisticsUsers } from 'pages/ProductRequests/api'

const TrialPreApproval = () => {
  const user = useSelector(getUser)
  const { id } = useParams()
  const dispatch = useDispatch()

  const [searchValue, setSearchValue] = useState('')
  const [tableData, setTableData] = useState<TTrialApprovalUsersData[]>([])
  const [pageInfo, setPageInfo] = useState({ ...DEFAULT_PAGE })
  const [firstLoad, setFirstLoad] = useState(true)
  const [filters, setFilters] = useState<TTrialApprovalUsersFilters>({})
  const [sortParams, setSortParams] = useState<TTrialUsersTableRequestData>()
  const [totalNumber, setTotalNumber] = useState(0)

  const tableDataTokenRef = useRef<CancelTokenSource>()
  const timeoutRef = useRef<ReturnType<typeof setTimeout>>()

  const debouncedSearch = useDebounce(searchValue, 500)

  useEffect(() => {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current)
    }
    timeoutRef.current = setTimeout(
      () => {
        getTableData({ ...sortParams, ...filters, page: { ...DEFAULT_PAGE } })
        setPageInfo({ ...DEFAULT_PAGE })
      },
      firstLoad ? 0 : 500
    )
  }, [debouncedSearch])

  useEffect(() => {
    return () => {
      if (!!tableDataTokenRef.current) {
        tableDataTokenRef.current.cancel()
      }
    }
  }, [])

  const getTableData = ({
    sortField,
    sortOrder,
    filters,
    page
  }: TTrialUsersTableRequestData) => {
    dispatch(setLoading(true))
    dispatch(setLoading(false))
    if (firstLoad) {
      setFirstLoad(false)
    }
    if (!!tableDataTokenRef.current) {
      tableDataTokenRef.current.cancel()
    }
    const params: TTrialApprovalUsersParams = { ...filters }
    if (sortField) {
      if (sortOrder) {
        params.ordering = `${sortOrder === 'descend' ? '-' : ''}${sortField}`
      }
      setSortParams({
        sortField,
        sortOrder
      })
    }
    if (searchValue) {
      params.search = searchValue
    }
    const dataPage = page ? page : pageInfo
    params.limit = dataPage.pageSize
    params.offset = (dataPage.pageNumber - 1) * dataPage.pageSize
    tableDataTokenRef.current = axios.CancelToken.source()
    getTrialStatisticsUsers(id as string, {
      cancelToken: tableDataTokenRef.current?.token,
      params
    })
      .then((resp: any) => {
        if (!resp.data?.results) {
          return
        }
        setTableData(
          resp.data.results.map((i) => ({
            ...i,
            key: i.uuid
          }))
        )
        setTotalNumber(resp.data.count)
      })
      .finally(() => dispatch(setLoading(false)))
  }

  const applyFilters: TTrialApprovalUsersFiltersFunc = (
    field,
    appliedFilters
  ) => {
    const newFilters = { ...filters, [field]: appliedFilters }
    setFilters(newFilters)
    getTableData({ ...sortParams, filters: newFilters })
  }
  useEffect(() => {
    setLoading(false)
  }, [tableData[0]?.uuid as string])

  const handleTableChange = (pagination, _filters, sorter) => {
    const page = {
      pageNumber: pagination.current,
      pageSize: pagination.pageSize
    }
    getTableData({
      sortField: sorter.field,
      sortOrder: sorter.order,
      filters,
      page
    })
    setPageInfo(page)
  }

  const handleChangePageSize = (pageSize) => {
    const page = {
      pageNumber: 1,
      pageSize
    }
    getTableData({
      ...sortParams,
      filters,
      page
    })
    setPageInfo(page)
  }

  return (
    <div className="trial-pre-approval-container">
      <PreApprovalChart />
      <Typography.Headline6 className="mt-24">
        Responses details
      </Typography.Headline6>
      <div className="table-wrapper">
        <div className="table-wrapper__header">
          <Input
            className="allow-clear mt-16"
            propsInput={{
              allowClear: { clearIcon: <Close16 /> },
              className: 'rfp-hs-users_search',
              placeholder: PLACEHOLDERS.SEARCH,
              prefix: <Search16 />,
              value: searchValue,
              onChange: (e) => setSearchValue(e.target.value)
            }}
          />
        </div>
        <Table<TTrialApprovalUsersData>
          dataSource={tableData}
          columns={columns({
            applyFilters,
            filters,
            community: user?.community || ''
          })}
          pageSize={pageInfo.pageSize}
          pagination={{
            pageSize: pageInfo.pageSize,
            current: pageInfo.pageNumber,
            total: totalNumber
          }}
          className="rfp-vendors-data-list-table"
          onChangePage={handleChangePageSize}
          onChange={handleTableChange}
          hasSearchOrFilters={isTableHasParams<TTrialApprovalUsersFilters>(
            debouncedSearch,
            filters
          )}
        />
      </div>
    </div>
  )
}

export default TrialPreApproval
