import { useCallback, useEffect, useState } from 'react'
import { TOption } from 'components/Select/types'
import {
  getCheckInsFilterOptionsDepartments,
  getCheckInsFilterOptionsHealthSystems,
  getCheckInsFilterOptionsHospitals,
  getCheckInsFilterOptionsVendorOrgName,
  getCheckInsFilterOptionsVisitorName
} from '../../../api'
import { TCheckInsFilters } from 'redux/store/checkIns/types'
import { useCheckInsListContext } from '../../../Providers/CheckInsContextProvider'
import { useCheckInsPopup } from '../../../Providers/CheckInsPopupProvider'

export const useFilters = () => {
  const [hsOptions, setHsOptions] = useState<TOption[]>([])
  const [hospitalsOptions, setHospitalsOptions] = useState<TOption[]>([])
  const [departmentsOptions, setDepartmentsOptions] = useState<TOption[]>([])
  const [vendorOrgNameOptions, setVendorOrgNameOptions] = useState<TOption[]>(
    []
  )
  const [visitorNameOptions, setVisitorNameOptions] = useState<TOption[]>([])

  const { state: listState } = useCheckInsListContext()
  const { filters } = listState

  const [modalFilters, setModalFilters] = useState<TCheckInsFilters>(filters)

  const { filtersPopup } = useCheckInsPopup()

  const { state: filtersPopupState } = filtersPopup

  useEffect(() => {
    setModalFilters(filters)
  }, [filters, filtersPopupState.visible])

  const mapResults = (data) => {
    return data.results.map((r) => {
      return {
        label: r.name,
        value: r.uuid
      }
    })
  }

  const getHospitalsOptions = useCallback(async () => {
    const { data } = await getCheckInsFilterOptionsHospitals()
    setHospitalsOptions(mapResults(data))
  }, [])

  const getDepartmentsOptions = useCallback(async () => {
    const { data } = await getCheckInsFilterOptionsDepartments()
    setDepartmentsOptions(mapResults(data))
  }, [])

  const getVisitorsOptions = useCallback(async () => {
    const { data } = await getCheckInsFilterOptionsVisitorName()
    setVisitorNameOptions(
      data.results.map((r) => {
        return {
          label: `${r.first_name} ${r.last_name}`,
          value: r.uuid
        }
      })
    )
  }, [])

  const getVendorOrgOptions = useCallback(async () => {
    const { data } = await getCheckInsFilterOptionsVendorOrgName()
    setVendorOrgNameOptions(mapResults(data))
  }, [])

  const getHSOptions = useCallback(async () => {
    const { data } = await getCheckInsFilterOptionsHealthSystems()
    setHsOptions(mapResults(data))
  }, [])

  useEffect(() => {
    getHospitalsOptions()
    getDepartmentsOptions()
    getVisitorsOptions()
    getVendorOrgOptions()
    getHSOptions()
  }, [])

  return {
    hsOptions,
    hospitalsOptions,
    departmentsOptions,
    vendorOrgNameOptions,
    visitorNameOptions,
    modalFilters,
    setModalFilters
  }
}
