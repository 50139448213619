import {
  FC,
  createContext,
  PropsWithChildren,
  useContext,
  useMemo
} from 'react'

import usePopup, { PopupReturnType } from 'hooks/usePopup'

type ContextProps = {
  addPipelinePopup: PopupReturnType
}

const basePopup = (title: string) => ({
  title
})

const ContractPopupContext = createContext<ContextProps>({
  addPipelinePopup: null!
})

const ContractPagePopupProvider: FC<PropsWithChildren> = ({ children }) => {
  const addPipelinePopupProps = usePopup(basePopup('Create pipeline record'))

  const context = useMemo(
    () => ({
      addPipelinePopup: addPipelinePopupProps
    }),
    [addPipelinePopupProps]
  )

  return (
    <ContractPopupContext.Provider value={context}>
      {children}
    </ContractPopupContext.Provider>
  )
}

export const useContractPagePopup = () => useContext(ContractPopupContext)

export default ContractPagePopupProvider
