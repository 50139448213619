import Fetcher from 'services/Fetcher'
import { API_DICTIONARY, HTTP_METHODS } from 'constants/api'
import {
  IVendorDetails,
  TParams,
  TVendorDetailsWithContractAdmin,
  VendorWithAdminCreationRequestData
} from 'features/VendorDetails/types'

const fetcher = new Fetcher({})

export const getVendorDetailsRequestAsync = async (id: string) =>
  fetcher.request<unknown, TVendorDetailsWithContractAdmin>({
    url: `${API_DICTIONARY.VENDORS}${id}/for_community/`,
    method: HTTP_METHODS.GET
  })

export const createVendorWithAdminRequestAsync = async (
  data: VendorWithAdminCreationRequestData
) =>
  fetcher.request<VendorWithAdminCreationRequestData, IVendorDetails>({
    url: `${API_DICTIONARY.VENDORS}`,
    method: HTTP_METHODS.POST,
    data
  })
export const checkVendorName = async (params: TParams) =>
  fetcher.request<unknown, string[]>({
    url: `${API_DICTIONARY.VENDORS}${API_DICTIONARY.SEARCH}`,
    method: HTTP_METHODS.GET,
    params
  })
