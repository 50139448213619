import { contractPipelineDetailsRoute } from '../ContractsPipelineDetails/ContractPipelineDetailsRouter/routes'
import { CONTRACT_PIPELINES_STATUS } from '../../components/Status'

export const CONTRACT_PIPELINE_DETAILS_TABS = [
  {
    key: contractPipelineDetailsRoute.general,
    label: 'General'
  },
  {
    key: contractPipelineDetailsRoute.hsList,
    label: 'HS list'
  }
]

export const INITIAL_CPR_DETAILS = {
  uuid: '',
  contract_pipeline_id: '',
  contract_category_id: '',
  joined: false,
  led_by: '',
  portfolio: '',
  contract_category_name: '',
  vendor_name: null,
  bid_start_date: '',
  expiration_date: null,
  estimated_savings: '',
  rebates: false,
  status: CONTRACT_PIPELINES_STATUS.ACTIVE,
  responsible: null,
  rfp: '',
  rfp_name: '',
  creator: null,
  creator_health_system: null
}
