import { Status } from '../../../components/Status'
import {
  RFP_ACCEPTANCE_STATUS_TO_CLASSNAME,
  RFP_ACCEPTANCE_STATUSES,
  RFP_ACCEPTANCE_STATUSES_NAMES,
  RFP_STATUS_TO_CLASSNAME,
  RFP_STATUSES,
  RFP_STATUSES_NAMES
} from '../constants'
import { useRFPDetailsContext } from './Providers/RFPDetailsContextProvider'
import cn from 'classnames'
import './styles.scss'

export const RFPDetailsStatus = ({
  vendorStatus,
  rfpStatus
}: {
  rfpStatus?: ValueOfObject<typeof RFP_STATUSES>
  vendorStatus?: ValueOfObject<typeof RFP_ACCEPTANCE_STATUSES>
}) => {
  const { state } = useRFPDetailsContext()
  const { data } = state

  return data.uuid ? (
    <div
      className={cn('row', {
        'two-status': vendorStatus && rfpStatus
      })}
    >
      {rfpStatus && (
        <Status.Default className={RFP_STATUS_TO_CLASSNAME[rfpStatus]}>
          {RFP_STATUSES_NAMES[rfpStatus]}
        </Status.Default>
      )}
      {vendorStatus && (
        <div className="two-status-separator">
          <Status.Default
            className={RFP_ACCEPTANCE_STATUS_TO_CLASSNAME[vendorStatus]}
          >
            {RFP_ACCEPTANCE_STATUSES_NAMES[vendorStatus]}
          </Status.Default>
        </div>
      )}
    </div>
  ) : null
}
