import { useCallback } from 'react'

import useTabs from 'hooks/useTabs'
import { RFP_SURVEY_TABS } from './constants'
import { Menu } from 'components/Menu/Menu'
import { Container } from 'components/Container/Container'
import VendorsSelection from 'features/RFP/RFPSurvey/CreateSurvey/VendorsSelection/VendorsSelection'
import SurveyPreview from 'features/RFP/RFPSurvey/CreateSurvey/SurveyPreview/SurveyPreview'

const CreateSurveyTabs = () => {
  const tabsProps = useTabs({
    tabs: RFP_SURVEY_TABS,
    activeTab: RFP_SURVEY_TABS[0].key
  })

  const { activeTab, tabs } = tabsProps.state
  const { setActiveTab } = tabsProps.actions

  const renderTabsContent = useCallback(() => {
    switch (activeTab) {
      case tabs[0].key:
        return <VendorsSelection />
      case tabs[1].key:
        return <SurveyPreview />
      default:
        return null
    }
  }, [activeTab, tabs])

  return (
    <Container
      className="rfp-create-survey"
      menu={
        <Menu
          mode="horizontal"
          onSelect={(e) => {
            setActiveTab(e.key)
          }}
          defaultSelectedKeys={[activeTab]}
          items={tabs}
        />
      }
    >
      {renderTabsContent()}
    </Container>
  )
}

export default CreateSurveyTabs
