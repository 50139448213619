import { FC, PropsWithChildren, createContext, useContext } from 'react'

import { UseRebateConditionsFormReturnType } from 'features/ContractDetails/RebateConditions/RebateConditionsForm/hooks/useRebateConditionsForm'

type ContextProps = UseRebateConditionsFormReturnType

const RebateConditionsFormContext = createContext<ContextProps>(null!)

const RebateConditionsFormProvider: FC<PropsWithChildren & ContextProps> = (
  props
) => {
  const { children, ...contextValueProps } = props

  return (
    <RebateConditionsFormContext.Provider value={contextValueProps}>
      {children}
    </RebateConditionsFormContext.Provider>
  )
}

export const useRebateConditionsFormContext = () =>
  useContext(RebateConditionsFormContext)

export default RebateConditionsFormProvider
