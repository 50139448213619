import { ReactNode } from 'react'
import { RejectedInvoiceLineError } from '../../types'

export const getTooltipErrorMessage = (errors: RejectedInvoiceLineError) => {
  const errorsSet = new Set<string>()
  Object.keys(errors).forEach((field) => {
    errors[field]?.forEach((i) => errorsSet.add(i))
  })
  const errorMessages: string[] = Array.from(errorsSet)
  let message: ReactNode = ''
  if (errorMessages.length === 1) {
    message = errorMessages[0]
  } else if (errorMessages.length > 1) {
    message = (
      <div className="column">
        <span>Multiple issues detected:</span>
        {errorMessages.map((message, idx) => (
          <span>
            {idx + 1}. {message}
          </span>
        ))}
      </div>
    )
  }
  return message
}
