import { useMemo } from 'react'
import { useAbility } from '@casl/react'
import { subject } from '@casl/ability'

import { AttachmentsList } from 'components/AttachmentsList/AttachmentsList'
import { DOCUMENT_TYPE, RFP_FILE_TYPES } from 'components/FileUpload/constants'
import { FileUpload } from 'components/FileUpload/FileUpload'
import { useRFPVotingContext } from 'features/RFP/RFPDetails/Providers/RFPVotingContextProvider'
import { useRFPDetailsContext } from 'features/RFP/RFPDetails/Providers/RFPDetailsContextProvider'
import { AbilityContext, ACTIONS, SUBJECTS } from 'features/Permission'
import { validateFileTypes } from 'helper/common'
import { BTN_TXT } from 'constants/txt'

import './styles.scss'
import { RFP_STATUSES } from 'features/RFP/constants'

const PriceFileContent = () => {
  const ability = useAbility<any>(AbilityContext)

  const rfpVotingContext = useRFPVotingContext()
  const rfpDetailsContext = useRFPDetailsContext()

  const { data } = rfpDetailsContext.state
  const { templateFiles, summaryPriceFiles, isVotingInProgress } =
    rfpVotingContext.state

  const { handleUploadFile, handleDeleteAttachment } = rfpDetailsContext.actions

  const isReadOnly = useMemo(
    () =>
      ability.cannot(
        ACTIONS.UPLOAD,
        subject(SUBJECTS.RFP_DOCUMENTS, { ...data })
      ) ||
      data.status !== RFP_STATUSES.PRICE_FILES_ANALYSIS ||
      isVotingInProgress,
    [ability, data, isVotingInProgress]
  )

  return (
    <div className="price-file-content">
      {isVotingInProgress || (
        <AttachmentsList
          className="price-file-content__attachments"
          label="Template"
          files={templateFiles}
          documentType={DOCUMENT_TYPE.TEMPLATE}
          disableActions
        />
      )}
      <div className="price-file-content__attachments">
        <AttachmentsList
          title="Upload Summary"
          label="Executive summary"
          files={summaryPriceFiles}
          onDelete={handleDeleteAttachment}
          disableActions={isReadOnly}
          hideSeparator
        />
        {isReadOnly || summaryPriceFiles.length > 0 || (
          <FileUpload
            documentType={DOCUMENT_TYPE.EXECUTIVE_SUMMARY}
            uploadBtnText={BTN_TXT.UPLOAD_RFP_SUMMARY}
            handleUploadFile={handleUploadFile}
            uploadFilesProps={{
              multiple: false,
              maxSize: 5242880,
              onDropAccepted: (file) =>
                handleUploadFile(file, DOCUMENT_TYPE.EXECUTIVE_SUMMARY),
              accept: {
                ...RFP_FILE_TYPES
              },
              validator: (file) => validateFileTypes(RFP_FILE_TYPES, file)
            }}
          />
        )}
      </div>
    </div>
  )
}

export default PriceFileContent
