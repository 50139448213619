import { ContractPipelineTable } from './Table/ContractPipelineTable'
import ContractDetailsPagePopupProvider from '../ContractsPipelineDetails/Providers/ContractDetailsPagePopupProvider'

export const ContractPipeline = () => {
  return (
    <ContractDetailsPagePopupProvider>
      <ContractPipelineTable />
    </ContractDetailsPagePopupProvider>
  )
}
