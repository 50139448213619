import Fetcher from 'services/Fetcher'
import { API_DICTIONARY, HTTP_METHODS } from 'constants/api'
import { TLogoutData } from './types'

const fetcher = new Fetcher({})

export const fetchUser = () =>
  fetcher.request({
    url: `${API_DICTIONARY.USERS}${API_DICTIONARY.CURRENT}`,
    method: HTTP_METHODS.GET
  })

export const logout = (data: TLogoutData) =>
  fetcher.request({
    url: `auth/logout/`,
    method: HTTP_METHODS.POST,
    data
  })
