import { useState } from 'react'
import SignPopup from 'features/SignPopup/SignPopup'
import { Popup } from 'components/Popup'
import { Forms } from 'components/Forms'
import './styles.scss'

const SignIn = (): JSX.Element => {
  const [openForgotPopup, setOpenForgotPopup] = useState(false)
  const [email, setEmail] = useState('')
  const emailChange = (e) => {
    setEmail(e.target.value)
  }
  return (
    <SignPopup email={email} footer={true}>
      <>
        <Forms.SignIn
          emailChange={emailChange}
          openForgotPopup={setOpenForgotPopup}
        />
        <Popup
          visible={openForgotPopup}
          className="sign-in__forget-password"
          onCancel={() => setOpenForgotPopup(false)}
          title="Forgot password"
          maskTransitionName="modal-mask-auth-pages"
          width={442}
          footer={null}
        >
          <Forms.ForgotPassword
            setOpenForgotPopup={setOpenForgotPopup}
            emailValue={email}
          />
        </Popup>
      </>
    </SignPopup>
  )
}

export default SignIn
