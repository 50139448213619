import { ACTIONS, SUBJECTS } from '../../Abilities'
import { subject } from '@casl/ability'
import { Button, BUTTON_TYPES } from 'components/Button'
import { BTN_TXT } from 'constants/index'
import { Can } from '../Providers/PrsDetailsAbilityProvider'
import { usePRSDetailsContext } from '../Providers/PRSDetailsContextProvider'
import Popup from 'components/Popup/Popup'
import { Typography, TYPOGRAPHY_WEIGHT } from 'components/Typography'
import { usePRSDetailsPopupContext } from '../Providers/PRSDetailsPopupProvider'
import { setLoading } from 'redux/store/common/slice'
import { patchTrial } from 'pages/ProductRequests/api'
import {
  PRS_STATUSES,
  PRS_STATUSES_MESSAGES
} from 'pages/ProductRequests/constants'
import { notification } from 'components/Notification'
import { useDispatch } from 'react-redux'

const ConfirmTrial = () => {
  const { state, actions } = usePRSDetailsContext()
  const { data } = state
  const { handleGetPRSData } = actions

  const { confirmTrialPopup } = usePRSDetailsPopupContext()
  const { visible } = confirmTrialPopup.state
  const { close, open } = confirmTrialPopup.actions

  const dispatch = useDispatch()

  const onOpen = () => open()

  const onSend = () => {
    dispatch(setLoading(true))
    patchTrial(data.uuid, {
      status: PRS_STATUSES.TRIAL_IN_PROGRESS
    })
      .then(() => {
        handleGetPRSData()
        notification.success({
          message: `${PRS_STATUSES_MESSAGES.trial_in_progress}`
        })
        close()
      })
      .catch((err) => {
        notification.error({
          message: err.data?.details[0]
        })
      })
      .finally(() => {
        dispatch(setLoading(false))
        close()
      })
  }
  return (
    <>
      <Can I={ACTIONS.CONFIRM} a={subject(SUBJECTS.TRIAL, { ...data })}>
        <Button
          type={BUTTON_TYPES.PRIMARY}
          onClick={onOpen}
          className="send-prs-to-clinical-sponsor-btn"
          upperCase
        >
          {BTN_TXT.CONFIRM_TRIAL}
        </Button>
      </Can>
      <Popup
        visible={visible}
        onCancel={close}
        title={`Confirm trial period?`}
        width={442}
        footer={
          <>
            <Button onClick={close} type={BUTTON_TYPES.DEFAULT} upperCase>
              {BTN_TXT.BACK_TO_PAGE}
            </Button>
            <Button type={BUTTON_TYPES.PRIMARY} upperCase onClick={onSend}>
              {BTN_TXT.CONFIRM_TRIAL}
            </Button>
          </>
        }
      >
        <Typography.Headline6 weight={TYPOGRAPHY_WEIGHT.NORMAL}>
          During the trial period the product will be tested. <br /> Do you
          confirm the trial period?
        </Typography.Headline6>
      </Popup>
    </>
  )
}

export default ConfirmTrial
