import { FC, useCallback } from 'react'
import { Form } from 'antd'

import './styles.scss'

import Timelines from 'components/Timelines/Timelines'
import useTimelines, {
  UseTimelinesProps
} from 'components/Timelines/hooks/useTimelines'
import { ITimeline } from 'components/Timelines/types'
import {
  getTimelinesBySettings,
  getTimeOptionsStartFrom
} from 'components/Timelines/utils'
import { RFP_STATUSES } from 'features/RFP/constants'
import { systemRFPTimelinesSettings } from 'features/RFP/RFPTimelines/constants'
import { useRFPDetailsContext } from 'features/RFP/RFPDetails/Providers/RFPDetailsContextProvider'

const systemTimelines = getTimelinesBySettings(systemRFPTimelinesSettings)

type Props = {
  onFinish: (values: ITimeline<typeof RFP_STATUSES>[]) => void
} & Omit<UseTimelinesProps<typeof RFP_STATUSES>, 'systemTimelines'>

const timeOptions = getTimeOptionsStartFrom('00:00 AM')

const RFPTimelines: FC<Props> = ({ onFinish, ...useTimelinesProps }) => {
  const rfpDetailsContext = useRFPDetailsContext()

  const { data } = rfpDetailsContext.state

  const { state, actions } = useTimelines<typeof RFP_STATUSES>({
    ...useTimelinesProps,
    systemTimelines,
    disabledBeforeKey: data.status
  })

  const { getHasValidationError, getDisabledByKey } = actions

  const handleFormSubmit = useCallback(() => {
    if (!getHasValidationError()) {
      const onlyAvailableTimelines = state.filter(
        (timeline) => !getDisabledByKey(timeline.key)
      )

      onFinish(onlyAvailableTimelines)
    }
  }, [getDisabledByKey, getHasValidationError, onFinish, state])

  return (
    <Form
      id="rfp-timeline-form-id"
      className="rfp-timeline-form"
      onFinish={handleFormSubmit}
    >
      <Timelines<typeof RFP_STATUSES>
        timelines={state}
        timeOptions={timeOptions}
        {...actions}
      />
    </Form>
  )
}

export default RFPTimelines
