import { Tag } from 'antd'
import cn from 'classnames'
import { Typography, TYPOGRAPHY_WEIGHT } from 'components/Typography'
import { TChipsProps } from './types'
import './styles.scss'

export const Menu = ({ children, className, ...props }: TChipsProps) => {
  return (
    <Tag className={cn('custom-chips', 'chips-menu', className)} {...props}>
      <Typography.Label weight={TYPOGRAPHY_WEIGHT.SEMI_BOLD}>
        {children}
      </Typography.Label>
    </Tag>
  )
}
