import { AttachmentsList } from 'components/AttachmentsList/AttachmentsList'
import './style.scss'
import { ReactComponent as Docs16Icon } from 'assets/svg/Docs16.svg'
import { VersionHistory } from '../../VersionHistory/VersionHistory'
import { BTN_TXT, ROLES_NAMES } from '../../../../constants'
import { FieldWithLabel } from '../../../../components/FieldWithLabel/FieldWithLabel'
import { IconFullName } from '../../../HealthSystemDetails/IconFullName'
import { useRequestForAnalysisDetailsContext } from '../../../../pages/RequestForAnalysisDetaisPage/RequestForAnalysisDetailsProvider'

export const SupportInformation = () => {
  const { headerInfo, uploadedDocs } =
    useRequestForAnalysisDetailsContext().state
  return (
    <div className="support-information-request-container">
      <div className="row">
        <AttachmentsList.WithHistory
          label="Invoice data"
          icon={<Docs16Icon />}
          className="mr-24 support-information-request-container__attachment"
          versionHistoryButtonText={BTN_TXT.SHOW_VERSION_HISTORY}
          files={uploadedDocs}
          hideSeparator
          versionHistory={<VersionHistory documents={uploadedDocs} />}
        />
        <FieldWithLabel title="Health System contact person">
          <IconFullName
            key={headerInfo.contact_person?.uuid}
            firstName={headerInfo.contact_person?.first_name}
            lastName={headerInfo.contact_person?.last_name}
            src={headerInfo.contact_person?.avatar?.file_preview}
            uuid={headerInfo.contact_person?.uuid}
            roleName={ROLES_NAMES[headerInfo.contact_person?.role]}
          />
        </FieldWithLabel>
      </div>
    </div>
  )
}
