import {
  Can,
  usePrsDetailsAbility
} from '../Providers/PrsDetailsAbilityProvider'
import { ACTIONS, SUBJECTS } from '../../Abilities'
import { subject } from '@casl/ability'
import { Button, BUTTON_TYPES } from 'components/Button'
import { usePRSDetailsContext } from '../Providers/PRSDetailsContextProvider'
import { BTN_TXT, TOOLTIP } from 'constants/txt'
import { PRS_STATUSES } from '../../../../pages/ProductRequests/constants'
import { Tooltip } from 'components/Tooltip/Tooltip'
import { useMemo } from 'react'
import './style.scss'
import cn from 'classnames'
import Popup from '../../../../components/Popup/Popup'
import {
  Typography,
  TYPOGRAPHY_WEIGHT
} from '../../../../components/Typography'
import { usePRSDetailsPopupContext } from '../Providers/PRSDetailsPopupProvider'
const SendToClinicalSponsor = () => {
  const { state, actions } = usePRSDetailsContext()
  const { data } = state
  const { handleChangePrsStatus } = actions
  const ability = usePrsDetailsAbility()

  const popup = usePRSDetailsPopupContext()
  const { sendToClinicalSponsorPopup } = popup
  const { visible } = sendToClinicalSponsorPopup.state
  const { close, open } = sendToClinicalSponsorPopup.actions

  const isDisabled = useMemo(() => {
    return !(
      ability.can(
        ACTIONS.SEND,
        subject(SUBJECTS.CLINICAL_SPONSOR, { ...data })
      ) && !!data.clinical_sponsor_stakeholder?.uuid
    )
  }, [ability, data])

  const onSend = () => {
    handleChangePrsStatus(PRS_STATUSES.CLINICAL_SPONSOR_APPROVAL)
  }
  return (
    <>
      <Can I={ACTIONS.VIEW} a={subject(SUBJECTS.CLINICAL_SPONSOR, { ...data })}>
        <Tooltip
          id="disable-send-btn"
          title={TOOLTIP.ASSIGN_CLINICAL_SPONSOR_TO_PROCEED}
          overlayInnerStyle={{
            display: isDisabled ? 'block' : 'none',
            width: 237
          }}
          placement="topRight"
          mouseLeaveDelay={0}
        >
          <span
            className={cn({
              'send-prs-to-clinical-sponsor-disabled': isDisabled
            })}
          >
            <Button
              type={BUTTON_TYPES.PRIMARY}
              onClick={open}
              disabled={isDisabled}
              className="send-prs-to-clinical-sponsor-btn"
              upperCase
            >
              {BTN_TXT.SEND_FOR_SPONSOR_APPROVAL}
            </Button>
          </span>
        </Tooltip>
        <Popup
          visible={visible}
          onCancel={close}
          title={`Send for clinical approval`}
          width={442}
          footer={
            <>
              <Button onClick={close} type={BUTTON_TYPES.DEFAULT} upperCase>
                {BTN_TXT.CANCEL}
              </Button>
              <Button type={BUTTON_TYPES.PRIMARY} upperCase onClick={onSend}>
                {BTN_TXT.SEND_FOR_APPROVAL}
              </Button>
            </>
          }
        >
          <Typography.Headline6 weight={TYPOGRAPHY_WEIGHT.NORMAL}>
            Request will be sent for approval to the selected clinical sponsor.
            You will be able to proceed with the next steps once clinical
            sponsor approves the request.
          </Typography.Headline6>
        </Popup>
      </Can>
    </>
  )
}

export default SendToClinicalSponsor
