import 'features/ContractDetails/ContractDetailsGeneral/ResponsibleUsers/styles.scss'

import { FieldWithLabel } from 'components/FieldWithLabel/FieldWithLabel'
import UserList from 'features/ContractDetails/ContractDetailsGeneral/ResponsibleUsers/UserList/UserList'
import ContractResponsibleContextProvider from 'features/ContractDetails/ContractDetailsGeneral/ResponsibleUsers/Providers/ContractResponsibleContextProvider'
import { DeleteResponsibleUserPopup } from 'features/ContractDetails/ContractDetailsGeneral/ResponsibleUsers/index'
import AddResponsibleBtn from '../Actions/AddResponsibleBtn'
import { useContractDetailsContext } from '../../Providers/ContractDetailsContextProvider'

const ResponsibleUsers = () => {
  const contractDetails = useContractDetailsContext()
  const { isVendor } = contractDetails.state
  return (
    <ContractResponsibleContextProvider>
      <div className="contract-details-responsible">
        <FieldWithLabel title="Team Responsible">
          <UserList vendor={isVendor} />
        </FieldWithLabel>
        <div className="contract-details-general__add-responsible">
          <AddResponsibleBtn />
        </div>
        <FieldWithLabel
          title={`${!isVendor ? 'Vendor' : 'Community'} contacts`}
        >
          <UserList vendor={!isVendor} />
        </FieldWithLabel>
      </div>
      <DeleteResponsibleUserPopup />
    </ContractResponsibleContextProvider>
  )
}

export default ResponsibleUsers
