import { Form, Space } from 'antd'
import { TQuestionsForm } from './forms.surveyQuestions.d'
import {
  initialStateForSurveyCreation,
  QUESTION_TYPE,
  QUESTION_TYPE_WITH_OPTIONS,
  questionEmptyState,
  questionOptions
} from '../../features/PRS/PRSDetails/Voting/Survey/constants'
import { ReactComponent as Delete24 } from 'assets/svg/Delete24.svg'
import { ReactComponent as Close24 } from 'assets/svg/Close24.svg'
import { ReactComponent as Plus16 } from 'assets/svg/Plus16.svg'
import { Button, BUTTON_TYPES } from '../Button'
import './forms.surveyQuestions.scss'
import { Select } from '../Select'
import { BTN_TXT, LABELS, PLACEHOLDERS } from '../../constants'
import { Switch } from '../Switch'
import Typography from '../Typography/Typography'
import { Input } from '../Input'
import { FormInstance } from 'antd/lib/form/hooks/useForm'
import { usePRSVotingContext } from '../../features/PRS/PRSDetails/Providers/PRSVotingContext'
import cn from 'classnames'

export type TProps = {
  form: FormInstance<TQuestionsForm>
}

export const SurveyQuestions = ({ form }: TProps) => {
  const questions = Form.useWatch('questions', form)
  const { state, actions } = usePRSVotingContext()
  const { isError } = state
  const { updateVotingData } = actions

  const selectOptionsComponent = (parentName: number) => {
    return (
      <>
        <Form.List name={[parentName, 'options']} initialValue={[]}>
          {(fields, { add, remove }) => (
            <>
              {fields.map(({ key, name }, _id) => (
                <div
                  className="row mt-16 question-template-container__item__option-filed options-row"
                  key={key}
                >
                  <Input
                    propsItem={{
                      name: [name, 'text'],
                      label: !!questions?.[parentName]?.options[name]?.is_other
                        ? LABELS.OTHER
                        : LABELS.OPTION + ' ' + (name + 1),
                      rules: [
                        {
                          max: 100,
                          required:
                            !questions?.[parentName]?.options[name]?.is_other
                        }
                      ]
                    }}
                    propsInput={{
                      showCount: true,
                      maxLength: 100,
                      disabled:
                        !!questions?.[parentName]?.options[name]?.is_other
                    }}
                  />
                  <Button.Icon
                    className="questions-form_delete-btn"
                    type={BUTTON_TYPES.LINK}
                    icon={<Close24 />}
                    onClick={() => {
                      remove(name)
                    }}
                  />
                </div>
              ))}
              {addOtherField(parentName, add)}
            </>
          )}
        </Form.List>
      </>
    )
  }
  const addOtherField = (parentNumber: number, add: (val: any) => void) => {
    return (
      <>
        {questions?.[parentNumber]?.type !== questionOptions[2].value && (
          <div className="row question-template-container__footer">
            <Button
              type={BUTTON_TYPES.GHOST}
              className="question-template-container__footer___options"
              onClick={() => add({ text: '', is_other: false })}
              upperCase
            >
              {BTN_TXT.ADD_OPTION}
            </Button>
            {!questions?.[parentNumber]?.options.find((i) => i?.is_other) && (
              <>
                <Typography.Body2>or</Typography.Body2>
                <Button
                  type={BUTTON_TYPES.GHOST}
                  className="question-template-container__footer___other"
                  onClick={() => {
                    add({ text: '', is_other: true })
                  }}
                  upperCase
                >
                  {BTN_TXT.OTHER}
                </Button>
              </>
            )}
          </div>
        )}
      </>
    )
  }

  return (
    <>
      <Form.List
        name="questions"
        initialValue={initialStateForSurveyCreation.questions}
      >
        {(fields, { add, remove }) => (
          <div className="">
            {fields.map(({ key, name }, _id) => {
              return (
                <Space
                  key={key}
                  className={cn('row question-template-container__item mt-24', {
                    error:
                      isError &&
                      !!questions?.length &&
                      questions[name]?.type !== QUESTION_TYPE.TEXT &&
                      questions[name]?.options?.length < 1
                  })}
                  direction="vertical"
                >
                  <div className="row align-center question-template-container__header">
                    <Select
                      options={questionOptions}
                      propsItem={{
                        name: [name, 'type'],
                        rules: [{ required: true }]
                      }}
                      propsSelect={{
                        placeholder: PLACEHOLDERS.PLEASE_SELECT,
                        onChange: (value) => {
                          const newQ = questions.map((i, idx) =>
                            idx === name
                              ? {
                                  ...i,
                                  text:
                                    QUESTION_TYPE_WITH_OPTIONS.includes(
                                      value
                                    ) &&
                                    QUESTION_TYPE_WITH_OPTIONS.includes(i?.type)
                                      ? i.text
                                      : '',
                                  type: value,
                                  options:
                                    QUESTION_TYPE_WITH_OPTIONS.includes(
                                      value
                                    ) &&
                                    QUESTION_TYPE_WITH_OPTIONS.includes(i?.type)
                                      ? i.options
                                      : [],
                                  has_other: false
                                }
                              : i
                          )
                          form.setFieldsValue({ questions: newQ })
                          updateVotingData({ questions: newQ })
                        },
                        defaultValue: questionOptions[0]
                      }}
                    />
                    <div className="row align-center mt-0 ml-16">
                      <Form.Item
                        name={[name, 'is_required']}
                        className="switch-form-item"
                        valuePropName="checked"
                      >
                        <Switch defaultChecked />
                      </Form.Item>
                      <Typography.Body1 className="ml-12">
                        Required
                      </Typography.Body1>
                    </div>
                    <Button.Icon
                      className="user-form_delete-btn"
                      type={BUTTON_TYPES.LINK}
                      icon={<Delete24 />}
                      onClick={() => {
                        remove(name)
                        // const newQ = questions.filter((_, idx) => idx !== name)
                        // form.setFieldsValue({ questions: newQ })
                      }}
                      danger
                    />
                  </div>
                  <div className="row mt-16">
                    <Input
                      propsItem={{
                        name: [name, 'text'],
                        label: LABELS.QUESTION,
                        rules: [
                          {
                            max: 100,
                            required: true
                          }
                        ]
                      }}
                      propsInput={{
                        showCount: true,
                        maxLength: 100
                      }}
                    />
                  </div>
                  {questions?.[name]?.type === questionOptions[2].value ? (
                    <div>
                      <Input.TextArea
                        propsItem={{
                          label: LABELS.ANSWER,
                          name: [name, 'answer'],
                          rules: [
                            {
                              max: 250
                            }
                          ]
                        }}
                        propsTextArea={{
                          showCount: true,
                          maxLength: 250,
                          disabled: true
                        }}
                      />
                    </div>
                  ) : (
                    <>{selectOptionsComponent(name)}</>
                  )}
                </Space>
              )
            })}
            {questions?.length < 15 && (
              <Button
                type={BUTTON_TYPES.GHOST}
                icon={<Plus16 />}
                className="question-template-container__footer___options mt-24"
                onClick={() => add(questionEmptyState)}
                upperCase
              >
                {BTN_TXT.ADD_QUESTION}
              </Button>
            )}
          </div>
        )}
      </Form.List>
    </>
  )
}
