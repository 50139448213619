import { useRFPVotingContext } from 'features/RFP/RFPDetails/Providers/RFPVotingContextProvider'
import { Segmented } from 'components/Segmented'

import './styles.scss'

const RFPVotingHeader = () => {
  const rfpVotingContext = useRFPVotingContext()

  const { headerOptions, activeHeaderOptionValue } = rfpVotingContext.state
  const { setActiveHeaderOptionValue } = rfpVotingContext.actions

  return (
    <div className="rfp-voting-header">
      <Segmented
        options={headerOptions}
        value={activeHeaderOptionValue}
        onChange={setActiveHeaderOptionValue}
      />
    </div>
  )
}

export default RFPVotingHeader
