import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { moduleName } from './constants'
import { ISetPasswordData } from './types'

const initialState: ISetPasswordData = {
  token: '',
  email: ''
}

const slice = createSlice({
  name: moduleName,
  initialState,
  reducers: {
    setPasswordData(
      state: ISetPasswordData,
      action: PayloadAction<ISetPasswordData>
    ) {
      const { token, email, isReset } = action.payload
      state.token = token
      state.email = email
      state.isReset = isReset
    }
  }
})

export default slice.reducer
export const { setPasswordData } = slice.actions
