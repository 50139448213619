import { RowInfoType } from './types'
import { Typography } from '../../../components/Typography'

export const RowInfo = ({ icon, children }: RowInfoType) => {
  return (
    <div className="row calendar__meeting-details-popover__row">
      <div className="mr-12 calendar__meeting-details-popover__row__icon">
        {icon}
      </div>
      <Typography.Body2 className="mr-12 calendar__meeting-details-popover__row__info">
        {children}
      </Typography.Body2>
    </div>
  )
}
