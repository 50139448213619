import { CommonsDetailsData } from './types'

export const initialCommonsData: CommonsDetailsData = {
  vice_president: {
    uuid: '',
    email: '',
    status: '',
    role: '',
    timezone: {
      uuid: '',
      name: '',
      offset: ''
    }
  }
}
