import { ReactComponent as Delete16 } from 'assets/svg/Delete16.svg'
import { Button, BUTTON_TYPES } from 'components/Button'
import { Props } from './types'

export default (props: Props) => {
  const { deleteMemberPopup } = props

  return (
    <Button.Icon
      small
      danger
      type={BUTTON_TYPES.LINK}
      icon={<Delete16 />}
      onClick={deleteMemberPopup.actions.open}
    />
  )
}
