import { Menu } from 'components/Menu/Menu'

import { PRS_SURVEY_TABS } from './constants'
import QuestionTemplate from '../QuestionsTemplate/QuestionTemplate'
import Preview from '../Preview/Preview'
import { FormInstance } from 'antd/lib/form/hooks/useForm'
import { TQuestionsForm } from 'components/Forms/forms.surveyQuestions.d'
import { usePRSVotingContext } from '../../Providers/PRSVotingContext'

export type TProps = {
  form: FormInstance<TQuestionsForm>
  setActiveTab: (val: string) => void
  activeTab: string
  isView?: boolean
}

const SurveyTabs = ({ form, setActiveTab, activeTab, isView }: TProps) => {
  const { state } = usePRSVotingContext()
  const { isOpen, isAbleToVote, votingData, isError } = state

  const onSelect = (e) => setActiveTab(e.key)
  return !isOpen && !isAbleToVote && !isView ? (
    <>
      <Menu
        mode="horizontal"
        onSelect={onSelect}
        defaultSelectedKeys={[activeTab]}
        items={PRS_SURVEY_TABS}
      />
      {PRS_SURVEY_TABS[0].key === activeTab && <QuestionTemplate form={form} />}
      {PRS_SURVEY_TABS[1].key === activeTab && (
        <Preview
          votingData={votingData}
          isAbleToVote={isAbleToVote}
          isError={isError}
          form={form}
        />
      )}
    </>
  ) : (
    <Preview
      votingData={votingData}
      isAbleToVote={isAbleToVote}
      isError={isError}
      form={form}
    />
  )
}

export default SurveyTabs
