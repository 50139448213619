import { FC, memo, useCallback, useMemo } from 'react'
import { FormListFieldData, FormListOperation } from 'antd/lib/form/FormList'

import './styles.scss'

import { LABELS } from 'constants/txt'
import { Input } from 'components/Input'
import { PROPS_INPUT_NUMBER } from 'features/ContractDetails/RebateConditions/RebateConditionsForm/constants'
import { useRebateConditionsFormContext } from 'features/ContractDetails/RebateConditions/RebateConditionsForm/Providers/RebateConditionsFormProvider'
import { percentageRebateValidator } from 'features/ContractDetails/RebateConditions/RebateConditionsForm/utils'

type Props = {
  index: number
  rebate: FormListFieldData
  actions: FormListOperation
}

const GrowthRebateCondition: FC<Props> = (props) => {
  const { rebate, index } = props

  const { rebates, hasValidationError } = useRebateConditionsFormContext()

  const validateStatus = useMemo(
    () => (hasValidationError ? 'error' : undefined),
    [hasValidationError]
  )

  const amountToValidator = useCallback(
    (_, value) => {
      const safeAmountFrom = rebates[index]?.amount_from ?? 0
      const safeAmountTo = value ?? Infinity

      if (safeAmountFrom >= safeAmountTo) {
        return Promise.reject(new Error('Less than Amount from'))
      }

      return Promise.resolve()
    },
    [index, rebates]
  )

  const renderAmount = useCallback(
    (amountType: 'from' | 'to') => {
      const isAmountTo = amountType === 'to'
      const isAmountFrom = amountType === 'from'

      const isAmountFromOrNotLastAmountTo =
        isAmountFrom || index !== rebates.length - 1

      const requiredRule = {
        required: isAmountFromOrNotLastAmountTo,
        message: `Amount ${amountType} is required`
      }

      return (
        <Input.Number
          propsItem={{
            ...rebate,
            validateStatus,
            name: [rebate.name, `amount_${amountType}`],
            label: isAmountFrom
              ? LABELS.REBATE_AMOUNT_FROM
              : LABELS.REBATE_AMOUNT_TO,
            rules: isAmountTo
              ? [requiredRule, { validator: amountToValidator }]
              : [requiredRule]
          }}
          propsInputNumber={PROPS_INPUT_NUMBER}
        />
      )
    },
    [amountToValidator, index, rebate, rebates.length, validateStatus]
  )

  const Percentage = useMemo(
    () => (
      <Input.Number
        propsItem={{
          ...rebate,
          validateStatus,
          name: [rebate.name, 'percentage'],
          label: LABELS.REBATE_PERCENTAGE,
          rules: [
            {
              validator: percentageRebateValidator
            }
          ]
        }}
        propsInputNumber={PROPS_INPUT_NUMBER}
      />
    ),
    [rebate, validateStatus]
  )

  return (
    <div className="rebate-conditions-growth__grid">
      {renderAmount('from')}
      {renderAmount('to')}
      {Percentage}
    </div>
  )
}

export default memo(GrowthRebateCondition)
