import { Can } from '../Providers/PrsDetailsAbilityProvider'
import { ACTIONS, SUBJECTS } from '../../Abilities'
import { subject } from '@casl/ability'
import { Button, BUTTON_TYPES } from 'components/Button'
import { usePRSDetailsContext } from '../Providers/PRSDetailsContextProvider'
import { BTN_TXT, TOOLTIP } from 'constants/txt'
import { PRS_STATUSES } from '../../../../pages/ProductRequests/constants'
import { Tooltip } from 'components/Tooltip/Tooltip'
import { useMemo } from 'react'
import './style.scss'
import cn from 'classnames'
import { usePRSVotingContext } from '../Providers/PRSVotingContext'

export type TProps = {
  stakeHoldersLength: number
}
const SendSurvey = ({ stakeHoldersLength }: TProps) => {
  const { state, actions } = usePRSDetailsContext()
  const { data } = state
  const { handleChangePrsStatus } = actions

  const votingContext = usePRSVotingContext()

  const { votingData } = votingContext.state

  const isDisabled = useMemo(() => {
    return !votingData?.questions?.find((i) => i?.uuid) || !stakeHoldersLength
  }, [votingData, stakeHoldersLength])

  const onSend = () => {
    handleChangePrsStatus(PRS_STATUSES.VOTING_IN_PROGRESS)
  }

  const getTooltipText = useMemo(() => {
    if (!stakeHoldersLength && !votingData?.questions?.find((i) => i?.uuid))
      return TOOLTIP.STAKEHOLDER_SURVEY
    else if (!votingData?.questions?.find((i) => i?.uuid))
      return TOOLTIP.SURVEY_IS_NOT_CREATED
    else if (!stakeHoldersLength) return TOOLTIP.FACILITY_USERS_ARE_NOT_PROVIDED
  }, [stakeHoldersLength, votingData])

  return (
    <>
      <Can I={ACTIONS.SEND} a={subject(SUBJECTS.SURVEY, { ...data })}>
        <Tooltip
          id="disable-send-prs-btn"
          title={
            <span
              dangerouslySetInnerHTML={{
                __html: getTooltipText?.replace('\n', '<br/>') || ''
              }}
            />
          }
          overlayInnerStyle={{
            display: isDisabled ? 'block' : 'none'
          }}
          className="disable-send-prs-btn-container"
          placement="bottomRight"
          mouseLeaveDelay={0}
        >
          <span
            className={cn({
              'send-prs-to-clinical-sponsor-disabled': isDisabled
            })}
          >
            <Button
              type={BUTTON_TYPES.PRIMARY}
              onClick={onSend}
              disabled={isDisabled}
              className="send-prs-to-clinical-sponsor-btn"
              upperCase
            >
              {BTN_TXT.SEND_SURVEY}
            </Button>
          </span>
        </Tooltip>
      </Can>
    </>
  )
}

export default SendSurvey
