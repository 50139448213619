import { useMemo, useState } from 'react'
import cn from 'classnames'
import { Popover } from 'antd'
import Button from './Button'
import { Typography } from 'components/Typography'
import { ReactComponent as ArrowDown16 } from 'assets/svg/ArrowDown16.svg'
import { ReactComponent as Check24 } from 'assets/svg/Check24.svg'
import { ButtonMenuProps } from './types'
import './button.menu.scss'

const Menu = ({
  children,
  className,
  secondary,
  upperCase,
  small,
  isBody,
  options,
  onClick,
  selectedValue,
  medium,
  hideArrow,
  iconOnly,
  ...props
}: ButtonMenuProps) => {
  const [visible, setVisible] = useState(false)

  const handleVisibleChange = (value) => {
    setVisible(value)
  }
  const content = (
    <div className="button-menu__options">
      {options?.map((item) => (
        <div
          key={item.text}
          className="button-menu__option row align-center gap-12"
          onClick={() => {
            item.onClick()
            setVisible(false)
          }}
        >
          <Typography.Body1>{item.text}</Typography.Body1>
          {item.value && item.value === selectedValue && <Check24 />}
        </div>
      ))}
    </div>
  )

  const ButtonComponent = useMemo(
    () => (iconOnly ? Button.Icon : Button),
    [iconOnly]
  )

  return (
    <Popover
      placement="bottomRight"
      overlayClassName="button-menu__popover"
      content={content}
      trigger="click"
      visible={visible}
      onVisibleChange={handleVisibleChange}
    >
      <ButtonComponent
        className={cn('button-menu', className, {
          secondary,
          upperCase,
          medium,
          small
        })}
        {...props}
      >
        {children}
        {options && !hideArrow && (
          <div className="button-menu__arrow">
            <ArrowDown16 />
          </div>
        )}
      </ButtonComponent>
    </Popover>
  )
}

export default Menu
