import { FC } from 'react'
import cn from 'classnames'
import { Input } from '../../../Input'
import { currencyFormatter } from '../../../../features/ContractDetails/ContractDetailsGeneral/ContractDetailsForm/utils'
import { NumberCellProps } from './types'
import './styles.scss'

export const NumberCell: FC<NumberCellProps> = ({
  error,
  value,
  onChange,
  isCurrency
}) =>
  error ? (
    <Input.Number
      propsInputNumber={{
        controls: false,
        placeholder: '0',
        className: cn('table-number-cell', {
          'table-number-cell--with-error': error
        }),
        onChange: (val) => onChange(val as string),
        value
      }}
    />
  ) : (
    <>
      {value
        ? isCurrency
          ? currencyFormatter(Number(value).toFixed(2))
          : value
        : '-'}
    </>
  )
