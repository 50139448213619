import { TCalendarEventViewType } from '../../../components/Calendar/types'
import { TExtendedEvent, TGroupedCalendarInMonth } from './types'
import moment from 'moment/moment'

export const getMappedInDays = (data: TExtendedEvent[]) => {
  return data.reduce<TGroupedCalendarInMonth>((grouped, item) => {
    const label = item.day
    return {
      ...grouped,
      [label]: [...(grouped[label] || []), item]
    }
  }, {})
}

export const splitLongEventByDays = (
  event: TCalendarEventViewType,
  offset: string
): TCalendarEventViewType[] => {
  const start = moment(event.start).utcOffset(offset)
  const end = moment(event.end).utcOffset(offset)

  if (start.isSame(end, 'day')) {
    return [event]
  }

  const events: TCalendarEventViewType[] = []
  let current = start.clone()

  while (current.isBefore(end, 'day')) {
    events.push({
      ...event,
      start: current.format(),
      day: current.format('D ddd')
    })
    current = current.add(1, 'day').startOf('day')
  }
  events.push({
    ...event,
    start: current.format(),
    day: current.format('D ddd')
  })

  return events
}
