import { CHAT_TYPE } from 'constants/common'

export const CHAT_LIST_TABS = [
  {
    key: 'all',
    label: 'All'
  },
  {
    key: CHAT_TYPE.CHANNEL,
    label: 'Channel'
  },
  {
    key: CHAT_TYPE.GROUP,
    label: 'Group'
  },
  {
    key: CHAT_TYPE.PRIVATE,
    label: 'Private'
  }
]

export const SIDEBAR_TYPE = {
  NEW_PRIVATE: 'new-private',
  NEW_GROUP: 'new-group'
  // VIEW_GROUP: 'view-group',
  // VIEW_PRIVATE: 'view-private',
  // VIEW_CHANNEL: 'view-channel'
}

export type SidebarTypeUnion = ValueOfObject<typeof SIDEBAR_TYPE>
