import cn from 'classnames'
import { Checkbox, Form } from 'antd'
import { TCheckboxDefault as Props } from './types'
import './checkbox.default.scss'

const Default = ({ children, className, ...props }: Props): JSX.Element => (
  <Form.Item className={cn('checkbox-default', className)} {...props.propsItem}>
    <Checkbox {...props.propsCheckbox}>{children}</Checkbox>
  </Form.Item>
)

export default Default
