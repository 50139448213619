import { PRSDetails } from './PRSDetails'
import PRSDetailsContextProvider from './Providers/PRSDetailsContextProvider'
import PRSDetailsPopupProvider from './Providers/PRSDetailsPopupProvider'
import PRSDetailsActionsProvider from './Providers/PRSDetailsActionsProvider'
import PrsDetailsAbilityProvider from './Providers/PrsDetailsAbilityProvider'
import PRSVotingContextProvider from './Providers/PRSVotingContext'
import PrsHistoryContextProvider from './Providers/PrsHistoryContextProvider'

export default () => (
  <PrsDetailsAbilityProvider>
    <PRSDetailsPopupProvider>
      <PRSDetailsContextProvider>
        <PRSDetailsActionsProvider>
          <PRSVotingContextProvider>
            <PrsHistoryContextProvider>
              <PRSDetails />
            </PrsHistoryContextProvider>
          </PRSVotingContextProvider>
        </PRSDetailsActionsProvider>
      </PRSDetailsContextProvider>
    </PRSDetailsPopupProvider>
  </PrsDetailsAbilityProvider>
)
