import Button from 'components/Button/Button'
import { BUTTON_TYPES } from 'components/Button'
import { BTN_TXT } from '../../constants'
import Popup from 'components/Popup/Popup'
import Typography from 'components/Typography/Typography'
import { useContractDetailsPagePopup } from './Providers/ContractDetailsPagePopupProvider'
import { useCPRDetailsContext } from './Providers/CPRDetailsContextProvider'
import useRouter from '../../hooks/useRouter'
import { routes } from 'router'
import { Can } from './Providers/ContractPipelineDetailsAbilityProvider'
import { ACTIONS, SUBJECTS } from './Abilities'
import { subject } from '@casl/ability'

export const DeleteContainer = () => {
  const { deletePipelinePopup } = useContractDetailsPagePopup()
  const { state: dataState } = useCPRDetailsContext()

  const handleModal = () => {
    deletePipelinePopup.actions.open()
  }

  return (
    <Can
      I={ACTIONS.DELETE}
      a={subject(SUBJECTS.CONTRACT_PIPELINE, dataState.details)}
    >
      <Button
        type={BUTTON_TYPES.DEFAULT}
        onClick={handleModal}
        upperCase
        danger
      >
        {BTN_TXT.DELETE}
      </Button>
    </Can>
  )
}

export const DeleteModal = () => {
  const { deletePipelinePopup } = useContractDetailsPagePopup()
  const { state, actions } = deletePipelinePopup

  const { state: dataState, actions: dataActions } = useCPRDetailsContext()

  const { navigate } = useRouter()

  const onDelete = async () => {
    await dataActions.deletePipeline(dataState.details.uuid).then(() => {
      navigate(`${routes.contracts}${routes.contractPipeline}`)
    })
    actions.close()
  }

  return (
    <div className="delete-modal">
      <Popup
        {...state}
        onCancel={actions.close}
        width={440}
        zIndex={10000}
        footer={
          <>
            <Button
              type={BUTTON_TYPES.DEFAULT}
              onClick={actions.close}
              upperCase
            >
              {BTN_TXT.CANCEL}
            </Button>
            <Button
              type={BUTTON_TYPES.PRIMARY}
              onClick={onDelete}
              upperCase
              danger
            >
              {BTN_TXT.DELETE}
            </Button>
          </>
        }
      >
        <div className="column">
          <Typography.Body1>
            Pipeline record will be removed from the pipeline list. Delete
            pipeline record?
          </Typography.Body1>
        </div>
      </Popup>
    </div>
  )
}
