import { useParams } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import {
  getChatHistory,
  getIsLoading,
  getTotal
} from '../../../redux/store/chatHistory/getters'
import { useCallback, useEffect } from 'react'
import {
  groupChatHistoryByDate,
  chatHistoryDivider
} from '../../../redux/store/chatHistory/utils'
import { setChatHistoryAsync } from '../../../redux/store/chatHistory/actions'
import useInfiniteData from '../../../hooks/useInfiniteData'
import moment from 'moment'
import { timelineDateFormat } from '../../../components/Timelines/constants'
import { IChatHistory } from 'redux/store/chatHistory/types'
import { reset } from '../../../redux/store/chatHistory/slice'
import { getCountOfUnreadMessages } from 'redux/store/chatDetails/getters'
import { useChatContext } from '../Providers/ChatContextProvider'

const CHAT_HISTORY_LIMIT = 50

const useHistory = () => {
  const { id } = useParams()

  const dispatch = useDispatch<any>()

  const { state } = useChatContext()
  const { currentSearchValue } = state

  const messages = useSelector(getChatHistory)
  const total = useSelector(getTotal)
  const isLoading = useSelector(getIsLoading)
  const unreadMessagesCount = useSelector(getCountOfUnreadMessages)

  const getGroupedHistory = () => {
    const [unreadMessagesSlice, readMessagesSlice] = chatHistoryDivider(
      messages,
      unreadMessagesCount
    )

    const unreadMessages: [string, IChatHistory[]] = [
      'unread',
      unreadMessagesSlice.reverse()
    ]

    const soretedAndReadMessages = Object.entries(
      groupChatHistoryByDate(readMessagesSlice)
    ).sort(([dateA], [dateB]) => {
      return moment(dateB, timelineDateFormat).diff(
        moment(dateA, timelineDateFormat),
        'days'
      )
    })

    return unreadMessages[1].length
      ? [unreadMessages, ...soretedAndReadMessages]
      : soretedAndReadMessages
  }

  const loadMore = useCallback(
    async (args) => {
      if (id)
        await dispatch(
          setChatHistoryAsync(id, { ...args, offset: messages.length })
        )
    },
    [dispatch, messages.length]
  )
  const { hasNextPage, handleLoadMore, limit } = useInfiniteData({
    total,
    limit: Math.max(unreadMessagesCount, CHAT_HISTORY_LIMIT),
    loadMore
  })

  useEffect(() => {
    if (
      currentSearchValue?.message.uuid &&
      currentSearchValue?.order_number > messages.length &&
      id
    ) {
      dispatch(
        setChatHistoryAsync(id, {
          limit: currentSearchValue?.order_number - messages.length,
          offset: messages.length
        })
      )
    }
  }, [currentSearchValue])

  useEffect(() => {
    if (id) {
      dispatch(reset())
      dispatch(setChatHistoryAsync(id, { limit, offset: 0 }))
    }
  }, [id])

  return {
    messages,
    total,
    isLoading,
    getGroupedHistory,
    hasNextPage,
    handleLoadMore
  }
}

export type UseHistoryType = ReturnType<typeof useHistory>

export default useHistory
