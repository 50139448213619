import {
  createContext,
  FC,
  PropsWithChildren,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState
} from 'react'

import {
  IRFPVendor,
  RFPVendorRating,
  RFPVendorValue
} from 'features/RFP/RFPSurvey/types'
import { useRFPSurveyContext } from 'features/RFP/RFPDetails/Providers/RFPSurveyContextProvider'
import { useRFPDetailsPopupContext } from 'features/RFP/RFPDetails/Providers/RFPDetailsPopupProvider'
import { useAbility } from '@casl/react'
import { AbilityContext, ACTIONS, SUBJECTS } from 'features/Permission'
import { useRFPDetailsContext } from 'features/RFP/RFPDetails/Providers/RFPDetailsContextProvider'
import { subject } from '@casl/ability'
import { RFP_STATUSES } from 'features/RFP/constants'
import { notification } from 'components/Notification'
import { VALIDATION_MESSAGES } from 'constants/txt'

type ContextProps = {
  state: {
    canCreateOrEditSurvey: boolean
    localSelectedVendors: IRFPVendor[]
    localSelectedVendorValues: RFPVendorValue[]
  }
  actions: {
    handleCreateSurvey: VoidFunction
    handleSubmitSurvey: VoidFunction
    handelCancelSurveyVoting: VoidFunction
    handelCancelSurveyCreation: VoidFunction
    getLocalVendorRatingByUuid: (uuid: string) => number
    hasVendorRatingErrorByUuid: (uuid: string) => boolean
    handleLocalVendorsChange: (vendorValues: RFPVendorValue[]) => void
    handleLocalVendorRateChange: (
      uuid: RFPVendorValue
    ) => (rate: number) => void
  }
}

const RFPCreateSurveyContext = createContext<ContextProps>({
  state: {} as ContextProps['state'],
  actions: {} as ContextProps['actions']
})

const RFPCreateSurveyContextProvider: FC<PropsWithChildren> = ({
  children
}) => {
  const ability = useAbility<any>(AbilityContext)

  const rfpDetailsContext = useRFPDetailsContext()
  const rfpSurveyContext = useRFPSurveyContext()
  const { createSurveyPopup, submitSurveyPopup } = useRFPDetailsPopupContext()

  const { data } = rfpDetailsContext.state
  const { vendors, vendorsRating, selectedVendorValues } =
    rfpSurveyContext.state
  const { createSurveyAsync, submitSurveyVotingAsync } =
    rfpSurveyContext.actions

  const [localSelectedVendorValues, _setLocalSelectedVendorValues] =
    useState<RFPVendorValue[]>(selectedVendorValues)

  const [_localVendorsRating, _setLocalVendorsRating] =
    useState<RFPVendorRating>(vendorsRating)

  const [_vendorsRatingWithError, _setVendorsRatingWithError] = useState<
    Array<IRFPVendor['uuid']>
  >([])

  const localSelectedVendors = useMemo(
    () =>
      vendors
        ? vendors.filter((vendor) =>
            localSelectedVendorValues.includes(vendor.uuid)
          )
        : [],
    [localSelectedVendorValues, vendors]
  )

  const canCreateOrEditSurvey = useMemo(
    () =>
      ability.can(ACTIONS.CREATE, subject(SUBJECTS.SURVEY, { ...data })) &&
      data.status === RFP_STATUSES.PRICE_FILES_ANALYSIS,
    [ability, data]
  )

  const getLocalVendorRatingByUuid = useCallback(
    (uuid: RFPVendorValue) => _localVendorsRating[uuid],
    [_localVendorsRating]
  )

  const hasVendorRatingErrorByUuid = useCallback(
    (uuid: RFPVendorValue) => _vendorsRatingWithError.includes(uuid),
    [_vendorsRatingWithError]
  )

  const _updateLocalSelectedVendorValues = useCallback(() => {
    _setLocalSelectedVendorValues(selectedVendorValues)
  }, [selectedVendorValues])

  const _updateLocalVendorRating = useCallback(() => {
    _setLocalVendorsRating(vendorsRating)
  }, [vendorsRating])

  const _getUnratedVendors = useCallback(() => {
    return vendors.filter((v) => !_localVendorsRating[v.uuid])
  }, [_localVendorsRating, vendors])

  const handleLocalVendorsChange = useCallback(
    (vendorValues: RFPVendorValue[]) => {
      _setLocalSelectedVendorValues(vendorValues)
    },
    []
  )

  const handleLocalVendorRateChange = useCallback(
    (uuid: RFPVendorValue) => (rate: number) => {
      _setVendorsRatingWithError((prevState) =>
        prevState.filter((e) => e !== uuid)
      )

      _setLocalVendorsRating((prevState) => ({
        ...prevState,
        [uuid]: rate
      }))
    },
    []
  )

  const handleCreateSurvey = useCallback(async () => {
    createSurveyAsync(localSelectedVendorValues)
  }, [createSurveyAsync, localSelectedVendorValues])

  const handleSubmitSurvey = useCallback(async () => {
    if (vendors.length !== Object.keys(_localVendorsRating).length) {
      const unratedVendorsUuid = _getUnratedVendors().map((v) => v.uuid)

      _setVendorsRatingWithError(unratedVendorsUuid)
      return
    }

    submitSurveyVotingAsync(_localVendorsRating)
  }, [
    _getUnratedVendors,
    _localVendorsRating,
    submitSurveyVotingAsync,
    vendors.length
  ])

  const handelCancelSurveyVoting = useCallback(() => {
    _updateLocalVendorRating()

    _setVendorsRatingWithError([])

    submitSurveyPopup.actions.close()
  }, [_updateLocalVendorRating, submitSurveyPopup.actions])

  const handelCancelSurveyCreation = useCallback(() => {
    _updateLocalSelectedVendorValues()

    createSurveyPopup.actions.close()
  }, [_updateLocalSelectedVendorValues, createSurveyPopup.actions])

  useEffect(() => {
    _updateLocalSelectedVendorValues()
  }, [_updateLocalSelectedVendorValues])

  useEffect(() => {
    if (_vendorsRatingWithError.length > 0) {
      notification.error({ message: VALIDATION_MESSAGES.SM0039 })
    }
  }, [_vendorsRatingWithError])

  const state = useMemo(
    () => ({
      canCreateOrEditSurvey,
      localSelectedVendors,
      localSelectedVendorValues
    }),
    [canCreateOrEditSurvey, localSelectedVendorValues, localSelectedVendors]
  )

  const actions = useMemo(
    () => ({
      getLocalVendorRatingByUuid,
      handleLocalVendorsChange,
      handleCreateSurvey,
      handleSubmitSurvey,
      handelCancelSurveyVoting,
      handelCancelSurveyCreation,
      handleLocalVendorRateChange,
      hasVendorRatingErrorByUuid
    }),
    [
      getLocalVendorRatingByUuid,
      handelCancelSurveyVoting,
      handelCancelSurveyCreation,
      handleCreateSurvey,
      handleLocalVendorsChange,
      handleLocalVendorRateChange,
      handleSubmitSurvey,
      hasVendorRatingErrorByUuid
    ]
  )
  return (
    <RFPCreateSurveyContext.Provider value={{ state, actions }}>
      {children}
    </RFPCreateSurveyContext.Provider>
  )
}

export const useRFPCreateSurveyContext = () =>
  useContext(RFPCreateSurveyContext)

export default RFPCreateSurveyContextProvider
