import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { Form } from 'antd'
import cn from 'classnames'
import { Button, BUTTON_TYPES } from 'components/Button'
import { Input } from 'components/Input'
import { BTN_TXT, LABELS } from 'constants/txt'
import {
  formatPhoneNumber,
  preventInitialToCorrectFormat,
  validateMessages
} from 'helper/common'
import { getError, getVendorInfo } from 'redux/store/vendorInfo/getters'
import { ability, ACTIONS, SUBJECTS } from 'features/Permission'
import { TVendorInfoProps, TVendorInfoForm } from './forms.vendorInfo.d'
import './forms.vendorInfo.scss'
import { Popup } from '../Popup'
import usePopup from '../../hooks/usePopup'
import { useShowPopup } from '../../hooks/useShowPopup'
import Typography from '../Typography/Typography'

export const VendorInfo = ({
  className,
  onSubmit
}: TVendorInfoProps): JSX.Element => {
  const [form] = Form.useForm()
  const initialState = useSelector(getVendorInfo)
  const [isActive, setIsActive] = useState(false)
  const discardChangesPopup = usePopup()
  const error = useSelector(getError)
  const [showPopup, confirmNavigation, cancelNavigation] =
    useShowPopup(isActive)
  const cancelDiscardChanges = () => {
    discardChangesPopup.actions.close()
  }
  const phoneNumber = Form.useWatch('phone_number', form)

  const submitCancellation = () => {
    form.resetFields()
    setIsActive(false)
    discardChangesPopup.actions.close()
  }

  const resetFields = () => {
    if (isActive) {
      discardChangesPopup.actions.open()
    } else submitCancellation()
  }

  useEffect(() => {
    form.resetFields()
    setIsActive(false)
  }, [initialState])
  const onSubmitForm = (values) => {
    setIsActive(false)
    onSubmit(values)
  }
  return (
    <Form<TVendorInfoForm>
      form={form}
      className={cn('vendor-info-form', className)}
      layout="vertical"
      onFinish={onSubmitForm}
      initialValues={preventInitialToCorrectFormat(initialState)}
      validateMessages={validateMessages}
      onFieldsChange={() => setIsActive(true)}
    >
      <div className="vendor-permissions-view">
        <Typography.Label>Vendor permission</Typography.Label>
        {initialState.vendor_permission === 'guest' ? (
          <Typography.Body1>
            Guest <span>(access only for Product Requests functionality)</span>
          </Typography.Body1>
        ) : (
          <Typography.Body1>Full access</Typography.Body1>
        )}
      </div>
      <Input
        propsItem={{
          label: LABELS.VENDOR_NAME,
          name: 'name',
          help: error?.name ? error?.name[0] : undefined,
          validateStatus: error?.name ? 'error' : undefined,
          rules: [
            {
              max: 100,
              required: true
            }
          ]
        }}
        propsInput={{
          disabled: ability.cannot(ACTIONS.CRUD, SUBJECTS.VENDOR),
          showCount: true,
          maxLength: 100
        }}
      />
      <Input.TextArea
        propsItem={{
          label: LABELS.DESCRIPTION,
          name: 'description',
          help: error?.description ? error?.description[0] : undefined,
          validateStatus: error?.description ? 'error' : undefined,
          rules: [
            {
              max: 250
            }
          ]
        }}
        propsTextArea={{
          disabled: ability.cannot(ACTIONS.CRUD, SUBJECTS.VENDOR),
          showCount: true,
          maxLength: 250
        }}
      />
      <div className="vendor-info-form_phone">
        <Input.Phone
          propsItem={{
            label: LABELS.VENDORS_PHONE_NUMBER,
            name: 'phone_number',
            help: error?.phone_number ? error?.phone_number[0] : undefined,
            validateStatus: error?.phone_number ? 'error' : undefined,
            rules: [
              {
                pattern: /^[\d]{10,15}$/
              }
            ]
          }}
          propsInputNumber={{
            disabled: ability.cannot(ACTIONS.CRUD, SUBJECTS.VENDOR),
            controls: false,
            formatter: formatPhoneNumber,
            prefix: phoneNumber ? '+1' : ''
          }}
        />
      </div>
      <div className="vendor-info-form_address">
        <Input
          propsItem={{
            name: ['address', 'street'],
            label: LABELS.STREET,
            help: error?.street ? error?.street[0] : undefined,
            validateStatus: error?.street ? 'error' : undefined,
            rules: [
              {
                required: true
              }
            ]
          }}
          propsInput={{
            disabled: ability.cannot(ACTIONS.CRUD, SUBJECTS.VENDOR)
          }}
        />
        <Input
          propsItem={{
            name: ['address', 'city'],
            label: LABELS.CITY,
            help: error?.city ? error?.city[0] : undefined,
            validateStatus: error?.city ? 'error' : undefined,
            rules: [
              {
                required: true
              }
            ]
          }}
          propsInput={{
            disabled: ability.cannot(ACTIONS.CRUD, SUBJECTS.VENDOR)
          }}
        />
        <Input
          propsItem={{
            name: ['address', 'state'],
            label: LABELS.STATE,
            help: error?.state ? error?.state[0] : undefined,
            validateStatus: error?.state ? 'error' : undefined,
            rules: [
              {
                required: true
              }
            ]
          }}
          propsInput={{
            disabled: ability.cannot(ACTIONS.CRUD, SUBJECTS.VENDOR)
          }}
        />
        <Input
          propsItem={{
            name: ['address', 'zip_code'],
            label: LABELS.ZIP_CODE,
            help: error?.zip_code ? error?.zip_code[0] : undefined,
            validateStatus: error?.zip_code ? 'error' : undefined,
            rules: [
              {
                required: true
              }
            ]
          }}
          propsInput={{
            disabled: ability.cannot(ACTIONS.CRUD, SUBJECTS.VENDOR)
          }}
        />
      </div>
      <div
        className={cn(
          'vendor-info-form_buttons',
          isActive || 'vendor-info-form_buttons_hide'
        )}
      >
        <Button onClick={resetFields} type={BUTTON_TYPES.DEFAULT} upperCase>
          {BTN_TXT.CANCEL}
        </Button>
        <Button type={BUTTON_TYPES.PRIMARY} htmlType="submit" upperCase>
          {BTN_TXT.SAVE}
        </Button>
      </div>
      <Popup.Discard
        visible={showPopup ? showPopup : discardChangesPopup.state.visible}
        onCancel={showPopup ? cancelNavigation : cancelDiscardChanges}
        onSubmit={showPopup ? confirmNavigation : submitCancellation}
      />
    </Form>
  )
}
