import {
  createContext,
  FC,
  PropsWithChildren,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState
} from 'react'
import useRouter from '../../hooks/useRouter'
import { useDispatch } from 'react-redux'
import { setLoading } from '../../redux/store/common/slice'
import { TRequestSupportInfoResponse } from '../../features/RequestForAnalysisDetails/types'
import { initialHSHeader } from '../../features/RequestForAnalysisDetails/constants'
import { getSupportInformationDetails } from './api'
import { getUploadedEvaluationDocumentsForHealthSystem } from '../../features/ContractDetails/api'
import { TUploadedPoDocuments } from '../../features/CognusRepository/OpportunityAnalysis/SupportInformation/types'

type ContextProps = {
  state: {
    headerInfo: TRequestSupportInfoResponse
    uploadedDocs: TUploadedPoDocuments[]
  }
  actions: {}
}
const RequestForAnalysisDetailsContext = createContext<ContextProps>({
  state: null!,
  actions: null!
})

const RequestForAnalysisDetailsContextProvider: FC<PropsWithChildren> = ({
  children
}) => {
  const { query } = useRouter()
  const [headerInfo, _setHeaderInfo] =
    useState<TRequestSupportInfoResponse>(initialHSHeader)
  const [uploadedDocs, _setUploadedDocs] = useState<TUploadedPoDocuments[]>([])
  const dispatch = useDispatch()

  const getSupportInfo = useCallback(async () => {
    if (!query?.id) {
      throw new Error('ID not provided')
    }
    dispatch(setLoading(true))
    try {
      const result = await getSupportInformationDetails(query.id)
      _setHeaderInfo(result.data)
    } catch {
    } finally {
      dispatch(setLoading(false))
    }
  }, [query.id])

  const getUploadedDocuments = useCallback(async () => {
    if (!query?.id) {
      throw new Error('ID not provided')
    }
    dispatch(setLoading(true))
    try {
      const result = await getUploadedEvaluationDocumentsForHealthSystem(
        query.id
      )
      _setUploadedDocs(result.data.results)
    } catch {
    } finally {
      dispatch(setLoading(false))
    }
  }, [query.id])

  useEffect(() => {
    if (query.id) {
      getSupportInfo()
    }
    getUploadedDocuments()
  }, [])

  const context = useMemo(
    () => ({
      state: { headerInfo, uploadedDocs },
      actions: {}
    }),
    [headerInfo, uploadedDocs]
  )
  return (
    <RequestForAnalysisDetailsContext.Provider value={context}>
      {children}
    </RequestForAnalysisDetailsContext.Provider>
  )
}
export const useRequestForAnalysisDetailsContext = () =>
  useContext(RequestForAnalysisDetailsContext)

export default RequestForAnalysisDetailsContextProvider
