import moment from 'moment'

export const initialStateAddEventForm = (offset: string) => {
  return {
    name: '',
    event_type: null,
    location: null,
    start_date: moment().zone(offset),
    start_time: '08:00 AM',
    end_date: moment().zone(offset),
    end_time: '08:30 AM',
    participants: [],
    summary: null
  }
}
