import { SegmentOption } from 'constants/types'

export const INITIAL_HEADER_OPTIONS: SegmentOption[] = [
  {
    value: 1,
    label: 'Price file'
  },
  {
    value: 2,
    label: 'Survey'
  }
]
