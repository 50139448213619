import { Drawer } from 'components/Drawer'
import { usePendingClassificationPopupContext } from '../../Providers/PendingClassificationPopupProvider'
import SimilarItemsCard from './SimilarItemsCard'
import { useCallback, useEffect, useState } from 'react'
import { TSimilarInvoice } from '../types'
import { getInvoiceSimilarItems } from '../../../../pages/InvoiceData/api'
import { Typography } from '../../../../components/Typography'
import { ReactComponent as EmptyBox } from 'assets/svg/EmptyBox.svg'
import { useDispatch } from 'react-redux'
import { setLoading } from '../../../../redux/store/common/slice'
import { usePendingClassificationEditContext } from '../../Providers/PendingClassificationEditProvider'

const SimilarItemsDrawer = () => {
  const dispatch = useDispatch()
  const { actions } = usePendingClassificationEditContext()
  const { similarItemsSidebarDrawer } = usePendingClassificationPopupContext()
  const { uuid } = similarItemsSidebarDrawer.state.payload
  const { close } = similarItemsSidebarDrawer.actions
  const [similarItems, setSimilarItems] = useState<TSimilarInvoice[] | null>([])

  const getSimilarItemsList = useCallback(
    (uuid: string) => {
      dispatch(setLoading(true))
      getInvoiceSimilarItems(uuid)
        .then((res) => {
          if (!res.data?.results?.length) {
            setSimilarItems(null)
          } else {
            setSimilarItems(res.data.results)
          }
        })
        .finally(() => {
          dispatch(setLoading(false))
        })
    },
    [dispatch]
  )

  useEffect(() => {
    if (uuid) {
      getSimilarItemsList(uuid)
    }
    return () => {
      setSimilarItems([])
    }
  }, [uuid, getSimilarItemsList])

  return (
    <Drawer {...similarItemsSidebarDrawer.state}>
      {!similarItems && (
        <div className="flex column full-width align-center mt-140">
          <EmptyBox />
          <Typography.Headline6 className="mt-40">
            No similar items found.
          </Typography.Headline6>
        </div>
      )}
      {similarItems?.map((similarItem) => (
        <SimilarItemsCard
          similarItem={similarItem}
          onSetDetailsPressed={() => {
            actions.setChangedRows((prev) => ({
              ...prev,
              [uuid]: {
                contract_category: similarItem.contract_category.uuid,
                unspsc_code: similarItem.unspsc_code
              }
            }))
            close()
          }}
        />
      ))}
    </Drawer>
  )
}

export default SimilarItemsDrawer
