import { FieldWithLabel } from '../../../components/FieldWithLabel/FieldWithLabel'
import { TPropsDetailsAccount } from '../types'
import '../style.scss'
import { safeString } from '../../../helper/common'
const Details = ({ dataAccount }: TPropsDetailsAccount) => {
  return (
    <div className="details-wrapper">
      <FieldWithLabel title="Financial Institution">
        {safeString(dataAccount.bank_name)}
      </FieldWithLabel>
      <FieldWithLabel title="Bank number">
        **** {safeString(dataAccount.bank_number)}
      </FieldWithLabel>
      <FieldWithLabel title="Routing number">
        {safeString(dataAccount.routing_number)}
      </FieldWithLabel>
    </div>
  )
}

export default Details
