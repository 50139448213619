import { FC } from 'react'
import cn from 'classnames'
import { Segmented as SegmentedBase, SegmentedProps } from 'antd'

import './styles.scss'

const Segmented: FC<SegmentedProps> = ({ className, ...props }) => (
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  <SegmentedBase
    {...props}
    className={cn(className, 'segmented-component')}
    size="large"
  />
)

export default Segmented
