import cn from 'classnames'
import { Button } from 'antd'
import { TButtonDefault as Props } from './types'
import './button.default.scss'

const Default = ({
  children,
  className,
  secondary,
  upperCase,
  small,
  medium,
  isBody,
  inline,
  ...props
}: Props): JSX.Element => {
  return (
    <Button
      className={cn('button-default', 'custom-button', className, {
        secondary,
        upperCase,
        small,
        medium,
        inline,
        'like-body': isBody
      })}
      {...props}
    >
      {children}
    </Button>
  )
}

export default Default
