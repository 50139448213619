import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Forms } from 'components/Forms'
import {
  fetchCommunityInfo,
  updateCommunityInfo
} from 'redux/store/chapterInfo/slice'
import { getUser } from 'redux/store/user/getters'
import BankAccountWrapper from 'features/BankAccount'
import { getBankDetails, getLikToAccount } from '../../pages/Financials/api'
import {
  TResponseDetailsAccount,
  TResponseLinkAccount
} from '../BankAccount/types'
import { ability, ACTIONS, SUBJECTS } from '../Permission'

export const CommunityInfo = () => {
  const user = useSelector(getUser)
  const dispatch = useDispatch()
  const [linkAccount, setLinkAccount] = useState<TResponseLinkAccount>()
  const [dataAccount, setDataAccount] = useState<TResponseDetailsAccount>()
  useEffect(() => {
    if (user?.community && ability.can(ACTIONS.ADD, SUBJECTS.BANK_ACCOUNT)) {
      dispatch(fetchCommunityInfo(user?.community))
      getLikToAccount({
        facility_id: user.community,
        facility_type: 'community'
      }).then((res) => {
        setLinkAccount(res.data)
      })
      getBankDetails({
        facility_id: user.community,
        facility_type: 'community'
      }).then((res) => {
        setDataAccount(res.data)
      })
    }
  }, [])

  const onSubmit = (data) => {
    if (user?.community) {
      dispatch(updateCommunityInfo(data, user?.community))
    }
  }

  return (
    <>
      <Forms.ChapterInfo onSubmit={onSubmit} />
      {user?.community && linkAccount && (
        <div className="row mt-32">
          <BankAccountWrapper
            linkAccount={linkAccount}
            dataAccount={dataAccount}
            type="community"
            uuid={user.community}
          />
        </div>
      )}
    </>
  )
}
