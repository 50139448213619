import DeleteMembersAction from './Action'
import DeleteMembersPopup from './Popup'
import { Can } from 'features/Chat/Providers/ChatAbilityProvider'
import { ACTIONS, chatSubject, SUBJECTS } from 'features/Chat/Abilities'
import { useChatContext } from 'features/Chat/Providers/ChatContextProvider'
import usePopup from 'hooks/usePopup'
import { DeleteMemberPopupProps } from './types'

type Props = Omit<DeleteMemberPopupProps, 'deleteMemberPopup'>

export default (props: Props) => {
  const chatContext = useChatContext()

  const deleteMemberPopup = usePopup({
    title: 'Delete chat member?'
  })

  return (
    <Can
      I={ACTIONS.EDIT}
      a={chatSubject(SUBJECTS.CHAT_MEMBERS, chatContext.state.chat)}
    >
      <DeleteMembersAction deleteMemberPopup={deleteMemberPopup} />
      <DeleteMembersPopup deleteMemberPopup={deleteMemberPopup} {...props} />
    </Can>
  )
}
