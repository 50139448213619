import Fetcher from 'services/Fetcher'
import { API_DICTIONARY, HTTP_METHODS } from 'constants/api'
import {
  HealthSystemCategoriesRequest,
  HealthSystemUsersRequest,
  HealthSystemUsersResponse
} from './types'
import { FilterListResponse } from '../../types'

const fetcher = new Fetcher({})

export const getHealthSystemUsers: HealthSystemUsersRequest = async (
  hsId,
  role,
  data
) =>
  fetcher.request<unknown, HealthSystemUsersResponse>({
    url: `${API_DICTIONARY.MY_COMMUNITY}${API_DICTIONARY.USERS}${API_DICTIONARY.HEALTH_SYSTEM}${hsId}/${role}/`,
    method: HTTP_METHODS.GET,
    ...data
  })

export const getContractCategoriesList: HealthSystemCategoriesRequest = async (
  hsId,
  role
) =>
  fetcher.request<unknown, FilterListResponse>({
    url: `${API_DICTIONARY.MY_COMMUNITY}${API_DICTIONARY.USERS}${API_DICTIONARY.HEALTH_SYSTEM}${hsId}/${role}/${API_DICTIONARY.CONTRACT_CATEGORIES}`,
    method: HTTP_METHODS.GET
  })
