import AddMembersAction from './Action'
import AddMembersPopup from './Popup'
import usePopup from 'hooks/usePopup'
import { Can } from 'features/Chat/Providers/ChatAbilityProvider'
import { ACTIONS, SUBJECTS, chatSubject } from 'features/Chat/Abilities'
import { useChatContext } from 'features/Chat/Providers/ChatContextProvider'

export default () => {
  const chatContext = useChatContext()

  const addMembersPopup = usePopup({
    title: 'Add members to chat'
  })

  return (
    <Can
      I={ACTIONS.EDIT}
      a={chatSubject(SUBJECTS.CHAT_MEMBERS, chatContext.state.chat)}
    >
      <AddMembersAction addMembersPopup={addMembersPopup} />
      <AddMembersPopup addMembersPopup={addMembersPopup} />
    </Can>
  )
}
