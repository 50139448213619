import { Container } from '../../../components/Container/Container'
import { CONTRACT_DETAILS_BREADCRUMBS } from '../../ContractDetails/constants'
import { FC, PropsWithChildren, useMemo } from 'react'
import Actions from '../Actions/Actions'
import useRouter from '../../../hooks/useRouter'

import './styles.scss'
import { useContractBulkTableConfigContext } from '../Providers/ContractBulkTableConfigProvider'
import { Banner } from '../../../components/Banner/Banner'

const ContractBulkContainer: FC<PropsWithChildren> = ({ children }) => {
  const router = useRouter()
  const RenderedActions = useMemo(() => <Actions />, [])
  const { state } = useContractBulkTableConfigContext()
  return (
    <Container
      title={'Contract bulk creation'}
      breadcrumbs={CONTRACT_DETAILS_BREADCRUMBS(
        'Contract bulk creation',
        router.query
      )}
      className="contract-bulk-container column"
      status={null}
      actions={RenderedActions}
      menu={null}
      previentTimerInteraction={false}
      reasonComponent={
        state.errorLabel.length ? (
          <Banner.Error text={state.errorLabel} className="mt-24" />
        ) : null
      }
    >
      {children}
    </Container>
  )
}

export default ContractBulkContainer
