import cn from 'classnames'
import { Input, Form } from 'antd'
import { Props } from './types'
import './input.default.scss'

export const Default = ({ ...props }: Props): JSX.Element => (
  <Form.Item
    className={cn('custom-input', 'input-default', props.className)}
    {...props.propsItem}
    colon={false}
  >
    <Input {...props.propsInput} />
  </Form.Item>
)
