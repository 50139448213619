import Default from './button.default'
import Icon from './button.icon'
import Menu from './button.menu'
import { TButtonDefault as Props } from './types'
import './styles.scss'

const Button = (props: Props) => <Default {...props} />

Button.Default = Default
Button.Icon = Icon
Button.Menu = Menu

export default Button
