import { routes } from '../../router'
import useRouter from '../../hooks/useRouter'

const PrivacyAction = () => {
  const { push } = useRouter()
  const handleAction = (e) => {
    e.stopPropagation()
    e.preventDefault()
    push(routes.privacyPolicy)
  }
  return (
    <span className="privacy-action" onClick={handleAction}>
      Privacy Policy
    </span>
  )
}

export default PrivacyAction
