import { useCallback } from 'react'
import { subject } from '@casl/ability'
import { useAbility } from '@casl/react'
import cn from 'classnames'

import './styles.scss'

import { Typography } from 'components/Typography'
import { useRFPCreateSurveyContext } from 'features/RFP/RFPSurvey/CreateSurvey/Providers/RFPCreateSurveyContextProvider'
import VendorRating from 'features/RFP/RFPSurvey/CreateSurvey/SurveyPreview/VendorRating'
import { useRFPVotingContext } from 'features/RFP/RFPDetails/Providers/RFPVotingContextProvider'
import { AbilityContext, ACTIONS, SUBJECTS } from 'features/Permission'
import { useRFPDetailsContext } from 'features/RFP/RFPDetails/Providers/RFPDetailsContextProvider'
import { useRFPSurveyContext } from 'features/RFP/RFPDetails/Providers/RFPSurveyContextProvider'

const SurveyPreview = () => {
  const ability = useAbility<any>(AbilityContext)

  const rfpDetailsContext = useRFPDetailsContext()
  const rfpSurveyContext = useRFPSurveyContext()
  const rfpCreateSurveyContext = useRFPCreateSurveyContext()
  const rfpVotingContext = useRFPVotingContext()

  const { data } = rfpDetailsContext.state
  const { isSurveySubmitted } = rfpSurveyContext.state
  const { localSelectedVendors } = rfpCreateSurveyContext.state
  const { isVotingInProgress } = rfpVotingContext.state

  const isVotingDisabled =
    ability.cannot(ACTIONS.VOTE, subject(SUBJECTS.SURVEY, { ...data })) ||
    !isVotingInProgress ||
    isSurveySubmitted

  const renderSelectedVendorsRating = useCallback(
    () =>
      localSelectedVendors.map((vendor) => (
        <VendorRating
          key={vendor.uuid}
          vendor={vendor}
          disabled={isVotingDisabled}
        />
      )),
    [isVotingDisabled, localSelectedVendors]
  )

  return (
    <div className="rfp-create-survey-preview survey-preview">
      {isSurveySubmitted || (
        <Typography.Body1 className="required">
          Rate your vendor preference, please
        </Typography.Body1>
      )}
      <div className={cn({ 'survey-preview__ratings': !isSurveySubmitted })}>
        {renderSelectedVendorsRating()}
      </div>
    </div>
  )
}

export default SurveyPreview
