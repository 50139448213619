import Popup from 'components/Popup/Popup'
import Typography from '../../../../components/Typography/Typography'
import { useCognusRepositoryPopup } from '../../Providers/CognusRepositoryPopupProvider'
import { Button, BUTTON_TYPES } from '../../../../components/Button'
import { BTN_TXT } from 'constants/txt'
import { useCognusRepositoryContext } from '../../Providers/CognusRepositoryActions'

type TProps = {
  callback: VoidFunction
}
export const TerminateModal = ({ callback }: TProps) => {
  const { terminateContractPopup } = useCognusRepositoryPopup()
  const { state, actions } = terminateContractPopup
  const { handleTerminateContract } = useCognusRepositoryContext().actions
  const { selectedCategoryName } = useCognusRepositoryContext().state
  return (
    <Popup
      visible={state.visible}
      onCancel={actions.close}
      title={state.title}
      width={460}
      footer={
        <>
          <Button type={BUTTON_TYPES.DEFAULT} onClick={actions.close} upperCase>
            {BTN_TXT.CANCEL}
          </Button>
          <Button
            type={BUTTON_TYPES.PRIMARY}
            htmlType="submit"
            onClick={() => handleTerminateContract(callback)}
            upperCase
          >
            {BTN_TXT.TERMINATE_EVALUATION}
          </Button>
        </>
      }
    >
      <Typography.Body1>
        The evaluation for {selectedCategoryName} will be terminated.
      </Typography.Body1>
    </Popup>
  )
}
