import Input from '../../../../components/Input/Input'
import { PLACEHOLDERS } from '../../../../constants'
import { AddTypeOfEventProps } from './types'

export const AddTypeOfEventOption = ({
  otherType,
  onChangeOtherType,
  onChangeSelectedType
}: AddTypeOfEventProps) => {
  const onChangeInputValue = (e) => {
    onChangeOtherType(e.currentTarget.value)
  }

  const onPressEnter = (e) => {
    onChangeSelectedType(e.currentTarget.value)
  }

  return (
    <div className="calendar__modal__type-of-event-select__add-type-option-container">
      <Input
        propsInput={{
          placeholder: PLACEHOLDERS.OTHER_TYPE,
          onChange: onChangeInputValue,
          value: otherType,
          onPressEnter,
          maxLength: 50
        }}
      />
    </div>
  )
}
