import { Container } from 'components/Container/Container'
import Select from 'components/Select/Select'
import { Charts } from 'features/Dashboard/Charts/Charts'
import { Expirings } from 'features/Dashboard/Tables/Expirings'
import { fetchHealthSystemsList } from 'features/HealthSystems/api'
import { getHealthSystemUsers } from 'features/HealthSystemUsers/api'
import { ROLES } from 'features/Permission/constants'
import { useEffect, useMemo, useState } from 'react'
import { useSelector } from 'react-redux'
import { getCommunityInfo } from 'redux/store/chapterInfo/getters'
import { getUser } from 'redux/store/user/getters'
import './styles.scss'
import DashboardPopupProvider from '../../features/Dashboard/Providers/DashboardPopupProvider'
import DashboardHistoryContextProvider from '../../features/Dashboard/Providers/DashboardHistoryContextProvider'

export const Dashboard = () => {
  const currenUser = useSelector(getUser)
  const communityInfo = useSelector(getCommunityInfo)
  const [healthSystem, setHealthSystem] = useState(
    currenUser?.health_system || ''
  )
  const [user, setUser] = useState('')
  const [hsUsers, setHsUsers] = useState<any>([{ label: 'All', value: '' }])
  const [healthSystems, setHealthSystems] = useState<any>([
    { label: 'All', value: '' }
  ])
  const isFiltersVisible =
    currenUser?.role === ROLES.COMMUNITY_PRESIDENT ||
    currenUser?.role === ROLES.VICE_PRESIDENT

  const isCCP = useMemo(
    () => currenUser.role === ROLES.COMMUNITY_PRESIDENT,
    [currenUser]
  )

  useEffect(() => {
    if (healthSystem && currenUser?.community) {
      getHealthSystemUsers(
        currenUser?.community as string,
        healthSystem as string,
        {
          params: {
            offset: 0,
            roles: [
              `${ROLES.CONTRACT_STEWARD}${
                isCCP ? ',' + ROLES.VICE_PRESIDENT : ''
              }`
            ]
          }
        }
      ).then((resp: any) => {
        if (!!resp?.data?.results?.length) {
          const usersList = [
            { label: 'All', value: '' },
            ...resp.data.results.map((item) => ({
              label: `${item.first_name} ${item.last_name}`,
              value: item.uuid
            }))
          ]
          setHsUsers(usersList)
        } else setHsUsers([{ label: 'All', value: '' }])
      })
    }
  }, [healthSystem, currenUser?.community])

  useEffect(() => {
    if (currenUser?.community) {
      fetchHealthSystemsList(currenUser?.community as string).then(
        (resp: any) => {
          const hsList = !!resp?.data?.results?.length
            ? [
                { label: 'All', value: '' },
                ...resp.data.results.map((item) => ({
                  label: item.name,
                  value: item.uuid
                }))
              ]
            : [{ label: 'All', value: '' }]
          if (currenUser?.health_system) {
            setHealthSystem(
              hsList.find((i) => i.value === currenUser.health_system).value
            )
          }
          setHealthSystems(hsList)
        }
      )
    }
  }, [currenUser?.community])

  return (
    <Container title="Dashboard" subtitle={communityInfo?.name}>
      <DashboardPopupProvider>
        <DashboardHistoryContextProvider>
          <div className="my-projects-dashboard-selectors">
            {isFiltersVisible && (
              <Select
                options={healthSystems}
                propsItem={{
                  label: 'Health System'
                }}
                propsSelect={{
                  onChange: (value) => {
                    setHealthSystem(value)
                    setUser('')
                  },
                  value: healthSystems.find((i) => i.value) ? healthSystem : '',
                  disabled: currenUser.role === ROLES.VICE_PRESIDENT
                }}
              />
            )}
            {isFiltersVisible && (
              <Select
                options={hsUsers}
                propsItem={{
                  label: 'User'
                }}
                propsSelect={{
                  disabled: !healthSystem,
                  onChange: setUser,
                  value: user
                }}
              />
            )}
          </div>
          <Charts
            healthSystem={healthSystem as string}
            user={user}
            isFiltersHidden={!isFiltersVisible}
          />
          <Expirings healthSystem={healthSystem as string} user={user} />
        </DashboardHistoryContextProvider>
      </DashboardPopupProvider>
    </Container>
  )
}
