import { Form } from 'antd'
import { Checkbox } from 'components/Checkbox'
import { Input } from 'components/Input'
import { PLACEHOLDERS } from 'constants/txt'
import { usePRSVotingContext } from '../../Providers/PRSVotingContext'
import { usePRSDetailsContext } from '../../Providers/PRSDetailsContextProvider'
import { useMemo } from 'react'
import { FormInstance } from 'antd/lib/form/hooks/useForm'
import { TQuestionsForm } from 'components/Forms/forms.surveyQuestions.d'

type TProps = {
  options: { label: string; value: string }[]
  isOther: boolean
  name: number
  question?: string
  selectedOptions: string[]
  vote?: boolean
  form: FormInstance<TQuestionsForm>
}
const MultiType = ({
  options,
  isOther,
  name,
  question,
  selectedOptions,
  vote,
  form
}: TProps) => {
  const { state, actions } = usePRSVotingContext()
  const prsContext = usePRSDetailsContext()
  const { isOpen, isCanViewSurveyResponse, votingData } = state
  const { setVotingData } = actions

  const isRequired = useMemo(() => {
    return selectedOptions.find((i) => i === options.slice(-1)?.[0]?.value)
  }, [selectedOptions, options])

  const setOtherOption = (event: any, other?: boolean) => {
    if (!question) return
    let data = [] as string[]
    if (other) {
      const uuid = options.slice(-1)?.[0]?.value
      if (!!selectedOptions.find((i) => i === uuid)) {
        data = selectedOptions.filter((i) => i !== uuid)
      } else {
        data = [...selectedOptions, uuid]
      }
    } else {
      const uuid = isOther ? options.slice(-1)?.[0]?.value : ''
      if (!!selectedOptions.find((i) => i === uuid)) {
        data = [...event, uuid]
      } else {
        data = event
      }
    }
    if (vote) {
      const res = prsContext.state.clinicalRationaleData.questions.map((i) =>
        i.uuid === question
          ? {
              ...i,
              selectedOptions: data,
              options: i.options.map((i) =>
                i.is_other && !data.find((str) => str === i.uuid)
                  ? { ...i, text: '' }
                  : i
              )
            }
          : i
      )
      prsContext.actions.setClinicalRationaleData({
        questions: res
      })
      form.setFieldsValue({ questions: res })
    } else {
      const res = votingData.questions.map((i) =>
        i.uuid === question
          ? {
              ...i,
              selectedOptions: data,
              options: i.options.map((i) =>
                i.is_other && !data.find((str) => str === i.uuid)
                  ? { ...i, text: '' }
                  : i
              )
            }
          : i
      )
      setVotingData({
        questions: res
      })
      form.setFieldsValue({ questions: res })
    }
  }

  return (
    <div className="column align-start gap-16">
      <Form.Item name={[name, 'options']}>
        <Checkbox.Group
          className="multi-questions-preview-container"
          propsGroupCheckbox={{
            value: selectedOptions,
            onChange: setOtherOption,
            options: isOther ? options.slice(0, options?.length - 1) : options,
            disabled: !vote && (!isOpen || isCanViewSurveyResponse)
          }}
        />
        {isOther && (
          <Checkbox
            className="multi-questions-preview-container__checkbox-with-input mt-16"
            propsCheckbox={{
              checked: !!selectedOptions?.find(
                (i) => i === options.slice(-1)?.[0]?.value
              ),
              disabled: !vote && (!isOpen || isCanViewSurveyResponse),
              onChange: (e) => setOtherOption(e, true)
            }}
            propsItem={{
              colon: false
            }}
          >
            {isRequired ? (
              <Input
                propsItem={{
                  name: [name, 'options', options.length - 1, 'text'],
                  rules: [
                    {
                      max: 100,
                      validator: (_, value) => {
                        if (!value?.length && isRequired) {
                          return Promise.reject(
                            new Error('The field cannot be empty')
                          )
                        }

                        return Promise.resolve()
                      }
                    }
                  ]
                }}
                propsInput={{
                  maxLength: 100,
                  disabled: !selectedOptions.find(
                    (i) => i === options.slice(-1)?.[0]?.value
                  ),
                  placeholder: PLACEHOLDERS.OTHER,
                  defaultValue: ''
                }}
              />
            ) : (
              <Input
                propsInput={{
                  disabled: true,
                  placeholder: PLACEHOLDERS.OTHER
                }}
              />
            )}
          </Checkbox>
        )}
      </Form.Item>
    </div>
  )
}

export default MultiType
