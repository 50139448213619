import { useMemo } from 'react'

import './styles.scss'

import { useContractDetailsContext } from 'features/ContractDetails/Providers/ContractDetailsContextProvider'
import ContractDetailsForm from 'features/ContractDetails/ContractDetailsGeneral/ContractDetailsForm/ContractDetailsForm'
import ContractDetailsPreview from 'features/ContractDetails/ContractDetailsGeneral/ContractDetailsPreview/ContractDetailsPreview'
import { ResponsibleUsers } from 'features/ContractDetails/ContractDetailsGeneral/ResponsibleUsers'

const ContractDetailsGeneral = () => {
  const contractDetailsContext = useContractDetailsContext()

  const { canEditContract, isCreation } = contractDetailsContext.state

  const ContractDetails = useMemo(
    () =>
      isCreation || canEditContract ? (
        <ContractDetailsForm />
      ) : (
        <ContractDetailsPreview />
      ),
    [canEditContract, isCreation]
  )

  return (
    <div className="contract-details-general">
      <div className="contract-details-general__details">{ContractDetails}</div>
      {isCreation || (
        <div className="contract-details-general__responsible">
          <ResponsibleUsers />
        </div>
      )}
    </div>
  )
}

export default ContractDetailsGeneral
