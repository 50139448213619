import { notification } from 'antd'
import { ReactComponent as WarningIcon } from 'assets/svg/WarningIcon.svg'
import { TNotification } from './types'
import './notification.warning.scss'
import { ReactComponent as Close24 } from '../../assets/svg/Close24.svg'

export const Warning = ({
  description,
  duration = 5,
  top = 40,
  message,
  placement = 'topRight'
}: TNotification): void => {
  notification.warning({
    placement,
    top,
    icon: <WarningIcon />,
    duration,
    message,
    description,
    closeIcon: <Close24 />
  })
}
