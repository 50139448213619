import Typography from '../../../components/Typography/Typography'
import { TYPOGRAPHY_WEIGHT } from '../../../components/Typography'
const WhoWeAre = () => {
  return (
    <div className="landing-page__who-we-are" id="who-we-serve">
      <Typography.Headline2
        className="landing-page__who-we-are__title"
        weight={TYPOGRAPHY_WEIGHT.MEDIUM}
      >
        Who we serve
      </Typography.Headline2>
      <div className="landing-page__who-we-are__content row space-between">
        <div className="landing-page__who-we-are__content__health-system">
          <Typography.HeadlineD4 weight={TYPOGRAPHY_WEIGHT.BOLD}>
            Health systems
          </Typography.HeadlineD4>
          <Typography.Body1 className="mt-24">
            The development of regional healthcare communities connected by a
            common sourcing strategy bolstered by technology promoting
            efficiencies in supply chain to control non labor expense.
          </Typography.Body1>
        </div>
        <div className="landing-page__who-we-are__content__circle1" />
        <div className="landing-page__who-we-are__content__circle2" />
        <div className="landing-page__who-we-are__content__circle3" />
        <div className="landing-page__who-we-are__content__circle4" />
        <div className="landing-page__who-we-are__content__circle5" />
        <div className="landing-page__who-we-are__content__vendors">
          <Typography.HeadlineD4 weight={TYPOGRAPHY_WEIGHT.BOLD}>
            Vendors
          </Typography.HeadlineD4>
          <Typography.Body1 className="mt-24">
            Healthcare systems and Vendors collaborate using a shared platform
            and philosophy promoting transparency and mutual value between
            healthcare and vendor communities.
          </Typography.Body1>
        </div>
      </div>
    </div>
  )
}

export default WhoWeAre
