import {
  createContext,
  FC,
  PropsWithChildren,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState
} from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { add } from '../../../redux/store/chatHistory/slice'
import { CHAT_TYPE } from 'constants/common'
import { getUser } from 'redux/store/user/getters'
import {
  getChatDetails,
  getChatMembers,
  getCountOfUnreadMessages
} from 'redux/store/chatDetails/getters'
import { setChatDetailsAsync } from 'redux/store/chatDetails/actions'
import { sendMessageRequest, uploadChatAttachments } from 'features/Chat/api'
import { ChatProps } from 'features/Chat/Chat'
import {
  ChatMember,
  IChatDetails,
  TUploadedAttachments
} from 'redux/store/chatDetails/types'
import { updateUnreadMessageCount } from '../../../redux/store/chats/actions'
import { getChatHistorySearchAsync } from '../../../redux/store/chatHistory/actions'
import { IChatHistory } from '../../../redux/store/chatHistory/types'
import { getChatHistory } from '../../../redux/store/chatHistory/getters'
import {
  resetChatDetails,
  resetUnreadMessages,
  resetChatMembers
} from 'redux/store/chatDetails/slice'
import { updateUnreadMessagesAfterSend } from 'redux/store/chats/slice'
import { TDocumentTypeUnion } from '../../../components/FileUpload/types'
import { notification } from '../../../components/Notification'
import { VALIDATION_MESSAGES } from '../../../constants'

type ContextProps<T extends IChatDetails = IChatDetails> = {
  state: {
    chat: T | undefined | null
    members: ChatMember[]
    isChatOwner: boolean
    canSendMessage: boolean
    isSearch: boolean
    searchValue: string
    historyRef: HTMLDivElement | any
    currentSearchValue: IChatHistory | null
    uploadedAttachments: TUploadedAttachments[] | null
    currentFile: TUploadedAttachments
  }
  actions: {
    sendMessageRequestAsync: (
      message: string,
      reply?: string,
      attachments?: string[]
    ) => Promise<void>
    handleIsSearch: (value: boolean) => void
    handleSearchValue: (value: string) => void
    handleCurrentSearchValue: (value: IChatHistory) => void
    handleDeleteAttachment: (uuid: string) => Promise<void>
    handleUploadFile: (
      files: File[],
      documentType: TDocumentTypeUnion
    ) => Promise<void>
  }
}

const ChatContext = createContext<ContextProps>({
  state: null!,
  actions: null!
})

const ChatContextProvider: FC<PropsWithChildren<ChatProps>> = ({
  children,
  ...props
}) => {
  const { id } = props

  const dispatch = useDispatch<any>()

  const historyRef = useRef<HTMLDivElement>(null as any)

  const user = useSelector(getUser)
  const chat = useSelector(getChatDetails())
  const members = useSelector(getChatMembers)
  const unreadMess = useSelector(getCountOfUnreadMessages)
  const messages = useSelector(getChatHistory)
  const [isSearch, setIsSearch] = useState(false)
  const [searchValue, setSearchValue] = useState('')
  const [currentSearchValue, setCurrentSearchValue] =
    useState<IChatHistory | null>(null)

  const [uploadedAttachments, setUploadedAttachments] = useState<
    TUploadedAttachments[] | null
  >(null)

  const [currentFile, setCurrentFile] = useState<TUploadedAttachments>({
    file: '',
    uuid: '',
    error: ''
  })

  const timeoutRef = useRef<NodeJS.Timeout>()

  const isChatOwner = useMemo(
    () => user.uuid === chat?.owner_id,
    [chat?.owner_id, user.uuid]
  )
  const handleDeleteAttachment = useCallback(
    async (uuid: string) => {
      uploadedAttachments &&
        setUploadedAttachments(
          uploadedAttachments?.filter((i) => i.uuid !== uuid)
        )
    },
    [uploadedAttachments]
  )

  const handlePercentage = (val: number) => {
    setCurrentFile((prev) => ({
      file: !!prev?.file ? prev.file : 'name',
      uuid: !!prev?.uuid ? prev.uuid : '1',
      percentage: val || 0
    }))
  }

  const handleIsSearch = (val: boolean) => {
    setIsSearch(val)
  }

  const handleSearchValue = (val: string) => {
    setSearchValue(val)
  }
  const handleCurrentSearchValue = (val: IChatHistory) => {
    setCurrentSearchValue(val)
  }

  const canSendMessage = useMemo(
    () => (chat?.type === CHAT_TYPE.CHANNEL ? isChatOwner : true),
    [chat?.type, isChatOwner]
  )

  const _listenSearchChange = useCallback(() => {
    if (searchValue !== null) {
      if (searchValue.length) {
        dispatch(getChatHistorySearchAsync(id, { search: searchValue }))
      }
    }
  }, [dispatch, searchValue])

  const handleUploadFile = useCallback(
    async (files: File[], documentType: string) => {
      const formData = new FormData()
      formData.append(documentType, files[0], files[0].name)
      setCurrentFile({ ...currentFile, file: files[0].name, uuid: '1' })

      try {
        const response = await uploadChatAttachments(
          {
            document_type: documentType,
            file: formData.get(documentType)
          },
          handlePercentage
        )
        if (!!response?.data) {
          const result = uploadedAttachments || []
          result.push(response?.data)
          setCurrentFile({ file: '', uuid: '' })
          setUploadedAttachments(result)
        }
      } catch (e: any) {
        console.error(e)
        setCurrentFile((prev) => ({ ...prev, error: e?.data?.file[0] }))
      }
    },
    [dispatch, uploadedAttachments, handlePercentage, currentFile]
  )

  useEffect(() => {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current)
    }
    timeoutRef.current = setTimeout(_listenSearchChange, 500)
  }, [_listenSearchChange])

  const sendMessageRequestAsync = useCallback(
    async (message: string, reply?: string, attachments?: string[]) => {
      try {
        if (!chat?.uuid) {
          throw new Error('Chat ID not provided')
        }

        const result = await sendMessageRequest(chat.uuid, {
          body: message || '',
          attachments,
          reply
        })
        if (result?.data) {
          setUploadedAttachments([])
          dispatch(add(result.data))
          dispatch(resetUnreadMessages())
          dispatch(
            updateUnreadMessagesAfterSend({ ...result.data, chat_id: id })
          )
        }
      } catch (e) {
        notification.error({
          message: VALIDATION_MESSAGES.CHAT_DOESNT_EXIST
        })
      }
    },
    [chat?.uuid]
  )

  useEffect(() => {
    dispatch(setChatDetailsAsync(id))
  }, [dispatch, id])

  useEffect(() => {
    if (chat?.unread_messages_count && messages.length) {
      dispatch(updateUnreadMessageCount(id))
    }
  }, [chat?.unread_messages_count, messages.length, unreadMess])

  useEffect(() => {
    handleIsSearch(false)
    handleSearchValue('')
    dispatch(resetChatDetails())
    dispatch(resetChatMembers())
  }, [id])

  const context = useMemo(
    () => ({
      state: {
        chat,
        members,
        isChatOwner,
        canSendMessage,
        isSearch,
        searchValue,
        historyRef,
        currentSearchValue,
        uploadedAttachments,
        currentFile
      },
      actions: {
        sendMessageRequestAsync,
        handleIsSearch,
        handleSearchValue,
        handleCurrentSearchValue,
        handleUploadFile,
        handleDeleteAttachment
      }
    }),
    [
      chat,
      members,
      isChatOwner,
      canSendMessage,
      sendMessageRequestAsync,
      isSearch,
      handleIsSearch,
      historyRef,
      handleCurrentSearchValue,
      currentSearchValue,
      handleUploadFile,
      uploadedAttachments,
      handleDeleteAttachment,
      currentFile
    ]
  )

  return <ChatContext.Provider value={context}>{children}</ChatContext.Provider>
}

export const useChatContext = <T extends IChatDetails = IChatDetails>() =>
  useContext(ChatContext) as ContextProps<T>

export default ChatContextProvider
