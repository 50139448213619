import { ReactElement } from 'react'
import { TCheckInsVendorList } from '../../Providers/types'
import Typography from '../../../../components/Typography/Typography'
import moment from 'moment/moment'
import { allFormatDate } from '../../../../utils/moment'
import { timelineFullDateFormatWitTime } from '../../../../components/Timelines/constants'
import { TYPOGRAPHY_WEIGHT } from '../../../../components/Typography'
import { getDurationHm } from '../../../../helper/dates'
import { useSelector } from 'react-redux'
import { getUser } from '../../../../redux/store/user/getters'

export const HeaderPanel = ({
  checkin
}: {
  checkin: TCheckInsVendorList
}): ReactElement => {
  const user = useSelector(getUser)

  const formattedDateStart = `${moment(checkin.created_at, allFormatDate)
    .zone(user.timezone.offset)
    .format(timelineFullDateFormatWitTime)}`
  const formattedDateEnd = `${moment(checkin.ended_at, allFormatDate)
    .zone(user.timezone.offset)
    .format(timelineFullDateFormatWitTime)}`
  const duration = getDurationHm(checkin.duration)

  return (
    <div className="check-ins-details-container__prev-check-ins__collapse-header column full-width">
      <Typography.Headline6 weight={TYPOGRAPHY_WEIGHT.SEMI_BOLD}>
        {checkin.hospital}
      </Typography.Headline6>
      <div className="check-ins-details-container__prev-check-ins__collapse-header__duration row space-between full-width">
        <Typography.Body2>
          {formattedDateStart} - {formattedDateEnd}
        </Typography.Body2>
        {!!checkin.duration && (
          <Typography.Body2 className="check-ins-details-container__prev-check-ins__collapse-header__duration__value">
            {duration}
          </Typography.Body2>
        )}
      </div>
    </div>
  )
}
