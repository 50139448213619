import { Can } from '../Providers/PrsDetailsAbilityProvider'
import { ACTIONS, SUBJECTS } from '../../Abilities'
import { subject } from '@casl/ability'
import { Button, BUTTON_TYPES } from 'components/Button'
import { usePRSDetailsContext } from '../Providers/PRSDetailsContextProvider'
import { BTN_TXT } from 'constants/txt'
import { PRS_STATUSES } from 'pages/ProductRequests/constants'
import './style.scss'
const StartSurvey = () => {
  const { state, actions } = usePRSDetailsContext()
  const { data } = state
  const { handleChangePrsStatus } = actions

  const onSend = () => {
    handleChangePrsStatus(PRS_STATUSES.SURVEY_CREATION)
  }
  return (
    <>
      <Can I={ACTIONS.START} a={subject(SUBJECTS.SURVEY, { ...data })}>
        <Button type={BUTTON_TYPES.PRIMARY} onClick={onSend} upperCase>
          {BTN_TXT.CREATE_SURVEY}
        </Button>
      </Can>
    </>
  )
}

export default StartSurvey
