import { Avatar } from 'antd'
import { StatusIcon } from '../../../components/IconNameItem/StatusIcon'
import { Typography } from '../../../components/Typography'
import { Chips } from '../../../components/Chips/Chips'
import '../../../components/IconNameItem/styles.scss'
import { formatFirstLastName } from '../../../helper/common'
import moment from 'moment/moment'
import { hoursMinutesFormat } from '../../../utils/moment'
import { VendorCheckInStatus } from '../../../pages/CheckIns/types'
import InfiniteList, {
  ILRenderItem
} from '../../../components/InfiniteList/InfiniteList'
import { FC, useMemo } from 'react'
import { LoadingOutlined } from '@ant-design/icons'
import { useCheckInsListContext } from '../Providers/CheckInsContextProvider'
import { ICheckInWithHeaderCondition } from '../../../redux/store/checkIns/types'
import { ROLES_NAMES } from '../../../constants'
import { EmptyState } from './EmptyState'
import { routes } from '../../../router'
import useRouter from '../../../hooks/useRouter'
import { useParams } from 'react-router-dom'
import cn from 'classnames'
import { timelineDateFormat } from '../../../components/Timelines/constants'
import { useSelector } from 'react-redux'
import { getUser } from '../../../redux/store/user/getters'

export const CheckInsListItemsList = () => {
  const { state, actions } = useCheckInsListContext()

  const { checkIns, isLoading, hasNextPage } = state

  const Loader = useMemo(
    () => <LoadingOutlined className="chat-list-feature-list__spinner" spin />,
    []
  )

  return (
    <InfiniteList<ICheckInWithHeaderCondition>
      items={checkIns}
      loader={Loader}
      renderItem={CheckInsListItem}
      loading={isLoading}
      keyExtractor={(item) => item.uuid}
      hasNextPage={hasNextPage}
      listEmptyComponent={
        <EmptyState
          showIcon
          text={`No vendor check-ins ${state.search?.length ? 'found' : 'yet'}`}
        />
      }
      onLoadMore={actions.handleLoadMore}
      className="check-ins-container__list__item__list"
    />
  )
}

export const CheckInsListItem: FC<ILRenderItem<ICheckInWithHeaderCondition>> = (
  props
) => {
  const router = useRouter()
  const { id } = useParams()
  const user = useSelector(getUser)
  const { item } = props

  const {
    first_name,
    last_name,
    organization_name,
    check_in_status,
    check_in_date,
    showHeader,
    role,
    uuid
  } = item

  const time = moment(check_in_date)
    .zone(user.timezone.offset)
    .format(hoursMinutesFormat)

  const formattedDate = `${moment(check_in_date).format(
    timelineDateFormat
  )} • ${time}`

  const name = formatFirstLastName({
    firstName: first_name,
    lastName: last_name
  })
  const handleDetails = () => {
    router.navigate(`${routes.checkIns}/${uuid}`, { replace: true })
  }

  return (
    <>
      {showHeader && (
        <div className="check-ins-container__list__group-header full-width">
          <Typography.Caption weight="bold">
            {check_in_status === VendorCheckInStatus.ACTIVE
              ? 'Active'
              : 'Inactive'}
          </Typography.Caption>
        </div>
      )}
      <div
        className={cn(
          'icon-name check-ins-container__list__item space-between ',
          check_in_status,
          { selected: id === uuid }
        )}
        onClick={handleDetails}
      >
        <div className="flex row check-ins-container__list__item-left">
          <StatusIcon status={check_in_status}>
            <Avatar src={null} gap={1} alt="user image" size={40}>
              {name}
            </Avatar>
          </StatusIcon>
          <div className="name-container">
            <div className="name-container__row row align-center">
              <Typography.Body1 weight="semi-bold">
                {first_name} {last_name}
              </Typography.Body1>
            </div>
            <Typography.Caption>{ROLES_NAMES[role]}</Typography.Caption>
            <Chips closable={false} grey>
              {organization_name}
            </Chips>
          </div>
        </div>
        <div className="date-time-container">
          <Typography.Label weight="bold">
            checked-{check_in_status === 'active' ? 'in' : 'out'}:
            <br />
            {formattedDate}
          </Typography.Label>
        </div>
      </div>
    </>
  )
}
