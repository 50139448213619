import cn from 'classnames'
import Typography from 'components/Typography/Typography'
import { Link } from 'components/Link'
import { StartChat } from '../../components/StartChat/StartChat'
import { Avatar } from '../../components/Avatar/Avatar'
import { BUTTON_TYPES } from '../../components/Button'
import { routes } from 'router/Config/config.routes'
import { SETTINGS_TABS } from 'pages/Settings/constants'
import { TYPOGRAPHY_WEIGHT } from '../../components/Typography'
import { ReactComponent as Phone16 } from 'assets/svg/Phone16.svg'
import { ReactComponent as Email16 } from 'assets/svg/Email16.svg'
import { TIconFullName } from './types'
import './styles.scss'

export const IconFullName = ({
  title,
  firstName,
  lastName,
  src,
  roleName,
  className,
  phone,
  email,
  uuid,
  onStartChat,
  hideChat
}: TIconFullName) => {
  const name = `${firstName?.charAt(0)}${lastName?.charAt(0)}`.toUpperCase()
  return (
    <div className={cn('hs-details_icon-name_container', className)}>
      {!!title && (
        <Typography.Label className="hs-details_title">
          {title}
        </Typography.Label>
      )}
      <div className="hs-details_icon-name">
        <Avatar name={name} alt="User avatar" src={src} />
        <div className="hs-details_name-container">
          <div className="row align-center gap-8">
            <Link
              to={`${routes.createUserProfile}/${uuid}`}
              state={{ redirectTab: SETTINGS_TABS[2].key }}
            >
              <Typography.Body1
                className="hs-details_user-name"
                weight={TYPOGRAPHY_WEIGHT.SEMI_BOLD}
              >
                {firstName} {lastName}
              </Typography.Body1>
            </Link>
            {onStartChat && uuid && !hideChat && (
              <StartChat
                onClick={() => onStartChat(uuid)}
                type={BUTTON_TYPES.LINK}
              />
            )}
          </div>
          {!!roleName && (
            <Typography.Caption className="hs-details_role-name">
              {roleName}
            </Typography.Caption>
          )}
        </div>
      </div>
      {(!!phone || !!email) && (
        <div className="hs-details__contact-info column gap-4">
          {!!phone && (
            <div className="row align-center gap-12">
              <Phone16 />
              <Typography.Body1>{phone}</Typography.Body1>
            </div>
          )}
          {!!email && (
            <div className="row align-center gap-12">
              <Email16 />
              <Typography.Body1>{email}</Typography.Body1>
            </div>
          )}
        </div>
      )}
    </div>
  )
}
