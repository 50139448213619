import { Avatar } from '../../../components/Avatar/Avatar'
import { TCheckInVendorInfo } from '../Providers/types'
import { formatFirstLastName } from '../../../helper/common'
import Typography from '../../../components/Typography/Typography'
import { TYPOGRAPHY_WEIGHT } from '../../../components/Typography'
import { ROLES_NAMES } from '../../../constants'

const VendorInfo = ({ info }: { info: TCheckInVendorInfo }) => {
  const nameInitials = formatFirstLastName({
    firstName: info.first_name,
    lastName: info.last_name
  })
  return (
    <div className="check-ins-details-container__vendor-info column align-center">
      <div className="check-ins-details-container__vendor-info__avatar">
        <Avatar
          name={nameInitials}
          alt="User avatar"
          src={info.avatar?.file_preview}
        />
      </div>
      <Typography.Headline5
        className="mt-20"
        weight={TYPOGRAPHY_WEIGHT.SEMI_BOLD}
      >
        {info.first_name} {info.last_name}
      </Typography.Headline5>
      <Typography.Body2>
        {ROLES_NAMES[info.role]} at {info.organization_name}
      </Typography.Body2>
      <a
        href={`mailto:${info.email}`}
        className="check-ins-details-container__vendor-info__mail"
      >
        {info.email}
      </a>
      <Typography.Body2 className="check-ins-details-container__vendor-info__phone">
        {info.phone_number}
      </Typography.Body2>
    </div>
  )
}

export default VendorInfo
