import { TVendorInfoForm } from 'components/Forms/forms.vendorInfo.d'
import Fetcher from 'services/Fetcher'
import { API_DICTIONARY, HTTP_METHODS } from 'constants/api'
import { notification } from 'components/Notification'

const fetcher = new Fetcher({})

export const updateVendorInfo = async (data: TVendorInfoForm, uuid: string) =>
  fetcher.request<unknown, TVendorInfoForm>({
    url: `${API_DICTIONARY.VENDORS}${uuid}/`,
    method: HTTP_METHODS.PATCH,
    data,
    notification: notification,
    successMessage: 'Vendor has been successfully saved.'
  })

export const getVendorInfo = async (uuid: string) =>
  fetcher.request<unknown, TVendorInfoForm>({
    url: `${API_DICTIONARY.VENDORS}${uuid}/`,
    method: HTTP_METHODS.GET
  })

export const getCSList = async (vendorUUID: string) =>
  fetcher.request<unknown, any>({
    url: `${API_DICTIONARY.VENDORS}${vendorUUID}/${API_DICTIONARY.USERS}?roles=vendor_contract_steward`,
    method: HTTP_METHODS.GET
  })
