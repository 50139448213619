import {
  createContext,
  FC,
  PropsWithChildren,
  useCallback,
  useContext,
  useEffect,
  useLayoutEffect,
  useMemo,
  useState
} from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import { setLoading } from 'redux/store/common/slice'
import { getUser } from 'redux/store/user/getters'
import useTabs, { TabsReturnActions, TabsReturnState } from 'hooks/useTabs'
import useRouter from '../../../hooks/useRouter'
import {
  CONTRACT_PIPELINE_DETAILS_TABS,
  INITIAL_CPR_DETAILS
} from '../contants'
import {
  deleteHealthSystemRecord,
  deletePipelineRecord,
  getCPRDetailsRequestAsync,
  joinContractRequest,
  optOutContractRequest
} from '../api'
import { ICPRtDetails } from '../types'
import { subject } from '@casl/ability'
import { useAbility } from '@casl/react'
import { ACTIONS, SUBJECTS } from '../Abilities'
import { ContractPipelineDetailsAbilityContext } from './ContractPipelineDetailsAbilityProvider'
import { CONTRACT_PIPELINES_STATUS } from '../../../components/Status'

type ContextProps = {
  state: {
    details: ICPRtDetails
    isCommunity: boolean
    isCommons: boolean
    canEditContract: boolean
    canSeeRfpLink: boolean
    needHSRefresh: boolean
  } & TabsReturnState
  actions: {
    getContractDetailsAsync: () => Promise<void>
    joinContract: (id: string) => Promise<void>
    optOutContract: (id: string) => Promise<void>
    deletePipeline: (id: string) => Promise<void>
    deleteHealthSystem: (
      id: string,
      successCallback: () => void
    ) => Promise<void>
    setNeedHSRefresh: (v: boolean) => void
  } & TabsReturnActions
}

const CPRDetailsContext = createContext<ContextProps>({
  state: null!,
  actions: null!
})

const createRoute = 'create'

const prepareRouteId = (routeId?: string) =>
  routeId ? (routeId === createRoute ? null : routeId) : null

const CPRDetailsContextProvider: FC<PropsWithChildren> = ({ children }) => {
  const dispatch = useDispatch()
  const { id: routeId, tab } = useParams()
  const user = useSelector(getUser)

  const ability = useAbility<any>(ContractPipelineDetailsAbilityContext)

  const { query } = useRouter()

  const [id, setId] = useState<string | null>(prepareRouteId(routeId))
  const [details, setDetails] = useState<ICPRtDetails>(INITIAL_CPR_DETAILS)

  const { state: tabsState, actions: tabsActions } = useTabs({
    tabs: CONTRACT_PIPELINE_DETAILS_TABS,
    activeTab: tab || CONTRACT_PIPELINE_DETAILS_TABS[0].key
  })

  const [needHSRefresh, setNeedHSRefresh] = useState<boolean>(false)

  const isCommunity = useMemo(() => Boolean(user.community), [user.community])
  const isCommons = useMemo(() => Boolean(user.commons), [user.commons])

  const canEditContract = useMemo(() => {
    return (
      details.status !== CONTRACT_PIPELINES_STATUS.IN_PROCESS &&
      ability.can(ACTIONS.EDIT, subject(SUBJECTS.CONTRACT_PIPELINE, details))
    )
  }, [details, ability])

  const canSeeRfpLink = useMemo(() => {
    return ability.can(ACTIONS.VIEW, subject(SUBJECTS.RFP_LINK, { ...details }))
  }, [details, ability])

  const getContractDetailsAsync = useCallback(async () => {
    if (!id) {
      throw new Error('CPR ID not provided')
    }

    try {
      dispatch(setLoading(true))
      const response = await getCPRDetailsRequestAsync(id)
      if (response?.data) {
        setDetails(response?.data)
      }
    } catch (e) {
      console.error(e)
    } finally {
      dispatch(setLoading(false))
    }
  }, [dispatch, id])

  const joinContract = useCallback(async (id: string) => {
    try {
      dispatch(setLoading(true))
      await joinContractRequest(id)
      return getContractDetailsAsync()
    } catch (e) {
      console.error(e)
    } finally {
      dispatch(setLoading(false))
    }
  }, [])

  const optOutContract = useCallback(async (id: string) => {
    try {
      dispatch(setLoading(true))
      await optOutContractRequest(id)
      return getContractDetailsAsync()
    } catch (e) {
      console.error(e)
    } finally {
      dispatch(setLoading(false))
    }
  }, [])

  const _adjustTabsAccessibility = useCallback(() => {
    tabsActions.setTabs(
      CONTRACT_PIPELINE_DETAILS_TABS.map((i) => {
        return {
          ...i
        }
      })
    )
  }, [tabsActions, query, details])

  const deletePipeline = useCallback(async (id: string) => {
    try {
      dispatch(setLoading(true))
      await deletePipelineRecord(id)
    } catch (e) {
      console.error(e)
    } finally {
      dispatch(setLoading(false))
    }
  }, [])

  const deleteHealthSystem = useCallback(
    async (id: string, successCallback: () => void) => {
      try {
        dispatch(setLoading(true))
        await deleteHealthSystemRecord(details?.uuid, id)
        successCallback()
      } catch (e) {
        console.error(e)
      } finally {
        dispatch(setLoading(false))
      }
    },
    [details?.uuid]
  )

  useLayoutEffect(() => {
    _adjustTabsAccessibility()
  }, [_adjustTabsAccessibility])

  useEffect(() => {
    getContractDetailsAsync()
  }, [])

  useEffect(() => {
    setId(prepareRouteId(routeId))
  }, [routeId])

  const context = useMemo(
    () => ({
      state: {
        details,
        ...tabsState,
        isCommons,
        isCommunity,
        canEditContract,
        needHSRefresh,
        canSeeRfpLink
      },
      actions: {
        ...tabsActions,
        getContractDetailsAsync,
        joinContract,
        optOutContract,
        deletePipeline,
        deleteHealthSystem,
        setNeedHSRefresh
      }
    }),
    [
      details,
      tabsState,
      tabsActions,
      getContractDetailsAsync,
      isCommons,
      isCommunity,
      canEditContract,
      joinContract,
      optOutContract,
      deletePipeline,
      deleteHealthSystem,
      needHSRefresh,
      setNeedHSRefresh,
      canSeeRfpLink
    ]
  )

  return (
    <CPRDetailsContext.Provider value={context}>
      {children}
    </CPRDetailsContext.Provider>
  )
}

export const useCPRDetailsContext = () => useContext(CPRDetailsContext)

export default CPRDetailsContextProvider
