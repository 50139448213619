import { useCallback, useEffect, useMemo, useState } from 'react'

import './styles.scss'

import { LABELS } from 'constants/txt'
import { Input } from 'components/Input'
import { Typography } from 'components/Typography'
import { useVendorDetailsContext } from 'features/VendorDetails/Providers/ContextProvider'
import { formatPhoneNumber } from 'helper/common'
import { Form, Radio } from 'antd'
import { useDebounce } from '../../../../hooks/hooks'
import { checkVendorName } from '../../api'
import WarningLabel from './WarningLabel'
import cn from 'classnames'

type TProps = {
  phoneNumber?: string
  isStakeholder?: boolean
  form?: any
}
const VendorForm = ({ ...props }: TProps) => {
  const vendorDetailsContext = useVendorDetailsContext()

  const vendorName = Form.useWatch(['vendor', 'name'], props.form)
  const vendorPermission = Form.useWatch(
    ['vendor', 'vendor_permission'],
    props.form
  )

  const [usedNames, setUsedNames] = useState<string[]>([])

  const debouncedSearch = useDebounce(vendorName, 500)

  const handleVendorName = useCallback(async () => {
    const resp = await checkVendorName({ name: vendorName })
    if (resp?.data) {
      setUsedNames(resp.data)
    }
  }, [vendorName])

  useEffect(() => {
    if (debouncedSearch?.length >= 3) handleVendorName()
  }, [debouncedSearch])

  const disabled = useMemo(
    () => !vendorDetailsContext.state.isCreation,
    [vendorDetailsContext.state.isCreation]
  )

  const getVendorError = useCallback(
    (query: string[]) => vendorDetailsContext.actions.getError(query),
    [vendorDetailsContext.actions]
  )

  const getValidationStatus = useCallback(
    (error?: string) => (error ? 'error' : undefined),
    []
  )

  const NameField = useMemo(
    () => (
      <Input
        propsItem={{
          name: ['vendor', 'name'],
          label: 'Vendor name',
          help: getVendorError(['name']),
          validateStatus: getValidationStatus(getVendorError(['name'])),
          rules: [
            {
              max: 100,
              required: !disabled
            }
          ]
        }}
        propsInput={{
          disabled,
          showCount: true,
          maxLength: 100
        }}
      />
    ),
    [disabled, getValidationStatus, getVendorError]
  )

  const VendorPermissionsField = useMemo(() => {
    return (
      <div
        className={cn('column align-start vendor-permissions-wrapper', {
          view: disabled
        })}
      >
        <Form.Item
          label="Vendor permission"
          name={['vendor', 'vendor_permission']}
        >
          {!disabled ? (
            <Radio.Group className="column" disabled={disabled}>
              <Radio value="full_access" disabled={props.isStakeholder}>
                <Typography.Body1>Full access</Typography.Body1>
              </Radio>
              <Radio value="guest" className="mt-8">
                <Typography.Body1 className="guest-radio-label">
                  Guest{' '}
                  <span>(access only for Product Requests functionality)</span>
                </Typography.Body1>
              </Radio>
            </Radio.Group>
          ) : (
            <>
              {vendorPermission === 'guest' ? (
                <Typography.Body1>
                  Guest{' '}
                  <span>(access only for Product Requests functionality)</span>
                </Typography.Body1>
              ) : (
                <Typography.Body1>Full access</Typography.Body1>
              )}
            </>
          )}
        </Form.Item>
      </div>
    )
  }, [disabled, getValidationStatus, getVendorError, vendorPermission])

  const DescriptionField = useMemo(
    () => (
      <Input.TextArea
        propsItem={{
          name: ['vendor', 'description'],
          label: 'Description',
          help: getVendorError(['description']),
          validateStatus: getValidationStatus(getVendorError(['description'])),
          rules: [
            {
              max: 250
            }
          ]
        }}
        propsTextArea={{
          disabled,
          showCount: true,
          maxLength: 250
        }}
      />
    ),
    [disabled, getValidationStatus, getVendorError]
  )

  const PhoneNumberField = useMemo(
    () => (
      <Input.Phone
        propsItem={{
          name: ['vendor', 'phone_number'],
          label: 'Vendor’s phone number',
          help: getVendorError(['phone_number']),
          validateStatus: getValidationStatus(getVendorError(['phone_number'])),
          rules: [
            {
              pattern: /^[\d]{10,15}$/,
              validateTrigger:
                props?.phoneNumber?.length === 10 ? ['onSubmit'] : ['onBlur']
            }
          ]
        }}
        propsInputNumber={{
          disabled,
          controls: false,
          formatter: formatPhoneNumber,
          prefix: props.phoneNumber ? '+1' : ''
        }}
      />
    ),
    [disabled, getValidationStatus, getVendorError, props.phoneNumber]
  )

  const StreetField = useMemo(
    () => (
      <Input
        propsItem={{
          name: ['vendor', 'address', 'street'],
          label: LABELS.STREET,
          help: getVendorError(['address', 'street']),
          validateStatus: getValidationStatus(
            getVendorError(['address', 'street'])
          )
        }}
        propsInput={{
          disabled
        }}
      />
    ),
    [disabled, getValidationStatus, getVendorError]
  )

  const CityField = useMemo(
    () => (
      <Input
        propsItem={{
          name: ['vendor', 'address', 'city'],
          label: LABELS.CITY,
          help: getVendorError(['address', 'city']),
          validateStatus: getValidationStatus(
            getVendorError(['address', 'city'])
          )
        }}
        propsInput={{
          disabled
        }}
      />
    ),
    [disabled, getValidationStatus, getVendorError]
  )

  const StateField = useMemo(
    () => (
      <Input
        propsItem={{
          name: ['vendor', 'address', 'state'],
          label: LABELS.STATE,
          help: getVendorError(['address', 'state']),
          validateStatus: getValidationStatus(
            getVendorError(['address', 'state'])
          )
        }}
        propsInput={{
          disabled
        }}
      />
    ),
    [disabled, getValidationStatus, getVendorError]
  )

  const ZipCodeField = useMemo(
    () => (
      <Input
        propsItem={{
          name: ['vendor', 'address', 'zip_code'],
          label: LABELS.ZIP_CODE,
          help: getVendorError(['address', 'zip_code']),
          validateStatus: getValidationStatus(
            getVendorError(['address', 'zip_code'])
          )
        }}
        propsInput={{
          disabled
        }}
      />
    ),
    [disabled, getValidationStatus, getVendorError]
  )

  return (
    <div className="vendor-details-form">
      {disabled || <Typography.Headline6>Vendor details</Typography.Headline6>}
      <div className="vendor-details-form__grid-column">
        {VendorPermissionsField}
        {NameField}
        {!!usedNames.length && !disabled && (
          <WarningLabel search={vendorName} usedNames={usedNames} />
        )}
        {DescriptionField}
        <div className="vendor-details-form__grid-row two-columns">
          {PhoneNumberField}
        </div>
        <div className="vendor-details-form__grid-row two-columns">
          {StreetField}
          {CityField}
        </div>
        <div className="vendor-details-form__grid-row two-columns">
          {StateField}
          <div className="vendor-details-form__zip-code-field">
            {ZipCodeField}
          </div>
        </div>
      </div>
    </div>
  )
}

export default VendorForm
