export const RFP_SURVEY_TABS = [
  {
    key: '0',
    label: 'Vendors selection'
  },
  {
    key: '1',
    label: 'Survey preview'
  }
]

export const SURVEY_VENDORS_RATING_RANGE = 3
