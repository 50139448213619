import { SegmentedLabeledOption } from 'antd/lib/segmented'
import { SortOrder } from 'antd/lib/table/interface'
import { TPageInfo } from 'components/Table/types'
import { BASE_SOCKET_EVENT } from 'constants/socket'
import { CHAT_TYPE } from 'constants/common'
import { Avatar } from '../features/UserProfile/types'

export type TMapI<T> = T & {
  value: string
  label: string
}

export type TAddress = {
  city: string
  state: string
  street: string
  zip_code: string
}

export type TMapOptions<T> = TMapI<T>[]
export type Tab = {
  key: string
  label: string
}

export type SegmentOption = SegmentedLabeledOption

export type ResponseWithPagination<T extends object> = {
  count: number
  next?: string
  previous?: string
  results: T[]
}

export type TOptionsResponse = {
  id: string
  value: string
}

export type TOptionsWithNameResponse = {
  uuid: string
  name: string
}

export type TOptionsResponseUuidValue = {
  uuid: string
  value: string
}

export type RequestArgs<TField, TFilter = undefined> = {
  sortField?: keyof TField
  sortOrder?: SortOrder
  filters?: TFilter
  page?: TPageInfo
}

export type TChatTypeUnion = ValueOfObject<typeof CHAT_TYPE>

export type BaseSocketEvent = ValueOfObject<typeof BASE_SOCKET_EVENT>

export type TSortOrder<T extends string> = Record<T, number>

export type TDocumentsErrors = {
  data?: {
    non_field_errors?: string[]
    details?: string[]
    file?: string[]
    field_errors?: {
      [key: string]: string
    }
  }
}

export type TimeZone = {
  name: string
  offset: string
  uuid: string
}

export type ContactPerson = {
  uuid: string
  first_name?: string
  last_name?: string
  email: string
  phone_number?: string
  role: string
  status: string
  avatar_logo?: Avatar | null
  timezone: TimeZone
}

export type LocationStateWithTab = {
  redirectTab?: string
}

export enum FIREBASE_STATUSES {
  COMPLETED = 'completed',
  FAILED = 'failed',
  IN_PROGRESS = 'in_progress'
}
