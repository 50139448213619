import * as Sentry from '@sentry/react'
import qs from 'qs'
import axios, {
  AxiosRequestHeaders,
  AxiosInstance,
  AxiosRequestConfig,
  AxiosResponse,
  AxiosError,
  Method,
  CancelToken
} from 'axios'
import history from 'router/history'
import {
  cleanUpPrivateStorage,
  getLocalAccessToken,
  getLocalLastPage,
  setLocalLastPage
} from 'helper/common'
import { notification } from 'components/Notification'
import { routes } from 'router/Config/config.routes'

interface IConfig {
  baseURL?: string
  timeout?: number
}

interface IRequest<TData> {
  url: string
  baseURL?: string
  method?: Method
  payload?: unknown
  params?: unknown
  cancelToken?: CancelToken
  data?: TData
  paramsSerializer?: AxiosRequestConfig['paramsSerializer']
  headers?: AxiosRequestHeaders
  isNotificationError?: boolean
  notification?: any
  successMessage?: string
  isLogin?: boolean
  onUploadProgress?: (data: any) => void
}

export const API_HOST =
  process.env.NODE_ENV === 'development'
    ? (window as Window).API_HOST
    : process.env.REACT_APP_HOST

export const API_URL = '/api/v1/'

class Fetcher {
  private instance: AxiosInstance

  constructor({
    baseURL = `${API_HOST}${API_URL}`,
    timeout = 60 * 1000
  }: IConfig) {
    this.instance = axios.create({
      baseURL,
      timeout
    })

    this.instance.interceptors.request.use((config) => {
      const token = getLocalAccessToken()
      if (!token) {
        return config
      }

      const headers = {
        authorization: `Bearer ${token}`,
        'X-Content-Type-Options': 'nosniff',
        'Strict-Transport-Security': 'max-age=31536000; includeSubDomains',
        'X-Frame-Options': 'SAMEORIGIN'
      }

      if (
        typeof config.headers === 'object' &&
        config.headers['Content-Type']
      ) {
        headers['Content-Type'] = config.headers['Content-Type']
      }

      return { ...config, headers }
    })

    this.instance.interceptors.response.use(
      (response) => response,
      (error) => {
        Sentry.captureException(error)
        return Promise.reject(error)
      }
    )
  }

  handleErrorData = <TResponse>(data: TResponse) => {
    let message = ''
    const prepareError = (obj: any) => {
      if (typeof obj === 'string') {
        message = obj
      } else {
        Object.keys(obj).forEach((i) => {
          if (
            (Array.isArray(obj[i]) && typeof obj[i][0] === 'object') ||
            Array.isArray(obj)
          ) {
            prepareError(obj[i])
          } else if (typeof obj[i] === 'object') {
            prepareError(obj[i])
          } else if (i === 'non_field_errors') {
            message += obj[i]
          } else {
            message += obj[i]
          }
        })
      }
    }
    prepareError(data)
    notification.error({ message })
  }

  handlerCatch = <TResponse>(
    e: AxiosError<unknown, TResponse>,
    isNotificationError = false,
    isLogin = false
  ) => {
    const { response } = e
    const { status, data } = response as AxiosResponse<TResponse>
    if (status === 401) {
      // cleanUpPrivateStorage()
      const page = getLocalLastPage()
      cleanUpPrivateStorage()
      if (!page) setLocalLastPage(window.location.pathname.substring(1))
      history.push(routes.login)
    }
    if (status === 403 && !isLogin) {
      history.push('/')
    }
    if (isNotificationError && status !== 404 && status !== 500) {
      this.handleErrorData<TResponse>(
        (data as any).detail || (data as any).name
      )
    }
    throw {
      status,
      data
    }
  }

  request = <TData = undefined, TResponse = unknown>({
    method = 'GET',
    url,
    params,
    cancelToken,
    headers,
    baseURL,
    data,
    isNotificationError,
    successMessage,
    isLogin,
    onUploadProgress
  }: IRequest<TData>): Promise<AxiosResponse<TResponse>> => {
    return this.instance
      .request({
        url,
        method,
        params,
        headers,
        cancelToken,
        baseURL,
        data,
        paramsSerializer: (params) =>
          qs.stringify(params, { arrayFormat: 'comma' }),
        onUploadProgress
      })
      .then((resp) => {
        if (successMessage) {
          notification.success({ message: successMessage })
        }
        return resp
      })
      .catch((e: any) =>
        this.handlerCatch<TResponse>(e, isNotificationError, isLogin)
      )
  }
}

export default Fetcher
