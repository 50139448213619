import { Avatar } from '../components/Avatar/Avatar'
import { Typography } from '../components/Typography'
import { ROLES_NAMES } from './common'
import { FC } from 'react'

import { IPartialUserInfo } from '../redux/store/user/types'
type Props = {
  user: IPartialUserInfo
}

const UserItem: FC<Props> = (props) => {
  const { user } = props
  const name = user.first_name + ' ' + user.last_name
  const capitalCharName = `${user.first_name?.charAt(0) || ''}${
    user.last_name?.charAt(0) || ''
  }`.toUpperCase()
  return (
    <div className="rfp-details-responsible-user">
      <div className="rfp-details-responsible-user__user">
        <div className="rfp-details-responsible-user__avatar">
          <Avatar
            name={capitalCharName}
            alt="Responsible user image"
            src={user.avatar?.file_preview}
          />
        </div>
        <div className="rfp-details-responsible-user__content">
          <Typography.Body1 weight="semi-bold">{name}</Typography.Body1>
          <Typography.Caption className="rfp-details-responsible-user__role">
            {ROLES_NAMES[user.role]}
          </Typography.Caption>
        </div>
      </div>
    </div>
  )
}

export default UserItem
