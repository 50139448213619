import General from '../General/General'
import HealthSystemTable from '../HealthSystemList/HealthSystemTable'

export const contractPipelineDetailsRoute = {
  general: 'general',
  hsList: 'hs_list'
}

export const contractPipelineDetailsRoutes = [
  {
    path: contractPipelineDetailsRoute.general,
    component: General
  },
  {
    path: contractPipelineDetailsRoute.hsList,
    component: HealthSystemTable
  }
]
