import { FC, useState, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Table } from '../../../../../components/Table'
import { getChangedLines } from '../../../../../redux/store/rejectedInvoices/getters'
import { isDeepEqualObjects } from '../../../../../utils/common'
import { columns } from './constants'
import { setChangedLines } from '../../../../../redux/store/rejectedInvoices/slice'
import { DEFAULT_PAGE } from '../../../../../components/Table/constants'
import { RejectedInvoiceLine } from '../../types'
import { RejectedInvoiceLinesProps, ChangeLineDataFunc } from './types'
import './styles.scss'

export const RejectedInvoiceLines: FC<RejectedInvoiceLinesProps> = ({
  lines
}) => {
  const [pageInfo, setPageInfo] = useState({ ...DEFAULT_PAGE })
  const changedLines = useSelector(getChangedLines)
  const dispatch = useDispatch()
  const tableData = useMemo(() => {
    if (!!changedLines.length) {
      const newLines = [...lines]
      changedLines.forEach((line) => {
        const idx = newLines.findIndex((i) => i.uuid === line.uuid)
        newLines[idx] = {
          ...newLines[idx],
          ...line
        }
      })
      return newLines
    } else {
      return lines
    }
  }, [lines, changedLines])

  const handleTableChange = (pagination) => {
    setPageInfo({
      pageNumber: pagination.current,
      pageSize: pagination.pageSize
    })
  }

  const handleChangePageSize = (pageSize) => {
    setPageInfo({
      pageNumber: 1,
      pageSize
    })
  }

  const onChangeLineData: ChangeLineDataFunc = (uuid, field, value) => {
    const newData = [...changedLines]
    const initialLine = lines.find((i) => i.uuid === uuid)
    const existedLineIdx = newData.findIndex((i) => i.uuid === uuid)
    if (initialLine) {
      if (existedLineIdx >= 0) {
        const updatedLine = { ...newData[existedLineIdx], [field]: value }
        newData[existedLineIdx] = { ...updatedLine }
        const isEqual = isDeepEqualObjects(updatedLine, initialLine)
        if (isEqual) {
          newData.splice(existedLineIdx, 1)
        }
      } else {
        newData.push({ ...initialLine, [field]: value })
      }
    }
    dispatch(setChangedLines(newData))
  }

  return (
    <Table<RejectedInvoiceLine>
      dataSource={tableData}
      className="rejected-invoices-table"
      columns={columns(onChangeLineData)}
      onChange={handleTableChange}
      onChangePage={handleChangePageSize}
      pageSize={pageInfo.pageSize}
      scroll={{
        x: 'max-content'
      }}
      pagination={{
        pageSize: pageInfo.pageSize,
        current: pageInfo.pageNumber
      }}
    />
  )
}
