import { createSlice, PayloadAction } from '@reduxjs/toolkit'

import { moduleName } from './constants'
import { ICheckIn, ICheckInsState } from './types'

import { ResponseWithPagination } from 'constants/types'
import { formatCheckInsToShowHeader } from './helpers'

const initialState: ICheckInsState = {
  search: null,
  checkIns: [],
  total: 0,
  isLoading: false,
  isLoaded: false,
  error: null
}

const slice = createSlice({
  name: moduleName,
  initialState,
  reducers: {
    loading(state: ICheckInsState) {
      state.isLoading = true
      state.error = null
    },
    finish(state: ICheckInsState) {
      state.isLoading = false
    },
    error(
      state: ICheckInsState,
      action: PayloadAction<{ error: ICheckInsState['error'] }>
    ) {
      const { error } = action.payload
      state.error = error
    },
    set(
      state: ICheckInsState,
      action: PayloadAction<
        ResponseWithPagination<ICheckIn> & Pick<ICheckInsState, 'search'>
      >
    ) {
      state.search = action.payload.search
      state.checkIns = formatCheckInsToShowHeader(action.payload.results)
      state.total = action.payload.count
    },
    bulk(state: ICheckInsState, action: PayloadAction<ICheckIn[]>) {
      state.checkIns = formatCheckInsToShowHeader([
        ...state.checkIns,
        ...action.payload
      ])
    },
    reset(state: ICheckInsState) {
      Object.keys(initialState).forEach((key) => {
        state[key as string] = initialState[key]
      })
    }
  }
})

export default slice.reducer
export const { loading, finish, error, set, bulk, reset } = slice.actions
