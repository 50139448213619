import { Button, BUTTON_TYPES } from '../../../components/Button'
import { BTN_TXT } from '../../../constants'
import { useCognusRepositoryPopup } from '../Providers/CognusRepositoryPopupProvider'

export const InitialClinicalReviewAction = () => {
  const { initiateClinicalReviewPopup } = useCognusRepositoryPopup()
  const { actions } = initiateClinicalReviewPopup

  const handleInitialModal = () => actions.open()
  return (
    <>
      <Button
        type={BUTTON_TYPES.DEFAULT}
        onClick={handleInitialModal}
        upperCase
      >
        {BTN_TXT.RUN_CLINICAL_REVIEW}
      </Button>
    </>
  )
}
