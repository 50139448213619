import { useMemo } from 'react'
import moment from 'moment'

import './styles.scss'

import { fileNameFormatter } from 'helper/common'
import { Attachment } from 'components/Attachment/Attachment'
import { FieldWithLabel } from 'components/FieldWithLabel/FieldWithLabel'
import { timelineDateFormat } from 'components/Timelines/constants'
import { useContractDetailsContext } from 'features/ContractDetails/Providers/ContractDetailsContextProvider'

const ContractExtensionHistory = () => {
  const contractDetailsContext = useContractDetailsContext()

  const { details } = contractDetailsContext.state

  const Documents = useMemo(
    () =>
      details.contract_extension_documents.map((document, idx) => (
        <div
          className="contract-extension-history__document"
          key={document.uuid || idx}
        >
          <div className="contract-extension-history__node" />
          <FieldWithLabel
            className="contract-extension-history__date"
            title={'Extended finish date'}
          >
            {moment(document.finish_date).format(timelineDateFormat)}
          </FieldWithLabel>
          <Attachment
            link={document.file}
            createdAt={document.created_at}
            filename={fileNameFormatter(document.file)}
            disableActions
            showDetails
          />
        </div>
      )),
    [details.contract_extension_documents]
  )

  return (
    <div className="contract-extension-history">
      {Documents}
      <div className="contract-extension-history__document">
        <div className="contract-extension-history__node" />
        <FieldWithLabel
          className="contract-extension-history__date"
          title={'Finish date'}
        >
          {moment(details.primary_finish_date).format(timelineDateFormat)}
        </FieldWithLabel>
      </div>
    </div>
  )
}

export default ContractExtensionHistory
