import { TOverallCompliance } from '../../../../pages/Compliance/types'

export const OVERALL_COLORS = {
  compliant: 'blue',
  non_compliant: 'light_red'
}
export const OVERALL_CHART_COLORS = {
  compliant: '#73A8D3',
  non_compliant: '#F99'
}

export const OVERALL_STATUS_LABEL = {
  compliant: 'Compliant',
  non_compliant: 'Non-compliant'
}

export const getOverallStatusLegendOrder = (data: TOverallCompliance[]) => {
  return data.sort((a, b) => {
    const indexA =
      OVERALL_STATUS_FILTER?.find((i) => a.name === i.value)?.index || 0
    const indexB =
      OVERALL_STATUS_FILTER?.find((i) => b.name === i.value)?.index || 0
    if (indexA < indexB) return -1
    else return 1
  })
}
export const INITIAL_DATA_OVERALL_LEGEND = [
  {
    name: 'compliant',
    amount: 0,
    percentage: '0'
  },
  {
    name: 'non_compliant',
    amount: 0,
    percentage: '0'
  }
]

export const OVERALL_STATUS_FILTER = [
  {
    label: 'Compliant',
    value: 'compliant',
    index: 0
  },
  {
    label: 'Non-compliant',
    value: 'non_compliant',
    index: 1
  }
]
