import { useMemo } from 'react'
import cn from 'classnames'
import { Typography } from 'components/Typography'
import { PASSWORD_STRENGTH } from './constants'
import { IPasswordStrength } from './types'
import './passwordStrength.default.scss'
import { definePasswordStrength } from '../../utils/common'

export const Default = ({
  className,
  value = ''
}: IPasswordStrength): JSX.Element => {
  const strengthLevel = useMemo(() => definePasswordStrength(value), [value])
  return (
    <div className={cn('password-strength', className)}>
      <div className="password-strength__wrapper">
        <div
          className={cn('password-strength__indicator', {
            'password-strength__indicator--weak':
              strengthLevel === PASSWORD_STRENGTH.WEAK,
            'password-strength__indicator--medium':
              strengthLevel === PASSWORD_STRENGTH.MEDIUM,
            'password-strength__indicator--strong':
              strengthLevel === PASSWORD_STRENGTH.STRONG
          })}
        />
      </div>
      {strengthLevel && (
        <Typography.Caption>{strengthLevel} strength</Typography.Caption>
      )}
    </div>
  )
}
