import { Can } from 'features/Permission'
import { ACTIONS, SUBJECTS } from 'features/Permission/constants'
import { Button, BUTTON_TYPES } from '../../../components/Button'
import { BTN_TXT } from '../../../constants'
import { ReactComponent as Plus16 } from 'assets/svg/Plus16.svg'
import { TPropsAddAccount } from '../types'

const AddBankAccount = ({ linkAccount }: TPropsAddAccount) => {
  const isAvailableStatus = true
  const handleBankAccount = () => {
    window.open(linkAccount.url, '_blank')?.focus()
  }

  return (
    <>
      {isAvailableStatus ? (
        <Can I={ACTIONS.ADD} a={SUBJECTS.BANK_ACCOUNT}>
          <Button
            icon={<Plus16 />}
            type={BUTTON_TYPES.GHOST}
            onClick={handleBankAccount}
            upperCase
          >
            {BTN_TXT.ADD_BANK_ACCOUNT}
          </Button>
        </Can>
      ) : null}
    </>
  )
}

export default AddBankAccount
