import { ColumnsType } from 'antd/es/table'
import { SortOrder } from 'antd/es/table/interface'
import { Sorter } from 'components/Sorter/Sorter'
import { Dropdown } from 'components/Dropdown'
import { DescriptionCell } from 'components/DescriptionCell/DescriptionCell'
import { AddressCell } from 'components/AddressCell/AddressCell'
import { Status, HOSPITAL_STATUS_TO_CLASSNAME } from 'components/Status'
import { fetchDepartments } from 'pages/CRUDUser/api'
import { fullFormatPhoneNumber } from 'helper/common'
import { HOSPITAL_STATUSES_OPTIONS } from 'constants/common'
import { ReactComponent as FilterApplied16 } from 'assets/svg/FilterApplied16.svg'
import { ReactComponent as Filter16 } from 'assets/svg/Filter16.svg'
import {
  THospital,
  THospitalsColumnsFunc,
  THospitalsFilters,
  THospitalsFiltersFunc
} from './types'
import LinkAccount from 'features/BankAccount/Link/LinkAccount'

export const columns = ({
  applyFilters,
  filters,
  hsId,
  userId
}: THospitalsColumnsFunc): ColumnsType<THospital> => [
  {
    dataIndex: 'name',
    key: 'name',
    width: 200,
    title: ({ sortOrder, sortColumn }) => (
      <Sorter<THospital>
        dataIndex="name"
        sortColumn={sortColumn}
        sortOrder={sortOrder as SortOrder}
      >
        Hospital
      </Sorter>
    ),
    sorter: true,
    showSorterTooltip: false
  },
  {
    dataIndex: 'tax_id',
    key: 'tax_id',
    width: 180,
    title: ({ sortOrder, sortColumn }) => (
      <Sorter<THospital>
        dataIndex="tax_id"
        sortColumn={sortColumn}
        sortOrder={sortOrder as SortOrder}
      >
        Tax ID
      </Sorter>
    ),
    sorter: true,
    showSorterTooltip: false
  },
  {
    dataIndex: 'status',
    key: 'status',
    width: 130,
    title: ({ sortOrder, sortColumn }) => (
      <Sorter<THospital>
        dataIndex="status"
        sortColumn={sortColumn}
        sortOrder={sortOrder as SortOrder}
      >
        Status
      </Sorter>
    ),
    filterDropdown: (props) => (
      <Dropdown.Filter<THospitalsFilters, THospitalsFiltersFunc, unknown>
        items={HOSPITAL_STATUSES_OPTIONS}
        applyFilters={applyFilters}
        appliedFilters={filters}
        field="statuses"
        {...props}
      />
    ),
    render: (_, { status }) => (
      <Status.Default className={HOSPITAL_STATUS_TO_CLASSNAME[status]}>
        {status}
      </Status.Default>
    ),
    filterIcon: !!filters?.statuses?.length ? (
      <FilterApplied16 />
    ) : (
      <Filter16 />
    ),
    filterSearch: true,
    sorter: true,
    showSorterTooltip: false
  },
  {
    dataIndex: 'is_connected_to_payment_system',
    key: 'is_connected_to_payment_system',
    width: 192,
    title: ({ sortOrder, sortColumn }) => (
      <Sorter<THospital>
        dataIndex="is_connected_to_payment_system"
        sortColumn={sortColumn}
        sortOrder={sortOrder as SortOrder}
      >
        Bank account
      </Sorter>
    ),
    render: (_, { is_connected_to_payment_system, uuid }) =>
      is_connected_to_payment_system ? (
        <LinkAccount type="hospital" uuid={uuid} />
      ) : (
        '-'
      ),
    sorter: true,
    showSorterTooltip: false
  },
  {
    dataIndex: 'email',
    key: 'email',
    width: 200,
    title: ({ sortOrder, sortColumn }) => (
      <Sorter<THospital>
        dataIndex="email"
        sortColumn={sortColumn}
        sortOrder={sortOrder as SortOrder}
      >
        Email
      </Sorter>
    ),
    sorter: true,
    showSorterTooltip: false
  },
  {
    dataIndex: 'phone_number',
    key: 'phone_number',
    width: 200,
    title: ({ sortOrder, sortColumn }) => (
      <Sorter<THospital>
        dataIndex="phone_number"
        sortColumn={sortColumn}
        sortOrder={sortOrder as SortOrder}
      >
        Phone #
      </Sorter>
    ),
    render: (_, { phone_number }) =>
      phone_number ? fullFormatPhoneNumber(phone_number) : '-',
    sorter: true,
    showSorterTooltip: false
  },
  {
    dataIndex: 'address',
    key: 'address',
    width: 240,
    title: ({ sortOrder, sortColumn }) => (
      <Sorter<THospital>
        dataIndex="address"
        sortColumn={sortColumn}
        sortOrder={sortOrder as SortOrder}
      >
        Address
      </Sorter>
    ),
    render: (_, { address }) => <AddressCell address={address} />,
    sorter: true,
    showSorterTooltip: false
  },
  {
    dataIndex: 'departments',
    key: 'departments',
    width: 240,
    title: ({ sortOrder, sortColumn }) => (
      <Sorter<THospital>
        dataIndex="departments"
        sortColumn={sortColumn}
        sortOrder={sortOrder as SortOrder}
      >
        Departments
      </Sorter>
    ),
    filterDropdown: (props) => (
      <Dropdown.Filter<THospitalsFilters, THospitalsFiltersFunc, unknown>
        applyFilters={applyFilters}
        appliedFilters={filters}
        searchCallback={() => fetchDepartments(userId, hsId)}
        field="departments"
        asyncSearch
        {...props}
      />
    ),
    render: (_, { departments }) => {
      const text = departments.map((i) => i.name).join(', ')
      return <DescriptionCell content={text} text={text} />
    },
    filterIcon: !!filters?.departments?.length ? (
      <FilterApplied16 />
    ) : (
      <Filter16 />
    ),
    filterSearch: true,
    sorter: true,
    showSorterTooltip: false
  }
]
