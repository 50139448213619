import { Typography } from 'components/Typography'

const Empty = () => {
  return (
    <div className="chat-empty">
      <Typography.Headline6 className="chat-empty__text">
        Select chat to start messaging
      </Typography.Headline6>
    </div>
  )
}

export default Empty
