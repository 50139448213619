import Fetcher from '../../../../services/Fetcher'
import { API_DICTIONARY, HTTP_METHODS } from '../../../../constants'
import { CommunityData, CommunityDetailsRequest } from './types'

const fetcher = new Fetcher({})

export const getCommunityDetails: CommunityDetailsRequest = async (
  id,
  params
) =>
  fetcher.request<unknown, CommunityData>({
    url: `${API_DICTIONARY.MY_COMMUNITY}${API_DICTIONARY.COMMUNITIES}${id}/`,
    method: HTTP_METHODS.GET,
    params
  })
