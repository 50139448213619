import { usePRSDetailsPopupContext } from '../Providers/PRSDetailsPopupProvider'
import { usePRSDetailsContext } from '../Providers/PRSDetailsContextProvider'
import Popup from '../../../../components/Popup/Popup'
import { Button, BUTTON_TYPES } from '../../../../components/Button'
import { BTN_TXT, PLACEHOLDERS } from '../../../../constants'
import {
  Typography,
  TYPOGRAPHY_WEIGHT
} from '../../../../components/Typography'
import Input from '../../../../components/Input/Input'
import { formatName } from '../../../../helper/common'
import { useMemo } from 'react'
import './style.scss'
import { Form } from 'antd'
import { useDispatch } from 'react-redux'
import { setLoading } from '../../../../redux/store/common/slice'
import { createContractGuest } from '../../../../pages/ProductRequests/api'
import { PRS_STATUSES_MESSAGES } from '../../../../pages/ProductRequests/constants'
import { notification } from '../../../../components/Notification'

export type TContractValues = {
  first_name: string
  last_name: string
  email: string
}

const GuestContract = () => {
  const dispatch = useDispatch()
  const { createContractForVendorPopup } = usePRSDetailsPopupContext()
  const prsDetailsContext = usePRSDetailsContext()

  const [contractCreation] = Form.useForm()

  const { state, actions } = createContractForVendorPopup
  const onSend = (values: TContractValues) => {
    dispatch(setLoading(true))
    createContractGuest(prsDetailsContext.state.data.uuid, { ...values })
      .then(() => {
        prsDetailsContext.actions.handleGetPRSData()
        notification.success({
          message: `${PRS_STATUSES_MESSAGES.archived}`
        })
        contractCreation.setFieldsValue({ decline_reason: '' })
      })
      .catch((err) => {
        notification.error({
          message: err.data?.details[0]
        })
      })
      .finally(() => {
        dispatch(setLoading(false))
        actions.close()
      })
  }

  const first_name = Form.useWatch('first_name', contractCreation)
  const last_name = Form.useWatch('last_name', contractCreation)
  const email = Form.useWatch('email', contractCreation)

  const isDisabled = useMemo(() => {
    return first_name && last_name && email
  }, [first_name, last_name, email])
  return (
    <Popup
      visible={state.visible}
      onCancel={actions.close}
      title={`Create contract?`}
      width={442}
      footer={
        <>
          <Button onClick={actions.close} type={BUTTON_TYPES.DEFAULT} upperCase>
            {BTN_TXT.CANCEL}
          </Button>
          <Button
            type={BUTTON_TYPES.PRIMARY}
            form="contract-creation-form"
            htmlType="submit"
            disabled={!isDisabled}
            upperCase
          >
            {BTN_TXT.INVITE_USER_CREATE_CONTRACT}
          </Button>
        </>
      }
    >
      <>
        <Typography.Body1 weight={TYPOGRAPHY_WEIGHT.NORMAL}>
          Product request review has been completed and contract will be
          created. Vendor permission will be changed to Full access, which
          requires Vendor contract admin to be created.
          <br />
          <br />
          Please provide user details below to proceed.
        </Typography.Body1>
        <Typography.Headline6 className="mt-32" weight={TYPOGRAPHY_WEIGHT.BOLD}>
          Vendor contract admin for {prsDetailsContext.state.data.vendor?.name}
        </Typography.Headline6>
        <Form
          id="contract-creation-form"
          form={contractCreation}
          onFinish={onSend}
          layout="vertical"
        >
          <Input
            propsItem={{
              name: 'first_name',
              label: 'First name',
              rules: [
                {
                  required: true,
                  pattern: /^[-\w\s]+$/,
                  max: 40
                }
              ],
              normalize: formatName
            }}
            propsInput={{
              showCount: true,
              maxLength: 40
            }}
          />
          <Input
            propsItem={{
              name: 'last_name',
              label: 'Last name',
              rules: [
                {
                  required: true,
                  pattern: /^[-\w\s]+$/,
                  max: 40
                }
              ]
            }}
            propsInput={{
              showCount: true,
              maxLength: 40
            }}
          />
          <Input
            propsItem={{
              name: 'email',
              label: 'Email',
              rules: [
                {
                  required: true,
                  type: 'email',
                  validateTrigger: 'onSubmit'
                }
              ]
            }}
            propsInput={{
              placeholder: PLACEHOLDERS.EMAIL
            }}
          />
        </Form>
      </>
    </Popup>
  )
}

export default GuestContract
