import Fetcher from 'services/Fetcher'
import { API_DICTIONARY, HTTP_METHODS } from 'constants/api'
import { TGetVendorListRequest, TVendorList } from './types'
import { ResponseWithPagination } from 'constants/types'

const fetcher = new Fetcher({})

export const getVendorListRequest: TGetVendorListRequest = async (data) =>
  fetcher.request<typeof data, ResponseWithPagination<TVendorList>>({
    url: `${API_DICTIONARY.MY_COMMUNITY}${API_DICTIONARY.VENDORS}`,
    method: HTTP_METHODS.GET,
    ...data
  })
