export const TYPOGRAPHY_WEIGHT = {
  BOLD: 'bold',
  MEDIUM: 'medium',
  SEMI_BOLD: 'semi-bold',
  NORMAL: 'normal'
} as const

export const TYPOGRAPHY_ALIGNMENT = {
  CENTER: 'center',
  LEFT: 'left',
  RIGHT: 'right'
} as const
