import { Forms } from '../../components/Forms'
import SignPopup from 'features/SignPopup/SignPopup'
import { useState } from 'react'
import Typography from '../../components/Typography/Typography'
import useRouter from '../../hooks/useRouter'

export const CompleteProfile = () => {
  const router = useRouter()
  const [email] = useState(router.query.email)
  return (
    <SignPopup email={email}>
      <Typography.Body1 className="mt-24">
        Please, complete your profile
      </Typography.Body1>
      <Forms.CompleteProfile />
    </SignPopup>
  )
}
