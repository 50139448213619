import { State } from '../../type'
import { moduleName } from './constants'
import { ICommonState } from './types'

const getState = (state: State): ICommonState => state[moduleName]

export const getIsLoading = (state: State) => {
  const stateSlice = getState(state)
  return stateSlice.isLoading
}

export const getLoaderProps = (state: State) => {
  const stateSlice = getState(state)
  return stateSlice.loaderProps
}
