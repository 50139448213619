export const HISTORY_TITLES = {
  prs_created: 'created the Product request.',
  prs_canceled: 'canceled the request.',
  prs_declined: 'declined the request.',
  rca_approve: 'approved the request.',
  rca_decline: 'declined the request.',
  cs_approved: 'approved the request.',
  cs_declined: 'declined the request',
  trial_requested: 'changed the status.',
  trial_pre_approval: 'changed the status.',
  contract_created: 'created a contract.',
  status_changed: 'changed the status.'
}
export const HISTORY_COMMENT_TYPE = {
  rca_decline: 'RCA has declined the product request.',
  trial_requested: 'Trial requested',
  trial_pre_approval: 'Trial pre-approval'
}
