import {
  Ability,
  AbilityBuilder,
  ForcedSubject,
  MongoQuery,
  subject
} from '@casl/ability'
import { ACTIONS, SUBJECTS } from 'features/Chat/Abilities/constants'
import { IChat } from 'redux/store/chats/types'

export type Actions = ValueOfObject<typeof ACTIONS>
export type Subjects = ValueOfObject<typeof SUBJECTS>

export type PossibleAbilities = [
  Actions,
  Subjects | (IChat & ForcedSubject<Subjects>)
]

export type Conditions =
  | Record<PropertyKey, number | string | MongoQuery>
  | MongoQuery<Record<PropertyKey, number | string | MongoQuery>>

export type ChatItemAbility = Ability<PossibleAbilities, Conditions>

export type ChatItemAbilityBuilder = AbilityBuilder<ChatItemAbility>

export const chatSubject = (type, object) =>
  subject<Subjects, IChat>(type, { ...object })
