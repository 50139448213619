import { FC, PropsWithChildren, createContext, useContext } from 'react'

import { UseHSTableReturnType } from 'features/ContractDetails/HSList/hooks/useHSTable'

type ContextProps = UseHSTableReturnType

const HSTableContext = createContext<ContextProps>(null!)

const HSTableProvider: FC<PropsWithChildren & ContextProps> = (props) => {
  const { children, ...contextValueProps } = props

  return (
    <HSTableContext.Provider value={contextValueProps}>
      {children}
    </HSTableContext.Provider>
  )
}

export const useHSTableContext = () => useContext(HSTableContext)

export default HSTableProvider
