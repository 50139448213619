import { Form } from 'antd'
import { useParams } from 'react-router-dom'

import './styles.scss'

import { BTN_TXT } from 'constants/txt'
import { Popup } from 'components/Popup'
import { Button, BUTTON_TYPES } from 'components/Button'
import AddMembers from 'features/ChatDetails/Members/AddMembers/AddMembers'
import { AddMembersForm } from 'features/ChatDetails/Members/AddMembers/types'
import { PopupReturnType } from 'hooks/usePopup'

type Props = {
  addMembersPopup: PopupReturnType
}

export default (props: Props) => {
  const { addMembersPopup } = props
  const { id } = useParams()
  const [form] = Form.useForm<AddMembersForm>()

  const members = Form.useWatch('members', form)

  return id ? (
    <Popup
      className="add-chat-members-popup"
      {...addMembersPopup.state}
      width={442}
      footer={
        <>
          <Button
            type={BUTTON_TYPES.DEFAULT}
            onClick={addMembersPopup.actions.close}
            upperCase
          >
            {BTN_TXT.CANCEL}
          </Button>
          <Button
            type={BUTTON_TYPES.PRIMARY}
            htmlType="submit"
            form="add-chat-members-form"
            upperCase
            disabled={!members?.length}
          >
            {BTN_TXT.ADD_TO_CHAT}
          </Button>
        </>
      }
    >
      <AddMembers form={form} chatId={id} addMembersPopup={addMembersPopup} />
    </Popup>
  ) : null
}
