import {
  createContext,
  FC,
  PropsWithChildren,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState
} from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { setLoading } from 'redux/store/common/slice'
import FullCalendar from '@fullcalendar/react'
import moment, { Moment } from 'moment'
import {
  RespondToTheMeeting,
  TCalendarDatePeriod,
  TCalendarEventDetails,
  TCalendarEventListType,
  TDeleteEventDetailsProps,
  TQueryCalendarListParams
} from './types'
import { convertDateToRequestFormat } from '../../utils/moment'
import {
  deleteCalendarEvent,
  getCalendarEventDetails,
  getCalendarEventList,
  respondToTheEvent
} from './api'
import { EventTypes, TCalendarEventViewType } from 'components/Calendar/types'
import {
  parseEventDataToCalendarView,
  parseEventDetailsToDetailedView
} from './helpers'
import { useLocation } from 'react-router-dom'
import { getUser } from '../../redux/store/user/getters'
import { getLocalCalendarView, setLocalCalendarView } from '../../helper/common'
import { CALENDAR_MODE_SWITCH_OPTIONS } from '../../features/Calendar/CalendarHeader/constants'
import { routes } from '../../router'
import { useNavigate } from 'react-router'

type ContextProps = {
  state: {
    calendarRef: React.RefObject<FullCalendar> | null
    currentMonthLabel: string
    isTodayDisabled: boolean
    calendarEvents: TCalendarEventViewType[]
    calendarView: string
    calendarEventDetails: TCalendarEventDetails | null
    queryEventId: string
  }
  actions: {
    goToNextMonth: () => void
    goToPrevMonth: () => void
    goToToday: () => void
    getEventDetails: (id: string | null) => void
    appendToCalendarEvents: (data: TCalendarEventListType[]) => void
    amendToCalendarEvents: (data: TCalendarEventListType) => void
    deleteEventDetails: (
      props: TDeleteEventDetailsProps,
      callback?: () => void
    ) => void
    respondToTheMeeting: (id: string, status: RespondToTheMeeting) => void
    changeCalendarView: (value: string) => void
    setQueryEventId: (value: string) => void
  }
}

const CalendarEventsContext = createContext<ContextProps>({
  state: null!,
  actions: null!
})

const CalendarEventsContextProvider: FC<PropsWithChildren> = ({ children }) => {
  const navigate = useNavigate()
  const calendarRef = useRef<FullCalendar>(null)
  const user = useSelector(getUser)
  const [_fetchedEventsPeriod, _setFetchedEventsPeriod] =
    useState<TCalendarDatePeriod | null>(null)
  const [calendarEvents, _setCalendarEvents] = useState<
    TCalendarEventViewType[]
  >([])
  const [_currentMonthTimesheet, _setCurrentMonthTimesheet] = useState<Moment>(
    moment()
  )
  const { search } = useLocation()

  const [queryEventId, setQueryEventId] = useState('')

  useEffect(() => {
    setQueryEventId(new URLSearchParams(search).get('event_id') || '')
  }, [])

  const [calendarView, setCalendarView] = useState<string>('')

  const [calendarEventDetails, setCalendarEventDetails] =
    useState<TCalendarEventDetails | null>(null)
  const dispatch = useDispatch()

  const uniqueEventIds = useMemo(
    () => calendarEvents.map((event) => event.id),
    [calendarEvents]
  )

  const appendToCalendarEvents = useCallback(
    (events: TCalendarEventListType[]) => {
      // get only unique events - avoid duplicates
      const uniqueEvents = events.filter(
        (event) => !uniqueEventIds.includes(event.uuid)
      )
      const parsedEventList = parseEventDataToCalendarView(
        uniqueEvents,
        user.timezone.offset
      )
      _setCalendarEvents((prevEvents) => [...prevEvents, ...parsedEventList])
    },
    [uniqueEventIds, user]
  )

  const amendToCalendarEvents = useCallback(
    (event: TCalendarEventListType) => {
      const parsedEventList = parseEventDataToCalendarView(
        [event],
        user.timezone.offset
      )
      _setCalendarEvents((prevEvents) =>
        prevEvents.map((item) =>
          item.id === parsedEventList[0].id ? parsedEventList[0] : item
        )
      )
    },
    [uniqueEventIds, user]
  )

  useEffect(() => {
    const view = getLocalCalendarView()
    setCalendarView(view || CALENDAR_MODE_SWITCH_OPTIONS[0].value)
  }, [])

  const changeCalendarView = (value: string) => {
    setCalendarView(value)
    setLocalCalendarView(value)
  }

  useEffect(() => {
    if (calendarView === CALENDAR_MODE_SWITCH_OPTIONS[0].value) {
      const api = calendarRef.current?.getApi()
      if (api) api.gotoDate(_currentMonthTimesheet.toISOString())
    }
  }, [calendarView, _currentMonthTimesheet])

  const getEventsList = useCallback(
    (dates: TCalendarDatePeriod) => {
      const { startDate, endDate } = dates
      const params: TQueryCalendarListParams = {
        start_date: convertDateToRequestFormat(startDate),
        end_date: convertDateToRequestFormat(endDate)
      }
      dispatch(setLoading(true))

      getCalendarEventList(params)
        .then((res) => {
          if (res.data?.results) {
            appendToCalendarEvents(res.data.results)
          }
        })
        .finally(() => {
          dispatch(setLoading(false))
        })
    },
    [dispatch, appendToCalendarEvents]
  )

  useEffect(() => {
    if (queryEventId) {
      getEventDetails(queryEventId, true)
      navigate(`${routes.calendar}`, { replace: true })
    }
  }, [queryEventId])

  const getEventDetails = useCallback(
    (id: string | null, withQuery?: boolean) => {
      if (!id) {
        setCalendarEventDetails(null)
        return
      }

      dispatch(setLoading(true))

      getCalendarEventDetails(id)
        .then((res) => {
          const details = parseEventDetailsToDetailedView(
            res.data,
            user.timezone.offset
          )
          if (!!queryEventId && withQuery) {
            _setCurrentMonthTimesheet(moment(details.start_date))
            getEventsList({
              startDate: moment(details.start_date)
                .subtract(1, 'month')
                .startOf('month'),
              endDate: moment(details.start_date).add(1, 'month').endOf('month')
            })
          } else {
            setCalendarEventDetails(details)
          }
        })
        .finally(() => {
          dispatch(setLoading(false))
        })
    },
    [dispatch, user, queryEventId]
  )

  const deleteEventDetails = useCallback(
    ({ id, sendEmails }: TDeleteEventDetailsProps, callback?: () => void) => {
      dispatch(setLoading(true))

      deleteCalendarEvent(id, sendEmails ?? false)
        .then(() => {
          _setCalendarEvents(calendarEvents.filter((event) => event.id !== id))
          setCalendarEventDetails(null)
          callback && callback()
          dispatch(setLoading(false))
        })
        .finally(() => {
          dispatch(setLoading(false))
        })
    },
    [calendarEvents]
  )

  const initialDataFetch = useCallback(() => {
    // initially fetching three months - current, one month above current and one month below
    const threeMonthPeriod: TCalendarDatePeriod = {
      startDate: moment().subtract(1, 'month').startOf('month'),
      endDate: moment().add(1, 'month').endOf('month')
    }
    _setFetchedEventsPeriod(threeMonthPeriod)
    getEventsList(threeMonthPeriod)
  }, [getEventsList])

  const monthChangeFetch = useCallback(
    (target: Moment) => {
      // init fetch is performed separately
      // we won't have any variables set before it completed
      if (target.isSame(moment(), 'month') || !_fetchedEventsPeriod) {
        return
      }
      // we always need to have at least one month above current and one month below
      // so checking current bounds based on target threeMonthPeriod bounds
      const threeMonthPeriod: TCalendarDatePeriod = {
        startDate: moment(target).subtract(1, 'month').startOf('month'),
        endDate: moment(target).add(1, 'month').endOf('month')
      }

      // lower bound - we're looking for _fetchedEventsPeriod.startDate
      // if our target bound is lower than startDate - we'd need to fetch missing month
      if (
        threeMonthPeriod.startDate
          .startOf('month')
          .isBefore(_fetchedEventsPeriod?.startDate)
      ) {
        // update lower bound of fetched dates
        _setFetchedEventsPeriod(
          (prevDates) =>
            prevDates && {
              ...prevDates,
              startDate: moment(threeMonthPeriod.startDate).startOf('month')
            }
        )
        // fetch missing month
        getEventsList({
          startDate: moment(threeMonthPeriod.startDate).startOf('month'),
          endDate: moment(threeMonthPeriod.startDate).endOf('month')
        })
        // upper bound - we're looking for _fetchedEventsPeriod.endDate
        // if our target bound is more upper than endDate - we'd need to fetch missing month
      } else if (
        threeMonthPeriod.endDate
          .endOf('month')
          .isAfter(_fetchedEventsPeriod?.endDate)
      ) {
        // update upper bound of fetched dates
        _setFetchedEventsPeriod(
          (prevDates) =>
            prevDates && {
              ...prevDates,
              endDate: moment(threeMonthPeriod.endDate).endOf('month')
            }
        )
        // fetch missing month
        getEventsList({
          startDate: moment(threeMonthPeriod.endDate).startOf('month'),
          endDate: moment(threeMonthPeriod.endDate).endOf('month')
        })
      }
    },
    [getEventsList, _fetchedEventsPeriod]
  )

  const goToNextMonth = useCallback(() => {
    const api = calendarRef.current?.getApi()
    _setCurrentMonthTimesheet((prevState) => moment(prevState.add(1, 'month')))
    if (api) {
      api.next()
    }
  }, [])

  const goToPrevMonth = useCallback(() => {
    const api = calendarRef.current?.getApi()
    _setCurrentMonthTimesheet((prevState) =>
      moment(prevState.subtract(1, 'month'))
    )
    if (api) {
      api.prev()
    }
  }, [])

  const goToToday = useCallback(() => {
    const api = calendarRef.current?.getApi()

    _setCurrentMonthTimesheet(moment())
    if (api) {
      api.today()
    }
  }, [])

  const respondToTheMeeting = useCallback(
    (id: string, status: RespondToTheMeeting) => {
      dispatch(setLoading(true))

      respondToTheEvent(id, status)
        .then(() => {
          if (status === RespondToTheMeeting.DECLINE) {
            _setCalendarEvents(
              calendarEvents.filter((event) => event.id !== id)
            )
            getEventDetails(null)
          } else {
            _setCalendarEvents(
              calendarEvents.map((calendarEvent) => {
                if (calendarEvent.id === id) {
                  return {
                    ...calendarEvent,
                    className:
                      status === RespondToTheMeeting.ACCEPT
                        ? EventTypes.ACTIVE
                        : EventTypes.MAYBE
                  }
                } else return calendarEvent
              })
            )
          }
        })
        .finally(() => {
          setCalendarEventDetails(null)
          dispatch(setLoading(false))
        })
    },
    [calendarEvents]
  )

  const currentMonthLabel = useMemo(
    () => _currentMonthTimesheet.format('MMMM YYYY'),
    [_currentMonthTimesheet]
  )

  const isTodayDisabled = useMemo(
    () => _currentMonthTimesheet.month() === moment().month(),
    [_currentMonthTimesheet]
  )

  useEffect(() => {
    initialDataFetch()
  }, [])

  useEffect(() => {
    monthChangeFetch(_currentMonthTimesheet)
  }, [_currentMonthTimesheet, _fetchedEventsPeriod])

  const context = useMemo(
    () => ({
      state: {
        calendarRef,
        currentMonthLabel,
        isTodayDisabled,
        calendarEvents,
        calendarView,
        calendarEventDetails,
        queryEventId
      },
      actions: {
        goToNextMonth,
        goToPrevMonth,
        goToToday,
        getEventDetails,
        deleteEventDetails,
        appendToCalendarEvents,
        amendToCalendarEvents,
        respondToTheMeeting,
        changeCalendarView,
        setQueryEventId
      }
    }),
    [
      calendarRef,
      currentMonthLabel,
      goToNextMonth,
      goToPrevMonth,
      isTodayDisabled,
      goToToday,
      calendarEvents,
      calendarEventDetails,
      getEventDetails,
      deleteEventDetails,
      appendToCalendarEvents,
      amendToCalendarEvents,
      changeCalendarView,
      queryEventId,
      setQueryEventId
    ]
  )
  return (
    <CalendarEventsContext.Provider value={context}>
      {children}
    </CalendarEventsContext.Provider>
  )
}
export const useCalendarEventContext = () => useContext(CalendarEventsContext)

export default CalendarEventsContextProvider
