import Fetcher from 'services/Fetcher'
import { API_DICTIONARY, HTTP_METHODS } from 'constants/api'
import { VendorData } from './types'

const fetcher = new Fetcher({})

export const getVendorDetails = async (uuid: string) =>
  fetcher.request<unknown, VendorData>({
    url: `${API_DICTIONARY.MY_COMMUNITY}${API_DICTIONARY.VENDORS}${uuid}/`,
    method: HTTP_METHODS.GET
  })
