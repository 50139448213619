import { useAbility } from '@casl/react'
import { subject } from '@casl/ability'

import './styles.scss'

import { FieldWithLabel } from 'components/FieldWithLabel/FieldWithLabel'
import AddResponsibleUserAction from './AddUserPopup/Action'
import { useRFPDetailsContext } from 'features/RFP/RFPDetails/Providers/RFPDetailsContextProvider'
import UserList from 'features/RFP/RFPDetails/ResponsibleForRFP/UserList/UserList'
import { Can, ACTIONS, SUBJECTS, AbilityContext } from 'features/Permission'
import RFPResponsibleContextProvider from 'features/RFP/RFPDetails/ResponsibleForRFP/Providers/RFPResponsibleContextProvider'
import {
  AddResponsibleUserPopup,
  DeleteResponsibleUserPopup
} from 'features/RFP/RFPDetails/ResponsibleForRFP/index'
import { RFP_DETAILS_TABS } from 'features/RFP/RFPDetails/constants'

const generalTab = RFP_DETAILS_TABS[0].key
const biddingTab = RFP_DETAILS_TABS[6].key

type TProps = {
  isBidding?: boolean
}

const ResponsibleForRFP = ({ isBidding }: TProps) => {
  const ability = useAbility<any>(AbilityContext)
  const rfpDetailsContext = useRFPDetailsContext()

  const {
    data,
    isVendor,
    activeTab,
    isVendorNotAnswerRfp,
    isCommons,
    isVendorCanSeeAddResponsibleOnBidding
  } = rfpDetailsContext.state
  const rfpSubject = {
    ...data,
    vendor_responsibles: data.vendor_contract_steward
      ? [data.vendor_contract_steward, ...(data.vendor_responsibles || [])]
      : data.vendor_responsibles || []
  }

  const isCanManageResponsible =
    ability.can(
      ACTIONS.CREATE,
      subject(SUBJECTS.RESPONSIBLE_USER, rfpSubject)
    ) &&
    ability.can(
      ACTIONS.DELETE,
      subject(SUBJECTS.RESPONSIBLE_USER, rfpSubject)
    ) &&
    isVendor
      ? activeTab === biddingTab
      : activeTab === generalTab

  const fieldLabel = !!isBidding
    ? 'Responsible for RFP'
    : isCanManageResponsible
    ? 'Team Responsible'
    : `${!isVendor ? 'Vendor' : isCommons ? 'Commons' : 'Community'} contacts`

  const showAddResponsiblesButton = isBidding
    ? isVendorCanSeeAddResponsibleOnBidding
    : !isVendorNotAnswerRfp

  return (
    <Can I={ACTIONS.VIEW} a={subject(SUBJECTS.RESPONSIBLE_USER, rfpSubject)}>
      <RFPResponsibleContextProvider>
        <div className="rfp-details-responsible">
          <FieldWithLabel title={fieldLabel}>
            <UserList />
          </FieldWithLabel>
          {showAddResponsiblesButton && (
            <div className="rfp-details-responsible__actions">
              <AddResponsibleUserAction />
            </div>
          )}
        </div>
        <DeleteResponsibleUserPopup />
        <AddResponsibleUserPopup />
      </RFPResponsibleContextProvider>
    </Can>
  )
}

export default ResponsibleForRFP
