import SignPopup from '../../features/SignPopup/SignPopup'
import { useState } from 'react'
import TimeIcon from 'assets/svg/Time.svg'
import './style.scss'
import Typography from '../../components/Typography/Typography'
import { TYPOGRAPHY_WEIGHT } from '../../components/Typography'

const PendingApproval = () => {
  const [email, _setEmail] = useState('')
  return (
    <SignPopup
      isBack
      email={email}
      newLogo={TimeIcon}
      className="pending-approval-container"
    >
      <Typography.HeadlineD4 className="mt-32" weight={TYPOGRAPHY_WEIGHT.BOLD}>
        Profile Pending Approval
      </Typography.HeadlineD4>
      <Typography.Body1 className="mt-12">
        Your profile is currently pending approval. Please wait a little while
        as our team reviews your information. We’ll notify you once the approval
        process is complete.
      </Typography.Body1>
    </SignPopup>
  )
}

export default PendingApproval
