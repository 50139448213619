import { Collapse, CollapseProps } from 'antd'
import cn from 'classnames'
import { ReactComponent as ArrowDown24 } from 'assets/svg/ArrowDown24.svg'
import { ReactComponent as ArrowUp24 } from 'assets/svg/ArrowUp24.svg'
import './collapse.default.scss'

export const Default = ({ className, onChange, children }: CollapseProps) => (
  <Collapse
    className={cn('collapse-default', className)}
    expandIconPosition="right"
    bordered={false}
    onChange={onChange}
    expandIcon={(panelProps) =>
      panelProps.isActive ? <ArrowUp24 /> : <ArrowDown24 />
    }
    destroyInactivePanel
    accordion
  >
    {children}
  </Collapse>
)
