import { Checkbox } from 'components/Checkbox'
import { Input } from 'components/Input'
import { PLACEHOLDERS } from 'constants/txt'
import { TComponent } from './types'
import { usePRSVotingContext } from '../../../Providers/PRSVotingContext'
import Typography from 'components/Typography/Typography'
import { usePRSDetailsContext } from '../../../Providers/PRSDetailsContextProvider'
import { useMemo } from 'react'

const Multi = ({ options, isClinicalRationale }: TComponent) => {
  const { state } = usePRSVotingContext()
  const { votingResponse } = state
  const prsDetailsContext = usePRSDetailsContext()
  const { clinicalRationaleResponse } = prsDetailsContext.state

  const response = useMemo(() => {
    return isClinicalRationale ? clinicalRationaleResponse : votingResponse
  }, [votingResponse, clinicalRationaleResponse])

  return (
    <>
      {options.map((option) => {
        return (
          <Checkbox
            className="multi-questions-preview-container__checkbox-with-input"
            key={option.uuid}
            propsCheckbox={{
              checked: !!response.get(option.uuid),
              disabled: true
            }}
            propsItem={{
              colon: false
            }}
          >
            {option.is_other ? (
              <Input
                propsInput={{
                  disabled: true,
                  placeholder: PLACEHOLDERS.OTHER,
                  value: response.get(option.uuid)?.text
                }}
              />
            ) : (
              <Typography.Body1>{option.text}</Typography.Body1>
            )}
          </Checkbox>
        )
      })}
    </>
  )
}

export default Multi
