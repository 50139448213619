import cn from 'classnames'
import Typography from 'components/Typography/Typography'
import './style.scss'
import { TYPOGRAPHY_WEIGHT } from 'components/Typography'
import { TCommonResponseProps } from './types'
import { usePRSVotingContext } from '../../Providers/PRSVotingContext'
import { QUESTION_TYPE } from '../Survey/constants'
import { useState } from 'react'

const CommonResponse = ({ question }: TCommonResponseProps) => {
  const { state } = usePRSVotingContext()
  const { votingResponse } = state

  const [isMore, setIsMore] = useState(false)

  const showMore = () => setIsMore(!isMore)

  return (
    <div
      className={cn('common-response-wrapper column mt-32', {
        isRequired: question.is_required
      })}
      key={question.uuid}
    >
      <Typography.Body1 className="common-response-wrapper__question">
        {question.text}
      </Typography.Body1>
      {question.options.map((option) => {
        const vote = votingResponse.get(option.uuid)
        return (
          <>
            {question.type !== QUESTION_TYPE.TEXT && (
              <div
                className="common-response-wrapper__statistics-row column"
                key={option.uuid}
              >
                <div className="common-response-wrapper__statistics-row__header row space-between">
                  <Typography.Caption
                    weight={TYPOGRAPHY_WEIGHT.SEMI_BOLD}
                    className="common-response-wrapper__statistics-row__header__name"
                  >
                    {option.is_other ? 'Other' : option.text}
                  </Typography.Caption>

                  <Typography.Caption
                    weight={TYPOGRAPHY_WEIGHT.SEMI_BOLD}
                    className="common-response-wrapper__statistics-row__header__percent"
                  >
                    {vote.answer_count}&nbsp;
                    <span>
                      (
                      {(vote.percentage * 100)
                        .toFixed(2)
                        .replace(/[.,]00$/, '')}
                      %)
                    </span>
                  </Typography.Caption>
                </div>

                <div className="common-response-wrapper__statistics-row__line">
                  <div
                    className="common-response-wrapper__statistics-row__line__colored"
                    style={{ width: `${752 * vote.percentage}px` }}
                  />
                </div>
              </div>
            )}
            {!!vote.text_answers?.length && (
              <>
                <div className="common-response-wrapper__other-response">
                  <Typography.Caption
                    weight={TYPOGRAPHY_WEIGHT.SEMI_BOLD}
                    className="common-response-wrapper__other-response__count"
                  >
                    {vote.text_answers?.length} responses
                  </Typography.Caption>
                </div>
                {vote.text_answers.map((i, idx) => {
                  return (
                    <div
                      className="common-response-wrapper__answers-wrapper column"
                      key={i?.uuid || idx}
                    >
                      {idx < 3 && (
                        <Typography.Body2 className="common-response-wrapper__answers-wrapper">
                          {i}
                        </Typography.Body2>
                      )}
                      {isMore && idx >= 3 && (
                        <Typography.Body2 className="common-response-wrapper__answers-wrapper">
                          {i}
                        </Typography.Body2>
                      )}
                      {idx >= 3 && (
                        <Typography.Body2
                          onClick={showMore}
                          className="common-response-wrapper__more"
                        >
                          {isMore
                            ? 'Hide other responses'
                            : 'Show more responses'}
                        </Typography.Body2>
                      )}
                    </div>
                  )
                })}
              </>
            )}
          </>
        )
      })}
    </div>
  )
}

export default CommonResponse
