import './styles.scss'

import { useVendorDetailsContext } from 'features/VendorDetails/Providers/ContextProvider'
import DetailsForm from 'features/VendorDetails/General/DetailsForm/DetailsForm'
import ContractAdminPreview from 'features/VendorDetails/General/ContractAdminPreview/ContractAdminPreview'

const General = () => {
  const vendorDetailsContext = useVendorDetailsContext()

  const { isCreation } = vendorDetailsContext.state

  return (
    <div className="vendor-details-general">
      <DetailsForm />
      {isCreation || <ContractAdminPreview />}
    </div>
  )
}

export default General
