import './styles.scss'

import { useContractDetailsContext } from 'features/ContractDetails/Providers/ContractDetailsContextProvider'
import RebateConditionsForm from 'features/ContractDetails/RebateConditions/RebateConditionsForm/RebateConditionsForm'
import RebateConditionsPreview from 'features/ContractDetails/RebateConditions/RebateConditionsPreview/RebateConditionsPreview'

const RebateConditions = () => {
  const contractDetailsContext = useContractDetailsContext()

  const { canEditRebateConditions } = contractDetailsContext.state

  return (
    <div className="contract-rebate-conditions">
      {canEditRebateConditions ? (
        <RebateConditionsForm />
      ) : (
        <RebateConditionsPreview />
      )}
    </div>
  )
}

export default RebateConditions
