import Button from 'components/Button/Button'
import { BUTTON_TYPES } from 'components/Button/constants'
import Popup from 'components/Popup/Popup'
import Select from 'components/Select/Select'
import { OPTIONS_SORT_ORDER, ROLES_NAMES } from 'constants/common'
import { BTN_TXT, PLACEHOLDERS } from 'constants/txt'
import { createRFP } from 'features/RFP/RFPDetails/api'
import useRouter from 'hooks/useRouter'
import { useEffect, useLayoutEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import { getUser } from 'redux/store/user/getters'
import { routes } from 'router/Config/config.routes'
import {
  getContractUsersForReassign,
  getCPRUsersForReassign,
  getPRSUsersForReassign,
  getRFPUsersForMandatoryReassign,
  getRFPUsersForReassign,
  reassignContractOwner,
  reassignCPRResponsible,
  reassignPRSOwner,
  reassignRFPOwner
} from './api'
import { TGroup } from '../../components/Select/types'
import './styles.scss'
import { TReassignPopup as Props } from './types'
import Typography from '../../components/Typography/Typography'
import { usePRSDetailsContext } from '../PRS/PRSDetails/Providers/PRSDetailsContextProvider'
import { ROLES } from 'features/Permission/constants'
import { getRcaList, updatePRS } from '../../pages/ProductRequests/api'
import { notification } from '../../components/Notification'
import { IPartialUserInfo } from '../../redux/store/user/types'

export const Reassign = ({
  isRFP,
  isReassignPopupOpen,
  setIsReassignPopupOpen,
  ownerRole,
  ownerName,
  uuid,
  update,
  isCreatingRFP,
  rfpData,
  isPrs,
  isRca,
  isCommons,
  isCPR,
  params
}: Props) => {
  const [users, setUsers] = useState<IPartialUserInfo[]>([])
  const [filteredOptions, setFilteredOptions] = useState<IPartialUserInfo[]>([])
  const [user, setUser] = useState('')
  const prsData = usePRSDetailsContext()
  const currentUser = useSelector(getUser)
  const { id } = useParams()
  const { push } = useRouter()
  const isVendor = currentUser.role.includes('vendor')
  const isVP = currentUser.role === ROLES.VICE_PRESIDENT

  const returnSelectUsers = (arr: any[]): TGroup[] => {
    if (arr?.length) {
      return Object.values(
        arr.reduce(
          (p, n) => ({
            ...p,
            [n.role]: {
              label: ROLES_NAMES[n.role],
              options: [
                ...(p[n.role]?.options || []),
                {
                  value: n.uuid,
                  key: n.uuid,
                  label: (
                    <>
                      {n?.first_name} {n?.last_name}
                      <span className="assign-text">
                        {n?.uuid === currentUser.uuid && '(assign to me)'}
                      </span>
                    </>
                  )
                }
              ]
            }
          }),
          {}
        )
      )
    }
    return []
  }

  useLayoutEffect(() => {
    if ((uuid || id) && id !== 'new') {
      if (isRFP) {
        getRFPUsersForReassign(uuid || id).then((res) => {
          setUsers(res?.data?.results)
          setFilteredOptions(res?.data?.results)
        })
      } else if (isPrs) {
        if (isRca && id) {
          getRcaList(id).then((res) => {
            setUsers(
              res.data.results.map((i) => ({ ...i, role: ROLES.COMMUNITY_RCA }))
            )
            setFilteredOptions(
              res.data.results.map((i) => ({ ...i, role: ROLES.COMMUNITY_RCA }))
            )
          })
        } else {
          getPRSUsersForReassign(uuid || id).then((res) => {
            setUsers(res?.data?.results)
            setFilteredOptions(res?.data?.results)
          })
        }
      } else if (isCPR) {
        if (params)
          getCPRUsersForReassign({ params: params }).then((res) => {
            setUsers(res?.data?.results)
            setFilteredOptions(res?.data?.results)
          })
      } else {
        getContractUsersForReassign(uuid || id).then((res) => {
          setUsers(res?.data?.results)
          setFilteredOptions(res?.data?.results)
        })
      }
    }
  }, [])

  useEffect(() => {
    if (isCreatingRFP) {
      getRFPUsersForMandatoryReassign(rfpData?.contract_categories).then(
        (res) => {
          setUsers(res?.data?.results)
          setFilteredOptions(res?.data?.results)
        }
      )
    }
  }, [rfpData?.contract_categories])

  const onCancel = () => {
    setIsReassignPopupOpen(false)
    setUser('')
  }

  const onConfirm = () => {
    if (isCreatingRFP) {
      createRFP({ ...rfpData, ...{ creator: user } }).then((resp) => {
        push(`${routes.bidding}${routes.rfp}/${resp?.data?.uuid}/`)
      })
      return
    }
    if (isRFP) {
      reassignRFPOwner(uuid || id || '', user).then(() => {
        if (update) {
          update()
        }
        onCancel()
      })
      return
    }
    if (isCPR) {
      reassignCPRResponsible(uuid || id || '', user).then(() => {
        if (update) {
          update()
        }
        onCancel()
      })
      return
    }
    if (isPrs) {
      if (isRca && id) {
        updatePRS(id, { rca: user }).then(() => {
          notification.success({
            message: 'Revenue Cycle Advisor has been successfully reassigned.'
          })
          if (update) {
            update()
          }
          onCancel()
        })
        return
      } else {
        reassignPRSOwner(uuid || id || '', user).then(() => {
          if (update) {
            update()
          }
          onCancel()
        })
        return
      }
    }

    reassignContractOwner(uuid || id || '', user).then(() => {
      if (update) {
        update()
      }
      onCancel()
    })
  }
  const handleSearch = (searchText) => {
    const filteredUsers: IPartialUserInfo[] = users?.filter((option) => {
      const fullName = `${option.first_name} ${option.last_name}`.toLowerCase()
      return fullName.includes(searchText.toLowerCase())
    })
    setFilteredOptions(filteredUsers || [])
  }
  return (
    <Popup
      visible={isReassignPopupOpen}
      onCancel={onCancel}
      className="reassign-popup__container"
      title={
        isPrs
          ? 'Reassign PRS'
          : isCPR
          ? 'Responsible for Contract pipeline'
          : `Reassign ${ROLES_NAMES[ownerRole]} ${ownerName}`
      }
      footer={
        <>
          <Button type={BUTTON_TYPES.DEFAULT} onClick={onCancel} upperCase>
            {BTN_TXT.CANCEL}
          </Button>
          <Button
            type={BUTTON_TYPES.PRIMARY}
            onClick={onConfirm}
            disabled={!user}
            upperCase
          >
            {isCreatingRFP ? 'assign and create RFP' : BTN_TXT.REASSIGN}
          </Button>
        </>
      }
    >
      <>
        {isPrs && prsData.state?.data?.name && (
          <Typography.Body1 className="mb-10">
            If you transfer PRS to another person, you might no longer be able
            to manage &nbsp;{prsData.state?.data?.name}. Reassign PRS to the
            selected person?
          </Typography.Body1>
        )}
        <Select
          options={returnSelectUsers(filteredOptions)}
          propsItem={{
            label: `${
              isRca
                ? 'Revenue Cycle Advisor'
                : isVP && !isCPR
                ? 'Contract Steward'
                : isVendor
                ? 'Vendor Contract Steward'
                : isCommons
                ? 'Commons Vice President / Commons Contract Steward'
                : 'Vice President / Contract Steward'
            }`,
            name: 'owner'
          }}
          propsSelect={{
            filterSort: (optionA, optionB) =>
              optionA.label && optionB.label
                ? OPTIONS_SORT_ORDER[optionB.label as string] -
                  OPTIONS_SORT_ORDER[optionA.label as string]
                : 0,
            placeholder: PLACEHOLDERS.PLEASE_SELECT,
            onChange: (val) =>
              typeof val === 'string' ? setUser(val) : setUser(val?.value),
            filterOption: false,
            showSearch: true,
            labelInValue: true,
            onSearch: handleSearch
          }}
          dropdownClassName="reassign-popup__select-dropdown"
          className="reassign-popup__select"
          alwaysName
        />
      </>
    </Popup>
  )
}
