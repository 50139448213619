import Fetcher from 'services/Fetcher'
import { API_DICTIONARY, HTTP_METHODS } from 'constants/api'
import {
  TComplianceRequest,
  TLinearChart,
  TNonComplianceProducts,
  TOptionsFacilityResponse,
  TOverallComplianceRequest,
  TOverallComplianceResponse,
  TSpendByVendorResponse,
  TTotalSpendRequest
} from './types'
import { ResponseWithPagination, TOptionsResponse } from '../../constants'

const fetcher = new Fetcher({})

export const fetchOverallComplianceData: TOverallComplianceRequest = async (
  data
) =>
  fetcher.request<unknown, TOverallComplianceResponse>({
    url: `${API_DICTIONARY.FINANCES}${API_DICTIONARY.COMPLIANCE_DASHBOARD}${API_DICTIONARY.TOTAL_SPEND_DATA}`,
    method: HTTP_METHODS.GET,
    ...data
  })

export const fetchMyActivityForVendorData: TComplianceRequest = async (data) =>
  fetcher.request<unknown, TOverallComplianceResponse>({
    url: `${API_DICTIONARY.FINANCES}${API_DICTIONARY.COMPLIANCE_DASHBOARD}${API_DICTIONARY.VENDOR_ACTIVITY}`,
    method: HTTP_METHODS.GET,
    ...data
  })

export const fetchSpendByVendorData: TComplianceRequest = async (data) =>
  fetcher.request<unknown, TSpendByVendorResponse>({
    url: `${API_DICTIONARY.FINANCES}${API_DICTIONARY.COMPLIANCE_DASHBOARD}${API_DICTIONARY.SPEND_BY_VENDOR}`,
    method: HTTP_METHODS.GET,
    ...data
  })

export const fetchNonCompliantProducts: TComplianceRequest = async (data) =>
  fetcher.request<unknown, ResponseWithPagination<TNonComplianceProducts>>({
    url: `${API_DICTIONARY.FINANCES}${API_DICTIONARY.COMPLIANCE_DASHBOARD}${API_DICTIONARY.NON_COMPLIANT_PRODUCTS}`,
    method: HTTP_METHODS.GET,
    ...data
  })

export const fetchSpendByFacility: TComplianceRequest = async (data) =>
  fetcher.request<unknown, ResponseWithPagination<TLinearChart>>({
    url: `${API_DICTIONARY.FINANCES}${API_DICTIONARY.COMPLIANCE_DASHBOARD}${API_DICTIONARY.SPEND_BY_FACILITY}`,
    method: HTTP_METHODS.GET,
    ...data
  })

export const fetchSpendByCategory: TComplianceRequest = async (data) =>
  fetcher.request<unknown, ResponseWithPagination<TLinearChart>>({
    url: `${API_DICTIONARY.FINANCES}${API_DICTIONARY.COMPLIANCE_DASHBOARD}${API_DICTIONARY.SPEND_BY_CATEGORY}`,
    method: HTTP_METHODS.GET,
    ...data
  })

export const fetchSpendByMonth: TComplianceRequest = async (data) =>
  fetcher.request<unknown, TTotalSpendRequest>({
    url: `${API_DICTIONARY.FINANCES}${API_DICTIONARY.COMPLIANCE_DASHBOARD}${API_DICTIONARY.SPEND_BY_MONTH}`,
    method: HTTP_METHODS.GET,
    ...data
  })
export const fetchCCOptions = async () =>
  fetcher.request<unknown, ResponseWithPagination<TOptionsResponse>>({
    url: `${API_DICTIONARY.FINANCES}${API_DICTIONARY.COMPLIANCE_DASHBOARD}${API_DICTIONARY.OPTIONS}${API_DICTIONARY.CC}`,
    method: HTTP_METHODS.GET
  })

export const fetchFacilityOptions = async () =>
  fetcher.request<unknown, ResponseWithPagination<TOptionsFacilityResponse>>({
    url: `${API_DICTIONARY.FINANCES}${API_DICTIONARY.COMPLIANCE_DASHBOARD}${API_DICTIONARY.OPTIONS}${API_DICTIONARY.FACILITY}`,
    method: HTTP_METHODS.GET
  })
