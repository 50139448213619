export const ACTIONS = {
  VIEW: 'VIEW',
  DELETE: 'DELETE',
  CREATE_RFP: 'CREATE_RFP',
  EDIT: 'EDIT',
  REASSIGN: 'REASSIGN'
}

export const SUBJECTS = {
  CONTRACT_PIPELINE: 'CONTRACT_PIPELINE',
  HEALTH_SYSTEM: 'HEALTH_SYSTEM',
  RFP_LINK: 'RFP_LINK'
}
