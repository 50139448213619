import Fetcher from 'services/Fetcher'
import { API_DICTIONARY, HTTP_METHODS } from 'constants/api'
import {
  TRFPVendorsRequest,
  TRFPAssignedVendorsResponse,
  TResendBidForm
} from './types'
import { ResponseWithPagination, TOptionsResponse } from '../../../constants'

const fetcher = new Fetcher({})

export const getAssignedVendors: TRFPVendorsRequest = async (id, data) =>
  fetcher.request<unknown, TRFPAssignedVendorsResponse>({
    url: `${API_DICTIONARY.RFP}${id}/${API_DICTIONARY.RFP_VENDOR_USERS}`,
    method: HTTP_METHODS.GET,
    ...data
  })
export const getResendBidOptions = async () =>
  fetcher.request<unknown, ResponseWithPagination<TOptionsResponse>>({
    url: `${API_DICTIONARY.RFP}${API_DICTIONARY.RESEND}${API_DICTIONARY.OPTIONS}${API_DICTIONARY.REASON}`,
    method: HTTP_METHODS.GET
  })

export const setResendBidReason = async (id: string, data: TResendBidForm) =>
  fetcher.request<unknown, unknown>({
    url: `${API_DICTIONARY.RFP}${id}/${API_DICTIONARY.RESEND}`,
    method: HTTP_METHODS.POST,
    data
  })
