import { FC } from 'react'

import { BTN_TXT } from 'constants/txt'
import { Button, BUTTON_TYPES } from 'components/Button'
import { useRFPCreateSurveyContext } from 'features/RFP/RFPSurvey/CreateSurvey/Providers/RFPCreateSurveyContextProvider'

type Props = {
  onClick: VoidFunction
}

const CreateSurveyButton: FC<Props> = (props) => {
  const { onClick } = props

  const rfpCreateSurveyContext = useRFPCreateSurveyContext()

  const { canCreateOrEditSurvey } = rfpCreateSurveyContext.state

  return canCreateOrEditSurvey ? (
    <Button type={BUTTON_TYPES.PRIMARY} onClick={onClick} upperCase>
      {BTN_TXT.CREATE_SURVEY}
    </Button>
  ) : null
}

export default CreateSurveyButton
