import cn from 'classnames'
import { Button, BUTTON_TYPES } from 'components/Button'
import { TAttachmentProps } from './types'
import './styles.scss'

export const Table = ({ filename, className, link }: TAttachmentProps) => {
  return (
    <div className={cn('attachment', 'attachment-table', className)}>
      <Button type={BUTTON_TYPES.LINK} onClick={() => window.open(link)}>
        <span className="attachment-table__filename">{filename}</span>
      </Button>
    </div>
  )
}
