import { ReactGrid } from '@silevis/reactgrid'
import { customCellTemplates } from '../constants'
import { useContractBulkTableConfigContext } from '../Providers/ContractBulkTableConfigProvider'
import { Button, BUTTON_TYPES } from '../../../components/Button'
import { BTN_TXT } from '../../../constants'
import { Typography, TYPOGRAPHY_WEIGHT } from '../../../components/Typography'
import Popup from '../../../components/Popup/Popup'
import { useShowPopup } from '../../../hooks/useShowPopup'

const Table = () => {
  const { state, actions } = useContractBulkTableConfigContext()
  const [showPopup, confirmNavigation, cancelNavigation] = useShowPopup(
    state.hasPendingChanges
  )

  return (
    <div className="contract-bulk__table">
      <ReactGrid
        rows={state.rows}
        columns={state.columns}
        onCellsChanged={actions.onCellsChanged}
        customCellTemplates={customCellTemplates}
        stickyLeftColumns={2}
        stickyTopRows={1}
        stickyRightColumns={1}
        // disabling automatic focus change
        onFocusLocationChanged={() => undefined}
        focusLocation={state.focusPosition}
      />
      <Popup
        visible={state.rowDeleteConfirmationPopupOpened}
        onCancel={actions.onRowDeleteCancel}
        title="Delete contract?"
        width={442}
        footer={
          <>
            <Button
              onClick={actions.onRowDeleteCancel}
              type={BUTTON_TYPES.DEFAULT}
              upperCase
            >
              {BTN_TXT.CANCEL}
            </Button>
            <Button
              type={BUTTON_TYPES.PRIMARY}
              upperCase
              danger
              onClick={() => actions.onRowDeleteConfirm()}
            >
              {BTN_TXT.DELETE}
            </Button>
          </>
        }
      >
        <Typography.Headline6 weight={TYPOGRAPHY_WEIGHT.NORMAL}>
          All progress associated with this line will be lost. Once deleted, it
          cannot be recovered. Do you want to continue?
        </Typography.Headline6>
      </Popup>
      <Popup.Discard
        visible={showPopup}
        onCancel={cancelNavigation}
        onSubmit={confirmNavigation}
      />
    </div>
  )
}

export default Table
