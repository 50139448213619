import { ColumnsType } from 'antd/es/table'
import { SortOrder } from 'antd/es/table/interface'
import { Dropdown } from 'components/Dropdown'
import { CellWithSubtitle } from 'components/CellWithSubtitle/CellWithSubtitle'
import { Sorter } from 'components/Sorter/Sorter'
import { ReactComponent as Filter16 } from 'assets/svg/Filter16.svg'
import { ReactComponent as FilterApplied16 } from 'assets/svg/FilterApplied16.svg'
import { fetchContractCategories } from 'pages/CRUDUser/api'
import {
  TAvailableForEvaluationList,
  TAvailableForEvaluationListColumnsFunc,
  TAvailableForEvaluationListFilters,
  TAvailableForEvaluationListFiltersFunc
} from './types'
import { REBATE_CONDITIONS_TITLE } from 'features/ContractDetails/RebateConditions/RebateConditionsForm/constants'
import moment from 'moment/moment'
import { timelineDateFormat } from '../../../components/Timelines/constants'
import { LeadByIndicator } from '../../../components/LeadByIndicator'
import { formatOptionPortfolio } from '../../../helper/optionsFormatters'
import { EvaluateContainer } from './EvaluateModal'
import { ROLES } from 'features/Permission'

export const columns = ({
  applyFilters,
  filters,
  user
}: TAvailableForEvaluationListColumnsFunc): ColumnsType<TAvailableForEvaluationList> => {
  const _columns: ColumnsType<TAvailableForEvaluationList> = [
    {
      dataIndex: 'led_by',
      key: 'led_by',
      width: 134,
      title: ({ sortOrder, sortColumn }) => (
        <Sorter<TAvailableForEvaluationList>
          dataIndex="led_by"
          sortColumn={sortColumn}
          sortOrder={sortOrder as SortOrder}
        >
          Led by
        </Sorter>
      ),
      render: (led_by) => <LeadByIndicator value={led_by} />,
      sorter: true,
      showSorterTooltip: false
    },
    {
      dataIndex: 'portfolio',
      key: 'portfolio',
      width: 143,
      title: ({ sortOrder, sortColumn }) => (
        <Sorter<TAvailableForEvaluationList>
          dataIndex={'portfolio'}
          sortColumn={sortColumn}
          sortOrder={sortOrder as SortOrder}
        >
          Portfolio
        </Sorter>
      ),
      filterDropdown: (props) => (
        <Dropdown.Filter<
          TAvailableForEvaluationListFilters,
          TAvailableForEvaluationListFiltersFunc,
          unknown
        >
          applyFilters={applyFilters}
          appliedFilters={filters}
          searchCallback={fetchContractCategories}
          optionFormatter={formatOptionPortfolio}
          field={'portfolio'}
          asyncSearch
          formatCallback={(data: { value: string; label: string }[]) => {
            const newData = Array.from(
              new Set(
                data
                  .filter((i) => i.value && i.label)
                  .map((i) => JSON.stringify(i))
              )
            )
            return newData.map((i) => JSON.parse(i))
          }}
          {...props}
        />
      ),
      filterIcon:
        !!filters?.portfolio?.length || !!filters?.portfolio?.length ? (
          <FilterApplied16 />
        ) : (
          <Filter16 />
        ),
      filterSearch: true,
      sorter: true,
      showSorterTooltip: false
    },
    {
      dataIndex: 'contract_category',
      key: 'contract_category',
      width: 234,
      title: ({ sortOrder, sortColumn }) => (
        <Sorter<TAvailableForEvaluationList>
          dataIndex={'contract_category'}
          sortColumn={sortColumn}
          sortOrder={sortOrder as SortOrder}
        >
          Contract category
        </Sorter>
      ),
      filterDropdown: (props) => (
        <Dropdown.Filter<
          TAvailableForEvaluationListFilters,
          TAvailableForEvaluationListFiltersFunc,
          unknown
        >
          applyFilters={applyFilters}
          appliedFilters={filters}
          searchCallback={fetchContractCategories}
          field="contract_category"
          asyncSearch
          {...props}
        />
      ),
      render: (_, { contract_category_name }) => (
        <CellWithSubtitle title={contract_category_name} />
      ),
      filterIcon: !!filters?.contract_category?.length ? (
        <FilterApplied16 />
      ) : (
        <Filter16 />
      ),
      filterSearch: true,
      sorter: true,
      showSorterTooltip: false
    },
    {
      dataIndex: 'vendor',
      key: 'vendor',
      title: 'Vendor',
      width: 233,
      render: (_, { contracts }) =>
        contracts.map((i) => (
          <span className="separate-item">
            {i.vendor_name}
            <br />
          </span>
        ))
    },
    {
      dataIndex: 'rebate_type',
      key: 'rebate_type',
      width: 172,
      title: 'Rebate type',
      render: (_, { contracts }) =>
        contracts.map((i) => (
          <span className="separate-item">
            <CellWithSubtitle
              title={
                i.rebate_type ? REBATE_CONDITIONS_TITLE[i.rebate_type] : '-'
              }
            />
          </span>
        )),
      showSorterTooltip: false
    },
    {
      dataIndex: 'start_date',
      key: 'start_date',
      title: 'Start date',
      width: 160,
      render: (_, { contracts }) =>
        contracts.map((i) => (
          <span className="separate-item">
            {i.start_date
              ? moment(i.start_date).format(timelineDateFormat)
              : '-'}
            <br />
          </span>
        )),
      showSorterTooltip: false
    },
    {
      dataIndex: 'finish_date',
      key: 'finish_date',
      title: 'Finish date',
      width: 160,
      render: (_, { contracts }) =>
        contracts.map((i) => (
          <span className="separate-item">
            {i.primary_finish_date
              ? moment(i.primary_finish_date).format(timelineDateFormat)
              : '-'}
            <br />
          </span>
        )),
      showSorterTooltip: false
    },
    {
      dataIndex: 'admin_fee',
      key: 'admin_fee',
      title: 'Admin fee',
      width: 84,
      render: (_, { contracts }) =>
        contracts.map((i) => (
          <span className="separate-item">
            {`${i.admin_fee}%`}
            <br />
          </span>
        )),
      showSorterTooltip: false
    }
  ]
  if (
    user.role === ROLES.VICE_PRESIDENT ||
    user.role === ROLES.CONTRACT_STEWARD
  ) {
    _columns.push({
      dataIndex: 'action',
      key: 'action',
      title: 'Action',
      width: 100,
      render: (_, row) => <EvaluateContainer uuid={row.uuid} />,
      showSorterTooltip: false
    })
  }
  return _columns
}
