import { Form } from 'antd'
import { FieldWithLabel } from '../../../../components/FieldWithLabel/FieldWithLabel'
import { Select } from '../../../../components/Select'
import { PLACEHOLDERS } from '../../../../constants'
import { useDispatch, useSelector } from 'react-redux'
import { getIsLoading } from '../../../../redux/store/common/getters'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { ROLES } from '../../../Permission'
import { useContractDetailsContext } from '../../Providers/ContractDetailsContextProvider'
import { TResponsibleUser } from '../../../RFP/RFPDetails/types'
import { setLoading } from '../../../../redux/store/common/slice'
import { getAvailableResponsibleUsersRequestAsync } from 'features/ContractDetails/api'
import { convertAvailableUsersToOptions } from 'features/RFP/RFPDetails/ResponsibleForRFP/AddResponsibleForRFP/AddResponsibleForRFP'
import { useContractResponsibleContext } from '../ResponsibleUsers/Providers/ContractResponsibleContextProvider'
import '../styles.scss'
import { TResponsiblePeople } from '../ResponsibleUsers/types'

const AddResponsibleForm = ({
  selectedAnalystUuid,
  setSelectedAnalyst,
  selectedLegalUuid,
  setSelectedLegal
}: TResponsiblePeople) => {
  const dispatch = useDispatch()
  const isLoading = useSelector(getIsLoading)

  const { state } = useContractDetailsContext()
  const responsibleContext = useContractResponsibleContext()
  const { addResponsibleUserAsync } = responsibleContext.actions
  const { isVendor, details, isCommons } = state

  const [availableUsers, setAvailableUsers] = useState<TResponsibleUser[]>([])
  const handleFinishForm = useCallback(() => {
    const responsibleUsers = [selectedAnalystUuid, selectedLegalUuid].filter(
      (i) => i !== null
    ) as Array<TResponsibleUser['uuid']>

    addResponsibleUserAsync(responsibleUsers)
  }, [addResponsibleUserAsync, selectedAnalystUuid, selectedLegalUuid])
  const updateState = (dispatch) => (value) => dispatch(value)
  const getAvailableResponsibleUsers = useCallback(async () => {
    if (!details.uuid) {
      throw new Error('Contract ID not provided')
    }

    try {
      dispatch(setLoading(true))

      const response = await getAvailableResponsibleUsersRequestAsync(
        details.uuid
      )

      if (response?.data?.length > 0) {
        setAvailableUsers(response?.data)
      }
    } catch (e) {
      console.error(e)
    } finally {
      dispatch(setLoading(false))
    }
  }, [details.uuid, dispatch])

  const getSelectedAvailableUsers = useCallback(() => {
    if (isVendor) {
      setSelectedAnalyst(
        details.vendor_responsibles.find((u) => u.role === ROLES.VENDOR_ANALYST)
          ?.uuid ?? null
      )
      setSelectedLegal(
        details.vendor_responsibles.find((u) => u.role === ROLES.VENDOR_LEGAL)
          ?.uuid ?? null
      )
    } else if (isCommons) {
      setSelectedAnalyst(
        details.commons_responsibles.find(
          (u) => u.role === ROLES.COMMONS_ANALYST
        )?.uuid ?? null
      )
      setSelectedLegal(
        details.commons_responsibles.find((u) => u.role === ROLES.COMMONS_LEGAL)
          ?.uuid ?? null
      )
    } else {
      setSelectedAnalyst(
        details.community_responsibles.find((u) => u.role === ROLES.ANALYST)
          ?.uuid ?? null
      )
      setSelectedLegal(
        details.community_responsibles.find((u) => u.role === ROLES.LEGAL)
          ?.uuid ?? null
      )
    }
  }, [details])

  const availableAnalystUsers = useMemo(() => {
    if (isVendor) {
      return convertAvailableUsersToOptions(
        availableUsers,
        ROLES.VENDOR_ANALYST
      )
    } else if (isCommons) {
      return convertAvailableUsersToOptions(
        availableUsers,
        ROLES.COMMONS_ANALYST
      )
    } else {
      return convertAvailableUsersToOptions(availableUsers, ROLES.ANALYST)
    }
  }, [availableUsers])

  const availableLegalUsers = useMemo(() => {
    if (isVendor) {
      return convertAvailableUsersToOptions(availableUsers, ROLES.VENDOR_LEGAL)
    } else if (isCommons) {
      return convertAvailableUsersToOptions(availableUsers, ROLES.COMMONS_LEGAL)
    } else {
      return convertAvailableUsersToOptions(availableUsers, ROLES.LEGAL)
    }
  }, [availableUsers])

  useEffect(() => {
    getSelectedAvailableUsers()
  }, [getSelectedAvailableUsers])

  useEffect(() => {
    getAvailableResponsibleUsers()
  }, [getAvailableResponsibleUsers])
  return (
    <Form
      id="contract-add-responsible"
      className="rfp-details-add-responsible"
      onFinish={handleFinishForm}
    >
      {!isLoading && (
        <>
          <FieldWithLabel title={`${isVendor ? 'Vendor ' : ''}Analyst`}>
            <Select
              options={availableAnalystUsers}
              propsSelect={{
                value: selectedAnalystUuid,
                placeholder: PLACEHOLDERS.PLEASE_SELECT,
                onChange: updateState(setSelectedAnalyst)
              }}
            />
          </FieldWithLabel>
          <FieldWithLabel title={`${isVendor ? 'Vendor ' : ''}Legal`}>
            <Select
              options={availableLegalUsers}
              propsSelect={{
                value: selectedLegalUuid,
                placeholder: PLACEHOLDERS.PLEASE_SELECT,
                onChange: updateState(setSelectedLegal)
              }}
            />
          </FieldWithLabel>
        </>
      )}
    </Form>
  )
}

export default AddResponsibleForm
