import { BTN_TXT } from '../../../constants'

export const SORT_OPTIONS = [
  {
    text: BTN_TXT.NONE_DEFAULT,
    value: ''
  },
  {
    text: BTN_TXT.DOCUMENT_KEY_ASC,
    value: 'document_key'
  },
  {
    text: BTN_TXT.DOCUMENT_KEY_DESC,
    value: '-document_key'
  },
  {
    text: BTN_TXT.INVOICE_DATE_ASC,
    value: 'invoice_date'
  },
  {
    text: BTN_TXT.INVOICE_DATE_DESC,
    value: '-invoice_date'
  }
]
