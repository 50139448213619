import { routes } from '../../router'

export const REQUEST_FOR_ANALYSIS_BREADCRUMBS = (title: string) => {
  return [
    {
      children: 'Contracts',
      href: '/contracts'
    },
    {
      children: 'Cognus repository',
      href: routes.contracts + routes.cognusRepository
    },
    {
      children: 'Requests for analysis',
      href: routes.contracts + routes.cognusRepository
    },
    {
      children: title
    }
  ]
}
export const REQUEST_FOR_ANALYSIS_BREADCRUMBS_COMMONS = (title: string) => {
  return [
    {
      children: 'Contracts',
      href: '/contracts'
    },
    {
      children: 'Opportunity analysis',
      href: routes.contracts + routes.opportunityAnalysis
    },
    {
      children: title
    }
  ]
}
