import { createContext, FC, PropsWithChildren, useMemo } from 'react'
import { useSelector } from 'react-redux'
import { createContextualCan, useAbility } from '@casl/react'

import { getUser } from 'redux/store/user/getters'
import {
  ability,
  defineAbility,
  ChatItemAbility
} from 'features/Chat/Abilities'

export const ChatAbilityContext = createContext<ChatItemAbility>(ability)

const ChatAbilityProvider: FC<PropsWithChildren> = ({ children }) => {
  const user = useSelector(getUser)

  const ability = useMemo(() => defineAbility(user), [user])

  return (
    <ChatAbilityContext.Provider value={ability}>
      {children}
    </ChatAbilityContext.Provider>
  )
}

export const useChatAbility = () =>
  useAbility<ChatItemAbility>(ChatAbilityContext)

export const Can = createContextualCan<any>(ChatAbilityContext.Consumer)

export default ChatAbilityProvider
