export const ACTIONS = {
  SEND: 'SEND',
  VIEW: 'VIEW',
  CANCEL: 'CANCEL',
  EDIT: 'EDIT',
  DECLINE: 'DECLINE',
  CREATE: 'CRETE',
  OPEN: 'OPEN',
  ASSIGN: 'ASSIGN',
  REASSIGN: 'REASSIGN',
  APPROVE: 'APPROVE',
  SEND_TO_RCA: 'SEND_TO_RCA',
  SEE: 'SEE',
  MANAGE: 'MANAGE',
  RESPOND: 'RESPOND',
  COMPLETE: 'COMPLETE',
  REQUEST: 'REQUEST',
  RCA: 'RCA',
  RUN: 'RUN',
  CONFIRM: 'CONFIRM',
  START: 'START'
}

export const SUBJECTS = {
  PRS: 'PRS',
  HEALTH_SYSTEMS: 'HEALTH_SYSTEMS',
  SURVEY: 'SURVEY',
  CLINICAL_SPONSOR: 'CLINICAL_SPONSOR',
  FACILITY_USERS: 'FACILITY_USERS',
  OWNER: 'OWNER',
  COMMUNITY_OWNER: 'COMMUNITY_OWNER',
  REQUEST: 'REQUEST',
  LABEL: 'LABEL',
  PRS_FROM_CLINICAL_SPONSOR: 'PRS_FROM_CLINICAL_SPONSOR',
  VOTING: 'VOTING',
  VOTING_RESPONSE: 'VOTING_RESPONSE',
  TRIAL: 'TRIAL',
  CLINICAL_RATIONALE: 'CLINICAL_RATIONALE',
  STEP: 'STEP',
  RCA: 'RCA',
  PRE_APPROVAL_LABEL: 'PRE_APPROVAL_LABEL',
  REVIEW_DECISION: 'review_decision',
  APPROVE: 'APPROVE',
  DECLINE: 'DECLINE',
  RCA_APPROVE_LABEL: 'RCA_APPROVE_LABEL',
  PRE_APPROVAL_TAB: 'PRE_APPROVAL_TAB',
  CONTRACT: 'CONTRACT',
  RESULTS_TAB: 'RESULTS_TAB',
  TRIAL_REQUESTED_NOTE: 'TRIAL_REQUESTED_NOTE',
  HISTORY: 'HISTORY',
  TO_VENDOR: 'TO_VENDOR',
  TIMER: 'TIMER',
  REVENUE_ASSESSMENT: 'REVENUE_ASSESSMENT',
  CS_APPROVAL_LABEL: 'CS_APPROVAL_LABEL'
}
