import Fetcher from '../../../../services/Fetcher'
import { API_DICTIONARY, HTTP_METHODS } from '../../../../constants'
import { HealthSystemDetailsData } from './types'

const fetcher = new Fetcher({})

export const getHealthSystemDetails = async (uuid: string) =>
  fetcher.request<unknown, HealthSystemDetailsData>({
    url: `${API_DICTIONARY.MY_COMMUNITY}${API_DICTIONARY.HEALTH_SYSTEMS}${uuid}/`,
    method: HTTP_METHODS.GET
  })
