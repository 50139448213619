import { Can } from '../Providers/PrsDetailsAbilityProvider'
import { ACTIONS, SUBJECTS } from '../../Abilities'
import { subject } from '@casl/ability'
import { Button, BUTTON_TYPES } from 'components/Button'
import { usePRSDetailsContext } from '../Providers/PRSDetailsContextProvider'
import { BTN_TXT, TOOLTIP } from 'constants/txt'
import { PRS_STATUSES } from '../../../../pages/ProductRequests/constants'
import { Tooltip } from 'components/Tooltip/Tooltip'
import { useMemo } from 'react'
import './style.scss'
import cn from 'classnames'
import { usePRSVotingContext } from '../Providers/PRSVotingContext'
const CompleteVoting = () => {
  const { state, actions } = usePRSDetailsContext()
  const { data } = state
  const { handleChangePrsStatus } = actions

  const votingContext = usePRSVotingContext()
  const { votingResponse } = votingContext.state

  const isDisabled = useMemo(() => {
    return !votingResponse?.size
  }, [votingResponse])

  const onSend = () => {
    handleChangePrsStatus(PRS_STATUSES.DECISION_MAKING)
  }
  return (
    <>
      <Can I={ACTIONS.COMPLETE} a={subject(SUBJECTS.VOTING, { ...data })}>
        <Tooltip
          id="complete-voting-btn"
          title={TOOLTIP.ONE_STAKEHOLDER}
          overlayInnerStyle={{
            display: isDisabled ? 'block' : 'none',
            width: 400
          }}
          placement="bottomRight"
          className="complete-voting-disabled"
        >
          <span
            className={cn({
              'complete-voting-btn-disabled': isDisabled
            })}
          >
            <Button
              type={BUTTON_TYPES.PRIMARY}
              onClick={onSend}
              disabled={isDisabled}
              className="send-prs-to-clinical-sponsor-btn"
              upperCase
            >
              {BTN_TXT.COMPLETE_VOTING}
            </Button>
          </span>
        </Tooltip>
      </Can>
    </>
  )
}

export default CompleteVoting
