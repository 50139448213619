import { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'

import 'features/ChatList/Sidebar/styles.scss'

import { add } from 'redux/store/chats/slice'
import { IChat } from 'redux/store/chats/types'
import { ReactComponent as Search16 } from 'assets/svg/Search16.svg'
import { Input } from 'components/Input'
import { useDebounce } from 'hooks/hooks'
import { setLoading } from 'redux/store/common/slice'
import { ROLES_NAMES } from 'constants/common'
import { ChatIconName } from 'features/ChatList/Sidebar/ChatIconName'
import { createChatRequest, getPvpAvailableUsers } from 'features/ChatList/api'
import { ReactComponent as Close16 } from '../../../assets/svg/Clos16.svg'
import { TParams, TStartPVPChat } from './types'
import { NOTIFICATIONS } from '../../../constants'
import { notification } from '../../../components/Notification'

export const StartPVPChat = ({ onClose }: TStartPVPChat) => {
  const [searchValue, setSearchValue] = useState('')
  const [availableUsers, setAvailableUsers] = useState([] as any)
  const debouncedSearch = useDebounce(searchValue, 500)
  const dispatch = useDispatch()

  const getAvailableUsers = () => {
    dispatch(setLoading(true))
    const params: TParams = { search: searchValue }
    if (searchValue) {
      params.search = searchValue
    }
    getPvpAvailableUsers(params)
      .then((resp) => setAvailableUsers(resp?.data?.results))
      .finally(() => dispatch(setLoading(false)))
  }

  const onClick = (data: IChat) => {
    const dataForPrivateChat = {
      members_count: data.members_count,
      type: data.type,
      uuid: data.uuid
    }
    createChatRequest({ ...dataForPrivateChat, members: [data.uuid] })
      .then((res) => {
        dispatch(
          add({
            ...res.data,
            name: data.name,
            pvp_user_role: data.pvp_user_role
          })
        )
        notification.success({
          message:
            res.data.type === 'private'
              ? NOTIFICATIONS.PRIVATE_CHAT_CREATED
              : NOTIFICATIONS.GROUP_CHAT_CREATED
        })
        onClose(res?.data?.uuid)
      })
      .finally()
  }

  useEffect(() => {
    getAvailableUsers()
  }, [debouncedSearch])

  return (
    <div className="start-pvp-chat">
      <Input
        className="allow-clear"
        propsInput={{
          allowClear: { clearIcon: <Close16 /> },
          placeholder: 'Search users',
          prefix: <Search16 />,
          value: searchValue,
          onChange: (e) => setSearchValue(e.target.value)
        }}
      />
      <div className="start-pvp-chat_users-list">
        {availableUsers.map((user) => (
          <ChatIconName
            key={user.uuid}
            organization={user.organization_name}
            src={user.avatar?.file_preview || ''}
            className="user-form_community"
            firstName={user.first_name}
            lastName={user.last_name}
            roleName={ROLES_NAMES[user.role]}
            role={user.role}
            uuid={user.uuid}
            onClick={onClick}
          />
        ))}
      </div>
    </div>
  )
}
