import { AppThunk } from '../../type'
import {
  error,
  finish,
  loading,
  set,
  bulk,
  setSearchHistory
  // resetSearchHistory
} from './slice'
import {
  getChatHistoryRequest,
  getMessagesArguments
} from '../../../features/Chat/api'
import { GetChatListRequestArgs } from '../../../features/ChatList/api'

export const setChatHistoryAsync =
  (id: string, args: getMessagesArguments): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(loading())
      const { data } = await getChatHistoryRequest(id, args)
      if (data) {
        if (args?.offset && Number(args.offset) !== 0) {
          dispatch(bulk({ ...data }))
        } else {
          dispatch(set({ ...data }))
        }
      }
    } catch (err) {
      dispatch(error({ error: err }))
    } finally {
      dispatch(finish())
    }
  }

export const getChatHistorySearchAsync =
  (id: string, args: GetChatListRequestArgs): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(loading())
      // dispatch(resetSearchHistory())
      const { data } = await getChatHistoryRequest(id, args)

      if (data) {
        dispatch(setSearchHistory({ ...data }))
      }
      await Promise.resolve()
    } catch (err) {
      dispatch(error({ error: err }))
      await Promise.reject(err)
    } finally {
      dispatch(finish())
    }
  }
