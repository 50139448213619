import { useMemo } from 'react'
import './styles.scss'
import { BTN_TXT } from 'constants/txt'
import { Popup } from 'components/Popup'
import { Button, BUTTON_TYPES } from 'components/Button'
import { usePRSDetailsContext } from '../PRSDetails/Providers/PRSDetailsContextProvider'
import PrsDetailsTimelines from './PrsDetailsTimelines'
import { timelinesResponseDataConverter } from './utils'
import { usePRSDetailsPopupContext } from '../PRSDetails/Providers/PRSDetailsPopupProvider'

const PrsDetailsTimelinesPopup = () => {
  const prsDetailsContext = usePRSDetailsContext()
  const { prsStepsTimePopup } = usePRSDetailsPopupContext()

  const { data } = prsDetailsContext.state
  const { setupPrsDetailsTimeAsync } = prsDetailsContext.actions

  const timelines = useMemo(
    () => timelinesResponseDataConverter(data.prs_timers),
    [data.prs_timers]
  )

  return (
    <Popup
      {...prsStepsTimePopup.state}
      className="prs-details-timeline-popup"
      width={632}
      footer={
        <>
          <Button
            type={BUTTON_TYPES.DEFAULT}
            onClick={prsStepsTimePopup.actions.close}
            upperCase
          >
            {BTN_TXT.CANCEL}
          </Button>
          <Button
            type={BUTTON_TYPES.PRIMARY}
            htmlType="submit"
            form="prs-timeline-form-id"
            upperCase
          >
            {BTN_TXT.SAVE}
          </Button>
        </>
      }
    >
      <PrsDetailsTimelines
        onFinish={setupPrsDetailsTimeAsync}
        disabledBeforeKey={data.status}
        timelines={timelines}
      />
    </Popup>
  )
}

export default PrsDetailsTimelinesPopup
