import FullCalendar from '@fullcalendar/react'
import { EventContentArg } from 'fullcalendar'
import { ReactElement } from 'react'

export interface Props {
  calendarRef?: React.RefObject<FullCalendar> | null
  onEventClick?: (eventId: string | null, popupUniqueId: string) => void
  events: TCalendarEventViewType[]
  renderEventContent: (eventInfo: EventContentArg) => ReactElement
}

export interface TCalendarEventViewType {
  id: string
  start: string
  end: string
  title: string
  location?: string
  day: string
  className: EventTypes
}

export enum EventTypes {
  ACTIVE = 'calendar-event__status-active',
  PENDING = 'calendar-event__status-pending',
  MAYBE = 'calendar-event__status-maybe'
}

export type EventOpenedId = string | null
