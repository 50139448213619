export const prsContractInitialState = {
  contract_category_name: '',
  name: '',
  number: '',
  uuid: '',
  vendor_name: ''
}
import { PRS_STATUSES } from 'pages/ProductRequests/constants'

export const STATUSES_BAN_TIMER = [
  PRS_STATUSES.CREATED,
  PRS_STATUSES.WAITING_FOR_REVIEW,
  PRS_STATUSES.ARCHIVED,
  PRS_STATUSES.CANCELED,
  PRS_STATUSES.DECLINED
] as const
