import ShowMoreText from 'react-show-more-text'
import { TPropsShowMore } from './types'
import './style.scss'
const ShowMore = ({
  children,
  className = ''
}: TPropsShowMore): JSX.Element => {
  return (
    <ShowMoreText
      lines={3}
      more="Show more"
      less="Show less"
      className={className}
      anchorClass="show-more-less-clickable"
      expanded={false}
      truncatedEndingComponent={
        <span>
          ...
          <br />
        </span>
      }
    >
      {children}
    </ShowMoreText>
  )
}

export default ShowMore
