import { useEffect } from 'react'
import { useParams } from 'react-router-dom'

import './styles.scss'

import ChatList from 'features/ChatList/ChatList'
import Chat from 'features/Chat/Chat'
import useChats from './useChats'
import cn from 'classnames'

const Chats = () => {
  const { id } = useParams()
  const socket = useChats()

  useEffect(() => {
    return socket._tryOpenOrCloseChatSocket()
  }, [socket._tryOpenOrCloseChatSocket])

  return (
    <div className={cn('chats-grid', { 'open-chat': !!id })}>
      <div className="chats-grid__list column">
        <ChatList />
      </div>
      <div className="chats-grid__chat">
        {id ? <Chat id={id} /> : <Chat.Empty />}
      </div>
    </div>
  )
}

export default Chats
