import { TGroup } from '../../../../../components/Select/types'
import { ROLES_NAMES } from '../../../../../constants'

export const returnSelectUsers = (
  arr: any[],
  currentUuid: string
): TGroup[] => {
  if (arr?.length) {
    return Object.values(
      arr.reduce(
        (p, n) => ({
          ...p,
          [n.role]: {
            label: ROLES_NAMES[n.role],
            options: [
              ...(p[n.role]?.options || []),
              {
                value: n.uuid,
                key: n.uuid,
                label: (
                  <>
                    {n?.first_name} {n?.last_name}
                    <span className="assign-text">
                      {n?.uuid === currentUuid && ' (assign to me)'}
                    </span>
                  </>
                )
              }
            ]
          }
        }),
        {}
      )
    )
  }
  return []
}
