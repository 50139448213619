import {
  ALL_ITEMS_PARAMS,
  API_DICTIONARY,
  HTTP_METHODS,
  ResponseWithPagination
} from '../../constants'
import Fetcher from '../../services/Fetcher'
import {
  SetupPrsDetailsTimeRequestData,
  TPRSDetails,
  TPRSDocument,
  TPRSRespond,
  TPRSResponse,
  TPRSUpdateRequest,
  TPRSUpdateTrial,
  TPRSUploadFilesRequest,
  TResponsibleUser
} from '../../features/PRS/PRSDetails/types'

import {
  TListRequest,
  TPRSCommunitiesHssRequest,
  TPRSListResponse,
  TSetSurvey
} from './types'
import { TPRSFacilityUsersRequest } from '../../features/PRS/FacilityUsers/types'
import { TTrialApprovalUsersRequest } from '../../features/PRS/PRSDetails/TrialPreApproval/types'
import { TQueryParams } from '../../components/Table/types'
import { TContractValues } from '../../features/PRS/PRSDetails/ContractCreation/GuestContract'

const fetcher = new Fetcher({})

export const fetchCommunityList: any = async () =>
  fetcher.request<unknown, any>({
    url: `${API_DICTIONARY.COMMUNITIES}`,
    method: HTTP_METHODS.GET
  })
export const fetchResponsibleCommunityUsersList: TPRSCommunitiesHssRequest =
  async (data) => {
    return fetcher.request<unknown, TPRSListResponse>({
      url: `${API_DICTIONARY.PRS}${API_DICTIONARY.AVAILABLE_COMMUNITY_RESPONSIBLES}`,
      method: HTTP_METHODS.GET,
      ...data
    })
  }
export const uploadDocumentsFiles: TPRSUploadFilesRequest = async (data) =>
  fetcher.request<unknown, TPRSDocument>({
    url: `${API_DICTIONARY.FILES}${API_DICTIONARY.PRS_REQUEST_ATTACHMENTS}`,
    method: HTTP_METHODS.POST,
    headers: {
      'Content-Type': 'multipart/form-data'
    },
    data
  })
export const uploadImagesFiles: TPRSUploadFilesRequest = async (data) =>
  fetcher.request<unknown, TPRSDocument>({
    url: `${API_DICTIONARY.FILES}${API_DICTIONARY.PRS_REQUEST_IMAGES}`,
    method: HTTP_METHODS.POST,
    headers: {
      'Content-Type': 'multipart/form-data'
    },
    data
  })

export const updatePRS: TPRSUpdateRequest = async (id, data, successMessage) =>
  fetcher.request<unknown, TPRSResponse>({
    url: `${API_DICTIONARY.PRS}${id}/`,
    method: HTTP_METHODS.PATCH,
    successMessage,
    data
  })

export const createPRS = async (data: any) =>
  fetcher.request<unknown, TPRSResponse>({
    url: API_DICTIONARY.PRS,
    method: HTTP_METHODS.POST,
    successMessage: 'PRS has been successfully created.',
    data
  })

export const createSurvey = async (uuid: string, data: any) =>
  fetcher.request<unknown, any>({
    url: `${API_DICTIONARY.PRS}${uuid}/${API_DICTIONARY.SURVEY}`,
    method: HTTP_METHODS.POST,
    successMessage: 'Survey has been successfully created.',
    data
  })
export const updateSurvey = async (uuid: string, data: any) =>
  fetcher.request<unknown, any>({
    url: `${API_DICTIONARY.PRS}${uuid}/${API_DICTIONARY.SURVEY}${API_DICTIONARY.UPDATE}`,
    method: HTTP_METHODS.POST,
    successMessage: 'Survey has been successfully updated.',
    data
  })
export const getSurvey = async (uuid: string) =>
  fetcher.request<unknown, any>({
    url: `${API_DICTIONARY.PRS}${uuid}/${API_DICTIONARY.SURVEY}`,
    method: HTTP_METHODS.GET
  })

export const getPrsList: TListRequest = async (data) =>
  fetcher.request<unknown, TPRSListResponse>({
    url: API_DICTIONARY.PRS,
    method: HTTP_METHODS.GET,
    ...data
  })
export const getPrsData = async (id) =>
  fetcher.request<unknown, TPRSDetails>({
    url: `${API_DICTIONARY.PRS}${id}/`,
    method: HTTP_METHODS.GET
  })
export const getPrsClinicalSponsors = async (id) =>
  fetcher.request<unknown, ResponseWithPagination<TResponsibleUser>>({
    url: `${API_DICTIONARY.PRS}${id}/${API_DICTIONARY.CLINICAL_SPONSOR_USERS}`,
    method: HTTP_METHODS.GET
  })

export const getFacilityUsers: TPRSFacilityUsersRequest = async (id, data) =>
  fetcher.request({
    url: `${API_DICTIONARY.PRS}${id}/${API_DICTIONARY.FACILITY_USERS}`,
    method: HTTP_METHODS.GET,
    ...data
  })
export const getAvailableFacilityUsers: TPRSFacilityUsersRequest = async (
  id,
  data
) =>
  fetcher.request({
    url: `${API_DICTIONARY.PRS}${id}/${API_DICTIONARY.FACILITY_USERS}${API_DICTIONARY.AVAILABLE}`,
    method: HTTP_METHODS.GET,
    ...data
  })

export const updateFacilityUsers: any = async (id, data, successMessage) =>
  fetcher.request<unknown, TPRSResponse>({
    url: `${API_DICTIONARY.PRS}${id}/${API_DICTIONARY.FACILITY_USERS}`,
    method: HTTP_METHODS.POST,
    successMessage,
    data
  })
export const deleteFacilityUsers: any = async (id, data, successMessage) =>
  fetcher.request<unknown, TPRSResponse>({
    url: `${API_DICTIONARY.PRS}${id}/${API_DICTIONARY.FACILITY_USERS}`,
    method: HTTP_METHODS.DELETE,
    successMessage,
    data
  })

export const voteSurvey = async (uuid: string, data: any) =>
  fetcher.request<unknown, any>({
    url: `${API_DICTIONARY.PRS}${uuid}/${API_DICTIONARY.SURVEY}${API_DICTIONARY.VOTE}`,
    method: HTTP_METHODS.POST,
    data
  })

export const getSurveyResponseAll = async (uuid: string) =>
  fetcher.request<unknown, any>({
    url: `${API_DICTIONARY.PRS}${uuid}/${API_DICTIONARY.SURVEY}${API_DICTIONARY.AGGREGATED_RESULTS}`,
    method: HTTP_METHODS.GET
  })
export const getSurveyResponseIndividual = async (
  uuid: string,
  user_uuid: string
) =>
  fetcher.request<unknown, any>({
    url: `${API_DICTIONARY.PRS}${uuid}/${API_DICTIONARY.SURVEY}${API_DICTIONARY.USER_RESULTS}${user_uuid}/`,
    method: HTTP_METHODS.GET
  })
export const getVoteUsers = async (uuid: string) =>
  fetcher.request<unknown, any>({
    url: `${API_DICTIONARY.PRS}${uuid}/${API_DICTIONARY.SURVEY}${API_DICTIONARY.VOTED_USERS}`,
    method: HTTP_METHODS.GET
  })

export const getSurveyForCs = async (uuid: string) =>
  fetcher.request<unknown, any>({
    url: `${API_DICTIONARY.PRS}${uuid}/${API_DICTIONARY.CLINICAL_SPONSOR_SURVEY}`,
    method: HTTP_METHODS.GET
  })
export const getSurveyResultsForCs = async (uuid: string) =>
  fetcher.request<unknown, any>({
    url: `${API_DICTIONARY.PRS}${uuid}/${API_DICTIONARY.CLINICAL_SPONSOR_SURVEY}${API_DICTIONARY.RESULTS}`,
    method: HTTP_METHODS.GET
  })
export const setSurveyResultsForCs = async ({ uuid, data }: TSetSurvey) =>
  fetcher.request<unknown, any>({
    url: `${API_DICTIONARY.PRS}${uuid}/${API_DICTIONARY.CLINICAL_SPONSOR_SURVEY}${API_DICTIONARY.RESULTS}`,
    method: HTTP_METHODS.POST,
    data
  })

export const getRcaList = async (id: string) =>
  fetcher.request<any, any>({
    url: `${API_DICTIONARY.PRS}${id}/${API_DICTIONARY.RCA}`,
    method: HTTP_METHODS.GET
  })
export const getAvailableStepsOptions = async (id: string) =>
  fetcher.request<any, any>({
    url: `${API_DICTIONARY.PRS}${id}/${API_DICTIONARY.NEXT_STEPS}`,
    method: HTTP_METHODS.GET
  })

export const updateTrial: TPRSUpdateTrial = async (id, data) =>
  fetcher.request<unknown, TPRSResponse>({
    url: `${API_DICTIONARY.PRS}${id}/${API_DICTIONARY.TRIAL}`,
    method: HTTP_METHODS.POST,
    data
  })
export const patchTrial: TPRSUpdateTrial = async (id, data) =>
  fetcher.request<unknown, TPRSResponse>({
    url: `${API_DICTIONARY.PRS}${id}/${API_DICTIONARY.TRIAL}`,
    method: HTTP_METHODS.PATCH,
    data
  })
export const respondPrs: TPRSRespond = async (id, data) =>
  fetcher.request<unknown, TPRSResponse>({
    url: `${API_DICTIONARY.PRS}${id}/${API_DICTIONARY.TRIAL}${API_DICTIONARY.STAKEHOLDER_REVIEWS}`,
    method: HTTP_METHODS.POST,
    data
  })

export const getTrialStatisticsUsers: TTrialApprovalUsersRequest = async (
  id,
  data
) =>
  fetcher.request({
    url: `${API_DICTIONARY.PRS}${id}/${API_DICTIONARY.TRIAL}${API_DICTIONARY.STAKEHOLDER_REVIEWS}`,
    method: HTTP_METHODS.GET,
    ...data
  })
export const getTrialStatistics: any = async (id) =>
  fetcher.request({
    url: `${API_DICTIONARY.PRS}${id}/${API_DICTIONARY.TRIAL}${API_DICTIONARY.STAKEHOLDER_REVIEWS}${API_DICTIONARY.STATISTICS}`,
    method: HTTP_METHODS.GET
  })
export const getPrsHistory = async (
  id,
  params: TQueryParams = ALL_ITEMS_PARAMS
) =>
  fetcher.request<unknown, ResponseWithPagination<any>>({
    url: `${API_DICTIONARY.PRS}${id}/${API_DICTIONARY.CHANGE_LOGS}`,
    method: HTTP_METHODS.GET,
    params
  })
export const getContractDetails = async (id) =>
  fetcher.request<unknown, any>({
    url: `${API_DICTIONARY.PRS}${id}/${API_DICTIONARY.CONTRACT}`,
    method: HTTP_METHODS.GET
  })
export const createContractGuest = async (id, data) =>
  fetcher.request<unknown, TContractValues>({
    url: `${API_DICTIONARY.PRS}${id}/${API_DICTIONARY.ARCHIVE}`,
    method: HTTP_METHODS.POST,
    data
  })

export const setupPrsDetailsTimeRequestAsync = async (
  id: string,
  timelines: SetupPrsDetailsTimeRequestData
) =>
  fetcher.request<{ timelines: SetupPrsDetailsTimeRequestData }, undefined>({
    url: `${API_DICTIONARY.PRS}${id}/${API_DICTIONARY.TIMERS}`,
    method: HTTP_METHODS.POST,
    data: { timelines },
    successMessage: 'The timeframes for PRS steps have been updated.'
  })
