import ClinicalReviewForm from './Form/ClinicalReviewForm'
import { useCallback, useEffect, useState } from 'react'
import { setLoading } from '../../redux/store/common/slice'
import { useDispatch } from 'react-redux'
import {
  getClinicalReview,
  setClinicalReview
} from '../../pages/ClinicalReview/api'
import {
  TClinicalReviewDataList,
  TClinicalReviewSetDataList
} from '../../pages/ClinicalReview/types'
import EmptyState from './EmptyState'
import { notification } from '../../components/Notification'
import { VALIDATION_MESSAGES } from '../../constants'
import { useClinicalReviewPopup } from '../../pages/ClinicalReview/Providers/useClinicalReviewPopup'
import { TClinicalReviewContainerProps } from './types'

const ClinicalReview = ({
  setIsReviewEmpty
}: TClinicalReviewContainerProps) => {
  const dispatch = useDispatch()
  const [review, setReview] = useState<TClinicalReviewDataList[]>([])
  const { submitClinicalReviewPopup } = useClinicalReviewPopup()
  const getClinicalReviewForStakeholder = useCallback(async () => {
    try {
      dispatch(setLoading(true))

      const response = await getClinicalReview()

      if (response?.data) {
        setReview(response?.data?.results)
      }
    } catch (e) {
      console.error(e)
    } finally {
      dispatch(setLoading(false))
    }
  }, [])

  useEffect(() => {
    setIsReviewEmpty(!review.length)
  }, [review])

  const setClinicalReviewForStakeholder = useCallback(
    async (values: TClinicalReviewSetDataList) => {
      submitClinicalReviewPopup.actions.close()
      try {
        dispatch(setLoading(true))
        await setClinicalReview({ decisions: values })
        notification.success({
          message: VALIDATION_MESSAGES.SM00128
        })
        submitClinicalReviewPopup.actions.close()
        getClinicalReviewForStakeholder()
      } catch (e) {
        console.error(e)
      } finally {
        dispatch(setLoading(false))
      }
    },
    []
  )

  useEffect(() => {
    getClinicalReviewForStakeholder()
  }, [])
  return (
    <div>
      {!!review?.length ? (
        <ClinicalReviewForm
          review={review}
          setClinicalReviewForStakeholder={setClinicalReviewForStakeholder}
        />
      ) : (
        <EmptyState />
      )}
    </div>
  )
}

export default ClinicalReview
