import ContractDetailsGeneral from 'features/ContractDetails/ContractDetailsGeneral/ContractDetailsGeneral'
import ContractDetailsHSList from 'features/ContractDetails/HSList/HSList'
import { ContractItemsTable } from 'features/ContractItems/ContractItemsTable'
import RebateConditions from 'features/ContractDetails/RebateConditions/RebateConditions'
import FacilityUsers from '../FacilityUsers/FacilityUsers'

export const contractDetailsRoute = {
  general: 'general',
  hsList: 'hs_list',
  itemList: 'item_list',
  rebateConditions: 'rebate_conditions',
  facilityUsers: 'facility_users'
}

export const contractDetailsRoutes = [
  {
    path: contractDetailsRoute.general,
    component: ContractDetailsGeneral
  },
  {
    path: contractDetailsRoute.facilityUsers,
    component: FacilityUsers
  },
  {
    path: contractDetailsRoute.hsList,
    component: ContractDetailsHSList
  },
  {
    path: contractDetailsRoute.itemList,
    component: ContractItemsTable
  },
  {
    path: contractDetailsRoute.rebateConditions,
    component: RebateConditions
  }
]
