import {
  API_DICTIONARY,
  HTTP_METHODS,
  ResponseWithPagination,
  TOptionsResponse
} from '../../constants'
import Fetcher from '../../services/Fetcher'
import { TCompleteProfileWithToken } from './types'

const fetcher = new Fetcher({})

export const getContractCategories = async (token: string) =>
  fetcher.request<unknown, ResponseWithPagination<TOptionsResponse>>({
    url: `${API_DICTIONARY.AUTH}${API_DICTIONARY.SELF_SIGN_UP}${API_DICTIONARY.COMPLETE}${API_DICTIONARY.OPTIONS}${API_DICTIONARY.CC}`,
    method: HTTP_METHODS.GET,
    params: {
      token
    }
  })

export const getDepartments = async (token: string) =>
  fetcher.request<unknown, ResponseWithPagination<TOptionsResponse>>({
    url: `${API_DICTIONARY.AUTH}${API_DICTIONARY.SELF_SIGN_UP}${API_DICTIONARY.COMPLETE}${API_DICTIONARY.OPTIONS}${API_DICTIONARY.DEPARTMENT}`,
    method: HTTP_METHODS.GET,
    params: {
      token
    }
  })

export const completeProfile = async (data: TCompleteProfileWithToken) =>
  fetcher.request<unknown, TCompleteProfileWithToken>({
    url: `${API_DICTIONARY.AUTH}${API_DICTIONARY.SELF_SIGN_UP}${API_DICTIONARY.COMPLETE}`,
    method: HTTP_METHODS.POST,
    data
  })
