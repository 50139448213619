import { DatePicker, Space } from 'antd'
import cn from 'classnames'
import { Button, BUTTON_TYPES } from 'components/Button'
import { TDropdownProps } from 'components/Dropdown/types'
import moment from 'moment'
import { Moment } from 'moment'
import { RangeValue } from 'rc-picker/lib/interface'
import { useState } from 'react'

import './datepicker.default.scss'

const { RangePicker } = DatePicker

export const Filter = <FiltersType, ApplyFiltersFunc, SearchFuncType>({
  className,
  applyFilters,
  appliedFilters,
  confirm,
  field,
  rangeClassName,
  ...props
}: TDropdownProps<FiltersType, ApplyFiltersFunc, SearchFuncType> | any) => {
  const [selectedOptions, setSelectedOptions] = useState<RangeValue<Moment>>([
    moment(appliedFilters ? appliedFilters[0] : null),
    moment(appliedFilters ? appliedFilters[1] : null)
  ])

  const handleApplyFilters = () => {
    if (typeof applyFilters === 'function') {
      applyFilters(
        field,
        selectedOptions?.map((date) => date?.format('YYYY-MM-DD')).join(',')
      )
      confirm({ closeDropdown: true })
    }
  }
  return (
    <div className={cn('rangepicker-filter', className)}>
      <Space>
        <RangePicker
          format="DD/MM/YY"
          value={selectedOptions as any}
          getPopupContainer={(trigger) => trigger.parentElement}
          onChange={(dateStrings) => {
            setSelectedOptions(dateStrings)
          }}
          allowClear={true}
          {...props}
          className={rangeClassName}
        />
      </Space>
      <Space>
        <Button
          type={BUTTON_TYPES.DEFAULT}
          upperCase
          disabled={!selectedOptions?.length}
          onClick={() => setSelectedOptions(null)}
        >
          reset
        </Button>
        <Button
          type={BUTTON_TYPES.PRIMARY}
          upperCase
          onClick={handleApplyFilters}
        >
          save
        </Button>
      </Space>
    </div>
  )
}
