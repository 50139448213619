import { Avatar as AntdAvatar } from 'antd'
import { LED_BY_LABEL, LED_BY_OPTIONS } from './constants'
import cn from 'classnames'
import './style.scss'
import { Tooltip } from 'components/Tooltip/Tooltip'
type TLeadByIndicator = {
  value: ValueOfObject<typeof LED_BY_OPTIONS>
  onClick?: () => void
}
export const LeadByIndicator = ({ value, onClick }: TLeadByIndicator) => {
  const handleClick = () => {
    if (onClick) onClick()
  }
  return (
    <Tooltip
      title={LED_BY_LABEL[value]}
      overlayInnerStyle={{
        display: 'block'
      }}
      placement="topLeft"
      id="led-by-indicator-tooltip"
    >
      <div className={cn('led-by-indicator', value)} onClick={handleClick}>
        <AntdAvatar>{LED_BY_OPTIONS[value]}</AntdAvatar>
      </div>
    </Tooltip>
  )
}
