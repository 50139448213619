import Popup from '../../../components/Popup/Popup'
import Button from '../../../components/Button/Button'
import { BUTTON_TYPES } from '../../../components/Button'
import { BTN_TXT } from '../../../constants'
import Typography from '../../../components/Typography/Typography'
import { useCognusRepositoryPopup } from '../Providers/CognusRepositoryPopupProvider'
import './style.scss'
import { Tooltip } from '../../../components/Tooltip/Tooltip'
import { ReactComponent as RenewIcon } from 'assets/svg/Renew16.svg'
import { ReactComponent as Plus16 } from 'assets/svg/Plus16.svg'
import { routes } from '../../../router'
import { useDispatch, useSelector } from 'react-redux'
import { getUser } from '../../../redux/store/user/getters'
import { TYPOGRAPHY_WEIGHT } from '../../../components/Typography'
import { Checkbox } from '../../../components/Checkbox'
import { StakeholderWithCategories } from './StakeholderWithCategories'
import { useEffect, useMemo, useState } from 'react'
import { getStakeholdersList, setStakeholdersList } from '../api'
import { CheckboxValueType } from 'antd/es/checkbox/Group'
import { TStakeholdersForm, TStakeholdersFormList } from '../types'
import { setLoading } from '../../../redux/store/common/slice'
type TProps = {
  callbackAfterAccept: VoidFunction
}

export const InitiateClinicalReview = ({ callbackAfterAccept }: TProps) => {
  const { initiateClinicalReviewPopup } = useCognusRepositoryPopup()
  const { state, actions } = initiateClinicalReviewPopup
  const dispatch = useDispatch()

  const [stakeholders, setStakeholders] = useState<TStakeholdersFormList[]>([])
  const [selectedCategories, setSelectedCategories] =
    useState<TStakeholdersForm>([])
  const [selectedStakeHolders, setSelectedStakeHolders] = useState<
    CheckboxValueType[]
  >([])

  const user = useSelector(getUser)

  const onClose = () => {
    actions.close()
    setSelectedStakeHolders([])
    setSelectedCategories([])
  }

  const handleCategories = (data, uuid) => {
    const newStData = selectedCategories.find((i) => i.stakeholder === uuid)
      ? selectedCategories.map((i) =>
          i.stakeholder === uuid ? { ...i, contract_categories: data } : i
        )
      : [
          ...selectedCategories,
          { stakeholder: uuid, contract_categories: data }
        ]
    setSelectedCategories(newStData)
  }

  const handleInitiate = () => {
    dispatch(setLoading(true))
    setStakeholdersList({
      stakeholder_with_categories: selectedCategories
    })
      .then(() => {
        onClose()
        callbackAfterAccept()
      })
      .finally(() => dispatch(setLoading(false)))
  }
  const getStakeHoldersOptions = () => {
    dispatch(setLoading(true))
    getStakeholdersList()
      .then((res) => {
        setStakeholders(
          res.data.results.map((stakeholder) => ({
            ...stakeholder,
            label: `${stakeholder.first_name} ${stakeholder.last_name}`,
            value: stakeholder.uuid,
            contract_categories: stakeholder.contract_categories.map(
              (category) => ({
                ...category,
                label: category.name,
                value: category.uuid
              })
            )
          }))
        )
        setSelectedStakeHolders([])
        setSelectedCategories([])
      })
      .finally(() => dispatch(setLoading(false)))
  }

  const isDisabled = useMemo(() => {
    return (
      !!selectedCategories?.find((i) => !i.contract_categories.length) ||
      selectedCategories.length !== selectedStakeHolders.length ||
      !selectedCategories.length ||
      !selectedStakeHolders.length
    )
  }, [selectedCategories, selectedStakeHolders])

  useEffect(() => {
    getStakeHoldersOptions()
  }, [])

  const handleNewUser = () => {
    window
      .open(
        window?.API_HOST +
          `${routes.createUserProfile}/new?hsUser=${user.health_system}&role=stakeholder`,
        '_blank'
      )
      ?.focus()
  }

  const handleSelectedSt = (data) => {
    if (selectedStakeHolders.length > data.length) {
      const newData = selectedCategories.filter((i) =>
        data.includes(i.stakeholder)
      )
      setSelectedCategories(newData)
    }
    setSelectedStakeHolders(data)
  }

  return (
    <Popup
      visible={state.visible}
      onCancel={onClose}
      title={state.title}
      width={548}
      footer={
        <>
          <Button type={BUTTON_TYPES.DEFAULT} onClick={onClose} upperCase>
            {BTN_TXT.CANCEL}
          </Button>
          <Button
            type={BUTTON_TYPES.PRIMARY}
            onClick={handleInitiate}
            upperCase
            disabled={isDisabled}
          >
            {BTN_TXT.SEND_TO_REVIEW}
          </Button>
        </>
      }
    >
      <div className="initiate-clinical-review-wrapper">
        <Typography.Body1>
          Please select the stakeholders and assign them a category to obtain
          their feedback.
        </Typography.Body1>
        <div className="initiate-clinical-review-wrapper__label row space-between align-center mt-20">
          <Typography.Body1 weight={TYPOGRAPHY_WEIGHT.BOLD}>
            Stakeholders
          </Typography.Body1>
          <Tooltip
            title="Refresh the Stakeholders list"
            placement="topRight"
            id="refresh-stakeholders"
          >
            <div
              className="renew-icon-wrapper"
              onClick={getStakeHoldersOptions}
            >
              <RenewIcon />
            </div>
          </Tooltip>
        </div>
        <div className="initiate-clinical-review-wrapper__form mt-12">
          <Checkbox.Group
            className="initiate-clinical-review-wrapper__form__group"
            propsGroupCheckbox={{
              value: selectedStakeHolders,
              onChange: handleSelectedSt,
              options: stakeholders.map((st) => ({
                value: st.uuid,
                label: (
                  <StakeholderWithCategories
                    selected={
                      selectedCategories?.find((i) => i.stakeholder === st.uuid)
                        ?.contract_categories || []
                    }
                    handleCategories={handleCategories}
                    isAvailable={selectedStakeHolders.includes(st.uuid)}
                    st={st}
                  />
                )
              }))
            }}
          />
        </div>
        <div className="initiate-clinical-review-wrapper__add mt-8">
          <Button
            type={BUTTON_TYPES.GHOST}
            onClick={handleNewUser}
            icon={<Plus16 />}
            upperCase
          >
            {BTN_TXT.CREATE_STAKEHOLDER}
          </Button>
        </div>
      </div>
    </Popup>
  )
}
