import { Select } from '../../components/Select'
import { LABELS, PLACEHOLDERS, TOptionsResponse } from '../../constants'
import { useEffect, useState } from 'react'
import {
  fetchCCOptions,
  fetchFacilityOptions
} from '../../pages/Compliance/api'
import { TGroup } from '../../components/Select/types'
import { TOptionsFacilityResponse } from '../../pages/Compliance/types'
import { useComplianceContext } from '../../pages/Compliance/Providers/ComplianceContextProvider'

const HeaderFilters = () => {
  const { setSelectedCategories, setSelectedFacilities } =
    useComplianceContext().actions
  const [categoryOptions, setCategoryOptions] = useState<TOptionsResponse[]>([])
  const [facilitiesOptions, setFacilitiesOptions] = useState<
    TOptionsFacilityResponse[]
  >([])

  useEffect(() => {
    fetchCCOptions().then((res) => {
      setCategoryOptions(res?.data?.results)
    })
    fetchFacilityOptions().then((res) => {
      setFacilitiesOptions(res?.data?.results)
    })
  }, [])

  const returnGroupedOptions = (arr: TOptionsFacilityResponse[]): TGroup[] => {
    if (arr?.length) {
      return Object.values(
        arr.reduce(
          (p, n) => ({
            ...p,
            [n.health_system]: {
              label: n.health_system,
              options: [
                ...(p[n.health_system]?.options || []),
                {
                  value: n.id,
                  key: n.id,
                  label: n.value
                }
              ]
            }
          }),
          {}
        )
      )
    }
    return []
  }

  return (
    <div className="compliance-container__header-filters row gap-8">
      <Select.Multi
        options={categoryOptions.map((option) => ({
          value: option.id,
          label: option.value
        }))}
        propsItem={{
          name: 'categories',
          label: LABELS.CATEGORIES
        }}
        propsSelect={{
          placeholder: PLACEHOLDERS.ALL,
          onChange: setSelectedCategories
        }}
      />
      <Select.Multi
        options={returnGroupedOptions(facilitiesOptions)}
        propsItem={{
          name: 'facilities',
          label: LABELS.FACILITIES
        }}
        propsSelect={{
          placeholder: PLACEHOLDERS.ALL,
          onChange: setSelectedFacilities
        }}
      />
    </div>
  )
}
export default HeaderFilters
