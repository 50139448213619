import Fetcher from 'services/Fetcher'
import { API_DICTIONARY, HTTP_METHODS } from 'constants/api'
import { TRFPVendorUsersRequest } from '../types'

const fetcher = new Fetcher({})

export const getAvailableVendorUsers: TRFPVendorUsersRequest = async (
  id,
  data
) =>
  fetcher.request({
    url: `${API_DICTIONARY.RFP}${id}/${API_DICTIONARY.AVAILABLE_VENDOR_USERS}`,
    method: HTTP_METHODS.GET,
    ...data
  })
