import Popup from '../../components/Popup/Popup'
import { Button, BUTTON_TYPES } from '../../components/Button'
import { BTN_TXT } from '../../constants'
import { Typography, TYPOGRAPHY_WEIGHT } from '../../components/Typography'
import './style.scss'
import { usePRSPopupContext } from './Providers/PRSPopupProvider'
import { useCallback } from 'react'
import { setLoading } from '../../redux/store/common/slice'
import { updatePRS } from '../../pages/ProductRequests/api'
import { notification } from '../../components/Notification'
import {
  PRS_STATUSES,
  PRS_STATUSES_MESSAGES
} from '../../pages/ProductRequests/constants'
import { useDispatch } from 'react-redux'
import { useProductRequestsContext } from '../../pages/ProductRequests/ProductRequestsProvider'
import { routes } from '../../router'
import useRouter from '../../hooks/useRouter'
const StartPrs = () => {
  const { startPrsPopup } = usePRSPopupContext()
  const { state, actions } = useProductRequestsContext()
  const { push } = useRouter()
  const dispatch = useDispatch()

  const onSend = useCallback(() => {
    dispatch(setLoading(true))
    updatePRS(state.selectedId, { status: PRS_STATUSES.IN_REVIEW })
      .then(() => {
        notification.success({
          message: `${PRS_STATUSES_MESSAGES[PRS_STATUSES.IN_REVIEW]}`
        })
        push(`${routes.productRequests}/${state.selectedId}`)
        actions.setSelectedId('')
      })
      .catch((err) => {
        notification.error({
          message: err.data?.details[0]
        })
        dispatch(setLoading(false))
      })
      .finally(() => dispatch(setLoading(false)))
  }, [state.selectedId, dispatch])
  return (
    <Popup
      visible={startPrsPopup.state.visible}
      onCancel={startPrsPopup.actions.close}
      title={`Start PRS review`}
      width={442}
      footer={
        <>
          <Button
            onClick={startPrsPopup.actions.close}
            type={BUTTON_TYPES.DEFAULT}
            upperCase
          >
            {BTN_TXT.CANCEL}
          </Button>
          <Button type={BUTTON_TYPES.PRIMARY} upperCase onClick={onSend}>
            {BTN_TXT.OPEN_PRS_AND_START_REVIEW}
          </Button>
        </>
      }
    >
      <Typography.Headline6 weight={TYPOGRAPHY_WEIGHT.NORMAL}>
        PRS will be automatically moved to “in review” status and the overall
        time 30d will be started.
        <Typography.Caption className="automatic-timer-sub-title mt-16">
          Timer can be adjust in the details.
        </Typography.Caption>
      </Typography.Headline6>
    </Popup>
  )
}

export default StartPrs
