import cn from 'classnames'
import { TYPOGRAPHY_WEIGHT } from './constants'
import { Props } from './types'
import './typograpy.scss'

export const Headline1 = ({
  children,
  className,
  weight,
  italic,
  uppercase,
  lowercase,
  align
}: Props): JSX.Element => (
  <div
    className={cn(
      'typography typography-headline-1',
      className,
      weight,
      align,
      { italic },
      { uppercase },
      { lowercase }
    )}
  >
    {children}
  </div>
)

export const Headline2 = ({
  children,
  className,
  weight,
  italic,
  uppercase,
  lowercase,
  align
}: Props): JSX.Element => (
  <div
    className={cn(
      'typography typography-headline-2',
      className,
      weight,
      align,
      { italic },
      { uppercase },
      { lowercase }
    )}
  >
    {children}
  </div>
)

export const Headline3 = ({
  children,
  className,
  weight,
  italic,
  uppercase,
  lowercase,
  align
}: Props): JSX.Element => (
  <div
    className={cn(
      'typography typography-headline-3',
      className,
      weight || TYPOGRAPHY_WEIGHT.SEMI_BOLD,
      align,
      { italic },
      { uppercase },
      { lowercase }
    )}
  >
    {children}
  </div>
)

export const Headline4 = ({
  children,
  className,
  weight,
  italic,
  uppercase,
  lowercase,
  align
}: Props): JSX.Element => (
  <div
    className={cn(
      'typography typography-headline-4',
      className,
      weight || TYPOGRAPHY_WEIGHT.BOLD,
      align,
      { italic },
      { uppercase },
      { lowercase }
    )}
  >
    {children}
  </div>
)

export const Headline5 = ({
  children,
  className,
  weight,
  italic,
  uppercase,
  lowercase,
  align,
  onClick,
  id
}: Props): JSX.Element => (
  <div
    className={cn(
      'typography typography-headline-5',
      className,
      weight || TYPOGRAPHY_WEIGHT.BOLD,
      align,
      { italic },
      { uppercase },
      { lowercase }
    )}
    role="presentation"
    onClick={onClick}
    id={id}
  >
    {children}
  </div>
)

export const Headline6 = ({
  children,
  className,
  weight,
  italic,
  uppercase,
  lowercase,
  align,
  onClick
}: Props) => (
  <div
    className={cn(
      'typography typography-headline-6',
      className,
      weight || TYPOGRAPHY_WEIGHT.BOLD,
      align,
      { italic },
      { uppercase },
      { lowercase }
    )}
    role="presentation"
    onClick={onClick}
  >
    {children}
  </div>
)

export const Body1 = ({
  children,
  className,
  weight,
  italic,
  uppercase,
  lowercase,
  align,
  onClick,
  dangerouslySetInnerHTML
}: Props) => (
  <div
    className={cn(
      'typography typography-body-1',
      className,
      weight,
      align,
      { italic },
      { uppercase },
      { lowercase }
    )}
    onClick={onClick}
    dangerouslySetInnerHTML={dangerouslySetInnerHTML}
  >
    {children}
  </div>
)

export const Body2 = ({
  children,
  className,
  weight,
  italic,
  uppercase,
  lowercase,
  align,
  onClick
}: Props): JSX.Element => (
  <div
    className={cn(
      'typography typography-body-2',
      className,
      weight,
      align,
      { italic },
      { uppercase },
      { lowercase }
    )}
    onClick={onClick}
  >
    {children}
  </div>
)

export const Button = ({
  children,
  className,
  weight,
  italic,
  uppercase,
  lowercase,
  align,
  onClick
}: Props): JSX.Element => (
  <div
    className={cn(
      'typography typography-button',
      className,
      weight,
      align,
      { italic },
      { uppercase } || 'uppercase',
      { lowercase }
    )}
    role="presentation"
    onClick={onClick}
  >
    {children}
  </div>
)

export const Label = ({
  children,
  className,
  weight,
  italic,
  uppercase,
  lowercase,
  align,
  onClick
}: Props) => (
  <div
    className={cn(
      'typography typography-label',
      className,
      weight,
      align,
      { italic },
      { uppercase },
      { lowercase }
    )}
    onClick={onClick}
  >
    {children}
  </div>
)

export const Caption = ({
  children,
  className,
  weight,
  italic,
  uppercase,
  lowercase,
  align,
  dangerouslySetInnerHTML
}: Props) => (
  <div
    dangerouslySetInnerHTML={dangerouslySetInnerHTML}
    className={cn(
      'typography typography-caption',
      className,
      weight || TYPOGRAPHY_WEIGHT.NORMAL,
      align,
      { italic },
      { uppercase },
      { lowercase }
    )}
  >
    {children}
  </div>
)

export const HeadlineD4 = ({
  children,
  className,
  weight,
  italic,
  uppercase,
  lowercase,
  align
}: Props) => (
  <div
    className={cn(
      'typography typography-headline-d4',
      className,
      weight || TYPOGRAPHY_WEIGHT.NORMAL,
      align,
      { italic },
      { uppercase },
      { lowercase }
    )}
  >
    {children}
  </div>
)
