import { BTN_TXT } from 'constants/txt'
import { Button, BUTTON_TYPES } from 'components/Button'
import { ReactComponent as Plus16 } from 'assets/svg/Plus16.svg'
import { useRFPDetailsContext } from 'features/RFP/RFPDetails/Providers/RFPDetailsContextProvider'
import { useRFPDetailsPopupContext } from 'features/RFP/RFPDetails/Providers/RFPDetailsPopupProvider'

export default () => {
  const rfpDetailsContext = useRFPDetailsContext()
  const { addResponsibleCommonsUserPopup } = useRFPDetailsPopupContext()

  const { canSeeAssignBanner } = rfpDetailsContext.state
  const { open } = addResponsibleCommonsUserPopup.actions

  return canSeeAssignBanner ? (
    <Button
      icon={<Plus16 />}
      type={BUTTON_TYPES.DEFAULT}
      onClick={open}
      upperCase
    >
      {BTN_TXT.ADD_COMMONS_RESPONSIBLE}
    </Button>
  ) : null
}
