import Fetcher from 'services/Fetcher'
import { HTTP_METHODS } from 'constants/api'
import { TEmailConfirmation, TResetPasswordConfirmation } from './types'
import { ISetPasswordData } from 'redux/store/setPassword/types'

const fetcher = new Fetcher({})

export const confirmEmail = async (data: TEmailConfirmation) =>
  fetcher.request<unknown, ISetPasswordData>({
    url: '/auth/sign_up/confirm_email/',
    method: HTTP_METHODS.POST,
    data
  })

export const confirmResetPassword = async (data: TResetPasswordConfirmation) =>
  fetcher.request<unknown, ISetPasswordData>({
    url: '/auth/reset_password/confirm_token/',
    method: HTTP_METHODS.POST,
    data
  })
