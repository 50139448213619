import VotingHeader from './VotingHeader'
import VotingContent from './VotingContent'

const Voting = () => {
  return (
    <div>
      <VotingHeader />
      <VotingContent />
    </div>
  )
}

export default Voting
