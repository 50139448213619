import { TPRSDetailsResponse } from '../types'
import { PRS_STATUSES } from '../../../../pages/ProductRequests/constants'

export const initialState: TPRSDetailsResponse = {
  name: '',
  status: PRS_STATUSES.CREATED,
  number: '',
  prs_request_image_ids: [],
  prs_request_attachment_ids: [],
  prs_request_attachments: [],
  prs_request_images: [],
  contract_category: '',
  health_systems: [],
  community: null,
  description: '',
  justification: '',
  community_responsible: '',
  anticipated_monthly_usage: '',
  price: '',
  clinical_sponsor: null,
  uuid: '',
  expire_at: '',
  rca: '',
  rca_decision: '',
  rca_decline_reason: '',
  prs_timers: []
}

export const generalInitialState = {
  name: '',
  prs_request_image_ids: [],
  prs_request_attachment_ids: [],
  contract_category: null,
  description: '',
  justification: '',
  community_responsible: '',
  anticipated_monthly_usage: '',
  price: ''
}
export const participantsInitialState = {
  community: '',
  health_systems: [],
  clinical_sponsor: null,
  community_responsible: ''
}

export const pageSize = {
  0: { start: 0, end: 5 },
  1: { start: 5, end: 12 },
  2: { start: 12, end: 15 }
}

export const initialHistoryState = [
  {
    uuid: '',
    changed_by: null,
    action_type: '',
    comment: '',
    changes: {
      from: {
        status: null,
        trial_status: ''
      },
      to: {
        status: null,
        trial_status: ''
      }
    },
    created_at: ''
  }
]
