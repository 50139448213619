export const PAGE_SIZES = [
  {
    label: '50 on page',
    value: 50
  },
  {
    label: '70 on page',
    value: 70
  },
  {
    label: '100 on page',
    value: 100
  }
]

export const DEFAULT_PAGE = {
  pageSize: PAGE_SIZES[0].value,
  pageNumber: 1
}
