import ContractBulkContainer from '../ContractBulkContainer/ContractBulkContainer'
import '../styles.scss'
import { ReactComponent as Plus16 } from 'assets/svg/Plus16.svg'
import Table from '../Table/Table'
import { Button, BUTTON_TYPES } from '../../../components/Button'
import { BTN_TXT } from '../../../constants'
import { useContractBulkTableConfigContext } from '../Providers/ContractBulkTableConfigProvider'

const ContractBulkScreen = () => {
  const { actions } = useContractBulkTableConfigContext()
  return (
    <ContractBulkContainer>
      <div className="contract-bulk column">
        <div className="row justify-end">
          <Button
            type={BUTTON_TYPES.PRIMARY}
            icon={<Plus16 />}
            onClick={actions.onAddNewRow}
            upperCase
          >
            {BTN_TXT.ADD_CONTRACT}
          </Button>
        </div>
        <Table />
      </div>
    </ContractBulkContainer>
  )
}

export default ContractBulkScreen
