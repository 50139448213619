import './styles.scss'

import { Popup } from 'components/Popup'
import { useContractDetailsPopup } from 'features/ContractDetails/Providers/ContractDetailsPopupProvider'
import ExtendContract from 'features/ContractDetails/ExtendContract/ExtendContract'

export default () => {
  const { extendContractPopup } = useContractDetailsPopup()

  return (
    <Popup
      className="extend-contract-popup"
      {...extendContractPopup.state}
      width={442}
      footer={null}
    >
      <ExtendContract />
    </Popup>
  )
}
