import { Can } from './Providers/ContractPipelineDetailsAbilityProvider'
import { ACTIONS, SUBJECTS } from './Abilities'
import { subject } from '@casl/ability'
import Button from 'components/Button/Button'
import { BUTTON_TYPES } from 'components/Button'
import { BTN_TXT } from 'constants/index'
import { useCPRDetailsContext } from './Providers/CPRDetailsContextProvider'
import { routes } from 'router'
import useRouter from 'hooks/useRouter'
import { useMemo } from 'react'
import { createSearchParams } from 'react-router-dom'
import { TQueryKeysForRFP } from '../RFP/RFPDetails/types'

export const CreateRFPContainer = () => {
  const { push } = useRouter()
  const { state: dataState } = useCPRDetailsContext()

  const search = useMemo(
    () =>
      createSearchParams({
        cpr_uuid: dataState.details.uuid,
        contract_category_id: dataState.details.contract_category_id,
        contract_category_name: dataState.details.contract_category_name,
        led_by: dataState.details.led_by
      } as TQueryKeysForRFP).toString(),
    [dataState.details]
  )

  return (
    <Can
      I={ACTIONS.CREATE_RFP}
      a={subject(SUBJECTS.CONTRACT_PIPELINE, dataState.details)}
    >
      <Button
        type={BUTTON_TYPES.PRIMARY}
        onClick={() =>
          push({ pathname: `${routes.bidding}${routes.rfp}/new`, search })
        }
        upperCase
      >
        {BTN_TXT.CREATE_RFP}
      </Button>
    </Can>
  )
}
