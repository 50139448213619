import { THealthSystemDetails } from './types'
import { TAddEditHospitalResponse } from 'components/Forms/forms.addEditHospital.d'

export const HEALTH_SYSTEM_TABLES_TABS = [
  { label: 'Hospitals', key: 'Hospitals' },
  { label: 'Users', key: 'Users' }
]

export const initialHealthSystemDetails: THealthSystemDetails = {
  uuid: '',
  community: {
    uuid: '',
    name: ''
  },
  name: '',
  email: '',
  status: '',
  contact_person: {
    uuid: '',
    first_name: '',
    last_name: '',
    email: ''
  },
  address: {
    state: '',
    street: '',
    zip_code: '',
    city: ''
  },
  users: []
}

export const initialHospitalData: TAddEditHospitalResponse = {
  uuid: '',
  name: '',
  tax_id: '',
  email: '',
  phone_number: '',
  status: '',
  address: {
    state: '',
    street: '',
    zip_code: '',
    city: ''
  },
  departments: [],
  is_connected_to_payment_system: false
}

export const downloadQRCodeOptions = [
  {
    key: 'image',
    label: 'Export as an image'
  },
  {
    key: 'pdf',
    label: 'Export to PDF'
  }
]
