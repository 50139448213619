import { ROLES } from '../../../Permission'
import { USER_STATUSES_TABS } from './constants'
import { ColumnsType } from 'antd/es/table'
import { HealthSystemUser } from './types'
import { ColumnType } from 'antd/es/table/interface'

export const filterColumns = (
  selectedRole: string,
  usersStatus: string,
  columns: ColumnsType<HealthSystemUser>
) =>
  columns
    .filter((i: ColumnType<HealthSystemUser>) => {
      switch (i.dataIndex) {
        case 'departments':
          return (
            selectedRole === ROLES.STAKEHOLDER ||
            selectedRole === ROLES.INFLUENCER
          )
        case 'hospitals':
          return (
            selectedRole === ROLES.STAKEHOLDER ||
            selectedRole === ROLES.INFLUENCER ||
            selectedRole === ROLES.HOSPITAL_ADMIN
          )
        case 'contract_categories':
          return (
            selectedRole === ROLES.STAKEHOLDER ||
            selectedRole === ROLES.INFLUENCER ||
            selectedRole === ROLES.CONTRACT_STEWARD ||
            selectedRole === ROLES.LEGAL ||
            selectedRole === ROLES.ANALYST
          )
        default:
          return true
      }
    })
    .map((i) => ({
      ...i,
      filterDropdown:
        usersStatus === USER_STATUSES_TABS[1].value && i.key === 'status'
          ? null
          : i.filterDropdown
    }))
