import { useLayoutEffect, useMemo } from 'react'
import { subject } from '@casl/ability'

import { BTN_TXT } from 'constants/txt'
import { Popup } from 'components/Popup'
import { Button, BUTTON_TYPES } from 'components/Button'
import SurveyPreview from 'features/RFP/RFPSurvey/CreateSurvey/SurveyPreview/SurveyPreview'
import { useRFPCreateSurveyContext } from 'features/RFP/RFPSurvey/CreateSurvey/Providers/RFPCreateSurveyContextProvider'
import { useRFPDetailsPopupContext } from 'features/RFP/RFPDetails/Providers/RFPDetailsPopupProvider'
import { Can, ACTIONS, SUBJECTS } from 'features/Permission'
import { useRFPDetailsContext } from 'features/RFP/RFPDetails/Providers/RFPDetailsContextProvider'
import { useRFPSurveyContext } from 'features/RFP/RFPDetails/Providers/RFPSurveyContextProvider'
import { safeString } from 'helper/common'

const SubmitSurveyPopup = () => {
  const rfpDetailsContext = useRFPDetailsContext()
  const rfpSurveyContext = useRFPSurveyContext()
  const rfpCreateSurveyContext = useRFPCreateSurveyContext()
  const { submitSurveyPopup } = useRFPDetailsPopupContext()

  const { data } = rfpDetailsContext.state
  const { isSurveySubmitted } = rfpSurveyContext.state
  const { visible, title } = submitSurveyPopup.state

  const { handleSubmitSurvey, handelCancelSurveyVoting } =
    rfpCreateSurveyContext.actions

  useLayoutEffect(() => {
    submitSurveyPopup.actions.setState((prevState) => ({
      ...prevState,
      title: `${safeString(data.name)} survey`
    }))
  }, [data.name, submitSurveyPopup.actions])

  const Footer = useMemo(
    () =>
      isSurveySubmitted || (
        <>
          <Button
            type={BUTTON_TYPES.DEFAULT}
            onClick={handelCancelSurveyVoting}
            upperCase
          >
            {BTN_TXT.CANCEL}
          </Button>
          <Can I={ACTIONS.SUBMIT} a={subject(SUBJECTS.SURVEY, { ...data })}>
            <Button
              onClick={handleSubmitSurvey}
              type={BUTTON_TYPES.PRIMARY}
              htmlType="submit"
              form="rfp-timeline-form-id"
              upperCase
            >
              {BTN_TXT.SUBMIT_SURVEY}
            </Button>
          </Can>
        </>
      ),
    [data, handelCancelSurveyVoting, handleSubmitSurvey, isSurveySubmitted]
  )

  return (
    <Popup
      className="rfp-create-survey-popup"
      visible={visible}
      onCancel={handelCancelSurveyVoting}
      title={title}
      width={910}
      footer={Footer}
    >
      <SurveyPreview />
    </Popup>
  )
}

export default SubmitSurveyPopup
