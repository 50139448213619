import { useDispatch } from 'react-redux'
import { configureStore } from '@reduxjs/toolkit'

import createRootReducer from './store/createRootReducer'

const createStore = () => {
  const rootReducer = createRootReducer()

  return configureStore({
    reducer: rootReducer,
    middleware: (getDefaultMiddleware) => getDefaultMiddleware()
  })
}

export default createStore

export type AppDispatch = ReturnType<typeof createStore>['dispatch']

export const useAppDispatch: () => AppDispatch = useDispatch
