import 'features/ContractDetails/ContractDetailsGeneral/ResponsibleUsers/styles.scss'
import { FieldWithLabel } from 'components/FieldWithLabel/FieldWithLabel'
import UserList from './UserList'

const ResponsibleUsers = () => {
  return (
    <div className="contract-details-responsible">
      <FieldWithLabel title="Responsible for Contract pipelinee">
        <UserList />
      </FieldWithLabel>
    </div>
  )
}

export default ResponsibleUsers
