import { useEffect, useMemo, useState } from 'react'

import { SegmentOption } from 'constants/types'

type Props = {
  options: SegmentOption[]
}

const useSegments = (props: Props) => {
  const { options: initialOptions = [] } = props

  const [options, setOptions] = useState(initialOptions)
  const [activeOptionValue, setActiveOptionValue] = useState(options[0].value)

  useEffect(() => {
    setActiveOptionValue(options[0].value)
  }, [options])

  const state = useMemo(
    () => ({
      options,
      activeOptionValue
    }),
    [activeOptionValue, options]
  )
  const actions = useMemo(() => ({ setOptions, setActiveOptionValue }), [])

  return { state, actions }
}

export default useSegments
