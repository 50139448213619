import { TCheckInsVendorList } from '../../Providers/types'
import Typography from '../../../../components/Typography/Typography'
import { EmptyState } from '../../CheckInsList/EmptyState'

import { useCheckInsListContext } from '../../Providers/CheckInsContextProvider'

import ListPevCheckIns from './ListPevCheckIns'

export const PrevCheckIns = ({ list }: { list: TCheckInsVendorList[] }) => {
  const { state } = useCheckInsListContext()

  return (
    <div className="check-ins-details-container__prev-check-ins column mt-24">
      <Typography.Headline6> Previous check-ins</Typography.Headline6>
      <div className="mt-24" />

      <ListPevCheckIns
        items={list}
        loading={state.isLoadingPrevCheckIns}
        hasNextPage={state.prevCheckIns.hasNextPage}
        listEmptyComponent={<EmptyState showIcon text={`No check-ins yet`} />}
        onLoadMore={state.prevCheckIns.handleLoadMore}
        className="check-ins-container__list__item__list"
      />
    </div>
  )
}
