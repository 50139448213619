import { ReactComponent as ArrowIcon } from 'assets/svg/ArrowDown16.svg'
import { Typography, TYPOGRAPHY_WEIGHT } from '../../../components/Typography'
import Button from '../../../components/Button/Button'
import { BUTTON_TYPES } from '../../../components/Button'
import { BTN_TXT, PLACEHOLDERS } from '../../../constants'
import { Input } from '../../../components/Input'
import { ReactComponent as Search16 } from '../../../assets/svg/Search16.svg'
import './style.scss'
import { useChatContext } from '../Providers/ChatContextProvider'
import { useDispatch, useSelector } from 'react-redux'
import { getChatHistoryFromSearch } from '../../../redux/store/chatHistory/getters'
import { useEffect, useMemo, useState } from 'react'
import cn from 'classnames'
import { resetSearchHistory } from 'redux/store/chatHistory/slice'
import { ReactComponent as Close16 } from '../../../assets/svg/Clos16.svg'

const ChatSearch = () => {
  const context = useChatContext()

  const dispatch = useDispatch()

  const searchHistory = useSelector(getChatHistoryFromSearch)
  const totalCounter = useMemo(() => {
    return context?.state.searchValue.length ? searchHistory.length : 0
  }, [context?.state.searchValue, searchHistory])
  const [currentSearchPosition, setCurrentSearchPosition] = useState(0)

  const handleChange = (e) => context.actions.handleSearchValue(e.target.value)

  const handleCancel = () => {
    context.actions.handleIsSearch(false)
    context.actions.handleSearchValue('')
    dispatch(resetSearchHistory())
    setCurrentSearchPosition(0)
  }

  const upHandler = () =>
    totalCounter > currentSearchPosition &&
    setCurrentSearchPosition(currentSearchPosition + 1)

  const downHandler = () =>
    currentSearchPosition > 1 &&
    setCurrentSearchPosition(currentSearchPosition - 1)

  useEffect(() => {
    if (currentSearchPosition) {
      context.actions.handleCurrentSearchValue(
        searchHistory[currentSearchPosition - 1]
      )
    }
  }, [currentSearchPosition])

  useEffect(() => {
    if (searchHistory.length)
      // context.actions.handleCurrentSearchValue(searchHistory[0])
      setCurrentSearchPosition(1)
  }, [totalCounter, searchHistory])

  useEffect(() => {
    setCurrentSearchPosition(0)
  }, [context?.state.searchValue])
  return (
    <div className="search-component row align-center">
      <div
        className={cn('search-component-arrow-up column', {
          disabled: totalCounter === currentSearchPosition
        })}
        onClick={upHandler}
      >
        <ArrowIcon />
      </div>
      <div
        className={cn('search-component-arrow-down column', {
          disabled: currentSearchPosition <= 1
        })}
        onClick={downHandler}
      >
        <ArrowIcon />
      </div>
      <div className="search-component-counter column">
        <Typography.Headline6 weight={TYPOGRAPHY_WEIGHT.NORMAL}>
          {currentSearchPosition}/{totalCounter}
        </Typography.Headline6>
      </div>
      <div className="search-component-input column">
        <Input
          className="mt-24 allow-clear"
          propsInput={{
            allowClear: { clearIcon: <Close16 /> },
            placeholder: PLACEHOLDERS.SEARCH,
            prefix: <Search16 />,
            value: context?.state.searchValue,
            onChange: handleChange
          }}
        />
      </div>
      <div className="search-component-btn column">
        <Button type={BUTTON_TYPES.DEFAULT} onClick={handleCancel} upperCase>
          {BTN_TXT.CANCEL}
        </Button>
      </div>
    </div>
  )
}

export default ChatSearch
