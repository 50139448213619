import { API_DICTIONARY, HTTP_METHODS } from '../../constants'
import Fetcher from '../../services/Fetcher'
import {
  TAcceptDeclineReport,
  TActualPaymentsList,
  TFacilities,
  TFinancialsListRequest,
  TFinancialsListResponse,
  TGetLinkPayment,
  TReportsList,
  TVendors
} from './types'

const fetcher = new Fetcher({})

export const getFinanceData: TFinancialsListRequest = async (data) =>
  fetcher.request<unknown, TFinancialsListResponse>({
    url: `${API_DICTIONARY.FINANCES}${API_DICTIONARY.FINANCES_DASHBOARD}${API_DICTIONARY.CALCULATIONS}`,
    method: HTTP_METHODS.GET,
    ...data
  })

export const getReports = async (data?) =>
  fetcher.request<unknown, TReportsList>({
    url: `${API_DICTIONARY.FINANCES}${API_DICTIONARY.FINANCES_DASHBOARD}${API_DICTIONARY.REPORTS}`,
    method: HTTP_METHODS.GET,
    ...data
  })

export const getActualPayments = async (data?) =>
  fetcher.request<unknown, TActualPaymentsList>({
    url: `${API_DICTIONARY.FINANCES}${API_DICTIONARY.FINANCES_DASHBOARD}${API_DICTIONARY.ACTUAL_PAYMENTS}`,
    method: HTTP_METHODS.GET,
    ...data
  })

export const resendRebate = async (UUID: string) =>
  fetcher.request<unknown, string>({
    url: `${API_DICTIONARY.FINANCES}${API_DICTIONARY.RECALCULATION}${UUID}/`,
    method: HTTP_METHODS.POST
  })

export const getVendors = async () =>
  fetcher.request<unknown, TVendors>({
    url: `${API_DICTIONARY.VENDORS}${API_DICTIONARY.SHORT_INFO}`,
    method: HTTP_METHODS.GET
  })

export const getFacilities = async () =>
  fetcher.request<unknown, TFacilities>({
    url: `${API_DICTIONARY.FINANCES}${API_DICTIONARY.FINANCES_DASHBOARD}${API_DICTIONARY.FACILITIES}`,
    method: HTTP_METHODS.GET
  })
export const acceptDeclineReport = async (
  UUID: string,
  data: TAcceptDeclineReport
) =>
  fetcher.request<unknown, any>({
    url: `${API_DICTIONARY.FINANCES}${API_DICTIONARY.FINANCES_DASHBOARD}${API_DICTIONARY.CALCULATIONS}${UUID}/`,
    method: HTTP_METHODS.PATCH,
    data
  })

export const acceptAndPayReport = async (UUID: string) =>
  fetcher.request<unknown, any>({
    url: `${API_DICTIONARY.PAYMENTS}${API_DICTIONARY.PAYMENTS_SESSION_LINK}${UUID}/`
  })
export const getLikToAccount = async (data: TGetLinkPayment) =>
  fetcher.request<unknown, any>({
    url: `${API_DICTIONARY.PAYMENTS}${API_DICTIONARY.CONNECT_ACCOUNT_LINK}`,
    method: HTTP_METHODS.POST,
    data
  })
export const getBankDetails = async (data: TGetLinkPayment) =>
  fetcher.request<unknown, any>({
    url: `${API_DICTIONARY.PAYMENTS}${API_DICTIONARY.CONNECT_ACCOUNT}`,
    method: HTTP_METHODS.POST,
    data
  })
