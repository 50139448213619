import { AvatarProps, Avatar as AntdAvatar } from 'antd'
import './styles.scss'
import cn from 'classnames'

type Props = AvatarProps & {
  name: string
  isChannel?: boolean
}
export const Avatar = ({
  name,
  isChannel = false,
  ...props
}: Props): JSX.Element => (
  <AntdAvatar.Group className={cn('form_avatar-container', { isChannel })}>
    <AntdAvatar className="form_avatar" gap={5} {...props}>
      {name}
    </AntdAvatar>
  </AntdAvatar.Group>
)
