import { Button, BUTTON_TYPES } from '../../../components/Button'
import { BTN_TXT } from '../../../constants'
import { useContractBulkTableConfigContext } from '../Providers/ContractBulkTableConfigProvider'

const Actions = () => {
  const { actions } = useContractBulkTableConfigContext()
  return (
    <>
      <Button type={BUTTON_TYPES.DEFAULT} onClick={actions.onCancel} upperCase>
        {BTN_TXT.CANCEL}
      </Button>
      <Button type={BUTTON_TYPES.PRIMARY} onClick={actions.onSubmit} upperCase>
        {BTN_TXT.SUBMIT}
      </Button>
    </>
  )
}

export default Actions
