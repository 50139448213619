import Fetcher from '../../../services/Fetcher'
import { API_DICTIONARY, HTTP_METHODS, NOTIFICATIONS } from '../../../constants'

const fetcher = new Fetcher({})

export const deleteChatRequest = async (id: string, isPrivateChat?: boolean) =>
  fetcher.request<unknown, unknown>({
    url: `${API_DICTIONARY.CHATS}${id}/`,
    method: HTTP_METHODS.DELETE,
    successMessage: isPrivateChat
      ? NOTIFICATIONS.PRIVATE_CHAT_DELETED
      : NOTIFICATIONS.GROUP_CHAT_DELETED
  })
