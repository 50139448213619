import cn from 'classnames'
import { useDropzone } from 'react-dropzone'
import { Button, BUTTON_TYPES } from 'components/Button'
import { Typography } from 'components/Typography'
import { ReactComponent as Attaches } from 'assets/svg/Attaches.svg'
import { TDropzoneProps } from './types'
import './styles.scss'

export const Dropzone = ({ className, ...props }: TDropzoneProps) => {
  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    ...props,
    useFsAccessApi: false
  })
  return (
    <div
      {...getRootProps({
        className: cn('dropzone', className, {
          'dropzone--active': isDragActive
        })
      })}
    >
      {!isDragActive ? (
        <>
          <Attaches />
          <Typography.Body1>
            <Button type={BUTTON_TYPES.LINK} isBody>
              Click to upload
            </Button>{' '}
            or drag a file here
          </Typography.Body1>
        </>
      ) : (
        <Typography.Body1>Drop file here</Typography.Body1>
      )}
      <input {...getInputProps()} />
    </div>
  )
}
