import {
  Can,
  usePrsDetailsAbility
} from '../Providers/PrsDetailsAbilityProvider'
import { ACTIONS, SUBJECTS } from '../../Abilities'
import { subject } from '@casl/ability'
import { Button, BUTTON_TYPES } from 'components/Button'
import { BTN_TXT, LABELS, PLACEHOLDERS } from 'constants/txt'
import { Typography, TYPOGRAPHY_WEIGHT } from 'components/Typography'
import Popup from 'components/Popup/Popup'
import { usePRSDetailsPopupContext } from '../Providers/PRSDetailsPopupProvider'
import { usePRSDetailsContext } from '../Providers/PRSDetailsContextProvider'
import {
  PRS_STATUSES,
  PRS_STATUSES_MESSAGES
} from 'pages/ProductRequests/constants'
import { useEffect, useState } from 'react'
import { Select } from 'components/Select'
import { ReactComponent as RenewIcon } from 'assets/svg/renew.svg'
import { ReactComponent as Plus16 } from 'assets/svg/Plus16.svg'
import './style.scss'
import { Tooltip } from 'components/Tooltip/Tooltip'
import { routes } from 'router'
import { useDispatch, useSelector } from 'react-redux'
import { getUser } from 'redux/store/user/getters'
import { setLoading } from 'redux/store/common/slice'
import { useParams } from 'react-router-dom'
import { getRcaList, updatePRS } from 'pages/ProductRequests/api'
import { IRFPStakeholder } from '../../../RFP/RFPSurvey/types'
import { TOption } from '../../../../components/Select/types'
import { notification } from '../../../../components/Notification'

const StartRevenueAssessment = () => {
  const prsDetailsContext = usePRSDetailsContext()

  const { id } = useParams()

  const dispatch = useDispatch()

  const { data } = prsDetailsContext.state
  const { handleGetPRSData } = prsDetailsContext.actions
  const { startRevenueAssessmentPopup } = usePRSDetailsPopupContext()

  const [rca, setRca] = useState([])

  const ability = usePrsDetailsAbility()

  const [selectedRca, setSelectedRca] = useState('')

  const getRcaOptions = () => {
    if (id) {
      getRcaList(id).then((res) => {
        setRca(res.data.results)
      })
    }
  }

  const user = useSelector(getUser)

  const { state, actions } = startRevenueAssessmentPopup

  useEffect(() => {
    if (
      ability.can(ACTIONS.RUN, subject(SUBJECTS.STEP, { ...data })) &&
      !!state.visible
    ) {
      dispatch(setLoading(true))
      Promise.all([getRcaOptions()]).finally(() => dispatch(setLoading(false)))
    }
  }, [id, state.visible])
  const openModal = () => actions.open()

  const onSend = () => {
    dispatch(setLoading(true))
    updatePRS(data.uuid, {
      status: PRS_STATUSES.REVENUE_ASSESSMENT,
      rca: selectedRca
    })
      .then(() => {
        handleGetPRSData()
        notification.success({
          message: `${PRS_STATUSES_MESSAGES.revenue_assessment}`
        })
        setSelectedRca('')
      })
      .catch((err) => {
        notification.error({
          message: err.data?.details[0]
        })
      })
      .finally(() => {
        dispatch(setLoading(false))
        onCancel()
      })
  }

  const returnSelectOptions = (arr: IRFPStakeholder[]): TOption[] => {
    if (arr?.length) {
      return [
        ...arr.map((item) => ({
          label: `${item.first_name} ${item.last_name}`,
          value: item.uuid
        }))
      ]
    }
    return []
  }

  const handleNewUser = () => {
    window
      .open(
        window?.API_HOST +
          `${routes.createUserProfile}/new?hsUser=${user.health_system}&role=rca`,
        '_blank'
      )
      ?.focus()
  }

  const onCancel = () => {
    actions.close()
    setSelectedRca('')
  }
  return (
    <>
      <Can
        I={ACTIONS.START}
        a={subject(SUBJECTS.REVENUE_ASSESSMENT, { ...data })}
      >
        <Button type={BUTTON_TYPES.PRIMARY} onClick={openModal} upperCase>
          {BTN_TXT.START_REVENUE_ASSESSMENT}
        </Button>
      </Can>
      <Popup
        visible={state.visible}
        onCancel={onCancel}
        title={state.title}
        width={442}
        className="start-revenue-assessment-modal"
        footer={
          <>
            <Button
              onClick={actions.close}
              type={BUTTON_TYPES.DEFAULT}
              upperCase
            >
              {BTN_TXT.CANCEL}
            </Button>
            <Button
              type={BUTTON_TYPES.PRIMARY}
              disabled={!selectedRca?.length}
              upperCase
              onClick={onSend}
            >
              {BTN_TXT.START}
            </Button>
          </>
        }
      >
        <>
          <Typography.Body1 weight={TYPOGRAPHY_WEIGHT.NORMAL}>
            To start revenue assessment, please select:
          </Typography.Body1>

          <div className="revenue-wrapper-select column align-start mt-16">
            <div className="row align-center full-width">
              <Select
                options={returnSelectOptions(rca)}
                propsItem={{
                  label: LABELS.RCA_REPRESENTATIVE,
                  name: 'rca_representative'
                }}
                propsSelect={{
                  defaultActiveFirstOption: true,
                  loading: !rca?.length,
                  onChange: setSelectedRca,
                  value: selectedRca,
                  placeholder: PLACEHOLDERS.PLEASE_SELECT
                }}
              />
              <Tooltip
                title="Refresh the RCA list"
                placement="topRight"
                id="run-new-step-tooltip"
              >
                <div className="renew-icon-wrapper" onClick={getRcaOptions}>
                  <RenewIcon />
                </div>
              </Tooltip>
            </div>
            <Button
              type={BUTTON_TYPES.GHOST}
              onClick={handleNewUser}
              icon={<Plus16 />}
              upperCase
            >
              {BTN_TXT.CREATE_RCA}
            </Button>
          </div>
        </>
      </Popup>
    </>
  )
}

export default StartRevenueAssessment
