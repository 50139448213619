import { useState, useEffect, useCallback } from 'react'
import { Rule } from 'antd/es/form'
import { VALIDATION_MESSAGES } from 'constants/txt'

export const useValidationPassword = () =>
  useCallback(
    () => ({
      validator: (_: Rule, value: string) => {
        const re =
          /^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])(?=.*[@,{}[\]:";'<>?/!$%^&*()_+|~\-=\\.#`]).{8,50}$/
        if (re.test(String(value)) || !value) return Promise.resolve()
        return Promise.reject(new Error(VALIDATION_MESSAGES.PASSWORD))
      }
    }),
    []
  )

export const useValidationRepeatPassword = () =>
  useCallback(
    ({ getFieldValue }: any) => ({
      validator: (_: Rule, value: string) => {
        if (!value || getFieldValue('password') === value) {
          return Promise.resolve()
        }
        return Promise.reject(new Error(VALIDATION_MESSAGES.MATCH_PASSWORDS))
      }
    }),
    []
  )

export const useDebounce = (value, delay) => {
  const [debouncedValue, setDebouncedValue] = useState(value)
  useEffect(() => {
    const timeoutID = setTimeout(() => {
      setDebouncedValue(value)
    }, delay)
    return () => {
      clearTimeout(timeoutID)
    }
  }, [value])
  return debouncedValue
}
