// import { AppThunk } from 'redux/store/createRootReducer'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { moduleName } from './constants'
import { IUserState, IUser, IUpdateUser } from './types'
import { fetchUser as fetchUserAPI } from 'features/Auth/api'
import { cleanUpPrivateStorage } from 'helper/common'
import { resetHealthSystemsListAction } from '../healthSystemsList/slice'
import { resetCommunityInfoAction } from '../chapterInfo/slice'
import { resetLoginAction } from '../login/slice'
import { reset as resetChatsAction } from '../chats/slice'
import { setLoading } from '../common/slice'
import { routes } from '../../../router'
import history from 'router/history'

const initialState: IUserState = {
  user: {
    uuid: '',
    email: '',
    first_name: '',
    last_name: '',
    phone: '',
    role: '',
    community: '',
    timezone: {
      uuid: '',
      name: '',
      offset: ''
    }
  },
  isUserLoading: false,
  isUserLoaded: false,
  error: null,
  token: null
}

const userSlice = createSlice({
  name: moduleName,
  initialState,
  reducers: {
    getUserStart(state: IUserState) {
      state.isUserLoading = true
      state.error = null
    },
    setUserToken(state: IUserState, action: PayloadAction<{ token: string }>) {
      const { token } = action.payload
      state.token = token
    },
    getUserFinish(state: IUserState) {
      state.isUserLoading = false
      state.isUserLoaded = true
    },
    getUserError(state: IUserState, action: PayloadAction<{ error: string }>) {
      const { error } = action.payload
      state.error = error
    },
    setUserAction(state: IUserState, action: PayloadAction<{ user: IUser }>) {
      const { user } = action.payload
      state.user = user
    },
    updateUserAction(
      state: IUserState,
      action: PayloadAction<{ updatedUser: IUpdateUser }>
    ) {
      const { updatedUser } = action.payload
      state.user = {
        ...(state.user || {}),
        ...updatedUser
      }
    },
    resetUserAction(state: IUserState) {
      Object.keys(initialState).forEach(
        (key) => ((state[key] as IUserState[typeof key]) = initialState[key])
      )
    }
  }
})

export default userSlice.reducer
export const {
  getUserStart,
  getUserError,
  getUserFinish,
  setUserAction,
  updateUserAction,
  resetUserAction,
  setUserToken
} = userSlice.actions

export const fetchUser =
  (completedCallback?: Function, failedCallback?: Function): any =>
  async (dispatch) => {
    try {
      dispatch(getUserStart())
      const result = await fetchUserAPI()
      if (result) {
        dispatch(setUserAction({ user: result.data as IUser }))
        if (completedCallback) {
          completedCallback(result.data)
        }
      }
    } catch (err: any) {
      if (err?.status === 401 && err?.data?.code === 'token_not_valid') {
        dispatch(LogOutAction())
        history.push(routes.login)
        if (failedCallback) failedCallback()
      } else {
        dispatch(getUserError({ error: err?.response.message as string }))
      }
    } finally {
      dispatch(getUserFinish())
      dispatch(setLoading(false))
    }
  }

export const LogOutAction = (): any => (dispatch) => {
  cleanUpPrivateStorage()
  dispatch(resetUserAction())
  dispatch(resetHealthSystemsListAction())
  dispatch(resetCommunityInfoAction())
  dispatch(resetLoginAction())
  dispatch(resetChatsAction())
}
