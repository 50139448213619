import Fetcher from 'services/Fetcher'
import { API_DICTIONARY, HTTP_METHODS } from 'constants/api'
import { ApproveCategoriesRequest, PendingCategoryRequest } from './types'

const fetcher = new Fetcher({})

export const approveContractCategories: ApproveCategoriesRequest = async (
  uuid,
  categories
) =>
  fetcher.request<unknown, PendingCategoryRequest[]>({
    url: `${API_DICTIONARY.USERS}${uuid}/${API_DICTIONARY.CONTRACT_CATEGORY_APPROVAL}`,
    method: HTTP_METHODS.POST,
    data: {
      contract_categories: categories
    }
  })
