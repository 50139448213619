import { FC } from 'react'

import './VendorSelectionItem.scss'
import { Checkbox } from 'components/Checkbox'
import { Select } from 'components/Select'
import { PLACEHOLDERS } from 'constants/txt'
import { useRFPDetailsContext } from 'features/RFP/RFPDetails/Providers/RFPDetailsContextProvider'
import { IRFPVendor } from 'features/RFP/RFPSurvey/types'
import { TListItem } from 'components/Forms/forms.user.d'

const convertCategoryToOption = (categories: TListItem[]) =>
  categories.map((c) => ({
    label: c.name,
    value: c.uuid
  }))

type Props = {
  vendor: IRFPVendor
  checked: boolean
  selectedCategories: string[]
  selectVendor: (vendorUuid: string) => void
  selectCategory: (categoryValues: string[]) => void
}

const RFPVendorSelectionItem: FC<Props> = ({
  vendor,
  checked,
  selectedCategories,
  selectVendor,
  selectCategory
}) => {
  const rfpDetailsContext = useRFPDetailsContext()

  const { data } = rfpDetailsContext.state

  const handleCheckboxChange = () => {
    selectVendor(vendor.uuid)
  }

  const handleSelectorChange = (value) => {
    selectCategory(value)
  }

  return (
    <div className="vendor-selection-item">
      <Checkbox
        propsCheckbox={{
          checked,
          onChange: handleCheckboxChange
        }}
      >
        {vendor.name}
      </Checkbox>
      <Select.Multi
        options={convertCategoryToOption(data.contract_categories)}
        propsSelect={{
          disabled: !checked,
          value: selectedCategories,
          placeholder: PLACEHOLDERS.SELECT_CATEGORY,
          onChange: handleSelectorChange
        }}
      />
    </div>
  )
}

export default RFPVendorSelectionItem
