import { Reassign } from '../../../Reassign/ReassignPopup'
import { Button, BUTTON_TYPES } from '../../../../components/Button'
import UserItem from '../../../../constants/UserItem'
import { usePRSDetailsContext } from '../Providers/PRSDetailsContextProvider'
import { ReactComponent as ReassignIcon } from 'assets/svg/Reassign.svg'
import { useState } from 'react'
import { IPartialUserInfo } from '../../../../redux/store/user/types'

export type TProps = {
  user: IPartialUserInfo
  canReassignOwner: boolean
  isRca?: boolean
}

const ReassignWrapper = ({ user, canReassignOwner, isRca }: TProps) => {
  const { state, actions } = usePRSDetailsContext()
  const { data } = state
  const { handleGetPRSData } = actions

  const [isOpenReassign, setIsOpenReassign] = useState(false)

  return !!data?.creator?.uuid ? (
    <div className="row align-start">
      <UserItem key={data?.creator.uuid} user={user} />
      {canReassignOwner && (
        <Button.Icon
          type={BUTTON_TYPES.LINK}
          icon={<ReassignIcon />}
          onClick={() => setIsOpenReassign(true)}
          small
        />
      )}
      {canReassignOwner && isOpenReassign && (
        <Reassign
          isPrs
          isRFP={false}
          isRca={isRca}
          isReassignPopupOpen={isOpenReassign}
          setIsReassignPopupOpen={setIsOpenReassign}
          ownerRole={user.role}
          ownerName={`${user.first_name} ${user.last_name}`}
          uuid={data?.uuid}
          update={handleGetPRSData}
        />
      )}
    </div>
  ) : null
}

export default ReassignWrapper
