import cn from 'classnames'
import { Select as SelectAntd } from 'antd'
import { ReactComponent as ChevronDown16 } from 'assets/svg/ChevronDown16.svg'
import { Props } from './types'
import './select.default.scss'
import { ReactComponent as Check16 } from '../../assets/svg/Check16.svg'
import { ReactElement, useMemo } from 'react'

const { OptGroup, Option } = SelectAntd

const DefaultNoForm = ({ ...props }: Props): ReactElement => {
  const onFilter = useMemo(() => {
    if (props.propsSelect?.filterOption !== undefined)
      return props.propsSelect.filterOption
    return (input, option) =>
      option?.props.children?.toLowerCase().indexOf(input?.toLowerCase()) >=
        0 ||
      option?.props.value?.toLowerCase().indexOf(input?.toLowerCase()) >= 0
  }, [props.propsSelect])

  return (
    <div
      className={cn(
        'custom-select ant-form-item',
        'select-default',
        props.className,
        {
          'custom-select-error': props.hasCustomError,
          'placeholder-show': props.propsSelect?.value === ''
        }
      )}
    >
      <SelectAntd
        getPopupContainer={(node) => node}
        {...props.propsSelect}
        placement="bottomLeft"
        menuItemSelectedIcon={!props.onPagination && <Check16 />}
        dropdownClassName={cn(
          'custom-select__dropdown',
          props.dropdownClassName
        )}
        suffixIcon={<ChevronDown16 />}
        filterOption={onFilter}
        showSearch={
          props.propsSelect?.showSearch !== undefined
            ? props.propsSelect?.showSearch
            : true
        }
      >
        {props.options.map((option) =>
          !option?.options ? (
            <Option value={option.value} key={option.value}>
              {option.label}
            </Option>
          ) : (
            <OptGroup label={option.label} key={option.key}>
              {option.options.map((i) => (
                <Option value={i.value} key={i.value}>
                  {i.label}
                </Option>
              ))}
            </OptGroup>
          )
        )}
      </SelectAntd>
    </div>
  )
}

export default DefaultNoForm
