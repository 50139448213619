import {
  FC,
  createContext,
  PropsWithChildren,
  useContext,
  useMemo
} from 'react'

import usePopup, { PopupReturnType } from 'hooks/usePopup'

type ContextProps = {
  submitClinicalReviewPopup: PopupReturnType
}

const basePopup = (title: string) => ({
  title
})

const ClinicalReviewPopupContext = createContext<ContextProps>({
  submitClinicalReviewPopup: null!
})

const ClinicalReviewPopupProvider: FC<PropsWithChildren> = ({ children }) => {
  const submitClinicalReviewPopupProps = usePopup(
    basePopup('Submit clinical review response?')
  )

  const context = useMemo(
    () => ({
      submitClinicalReviewPopup: submitClinicalReviewPopupProps
    }),
    [submitClinicalReviewPopupProps]
  )

  return (
    <ClinicalReviewPopupContext.Provider value={context}>
      {children}
    </ClinicalReviewPopupContext.Provider>
  )
}

export const useClinicalReviewPopup = () =>
  useContext(ClinicalReviewPopupContext)

export default ClinicalReviewPopupProvider
