import { Form } from 'antd'
import { FieldWithLabel } from '../../../../components/FieldWithLabel/FieldWithLabel'
import { Select } from '../../../../components/Select'
import { PLACEHOLDERS } from '../../../../constants'
import { useDispatch, useSelector } from 'react-redux'
import { getIsLoading } from '../../../../redux/store/common/getters'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { setLoading } from '../../../../redux/store/common/slice'
import { usePRSDetailsContext } from '../Providers/PRSDetailsContextProvider'
import { usePRSDetailsActions } from '../Providers/PRSDetailsActionsProvider'
import { getPrsClinicalSponsors } from '../../../../pages/ProductRequests/api'
import { TFormClinical, TResponsibleUser } from '../types'
import { TOption } from '../../../../components/Select/types'

const FormClinicalSponsor = ({ setIsChanged }: TFormClinical) => {
  const dispatch = useDispatch()
  const isLoading = useSelector(getIsLoading)

  const { state } = usePRSDetailsContext()
  const { data } = state
  const { assignClinicalSponsor } = usePRSDetailsActions()

  const [selectedUuid, setSelected] = useState<TResponsibleUser['uuid'] | null>(
    null
  )
  const [availableUsers, setAvailableUsers] = useState<TResponsibleUser[]>([])

  const handleFinishForm = useCallback(() => {
    assignClinicalSponsor(selectedUuid)
  }, [assignClinicalSponsor, selectedUuid])

  const updateState = (dispatch) => (value) => {
    dispatch(value)
    setIsChanged(true)
  }
  const getAvailableResponsibleUsers = useCallback(async () => {
    if (!data.uuid) {
      throw new Error('Contract ID not provided')
    }

    try {
      dispatch(setLoading(true))

      const response = await getPrsClinicalSponsors(data.uuid)

      if (response?.data?.results.length > 0) {
        setAvailableUsers(response?.data?.results)
      }
    } catch (e) {
      console.error(e)
    } finally {
      dispatch(setLoading(false))
    }
  }, [data.uuid, dispatch])

  const getSelectedAvailableUsers = useCallback(() => {
    setSelected(data.clinical_sponsor_stakeholder?.uuid ?? null)
  }, [data])

  const availableLegalUsers = useMemo(() => {
    const suggested = [] as TOption[]
    const res = [] as TOption[]
    availableUsers.forEach((u) => {
      if (u.suggested) {
        suggested.push({
          label: u.first_name + ' ' + u.last_name,
          value: u.uuid
        })
      } else {
        res.push({
          label: u.first_name + ' ' + u.last_name,
          value: u.uuid
        })
      }
    })
    return [
      { label: 'Suggested', options: suggested },
      { label: 'All Stakeholders', options: res }
    ].filter((i) => i.options.length)
  }, [availableUsers])

  useEffect(() => {
    getSelectedAvailableUsers()
  }, [getSelectedAvailableUsers])

  useEffect(() => {
    getAvailableResponsibleUsers()
  }, [getAvailableResponsibleUsers])
  return (
    <Form
      id="prs-add-clinical-sponsor"
      className="prs-add-clinical-sponsor mt-12"
      onFinish={handleFinishForm}
    >
      {!isLoading && (
        <>
          <FieldWithLabel title={'Stakeholder'}>
            <Select
              options={availableLegalUsers}
              propsSelect={{
                value: selectedUuid,
                placeholder: PLACEHOLDERS.PLEASE_SELECT,
                onChange: updateState(setSelected)
              }}
            />
          </FieldWithLabel>
        </>
      )}
    </Form>
  )
}

export default FormClinicalSponsor
