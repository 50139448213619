import {
  Can,
  usePrsDetailsAbility
} from '../Providers/PrsDetailsAbilityProvider'
import { ACTIONS, SUBJECTS } from '../../Abilities'
import { subject } from '@casl/ability'
import { Button, BUTTON_TYPES } from '../../../../components/Button'
import { BTN_TXT, TOOLTIP } from '../../../../constants'
import { usePRSDetailsContext } from '../Providers/PRSDetailsContextProvider'
import { usePRSDetailsPopupContext } from '../Providers/PRSDetailsPopupProvider'
import { Popup } from 'components/Popup'
import { Form } from 'antd'
import {
  Typography,
  TYPOGRAPHY_WEIGHT
} from '../../../../components/Typography'
import Input from '../../../../components/Input/Input'
import { setLoading } from '../../../../redux/store/common/slice'
import {
  getTrialStatisticsUsers,
  patchTrial
} from '../../../../pages/ProductRequests/api'
import { notification } from '../../../../components/Notification'
import {
  PRS_RESPOND,
  PRS_STATUSES,
  PRS_STATUSES_MESSAGES
} from '../../../../pages/ProductRequests/constants'
import { useDispatch } from 'react-redux'
import cn from 'classnames'
import { useEffect, useMemo, useState } from 'react'
import './style.scss'
import { Tooltip } from 'components/Tooltip/Tooltip'

export type TSendToVendorForm = {
  message_for_vendor: string
}

const SendToVendor = () => {
  const prsDetails = usePRSDetailsContext()
  const dispatch = useDispatch()
  const { data } = prsDetails.state
  const { handleGetPRSData } = prsDetails.actions
  const { sendToVendorPopup } = usePRSDetailsPopupContext()
  const { state, actions } = sendToVendorPopup

  const [responses, setResponses] = useState(0)
  const ability = usePrsDetailsAbility()
  const openSendPopup = () => actions.open()
  const onCancelHandler = () => {
    sendToVendorForm.setFieldsValue({ message_for_vendor: '' })
    actions.close()
  }

  const [sendToVendorForm] = Form.useForm()

  const message_for_vendor = Form.useWatch(
    'message_for_vendor',
    sendToVendorForm
  )

  const getAvailableTrialResults = () => {
    getTrialStatisticsUsers(data.uuid as string, {
      params: {
        limit: 1,
        offset: 0,
        decisions: [PRS_RESPOND.APPROVED, PRS_RESPOND.REJECTED]
      }
    }).then((res) => {
      setResponses(res.data.results?.length)
    })
  }

  useEffect(() => {
    if (ability.can(ACTIONS.SEND, subject(SUBJECTS.TO_VENDOR, { ...data })))
      getAvailableTrialResults()
  }, [data, ability])

  const isDisabled = useMemo(() => {
    return !responses
  }, [responses])

  const onSend = (value: TSendToVendorForm) => {
    dispatch(setLoading(true))
    patchTrial(data.uuid, {
      status: PRS_STATUSES.TRIAL_REQUESTED,
      message_for_vendor: value.message_for_vendor
    })
      .then(() => {
        handleGetPRSData()
        notification.success({
          message: `${PRS_STATUSES_MESSAGES.trial_requested}`
        })
        sendToVendorForm.resetFields()
      })
      .catch((err) => {
        notification.error({
          message: err.data?.details[0]
        })
      })
      .finally(() => {
        dispatch(setLoading(false))
        sendToVendorForm.resetFields()
        onCancelHandler()
      })
  }
  return (
    <div className="send-to-vendor-wrapper">
      <Can I={ACTIONS.SEND} a={subject(SUBJECTS.TO_VENDOR, { ...data })}>
        <Tooltip
          id="disable-send-prs-btn"
          title={
            <span
              dangerouslySetInnerHTML={{
                __html:
                  TOOLTIP.SEND_TRIAL_TO_VENDOR_ERROR?.replace('\n', '<br/>') ||
                  ''
              }}
            />
          }
          overlayInnerStyle={{
            display: isDisabled ? 'block' : 'none'
          }}
          className="disable-send-prs-btn-container"
          placement="bottomRight"
          mouseLeaveDelay={0}
        >
          <span
            className={cn({
              'send-prs-to-clinical-sponsor-disabled': isDisabled
            })}
          >
            <Button
              type={BUTTON_TYPES.PRIMARY}
              onClick={openSendPopup}
              upperCase
              disabled={isDisabled}
            >
              {BTN_TXT.SEND_TRIAL_REQUEST_TO_VENDOR}
            </Button>
          </span>
        </Tooltip>
      </Can>
      <Popup
        visible={state.visible}
        onCancel={onCancelHandler}
        title="Send to vendor?"
        width={442}
        footer={
          <>
            <Button
              onClick={onCancelHandler}
              type={BUTTON_TYPES.DEFAULT}
              upperCase
            >
              {BTN_TXT.CANCEL}
            </Button>
            <Button
              form="decline-contract-form"
              disabled={!message_for_vendor?.trim()}
              htmlType="submit"
              type={BUTTON_TYPES.PRIMARY}
              upperCase
            >
              {BTN_TXT.SEND}
            </Button>
          </>
        }
      >
        <Form
          id="decline-contract-form"
          form={sendToVendorForm}
          onFinish={onSend}
          layout="vertical"
        >
          <Typography.Headline6
            weight={TYPOGRAPHY_WEIGHT.NORMAL}
            className="financials-table__subtitle"
          >
            Please provide query to vendor
          </Typography.Headline6>
          <Input.TextArea
            propsItem={{
              label: 'Query',
              name: 'message_for_vendor',
              rules: [
                {
                  max: 500
                }
              ]
            }}
            propsTextArea={{
              autoSize: {
                maxRows: 12
              },
              showCount: true,
              maxLength: 500
            }}
          />
        </Form>
      </Popup>
    </div>
  )
}

export default SendToVendor
