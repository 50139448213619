import { Button, BUTTON_TYPES } from 'components/Button'
import { BTN_TXT } from 'constants/txt'
import './style.scss'
import { Popup } from 'components/Popup'
import { useRFPDetailsPopupContext } from 'features/RFP/RFPDetails/Providers/RFPDetailsPopupProvider'
import RFPTimelines from 'features/RFP/RFPTimelines/RFPTimelines'
import { useRFPDetailsContext } from 'features/RFP/RFPDetails/Providers/RFPDetailsContextProvider'

export default () => {
  const { state, actions } = useRFPDetailsContext()
  const { sendRFPPopup } = useRFPDetailsPopupContext()

  const { title, visible, onCancel } = sendRFPPopup.state
  const { close } = sendRFPPopup.actions

  return (
    <Popup
      visible={visible}
      onCancel={onCancel}
      className="timeline-popup"
      title={title}
      width={676}
      footer={
        <>
          <Button type={BUTTON_TYPES.DEFAULT} onClick={close} upperCase>
            {BTN_TXT.CANCEL}
          </Button>
          <Button
            type={BUTTON_TYPES.PRIMARY}
            htmlType="submit"
            form="rfp-timeline-form-id"
            upperCase
          >
            {state.isUpdate.biddingUpdate
              ? BTN_TXT.UPDATE_TIME
              : state.isUpdate.surveyUpdate
              ? BTN_TXT.UPDATE_TIME_AND_SEND_SURVEY
              : BTN_TXT.SEND_RFP_AND_START_BIDDING}
          </Button>
        </>
      }
    >
      <RFPTimelines
        onFinish={actions.createRfpTimelinesAsync}
        timelines={state.timelineForm}
        validation={state.timelineValidation}
      />
    </Popup>
  )
}
