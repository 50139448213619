import { ReactComponent as WarningIcon } from '../../assets/svg/WarningIcon.svg'
import { Typography } from '../Typography'
import './styles.scss'
import cn from 'classnames'

export const InfoLine = ({
  text,
  children,
  className
}: {
  text: string
  className?: string
  children?: JSX.Element
}) => {
  return (
    <div className={cn('info-line row space-between info', className)}>
      <WarningIcon />
      <div className="info-line__content column">
        <Typography.Body2 className="rfp-resend-bid-reason__content__header">
          {text}
        </Typography.Body2>
        {children}
      </div>
    </div>
  )
}
