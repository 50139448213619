import {
  FC,
  createContext,
  PropsWithChildren,
  useContext,
  useMemo
} from 'react'

import usePopup, { PopupReturnType } from 'hooks/usePopup'

type ContextProps = {
  editSavingsGoalsPopup: PopupReturnType
  historyGoalsPopup: PopupReturnType
}

const basePopup = (title: string) => ({
  title
})

const DashboardPopupContext = createContext<ContextProps>({
  editSavingsGoalsPopup: null!,
  historyGoalsPopup: null!
})

const DashboardPopupProvider: FC<PropsWithChildren> = ({ children }) => {
  const editSavingsGoalsPopupProps = usePopup(basePopup('Edit savings goals'))
  const historyGoalsPopupProps = usePopup(basePopup('History'))

  const context = useMemo(
    () => ({
      editSavingsGoalsPopup: editSavingsGoalsPopupProps,
      historyGoalsPopup: historyGoalsPopupProps
    }),
    [editSavingsGoalsPopupProps, historyGoalsPopupProps]
  )

  return (
    <DashboardPopupContext.Provider value={context}>
      {children}
    </DashboardPopupContext.Provider>
  )
}

export const useDashboardPopup = () => useContext(DashboardPopupContext)

export default DashboardPopupProvider
