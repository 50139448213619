import { useState, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { Form } from 'antd'
import cn from 'classnames'
import { Input } from 'components/Input'
import { Link } from 'components/Link'
import { Typography } from 'components/Typography'
import { Button, BUTTON_TYPES } from 'components/Button'
import { BTN_TXT, LABELS, PLACEHOLDERS } from 'constants/txt'
import { setLoading } from 'redux/store/common/slice'
import { requestResetPassword } from 'features/SignPopup/api'
import {
  TFormForgotPasswordForm,
  TFormForgotPassword
} from './forms.forgotPassword.d'
import { forgotPasswordInitialValues } from './constants'

export const ForgotPassword: TFormForgotPassword = ({
  className,
  setOpenForgotPopup,
  emailValue
}): JSX.Element => {
  const [error, setError] = useState('')
  const dispatch = useDispatch()
  const [form] = Form.useForm()
  const email = Form.useWatch('email', form)
  useEffect(() => {
    dispatch(setLoading(false))
    form.setFieldsValue({ email: emailValue })
  }, [])

  const handleResetPassword = () => {
    dispatch(setLoading(true))
    requestResetPassword({ email })
      .then(() => {
        setOpenForgotPopup(false)
        form.resetFields()
        setError('')
      })
      .catch((e) => setError(e.data.email[0]))
      .finally(() => dispatch(setLoading(false)))
  }

  return (
    <Form<TFormForgotPasswordForm>
      className={cn('forms-forgot-password', className)}
      layout="vertical"
      onFinish={handleResetPassword}
      initialValues={forgotPasswordInitialValues}
      form={form}
    >
      {/* prettier-ignore */}
      <Typography.Body1>
        We’ll send you a link to your email to update your password or contact
        us via email <Link to="//mailto:info@cognushealth.com">info@cognushealth.com</Link>
      </Typography.Body1>
      <div className="row mt-24">
        <Input
          propsInput={{ placeholder: PLACEHOLDERS.EMAIL }}
          propsItem={{
            label: LABELS.EMAIL,
            help: error,
            name: 'email',
            validateStatus: !!error ? 'error' : undefined
          }}
        />
      </div>
      <div className="row mt-32">
        <Button
          type={BUTTON_TYPES.PRIMARY}
          onClick={handleResetPassword}
          disabled={!email}
          upperCase
        >
          {BTN_TXT.SEND}
        </Button>
      </div>
    </Form>
  )
}
