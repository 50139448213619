import { createSlice, PayloadAction } from '@reduxjs/toolkit'

import { moduleName } from './constants'
import { IChatHistoryState, IChatHistory } from './types'
import { ResponseWithPagination } from '../../../constants'

const initialState: IChatHistoryState = {
  chatHistory: [],
  total: 0,
  isLoading: false,
  isLoaded: false,
  error: null,
  messageToReply: null,
  searchMessages: []
}

const slice = createSlice({
  name: moduleName,
  initialState,
  reducers: {
    loading(state: IChatHistoryState) {
      state.isLoading = true
      state.error = null
    },
    finish(state: IChatHistoryState) {
      state.isLoading = false
    },
    error(
      state: IChatHistoryState,
      action: PayloadAction<{ error: IChatHistoryState['error'] }>
    ) {
      const { error } = action.payload
      state.error = error
    },
    reset(state: IChatHistoryState) {
      Object.keys(initialState).forEach((key) => {
        state[key as string] = initialState[key]
      })
    },
    set(
      state: IChatHistoryState,
      action: PayloadAction<{ results: IChatHistory[]; count: number }>
    ) {
      state.chatHistory = action.payload.results
      state.total = action.payload.count
    },
    add(state: IChatHistoryState, action: PayloadAction<IChatHistory>) {
      state.chatHistory = [action.payload, ...state.chatHistory]
      state.total = state.total + 1
    },
    updateReadByUsers(state: IChatHistoryState) {
      state.chatHistory = state.chatHistory?.map((i) => ({
        ...i,
        read_by_members: true
      }))
    },
    bulk(
      state: IChatHistoryState,
      action: PayloadAction<ResponseWithPagination<IChatHistory>>
    ) {
      state.chatHistory = [...state.chatHistory, ...action.payload.results]
      state.total = action.payload.count
    },
    setMessageToReply(
      state: IChatHistoryState,
      action: PayloadAction<Omit<IChatHistory, 'read'>>
    ) {
      state.messageToReply = { ...action.payload, read: false }
    },
    resetMessageToReply(state: IChatHistoryState) {
      state.messageToReply = null
    },
    setSearchHistory(
      state: IChatHistoryState,
      action: PayloadAction<{ results: IChatHistory[] }>
    ) {
      state.searchMessages = action.payload.results
    },
    resetSearchHistory(state: IChatHistoryState) {
      state.searchMessages = []
    }
  }
})

export default slice.reducer
export const {
  loading,
  finish,
  error,
  reset,
  set,
  add,
  bulk,
  setMessageToReply,
  resetMessageToReply,
  setSearchHistory,
  resetSearchHistory,
  updateReadByUsers
} = slice.actions
