import moment, { Moment } from 'moment'
export const requestDateFormat = 'YYYY-MM-DD'
export const requestTimeFormat12 = 'hh:mm A'

export const requestMonthFormat = 'MMM YYYY'
export const allFormatDate = 'YYYY-MM-DD HH:mm:ss Z'

export const weekdayAndDateFormat = 'ddd L'
export const hoursMinutesFormat = 'LT'

export const convertDateToRequestFormat = (date: Moment, format?: string) =>
  moment(date, format).format(requestDateFormat)
