import { CellChange, DefaultCellTypes, Row } from '@silevis/reactgrid'
import {
  IPickerList,
  IPickersMap,
  ITableItemsData,
  TAdditionalIconColIds,
  TAvailableColumnIds,
  TBulkErrorResponse,
  TCellInfo,
  TCellTypes,
  TErrorResponseTableData
} from './types'
import { IconCellInterface } from './Table/Cells/CustomCellIconTemplate'
import { ReactComponent as Warning16Icon } from 'assets/svg/WarningRed16.svg'
import { ReactComponent as Delete16Icon } from 'assets/svg/Delete16Red.svg'
import { TextCellInterface } from './Table/Cells/CustomCellTextTemplate'
import { defaultFocusPosition, defaultHeaderRowId } from './constants'
import { NumberCellInterface } from './Table/Cells/CustomCellNumberTemplate'
import { SelectCellInterface } from './Table/Cells/CustomCellSelectTemplate'
import { ManualContractCreationRequestData } from '../ContractDetails/types'
import { convertDateToRequestFormat } from '../../utils/moment'
import moment from 'moment'
import { DatepickerCellInterface } from './Table/Cells/CustomCellDatepickerTemplate'
import {
  currencyParser,
  twoDecimalParser
} from '../ContractDetails/ContractDetailsGeneral/ContractDetailsForm/utils'

export const getHeaderRow = (data: string[]): Row => ({
  rowId: defaultHeaderRowId,
  height: 40,
  cells: data.map((item) => getHeaderCell(item))
})

export const getHeaderCell = (text: string): DefaultCellTypes => ({
  type: 'header',
  text
})

export const getCell = ({
  cell,
  row,
  rowId,
  focusChanged,
  errorCellIds,
  deletePressed,
  pickerItems
}: TCellInfo): TCellTypes => {
  const itemText = row[cell.columnId] ?? ''
  const isError = errorCellIds.includes(cell?.columnId)
  const generalVals = {
    type: cell.cellType,
    text: itemText,
    isError,
    focusCallback: () => focusChanged({ rowId, columnId: cell.columnId }),
    unFocusCallback: () => focusChanged(defaultFocusPosition)
  }
  switch (cell.cellType) {
    case 'dropdown':
      return {
        values: pickerItems,
        disabled: pickerItems === null,
        ...generalVals
      } as SelectCellInterface
    case 'icon':
      return getIconData({
        deletePressed,
        isError,
        columnId: cell.columnId as TAdditionalIconColIds,
        rowId
      })
    case 'date':
      return {
        value: itemText,
        placeholder: cell.placeholder,
        ...generalVals
      } as DatepickerCellInterface
    case 'number':
      const adminFee = cell.columnId === 'admin_fee'
      return {
        value: itemText,
        placeholder: cell.placeholder,
        max: adminFee ? '3' : undefined,
        parser: adminFee ? twoDecimalParser : currencyParser,
        ...generalVals
      } as NumberCellInterface
    case 'text':
    default:
      return {
        value: itemText,
        placeholder: cell.placeholder,
        ...generalVals
      } as TextCellInterface
  }
}

const getIconData = ({
  columnId,
  deletePressed,
  rowId,
  isError
}: Pick<TCellInfo, 'deletePressed' | 'rowId'> & {
  isError: boolean
  columnId: TAdditionalIconColIds
}): IconCellInterface => {
  switch (columnId) {
    case 'deleteIcon':
      return {
        type: 'icon',
        iconSrc: deletePressed && <Delete16Icon />,
        onIconClick: deletePressed ? () => deletePressed(rowId) : undefined
      } as IconCellInterface
    case 'icon':
      return {
        type: 'icon',
        iconSrc: isError && <Warning16Icon />
      } as IconCellInterface
  }
}

export const getUpdatedRowInfo = (
  changes: CellChange<TCellTypes>[]
): Partial<ITableItemsData> => {
  return changes.reduce((prev, item) => {
    let value: string | number
    switch (item.newCell.type) {
      case 'dropdown':
      case 'date':
        value = item.newCell['text']
        break
      case 'number':
        value = item.newCell.value
        break
      case 'text':
        value = item.newCell.text
        break
      default:
        value = ''
    }
    return {
      ...prev,
      [item.columnId]: value
    }
  }, {})
}

export const parseFieldValue = (
  items: Partial<ITableItemsData>,
  getPickerItems: (columnId: TAvailableColumnIds) => IPickerList[] | null
): Partial<ITableItemsData> => {
  const updatedItems = {}
  Object.keys(items).forEach((key) => {
    switch (key) {
      case 'contract_type':
      case 'vendor':
      case 'vendor_contract_steward':
      case 'contract_category':
        updatedItems[key] =
          (getPickerItems(key) ?? []).find(
            (item) => item.label === items[key] || item.value === items[key]
          )?.value ?? null
        break
      case 'finish_date':
      case 'start_date':
        updatedItems[key] =
          items[key] && moment(items[key]).isValid() ? moment(items[key]) : null
        break
      case 'expected_spend':
      case 'expected_savings':
        updatedItems[key] = items[key] ? Number(items[key])?.toFixed(2) : null
        break
      case 'admin_fee':
        updatedItems[key] = items[key]
          ? Number(items[key]) > 3
            ? 3
            : items[key]?.toFixed(2)
          : null
        break
      default:
        updatedItems[key] = items[key]
    }
  })
  return updatedItems
}

export const parseTableDataToRequest = (
  data: ITableItemsData[],
  creatorId: string
): Partial<ManualContractCreationRequestData>[] => {
  return data.map((item) => ({
    creator: creatorId,
    contract_category: item.contract_category,
    vendor_contract_steward: item.vendor_contract_steward,
    name: item.contract_name,
    start_date: item.start_date
      ? convertDateToRequestFormat(item.start_date)
      : '',
    primary_finish_date: item.finish_date
      ? convertDateToRequestFormat(item.finish_date)
      : '',
    admin_fee: item.admin_fee ?? undefined,
    expected_spend: item.expected_spend ?? undefined,
    expected_savings: item.expected_savings ?? undefined,
    creation_type: item.contract_type
  }))
}

export const parseErrorResponse = (
  data: TBulkErrorResponse
): TErrorResponseTableData => {
  return (data.contracts ?? []).reduce((acc, item, idx) => {
    const errors: TAvailableColumnIds[] = []
    const keys = Object.keys(item)
    if (keys.length) {
      errors.push('icon')
      Object.keys(item).forEach((key) => {
        switch (key) {
          case 'creator':
            break
          case 'name':
            errors.push('contract_name')
            break
          case 'primary_finish_date':
            errors.push('finish_date')
            break
          case 'creation_type':
            errors.push('contract_type')
            break
          default:
            errors.push(key)
        }
      })
      return {
        ...acc,
        [idx]: errors
      }
    }
    return acc
  }, {} as TErrorResponseTableData)
}

export const checkMissingFieldsValidation = (
  data: ITableItemsData[]
): TErrorResponseTableData => {
  return data.reduce((acc, item, idx) => {
    const errors: TAvailableColumnIds[] = []
    Object.keys(item).forEach((key) => {
      switch (key) {
        // these three fields are required to proceed
        case 'vendor_contract_steward':
        case 'vendor':
        case 'contract_category':
        case 'contract_name':
        case 'contract_type':
        case 'start_date':
        case 'finish_date':
          if (!item[key]) {
            errors.push(key)
          }
          break
      }
    })
    if (errors.length) {
      errors.push('icon')
      return {
        ...acc,
        [idx]: errors
      }
    }
    return acc
  }, {} as TErrorResponseTableData)
}

export const updateRowIdsOnDelete = (
  data: IPickersMap | TErrorResponseTableData,
  rowId: string | number
): IPickersMap | TErrorResponseTableData => {
  return Object.keys(data).reduce((acc, key) => {
    const idx = Number(key)
    if (idx === rowId) {
      return acc
    } else if (idx < rowId) {
      return {
        ...acc,
        [idx]: data[key]
      }
    } else {
      return {
        ...acc,
        [idx - 1]: data[key]
      }
    }
  }, {})
}
