import { useCallback } from 'react'
import { useDispatch } from 'react-redux'
import { useParams } from 'react-router-dom'

import { BTN_TXT, VALIDATION_MESSAGES } from 'constants/txt'
import { Popup } from 'components/Popup'
import { Typography } from 'components/Typography'
import { Button, BUTTON_TYPES } from 'components/Button'
import { setLoading } from 'redux/store/common/slice'
import { deleteChatMembersAsync } from 'redux/store/chatDetails/actions'
import { deleteChatMembers } from '../../../../redux/store/chats/slice'
import { DeleteMemberPopupProps } from './types'
import { notification } from '../../../../components/Notification'

export default (props: DeleteMemberPopupProps) => {
  const { user, deleteMemberPopup } = props

  const { id } = useParams()
  const dispatch = useDispatch<any>()

  const name = [user.first_name, user.last_name].join(' ')

  const updateMembersRequestAsync = useCallback(async () => {
    if (!id) {
      throw new Error('Chat ID not provided')
    }

    try {
      dispatch(setLoading(true))

      await dispatch(deleteChatMembersAsync(id, user))
      dispatch(deleteChatMembers(id))
      deleteMemberPopup.actions.close()

      notification.success({ message: VALIDATION_MESSAGES.DELETE_MEMBERS })
    } catch (e) {
      console.error(e)
    } finally {
      dispatch(setLoading(false))
    }
  }, [deleteMemberPopup.actions, dispatch, id, user])

  return (
    <Popup
      {...deleteMemberPopup.state}
      width={442}
      footer={
        <>
          <Button
            type={BUTTON_TYPES.DEFAULT}
            onClick={deleteMemberPopup.actions.close}
            upperCase
          >
            {BTN_TXT.CANCEL}
          </Button>
          <Button
            danger
            type={BUTTON_TYPES.PRIMARY}
            onClick={updateMembersRequestAsync}
            upperCase
          >
            {BTN_TXT.DELETE_USER_FROM_CHAT}
          </Button>
        </>
      }
    >
      <Typography.Body1>
        {name} will lose access to the Chat. Do you really want to delete user
        from the Chat?
      </Typography.Body1>
    </Popup>
  )
}
