import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { moduleName } from './constants'
import { TFormSignInForm } from 'components/Forms/forms.signIn.d'

const initialState: TFormSignInForm = {
  email: '',
  password: ''
}

const slice = createSlice({
  name: moduleName,
  initialState,
  reducers: {
    setLoginData(
      state: TFormSignInForm,
      action: PayloadAction<TFormSignInForm>
    ) {
      const { email, password } = action.payload
      state.email = email
      state.password = password
    },
    resetLoginAction(state: TFormSignInForm) {
      Object.keys(initialState).forEach(
        (key) =>
          ((state[key] as TFormSignInForm[typeof key]) = initialState[key])
      )
    }
  }
})

export default slice.reducer
export const { setLoginData, resetLoginAction } = slice.actions
