import cn from 'classnames'
import { Checkbox, Form } from 'antd'
import { TCheckboxDefault as Props } from './types'
import './checkbox.default.scss'

export const Group = ({
  children,
  className,
  ...props
}: Props): JSX.Element => (
  <Form.Item className={cn('checkbox-default', className)} {...props.propsItem}>
    <Checkbox.Group {...props.propsGroupCheckbox} />
  </Form.Item>
)
