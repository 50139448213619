import { Typography } from 'components/Typography'

const Empty = () => {
  return (
    <div className="chat-history-empty">
      <Typography.Headline6 className="chat-history-empty__text">
        No messages yet
      </Typography.Headline6>
    </div>
  )
}

export default Empty
