import { useCallback, useEffect, useMemo, useState } from 'react'
import { Form } from 'antd'

import { routes } from 'router'
import { VALIDATION_MESSAGES } from 'constants/txt'
import { notification } from 'components/Notification'
import { useVendorDetailsContext } from 'features/VendorDetails/Providers/ContextProvider'
import { IVendorDetailsForm } from 'features/VendorDetails/General/DetailsForm/types'
import {
  convertDetailsToForm,
  convertFormToCreationDetails
} from 'features/VendorDetails/General/DetailsForm/utils'
import useRouter from 'hooks/useRouter'
import { ROLES } from '../../../../Permission'
import { useSelector } from 'react-redux'
import { getUser } from '../../../../../redux/store/user/getters'

const useDetailsForm = () => {
  const router = useRouter()

  const vendorDetailsContext = useVendorDetailsContext()
  const user = useSelector(getUser)

  const { details, isCreation } = vendorDetailsContext.state
  const { createVendorWithAdminAsync } = vendorDetailsContext.actions

  const [form] = Form.useForm<IVendorDetailsForm>()

  const [hasFormChanges, _setHasFormChanges] = useState(false)
  const [isActive, setIsActive] = useState(false)

  const isStakeholder = useMemo(() => {
    return user.role === ROLES.STAKEHOLDER
  }, [user])

  const triggerHasFormChanges = useCallback(() => {
    if (!hasFormChanges) {
      _setHasFormChanges(true)
    }
  }, [hasFormChanges])

  const handleFinishForm = useCallback(
    async (values: IVendorDetailsForm) => {
      const creationDetails = convertFormToCreationDetails(values)
      setIsActive(false)
      const vendorUuid = await createVendorWithAdminAsync(creationDetails)

      if (vendorUuid) {
        if (values.contractAdmin) {
          notification.success({
            message: VALIDATION_MESSAGES.SM0055
          })
        } else {
          notification.success({
            message: VALIDATION_MESSAGES.SM0055_1
          })
        }

        router.push(`${routes.vendors}/${vendorUuid}`)

        _setHasFormChanges(false)
      }
    },
    [createVendorWithAdminAsync, router]
  )

  const handleCancelForm = useCallback(() => {
    form.setFieldsValue(convertDetailsToForm(details, isStakeholder))
    router.push(routes.vendors)
    _setHasFormChanges(false)
  }, [details, form, router, isStakeholder])

  const handleChangeForm = useCallback(() => {
    triggerHasFormChanges()
  }, [triggerHasFormChanges])

  useEffect(() => {
    form.setFieldsValue(convertDetailsToForm(details, isStakeholder))
  }, [details, form, isStakeholder])

  return {
    form,
    isCreation,
    hasFormChanges,
    handleFinishForm,
    handleCancelForm,
    handleChangeForm,
    details,
    isActive,
    setIsActive
  }
}

export default useDetailsForm
