import ProductRequestsContextContextProvider from './ProductRequestsProvider'
import { ProductRequestContainer } from './ProductRequestsContainer'
import PRSPopupProvider from 'features/ProductRequests/Providers/PRSPopupProvider'

export const ProductRequest = () => (
  <ProductRequestsContextContextProvider>
    <PRSPopupProvider>
      <ProductRequestContainer />
    </PRSPopupProvider>
  </ProductRequestsContextContextProvider>
)
