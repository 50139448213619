import { useCallback, useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router'
import { useBlocker } from './useBlocker'
import usePopup from './usePopup'
import { useParams } from 'react-router-dom'

export const useShowPopup = (when: boolean, isCurrentPage?: boolean): any => {
  const navigate = useNavigate()
  const location = useLocation()
  const { id } = useParams()
  const discardLeavePagePopup = usePopup()
  const [lastLocation, setLastLocation] = useState<any>(null)
  const [confirmedNavigation, setConfirmedNavigation] = useState(false)

  const blockNavigationHandler = useCallback(
    (nextLocation) => {
      if (isCurrentPage) {
        const newId = nextLocation.location.pathname.split('/')
        if (!confirmedNavigation && newId[2] !== id) {
          discardLeavePagePopup.actions.open()
        } else {
          setConfirmedNavigation(true)
        }
        setLastLocation(nextLocation)
      } else {
        if (
          !confirmedNavigation &&
          nextLocation.location.pathname !== location.pathname
        ) {
          discardLeavePagePopup.actions.open()
          setLastLocation(nextLocation)
        }
      }
    },
    [lastLocation, confirmedNavigation]
  )

  const confirmNavigationHandler = useCallback(() => {
    discardLeavePagePopup.actions.close()
    setConfirmedNavigation(true)
  }, [confirmedNavigation, discardLeavePagePopup])

  useEffect(() => {
    if (confirmedNavigation && lastLocation) {
      navigate(lastLocation.location.pathname)
      if (isCurrentPage) setConfirmedNavigation(false)
    }
  }, [confirmedNavigation, lastLocation])

  useBlocker(blockNavigationHandler, when)

  return [
    discardLeavePagePopup.state.visible,
    confirmNavigationHandler,
    discardLeavePagePopup.actions.close,
    lastLocation
  ]
}
