import { Form } from 'antd'
import cn from 'classnames'

import { Input } from 'components/Input'
import { Button, BUTTON_TYPES } from 'components/Button'
import { BTN_TXT, LABELS } from 'constants/txt'
import { validateMessages } from 'helper/common'
import './forms.editGroupChat.scss'
import 'components/Checkbox/checkbox.default.scss'
import {
  TEditGroupChatProps,
  TEditGroupChatForm
} from './forms.editGroupChat.d'
import { IChatDetails } from 'redux/store/chatDetails/types'

export const EditGroupChat = <T extends IChatDetails>({
  chat,
  onSubmit,
  onCancel
}: TEditGroupChatProps<T>) => {
  const [form] = Form.useForm()

  const onSubmitForm = (values) => {
    onSubmit({
      ...values
    })
  }

  return (
    <Form<TEditGroupChatForm>
      form={form}
      className={cn('edit-group-chat_form')}
      layout="vertical"
      initialValues={chat}
      onFinish={onSubmitForm}
      validateMessages={validateMessages}
    >
      <Input
        propsItem={{
          label: LABELS.CHAT_NAME,
          name: 'name',
          rules: [
            {
              max: 24,
              required: true
            }
          ]
        }}
        propsInput={{
          showCount: true,
          maxLength: 24
        }}
      />
      <Input.TextArea
        propsItem={{
          label: LABELS.DESCRIPTION,
          name: 'description',
          rules: [
            {
              max: 250
            }
          ]
        }}
        propsTextArea={{
          showCount: true,
          maxLength: 250
        }}
      />
      <div className="edit-group-chat_buttons">
        <Button onClick={onCancel} type={BUTTON_TYPES.DEFAULT} upperCase>
          {BTN_TXT.CANCEL}
        </Button>
        <Button type={BUTTON_TYPES.PRIMARY} htmlType="submit" upperCase>
          {BTN_TXT.SAVE}
        </Button>
      </div>
      <div className="edit-group-chat_separator" />
    </Form>
  )
}
