import cn from 'classnames'
import Typography from '../Typography/Typography'
import { ReactComponent as WarningIcon } from 'assets/svg/WarningIcon.svg'
import { FC } from 'react'
import { BannerProps } from './types'
import './banner.warning.scss'

export const Warning: FC<BannerProps> = ({ text, className }) => (
  <div className={cn('banner banner--warning row gap-12', className)}>
    <WarningIcon />
    <Typography.Body1>{text}</Typography.Body1>
  </div>
)
