import Fetcher from 'services/Fetcher'
import { API_DICTIONARY, HTTP_METHODS } from 'constants/api'
import { ResponseWithPagination } from 'constants/types'
import {
  IRFPVendor,
  RFPVendorValue,
  TRFPSubmitSurveyVotingRequestData
} from 'features/RFP/RFPSurvey/types'

const fetcher = new Fetcher({})

export const getRfpVendorListRequestAsync = async (id: string) =>
  fetcher.request<undefined, ResponseWithPagination<IRFPVendor>>({
    url: `${API_DICTIONARY.RFP}${id}/${API_DICTIONARY.ACCEPTED_VENDORS}`,
    method: HTTP_METHODS.GET
  })

export const getSurveyRequestAsync = async (id: string) =>
  fetcher.request<undefined, ResponseWithPagination<IRFPVendor>>({
    url: `${API_DICTIONARY.RFP}${id}/${API_DICTIONARY.SURVEY}`,
    method: HTTP_METHODS.GET
  })

export const createOrUpdateSurveyRequestAsync = async (
  id: string,
  data: { vendors: RFPVendorValue[] }
) =>
  fetcher.request<{ vendors: RFPVendorValue[] }, { status: string }>({
    url: `${API_DICTIONARY.RFP}${id}/${API_DICTIONARY.SURVEY}`,
    method: HTTP_METHODS.POST,
    data
  })

export const sendSurveyRequestAsync = async (id: string) =>
  fetcher.request<unknown, ResponseWithPagination<IRFPVendor>>({
    url: `${API_DICTIONARY.RFP}${id}/${API_DICTIONARY.SURVEY}send/`,
    method: HTTP_METHODS.PATCH
  })

export const getAgregatedSurveyResults = async (rfpID: string) =>
  fetcher.request<any, any>({
    url: `${API_DICTIONARY.RFP}${rfpID}/${API_DICTIONARY.SURVEY}total_vote_result/`,
    method: HTTP_METHODS.GET
  })

export const getIndividualSurveyResults = async (rfpID: string, shID: string) =>
  fetcher.request<any, any>({
    url: `${API_DICTIONARY.RFP}${rfpID}/${API_DICTIONARY.SURVEY}total_vote_result/${shID}/`,
    method: HTTP_METHODS.GET
  })

export const getVotedStakeholdersList = async (rfpID: string) =>
  fetcher.request<any, any>({
    url: `${API_DICTIONARY.RFP}${rfpID}/${API_DICTIONARY.SURVEY}voted_stakeholder/`,
    method: HTTP_METHODS.GET
  })

export const submitSurveyVotingRequestAsync = async (
  id: string,
  data: TRFPSubmitSurveyVotingRequestData
) =>
  fetcher.request<
    { vote: TRFPSubmitSurveyVotingRequestData },
    ResponseWithPagination<IRFPVendor>
  >({
    url: `${API_DICTIONARY.RFP}${id}/${API_DICTIONARY.SURVEY}stakeholder_vote/`,
    method: HTTP_METHODS.POST,
    data: { vote: data }
  })
