import { Popup } from 'components/Popup'
import AssignResponsible from 'features/ContractDetails/ContractDetailsGeneral/ContractDetailsForm/AssignResponsible/AssignResponsible'
import { useContractDetailsPopup } from 'features/ContractDetails/Providers/ContractDetailsPopupProvider'

export default () => {
  const { assignResponsiblePopup } = useContractDetailsPopup()

  return (
    <Popup
      {...assignResponsiblePopup.state}
      className="assign-responsible-popup"
      width={442}
      footer={null}
    >
      <AssignResponsible />
    </Popup>
  )
}
