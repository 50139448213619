import { createSlice, PayloadAction } from '@reduxjs/toolkit'

import { moduleName } from './constants'
import { ChatMember, IChatDetails, IChatDetailsState } from './types'

const initialState: IChatDetailsState = {
  details: null,
  members: [],
  isLoading: false,
  isLoaded: false,
  error: null
}

const slice = createSlice({
  name: moduleName,
  initialState,
  reducers: {
    loading(state: IChatDetailsState) {
      state.isLoading = true
      state.error = null
    },
    finish(state: IChatDetailsState) {
      state.isLoading = false
    },
    error(
      state: IChatDetailsState,
      action: PayloadAction<{ error: IChatDetailsState['error'] }>
    ) {
      const { error } = action.payload
      state.error = error
    },
    setDetails(state: IChatDetailsState, action: PayloadAction<IChatDetails>) {
      state.details = action.payload
    },
    updateDetails(
      state: IChatDetailsState,
      action: PayloadAction<IChatDetails>
    ) {
      state.details = {
        ...state.details,
        ...action.payload
      }
    },
    setMembers(state: IChatDetailsState, action: PayloadAction<ChatMember[]>) {
      state.members = action.payload
    },
    updateMembers(
      state: IChatDetailsState,
      action: PayloadAction<ChatMember[]>
    ) {
      state.members = [...state.members, ...action.payload]
    },
    reset(state: IChatDetailsState) {
      Object.keys(initialState).forEach((key) => {
        state[key as string] = initialState[key]
      })
    },
    updateUnreadMessages(state: IChatDetailsState) {
      if (state.details?.uuid) {
        state.details['unread_messages_count'] =
          (state.details.unread_messages_count || 0) + 1
      }
    },
    resetUnreadMessages(state: IChatDetailsState) {
      if (state.details?.uuid) {
        state.details['unread_messages_count'] = 0
      }
    },
    resetChatDetails(state: IChatDetailsState) {
      state.details = null
    },
    resetChatMembers(state: IChatDetailsState) {
      state.members = []
    }
  }
})

export default slice.reducer
export const {
  loading,
  finish,
  error,
  setDetails,
  updateDetails,
  setMembers,
  updateMembers,
  reset,
  updateUnreadMessages,
  resetUnreadMessages,
  resetChatDetails,
  resetChatMembers
} = slice.actions
