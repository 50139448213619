import { createContext, FC, PropsWithChildren, useMemo } from 'react'
import { useSelector } from 'react-redux'
import { createContextualCan, useAbility } from '@casl/react'

import { getUser } from 'redux/store/user/getters'
import {
  ability,
  defineAbility,
  ChatListItemAbility
} from 'features/ChatList/Abilities'

export const ChatListAbilityContext =
  createContext<ChatListItemAbility>(ability)

const ChatListAbilityProvider: FC<PropsWithChildren> = ({ children }) => {
  const user = useSelector(getUser)

  const ability = useMemo(() => defineAbility(user), [user])

  return (
    <ChatListAbilityContext.Provider value={ability}>
      {children}
    </ChatListAbilityContext.Provider>
  )
}

export const useChatListAbility = () =>
  useAbility<ChatListItemAbility>(ChatListAbilityContext)

export const Can = createContextualCan<any>(ChatListAbilityContext.Consumer)

export default ChatListAbilityProvider
