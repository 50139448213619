import { useCallback, useMemo } from 'react'

import './styles.scss'

import { BTN_TXT } from 'constants/txt'
import { Popup } from 'components/Popup'
import { Typography } from 'components/Typography'
import { Button, BUTTON_TYPES } from 'components/Button'
import { useContractDetailsPopup } from 'features/ContractDetails/Providers/ContractDetailsPopupProvider'
import { useContractDetailsContext } from 'features/ContractDetails/Providers/ContractDetailsContextProvider'
import { defaultDateFormat } from 'helper/dates'

export default () => {
  const { renewFinishDatePopup } = useContractDetailsPopup()
  const contractDetailsContext = useContractDetailsContext()

  const { payload } = renewFinishDatePopup.state

  const { details } = contractDetailsContext.state
  const { extendContractDetailsAsync } = contractDetailsContext.actions

  const formatterFinishDate = defaultDateFormat(details?.finish_date)
  const formatterNewFinishDate = payload?.newFinishDate?.format('MM/DD/YYYY')

  const handleClickRenewFinishDate = useCallback(async () => {
    if (payload?.newFinishDate && payload?.extensionDocument) {
      await extendContractDetailsAsync(payload)
    }
  }, [extendContractDetailsAsync, payload])

  const Footer = useMemo(
    () => (
      <>
        <Button
          type={BUTTON_TYPES.DEFAULT}
          onClick={renewFinishDatePopup.actions.close}
          upperCase
        >
          {BTN_TXT.BACK_TO_RENEWAL_FORM}
        </Button>
        <Button
          type={BUTTON_TYPES.PRIMARY}
          onClick={handleClickRenewFinishDate}
          upperCase
        >
          {BTN_TXT.RENEW_DATE}
        </Button>
      </>
    ),
    [handleClickRenewFinishDate, renewFinishDatePopup.actions.close]
  )

  return (
    <Popup {...renewFinishDatePopup.state} width={442} footer={Footer}>
      <Typography.Body1>
        {`New finish date ${formatterNewFinishDate} overlaps the previous ${formatterFinishDate}.
         Do you really want to update finish date?`}
      </Typography.Body1>
    </Popup>
  )
}
