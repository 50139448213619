import { CheckInsContainer } from 'features/CheckIns/CheckInsContainer'
import CheckInsContextProvider from 'features/CheckIns/Providers/CheckInsContextProvider'
import CheckInsPopupProvider from 'features/CheckIns/Providers/CheckInsPopupProvider'

export const CheckIn = () => {
  return (
    <CheckInsContextProvider>
      <CheckInsPopupProvider>
        <CheckInsContainer />
      </CheckInsPopupProvider>
    </CheckInsContextProvider>
  )
}
