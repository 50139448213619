import ContractPipelineDetailsAbilityProvider from './Providers/ContractPipelineDetailsAbilityProvider'
import ContractPipelineDetailsContainer from './ContractPipelineDetailsContainer/ContractPipelineDetailsContainer'
import ContractPipelineDetailsRouter from './ContractPipelineDetailsRouter/ContractPipelineDetailsRouter'
import CPRDetailsContextProvider from './Providers/CPRDetailsContextProvider'
import ContractDetailsPagePopupProvider from './Providers/ContractDetailsPagePopupProvider'
import { DeleteModal } from './DeleteContainer'

const ContractPipelineDetails = () => {
  return (
    <ContractPipelineDetailsAbilityProvider>
      <CPRDetailsContextProvider>
        <ContractDetailsPagePopupProvider>
          <ContractPipelineDetailsContainer>
            <ContractPipelineDetailsRouter />
          </ContractPipelineDetailsContainer>
          <DeleteModal />
        </ContractDetailsPagePopupProvider>
      </CPRDetailsContextProvider>
    </ContractPipelineDetailsAbilityProvider>
  )
}

export default ContractPipelineDetails
