import { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { Popup } from '../../../components/Popup'
import { HistoryCard } from './HistoryCard/HistoryCard'
import { getInvoiceHistoryData } from './api'
import { setLoading } from '../../../redux/store/common/slice'
import { HistoryModalProps, InvoiceHistoryData } from './types'

export const HistoryModal = ({
  documentKey,
  isOpen,
  onClose
}: HistoryModalProps) => {
  const [historyData, setHistoryData] = useState<InvoiceHistoryData[]>([])
  const dispatch = useDispatch()

  useEffect(() => {
    if (isOpen) {
      onGetInvoiceData()
    }
  }, [isOpen])

  const onGetInvoiceData = async () => {
    try {
      dispatch(setLoading(true))
      const { data } = await getInvoiceHistoryData(documentKey)
      setHistoryData(data.results)
    } finally {
      dispatch(setLoading(false))
    }
  }

  return (
    <Popup
      title="History"
      onCancel={onClose}
      visible={isOpen}
      width={528}
      zIndex={1000}
      footer={null}
    >
      {historyData.map((data, idx) => (
        <HistoryCard key={idx} data={data} />
      ))}
    </Popup>
  )
}
