import { useMemo } from 'react'
import './styles.scss'
import { BTN_TXT } from 'constants/txt'
import { Popup } from 'components/Popup'
import { Button, BUTTON_TYPES } from 'components/Button'
import { useContractDetailsContext } from 'features/ContractDetails/Providers/ContractDetailsContextProvider'
import { useContractDetailsPopup } from 'features/ContractDetails/Providers/ContractDetailsPopupProvider'
import ContractDetailsTimelines from 'features/ContractDetails/ContractDetailsTimelines/ContractDetailsTimelines'
import { timelinesResponseDataConverter } from 'features/ContractDetails/ContractDetailsTimelines/utils'

const ContractDetailsTimelinesPopup = () => {
  const contractDetailsContext = useContractDetailsContext()
  const { contractStepsTimePopup } = useContractDetailsPopup()

  const { details } = contractDetailsContext.state
  const { setupContractDetailsTimeAsync } = contractDetailsContext.actions

  const timelines = useMemo(
    () => timelinesResponseDataConverter(details.timelines),
    [details.timelines]
  )

  return (
    <Popup
      {...contractStepsTimePopup.state}
      className="contract-details-timeline-popup"
      width={676}
      footer={
        <>
          <Button
            type={BUTTON_TYPES.DEFAULT}
            onClick={contractStepsTimePopup.actions.close}
            upperCase
          >
            {BTN_TXT.CANCEL}
          </Button>
          <Button
            type={BUTTON_TYPES.PRIMARY}
            htmlType="submit"
            form="contract-timeline-form-id"
            upperCase
          >
            {BTN_TXT.SAVE}
          </Button>
        </>
      }
    >
      <ContractDetailsTimelines
        onFinish={setupContractDetailsTimeAsync}
        disabledBeforeKey={details.status}
        timelines={timelines}
      />
    </Popup>
  )
}

export default ContractDetailsTimelinesPopup
