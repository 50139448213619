import { Radio, RadioChangeEvent } from 'antd'
import cn from 'classnames'

import { Props as BaseProps } from 'components/RadioGroup/types'

import './radioGroup.buttons.scss'

type Props = {
  range?: number
  options: { label: string; value: string }[]
  onChange: (e: string) => void
  defaultValue: string
} & Omit<BaseProps, 'options'>

const Buttons = ({ className, options, defaultValue, onChange }: Props) => {
  const handleChange = (event: RadioChangeEvent) => {
    onChange(event.target.value)
  }

  return (
    <div className={cn('radio-group-buttons-wrapper', className)}>
      <Radio.Group onChange={handleChange} value={defaultValue}>
        {options.map((i) => (
          <Radio.Button value={i.value} key={i.value}>
            {i.label}
          </Radio.Button>
        ))}
      </Radio.Group>
    </div>
  )
}

export default Buttons
