import { FC } from 'react'
import cn from 'classnames'
import { Input } from '../../../Input'
import { TextCellProps } from './types'
import './styles.scss'

export const TextCell: FC<TextCellProps> = ({ error, value, onChange }) =>
  error ? (
    <Input
      propsInput={{
        className: cn('table-text-cell', {
          'table-text-cell--with-error': error
        }),
        onChange: (e) => onChange(e.target.value),
        value
      }}
    />
  ) : (
    <>{value || '-'}</>
  )
