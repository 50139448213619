import Default from './popup.default'
import Terms from './popup.terms'
import { Discard } from './popup.discard'
import { ConfirmChanges } from './popup.confirmChanges'
import { ModalProps } from './types'

const Popup = (props: ModalProps) => <Default {...props} />

Popup.Default = Default
Popup.Discard = Discard
Popup.Terms = Terms
Popup.ConfirmChanges = ConfirmChanges

export default Popup
