import { useMemo } from 'react'
import { Form } from 'antd'
import { useSelector } from 'react-redux'
import { BTN_TXT } from 'constants/txt'
import { Button, BUTTON_TYPES } from 'components/Button'
import { Popup } from 'components/Popup'
import VendorForm from '../../General/VendorForm/VendorForm'
import ContractAdminForm from '../../General/ContractAdminForm/ContractAdminForm'
import useDetailsForm from '../../General/DetailsForm/hooks/useDetailsForm'
import { useVendorDetailsContext } from '../../Providers/ContextProvider'
import { validateMessages } from '../../constants'
import { useShowPopup } from 'hooks/useShowPopup'
import { routes } from 'router'
import useRouter from 'hooks/useRouter'
import { getUser } from 'redux/store/user/getters'
import { ROLES } from 'features/Permission'
import { MY_COMMUNITY_TABS } from '../../../../constants'
import { VendorDetailsForm } from './types'
import './styles.scss'

const DetailsForm = () => {
  const vendorDetailsContext = useVendorDetailsContext()
  const detailsForm = useDetailsForm()
  const router = useRouter()
  const user = useSelector(getUser)

  const {
    form,
    isCreation,
    hasFormChanges,
    handleFinishForm,
    handleChangeForm,
    isActive,
    setIsActive
  } = detailsForm

  const [showPopup, confirmNavigation, cancelNavigation] =
    useShowPopup(isActive)

  const pushToPrevPage = () =>
    router.push(routes.myCommunity, {
      state: {
        redirectTab: MY_COMMUNITY_TABS[1].key
      }
    })
  const phoneNumber = Form.useWatch(['vendor', 'phone_number'], form)
  const isStakeholder = useMemo(() => {
    return user.role === ROLES.STAKEHOLDER
  }, [user])

  const FormActions = useMemo(
    () => (
      <div className="vendor-details-form__actions">
        <Button onClick={pushToPrevPage} upperCase>
          {BTN_TXT.CANCEL}
        </Button>
        <Button
          type={BUTTON_TYPES.PRIMARY}
          form="vendor-details-form"
          htmlType="submit"
          upperCase
        >
          {BTN_TXT.SAVE}
        </Button>
      </div>
    ),
    []
  )

  return (
    <>
      <Form<VendorDetailsForm>
        id="vendor-details-form"
        form={form}
        onChange={handleChangeForm}
        onFinish={handleFinishForm}
        validateMessages={validateMessages}
        initialValues={{
          vendor: { vendor_permission: isStakeholder ? 'guest' : 'full_access' }
        }}
        onFieldsChange={() => setIsActive(true)}
        className="vendor-details__form"
      >
        <VendorForm
          phoneNumber={phoneNumber}
          form={form}
          isStakeholder={isStakeholder}
        />
        {vendorDetailsContext.state.isCreation && (
          <ContractAdminForm {...detailsForm} />
        )}
        {(hasFormChanges || isCreation) && FormActions}
      </Form>
      <Popup.Discard
        visible={isActive && showPopup}
        onCancel={cancelNavigation}
        onSubmit={confirmNavigation}
      />
    </>
  )
}

export default DetailsForm
