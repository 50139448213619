import { BTN_TXT } from 'constants/txt'
import { Button, BUTTON_TYPES } from 'components/Button'
import { useContractDetailsContext } from 'features/ContractDetails/Providers/ContractDetailsContextProvider'
import {
  ACTIONS,
  contractSubject,
  SUBJECTS
} from 'features/ContractDetails/Abilities'
import { CONTRACT_STATUS } from 'features/ContractDetails/constants'
import { Can } from 'features/ContractDetails/Providers/ContractDetailsAbilityProvider'

const StartSignProcessAction = () => {
  const contractDetailsContext = useContractDetailsContext()

  const { details } = contractDetailsContext.state
  const { updateContractDetailsStatusAsync } = contractDetailsContext.actions

  const handleClickStartSigningProcess = () => {
    updateContractDetailsStatusAsync(CONTRACT_STATUS.OUT_FOR_SIGNATURE)
  }

  return (
    <Can
      I={ACTIONS.SUBMIT}
      a={contractSubject(SUBJECTS.START_SIGNING_PROCESS, details)}
    >
      <Button
        type={BUTTON_TYPES.PRIMARY}
        onClick={handleClickStartSigningProcess}
        upperCase
      >
        {BTN_TXT.START_SIGN_PROCESS}
      </Button>
    </Can>
  )
}

export default StartSignProcessAction
