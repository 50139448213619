import { Modal } from 'antd'
import cn from 'classnames'
import { Button, BUTTON_TYPES } from '../Button'
import { Typography } from '../Typography'
import { BTN_TXT } from '../../constants'
import { ReactComponent as Close24 } from '../../assets/svg/Close24.svg'
import { ModalProps } from './types'
import './popup.default.scss'

export const ConfirmChanges = ({
  closable = true,
  onCancel,
  onSubmit,
  children,
  ...props
}: ModalProps) => (
  <Modal
    centered
    closeIcon={<Close24 />}
    closable={closable}
    title="Confirm Changes"
    getContainer="#root"
    onCancel={onCancel}
    width={442}
    destroyOnClose
    className={cn('popup-default', {
      'popup-default--closable': closable
    })}
    wrapClassName={cn('popup-default-wrap', 'popup-over')}
    footer={
      <>
        <Button type={BUTTON_TYPES.DEFAULT} upperCase onClick={onCancel}>
          {BTN_TXT.BACK_TO_EDIT}
        </Button>
        <Button type={BUTTON_TYPES.PRIMARY} upperCase onClick={onSubmit}>
          {BTN_TXT.SAVE_CHANGES}
        </Button>
      </>
    }
    {...props}
  >
    {children ? (
      children
    ) : (
      <Typography.Body1>
        Make sure everything is correct before proceeding. Once submitted, these
        changes will be applied.
      </Typography.Body1>
    )}
  </Modal>
)
