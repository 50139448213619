import { BTN_TXT } from 'constants/txt'
import { Popup } from 'components/Popup'
import { Button, BUTTON_TYPES } from 'components/Button'
import CreateSurveyTabs from 'features/RFP/RFPSurvey/CreateSurvey/CreateSurveyTabs'
import { useRFPSurveyContext } from 'features/RFP/RFPDetails/Providers/RFPSurveyContextProvider'
import { useRFPCreateSurveyContext } from 'features/RFP/RFPSurvey/CreateSurvey/Providers/RFPCreateSurveyContextProvider'
import { useRFPDetailsPopupContext } from 'features/RFP/RFPDetails/Providers/RFPDetailsPopupProvider'
import { useLayoutEffect } from 'react'
import './styles.scss'

const CreateSurveyPopup = () => {
  const { createSurveyPopup } = useRFPDetailsPopupContext()
  const rfpSurveyContext = useRFPSurveyContext()
  const rfpCreateSurveyContext = useRFPCreateSurveyContext()

  const { isSurveyCreated } = rfpSurveyContext.state
  const { canCreateOrEditSurvey, localSelectedVendors } =
    rfpCreateSurveyContext.state

  const { handleCreateSurvey, handelCancelSurveyCreation } =
    rfpCreateSurveyContext.actions

  useLayoutEffect(() => {
    createSurveyPopup.actions.setState((prevState) => ({
      ...prevState,
      title: isSurveyCreated ? 'Edit survey' : 'Create survey'
    }))
  }, [createSurveyPopup.actions, isSurveyCreated])

  return (
    <Popup
      className="rfp-create-survey-popup"
      {...createSurveyPopup.state}
      onCancel={handelCancelSurveyCreation}
      width={910}
      footer={
        canCreateOrEditSurvey ? (
          <>
            <Button
              type={BUTTON_TYPES.DEFAULT}
              onClick={handelCancelSurveyCreation}
              upperCase
            >
              {BTN_TXT.CANCEL}
            </Button>
            <Button
              onClick={handleCreateSurvey}
              type={BUTTON_TYPES.PRIMARY}
              htmlType="submit"
              form="rfp-timeline-form-id"
              upperCase
              disabled={!localSelectedVendors.length}
            >
              {BTN_TXT.SAVE}
            </Button>
          </>
        ) : null
      }
    >
      <CreateSurveyTabs />
    </Popup>
  )
}

export default CreateSurveyPopup
