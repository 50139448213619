import { Select } from 'components/Select'
import useGoogle from 'react-google-autocomplete/lib/usePlacesAutocompleteService'
import { useEffect, useMemo, useState } from 'react'
import { ReactComponent as Search16 } from 'assets/svg/Search16.svg'
import { LABELS, PLACEHOLDERS } from '../../../constants'

export function LocationSelect({ required }: { required?: boolean }) {
  const { placePredictions, getPlacePredictions } = useGoogle({
    apiKey:
      process.env.REACT_APP_GOOGLE ||
      'random_key_to_prevent_error_if_nothing_set_in_env'
  })

  const [searchValue, setSearchValue] = useState<string>('')

  const options = useMemo(() => {
    return searchValue
      ? placePredictions.map((prediction) => {
          return {
            value: prediction.description,
            label: prediction.description
          }
        })
      : []
  }, [placePredictions, searchValue])

  useEffect(() => {
    if (searchValue) {
      getPlacePredictions({ input: searchValue })
    }
  }, [searchValue])

  return (
    <div className="add-meeting-popup_location-select">
      <Search16 className="add-meeting-popup_location-select_search-icon" />
      <Select.Autocomplete
        propsItem={{
          label: LABELS.LOCATION,
          name: 'location',
          rules: [
            {
              required
            }
          ]
        }}
        propsSelect={{
          searchValue,
          onSearch: setSearchValue,
          filterOption: false,
          placeholder: PLACEHOLDERS.SEARCH_LOCATION
        }}
        alwaysName
        options={options}
      />
    </div>
  )
}
