import cn from 'classnames'
import { Form, InputNumber } from 'antd'
import { Props } from './types'
import './input.default.scss'
import { KeyboardEvent } from 'react'

export const PhoneInput = ({ ...props }: Props): JSX.Element => {
  const onKeyDown = (e: KeyboardEvent<HTMLInputElement>) => {
    if (!/^(?:\d|Backspace|[.])$/.test(e.key)) {
      e.preventDefault()
    }
  }
  return (
    <Form.Item
      className={cn('custom-input', 'input-default', props.className)}
      colon={false}
      {...props.propsItem}
    >
      <InputNumber
        // maxLength={10}
        maxLength={14}
        minLength={14}
        value={props?.propsInputNumber?.value || ''}
        {...props.propsInputNumber}
        onKeyDown={onKeyDown}
      />
    </Form.Item>
  )
}
