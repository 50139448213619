export const ACTIONS = {
  VIEW: 'VIEW',
  CREATE: 'CREATE'
}

export const SUBJECTS = {
  CHANNEL_CHATS: 'CHANNEL_CHATS',
  GROUP_CHATS: 'GROUP_CHATS',
  PRIVATE_CHATS: 'PRIVATE_CHATS'
}
