import { Popup } from '../../../components/Popup'
import { Button, BUTTON_TYPES } from '../../../components/Button'
import { BTN_TXT } from '../../../constants'
import { Typography } from '../../../components/Typography'
import { useContractDetailsPagePopup } from '../Providers/ContractDetailsPagePopupProvider'
import { TDeleteModalProps } from './types'
import { useCPRDetailsContext } from '../Providers/CPRDetailsContextProvider'

const DeleteModal = ({
  hsName,
  contractCategoryName,
  uuid,
  resetSelected,
  successCallback
}: TDeleteModalProps) => {
  const { deleteHSPopup } = useContractDetailsPagePopup()
  const { actions } = useCPRDetailsContext()
  const handleCloseDeletePopup = () => {
    resetSelected()
    deleteHSPopup.actions.close()
  }
  const handleSubmit = () => {
    actions
      .deleteHealthSystem(uuid, successCallback)
      .then(() => deleteHSPopup.actions.close())
  }
  return (
    <Popup
      onCancel={handleCloseDeletePopup}
      width={442}
      visible={deleteHSPopup.state.visible}
      title={deleteHSPopup.state.title}
      footer={
        <>
          <Button
            type={BUTTON_TYPES.DEFAULT}
            onClick={handleCloseDeletePopup}
            upperCase
          >
            {BTN_TXT.CANCEL}
          </Button>
          <Button
            type={BUTTON_TYPES.PRIMARY}
            onClick={handleSubmit}
            upperCase
            danger
          >
            {BTN_TXT.DELETE}
          </Button>
        </>
      }
    >
      <Typography.Body1>
        {hsName} will be removed from the {contractCategoryName} pipeline list.
        Do you want to proceed with deleting the health system?
      </Typography.Body1>
    </Popup>
  )
}

export default DeleteModal
