import Typography from '../../../components/Typography/Typography'
import './style.scss'
import { TYPOGRAPHY_WEIGHT } from '../../../components/Typography'
import { TSavingsComponentProps } from './types'
import { currencyFormatter } from '../../ContractDetails/ContractDetailsGeneral/ContractDetailsForm/utils'

export const TotalSavings = ({
  savings,
  isTotalHidden,
  isGoalsHidden
}: TSavingsComponentProps) => {
  return (
    <div className="total-saving-wrapper">
      <div className="total-saving-wrapper__item row">
        <Typography.Body1 weight={TYPOGRAPHY_WEIGHT.BOLD}>
          Opportunity analysis savings
        </Typography.Body1>
        <Typography.Body1 className="amount" weight={TYPOGRAPHY_WEIGHT.BOLD}>
          {!!savings.opportunity_analysis_savings
            ? '$' + currencyFormatter(savings.opportunity_analysis_savings)
            : '-'}
        </Typography.Body1>
      </div>
      {!isTotalHidden && (
        <div className="total-saving-wrapper__item row">
          <Typography.Body1 weight={TYPOGRAPHY_WEIGHT.BOLD}>
            Total savings
          </Typography.Body1>
          <Typography.Body1 className="amount" weight={TYPOGRAPHY_WEIGHT.BOLD}>
            {!!savings.total_savings
              ? '$' + currencyFormatter(savings.total_savings)
              : '-'}
          </Typography.Body1>
        </div>
      )}
      {!isGoalsHidden && (
        <div className="total-saving-wrapper__item row">
          <Typography.Body1 weight={TYPOGRAPHY_WEIGHT.BOLD}>
            Savings goals
          </Typography.Body1>
          <Typography.Body1 className="amount" weight={TYPOGRAPHY_WEIGHT.BOLD}>
            {!!savings.saving_goals
              ? '$' + currencyFormatter(savings.saving_goals)
              : '-'}
          </Typography.Body1>
        </div>
      )}
    </div>
  )
}
