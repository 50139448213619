import { TUpdateOrAddDecisionProps } from './types'

export const updateOrAddDecision = ({
  data,
  uuid,
  decision
}: TUpdateOrAddDecisionProps) => {
  const resultedData = [...data]
  // Find the index of the object with the given uuid
  const index = resultedData.findIndex((item) => item.uuid === uuid)

  if (index !== -1) {
    // If the uuid exists, update the decision
    resultedData[index].decision = decision
  } else {
    // If the uuid does not exist, add a new object
    resultedData.push({ uuid, decision })
  }
  return resultedData
}
