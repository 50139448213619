import {
  createContext,
  FC,
  PropsWithChildren,
  useCallback,
  useContext,
  useEffect,
  useLayoutEffect,
  useMemo,
  useState
} from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'

import { getUser } from 'redux/store/user/getters'

import useTabs, { TabsReturnActions, TabsReturnState } from 'hooks/useTabs'
import useRouter from '../../../hooks/useRouter'
import { contractPageRoute } from '../constants'
import { TEvaluateModalData } from '../../../features/CognusRepository/AvailableForEvaluation/types'
import { setOpportunityAnalysisList } from '../../../features/Contracts/api'
import { setLoading } from '../../../redux/store/common/slice'
import {
  CONTRACT_PAGE_TABS_COMMONS,
  CONTRACT_PAGE_TABS_COMMUNITY
} from '../constants'
import { canSeeMyContracts } from 'helper/common'
import { ability, ACTIONS } from '../../../features/Permission'

type ContextProps = {
  state: {
    isVendor: boolean
    isCommunity?: boolean
    isCanSeeMyContracts: boolean
    isCommons?: boolean
    evaluateData: TEvaluateModalData
  } & TabsReturnState
  actions: TabsReturnActions & {
    setEvaluateData: (data: TEvaluateModalData) => void
    handleEvaluation: (callback: () => void) => void
  }
}

const ContractPageContext = createContext<ContextProps>({
  state: null!,
  actions: null!
})

const createRoute = 'create'

const prepareRouteId = (routeId?: string) =>
  routeId ? (routeId === createRoute ? null : routeId) : null

const ContractPageContextProvider: FC<PropsWithChildren> = ({ children }) => {
  const { id: routeId, tab } = useParams()
  const user = useSelector(getUser)
  const [evaluateData, setEvaluateData] = useState<TEvaluateModalData>([])
  const { query } = useRouter()
  const dispatch = useDispatch()

  const [_id, setId] = useState<string | null>(prepareRouteId(routeId))

  const availableTabs = useMemo(() => {
    return user?.commons
      ? CONTRACT_PAGE_TABS_COMMONS
      : CONTRACT_PAGE_TABS_COMMUNITY
  }, [user])

  const { state: tabsState, actions: tabsActions } = useTabs({
    tabs: availableTabs,
    activeTab: tab || availableTabs[0].key
  })

  const isVendor = useMemo(() => Boolean(user.vendor), [user.vendor])

  const isCommunity = useMemo(() => Boolean(user.community), [user.community])
  const isCommons = useMemo(() => Boolean(user.commons), [user.commons])

  const isCanSeeMyContracts = useMemo(
    () => canSeeMyContracts(user.role),
    [user.role]
  )

  const _adjustTabsAccessibility = useCallback(() => {
    tabsActions.setTabs(
      availableTabs.map((i) => {
        const isPipelineDisable =
          i.key === contractPageRoute.contractPipeline &&
          ability.cannot(ACTIONS.VIEW, i.label)
        return {
          ...i,
          danger: isPipelineDisable
        }
      })
    )
  }, [tabsActions, query])

  const handleEvaluation = (callback) => {
    dispatch(setLoading(true))
    setOpportunityAnalysisList({ contract_groups: evaluateData })
      .then(() => {
        callback()
      })
      .finally(() => dispatch(setLoading(false)))
  }

  useLayoutEffect(() => {
    _adjustTabsAccessibility()
  }, [_adjustTabsAccessibility])

  useEffect(() => {
    setId(prepareRouteId(routeId))
  }, [routeId])

  const context = useMemo(
    () => ({
      state: {
        isVendor,
        ...tabsState,
        isCommunity,
        isCommons,
        isCanSeeMyContracts,
        evaluateData
      },
      actions: {
        ...tabsActions,
        setEvaluateData,
        handleEvaluation
      }
    }),
    [
      isCommunity,
      isVendor,
      tabsState,
      tabsActions,
      isCommons,
      isCanSeeMyContracts,
      evaluateData,
      setEvaluateData,
      handleEvaluation
    ]
  )

  return (
    <ContractPageContext.Provider value={context}>
      {children}
    </ContractPageContext.Provider>
  )
}

export const useContractPageContext = () => useContext(ContractPageContext)

export default ContractPageContextProvider
