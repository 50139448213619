import { MY_COMMUNITY_TABS } from '../constants'

export const BREADCRUMBS = (title: string) => [
  {
    children: 'My Community',
    href: '/my-community',
    state: { redirectTab: MY_COMMUNITY_TABS[1].key }
  },
  {
    children: title
  }
]
