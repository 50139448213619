import cn from 'classnames'
import './styles.scss'
import { TAttachmentsListProps } from '../types'
import { FieldWithLabel } from 'components/FieldWithLabel/FieldWithLabel'
import { ReactComponent as DeleteIcon } from 'assets/svg/DeleteBtn24.svg'
import { ReactComponent as CloseIcon } from 'assets/svg/Close24.svg'
import { useState } from 'react'
import { Modal } from 'antd'
const WithImages = (props: TAttachmentsListProps) => {
  const { className, files, label, onDelete, disableActions } = props
  const [previewOpen, setPreviewOpen] = useState(false)
  const [previewImage, setPreviewImage] = useState('')
  const deleteHandler = (uuid: string) => {
    if (onDelete) {
      onDelete(uuid)
    }
  }
  const handleCancel = () => setPreviewOpen(false)
  const handlePreview = async (file) => {
    setPreviewImage(file.file || (file.preview as string))
    setPreviewOpen(true)
  }

  return (
    <div className={cn('attachments-list', className)}>
      <FieldWithLabel title={label}>
        <div className="attachments-list__files images">
          {files.map((i) => (
            <div
              key={i.uuid}
              className="row attachments-list__files__container"
            >
              <img
                width={60}
                height={60}
                src={i.file}
                onClick={() => handlePreview(i)}
              />
              {!disableActions && (
                <div
                  className="attachments-list__files__delete-btn"
                  onClick={() => deleteHandler(i.uuid)}
                >
                  <DeleteIcon />
                </div>
              )}
            </div>
          ))}
        </div>
      </FieldWithLabel>
      <Modal
        className="preview-image-modal"
        visible={previewOpen}
        footer={null}
        onCancel={handleCancel}
        closeIcon={<CloseIcon />}
      >
        <img alt="example" style={{ width: '100%' }} src={previewImage} />
      </Modal>
    </div>
  )
}

export default WithImages
