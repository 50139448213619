import { ReactElement, useMemo, useState } from 'react'
import SignPopup from 'features/SignPopup/SignPopup'
import Typography from '../../components/Typography/Typography'
import { TYPOGRAPHY_WEIGHT } from '../../components/Typography'
import { Button, BUTTON_TYPES } from '../../components/Button'
import { BTN_TXT } from '../../constants'
import Countdown from 'antd/lib/statistic/Countdown'
import { getLocalEmail } from '../../helper/common'
import { setLoading } from '../../redux/store/common/slice'
import { useDispatch } from 'react-redux'
import './style.scss'
import { resendVerifyEmail } from '../../features/SignPopup/api'

const VerifyEmail = (): ReactElement => {
  const dispatch = useDispatch()
  const [email, _setEmail] = useState('')
  const [isResend, setIsResend] = useState(true)

  const countValue = useMemo(() => {
    if (!isResend) {
      return Date.now() + 61 * 1000
    }
  }, [isResend])

  const handleResend = () => {
    const email = getLocalEmail()
    if (email) {
      dispatch(setLoading(true))
      resendVerifyEmail({ email: email }).finally(() => {
        dispatch(setLoading(false))
        setIsResend(false)
      })
    }
  }

  const handleFinishCountDown = () => {
    setIsResend(true)
  }

  return (
    <SignPopup isBack email={email}>
      <Typography.HeadlineD4 className="mt-32" weight={TYPOGRAPHY_WEIGHT.BOLD}>
        Please verify your
        <br /> email address
      </Typography.HeadlineD4>
      <Typography.Body1 className="mt-12">
        To confirm your email address, tap the button in the email we sent to{' '}
        {email}.
      </Typography.Body1>
      <div className="verify-email-footer row align-center mt-32 gap-12">
        <Button
          type={BUTTON_TYPES.GHOST}
          disabled={!isResend}
          htmlType="submit"
          upperCase
          onClick={handleResend}
        >
          {BTN_TXT.RESEND_VERIFICATION_LINK}
        </Button>
        {!isResend && (
          <div className="col fa-count-down">
            <Countdown
              format="ss"
              value={countValue}
              prefix="in"
              suffix="seconds"
              onFinish={handleFinishCountDown}
            />
          </div>
        )}
      </div>
    </SignPopup>
  )
}

export default VerifyEmail
