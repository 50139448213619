import './styles.scss'
import { ROLES_NAMES } from 'constants/common'
import { Avatar } from 'components/Avatar/Avatar'
import { Typography, TYPOGRAPHY_WEIGHT } from 'components/Typography'
import { Can } from 'features/Chat/Providers/ChatAbilityProvider'
import { ACTIONS, chatSubject, SUBJECTS } from 'features/Chat/Abilities'
import { useChatContext } from 'features/Chat/Providers/ChatContextProvider'
import DeleteMember from 'features/ChatDetails/Members/DeleteMember'
import { IGroupChatDetails } from 'redux/store/chatDetails/types'
import { Props } from './types'

const Member = (props: Props) => {
  const { user, canEdit } = props

  const chatContext = useChatContext<IGroupChatDetails>()

  const { chat } = chatContext.state
  const capitalCharName = `${user.first_name?.charAt(0) || ''}${
    user.last_name?.charAt(0) || ''
  }`.toUpperCase()

  const name = [user.first_name, user.last_name].join(' ')
  return (
    <div className="rfp-details-responsible-user chat-member">
      <div className="rfp-details-responsible-user__user">
        <div className="rfp-details-responsible-user__avatar">
          <Avatar
            name={capitalCharName}
            alt="Responsible user image"
            src={user.avatar?.file_preview}
          />
        </div>
        <div className="rfp-details-responsible-user__content">
          <Typography.Body1 weight="semi-bold">{name}</Typography.Body1>
          <Typography.Caption className="rfp-details-responsible-user__role mb-4">
            {ROLES_NAMES[user.role]}
          </Typography.Caption>
          <Typography.Caption
            className="rfp-details-responsible-user__role"
            uppercase
          >
            {user.organization_name}
          </Typography.Caption>
        </div>
      </div>
      {chat && user.uuid === chat.owner_id ? (
        <Typography.Body2
          weight={TYPOGRAPHY_WEIGHT.SEMI_BOLD}
          className="rfp-details-responsible-user__owner"
        >
          Owner
        </Typography.Body2>
      ) : (
        canEdit && (
          <Can I={ACTIONS.EDIT} a={chatSubject(SUBJECTS.GROUP_CHAT, chat)}>
            <DeleteMember user={user} />
          </Can>
        )
      )}
    </div>
  )
}

export default Member
