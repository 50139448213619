import { BTN_TXT } from 'constants/txt'
import { Button, BUTTON_TYPES } from 'components/Button'
import { useContractDetailsContext } from 'features/ContractDetails/Providers/ContractDetailsContextProvider'
import {
  ACTIONS,
  contractSubject,
  SUBJECTS
} from 'features/ContractDetails/Abilities'
import { CONTRACT_STATUS } from 'features/ContractDetails/constants'
import { Can } from 'features/ContractDetails/Providers/ContractDetailsAbilityProvider'
import { checkIsBefore } from '../../../helper/dates'

const ContractSignedAction = () => {
  const contractDetailsContext = useContractDetailsContext()

  const { details } = contractDetailsContext.state
  const { updateContractDetailsStatusAsync } = contractDetailsContext.actions

  const handleClickContractSigned = () => {
    const status = checkIsBefore(details.start_date)
      ? CONTRACT_STATUS.ACTIVE
      : CONTRACT_STATUS.PENDING_FOR_START
    updateContractDetailsStatusAsync(status)
  }

  return (
    <Can
      I={ACTIONS.SUBMIT}
      a={contractSubject(SUBJECTS.CONTRACT_SIGNED, details)}
    >
      <Button
        type={BUTTON_TYPES.DEFAULT}
        onClick={handleClickContractSigned}
        upperCase
      >
        {BTN_TXT.CONTRACT_SIGNED}
      </Button>
    </Can>
  )
}

export default ContractSignedAction
