import cn from 'classnames'
import Header from './Header/Header'
import ChatSearch from './ChatSearch/ChatSearch'
import History from './History/History'
import MessageToReply from './MessageToReply/MessageToReply'
import Input from './Input/Input'
import Sidebar from './Sidebar/Sidebar'
import { useSelector } from 'react-redux'
import { getMessageToReply } from '../../redux/store/chatHistory/getters'
import { useChatContext } from './Providers/ChatContextProvider'
import { useMemo } from 'react'

const ChatContext = () => {
  const repliedMessage = useSelector(getMessageToReply)
  const context = useChatContext()
  const fullName = useMemo(() => {
    return (
      repliedMessage?.message.author.first_name +
      ' ' +
      repliedMessage?.message.author.last_name
    )
  }, [repliedMessage])
  return (
    <>
      <div className={cn('chat__grid', { search: context.state?.isSearch })}>
        <Header />
        {context.state?.isSearch && <ChatSearch />}
        <History />
        {repliedMessage?.uuid && (
          <MessageToReply
            isView={false}
            full_name={fullName}
            uuid={repliedMessage.uuid}
            body={repliedMessage.message.body}
          />
        )}
        <Input />
      </div>
      <Sidebar />
    </>
  )
}

export default ChatContext
