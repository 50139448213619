import PendingClassificationTableContextProvider from '../Providers/PendingClassificationTableProvider'
import { PendingClassification } from './PendingClassification'
import PendingClassificationPopupContextProvider from '../Providers/PendingClassificationPopupProvider'
import PendingClassificationEditContextProvider from '../Providers/PendingClassificationEditProvider'
import SimilarItemsDrawer from './SimilarItems/SimilarItemsDrawer'
import BulkEditPopup from './BulkEdit/BulkEditPopup'
import ConfirmSavePopup from './ConfirmSave/ConfirmSavePopup'

export const PendingClassificationContainer = () => {
  return (
    <PendingClassificationPopupContextProvider>
      <PendingClassificationEditContextProvider>
        <PendingClassificationTableContextProvider>
          <PendingClassification />
          <SimilarItemsDrawer />
          <BulkEditPopup />
          <ConfirmSavePopup />
        </PendingClassificationTableContextProvider>
      </PendingClassificationEditContextProvider>
    </PendingClassificationPopupContextProvider>
  )
}
