import './styles.scss'

const PageNotFound = (): JSX.Element => {
  return (
    <div>
      <br />
      Uh-oh! Looks like we can’t find
      <br /> what you were looking for...
    </div>
  )
}

export default PageNotFound
