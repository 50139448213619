import { Ability, AbilityBuilder, AbilityClass } from '@casl/ability'

import { IUser } from 'redux/store/user/types'
import { ROLES } from 'features/Permission'
import {
  Conditions,
  ChatListItemAbility,
  ChatListItemAbilityBuilder,
  PossibleAbilities
} from 'features/ChatList/Abilities/types'
import { ACTIONS, SUBJECTS } from 'features/ChatList/Abilities/constants'

const ChatListItemAbilityClass = Ability as AbilityClass<ChatListItemAbility>

export const ability = new Ability<PossibleAbilities, Conditions>()

export default (user: IUser) => {
  const builder = new AbilityBuilder(ChatListItemAbilityClass)

  defineAnonymousAbilities(builder)

  switch (user.role) {
    case ROLES.VICE_PRESIDENT:
    case ROLES.CONTRACT_STEWARD:
    case ROLES.LEGAL:
    case ROLES.ANALYST:
    case ROLES.VENDOR_LEGAL:
    case ROLES.VENDOR_ANALYST:
    case ROLES.VENDOR_CONTRACT_STEWARD:
      defineVCVAbilities(builder)
      break
  }

  return new Ability(builder.rules) as ChatListItemAbility
}

const defineVCVAbilities = (builder: ChatListItemAbilityBuilder) => {
  builder.can(ACTIONS.VIEW, SUBJECTS.CHANNEL_CHATS)
}

const defineAnonymousAbilities = (builder: ChatListItemAbilityBuilder) => {
  builder.can(ACTIONS.VIEW, [SUBJECTS.GROUP_CHATS, SUBJECTS.PRIVATE_CHATS])
}
