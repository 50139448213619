import Fetcher from 'services/Fetcher'
import { API_DICTIONARY, HTTP_METHODS } from '../../../../constants'
import {
  VendorUsersResponse,
  VendorUsersRequest,
  VendorCategoriesRequest
} from './types'
import { FilterListResponse } from '../../types'

const fetcher = new Fetcher({})

export const getVendorsList: VendorUsersRequest = async (id, role, data) =>
  fetcher.request<unknown, VendorUsersResponse>({
    url: `${API_DICTIONARY.MY_COMMUNITY}${API_DICTIONARY.USERS}${API_DICTIONARY.VENDOR}${id}/${role}/`,
    method: HTTP_METHODS.GET,
    ...data
  })

export const getContractCategoriesList: VendorCategoriesRequest = async (
  id,
  role
) =>
  fetcher.request<unknown, FilterListResponse>({
    url: `${API_DICTIONARY.MY_COMMUNITY}${API_DICTIONARY.USERS}${API_DICTIONARY.VENDOR}${id}/${role}/${API_DICTIONARY.CONTRACT_CATEGORIES}`,
    method: HTTP_METHODS.GET
  })
