import cn from 'classnames'
import { Input } from 'antd'
import { Props } from './types'
import './input.default.scss'

export const DefaultNoForm = ({ ...props }: Props): JSX.Element => {
  return (
    <div
      className={cn('custom-input', 'input-default', props.className, {
        'custom-input-error': props.hasCustomError
      })}
    >
      <Input {...props.propsInput} />
    </div>
  )
}
