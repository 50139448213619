import cn from 'classnames'
import { NavLink as NavLinkReact } from 'react-router-dom'
import { DefaultProps } from './types'
import './styles.scss'

const NavLink = ({
  className,
  label,
  hrefTo,
  Icon,
  onClick,
  children
}: DefaultProps): JSX.Element => (
  <NavLinkReact
    to={hrefTo}
    key={hrefTo}
    onClick={onClick}
    className={({ isActive }) =>
      isActive
        ? 'nav-link-default_active nav-link-default'
        : `${cn('nav-link-default', className)}`
    }
  >
    <div className="nav-link-default__link-content row align-center">
      <Icon />
      <span>{label}</span>
    </div>
    <div>{children}</div>
  </NavLinkReact>
)

// add versions declarations here if needed

export default NavLink
