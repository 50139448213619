import useRouter from '../../hooks/useRouter'
import { routes } from '../../router'
import './style.scss'
const TermsOfUseAction = () => {
  const { push } = useRouter()
  const handleOpenTerms = (e) => {
    e.stopPropagation()
    e.preventDefault()
    push(routes.termsOfUse)
  }
  return <span onClick={handleOpenTerms}>Terms</span>
}

export default TermsOfUseAction
