import DetailsForm from '../General/DetailsForm/DetailsForm'
import ContractAdminPreview from '../General/ContractAdminPreview/ContractAdminPreview'
import { useVendorDetailsContext } from '../Providers/ContextProvider'
import './styles.scss'

const General = () => {
  const vendorDetailsContext = useVendorDetailsContext()

  const { isCreation } = vendorDetailsContext.state

  return isCreation ? (
    <div className="vendor-details-general">
      <DetailsForm />
      <ContractAdminPreview />
    </div>
  ) : (
    <></>
  )
}

export default General
