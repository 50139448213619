import './style.scss'
import VendorInfo from './VendorInfo'
import { useCheckInsListContext } from '../Providers/CheckInsContextProvider'
import { ActiveCheckIn } from './ActiveCheckIn'
import { useMemo } from 'react'
import { PrevCheckIns } from './PrevCheckIns/PrevCheckIns'

const Details = () => {
  const { state } = useCheckInsListContext()
  const { checkInsDetails } = state
  const activeCheckIn = useMemo(() => {
    return (
      checkInsDetails?.list?.find((item) => item.status === 'active') || null
    )
  }, [checkInsDetails?.list])
  const previousCheckIns = useMemo(() => {
    return checkInsDetails?.list?.filter((item) => item.status !== 'active')
  }, [checkInsDetails?.list])
  return (
    <div className="check-ins-details-container column full-height">
      {!!checkInsDetails?.info && <VendorInfo info={checkInsDetails.info} />}
      {!!activeCheckIn && <ActiveCheckIn item={activeCheckIn} />}
      {!!previousCheckIns?.length && <PrevCheckIns list={previousCheckIns} />}
    </div>
  )
}
export default Details
