import { useDispatch, useSelector } from 'react-redux'
import { Container } from 'components/Container/Container'
import { Typography, TYPOGRAPHY_WEIGHT } from 'components/Typography'
import Select from 'components/Select/Select'
import { useState, useEffect } from 'react'
import { getCommunityInfo } from '../../redux/store/chapterInfo/getters'
import './styles.scss'
import { ReactComponent as Information } from 'assets/svg/Information.svg'
import '../../components/Datepicker/datepicker.default.scss'
import {
  FACILITIES_OPTIONS_LABELS,
  REPORTS_COLORS,
  ACTUAL_PAYMENTS_COLORS,
  adjustActualPaymentsData,
  INITIAL_DATA_PAYMENTS_LEGEND,
  getFinancialStatus,
  getFinancialStatusLegend,
  getFinancialStatusLegendOrder,
  getStartOfQuarter,
  getEndOfQuarter
} from './constants'
import moment from 'moment'
import { FinancialsTable } from './FinancialsTable'
import { FinancialsChartLegendPart } from './FinancialsChartLegendPart'
import { ActualPaymentsChart } from './ActualPaymentsChart'
import { ReportsChart } from './ReportsCharts'
import { setLoading } from '../../redux/store/common/slice'
import { getFacilities, getReports, getActualPayments, getVendors } from './api'
import {
  TReportsList,
  TVendorsOptions,
  TActualPaymentsData,
  TFinancialsListFilters
} from './types'
import { RangeValue } from '../Compliance/types'
import { LABELS, PLACEHOLDERS } from '../../constants'
import { getUser } from '../../redux/store/user/getters'
import { getVendorInfo } from '../../redux/store/vendorInfo/getters'
import { TGroup } from '../../components/Select/types'
import { Tooltip } from '../../components/Tooltip/Tooltip'
import Datepicker from '../../components/Datepicker/Datepicker'
import {
  convertDateToRequestFormat,
  requestMonthFormat
} from '../../utils/moment'

export const Financials = () => {
  const communityInfo = useSelector(getCommunityInfo)
  const vendorInfo = useSelector(getVendorInfo)
  const [period, setPeriod] = useState<RangeValue>([
    moment().subtract(5, 'month'),
    moment().subtract(3, 'month')
  ])
  const [vendorsOptions, setVendorsOptions] = useState<TVendorsOptions[]>([])
  const [facilitiesOptions, setFacilitiesOptions] = useState<TGroup[]>([])
  const user = useSelector(getUser)

  const dispatch = useDispatch()
  const [actualPayments, setActualPayments] = useState<TActualPaymentsData>([])
  const [reports, setReports] = useState<TReportsList | null>(null)
  const [selectedVendors, setSelectedVendors] = useState([])
  const [selectedFacilities, setSelectedFacilities] = useState([])
  const [isUpdate, setIsUpdate] = useState(false)

  const isCommunity = user.role.includes('community')
  const isVendor = user.role.includes('vendor')

  useEffect(() => {
    if (isCommunity) {
      getVendors().then((resp) => setVendorsOptions(resp.data.results))
    }
    if (isVendor) {
      getFacilities().then((resp) => {
        setFacilitiesOptions(
          Object.entries(resp.data).map((i) => ({
            label: i[1].length ? FACILITIES_OPTIONS_LABELS[i[0]] : '',
            options: i[1].map((j) => ({
              value: j.uuid,
              label: j.name
            }))
          }))
        )
      })
    }
  }, [])

  useEffect(
    () => getChartsData(),
    [period, selectedVendors, selectedFacilities, isUpdate]
  )

  const getChartsData = () => {
    dispatch(setLoading(true))
    const params: TFinancialsListFilters = {}
    if (period && period[0] && period[1]) {
      params.date_from = convertDateToRequestFormat(period[0]?.startOf('month'))
      params.date_to = convertDateToRequestFormat(period[1]?.endOf('month'))
    }
    if (selectedVendors) {
      params.vendors = selectedVendors
    }
    if (selectedFacilities) {
      params.facilities = selectedFacilities
    }
    getReports({ params }).then((res) => {
      setReports({
        ...res.data,
        data: res.data.data.map((i) => ({ ...i, amount: Number(i.amount) }))
      })
    })
    getActualPayments({ params }).then((res) => {
      if (res?.data) {
        const data = adjustActualPaymentsData(res.data).slice().reverse()
        setActualPayments(data)
      }
    })
  }

  const onVendorHandler = (value) => {
    setSelectedVendors(value)
  }

  const onFacilityHandler = (value) => {
    setSelectedFacilities(value)
  }
  return (
    <Container
      title={
        <div className="financials__title-container">
          <Typography.Headline3 weight={TYPOGRAPHY_WEIGHT.SEMI_BOLD}>
            Financials
          </Typography.Headline3>
          <Tooltip
            className="financials__tooltip"
            overlayInnerStyle={{
              width: 360
            }}
            title={
              <div>
                3% rebate fee and 20% administration fee will be deducted from
                the total
              </div>
            }
            placement="topLeft"
          >
            <Information />
          </Tooltip>
        </div>
      }
      subtitle={communityInfo?.name || vendorInfo?.name}
    >
      <div className="financials__filters">
        <div className="compliance__datepicker-container">
          <Typography.Label className="datepicker__label" weight="semi-bold">
            Period
          </Typography.Label>
          <Datepicker.Range
            isMonthRange
            propsRangeDate={{
              picker: 'month',
              format: requestMonthFormat,
              onChange: (dateStrings) => {
                setPeriod(dateStrings as RangeValue)
              },
              defaultValue: [getStartOfQuarter(), getEndOfQuarter()]
            }}
          />
        </div>
        {isCommunity && (
          <Select.Multi
            className="financials__select"
            options={vendorsOptions.map((option) => ({
              value: option.uuid,
              label: option.name
            }))}
            propsItem={{
              label: LABELS.VENDOR
            }}
            propsSelect={{
              placeholder: PLACEHOLDERS.PLEASE_SELECT,
              onChange: onVendorHandler
            }}
          />
        )}
        {isVendor && (
          <Select.Multi
            className="financials__select"
            options={facilitiesOptions}
            propsItem={{
              label: LABELS.FACILITY
            }}
            propsSelect={{
              placeholder: PLACEHOLDERS.PLEASE_SELECT,
              onChange: onFacilityHandler
            }}
          />
        )}
      </div>
      <div className="charts__container">
        <div className="charts_ongoing-contracts_container">
          <div className="charts_title">Actual payments</div>
          <div className="charts__description align-start">
            <div className="round-chart__payments">
              <ActualPaymentsChart
                data={actualPayments || INITIAL_DATA_PAYMENTS_LEGEND}
              />
            </div>
            <div className="chart__legend">
              {(!!actualPayments
                ? actualPayments
                : INITIAL_DATA_PAYMENTS_LEGEND.reverse()
              ).map((i, idx) => {
                return (
                  <div className="legend__part" key={idx}>
                    <FinancialsChartLegendPart
                      color={ACTUAL_PAYMENTS_COLORS[idx]}
                      name={i.status}
                      price={i.payments}
                    />
                  </div>
                )
              })}
            </div>
          </div>
        </div>
        <div className="charts_ongoing-contracts_container">
          <div className="charts_title">Reports</div>
          <div className="charts__description align-start">
            <div className="round-chart__reports">
              <ReportsChart
                isVendor={isVendor}
                total={reports?.total_amount}
                data={getFinancialStatusLegendOrder(
                  !!reports?.data.length
                    ? reports.data
                    : getFinancialStatusLegend(isVendor)
                )}
              />
            </div>
            <div className="chart__legend">
              {(!!reports?.data.length
                ? reports.data
                : getFinancialStatusLegend(isVendor)
              )?.map((i, idx) => {
                return (
                  <div className="legend__part" key={idx}>
                    <FinancialsChartLegendPart
                      color={REPORTS_COLORS[i.status]}
                      name={getFinancialStatus(i.status, isVendor)}
                      price={i.amount}
                    />
                  </div>
                )
              })}
            </div>
          </div>
        </div>
      </div>
      <FinancialsTable
        period={period}
        selectedVendors={selectedVendors}
        selectedFacilities={selectedFacilities}
        isVendor={isVendor}
        isUpdate={isUpdate}
        getChartsData={getChartsData}
        setIsUpdate={setIsUpdate}
      />
    </Container>
  )
}
