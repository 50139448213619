import { Fragment } from 'react'
import moment from 'moment/moment'
import { Typography, TYPOGRAPHY_WEIGHT } from '../../../components/Typography'
import { NotificationCard } from '../NotificationCard/NotificationCard'
import { getIsToday } from '../utils'
import { timelineDateFormat } from '../../../components/Timelines/constants'
import { NotificationItemListProps } from './types'

export const NotificationItemList = ({
  onMarkAsRead,
  showDate,
  data
}: NotificationItemListProps) => {
  return (
    <Fragment>
      {showDate && (
        <Typography.Label
          className="notifications-drawer__date"
          weight={TYPOGRAPHY_WEIGHT.SEMI_BOLD}
        >
          {moment(data.created_at).format(timelineDateFormat)}
          {getIsToday(data.created_at) && ' - Today'}
        </Typography.Label>
      )}
      <NotificationCard
        createdAt={data.created_at}
        isRead={data.is_read}
        id={data.uuid}
        onMarkAsRead={onMarkAsRead}
        description={data.description}
        payload={data.payload}
      />
    </Fragment>
  )
}
