import Popup from '../../../../components/Popup/Popup'
import { Button, BUTTON_TYPES } from '../../../../components/Button'
import { BTN_TXT } from '../../../../constants'
import {
  Typography,
  TYPOGRAPHY_WEIGHT
} from '../../../../components/Typography'
import { PRS_STATUSES } from '../../../../pages/ProductRequests/constants'
import { usePRSDetailsPopupContext } from '../Providers/PRSDetailsPopupProvider'
import { usePRSDetailsContext } from '../Providers/PRSDetailsContextProvider'

const FullAccessContract = () => {
  const { createContractPopup } = usePRSDetailsPopupContext()
  const prsDetailsContext = usePRSDetailsContext()

  const { state, actions } = createContractPopup
  const onSend = () => {
    prsDetailsContext.actions.handleChangePrsStatus(PRS_STATUSES.ARCHIVED)
  }
  return (
    <Popup
      visible={state.visible}
      onCancel={actions.close}
      title={`Create contract?`}
      width={442}
      footer={
        <>
          <Button onClick={actions.close} type={BUTTON_TYPES.DEFAULT} upperCase>
            {BTN_TXT.CANCEL}
          </Button>
          <Button type={BUTTON_TYPES.PRIMARY} upperCase onClick={onSend}>
            {BTN_TXT.CREATE_CONTRACT}
          </Button>
        </>
      }
    >
      <>
        <Typography.Body1 weight={TYPOGRAPHY_WEIGHT.NORMAL}>
          Product request review has been completed and contract will be
          created. Do you want to create contract?
        </Typography.Body1>
      </>
    </Popup>
  )
}

export default FullAccessContract
