import {
  convertToFixed,
  currencyFormatter
} from '../../../ContractDetails/ContractDetailsGeneral/ContractDetailsForm/utils'
import { Pie } from '@ant-design/plots'
import { OVERALL_CHART_COLORS, OVERALL_STATUS_LABEL } from './constants'
import { TOverallCompliance } from '../../../../pages/Compliance/types'
import { memo } from 'react'
type TProps = {
  data: TOverallCompliance[]
  total?: string
}
export const Chart = memo(({ data, total = '0' }: TProps) => {
  const config = {
    data: data,
    tooltip: {
      customContent: (_title, data) => {
        return data.map((i, idx) => (
          <div className="chart__tooltip" key={idx}>
            {OVERALL_STATUS_LABEL[i.data.name]} <br /> $
            {currencyFormatter(convertToFixed(i.value))} (
            {(Number(i.data.percentage) * 100).toFixed(2)}
            %)
          </div>
        ))
      }
    },
    pieStyle: {
      lineWidth: 2
    },
    angleField: 'amount',
    colorField: 'name',
    radius: 0.8,
    innerRadius: 0.6,
    indicator: false,
    color: data.find((i) => !!Number(i.amount || 0))
      ? data.map((i) => OVERALL_CHART_COLORS[i.name])
      : ['#F3F5F6'],
    label: {
      type: 'inner',
      offset: '-50%',
      content: '',
      style: {
        textAlign: 'center',
        fontSize: 14
      }
    },
    legend: false as const,
    interactions: [],
    statistic: {
      title: {
        style: {
          color: '#285F86',
          fontFamily: 'Open Sans',
          fontStyle: 'normal',
          fontWeight: 700,
          fontSize: '16px',
          lineHeight: '24px'
        },
        offsetY: 5,
        customHtml: (_container, _view) => {
          return `<div >${(Number(total) * 100).toFixed(2)}%</div>`
        }
      },
      content: {
        style: {
          color: '#6E7F8C',
          fontFamily: 'Open Sans',
          fontStyle: 'normal',
          fontWeight: 400,
          fontSize: '14px',
          lineHeight: '20px'
        },
        offsetY: 8,
        content: ''
      }
    }
  }
  return <Pie {...config} />
})
