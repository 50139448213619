import * as React from 'react'

import {
  Cell,
  CellTemplate,
  Compatible,
  Span,
  Uncertain
} from '@silevis/reactgrid'
import cn from 'classnames'

export interface IconCellInterface extends Cell, Span {
  type: 'icon'
  iconSrc?: React.ReactElement
  onIconClick?: () => void
}

export class CustomCellIconTemplate implements CellTemplate<IconCellInterface> {
  getCompatibleCell(
    uncertainCell: Uncertain<IconCellInterface>
  ): Compatible<IconCellInterface> {
    return { ...uncertainCell, text: '', value: 0 }
  }

  isFocusable = (): boolean => false

  getClassName(cell: Compatible<IconCellInterface>): string {
    return cell.className ? cell.className : ''
  }

  render(cell: Compatible<IconCellInterface>): React.ReactNode {
    return (
      <div
        onClick={cell?.onIconClick}
        className={cn(
          'full-width full-height flex align-center justify-center icon-cell-wrapper',
          { pointer: !!cell?.onIconClick }
        )}
      >
        {cell?.iconSrc ?? <></>}
      </div>
    )
  }
}
