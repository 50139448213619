import { Container } from '../../components/Container/Container'
import './styles.scss'
import ClinicalReview from '../../features/ClinicalReview/ClinicalReview'
import ClinicalReviewPopupProvider from './Providers/useClinicalReviewPopup'
import { useState } from 'react'

export const ClinicalReviewPage = () => {
  const [isReviewEmpty, setIsReviewEmpty] = useState(false)
  return (
    <ClinicalReviewPopupProvider>
      <Container
        className="clinical-review-container"
        title="Clinical Review"
        subtitle={
          isReviewEmpty
            ? ''
            : 'Please provide your opinion regarding the acceptance of the categories'
        }
      >
        <ClinicalReview setIsReviewEmpty={setIsReviewEmpty} />
      </Container>
    </ClinicalReviewPopupProvider>
  )
}
