import { Button, BUTTON_TYPES } from 'components/Button'
import { BTN_TXT } from 'constants/txt'
import { ReactComponent as Send16 } from 'assets/svg/Send16.svg'
import { useRFPDetailsActions } from 'features/RFP/RFPDetails/Providers/RFPDetailsActionsProvider'
import { useRFPDetailsContext } from 'features/RFP/RFPDetails/Providers/RFPDetailsContextProvider'
import { ACTIONS, Can, SUBJECTS } from 'features/Permission'
import { subject } from '@casl/ability'

export default () => {
  const rfpDetailsContext = useRFPDetailsContext()
  const rfpDetailsActions = useRFPDetailsActions()

  const { data } = rfpDetailsContext.state

  return (
    <Can I={ACTIONS.SEND} a={subject(SUBJECTS.RFP, { ...data })}>
      <Button
        icon={<Send16 />}
        type={BUTTON_TYPES.PRIMARY}
        onClick={rfpDetailsActions.handleSendRfp}
        upperCase
      >
        {BTN_TXT.SEND_RFP}
      </Button>
    </Can>
  )
}
