import { useSelector } from 'react-redux'
import { Container } from '../../../components/Container/Container'
import { Menu } from '../../../components/Menu/Menu'
import useRouter from '../../../hooks/useRouter'
import { getCommunityInfo } from '../../../redux/store/chapterInfo/getters'
import { useInvoiceDataContext } from '../../../pages/InvoiceData/Providers/InvoiceDataContextProvider'

export const InvoiceDataContainer = ({ children }) => {
  const communityInfo = useSelector(getCommunityInfo)
  const router = useRouter()
  const invoiceDataContext = useInvoiceDataContext()
  const { tabs, activeTab } = invoiceDataContext.state
  const { setActiveTab } = invoiceDataContext.actions

  return (
    <Container
      className="invoice-data-container"
      title="Invoice data"
      subtitle={communityInfo?.name}
      menu={
        <Menu
          mode="horizontal"
          onSelect={(e) => {
            setActiveTab(e.key)
            router.push(e.key)
          }}
          selectedKeys={[activeTab]}
          defaultSelectedKeys={[activeTab]}
          items={tabs}
        />
      }
    >
      {children}
    </Container>
  )
}
