import { Form } from 'antd'
import cn from 'classnames'
import { Input } from '../Input'
import { BTN_TXT, LABELS, PLACEHOLDERS } from '../../constants'
import {
  TFormSelfSignUpForm,
  TFormSelfSignUpFormErrors
} from './forms.completeProfile.d'
import {
  formatName,
  formatPhoneNumber,
  validateMessages
} from '../../helper/common'
import { Select } from '../Select'
import { useEffect, useState } from 'react'
import { Button, BUTTON_TYPES } from '../Button'
import { TOption } from '../Select/types'
import {
  getContractCategories,
  getDepartments,
  completeProfile
} from '../../pages/CompleteProfile/api'
import { routes } from '../../router'
import useRouter from '../../hooks/useRouter'
import { setLoading } from '../../redux/store/common/slice'
import { useDispatch } from 'react-redux'

export const CompleteProfile = (): JSX.Element => {
  const [form] = Form.useForm()
  const [error, setError] = useState({} as TFormSelfSignUpFormErrors)
  const router = useRouter()
  const dispatch = useDispatch()

  const firstName = Form.useWatch('first_name', form)
  const lastName = Form.useWatch('last_name', form)
  const department = Form.useWatch('department', form)
  const phoneNumber = Form.useWatch('phone_number', form)
  const cc = Form.useWatch('contract_categories', form)

  const [departments, setDepartments] = useState<TOption[]>([])
  const [contractCategories, setContractCategories] = useState<TOption[]>([])

  const token = localStorage.getItem('completeProfileToken')

  const onFinish = (values) => {
    dispatch(setLoading(true))
    if (values?.phone_number) {
      values.phone_number = !values?.phone_number.toString().includes('+1')
        ? '+1' + values.phone_number
        : values.phone_number
    } else values.phone_number = ''

    const keys = ['vendor_preferences', 'title']
    keys.forEach((key) => {
      if ((values?.[key] && !values[key].length) || !values?.[key])
        values[key] = ''
    })
    completeProfile({
      ...values,
      token
    })
      .then(() => {
        localStorage.removeItem('completeProfileToken')
        router.push(routes.login)
      })
      .catch((e) => {
        setError(e.data)
        dispatch(setLoading(false))
      })
  }

  useEffect(() => {
    if (token) {
      Promise.all([getContractCategories(token), getDepartments(token)])
        .then((responses) => {
          const [ccResponse, departmentsResponse] = responses
          if (ccResponse.data.results) {
            setContractCategories(
              ccResponse.data.results.map((option) => {
                return {
                  label: option.value,
                  value: option.id
                }
              })
            )
          }
          if (departmentsResponse.data.results) {
            setDepartments(
              departmentsResponse.data.results.map((option) => {
                return {
                  label: option.value,
                  value: option.id
                }
              })
            )
          }
        })
        .then(() => {
          dispatch(setLoading(false))
        })
    } else {
      router.push(routes.login)
    }
  }, [])

  return (
    <Form<TFormSelfSignUpForm>
      className={cn('forms-sign-in')}
      validateMessages={validateMessages}
      layout="vertical"
      onFinish={onFinish}
      form={form}
    >
      <div className="row mt-24">
        <Input
          propsItem={{
            label: LABELS.FIRST_NAME,
            name: 'first_name',
            help: error?.first_name ? error?.first_name[0] : undefined,
            validateStatus: error?.first_name ? 'error' : undefined,
            rules: [
              {
                pattern: /^[-\w\s]+$/,
                max: 40,
                required: true
              }
            ],
            normalize: formatName
          }}
        />
      </div>
      <div className="row mt-24">
        <Input
          propsItem={{
            label: LABELS.LAST_NAME,
            name: 'last_name',
            help: error?.last_name ? error?.last_name[0] : undefined,
            validateStatus: error?.last_name ? 'error' : undefined,
            rules: [
              {
                pattern: /^[-\w\s]+$/,
                max: 40,
                required: true
              }
            ]
          }}
        />
      </div>
      <div className="row mt-24">
        <Input
          propsItem={{
            label: LABELS.TITLE,
            name: 'title',
            help: error?.title ? error?.title[0] : undefined,
            validateStatus: error?.title ? 'error' : undefined,
            rules: [
              {
                max: 50
              }
            ]
          }}
          propsInput={{
            maxLength: 50
          }}
        />
      </div>
      <div className="row mt-24">
        <Input.Phone
          propsInputNumber={{
            controls: false,
            formatter: formatPhoneNumber
          }}
          propsItem={{
            name: 'phone_number',
            label: LABELS.PHONE_NUMBER,
            help: error?.phone_number ? error?.phone_number[0] : undefined,
            validateStatus: error?.phone_number ? 'error' : undefined,
            rules: [
              {
                required: true
              }
            ]
          }}
        />
      </div>
      <div className="row mt-24">
        <Select.Default
          options={departments}
          propsItem={{
            label: LABELS.DEPARTMENT,
            name: 'department',
            rules: [
              {
                required: true
              }
            ]
          }}
          propsSelect={{
            placeholder: PLACEHOLDERS.PLEASE_SELECT
          }}
        />
      </div>
      <div className="row mt-24">
        <Select.Multi
          options={contractCategories}
          propsItem={{
            label: LABELS.CONTRACT_CATEGORIES,
            name: 'contract_categories',
            rules: [
              {
                required: true
              }
            ]
          }}
          propsSelect={{
            placeholder: PLACEHOLDERS.PLEASE_SELECT
          }}
        />
      </div>
      <div className="row mt-24">
        <Input.TextArea
          propsItem={{
            label: LABELS.VENDOR_PREFERENCES,
            name: 'vendor_preferences',
            help: error?.vendor_preferences
              ? error?.vendor_preferences[0]
              : undefined,
            validateStatus: error?.vendor_preferences ? 'error' : undefined,
            rules: [
              {
                max: 100
              }
            ]
          }}
          propsTextArea={{
            showCount: true,
            maxLength: 100
          }}
        />
      </div>
      <div className="row mt-24">
        <Button
          type={BUTTON_TYPES.PRIMARY}
          htmlType="submit"
          upperCase
          disabled={
            !firstName || !lastName || !cc || !phoneNumber || !department
          }
        >
          {BTN_TXT.NEXT}
        </Button>
      </div>
    </Form>
  )
}
