import { useDispatch } from 'react-redux'
import { Form } from 'antd'
import cn from 'classnames'
import { Input } from 'components/Input'
import { Button, BUTTON_TYPES } from 'components/Button'
import { setLoading } from 'redux/store/common/slice'
import { setPhoneNumberSignUp } from 'features/SignPopup/api'
import useRouter from 'hooks/useRouter'
import { routes } from 'router'
import { BTN_TXT, LABELS } from 'constants/txt'
import { TFANumber, TFormFaNumberForm } from './forms.2FANumber.d'
import {
  formatPhoneNumber,
  getLocalConfirmToken,
  parserPhoneNumber,
  setLocalConfirmToken,
  setLocalPhoneNumber
} from 'helper/common'
import { Typography, TYPOGRAPHY_WEIGHT } from '../Typography'
import { faNumberInitialValues } from './constants'

export const FANumber: TFANumber = ({ className }): JSX.Element => {
  const dispatch = useDispatch()
  const [form] = Form.useForm()
  const phoneNumber = Form.useWatch('phone_number', form)
  const { push } = useRouter()
  const handleLogin = (values) => {
    dispatch(setLoading(true))
    const token = getLocalConfirmToken()
    values.phone_number = !values?.phone_number.toString().includes('+1')
      ? '+1' + values.phone_number
      : values.phone_number
    setPhoneNumberSignUp({ ...values, token: token })
      .then((resp) => {
        setLocalPhoneNumber(values.phone_number)
        push(routes.faCode)
        setLocalConfirmToken(resp?.data?.token || token || '')
      })
      .finally(() => dispatch(setLoading(false)))
  }
  return (
    <Form<TFormFaNumberForm>
      className={cn('forms-sign-in', className)}
      layout="vertical"
      onFinish={handleLogin}
      initialValues={faNumberInitialValues}
      form={form}
    >
      <div className="row mt-32">
        <Typography.HeadlineD4 weight={TYPOGRAPHY_WEIGHT.BOLD}>
          Two-factor authentication
        </Typography.HeadlineD4>
      </div>
      <div className="row mt-12">
        <Typography.Body1>
          Enter your phone number and we will send you one-time passcode:
        </Typography.Body1>
      </div>
      <div className="row mt-24">
        <Input.Phone
          propsInputNumber={{
            controls: false,
            formatter: formatPhoneNumber,
            prefix: phoneNumber ? '+1' : ''
          }}
          propsItem={{
            name: 'phone_number',
            label: LABELS.PHONE_NUMBER
          }}
        />
      </div>
      <div className="row mt-24">
        <Form.Item noStyle shouldUpdate>
          {({ getFieldsValue }) => {
            const { phone_number } = getFieldsValue()
            return (
              <Button
                type={BUTTON_TYPES.PRIMARY}
                disabled={parserPhoneNumber(phone_number)}
                htmlType="submit"
                upperCase
              >
                {BTN_TXT.NEXT}
              </Button>
            )
          }}
        </Form.Item>
      </div>
    </Form>
  )
}
