import { Filter } from './dropdown.filter'
import { TDropdownProps } from './types'

const Dropdown = <FiltersType, ApplyFiltersFunc, SearchFuncType>(
  props: TDropdownProps<FiltersType, ApplyFiltersFunc, SearchFuncType>
) => <Filter<FiltersType, ApplyFiltersFunc, SearchFuncType> {...props} />

Dropdown.Filter = Filter

export default Dropdown
