import { useState, useEffect, useRef } from 'react'
import { useDispatch } from 'react-redux'
import axios, { CancelTokenSource } from 'axios'
import classNames from 'classnames'
import { Table } from 'components/Table'
import { setLoading } from 'redux/store/common/slice'
import { DEFAULT_PAGE } from 'components/Table/constants'
import '../styles.scss'
import { columns, DASHBOARD_TABS } from '../constants'
import {
  getExpiringContractList,
  getExpiringContractsSteps,
  getExpiringRFPSteps
} from '../api'

export const DashboardTable = ({
  activeTab,
  healthSystem,
  user,
  collapsingTopMargin
}: {
  activeTab: string
  healthSystem?: string
  user?: string
  collapsingTopMargin?: boolean
}) => {
  const [tableData, setTableData] = useState<any[]>([])
  const [sortParams, setSortParams] = useState<any>()
  const [firstLoad, setFirstLoad] = useState(true)
  const [totalNumber, setTotalNumber] = useState(0)
  const [pageInfo, setPageInfo] = useState({ ...DEFAULT_PAGE })

  const tableDataTokenRef = useRef<CancelTokenSource>()
  const dispatch = useDispatch()

  const getData = async (params) => {
    switch (activeTab) {
      case DASHBOARD_TABS[0].key:
        return getExpiringContractList({
          params
        })
      case DASHBOARD_TABS[1].key:
        return getExpiringContractsSteps({
          params
        })
      case DASHBOARD_TABS[2].key:
        return getExpiringRFPSteps({
          params
        })
      default:
        return getExpiringContractList({
          params
        })
    }
  }

  const getTableData = ({ sortField, sortOrder, page }: any) => {
    dispatch(setLoading(true))
    if (!!tableDataTokenRef.current) {
      tableDataTokenRef.current.cancel()
    }
    const params: any = {}
    if (sortField) {
      if (sortOrder) {
        params.ordering = `${sortOrder === 'descend' ? '-' : ''}${sortField}`
      }
      setSortParams({
        sortField,
        sortOrder
      })
    }
    if (healthSystem) {
      params.health_system = healthSystem
    }
    if (user) {
      params.user = user
    }
    const dataPage = page ? page : pageInfo
    params.limit = dataPage.pageSize
    params.offset = (dataPage.pageNumber - 1) * dataPage.pageSize
    tableDataTokenRef.current = axios.CancelToken.source()

    getData(params)
      .then((resp) => {
        if (!resp.data?.results) {
          return
        }
        setTableData(
          resp.data.results.map((i) => ({
            ...i,
            key: i.uuid
          }))
        )
        setTotalNumber(resp.data.count)
        if (firstLoad) {
          setFirstLoad(false)
        }
      })
      .finally(() => dispatch(setLoading(false)))
  }

  const handleTableChange = (pagination, _filters, sorter) => {
    const page = {
      pageNumber: pagination.current,
      pageSize: pagination.pageSize
    }
    getTableData({
      sortField: sorter.field,
      sortOrder: sorter.order,
      page
    })
    setPageInfo(page)
  }
  const handleChangePageSize = (pageSize) => {
    const page = {
      pageNumber: 1,
      pageSize
    }
    getTableData({
      ...sortParams,
      page
    })
    setPageInfo(page)
  }

  useEffect(() => {
    const newPageInfo = { ...pageInfo, pageNumber: 1 }
    setPageInfo(newPageInfo)
    getTableData({ ...sortParams, page: newPageInfo })
  }, [healthSystem, user, activeTab])

  useEffect(() => {
    return () => {
      if (!!tableDataTokenRef.current) {
        tableDataTokenRef.current.cancel()
      }
    }
  }, [])

  return (
    <div
      className={classNames('table-wrapper', {
        'table-wrapper_collapse-top-margin': collapsingTopMargin
      })}
    >
      {!firstLoad && (
        <Table<any>
          dataSource={tableData}
          className="dashboard-table"
          columns={columns(activeTab)}
          onChange={handleTableChange}
          onChangePage={handleChangePageSize}
          pageSize={pageInfo.pageSize}
          pagination={{
            pageSize: pageInfo.pageSize,
            current: pageInfo.pageNumber,
            total: totalNumber
          }}
        />
      )}
    </div>
  )
}
