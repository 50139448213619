import { Chips } from 'components/Chips/Chips'
import { ReactComponent as Close16 } from 'assets/svg/Clos16.svg'
import IconNameItem from 'components/IconNameItem/IconNameItem'
import { Select } from 'components/Select'
import './style.scss'
import { useEffect, useState } from 'react'
import { LABELS } from '../../../constants'
import { IPartialUserInfo } from '../../../redux/store/user/types'
import { getCalendarEventAvailableParticipants } from 'pages/Calendar/api'

export function AddGuestsSelect({
  participants,
  isEdit,
  removeParticipant
}: {
  participants: IPartialUserInfo[]
  isEdit: boolean
  removeParticipant: (uuid: string) => void
}) {
  const [selectedGuests, setSelectedGuests] = useState<IPartialUserInfo[]>([])
  const onSelectGuests = (guests) => {
    setSearchText('')
    setSelectedGuests(
      guests.map((guest) => {
        const { firstName, lastName, src, roleName } = guest.label.props
        return {
          first_name: firstName,
          last_name: lastName,
          role: roleName,
          avatar: src,
          uuid: guest.value
        }
      })
    )
  }

  useEffect(() => {
    if (isEdit) setSelectedGuests([...participants])
  }, [participants, isEdit])

  const [searchText, setSearchText] = useState<string>('')

  return (
    <Select.DebounceMulti<IPartialUserInfo>
      onChange={onSelectGuests}
      onSearch={(v: string) => {
        setSearchText(v)
        return getCalendarEventAvailableParticipants({ search: v })
      }}
      propsItem={{ label: LABELS.ADD_GUESTS, name: 'participants' }}
      propsSelect={{
        mode: 'multiple',
        allowClear: true,
        value: selectedGuests.map((g) => {
          return {
            ...g,
            value: g.uuid
          }
        }),
        tagRender: (props) => {
          const { value } = props
          const { first_name: firstName, last_name: lastName } =
            selectedGuests.find((guest) => guest.uuid === value) ?? {}
          return (
            <Chips
              className="create-group-chat_chips mt-0 mr-4"
              closeIcon={<Close16 />}
              closable
              children={`${firstName} ${lastName}`}
              uuid={value}
              key={value}
              onClose={() => {
                const newGuestsList = selectedGuests.filter(
                  (guest) => guest.uuid !== value
                )
                setSelectedGuests(newGuestsList)
                removeParticipant(value)
              }}
            />
          )
        }
      }}
      valueKey={'uuid'}
      optionLabel={({
        first_name,
        last_name,
        avatar,
        role,
        health_system,
        email
      }) => {
        return (
          <IconNameItem
            highlightedText={searchText}
            tagText={health_system}
            firstName={first_name}
            lastName={last_name}
            email={email}
            src={avatar?.file_preview ?? null}
            roleName={role}
          />
        )
      }}
    />
  )
}
