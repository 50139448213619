import { Button, BUTTON_TYPES } from '../../../components/Button'
import { BTN_TXT, PLACEHOLDERS } from '../../../constants'
import { ReactComponent as Plus16 } from 'assets/svg/Plus16.svg'
import { ReactComponent as Search16 } from 'assets/svg/Search16.svg'
import { ReactComponent as Close16 } from 'assets/svg/Clos16.svg'
import { Popup } from '../../../components/Popup'
import { Typography, TYPOGRAPHY_WEIGHT } from '../../../components/Typography'
import { Input } from '../../../components/Input'
import { Table } from '../../../components/Table'
import { useEffect, useRef, useState } from 'react'
import {
  TContractFacilityUsersData,
  TContractFacilityUsersFilters,
  TContactFacilityUsersFiltersFunc
} from './types'
import { useDispatch, useSelector } from 'react-redux'
import { getUser } from '../../../redux/store/user/getters'
import { columns } from './constants'
import { DEFAULT_PAGE } from '../../../components/Table/constants'
import {
  THealthSystemTableRequestData,
  THealthSystemUsersParams
} from '../../HealthSystemUsers/types'
import { useDebounce } from '../../../hooks/hooks'
import { setLoading } from '../../../redux/store/common/slice'
import axios, { CancelTokenSource } from 'axios'
import { getAvailableFacilityUsers, updateFacilityUsers } from '../api'
import { useParams } from 'react-router-dom'
import { notification } from '../../../components/Notification'
import { useContractDetailsPopup } from '../Providers/ContractDetailsPopupProvider'

export type TProps = {
  updateAssignedValues: VoidFunction
  selected: string[]
}
const AddAction = ({ updateAssignedValues, selected }: TProps) => {
  const { addFacilityUsersPopup } = useContractDetailsPopup()
  const { state, actions } = addFacilityUsersPopup
  const { open, close } = actions
  const { visible } = state

  const user = useSelector(getUser)

  const dispatch = useDispatch()

  const { id } = useParams()

  const [filters, setFilters] = useState<TContractFacilityUsersFilters>({})
  const [selectedRowKeys, setSelectedRowKeys] = useState([])
  const [searchValue, setSearchValue] = useState('')
  const [totalNumber, setTotalNumber] = useState(0)
  const [tableData, setTableData] = useState<TContractFacilityUsersData[]>([])
  const [pageInfo, setPageInfo] = useState({ ...DEFAULT_PAGE })
  const [sortParams, setSortParams] = useState<THealthSystemTableRequestData>()
  const [firstLoad, setFirstLoad] = useState(true)

  const timeoutRef = useRef<ReturnType<typeof setTimeout>>()
  const tableDataTokenRef = useRef<CancelTokenSource>()

  const debouncedSearch = useDebounce(searchValue, 500)
  useEffect(() => {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current)
    }
    if (id && visible) {
      timeoutRef.current = setTimeout(
        () => {
          getTableData({ ...sortParams, ...filters, page: { ...DEFAULT_PAGE } })
          setPageInfo({ ...DEFAULT_PAGE })
        },
        firstLoad ? 0 : 500
      )
    }

    if (!visible) {
      setFirstLoad(true)
    }
  }, [debouncedSearch, id, visible])

  const getTableData = ({
    sortField,
    sortOrder,
    filters,
    page
  }: THealthSystemTableRequestData) => {
    dispatch(setLoading(true))
    setSelectedRowKeys([])
    if (firstLoad) {
      setFirstLoad(false)
    }
    if (!!tableDataTokenRef.current) {
      tableDataTokenRef.current.cancel()
    }
    const params: THealthSystemUsersParams = { ...filters }
    if (sortField) {
      if (sortOrder) {
        params.ordering = `${sortOrder === 'descend' ? '-' : ''}${sortField}`
      }
      setSortParams({
        sortField,
        sortOrder
      })
    }
    if (searchValue) {
      params.search = searchValue
    }
    const dataPage = page ? page : pageInfo
    params.limit = dataPage.pageSize
    params.offset = (dataPage.pageNumber - 1) * dataPage.pageSize
    tableDataTokenRef.current = axios.CancelToken.source()
    getAvailableFacilityUsers(id as string, {
      cancelToken: tableDataTokenRef.current?.token,
      params
    })
      .then((resp: any) => {
        if (!resp.data?.results) {
          return
        }
        setTableData(
          resp.data.results.map((i) => ({
            ...i,
            key: i.uuid
          }))
        )
        setTotalNumber(resp.data.count)
      })
      .finally(() => dispatch(setLoading(false)))
  }
  useEffect(() => {
    setLoading(false)
  }, [tableData[0]?.uuid as string])

  const addUsers = () => {
    dispatch(setLoading(true))
    updateFacilityUsers(id as string, {
      users: [...selectedRowKeys]
    })
      .then(() => {
        updateAssignedValues()
        close()
        notification.success({
          message: `User${selectedRowKeys.length > 1 ? 's' : ''} ${
            selectedRowKeys.length > 1 ? 'have' : 'has'
          } been successfully added.`
        })
      })
      .finally(() => dispatch(setLoading(false)))
  }

  const openAddFacilityUsersPopup = () => open()
  const closeAddFacilityUsersPopup = () => close()

  const handleSelectRow = (selectedRowKeys) =>
    setSelectedRowKeys(selectedRowKeys)

  const handleTableChange = (pagination, _filters, sorter) => {
    const page = {
      pageNumber: pagination.current,
      pageSize: pagination.pageSize
    }
    getTableData({
      sortField: sorter.field,
      sortOrder: sorter.order,
      filters,
      page
    })
    setPageInfo(page)
  }

  const applyFilters: TContactFacilityUsersFiltersFunc = (
    field,
    appliedFilters
  ) => {
    const newFilters = { ...filters, [field]: appliedFilters }
    setFilters(newFilters)
    getTableData({ ...sortParams, filters: newFilters })
  }

  const handleChangePageSize = (pageSize) => {
    const page = {
      pageNumber: 1,
      pageSize
    }
    getTableData({
      ...sortParams,
      filters,
      page
    })
    setPageInfo(page)
  }

  return (
    <>
      {!selected.length && (
        <Button
          type={BUTTON_TYPES.PRIMARY}
          icon={<Plus16 />}
          onClick={openAddFacilityUsersPopup}
          upperCase
        >
          {BTN_TXT.ADD_USER}
        </Button>
      )}
      <Popup
        visible={visible}
        onCancel={closeAddFacilityUsersPopup}
        title="Add facility users"
        width={1376}
        footer={
          <>
            <Button
              type={BUTTON_TYPES.DEFAULT}
              onClick={closeAddFacilityUsersPopup}
              upperCase
            >
              {BTN_TXT.CANCEL}
            </Button>
            <Button
              type={BUTTON_TYPES.PRIMARY}
              onClick={addUsers}
              disabled={!selectedRowKeys.length}
              upperCase
            >
              {BTN_TXT.ADD}
            </Button>
          </>
        }
      >
        <div className="table-popup">
          <div className="table-wrapper__header">
            {!!selectedRowKeys.length ? (
              <Typography.Body1
                className="table-wrapper__select-title"
                weight={TYPOGRAPHY_WEIGHT.SEMI_BOLD}
              >
                Selected {selectedRowKeys.length} of {totalNumber}
              </Typography.Body1>
            ) : (
              <Input
                className="allow-clear"
                propsInput={{
                  allowClear: { clearIcon: <Close16 /> },
                  placeholder: PLACEHOLDERS.SEARCH,
                  prefix: <Search16 />,
                  value: searchValue,
                  onChange: (e) => setSearchValue(e.target.value)
                }}
              />
            )}
          </div>
          <Table<TContractFacilityUsersData>
            dataSource={tableData}
            columns={columns({
              applyFilters,
              filters,
              community: user?.community || ''
            })}
            pageSize={pageInfo.pageSize}
            pagination={{
              pageSize: pageInfo.pageSize,
              current: pageInfo.pageNumber,
              total: totalNumber
            }}
            className="add-vendors-rfp-list-table"
            onChangePage={handleChangePageSize}
            onChange={handleTableChange}
            hasSearchOrFilters={true}
            rowSelection={{
              selectedRowKeys,
              onChange: handleSelectRow
            }}
          />
        </div>
      </Popup>
    </>
  )
}

export default AddAction
