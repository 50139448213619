import {
  Headline1,
  Headline2,
  Headline3,
  Headline4,
  Headline5,
  Headline6,
  Body1,
  Body2,
  Button,
  Label,
  Caption,
  HeadlineD4
} from './typograpy'

const Typography = (): string => 'Please, provide name component'

Typography.Headline1 = Headline1
Typography.Headline2 = Headline2
Typography.Headline3 = Headline3
Typography.Headline4 = Headline4
Typography.Headline5 = Headline5
Typography.Headline6 = Headline6
Typography.Body1 = Body1
Typography.Body2 = Body2
Typography.Button = Button
Typography.Label = Label
Typography.Caption = Caption
Typography.HeadlineD4 = HeadlineD4

export default Typography
