import { Button, BUTTON_TYPES } from '../../../components/Button'
import { ReactComponent as Redirect16 } from 'assets/svg/Redirect16.svg'
import { BTN_TXT } from '../../../constants'
import { TPropsLinkAccount } from '../types'
import { getLikToAccount } from '../../../pages/Financials/api'
import '../style.scss'
import { useDispatch } from 'react-redux'
import { setLoading } from '../../../redux/store/common/slice'
const LinkAccount = ({ type, uuid }: TPropsLinkAccount) => {
  const isAvailableStatus = true
  const dispatch = useDispatch()
  const handleBankAccount = () => {
    dispatch(setLoading(true))
    getLikToAccount({
      facility_id: uuid,
      facility_type: type
    })
      .then((res) => {
        window.open(res.data.url, '_blank')?.focus()
      })
      .finally(() => dispatch(setLoading(false)))
  }

  return (
    <div className="row link-bank-account">
      {isAvailableStatus ? (
        <Button
          type={BUTTON_TYPES.GHOST}
          onClick={handleBankAccount}
          className="link-bank-account__btn"
        >
          <div className="row">
            {BTN_TXT.LINKED_ACCOUNT} &nbsp;
            <Redirect16 />
          </div>
        </Button>
      ) : null}
    </div>
  )
}

export default LinkAccount
