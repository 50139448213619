import Default from './checkbox.default'
import { TCheckboxDefault as Props } from './types'
import { Group } from './checkbox.group'

const Checkbox = (props: Props) => <Default {...props} />

Checkbox.Default = Default
Checkbox.Group = Group

export default Checkbox
