import { BTN_TXT } from 'constants/txt'
import { Button, BUTTON_TYPES } from 'components/Button'
import { useContractDetailsPopup } from 'features/ContractDetails/Providers/ContractDetailsPopupProvider'
import { useContractDetailsContext } from 'features/ContractDetails/Providers/ContractDetailsContextProvider'
import {
  ACTIONS,
  contractSubject,
  SUBJECTS
} from 'features/ContractDetails/Abilities'
import { Can } from 'features/ContractDetails/Providers/ContractDetailsAbilityProvider'

export default () => {
  const contractDetailsContext = useContractDetailsContext()
  const { terminateContractPopup } = useContractDetailsPopup()

  const { details } = contractDetailsContext.state

  return (
    <Can I={ACTIONS.CANCEL} a={contractSubject(SUBJECTS.CONTRACT, details)}>
      <Button
        type={BUTTON_TYPES.DEFAULT}
        onClick={terminateContractPopup.actions.open}
        danger
        upperCase
      >
        {BTN_TXT.TERMINATE}
      </Button>
    </Can>
  )
}
