import Fetcher from 'services/Fetcher'
import { API_DICTIONARY, HTTP_METHODS } from 'constants/api'
import {
  CommonsCategoriesRequest,
  CommonsUsersRequest,
  CommonsUsersResponse
} from './types'
import { FilterListResponse } from '../../types'

const fetcher = new Fetcher({})

export const getCommonsUsers: CommonsUsersRequest = async (role, data) =>
  fetcher.request<unknown, CommonsUsersResponse>({
    url: `${API_DICTIONARY.MY_COMMUNITY}${API_DICTIONARY.USERS}${API_DICTIONARY.COMMONS}${role}/`,
    method: HTTP_METHODS.GET,
    ...data
  })

export const getContractCategoriesList: CommonsCategoriesRequest = async (
  role
) =>
  fetcher.request<unknown, FilterListResponse>({
    url: `${API_DICTIONARY.MY_COMMUNITY}${API_DICTIONARY.USERS}${API_DICTIONARY.COMMONS}${role}/${API_DICTIONARY.CONTRACT_CATEGORIES}`,
    method: HTTP_METHODS.GET
  })
