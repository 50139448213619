import {
  BTN_TXT,
  LABELS,
  TOptionsResponse,
  VALIDATION_MESSAGES
} from '../../../constants'
import { Button, BUTTON_TYPES } from '../../../components/Button'
import { TResendBid, TResendBidForm, TResendBidModalProps } from './types'
import { useRFPDetailsPopupContext } from '../RFPDetails/Providers/RFPDetailsPopupProvider'
import { Popup } from '../../../components/Popup'
import Typography from '../../../components/Typography/Typography'
import { useEffect, useMemo, useState } from 'react'
import { RFP_ACCEPTANCE_STATUSES, RFP_STATUSES } from '../constants'
import { getResendBidOptions, setResendBidReason } from './api'
import { Form, Radio, Space } from 'antd'
import cn from 'classnames'
import { TYPOGRAPHY_WEIGHT } from '../../../components/Typography'
import { validateMessages } from '../../../helper/common'
import { Input } from '../../../components/Input'
import './style.scss'
import { useDispatch } from 'react-redux'
import { setLoading } from '../../../redux/store/common/slice'
import { notification } from '../../../components/Notification'

export const ResendBidContainer = ({
  data,
  uuid,
  status,
  setVendorId
}: TResendBid) => {
  const { resendBidPopup } = useRFPDetailsPopupContext()
  const { actions } = resendBidPopup
  const handleModal = () => {
    setVendorId?.(data.vendor_id)
    actions.open()
  }
  const isAvailable = useMemo(() => {
    return (
      !data.has_resend &&
      (data.acceptance_status === RFP_ACCEPTANCE_STATUSES.DECLINED ||
        data.acceptance_status === RFP_ACCEPTANCE_STATUSES.OVERDUE ||
        (data.acceptance_status === RFP_ACCEPTANCE_STATUSES.ACCEPTED &&
          !!data.documents?.length &&
          status === RFP_STATUSES.PRICE_FILES_ANALYSIS))
    )
  }, [data])

  return (
    <div className="evaluate-container" key={uuid}>
      <Button
        type={BUTTON_TYPES.GHOST}
        onClick={handleModal}
        disabled={!isAvailable}
      >
        {BTN_TXT.RESEND_BID}
      </Button>
    </div>
  )
}
export const ResendBidModal = ({
  uuid,
  vendorId,
  callback
}: TResendBidModalProps) => {
  const { resendBidPopup } = useRFPDetailsPopupContext()
  const dispatch = useDispatch()
  const { state, actions } = resendBidPopup
  const [form] = Form.useForm()
  const [options, setOptions] = useState<TOptionsResponse[]>([])
  const reason = Form.useWatch('reason', form)
  const reasonText = Form.useWatch('reason_other_text', form)
  const getOptions = async () => {
    const res = await getResendBidOptions()
    setOptions(res?.data?.results)
  }
  useEffect(() => {
    getOptions()
  }, [])

  const onSubmitForm = (values: TResendBidForm) => {
    dispatch(setLoading(true))
    const data = { reason: values.reason, vendor: vendorId }
    if (values.reason === 'other') {
      data['reason_other_text'] = values.reason_other_text
    }
    setResendBidReason(uuid, data)
      .then(() => {
        form.resetFields()
        resendBidPopup.actions.close()
        notification.success({
          message: VALIDATION_MESSAGES.SM00119
        })
        callback()
      })
      .finally(() => dispatch(setLoading(false)))
  }
  const isAvailable = useMemo(() => {
    if (reason === 'other') {
      return !!reasonText
    } else return !!reason
  }, [reason, reasonText])

  const handleClose = () => {
    form.resetFields()
    actions.close()
  }
  return (
    <div className="evaluate-modal">
      <Popup
        visible={state.visible}
        onCancel={handleClose}
        title={state.title}
        width={442}
        footer={
          <>
            <Button type={BUTTON_TYPES.DEFAULT} onClick={handleClose} upperCase>
              {BTN_TXT.CANCEL}
            </Button>
            <Button
              disabled={!isAvailable}
              type={BUTTON_TYPES.PRIMARY}
              form="resend-bid-modal"
              htmlType="submit"
              upperCase
            >
              {BTN_TXT.RESEND_BID}
            </Button>
          </>
        }
      >
        <Typography.Body1>
          Please provide a reason for resending:
        </Typography.Body1>
        <Form<TResendBidForm>
          form={form}
          id="resend-bid-modal"
          className={cn('resend-bid-modal')}
          layout="vertical"
          onFinish={onSubmitForm}
          validateMessages={validateMessages}
        >
          <Form.Item label={''} name="reason" colon={false}>
            <Radio.Group className={cn('radio-group-default', 'mt-16')}>
              <Space direction="vertical">
                <>
                  {options.map((i) => {
                    return (
                      <>
                        <Radio value={i.id} className="row align-start">
                          <Typography.Body1
                            weight={TYPOGRAPHY_WEIGHT.NORMAL}
                            className={cn('run-new-step-option')}
                          >
                            {i.value}
                          </Typography.Body1>
                        </Radio>
                        {i.id === 'other' && reason === 'other' ? (
                          <Input.TextArea
                            propsItem={{
                              label: LABELS.REASON,
                              name: 'reason_other_text',
                              rules: [
                                {
                                  max: 200
                                }
                              ]
                            }}
                            propsTextArea={{
                              showCount: true,
                              maxLength: 200
                            }}
                          />
                        ) : null}
                      </>
                    )
                  })}
                </>
              </Space>
            </Radio.Group>
          </Form.Item>
        </Form>
      </Popup>
    </div>
  )
}
