import { Radio } from 'antd'
import cn from 'classnames'
import { Typography } from 'components/Typography/index'
import { Tooltip } from 'components/Tooltip/Tooltip'
import { Props, TRadioBTNOption } from 'components/RadioGroup/types'
import './radioGroup.timePeriod.scss'

const TimePeriod = ({
  className,
  title,
  options,
  tooltipTitle,
  tooltipWidth,
  ...props
}: Props) => (
  <div className="radio-group-timePeriod-title">
    {title && <Typography.Body2>{title}</Typography.Body2>}
    <Radio.Group className={cn('radio-group-timePeriod', className)} {...props}>
      {options.map((option: TRadioBTNOption, idx) => (
        <Tooltip
          title={tooltipTitle}
          key={idx}
          overlayInnerStyle={{
            display: option.disabled ? 'block' : 'none',
            width: tooltipWidth
          }}
        >
          <Radio.Button {...option}>{option.label}</Radio.Button>
        </Tooltip>
      ))}
    </Radio.Group>
  </div>
)

export default TimePeriod
