export const contractPageRoute = {
  contracts: 'my_contracts',
  cognusRepository: 'cognus_repository',
  contractPipeline: 'contract_pipeline',
  opportunityAnalysis: 'opportunity_analysis'
}

export const CONTRACT_PAGE_TABS_COMMUNITY = [
  {
    key: contractPageRoute.contracts,
    label: 'My contracts'
  },
  {
    key: contractPageRoute.cognusRepository,
    label: 'Cognus repository'
  },
  {
    key: contractPageRoute.contractPipeline,
    label: 'Contract pipeline'
  }
]
export const CONTRACT_PAGE_TABS_COMMONS = [
  {
    key: contractPageRoute.contracts,
    label: 'My contracts'
  },
  {
    key: contractPageRoute.opportunityAnalysis,
    label: 'Opportunity analysis'
  },
  {
    key: contractPageRoute.contractPipeline,
    label: 'Contract pipeline'
  }
]
