import { useDispatch } from 'react-redux'
import { Typography, TYPOGRAPHY_WEIGHT } from 'components/Typography'
import { ReactComponent as Close16Icon } from 'assets/svg/Clos16.svg'
import './style.scss'
import { resetMessageToReply } from 'redux/store/chatHistory/slice'
import cn from 'classnames'
import { TProps } from './types'

const MessageToReply = ({ isView = true, full_name, body, uuid }: TProps) => {
  const dispatch = useDispatch()

  const handleClose = () => {
    dispatch(resetMessageToReply())
  }

  return uuid ? (
    <div
      key={uuid}
      className={cn('message-to-reply-container row', { withBG: !isView })}
    >
      <div className="message-to-reply-container-wrapper">
        <Typography.Label
          weight={TYPOGRAPHY_WEIGHT.SEMI_BOLD}
          className="message-to-reply-container-wrapper__author"
        >
          {full_name}
        </Typography.Label>
        <div className="message-to-reply-container-wrapper__message">
          <Typography.Body1>
            <p className="replied-message-container">{body}</p>
          </Typography.Body1>
        </div>
      </div>
      {!isView && (
        <div className="message-to-reply-container-close" onClick={handleClose}>
          <Close16Icon />
        </div>
      )}
    </div>
  ) : null
}

export default MessageToReply
