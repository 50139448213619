import React, { ErrorInfo, ReactElement, ReactNode } from 'react'
import Logger from 'services/Logger'

type Props = {
  children: ReactNode | ReactNode[]
  logger: Logger
}

type State = {
  hasError: boolean
}

const ApplicationError = (): ReactElement => (
  <h1>Oops... Something went wrong.</h1>
)

class ErrorBoundary extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props)
    this.state = { hasError: false }
  }

  static getDerivedStateFromError(): State {
    return { hasError: true }
  }

  componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    const { logger } = this.props
    // Here we should provide all info for debug
    logger.error(error, errorInfo)
  }

  render(): ReactNode {
    const { hasError } = this.state
    if (hasError) {
      return (
        <>
          <ApplicationError />
        </>
      )
    }
    const { children } = this.props
    return children
  }
}

export default ErrorBoundary
