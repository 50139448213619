import {
  ALL_ITEMS_PARAMS,
  API_DICTIONARY,
  HTTP_METHODS,
  NOTIFICATIONS,
  ResponseWithPagination
} from '../../constants'
import Fetcher from 'services/Fetcher'
import { ChatMember, IChatDetails } from 'redux/store/chatDetails/types'
import { TChatDetails } from 'features/ChatDetails/types'
import { TQueryParams } from 'components/Table/types'

const fetcher = new Fetcher({})

export const getChatDetails = async (id: string) =>
  fetcher.request<unknown, IChatDetails>({
    url: `${API_DICTIONARY.CHATS}${id}/`,
    method: HTTP_METHODS.GET
  })

export const updateChatDetails = async (id: string, data: TChatDetails) =>
  fetcher.request<TChatDetails, any>({
    url: `${API_DICTIONARY.CHATS}${id}/`,
    method: HTTP_METHODS.PATCH,
    successMessage: NOTIFICATIONS.GROUP_CHAT_UPDATED,
    data
  })

export const getChatMembers = async (
  id: string,
  params: TQueryParams = ALL_ITEMS_PARAMS
) =>
  fetcher.request<unknown, ResponseWithPagination<ChatMember>>({
    url: `${API_DICTIONARY.CHATS}${id}/${API_DICTIONARY.MEMBERS}`,
    method: HTTP_METHODS.GET,
    params
  })
