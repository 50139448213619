import { API_DICTIONARY, HTTP_METHODS } from '../../constants'
import Fetcher from '../../services/Fetcher'
import { TStakeholdersFormRequest, TStakeholdersListResponse } from './types'

const fetcher = new Fetcher({})
export const getStakeholdersList = async () =>
  fetcher.request<any, TStakeholdersListResponse>({
    url: `${API_DICTIONARY.ANALYSIS}${API_DICTIONARY.STAKEHOLDER_ASSIGNMENT}`,
    method: HTTP_METHODS.GET
  })
export const setStakeholdersList = async (data: TStakeholdersFormRequest) =>
  fetcher.request<unknown, TStakeholdersListResponse>({
    url: `${API_DICTIONARY.ANALYSIS}${API_DICTIONARY.STAKEHOLDER_ASSIGNMENT}`,
    method: HTTP_METHODS.POST,
    data,
    successMessage: 'Clinical review has been updated'
  })
