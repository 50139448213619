import { useLayoutEffect, useMemo } from 'react'

import { BTN_TXT } from 'constants/txt'
import { Popup } from 'components/Popup'
import { Typography } from 'components/Typography'
import { Button, BUTTON_TYPES } from 'components/Button'
import { useContractDetailsContext } from 'features/ContractDetails/Providers/ContractDetailsContextProvider'
import { useContractDetailsPopup } from 'features/ContractDetails/Providers/ContractDetailsPopupProvider'
import { CONTRACT_STATUS } from 'features/ContractDetails/constants'

export default () => {
  const contractDetailsContext = useContractDetailsContext()
  const { terminateContractPopup } = useContractDetailsPopup()

  const { details } = contractDetailsContext.state
  const { updateContractDetailsStatusAsync } = contractDetailsContext.actions

  const { setState, close } = terminateContractPopup.actions

  const handleClickTerminateContract = () => {
    updateContractDetailsStatusAsync(CONTRACT_STATUS.TERMINATED).finally(() =>
      terminateContractPopup.actions.close()
    )
  }

  const Footer = useMemo(
    () => (
      <>
        <Button type={BUTTON_TYPES.DEFAULT} onClick={close} upperCase>
          {BTN_TXT.BACK_TO_DETAILS}
        </Button>
        <Button
          type={BUTTON_TYPES.PRIMARY}
          onClick={handleClickTerminateContract}
          danger
          upperCase
        >
          {BTN_TXT.TERMINATE_CONTRACT}
        </Button>
      </>
    ),
    [close, handleClickTerminateContract]
  )

  useLayoutEffect(() => {
    setState((prevState) => ({
      ...prevState,
      title: `Terminate ${details.name}?`
    }))
  }, [details.name, setState])

  return (
    <Popup {...terminateContractPopup.state} width={442} footer={Footer}>
      <Typography.Body1>
        All the contract progress will be stopped. All the related users and
        vendors will be notified about termination. Do you really want to
        terminate it?
      </Typography.Body1>
    </Popup>
  )
}
