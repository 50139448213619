import { useState } from 'react'
import { useDispatch } from 'react-redux'
import { Form } from 'antd'
import { Button, BUTTON_TYPES } from 'components/Button'
import Popup from 'components/Popup/Popup'
import Input from 'components/Input/Input'
import { fetchDepartments } from 'pages/CRUDUser/api'
import { addDepartment, deleteDepartment, editDepartment } from './api'
import { setLoading } from 'redux/store/common/slice'
import { Can, ACTIONS, SUBJECTS } from 'features/Permission'
import { BTN_TXT } from 'constants/txt'
import { ReactComponent as Edit16 } from 'assets/svg/Edit16.svg'
import { ReactComponent as Delete16 } from 'assets/svg/Delete16.svg'
import { ReactComponent as Plus16 } from 'assets/svg/Plus16.svg'
import { TDepartment } from './types'
import './styles.scss'

export const DepartmentsManagement = ({
  initStateDepartments,
  communityUUID,
  HSUUID,
  HSName,
  onClose
}: {
  HSName: string
  onClose: VoidFunction
  initStateDepartments: []
  communityUUID: string
  HSUUID: string
}) => {
  const [isOpen, setIsOpen] = useState(false)
  const [isDeleteOpen, setIsDeleteOpen] = useState(false)
  const [isEditOpen, setIsEditOpen] = useState(false)
  const [isAddOpen, setIsAddOpen] = useState(false)
  const [departments, setDepartments] = useState(initStateDepartments)
  const [currDepartment, setCurrDepartment] = useState({} as TDepartment)
  const dispatch = useDispatch()
  const updateDepartmentsList = () => {
    fetchDepartments(communityUUID, HSUUID).then((data) =>
      setDepartments(
        data?.data?.results.filter((department) => !department.is_default)
      )
    )
  }
  const deleteDepartmentPopupAction = () => {
    dispatch(setLoading(true))
    deleteDepartment(communityUUID, HSUUID, currDepartment?.uuid)
      .then(() => {
        setCurrDepartment({} as TDepartment)
        updateDepartmentsList()
        setIsDeleteOpen(false)
      })
      .finally(() => dispatch(setLoading(false)))
  }
  const editDepartmentAction = (values) => {
    dispatch(setLoading(true))
    editDepartment(communityUUID, HSUUID, currDepartment.uuid, values)
      .then(() => {
        setCurrDepartment({} as TDepartment)
        updateDepartmentsList()
        setIsEditOpen(false)
      })
      .finally(() => dispatch(setLoading(false)))
  }
  const addDepartmentAction = (values) => {
    dispatch(setLoading(true))
    addDepartment(communityUUID, HSUUID, values)
      .then(() => {
        setCurrDepartment({} as TDepartment)
        updateDepartmentsList()
        setIsAddOpen(false)
      })
      .finally(() => dispatch(setLoading(false)))
  }
  const renderDepartment = ({ name, uuid }: { name: string; uuid: string }) => {
    const onEdit = () => {
      setCurrDepartment({ name, uuid })
      setIsEditOpen(true)
    }
    const onDelete = () => {
      setCurrDepartment({ name, uuid })
      setIsDeleteOpen(true)
    }
    return (
      <div className="departments-management_department-container">
        <div className="departments-management_department">{name}</div>
        <div className="departments-management_department-buttons">
          <Button.Icon
            type={BUTTON_TYPES.LINK}
            onClick={() => onEdit()}
            icon={<Edit16 />}
          />
          <Button.Icon
            type={BUTTON_TYPES.LINK}
            onClick={() => onDelete()}
            icon={<Delete16 />}
          />
        </div>
      </div>
    )
  }
  const afterClose = () => {
    setCurrDepartment({} as TDepartment)
    onClose()
  }
  return (
    <>
      <Can I={ACTIONS.CRUD} a={SUBJECTS.DEPARTMENTS}>
        <Button.Icon
          className="departments-management_open-btn"
          type={BUTTON_TYPES.LINK}
          onClick={() => setIsOpen(true)}
          icon={<Edit16 />}
        />
      </Can>
      <Popup
        visible={isOpen}
        afterClose={afterClose}
        onCancel={() => setIsOpen(false)}
        className="departments-management_popup"
        title="Edit custom department list"
        width={447}
        footer={null}
      >
        {!!departments.length && (
          <div className="departments-management_departments-list">
            {departments.map((department) => renderDepartment(department))}
          </div>
        )}
        <Button
          type={BUTTON_TYPES.GHOST}
          className="departments-management_add-btn user-form_add-hospital"
          icon={<Plus16 />}
          onClick={() => setIsAddOpen(true)}
          upperCase
        >
          add department
        </Button>
      </Popup>
      <Popup
        afterClose={() => setCurrDepartment({} as TDepartment)}
        visible={isDeleteOpen}
        onCancel={() => setIsDeleteOpen(false)}
        title={`Delete ${currDepartment?.name}`}
        width={442}
        footer={
          <>
            <Button
              onClick={() => setIsDeleteOpen(false)}
              type={BUTTON_TYPES.DEFAULT}
              upperCase
            >
              {BTN_TXT.CANCEL}
            </Button>
            <Button
              type={BUTTON_TYPES.PRIMARY}
              onClick={() => deleteDepartmentPopupAction()}
              upperCase
              danger
            >
              {BTN_TXT.DELETE}
            </Button>
          </>
        }
      >
        <div>
          The department list will be deleted from {HSName} and from the related
          Hospitals and users.
        </div>
      </Popup>
      <Popup
        afterClose={() => setCurrDepartment({} as TDepartment)}
        visible={isAddOpen || isEditOpen}
        onCancel={() => {
          setIsAddOpen(false)
          setIsEditOpen(false)
        }}
        title={`${isAddOpen ? 'Add' : 'Edit'} department`}
        width={442}
        footer={
          <>
            <Button
              onClick={() =>
                isAddOpen ? setIsAddOpen(false) : setIsEditOpen(false)
              }
              type={BUTTON_TYPES.DEFAULT}
              upperCase
            >
              {BTN_TXT.CANCEL}
            </Button>
            <Button
              htmlType="submit"
              form="add-edit-department-form"
              type={BUTTON_TYPES.PRIMARY}
              upperCase
            >
              {BTN_TXT.ADD}
            </Button>
          </>
        }
        className="departments-management_popup"
      >
        <Form<{ name: string }>
          layout="vertical"
          onFinish={isAddOpen ? addDepartmentAction : editDepartmentAction}
          initialValues={{ name: currDepartment.name }}
          id="add-edit-department-form"
        >
          <Input
            propsItem={{
              label: 'Department name',
              name: 'name',
              rules: [
                {
                  max: 100,
                  required: true
                }
              ]
            }}
            propsInput={{
              showCount: true,
              maxLength: 100
            }}
          />
        </Form>
      </Popup>
    </>
  )
}
