import { ReactComponent as Plus16 } from 'assets/svg/Plus16.svg'

import { BTN_TXT } from 'constants/txt'
import { Button, BUTTON_TYPES } from 'components/Button'
import { Props } from './types'

export default (props: Props) => {
  const { addMembersPopup } = props

  return (
    <Button
      className="add-chat-member__action"
      icon={<Plus16 />}
      type={BUTTON_TYPES.DEFAULT}
      onClick={addMembersPopup.actions.open}
      upperCase
    >
      {BTN_TXT.ADD_MEMBERS}
    </Button>
  )
}
