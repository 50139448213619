import Typography from '../../components/Typography/Typography'
import { ReactComponent as EmptyBox } from 'assets/svg/EmptyBox.svg'

const EmptyState = () => {
  return (
    <div className="clinical-review-container__empty-state">
      <div className="clinical-review-container__empty-state__wrapper">
        <EmptyBox />
        <Typography.Headline6 className="mt-16">
          There are no active requests for clinical review
        </Typography.Headline6>
      </div>
    </div>
  )
}

export default EmptyState
