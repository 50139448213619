import { STATUS_TYPES_CLASSNAMES } from 'components/Status/constants'

export const RFP_STATUSES = {
  CREATED: 'created',
  VENDOR_ACCEPTANCE: 'vendor_acceptance',
  BIDDING_QA: 'bidding_qa',
  BIDDING_IN_PROGRESS: 'bidding_in_progress',
  PRICE_FILES_ANALYSIS: 'price_files_analysis',
  VOTING_IN_PROGRESS: 'voting_in_progress',
  VENDORS_SELECTION: 'vendors_selection',
  CONTRACTING: 'contracting',
  ARCHIVED: 'archived',
  CANCELED: 'canceled',
  OUT_FOR_SIGNATURE: 'out_for_signature',
  WAITING_FOR_ASSIGNMENT: 'waiting_for_assignment',
  IN_CS_QUEUE: 'in_cs_queue'
} as const

export const RFP_ACCEPTANCE_STATUSES = {
  ACCEPTED: 'accepted',
  DECLINED: 'declined',
  OVERDUE: 'overdue'
} as const

export const RFP_ACCEPTANCE_STATUSES_NAMES = {
  accepted: 'Accepted',
  declined: 'Declined',
  overdue: 'Overdue'
} as const

export const RFP_STATUSES_NAMES = {
  created: 'Created',
  vendor_acceptance: 'Vendor acceptance',
  bidding_qa: 'Bidding Q&A',
  bidding_in_progress: 'Bidding in progress',
  price_files_analysis: 'Price file analysis',
  voting_in_progress: 'Voting in progress',
  vendors_selection: 'Vendors selection',
  contracting: 'Contracting',
  archived: 'Archived',
  canceled: 'Canceled',
  out_for_signature: 'Out for signature',
  waiting_for_assignment: 'Waiting for assignment',
  in_cs_queue: 'In CS queue'
} as const

export const RFP_STATUSES_OPTIONS = [
  {
    label: 'Created',
    value: 'created'
  },
  {
    label: 'Waiting for assignment',
    value: 'waiting_for_assignment'
  },
  {
    label: 'In CS queue',
    value: 'in_cs_queue'
  },
  {
    label: 'Vendor acceptance',
    value: 'vendor_acceptance'
  },
  {
    label: 'Bidding Q&A',
    value: 'bidding_qa'
  },
  {
    label: 'Bidding in progress',
    value: 'bidding_in_progress'
  },
  {
    label: 'Price file analysis',
    value: 'price_files_analysis'
  },
  {
    label: 'Voting in progress',
    value: 'voting_in_progress'
  },
  {
    label: 'Vendors selection',
    value: 'vendors_selection'
  },
  {
    label: 'Archived',
    value: 'archived'
  },
  {
    label: 'Canceled',
    value: 'canceled'
  }
]

export const RFP_STATUS_VALUES = {
  created: 'created',
  vendor_acceptance: 'vendor_acceptance',
  bidding_qa: 'bidding_qa',
  bidding_in_progress: 'bidding_in_progress',
  price_files_analysis: 'price_files_analysis',
  voting_in_progress: 'voting_in_progress',
  vendors_selection: 'vendors_selection',
  contracting: 'contracting',
  archived: 'archived',
  canceled: 'canceled',
  out_for_signature: 'out_for_signature',
  waiting_for_assignment: 'waiting_for_assignment',
  in_cs_queue: 'in_cs_queue'
}

export const RFP_STATUS_TO_CLASSNAME = {
  created: STATUS_TYPES_CLASSNAMES.GREEN,
  Accepted: STATUS_TYPES_CLASSNAMES.GREEN,
  vendor_acceptance: STATUS_TYPES_CLASSNAMES.GRAY,
  bidding_qa: STATUS_TYPES_CLASSNAMES.LIGHTBLUE,
  bidding_in_progress: STATUS_TYPES_CLASSNAMES.YELLOW,
  price_files_analysis: STATUS_TYPES_CLASSNAMES.DARKBLUE,
  voting_in_progress: STATUS_TYPES_CLASSNAMES.YELLOW,
  vendors_selection: STATUS_TYPES_CLASSNAMES.DARKBLUE,
  contracting: STATUS_TYPES_CLASSNAMES.BLACK,
  archived: STATUS_TYPES_CLASSNAMES.RED,
  canceled: STATUS_TYPES_CLASSNAMES.RED,
  Declined: STATUS_TYPES_CLASSNAMES.RED,
  waiting_for_assignment: STATUS_TYPES_CLASSNAMES.RED,
  in_cs_queue: STATUS_TYPES_CLASSNAMES.DARKGRAY
}

export const RFP_ACCEPTANCE_STATUS_TO_CLASSNAME = {
  accepted: STATUS_TYPES_CLASSNAMES.GREEN,
  declined: STATUS_TYPES_CLASSNAMES.RED,
  overdue: STATUS_TYPES_CLASSNAMES.GRAY
}

export const RFP_STATUSES_TO_HIDE_BUTTONS_FOR_VENDOR = [
  RFP_STATUSES.BIDDING_IN_PROGRESS,
  RFP_STATUSES.PRICE_FILES_ANALYSIS,
  RFP_STATUSES.VOTING_IN_PROGRESS,
  RFP_STATUSES.VENDORS_SELECTION
] as const

export const RFP_STATUSES_FOR_CANCEL_CLC = [
  RFP_STATUSES.VENDOR_ACCEPTANCE,
  RFP_STATUSES.BIDDING_QA,
  RFP_STATUSES.BIDDING_IN_PROGRESS,
  RFP_STATUSES.PRICE_FILES_ANALYSIS,
  RFP_STATUSES.VOTING_IN_PROGRESS,
  RFP_STATUSES.VENDORS_SELECTION,
  RFP_STATUSES.CONTRACTING,
  RFP_STATUSES.OUT_FOR_SIGNATURE,
  RFP_STATUSES.IN_CS_QUEUE
]
