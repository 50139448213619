import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { moduleName } from './constants'
import { ICommunityInfoState, ICommunityInfo } from './types'
import {
  getCommunityInfo as fetchCommunityInfoAPI,
  updateCommunityInfo as updateCommunityInfoAPI
} from 'features/ChapterInfo/api'

const initialState: ICommunityInfoState = {
  communityInfo: {
    name: '',
    description: '',
    contact_person: {
      avatar_logo: {},
      first_name: '',
      last_name: '',
      role: '',
      uuid: ''
    }
  },
  isCommunityInfoLoading: false,
  isCommunityInfoLoaded: false,
  error: null
}

const CommunityInfoSlice = createSlice({
  name: moduleName,
  initialState,
  reducers: {
    getCommunityInfoStart(state: ICommunityInfoState) {
      state.isCommunityInfoLoading = true
      state.error = null
    },
    getCommunityInfoFinish(state: ICommunityInfoState) {
      state.isCommunityInfoLoading = false
      state.isCommunityInfoLoaded = true
    },
    setCommunityInfoError(
      state: ICommunityInfoState,
      action: PayloadAction<{ error: string }>
    ) {
      const { error } = action.payload
      state.error = error
    },
    setCommunityInfoAction(
      state: ICommunityInfoState,
      action: PayloadAction<{ communityInfo: ICommunityInfo }>
    ) {
      const { communityInfo } = action.payload
      state.communityInfo = communityInfo
    },
    updateCommunityInfoAction(
      state: ICommunityInfoState,
      action: PayloadAction<{ updatedCommunityInfo: ICommunityInfoState }>
    ) {
      const { updatedCommunityInfo } = action.payload
      state.communityInfo = {
        ...(state.communityInfo || {}),
        ...updatedCommunityInfo
      }
    },
    resetCommunityInfoAction(state: ICommunityInfoState) {
      Object.keys(initialState).forEach(
        (key) =>
          ((state[key] as ICommunityInfoState[typeof key]) = initialState[key])
      )
    }
  }
})

export default CommunityInfoSlice.reducer
export const {
  getCommunityInfoStart,
  setCommunityInfoError,
  getCommunityInfoFinish,
  setCommunityInfoAction,
  updateCommunityInfoAction,
  resetCommunityInfoAction
} = CommunityInfoSlice.actions

export const fetchCommunityInfo =
  (community: string, completedCallback?: Function): any =>
  async (dispatch) => {
    try {
      dispatch(getCommunityInfoStart())
      const result = await fetchCommunityInfoAPI(community)
      if (result) {
        dispatch(
          setCommunityInfoAction({
            communityInfo: result.data as ICommunityInfo
          })
        )
        if (completedCallback) {
          completedCallback(result.data)
        }
      }
    } catch (err: any) {
      dispatch(
        setCommunityInfoError({ error: err?.response.message as string })
      )
    } finally {
      dispatch(getCommunityInfoFinish())
    }
  }
export const updateCommunityInfo =
  (
    data: ICommunityInfo,
    community: string,
    completedCallback?: Function
  ): any =>
  async (dispatch) => {
    try {
      dispatch(getCommunityInfoStart())
      const result = await updateCommunityInfoAPI(data, community)
      if (result) {
        dispatch(
          setCommunityInfoAction({ communityInfo: data as ICommunityInfo })
        )
        if (completedCallback) {
          completedCallback(result.data)
        }
      }
    } catch (err: any) {
      dispatch(
        setCommunityInfoError({
          error: 'community with this name already exists.' as string
        })
      )
    } finally {
      dispatch(getCommunityInfoFinish())
    }
  }
