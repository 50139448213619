import {
  API_DICTIONARY,
  HTTP_METHODS,
  ResponseWithPagination
} from '../../constants'
import Fetcher from '../../services/Fetcher'
import { ICPRtDetails } from './types'
import {
  ICPRHealthSystem,
  TGetCPRHealthSystemsRequest
} from './HealthSystemList/types'
import { TRFPResponse } from '../RFP/RFPDetails/types'

const fetcher = new Fetcher({})

export const getCPRDetailsRequestAsync = async (id: string) =>
  fetcher.request<unknown, ICPRtDetails>({
    url: `${API_DICTIONARY.CONTRACTS}${API_DICTIONARY.PIPELINES}${id}/`,
    method: HTTP_METHODS.GET
  })

export const getCPRHealthSystems: TGetCPRHealthSystemsRequest = async (
  id,
  data
) =>
  fetcher.request<unknown, ResponseWithPagination<ICPRHealthSystem>>({
    url: `${API_DICTIONARY.CONTRACTS}${API_DICTIONARY.PIPELINES}${id}/${API_DICTIONARY.HEALTH_SYSTEMS}`,
    method: HTTP_METHODS.GET,
    ...data
  })

export const joinContractRequest = async (id: string) =>
  fetcher.request<unknown, ICPRtDetails>({
    url: `${API_DICTIONARY.CONTRACTS}${API_DICTIONARY.PIPELINES}${id}/${API_DICTIONARY.JOIN}`,
    method: HTTP_METHODS.POST
  })

export const optOutContractRequest = async (id: string) =>
  fetcher.request<unknown, ICPRtDetails>({
    url: `${API_DICTIONARY.CONTRACTS}${API_DICTIONARY.PIPELINES}${id}/${API_DICTIONARY.OPT_OUT}`,
    method: HTTP_METHODS.POST
  })

export const deletePipelineRecord = async (id: string) =>
  fetcher.request<unknown, TRFPResponse>({
    url: `${API_DICTIONARY.CONTRACTS}${API_DICTIONARY.PIPELINES}${id}/`,
    method: HTTP_METHODS.DELETE
  })

export const deleteHealthSystemRecord = async (uuid: string, hsId: string) =>
  fetcher.request<unknown, TRFPResponse>({
    url: `${API_DICTIONARY.CONTRACTS}${API_DICTIONARY.PIPELINES}${uuid}/${API_DICTIONARY.HEALTH_SYSTEMS}${hsId}/`,
    method: HTTP_METHODS.DELETE
  })
