import Container from './Container/Container'
import General from './General/General'
import { AbilityProvider, ContextProvider } from './Providers'

export const VendorCreation = () => (
  <AbilityProvider>
    <ContextProvider>
      <Container>
        <General />
      </Container>
    </ContextProvider>
  </AbilityProvider>
)
