import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { moduleName } from './constants'
import { IVendorInfoState, IVendorInfo, IVendorInfoErrors } from './types'
import {
  getVendorInfo as fetchVendorInfoAPI,
  updateVendorInfo as updateVendorInfoAPI
} from 'features/VendorInfo/api'
import { TAddress } from 'router/types'
import { notification } from '../../../components/Notification'

const initialState: IVendorInfoState = {
  vendorInfo: {
    uuid: '',
    name: '',
    description: '',
    phone_number: '',
    address: {} as TAddress,
    vendor_permission: 'full_access'
  },
  isVendorInfoLoading: false,
  isVendorInfoLoaded: false,
  error: null
}

const VendorInfoSlice = createSlice({
  name: moduleName,
  initialState,
  reducers: {
    getVendorInfoStart(state: IVendorInfoState) {
      state.isVendorInfoLoading = true
      state.error = null
    },
    getVendorInfoFinish(state: IVendorInfoState) {
      state.isVendorInfoLoading = false
      state.isVendorInfoLoaded = true
    },
    setVendorInfoError(
      state: IVendorInfoState,
      action: PayloadAction<{ error: IVendorInfoErrors }>
    ) {
      const { error } = action.payload
      state.error = error
    },
    setVendorInfoAction(
      state: IVendorInfoState,
      action: PayloadAction<{ vendorInfo: IVendorInfo }>
    ) {
      const { vendorInfo } = action.payload
      state.vendorInfo = vendorInfo
    },
    updateVendorInfoAction(
      state: IVendorInfoState,
      action: PayloadAction<{ updatedVendorInfo: IVendorInfo }>
    ) {
      const { updatedVendorInfo } = action.payload
      state.vendorInfo = {
        ...(state.vendorInfo || {}),
        ...updatedVendorInfo
      }
    },
    resetVendorInfoAction(state: IVendorInfoState) {
      Object.keys(initialState).forEach(
        (key) =>
          ((state[key] as IVendorInfoState[typeof key]) = initialState[key])
      )
    }
  }
})

export default VendorInfoSlice.reducer
export const {
  getVendorInfoStart,
  setVendorInfoError,
  getVendorInfoFinish,
  setVendorInfoAction,
  updateVendorInfoAction,
  resetVendorInfoAction
} = VendorInfoSlice.actions

export const fetchVendorInfo =
  (Vendor: string, completedCallback?: Function): any =>
  async (dispatch) => {
    try {
      dispatch(getVendorInfoStart())
      const result = await fetchVendorInfoAPI(Vendor)
      if (result) {
        dispatch(
          setVendorInfoAction({ vendorInfo: result.data as IVendorInfo })
        )
        if (completedCallback) {
          completedCallback(result.data)
        }
      }
    } catch (err: any) {
      dispatch(
        setVendorInfoError({ error: err?.response as IVendorInfoErrors })
      )
    } finally {
      dispatch(getVendorInfoFinish())
    }
  }
export const updateVendorInfo =
  (data: IVendorInfo, Vendor: string, completedCallback?: Function): any =>
  async (dispatch) => {
    try {
      dispatch(getVendorInfoStart())
      const result = await updateVendorInfoAPI(data, Vendor)
      if (result) {
        dispatch(setVendorInfoAction({ vendorInfo: data }))
        if (completedCallback) {
          completedCallback(result.data)
        }
      }
    } catch (err: any) {
      dispatch(
        setVendorInfoError({ error: err?.response as IVendorInfoErrors })
      )
      notification.error({ message: err?.data?.name })
    } finally {
      dispatch(getVendorInfoFinish())
    }
  }
