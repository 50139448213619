import { useEffect } from 'react'
import { getToken } from './api'
import useRouter from '../../hooks/useRouter'
import { routes } from '../../router'

export const SelfSignUp = () => {
  const router = useRouter()

  useEffect(() => {
    getToken({ token: router.query.token })
      .then((response) => {
        if (response.data.token)
          localStorage.setItem('completeProfileToken', response.data.token)
        router.push(routes.completeProfile + `?email=${response.data.email}`)
      })
      .catch((err) => {
        console.log(err)
        router.push(routes.login)
      })
  }, [])

  return null
}
