import { FC } from 'react'
import { ReactComponent as Search16 } from 'assets/svg/Search16.svg'
import { Input } from 'components/Input'
import { ReactComponent as Close16 } from '../../../../../assets/svg/Clos16.svg'
import { Props } from './types'
import { PLACEHOLDERS } from '../../../../../constants'

const Search: FC<Props> = (props) => {
  const { value, onChange } = props

  const handleChange = (e) => {
    onChange(e.target.value)
  }

  return (
    <Input
      className="allow-clear"
      propsInput={{
        allowClear: { clearIcon: <Close16 /> },
        placeholder: PLACEHOLDERS.SEARCH,
        prefix: <Search16 />,
        value,
        onChange: handleChange
      }}
    />
  )
}

export default Search
