import { Forms } from 'components/Forms'
import { Button, BUTTON_TYPES } from 'components/Button'
import { BTN_TXT, LABELS } from '../../../../constants'
import { usePRSDetailsContext } from '../Providers/PRSDetailsContextProvider'
import './style.scss'
import { AttachmentsList } from 'components/AttachmentsList/AttachmentsList'
import { FileUpload } from 'components/FileUpload/FileUpload'
import {
  IMAGES_FILE_TYPES,
  ATTACHMENTS_FILE_TYPES
} from 'components/FileUpload/constants'
import { safeString, validateFileTypes } from 'helper/common'
import { Tooltip } from 'components/Tooltip/Tooltip'
import useRouter from 'hooks/useRouter'
import { routes } from 'router'
import Details from './Details'
import cn from 'classnames'
import { FieldWithLabel } from 'components/FieldWithLabel/FieldWithLabel'
import { useSelector } from 'react-redux'
import { getUser } from 'redux/store/user/getters'
import ReassignWrapper from '../ReassignActions/ReassignWrapper'
import { ACTIONS, SUBJECTS } from '../../Abilities'
import { subject } from '@casl/ability'
import { usePrsDetailsAbility } from '../Providers/PrsDetailsAbilityProvider'
import { useMemo } from 'react'

const General = () => {
  const { state, actions } = usePRSDetailsContext()
  const { data, isCanEdit, isNew, form, isShowButtons, isNextDisabled } = state
  const {
    handleGetPRSData,
    handleDeleteAttachment,
    onValueChange,
    handleUploadFile,
    setShowButtons
  } = actions

  const ability = usePrsDetailsAbility()

  const { push } = useRouter()

  const user = useSelector(getUser)

  const canReassign = useMemo(() => {
    return ability.can(ACTIONS.REASSIGN, subject(SUBJECTS.OWNER, { ...data }))
  }, [data])

  const cancelHandler = () => {
    push(routes.productRequests)
  }

  return (
    <div className="prs-details-general-container row">
      <div className="prs-details-general">
        <div className="row">
          <AttachmentsList.WithImages
            label={'Product pictures'}
            className={cn('product_pictures_title', {
              creation: isCanEdit || isNew
            })}
            files={data?.prs_request_images}
            onDelete={(uuid) => handleDeleteAttachment(uuid, true)}
            disableActions={!(isCanEdit || isNew)}
            hideSeparator={data?.prs_request_images.length === 2}
          />
        </div>
        {(isCanEdit || isNew) && (
          <Tooltip
            title={
              'You’ve reached the limitations. Only 10 pictures can be uploaded'
            }
            overlayInnerStyle={{
              display: data?.prs_request_images.length > 9 ? 'block' : 'none',
              width: 235
            }}
            placement="topLeft"
          >
            <div className="file-upload-wrapper row mt-0">
              <FileUpload
                documentType="attachments"
                uploadBtnText={BTN_TXT.UPLOAD}
                disabled={data?.prs_request_images.length > 9}
                handleUploadFile={(data, documentType) =>
                  handleUploadFile(data, documentType, true)
                }
                uploadFilesProps={{
                  multiple: false,
                  maxSize: 5242880,
                  onDropAccepted: (file) =>
                    handleUploadFile(file, 'attachments', true),
                  accept: {
                    ...IMAGES_FILE_TYPES
                  },
                  validator: (file) =>
                    validateFileTypes(IMAGES_FILE_TYPES, file)
                }}
              />
            </div>
          </Tooltip>
        )}
        {isCanEdit || isNew ? (
          <Forms.PRSCreation
            form={form}
            data={data}
            callback={handleGetPRSData}
            onValueChange={onValueChange}
            setShowButtons={setShowButtons}
          />
        ) : (
          <Details />
        )}
        {isCanEdit || isNew || data?.prs_request_attachments.length ? (
          <div className="row mt-24">
            <AttachmentsList
              label={'Documents'}
              files={data?.prs_request_attachments}
              onDelete={handleDeleteAttachment}
              disableActions={!(isCanEdit || isNew)}
              hideSeparator={false}
            />
          </div>
        ) : null}
        {(isCanEdit || isNew) && (
          <div className="row mt-0">
            <FileUpload
              documentType="attachments"
              uploadBtnText={BTN_TXT.UPLOAD}
              handleUploadFile={handleUploadFile}
              disabled={data?.prs_request_attachments.length > 9}
              uploadFilesProps={{
                multiple: false,
                maxSize: 5242880,
                onDropAccepted: (file) => handleUploadFile(file, 'attachments'),
                accept: {
                  ...ATTACHMENTS_FILE_TYPES
                },
                validator: (file) =>
                  validateFileTypes(ATTACHMENTS_FILE_TYPES, file)
              }}
            />
          </div>
        )}
        {isShowButtons && (isCanEdit || isNew) && (
          <div className="rfp-details-general__buttons mt-32">
            <Button onClick={cancelHandler} upperCase>
              {BTN_TXT.CANCEL}
            </Button>
            <Button
              type={BUTTON_TYPES.PRIMARY}
              disabled={
                isNextDisabled ||
                (isNew && !data?.prs_request_image_ids?.length)
              }
              form="prs-creation-general"
              htmlType="submit"
              upperCase
            >
              {BTN_TXT.NEXT}
            </Button>
          </div>
        )}
      </div>
      {!!data?.creator?.uuid && (
        <div className="column prs-details-general__vcs">
          {!user.vendor && (
            <>
              <div className="row">
                <FieldWithLabel
                  title={LABELS.VENDOR}
                  className="full-width prs-details-general__left__contacts__vendor"
                >
                  {safeString(data?.vendor?.name)}
                </FieldWithLabel>
              </div>
              <div className="row mt-24 mb-24">
                <FieldWithLabel
                  title={LABELS.DESCRIPTION}
                  className="full-width prs-details-general__left__contacts"
                >
                  {safeString(data?.vendor?.description)}
                </FieldWithLabel>
              </div>
            </>
          )}
          <FieldWithLabel
            title={LABELS.VENDOR_CONTACTS}
            className="full-width prs-details-general__left__contacts"
          >
            <ReassignWrapper
              canReassignOwner={canReassign}
              user={data.creator}
            />
          </FieldWithLabel>
        </div>
      )}
    </div>
  )
}

export default General
