import { useCallback, useEffect } from 'react'
import { useAppSocket } from '../../features/AppSocket/AppSocketProvider'
import { useDispatch } from 'react-redux'
import { useParams } from 'react-router-dom'
import { add, updateReadByUsers } from 'redux/store/chatHistory/slice'
import { updateUnreadMessagesDueSocket } from '../../redux/store/chats/slice'
import { BASE_SOCKET_TYPE } from '../../constants'
import { updateUnreadMessages } from 'redux/store/chatDetails/slice'

const useChats = () => {
  const socket = useAppSocket()
  const { id } = useParams()

  const dispatch = useDispatch()

  const _tryOpenOrCloseChatSocket = useCallback(() => {
    if (socket) {
      socket?.open()
      socket.onmessage = (event: MessageEvent) => {
        const message = JSON.parse(event.data)
        if (
          BASE_SOCKET_TYPE.NEW_MESSAGE_ADDED === message.type &&
          id === message.payload.chat_id
        ) {
          dispatch(add(message.payload))
          dispatch(updateUnreadMessages())
        } else if (
          BASE_SOCKET_TYPE.MESSAGE_READ === message.type &&
          id === message.payload.chat_id
        ) {
          dispatch(updateReadByUsers())
        }
        dispatch(updateUnreadMessagesDueSocket(message.payload))
      }

      // return () => socket?.close()
    }
  }, [socket])

  useEffect(() => {
    return () => socket?.close()
  }, [])

  return {
    _tryOpenOrCloseChatSocket
  }
}

export default useChats
