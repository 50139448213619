import './style.scss'
import Typography from '../../../components/Typography/Typography'
import { Select } from '../../../components/Select'
import { PLACEHOLDERS } from '../../../constants'
import { TStakeholdersFormList } from '../types'

type TProps = {
  st: TStakeholdersFormList
  isAvailable: boolean
  handleCategories: (data: string[], uuid: string) => void
  selected: string[]
}
export const StakeholderWithCategories = ({
  st,
  isAvailable,
  handleCategories,
  selected
}: TProps) => {
  return (
    <div
      key={st.uuid}
      className="stakeholder-with-categories-field"
      onClick={(e) => e.preventDefault()}
    >
      <Typography.Body1>{st.first_name + ' ' + st.last_name}</Typography.Body1>
      <Select.Multi
        options={st.contract_categories}
        propsItem={{}}
        propsSelect={{
          placeholder: PLACEHOLDERS.PLEASE_SELECT,
          onChange: (e) => handleCategories(e, st.uuid),
          disabled: !isAvailable,
          value: selected,
          defaultValue: []
        }}
      />
    </div>
  )
}
