import { TPRSDetailsResponse } from './PRSDetails/types'

export const handleDeletedAttachmentsIds = (
  data: TPRSDetailsResponse,
  id: string,
  field: string
) => {
  return [...(data[field]?.map((i) => i.uuid).filter((i) => i !== id) ?? [])]
}
export const handleDeletedAttachmentsItems = (
  data: TPRSDetailsResponse,
  id: string,
  field: string
) => {
  return [...(data[field]?.filter((i) => i.uuid !== id) ?? [])]
}
