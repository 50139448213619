import cn from 'classnames'
import { Typography, TYPOGRAPHY_WEIGHT } from 'components/Typography'
import { TFieldWithLabel } from './types'
import './styles.scss'

export const FieldWithLabel = ({
  title,
  className,
  children
}: TFieldWithLabel) =>
  children ? (
    <div className={cn('field-with-label', className)}>
      <Typography.Label weight={TYPOGRAPHY_WEIGHT.SEMI_BOLD}>
        {title}
      </Typography.Label>
      <Typography.Body1>{children}</Typography.Body1>
    </div>
  ) : (
    <></>
  )
