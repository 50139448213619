import './styles.scss'

import { Menu } from 'components/Menu/Menu'
import { useChatListContext } from 'features/ChatList/Providers/ChatListContextProvider'

const Tabs = () => {
  const chatListContext = useChatListContext()

  const handleSelectTab = (e) => {
    chatListContext.actions.setActiveTab(e.key)
  }

  if (chatListContext.state.search) {
    return null
  }

  return (
    <Menu
      mode="horizontal"
      className="chat-list-tabs"
      onClick={handleSelectTab}
      defaultActiveFirst={true}
      // selectable={false}
      selectedKeys={[chatListContext.state.activeTab]}
      activeKey={chatListContext.state.activeTab}
      items={chatListContext.state.tabs}
      disabledOverflow
      multiple={false}
    />
  )
}

export default Tabs
