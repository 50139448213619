import Fetcher from 'services/Fetcher'
import { API_DICTIONARY, HTTP_METHODS } from 'constants/api'
import { TRFPItemListRequest, TRFPItemListResponse } from './types'

const fetcher = new Fetcher({})

export const getAssignedItems: TRFPItemListRequest = async (id, data) =>
  fetcher.request<unknown, TRFPItemListResponse>({
    url: `${API_DICTIONARY.RFP}${id}/${API_DICTIONARY.RFP_ITEMS}`,
    method: HTTP_METHODS.GET,
    ...data
  })
