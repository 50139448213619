import { Input } from 'components/Input'
import { PLACEHOLDERS } from 'constants/txt'
import { TComponent } from './types'
import { usePRSVotingContext } from '../../../Providers/PRSVotingContext'
import Typography from 'components/Typography/Typography'
import { Radio, Space } from 'antd'
import { usePRSDetailsContext } from '../../../Providers/PRSDetailsContextProvider'
import { useMemo } from 'react'

const Single = ({ options, isClinicalRationale }: TComponent) => {
  const { state } = usePRSVotingContext()
  const { votingResponse } = state

  const prsDetailsContext = usePRSDetailsContext()
  const { clinicalRationaleResponse } = prsDetailsContext.state

  const response = useMemo(() => {
    return isClinicalRationale ? clinicalRationaleResponse : votingResponse
  }, [votingResponse, clinicalRationaleResponse])

  return (
    <>
      <Space direction="vertical">
        {options.map((option) => {
          return (
            <Radio
              className="radio-group-default__input"
              disabled={true}
              value={option.uuid}
              key={option.uuid}
              checked={!!response.get(option.uuid)}
            >
              {option.is_other ? (
                <Input
                  propsInput={{
                    disabled: true,
                    placeholder: PLACEHOLDERS.OTHER,
                    value: response.get(option.uuid)?.text
                  }}
                />
              ) : (
                <Typography.Body1>{option.text}</Typography.Body1>
              )}
            </Radio>
          )
        })}
      </Space>
    </>
  )
}

export default Single
