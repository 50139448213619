import useCreateContractPipelineModal from '../../ContractPipeline/hooks/useCreateContractPipelineModal'
import { CreateContractPipelineForm } from '../../ContractPipeline/CreateContractPipelineModal/CreateContractPipelineForm'
import { useCallback, useEffect, useMemo } from 'react'
import { useCPRDetailsContext } from '../Providers/CPRDetailsContextProvider'
import moment from 'moment'
import { Button, BUTTON_TYPES } from '../../../components/Button'
import { BTN_TXT } from '../../../constants'
import AdditionalInfo from '../General/AdditionalInfo'
import { useNavigate } from 'react-router-dom'
import { routes } from '../../../router'

export const UpdateContractPipelineDetails = () => {
  const contractDetailsContext = useCPRDetailsContext()

  const { details } = contractDetailsContext.state

  const formProps = useCreateContractPipelineModal({
    editModeData: details
  })

  const {
    form,
    contractCategoryNameOptions,
    setDiscardChanges,
    onFirstLoad,
    findChangesHandler,
    formWasChanged
  } = formProps

  const navigate = useNavigate()

  useEffect(() => {
    // if details are loaded - get edit options
    if (details.uuid) onFirstLoad()
  }, [details])

  const setInitialValues = () => {
    form.setFieldsValue({
      led_by: details.led_by,
      contract_category:
        contractCategoryNameOptions
          .find((o) => o.label === details.contract_category_name)
          ?.value.toString() || '',
      bid_start_date: moment(details.bid_start_date),
      estimated_savings: details.estimated_savings
    })
  }

  const onDiscardChanges = () => {
    navigate(`${routes.contracts}${routes.contractPipeline}`)
  }

  useEffect(() => {
    // set initial values from details
    if (details && contractCategoryNameOptions) {
      setInitialValues()
    }
  }, [details, contractCategoryNameOptions])

  const ledByDisabled = useMemo(() => {
    return Boolean(
      details.count_of_joined_health_systems &&
        details.count_of_joined_health_systems > 0
    )
  }, [details])

  const onCancelSave = useCallback(() => {
    if (findChangesHandler()) {
      setDiscardChanges(true)
    }
  }, [findChangesHandler, setDiscardChanges])

  return (
    <div>
      <div className="row">
        <div className="contract-details-general__details">
          <CreateContractPipelineForm
            className="mr-24"
            successCloseConfirmationCallback={onDiscardChanges}
            ledByDisabled={ledByDisabled}
            editMode
            {...formProps}
          />
        </div>
        <AdditionalInfo />
      </div>
      {formWasChanged && (
        <div className="row justify-end mt-32">
          <Button
            onClick={onCancelSave}
            type={BUTTON_TYPES.DEFAULT}
            upperCase
            className="mr-8"
          >
            {BTN_TXT.CANCEL}
          </Button>
          <Button
            type={BUTTON_TYPES.PRIMARY}
            form="add-edit-contract-pipeline-form"
            htmlType="submit"
            upperCase
          >
            {BTN_TXT.SAVE}
          </Button>
        </div>
      )}
    </div>
  )
}
