import cn from 'classnames'
import { Tooltip } from 'components/Tooltip/Tooltip'
import { TAddressCell } from './types'
import './styles.scss'

export const AddressCell = ({ address, className }: TAddressCell) => {
  const formattedAddress = address
    ? `${address.zip_code} ${address.street}, ${address.city}, ${address.state}`
    : '-'
  return (
    <div className={cn('address-cell', className)}>
      <Tooltip
        title={formattedAddress}
        overlayInnerStyle={{
          display: 'block',
          width: 258
        }}
      >
        {formattedAddress}
      </Tooltip>
    </div>
  )
}
