import { useState, useEffect, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import axios, { CancelTokenSource } from 'axios'
import { Button, BUTTON_TYPES } from 'components/Button'
import { Typography, TYPOGRAPHY_WEIGHT } from 'components/Typography'
import { Table } from 'components/Table'
import { Input } from 'components/Input'
import { UserArchivePopup } from 'pages/CRUDUser/UserArchivePopup'
import { getCommunityUsersList } from './api'
import { setLoading } from 'redux/store/common/slice'
import { columns } from './constants'
import { useDebounce } from 'hooks/hooks'
import useRouter from 'hooks/useRouter'
import { getUser } from 'redux/store/user/getters'
import { routes } from 'router/Config/config.routes'
import { isTableHasParams } from 'helper/common'
import { BTN_TXT, PLACEHOLDERS } from 'constants/txt'
import { USER_STATUSES } from 'constants/common'
import { SETTINGS_TABS } from 'pages/Settings/constants'
import { DEFAULT_PAGE } from 'components/Table/constants'
import { ReactComponent as Close16 } from 'assets/svg/Clos16.svg'
import { ReactComponent as Search16 } from 'assets/svg/Search16.svg'
import { ReactComponent as Plus16 } from 'assets/svg/Plus16.svg'
import { ReactComponent as Edit16 } from 'assets/svg/Edit16.svg'
import {
  TCommunityUsersList,
  TCommunityTableRequestData,
  TCommunityUsersParams,
  TCommunityUsersFilters,
  TCommunityUsersFiltersFunc
} from './types'
import './styles.scss'
import { ACTIONS, Can, SUBJECTS } from '../Permission'

export const CommunityUsers = () => {
  const [tableData, setTableData] = useState<TCommunityUsersList[]>([])
  const [selectedRowKeys, setSelectedRowKeys] = useState([])
  const [selectedRows, setSelectedRows] = useState<TCommunityUsersList[]>([])
  const [searchValue, setSearchValue] = useState('')
  const [sortParams, setSortParams] = useState<TCommunityTableRequestData>()
  const [firstLoad, setFirstLoad] = useState(true)
  const [filters, setFilters] = useState<TCommunityUsersFilters>({})
  const [totalNumber, setTotalNumber] = useState(0)
  const [pageInfo, setPageInfo] = useState({ ...DEFAULT_PAGE })
  const timeoutRef = useRef<ReturnType<typeof setTimeout>>()
  const tableDataTokenRef = useRef<CancelTokenSource>()
  const dispatch = useDispatch()
  const user = useSelector(getUser)
  const { push } = useRouter()
  const debouncedSearch = useDebounce(searchValue, 500)
  useEffect(() => {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current)
    }
    if (user.community) {
      timeoutRef.current = setTimeout(
        () => getTableData({ ...sortParams, ...filters }),
        firstLoad ? 0 : 500
      )
    }
  }, [debouncedSearch, user.community])
  useEffect(() => {
    return () => {
      if (!!tableDataTokenRef.current) {
        tableDataTokenRef.current.cancel()
      }
    }
  }, [])
  const getTableData = ({
    sortField,
    sortOrder,
    filters,
    page
  }: TCommunityTableRequestData) => {
    dispatch(setLoading(true))
    if (!!tableDataTokenRef.current) {
      tableDataTokenRef.current.cancel()
    }
    const params: TCommunityUsersParams = { ...filters }
    if (searchValue) {
      params.search = searchValue
    }
    if (sortField) {
      if (sortOrder) {
        params.ordering = `${sortOrder === 'descend' ? '-' : ''}${sortField}`
      }
      setSortParams({
        sortField,
        sortOrder
      })
    }
    const dataPage = page ? page : pageInfo
    params.limit = dataPage.pageSize
    params.offset = (dataPage.pageNumber - 1) * dataPage.pageSize
    tableDataTokenRef.current = axios.CancelToken.source()
    getCommunityUsersList(user.community as string, {
      cancelToken: tableDataTokenRef.current?.token,
      params
    })
      .then((resp) => {
        if (!resp.data?.results) {
          return
        }
        setTableData(
          resp.data.results.map((i) => ({
            ...i,
            key: i.uuid
          }))
        )
        setTotalNumber(resp.data.count)
        if (firstLoad) {
          setFirstLoad(false)
        }
      })
      .finally(() => dispatch(setLoading(false)))
  }
  const handleSelectRow = (selectedRowKeys, selectedRow) => {
    setSelectedRowKeys(selectedRowKeys)
    setSelectedRows(selectedRow)
  }

  const createUser = () => {
    push(`${routes.createUserProfile}/new`, {
      state: { redirectTab: SETTINGS_TABS[2].key }
    })
  }
  const handleRedirect = () => {
    push(`${routes.createUserProfile}/${selectedRowKeys[0]}`)
  }
  const handleTableChange = (pagination, _filters, sorter) => {
    const page = {
      pageNumber: pagination.current,
      pageSize: pagination.pageSize
    }
    getTableData({
      sortField: sorter.field,
      sortOrder: sorter.order,
      filters,
      page
    })
    setPageInfo(page)
  }
  const handleChangePageSize = (pageSize) => {
    const page = {
      pageNumber: 1,
      pageSize
    }
    getTableData({
      ...sortParams,
      filters,
      page
    })
    setPageInfo(page)
  }
  const applyFilters: TCommunityUsersFiltersFunc = (field, appliedFilters) => {
    const newFilters = { ...filters, [field]: appliedFilters }
    setFilters(newFilters)
    getTableData({ ...sortParams, filters: newFilters })
  }
  return (
    <div className="table-wrapper community-users">
      <div className="table-wrapper__header">
        {!!selectedRowKeys.length ? (
          <Typography.Body1
            className="table-wrapper__select-title"
            weight={TYPOGRAPHY_WEIGHT.SEMI_BOLD}
          >
            Selected {selectedRowKeys.length} of {totalNumber}
          </Typography.Body1>
        ) : (
          <Input
            className="allow-clear"
            propsInput={{
              allowClear: { clearIcon: <Close16 /> },
              placeholder: PLACEHOLDERS.SEARCH_USERS,
              prefix: <Search16 />,
              value: searchValue,
              onChange: (e) => setSearchValue(e.target.value)
            }}
          />
        )}
        <div className="table-wrapper__header-buttons">
          {!selectedRowKeys.length && (
            <Button
              type={BUTTON_TYPES.PRIMARY}
              icon={<Plus16 />}
              onClick={createUser}
              upperCase
            >
              {BTN_TXT.CREATE_USER}
            </Button>
          )}
          {selectedRowKeys.length === 1 && (
            <Can I={ACTIONS.EDIT} a={SUBJECTS.USER}>
              <Button
                type={BUTTON_TYPES.PRIMARY}
                onClick={handleRedirect}
                icon={<Edit16 />}
                upperCase
                className="table-wrapper__header_buttons__edit-btn"
              >
                {BTN_TXT.EDIT}
              </Button>
            </Can>
          )}
          {!!selectedRows.length &&
            selectedRows.filter((i) => i.status !== USER_STATUSES.ARCHIVED)
              .length === selectedRows.length && (
              <UserArchivePopup<TCommunityUsersList>
                selected={selectedRows}
                callback={() => {
                  setSelectedRowKeys([])
                  setSelectedRows([])
                  getTableData({ ...sortParams, ...filters })
                }}
                isTable
              />
            )}
        </div>
      </div>
      {!firstLoad && (
        <Table<TCommunityUsersList>
          dataSource={tableData}
          className="community-users-table"
          columns={columns({
            applyFilters,
            filters,
            community: user.community as string
          })}
          onChange={handleTableChange}
          hasSearchOrFilters={isTableHasParams<TCommunityUsersFilters>(
            debouncedSearch,
            filters
          )}
          onChangePage={handleChangePageSize}
          pageSize={pageInfo.pageSize}
          pagination={{
            pageSize: pageInfo.pageSize,
            current: pageInfo.pageNumber,
            total: totalNumber
          }}
          createButton={{
            buttonText: BTN_TXT.CREATE_USER,
            availability: true,
            action: createUser
          }}
          rowSelection={{
            selectedRowKeys,
            onChange: handleSelectRow,
            getCheckboxProps: (record) => ({
              disabled: record.uuid === user.uuid
            })
          }}
        />
      )}
    </div>
  )
}
