import { LABELS } from 'constants/txt'
import { FieldWithLabel } from '../../../../components/FieldWithLabel/FieldWithLabel'
import { safeNumber, safeString } from '../../../../helper/common'
import './style.scss'
import ShowMore from '../../../../components/ShowMore/ShowMore'
import Typography from '../../../../components/Typography/Typography'
import { usePRSDetailsContext } from '../Providers/PRSDetailsContextProvider'
import { currencyFormatter } from '../../../ContractDetails/ContractDetailsGeneral/ContractDetailsForm/utils'
const Details = () => {
  const { state } = usePRSDetailsContext()
  const { data } = state
  return (
    <>
      <div className="prs-details-general__left mt-24">
        <div className="row two-columns space-between">
          <FieldWithLabel
            title={LABELS.CONTRACT_CATEGORY}
            className="prs-details-general__left__contract-category"
          >
            {safeString(data.contract_category_name)}
          </FieldWithLabel>
          <div className="prs-creation-form__half-row row space-between two-columns mt-0">
            <FieldWithLabel title={LABELS.ANTICIPATED_MONTHLY_USAGE}>
              {currencyFormatter(
                safeNumber(data.anticipated_monthly_usage).toFixed(2)
              )}
            </FieldWithLabel>
            <FieldWithLabel title={LABELS.PRICE}>
              {currencyFormatter(safeNumber(data.price).toFixed(2))}
            </FieldWithLabel>
          </div>
        </div>
        <div className="row full-width mt-24">
          <FieldWithLabel title={LABELS.PRODUCT_DESCRIPTION}>
            <ShowMore className="prs-details-general__left__description">
              <Typography.Body1>
                {safeString(data.description)}
              </Typography.Body1>
            </ShowMore>
          </FieldWithLabel>
        </div>
        <div className="row full-width mt-24">
          <FieldWithLabel title={LABELS.JUSTIFICATION}>
            <ShowMore className="prs-details-general__left__justification">
              <Typography.Body1>
                {safeString(data.justification)}
              </Typography.Body1>
            </ShowMore>
          </FieldWithLabel>
        </div>
      </div>
    </>
  )
}

export default Details
