import {
  createContext,
  FC,
  PropsWithChildren,
  useCallback,
  useContext,
  useMemo
} from 'react'
import { useDispatch } from 'react-redux'

import { setLoading } from 'redux/store/common/slice'
import { TResponsibleUser } from 'features/RFP/RFPDetails/types'
import { RFP_DETAILS_TABS } from 'features/RFP/RFPDetails/constants'
import { useRFPDetailsContext } from 'features/RFP/RFPDetails/Providers/RFPDetailsContextProvider'
import {
  updateCommonsResponsibleUsersRequestAsync,
  updateResponsibleUsersRequestAsync
} from 'features/RFP/RFPDetails/api'
import { useRFPDetailsPopupContext } from 'features/RFP/RFPDetails/Providers/RFPDetailsPopupProvider'
import { ROLES } from 'features/Permission'
import { VALIDATION_MESSAGES } from '../../../../../constants'

type ContextProps = {
  state: {
    responsibleUsers: TResponsibleUser[]
    isAllResponsibleUsersAdded: boolean
  }
  actions: {
    deleteResponsibleUserAsync: (
      responsibleUserUuid: TResponsibleUser['uuid']
    ) => Promise<void>
    addResponsibleUserAsync: (
      responsibleUsers: Array<TResponsibleUser['uuid']>,
      isCommons?: boolean
    ) => Promise<void>
  }
}

const RFPResponsibleContext = createContext<ContextProps>({
  state: null!,
  actions: null!
})

const generalTab = RFP_DETAILS_TABS[0].key
const biddingTab = RFP_DETAILS_TABS[6].key

const RFPResponsibleContextProvider: FC<PropsWithChildren> = ({ children }) => {
  const dispatch = useDispatch()

  const rfpDetailsContext = useRFPDetailsContext()
  const {
    addResponsibleUserPopup,
    deleteResponsibleUserPopup,
    addResponsibleCommonsUserPopup
  } = useRFPDetailsPopupContext()

  const { activeTab, data, isVendor } = rfpDetailsContext.state
  const { handleGetRfpData } = rfpDetailsContext.actions

  const isCLC = useMemo(() => {
    return data.participation_type === 'clinically_led_central'
  }, [data])
  const isCreatedByOwner = useMemo(() => {
    return data.creator?.role?.includes('commons')
  }, [data])

  const responsibleUsers = useMemo(() => {
    switch (activeTab) {
      case generalTab:
        return (
          (isCLC || isCreatedByOwner
            ? data?.commons_responsibles
            : data.community_responsibles) ?? []
        )
      case biddingTab:
        return data.vendor_responsibles ?? []
      default:
        return []
    }
  }, [
    activeTab,
    data.community_responsibles,
    data.vendor_responsibles,
    isCLC,
    isCreatedByOwner
  ])

  const isAllResponsibleUsersAdded = useMemo(
    () =>
      isVendor
        ? Boolean(
            responsibleUsers.find((u) => u.role === ROLES.VENDOR_ANALYST) &&
              responsibleUsers.find((u) => u.role === ROLES.VENDOR_LEGAL)
          )
        : isCreatedByOwner
        ? Boolean(
            responsibleUsers.find((u) => u.role === ROLES.COMMONS_ANALYST) &&
              responsibleUsers.find((u) => u.role === ROLES.COMMONS_LEGAL)
          )
        : Boolean(
            responsibleUsers.find((u) => u.role === ROLES.ANALYST) &&
              responsibleUsers.find((u) => u.role === ROLES.LEGAL)
          ),
    [isVendor, responsibleUsers, isCreatedByOwner]
  )

  const addResponsibleUserAsync = useCallback(
    async (
      responsibles: Array<TResponsibleUser['uuid']>,
      isCommons?: boolean
    ) => {
      if (!data.uuid) {
        throw new Error('RFP ID not provided')
      }
      try {
        dispatch(setLoading(true))
        if (isCommons) {
          await updateCommonsResponsibleUsersRequestAsync(
            data.uuid,
            { commons_responsibles: responsibles },
            VALIDATION_MESSAGES.COMMONS_MEMBER_USER_ADDED
          )
        } else {
          await updateResponsibleUsersRequestAsync(
            data.uuid,
            { responsibles: responsibles },
            responsibles?.length > 1
              ? VALIDATION_MESSAGES.TEAM_MEMBERS_USER_ADDED
              : VALIDATION_MESSAGES.TEAM_MEMBER_USER_ADDED
          )
        }

        await handleGetRfpData()

        addResponsibleUserPopup.actions.close()
        addResponsibleCommonsUserPopup.actions.close()
      } catch (e) {
        console.error(e)
      } finally {
        dispatch(setLoading(false))
      }
    },
    [data.uuid, dispatch, handleGetRfpData, addResponsibleUserPopup.actions]
  )

  const deleteResponsibleUserAsync = useCallback(
    async (responsibleUserUuid: TResponsibleUser['uuid']) => {
      if (!data.uuid) {
        throw new Error('RFP ID not provided')
      }

      try {
        dispatch(setLoading(true))
        const responsibles = responsibleUsers
          .filter(
            (u) =>
              u.uuid !== responsibleUserUuid &&
              u.role !== ROLES.COMMONS_VICE_PRESIDENT &&
              u.role !== ROLES.COMMONS_CONTRACT_STEWARD
          )
          .map((u) => u.uuid)

        await updateResponsibleUsersRequestAsync(
          data.uuid,
          { responsibles },
          VALIDATION_MESSAGES.TEAM_MEMBER_USER_DELETED
        )

        await handleGetRfpData()

        deleteResponsibleUserPopup.actions.close()
      } catch (e) {
        console.error(e)
      } finally {
        dispatch(setLoading(false))
      }
    },
    [
      data.uuid,
      dispatch,
      responsibleUsers,
      handleGetRfpData,
      deleteResponsibleUserPopup.actions
    ]
  )

  const state = useMemo(
    () => ({
      responsibleUsers,
      isAllResponsibleUsersAdded
    }),
    [responsibleUsers, isAllResponsibleUsersAdded]
  )

  const actions = useMemo(
    () => ({
      addResponsibleUserAsync,
      deleteResponsibleUserAsync
    }),
    [addResponsibleUserAsync, deleteResponsibleUserAsync]
  )

  return (
    <RFPResponsibleContext.Provider value={{ state, actions }}>
      {children}
    </RFPResponsibleContext.Provider>
  )
}

export const useRFPResponsibleContext = () => useContext(RFPResponsibleContext)

export default RFPResponsibleContextProvider
