import { useMemo } from 'react'
import { useParams, useLocation, useNavigate } from 'react-router-dom'
import queryString from 'query-string'

export type Query = {
  [key: string]: string
}

export default () => {
  const params = useParams()
  const location = useLocation()
  const navigate = useNavigate()
  return useMemo(
    () => ({
      push: navigate,
      pathname: location.pathname,
      query: {
        ...queryString.parse(location.search),
        ...params
      } as Query,
      location,
      navigate
    }),
    [params, location, navigate]
  )
}
