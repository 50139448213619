import Button from 'components/Button/Button'
import { BUTTON_TYPES } from 'components/Button'
import { BTN_TXT, TOOLTIP } from '../../constants'
import { Tooltip } from 'components/Tooltip/Tooltip'
import Popup from 'components/Popup/Popup'
import Typography from 'components/Typography/Typography'
import { useContractDetailsPagePopup } from '../ContractsPipelineDetails/Providers/ContractDetailsPagePopupProvider'
import { useCPRDetailsContext } from '../ContractsPipelineDetails/Providers/CPRDetailsContextProvider'
import { TJoinedModalProps } from './types'
import { useCallback } from 'react'
import { setLoading } from '../../redux/store/common/slice'
import {
  joinContractRequest,
  optOutContractRequest
} from '../ContractsPipelineDetails/api'
import { useDispatch, useSelector } from 'react-redux'
import { getUser } from '../../redux/store/user/getters'
import { CONTRACT_PIPELINE_DETAILS_TABS } from '../ContractsPipelineDetails/contants'

export const JoinContainer = () => {
  const { state: dataState, actions } = useCPRDetailsContext()
  const { joinPopup } = useContractDetailsPagePopup()

  const user = useSelector(getUser)

  const alreadyJoined = dataState.details.joined

  const isCommunity = user.role.includes('community')
  const createdByAnotherHS = dataState.details.led_by !== 'health_system'
  const userIsInAnotherHS =
    user.health_system !== dataState.details.creator_health_system

  const handleModal = () => {
    joinPopup.actions.open()
  }

  const successCallback = () => {
    if (dataState.activeTab === CONTRACT_PIPELINE_DETAILS_TABS[1].key) {
      actions.setNeedHSRefresh(true)
    }
    actions.getContractDetailsAsync()
  }

  return (
    <>
      {createdByAnotherHS && isCommunity && userIsInAnotherHS && (
        <Tooltip
          title={
            alreadyJoined
              ? TOOLTIP.OUT_CONTRACT_PIPELINE
              : TOOLTIP.JOIN_CONTRACT_PIPELINE
          }
        >
          <Button
            type={alreadyJoined ? BUTTON_TYPES.DEFAULT : BUTTON_TYPES.PRIMARY}
            onClick={handleModal}
            upperCase
            danger={alreadyJoined}
          >
            {alreadyJoined ? BTN_TXT.OPT_OUT : BTN_TXT.JOIN}
          </Button>
        </Tooltip>
      )}
      <JoinModal
        uuid={dataState.details.uuid}
        alreadyJoined={alreadyJoined}
        successCallback={successCallback}
        pipelineName={dataState.details.contract_category_name}
      />
    </>
  )
}

export const JoinModal = ({
  uuid,
  alreadyJoined,
  pipelineName,
  successCallback
}: TJoinedModalProps) => {
  const dispatch = useDispatch()
  const { joinPopup } = useContractDetailsPagePopup()

  const cprDetailsContext = useCPRDetailsContext()

  const { state, actions } = joinPopup

  const onOk = useCallback(async () => {
    try {
      dispatch(setLoading(true))
      const changeJoinedStatus = alreadyJoined
        ? optOutContractRequest
        : joinContractRequest
      await changeJoinedStatus(uuid)
      return successCallback()
    } catch (e) {
      console.error(e)
    } finally {
      dispatch(setLoading(false))
      actions.close()
    }
  }, [uuid, alreadyJoined, cprDetailsContext?.state?.activeTab])

  return (
    <div className="delete-modal">
      <Popup
        visible={state.visible}
        onCancel={actions.close}
        title={
          alreadyJoined
            ? `Opt out from ${pipelineName}`
            : 'Participate in the contract?'
        }
        width={440}
        zIndex={10000}
        footer={
          <>
            <Button
              type={BUTTON_TYPES.DEFAULT}
              onClick={actions.close}
              upperCase
            >
              {BTN_TXT.CANCEL}
            </Button>
            <Button
              type={BUTTON_TYPES.PRIMARY}
              onClick={onOk}
              upperCase
              danger={alreadyJoined}
            >
              {alreadyJoined ? BTN_TXT.OPT_OUT : BTN_TXT.PARTICIPATE}
            </Button>
          </>
        }
      >
        <div className="column">
          <Typography.Body1>
            {alreadyJoined
              ? `You will leave the ${pipelineName} pipeline. Do you want to proceed with opting out?`
              : 'Please note that the participation letters must be fully completed and signed 30 days before the start of the RFP.'}
          </Typography.Body1>
        </div>
      </Popup>
    </div>
  )
}
