import { FC, PropsWithChildren, useCallback, useEffect, useMemo } from 'react'

import useRouter from 'hooks/useRouter'
import { Status } from 'components/Status'
import { Container } from 'components/Container/Container'
import { Menu } from 'components/Menu/Menu'
import { useContractDetailsContext } from 'features/ContractDetails/Providers/ContractDetailsContextProvider'
import ContractDetailsActions from 'features/ContractDetails/ContractDetailsActions/ContractDetailsActions'
import {
  CONTRACT_DETAILS_BREADCRUMBS,
  CONTRACT_STATUS_CLASSNAME,
  CONTRACT_STATUS_NAME
} from 'features/ContractDetails/constants'
import {
  ACTIONS,
  contractSubject,
  SUBJECTS
} from 'features/ContractDetails/Abilities'
import {
  Can,
  useContractDetailsAbility
} from 'features/ContractDetails/Providers/ContractDetailsAbilityProvider'
import { useContractDetailsPopup } from 'features/ContractDetails/Providers/ContractDetailsPopupProvider'
import { safeString } from '../../../helper/common'
import { useParams } from 'react-router-dom'

const ContractDetailsContainer: FC<PropsWithChildren> = ({ children }) => {
  const router = useRouter()
  const ability = useContractDetailsAbility()
  const { tab } = useParams()

  const contractDetailsContext = useContractDetailsContext()
  const { contractStepsTimePopup } = useContractDetailsPopup()

  const { details, expiredAt, activeTab, tabs } = contractDetailsContext.state
  const { setActiveTab } = contractDetailsContext.actions

  const title = details.uuid ? details.name : 'Contract creation'
  const canOpenTimer = ability.can(
    ACTIONS.SETUP,
    contractSubject(SUBJECTS.TIMELINES, details)
  )
  const handleClickTimer = useCallback(() => {
    if (canOpenTimer) {
      contractStepsTimePopup.actions.open()
    }
  }, [canOpenTimer, contractStepsTimePopup.actions])

  useEffect(() => {
    setActiveTab(safeString(tab || 'general'))
  }, [tab])

  const RenderedStatus = useMemo(
    () => (
      <Status.Default className={CONTRACT_STATUS_CLASSNAME[details.status]}>
        {CONTRACT_STATUS_NAME[details.status]}
      </Status.Default>
    ),
    [details.status]
  )

  const RenderedActions = useMemo(() => <ContractDetailsActions />, [])

  const RenderedMenu = useMemo(() => {
    const handleSelectTab = (e) => {
      setActiveTab(e.key)
      router.push(e.key)
    }

    return (
      <Menu
        mode="horizontal"
        onSelect={handleSelectTab}
        selectedKeys={[activeTab]}
        defaultSelectedKeys={[activeTab]}
        items={tabs}
      />
    )
  }, [activeTab, router, setActiveTab, tabs])

  return (
    <Can I={ACTIONS.VIEW} a={contractSubject(SUBJECTS.CONTRACT, details)}>
      <Container
        title={title}
        subtitle={details.number}
        expiresAt={expiredAt}
        onClickTimer={handleClickTimer}
        breadcrumbs={CONTRACT_DETAILS_BREADCRUMBS(title, router.query)}
        status={RenderedStatus}
        actions={RenderedActions}
        menu={RenderedMenu}
        previentTimerInteraction={!canOpenTimer}
      >
        {children}
      </Container>
    </Can>
  )
}

export default ContractDetailsContainer
