import { Container } from '../../components/Container/Container'
import {
  REQUEST_FOR_ANALYSIS_BREADCRUMBS,
  REQUEST_FOR_ANALYSIS_BREADCRUMBS_COMMONS
} from './constants'
import { RequestForAnalysisDetails } from '../../features/RequestForAnalysisDetails/RequestForAnalysisDetails'
import { useSelector } from 'react-redux'
import { getUser } from '../../redux/store/user/getters'
import { useRequestForAnalysisDetailsContext } from './RequestForAnalysisDetailsProvider'

export const RequestForAnalysisDetailsPage = () => {
  const { headerInfo } = useRequestForAnalysisDetailsContext().state
  const user = useSelector(getUser)
  const isCommons = user?.commons

  return (
    <Container
      title={headerInfo.health_system.name}
      subtitle={isCommons ? headerInfo.health_system.community_name : ''}
      breadcrumbs={
        isCommons
          ? REQUEST_FOR_ANALYSIS_BREADCRUMBS_COMMONS(
              headerInfo.health_system.name
            )
          : REQUEST_FOR_ANALYSIS_BREADCRUMBS(headerInfo.health_system.name)
      }
    >
      <RequestForAnalysisDetails />
    </Container>
  )
}
