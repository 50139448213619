import { TDocumentTypeUnion } from 'components/FileUpload/types'
import {
  ATTACHMENTS_FILE_TYPES,
  DOCUMENT_TYPE,
  PRICE_FILE_TYPES,
  QUESTIONS_FILE_TYPES,
  RFP_FILE_TYPES
} from 'components/FileUpload/constants'

export const getAcceptFileTypesByDocumentType = (
  documentType: TDocumentTypeUnion
) => {
  switch (documentType) {
    case DOCUMENT_TYPE.TEMPLATE:
      return RFP_FILE_TYPES
    case DOCUMENT_TYPE.QUESTIONS:
      return QUESTIONS_FILE_TYPES
    case DOCUMENT_TYPE.PRICE_FILE:
      return PRICE_FILE_TYPES
    case DOCUMENT_TYPE.ATTACHMENTS:
      return ATTACHMENTS_FILE_TYPES
    case DOCUMENT_TYPE.SUMMARY_PRICE_FILE:
      return RFP_FILE_TYPES
    case DOCUMENT_TYPE.EXECUTIVE_SUMMARY:
      return RFP_FILE_TYPES
  }
}
