import { ACTIONS, Can, SUBJECTS } from '../../../../Permission'
import { subject } from '@casl/ability'
import { Button, BUTTON_TYPES } from '../../../../../components/Button'
import { BTN_TXT } from '../../../../../constants'
import { ReactComponent as Send16 } from 'assets/svg/Send16.svg'
import { useRFPDetailsContext } from '../../Providers/RFPDetailsContextProvider'
import { useRFPDetailsActions } from '../../Providers/RFPDetailsActionsProvider'
import { Popup } from '../../../../../components/Popup'
import Typography from '../../../../../components/Typography/Typography'
import { useRFPDetailsPopupContext } from '../../Providers/RFPDetailsPopupProvider'
import { RFP_STATUSES } from '../../../constants'

const SendToCommonsAction = () => {
  const rfpDetailsContext = useRFPDetailsContext()
  const rfpDetailsActions = useRFPDetailsActions()
  const { sendRFPToCommonsPopup } = useRFPDetailsPopupContext()

  const { title, visible, onCancel } = sendRFPToCommonsPopup.state
  const { close } = sendRFPToCommonsPopup.actions

  const { data } = rfpDetailsContext.state
  const sendToCommons = () =>
    rfpDetailsContext.actions.handleChangeRFPStatus(
      RFP_STATUSES.WAITING_FOR_ASSIGNMENT,
      close
    )
  return (
    <>
      <Can I={ACTIONS.SEND} a={subject(SUBJECTS.TO_COMMONS, { ...data })}>
        <Button
          icon={<Send16 />}
          type={BUTTON_TYPES.PRIMARY}
          onClick={rfpDetailsActions.handleSendToCommons}
          upperCase
        >
          {BTN_TXT.SEND_TO_COMMONS}
        </Button>
      </Can>
      <Popup
        visible={visible}
        onCancel={onCancel}
        className="timeline-popup"
        title={title}
        width={442}
        footer={
          <>
            <Button type={BUTTON_TYPES.DEFAULT} onClick={close} upperCase>
              {BTN_TXT.CANCEL}
            </Button>
            <Button
              type={BUTTON_TYPES.PRIMARY}
              onClick={sendToCommons}
              upperCase
            >
              {BTN_TXT.SEND}
            </Button>
          </>
        }
      >
        <Typography.Body1>
          The RFP will be sent to Cognus Commons side. Do you want to send RFP?
        </Typography.Body1>
      </Popup>
    </>
  )
}

export default SendToCommonsAction
