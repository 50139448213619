import { useCallback } from 'react'
import { JoinContainer } from 'features/ContractPipeline/JoinContainer'
import { useCPRDetailsContext } from '../Providers/CPRDetailsContextProvider'
import { CONTRACT_PIPELINES_STATUS } from 'components/Status'
import { DeleteContainer } from '../DeleteContainer'
import { CreateRFPContainer } from '../CreateRFPContainer'

export const ContractPipelineDetailsActions = () => {
  const { state } = useCPRDetailsContext()

  const renderStatusAction = useCallback(() => {
    switch (state.details.status) {
      case CONTRACT_PIPELINES_STATUS.ACTIVE:
      case CONTRACT_PIPELINES_STATUS.FUTURE:
        return (
          <>
            <JoinContainer />
            <DeleteContainer />
            <CreateRFPContainer />
          </>
        )
      default:
        return null
    }
  }, [state.details.status])

  return <>{renderStatusAction()}</>
}
