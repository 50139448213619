import { EventTypes, TCalendarEventViewType } from 'components/Calendar/types'
import {
  RespondToTheMeeting,
  TCalendarEvent,
  TCalendarEventListType
} from './types'
import moment from 'moment'
import {
  hoursMinutesFormat,
  requestDateFormat,
  requestTimeFormat12,
  weekdayAndDateFormat
} from '../../utils/moment'
import { transformTimeWithoutOffset } from '../../features/Calendar/AddEventPopup/helper'

export const parseEventDataToCalendarView = (
  events: TCalendarEventListType[],
  offset: string
): TCalendarEventViewType[] => {
  return events.map((event) => {
    const start = transformTimeWithoutOffset(
      event.start_date,
      event.start_time,
      offset
    )

    const end = transformTimeWithoutOffset(
      event.end_date,
      event.end_time,
      offset
    )

    let eventStatusClassName = EventTypes.PENDING

    if (event.respond === RespondToTheMeeting.ACCEPT)
      eventStatusClassName = EventTypes.ACTIVE

    if (event.respond === RespondToTheMeeting.MAYBE)
      eventStatusClassName = EventTypes.MAYBE

    return {
      title: event.name,
      id: event.uuid,
      className: eventStatusClassName,
      location: event.location,
      day: moment(start.date).format('D ddd'),
      start: moment(start.date).format(),
      end: moment(end.date).format()
    }
  })
}

export const parseEventDetailsToDetailedView = (
  event: TCalendarEvent,
  offset: string
) => {
  let formattedDate = ''
  const startDate = transformTimeWithoutOffset(
    event.start_date,
    event.start_time,
    offset
  )
  const endDate = transformTimeWithoutOffset(
    event.end_date,
    event.end_time,
    offset
  )
  const start = `${startDate.dateFormatted} ${startDate.time}`
  const end = `${endDate.dateFormatted} ${endDate.time}`
  const timeStart = moment(
    start,
    requestDateFormat + ' ' + requestTimeFormat12
  ).format(hoursMinutesFormat)
  const timeEnd = moment(
    end,
    requestDateFormat + ' ' + requestTimeFormat12
  ).format(hoursMinutesFormat)
  if (event.start_date === event.end_date) {
    formattedDate = `${moment(start, requestDateFormat).format(
      weekdayAndDateFormat
    )} • ${timeStart} - ${timeEnd}`
  } else {
    formattedDate = `${moment(start, requestDateFormat).format(
      weekdayAndDateFormat
    )} ${timeStart} - ${moment(end, requestDateFormat).format(
      weekdayAndDateFormat
    )} ${timeEnd}`
  }

  return {
    ...event,
    formattedDate
  }
}
