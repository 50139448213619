import { Forms } from 'components/Forms'
import { FormInstance } from 'antd/lib/form/hooks/useForm'
import { TQuestionsForm } from '../../../../../components/Forms/forms.surveyQuestions.d'

export type TProps = {
  form: FormInstance<TQuestionsForm>
}
const QuestionTemplate = ({ form }: TProps) => {
  return (
    <div>
      <Forms.SurveyQuestions form={form} />
    </div>
  )
}

export default QuestionTemplate
