import { BTN_TXT } from 'constants/txt'
import { Button, BUTTON_TYPES } from 'components/Button'
import Popup from 'components/Popup/Popup'
import { usePRSDetailsPopupContext } from '../../Providers/PRSDetailsPopupProvider'
import { usePRSDetailsContext } from '../../Providers/PRSDetailsContextProvider'
import IndividualResponse from '../../Voting/Responses/IndividualResponse'

const ClinicalResponse = () => {
  const { viewRationaleResponsePopup } = usePRSDetailsPopupContext()
  const { state } = usePRSDetailsContext()
  const { data, isClinicalSponsor, clinicalRationaleData } = state
  const onOpen = () => viewRationaleResponsePopup.actions.open()
  return (
    <>
      <Button onClick={onOpen} upperCase type={BUTTON_TYPES.DEFAULT} small>
        {isClinicalSponsor ? BTN_TXT.OPEN_MY_RESPONSE : BTN_TXT.OPEN_RESPONSE}
      </Button>
      <Popup
        visible={viewRationaleResponsePopup.state.visible}
        onCancel={viewRationaleResponsePopup.actions.close}
        title={`${data?.name} survey`}
        width={680}
        footer={null}
        className="prs-modal-create-survey"
      >
        <div className="response-container">
          {clinicalRationaleData?.questions?.map((question) => {
            return (
              <IndividualResponse
                question={question}
                key={question.uuid}
                isClinicalRationale
              />
            )
          })}
        </div>
      </Popup>
    </>
  )
}

export default ClinicalResponse
