import React, { useCallback, useMemo } from 'react'
import cn from 'classnames'

import './styles.scss'

import { LABELS } from 'constants/txt'
import { Typography, TYPOGRAPHY_WEIGHT } from 'components/Typography'
import { FieldWithLabel } from 'components/FieldWithLabel/FieldWithLabel'
import { useContractDetailsContext } from 'features/ContractDetails/Providers/ContractDetailsContextProvider'
import {
  REBATE_CONDITIONS_TYPE,
  REBATE_CONDITIONS_TYPE_NAME
} from 'features/ContractDetails/RebateConditions/RebateConditionsForm/constants'
import { safeNumber } from 'helper/common'

const formatCurrency = new Intl.NumberFormat(undefined, {
  style: 'currency',
  currency: 'USD',
  currencyDisplay: 'narrowSymbol'
})
const formatPercent = new Intl.NumberFormat('en-US', {
  style: 'percent',
  minimumFractionDigits: 2,
  maximumFractionDigits: 2
})

const RebateConditionsPreview = () => {
  const contractDetailsContext = useContractDetailsContext()

  const { details } = contractDetailsContext.state

  const isGrowthRebateType =
    details.rebate_type === REBATE_CONDITIONS_TYPE.GROWTH_REBATE

  const fixedRebateCondition = useCallback(
    (rebate) => (
      <Typography.Body1 key={rebate.uuid}>
        {formatCurrency.format(safeNumber(rebate.fixed_amount))}
      </Typography.Body1>
    ),
    []
  )

  const percentageRebateCondition = useCallback(
    (rebate) => (
      <Typography.Body1 key={rebate.uuid}>
        {formatPercent.format(safeNumber(rebate.percentage) / 100)}
      </Typography.Body1>
    ),
    []
  )

  const growthRebateCondition = useCallback(
    (rebate) => (
      <React.Fragment key={rebate.uuid}>
        <Typography.Body1>
          {formatCurrency.format(safeNumber(rebate.amount_from))}
        </Typography.Body1>
        <Typography.Body1>
          {rebate?.amount_to === 'inf'
            ? `> ${formatCurrency.format(safeNumber(rebate.amount_from))}`
            : formatCurrency.format(safeNumber(rebate.amount_to))}
        </Typography.Body1>
        <Typography.Body1>
          {formatPercent.format(safeNumber(rebate.percentage) / 100)}
        </Typography.Body1>
      </React.Fragment>
    ),
    []
  )

  const rebateCondition = useCallback(
    (rebate) => {
      switch (details.rebate_type) {
        case REBATE_CONDITIONS_TYPE.NO_REBATE:
          return null
        case REBATE_CONDITIONS_TYPE.FIXED_REBATE:
          return fixedRebateCondition(rebate)
        case REBATE_CONDITIONS_TYPE.PERCENTAGE_REBATE:
          return percentageRebateCondition(rebate)
        case REBATE_CONDITIONS_TYPE.GROWTH_REBATE:
          return growthRebateCondition(rebate)
      }
    },
    [
      details.rebate_type,
      fixedRebateCondition,
      growthRebateCondition,
      percentageRebateCondition
    ]
  )

  const RebateConditionsTitle = useMemo(() => {
    switch (details.rebate_type) {
      case REBATE_CONDITIONS_TYPE.NO_REBATE:
        return null
      case REBATE_CONDITIONS_TYPE.FIXED_REBATE:
        return (
          <Typography.Label weight={TYPOGRAPHY_WEIGHT.SEMI_BOLD}>
            Amount
          </Typography.Label>
        )
      case REBATE_CONDITIONS_TYPE.PERCENTAGE_REBATE:
        return (
          <Typography.Label weight={TYPOGRAPHY_WEIGHT.SEMI_BOLD}>
            Percentage
          </Typography.Label>
        )
      case REBATE_CONDITIONS_TYPE.GROWTH_REBATE:
        return (
          <>
            <Typography.Label weight={TYPOGRAPHY_WEIGHT.SEMI_BOLD}>
              Amount from
            </Typography.Label>
            <Typography.Label weight={TYPOGRAPHY_WEIGHT.SEMI_BOLD}>
              Amount to
            </Typography.Label>
            <Typography.Label weight={TYPOGRAPHY_WEIGHT.SEMI_BOLD}>
              Percentage
            </Typography.Label>
          </>
        )
    }
  }, [details.rebate_type])

  const RebateConditions = useMemo(
    () => details.rebates.map((rebate) => rebateCondition(rebate)),
    [details, rebateCondition]
  )

  return (
    <div className="rebate-conditions-preview">
      <div className="rebate-conditions-preview__grid">
        <FieldWithLabel title={LABELS.REBATE_TYPE}>
          <Typography.Body1>
            {REBATE_CONDITIONS_TYPE_NAME[details.rebate_type]}
          </Typography.Body1>
        </FieldWithLabel>
        <div
          className={cn('rebate-conditions-preview-conditions__grid', {
            'rebate-conditions-preview-conditions__grid--growth':
              isGrowthRebateType
          })}
        >
          {RebateConditionsTitle}
          {RebateConditions}
        </div>
      </div>
    </div>
  )
}

export default RebateConditionsPreview
