import { CHARTS_COLORS, CHARTS_COLORS_TXT } from '../constants'
import { TOngoingRFPResponce } from '../types'
import { ReactComponent as ArrowRight16 } from 'assets/svg/ArrowRight16.svg'
import '../styles.scss'
import { useRef } from 'react'

export const OngoingRFPsChart = ({ data }: { data: TOngoingRFPResponce }) => {
  const ref = useRef(null as any)

  return (
    <div
      className="charts_ongoing-contracts_container"
      data-testid="chartsOngoingRFPContainer"
    >
      <div className="charts_title" data-testid="chartsOngoingRFPTitle">
        Ongoing RFPs
      </div>
      <div
        className="charts_ongoing-contracts_total_container"
        data-testid="chartsOngoingRFPTotalContainer"
      >
        <div
          className="charts_ongoing-contracts_total"
          data-testid="chartsOngoingRFPTotal"
        >
          {data?.rfp_total_count}
        </div>
        <div
          className="charts_ongoing-contracts_total-title"
          data-testid="chartsOngoingRFPTotalTitle"
        >
          Total active RFPs
        </div>
      </div>
      <div
        className="charts_ongoing-contracts_chart"
        ref={ref}
        data-testid="chartsOngoingRFPChart"
      >
        {data &&
          Object.keys(data).map((key) => {
            if (key !== 'rfp_total_count' && +data[key] > 0) {
              return (
                <div
                  className="charts_ongoing-contracts_chart-part"
                  key={key}
                  style={{
                    backgroundColor: CHARTS_COLORS[key],
                    width: `${
                      (+(ref.current.offsetWidth || 332) /
                        +data?.rfp_total_count) *
                      +data[key]
                    }px`
                  }}
                />
              )
            }
          })}
      </div>
      <div
        className="charts_ongoing-contracts_conventions"
        data-testid="chartsOngoingRFPConventions"
      >
        {data &&
          Object.keys(data).map((key) => {
            if (
              key !== 'rfp_total_count' &&
              !key.includes('contract') &&
              +data[key] > 0
            ) {
              return (
                <div
                  className="charts_ongoing-contracts_convention-wrapper"
                  data-testid="chartsOngoingRFPConventionPart"
                  key={key}
                >
                  <div className="charts_ongoing-contracts_convention">
                    <div className="charts_ongoing-contracts_convention_color-count">
                      <div
                        className="charts_ongoing-contracts_convention_color"
                        style={{
                          backgroundColor: CHARTS_COLORS_TXT[key]?.color
                        }}
                      />
                      <div className="charts_ongoing-contracts_convention_count">
                        {data[key]}
                      </div>
                    </div>

                    <div className="charts_ongoing-contracts_convention_name">
                      {CHARTS_COLORS_TXT[key]?.name}
                    </div>
                  </div>
                  <ArrowRight16 />
                </div>
              )
            }
          })}
      </div>
    </div>
  )
}
