import { TClinicalReview } from '../types'

export const getChartDataForItem = (
  clinical_review_percentage: TClinicalReview
) => {
  const accepted =
    clinical_review_percentage?.find((i) => i.decision === 'accepted')
      ?.percentage || 0
  const declined =
    clinical_review_percentage?.find((i) => i.decision === 'declined')
      ?.percentage || 0
  const not_reviewed =
    clinical_review_percentage?.find((i) => i.decision === 'not_reviewed')
      ?.percentage || 0

  return {
    accepted: accepted,
    declined: declined,
    not_reviewed
  }
}
