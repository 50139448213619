export const PRS_SURVEY_TABS = [
  {
    key: 'Questions',
    label: 'Questions'
  },
  {
    key: 'Preview',
    label: 'Preview'
  }
]

export const QUESTION_TYPE = {
  SINGLE: 'single',
  MULTI: 'multi',
  TEXT: 'text'
} as const
export const QUESTION_TYPE_WITH_OPTIONS = ['single', 'multi']

export const questionEmptyState = {
  type: QUESTION_TYPE.SINGLE,
  text: '',
  options: [],
  has_other: false,
  is_required: true
}

export const initialStateForSurveyCreation = {
  questions: [questionEmptyState]
}

export const questionOptions = [
  {
    label: 'Single select',
    value: QUESTION_TYPE.SINGLE
  },
  {
    label: 'Multi select',
    value: QUESTION_TYPE.MULTI
  },
  {
    label: 'Text',
    value: QUESTION_TYPE.TEXT
  }
]
