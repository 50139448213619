import { FC, useState } from 'react'
import '../../ContractDetails/ContractDetailsGeneral/ResponsibleUsers/UserList/styles.scss'
import { Typography } from 'components/Typography'
import { Button, BUTTON_TYPES } from 'components/Button'
import { ROLES_NAMES } from 'constants/common'
import { Avatar } from 'components/Avatar/Avatar'
import { ReactComponent as ReassignIcon } from 'assets/svg/Reassign.svg'
import { Reassign } from 'features/Reassign/ReassignPopup'
import { TResponsibleUser } from '../../RFP/RFPDetails/types'
import { useCPRDetailsContext } from '../Providers/CPRDetailsContextProvider'
import { getCapitalCharName } from '../../../helper/common'
import { Can } from '../Providers/ContractPipelineDetailsAbilityProvider'
import { ACTIONS, SUBJECTS, contractSubject } from '../Abilities'

export type Props = {
  user: TResponsibleUser
}
export const UserListItem: FC<Props> = (props) => {
  const { user } = props
  const contractDetailsContext = useCPRDetailsContext()

  const [isOpenReassign, setIsOpenReassign] = useState(false)

  const { details, isCommons } = contractDetailsContext.state

  const name = user.first_name + ' ' + user.last_name
  const capitalCharName = getCapitalCharName(user?.first_name, user?.last_name)

  const update = () => contractDetailsContext.actions.getContractDetailsAsync()

  return (
    <>
      <div className="rfp-details-responsible-user">
        <div className="rfp-details-responsible-user__user">
          <div className="rfp-details-responsible-user__avatar">
            <Avatar
              name={capitalCharName}
              alt="Responsible user image"
              src={user.avatar?.file_preview}
            />
          </div>
          <div className="rfp-details-responsible-user__content">
            <Typography.Body1 weight="semi-bold">{name}</Typography.Body1>
            <Typography.Caption className="rfp-details-responsible-user__role">
              {ROLES_NAMES[user.role]}
            </Typography.Caption>
          </div>
        </div>
        <div className="rfp-details-responsible-user__actions">
          <Can
            I={ACTIONS.REASSIGN}
            a={contractSubject(SUBJECTS.CONTRACT_PIPELINE, details)}
          >
            <Button.Icon
              type={BUTTON_TYPES.LINK}
              icon={<ReassignIcon />}
              onClick={() => setIsOpenReassign(true)}
              small
            />
          </Can>
        </div>
      </div>
      <Can
        I={ACTIONS.REASSIGN}
        a={contractSubject(SUBJECTS.CONTRACT_PIPELINE, details)}
      >
        {isOpenReassign && (
          <Reassign
            isRFP={false}
            isReassignPopupOpen={isOpenReassign}
            setIsReassignPopupOpen={setIsOpenReassign}
            ownerRole={user.role}
            ownerName={name}
            isCPR
            uuid={details?.uuid}
            isCommons={isCommons}
            params={{
              led_by: details?.led_by,
              contract_category: details.contract_category_id
            }}
            update={update}
          />
        )}
      </Can>
    </>
  )
}
