import { useCallback, useEffect, useRef, useState } from 'react'
import { Table } from 'components/Table'
import { isTableHasParams } from 'helper/common'
import { useDispatch, useSelector } from 'react-redux'
import { RequestArgs } from '../../../constants'
import { DEFAULT_PAGE } from '../../../components/Table/constants'
import axios, { CancelTokenSource } from 'axios'
import { setLoading } from '../../../redux/store/common/slice'
import { TQueryParams } from '../../../components/Table/types'
import { useCPRDetailsContext } from '../Providers/CPRDetailsContextProvider'
import { columns } from './constants'
import { ICPRHealthSystem } from './types'
import { getCPRHealthSystems } from '../api'
import { ACTIONS, SUBJECTS } from '../Abilities'
import { subject } from '@casl/ability'
import { useAbility } from '@casl/react'
import { ContractPipelineDetailsAbilityContext } from '../Providers/ContractPipelineDetailsAbilityProvider'
import DeleteModal from './DeleteModal'
import { getUser } from '../../../redux/store/user/getters'

const HealthSystemTable = () => {
  const dispatch = useDispatch()
  const user = useSelector(getUser)
  const ability = useAbility<any>(ContractPipelineDetailsAbilityContext)
  const contractDetailsContext = useCPRDetailsContext()

  const { details } = contractDetailsContext.state

  const [tableData, setTableData] = useState<ICPRHealthSystem[]>([])
  const [sortParams, setSortParams] = useState<RequestArgs<ICPRHealthSystem>>()
  const [firstLoad, setFirstLoad] = useState(true)
  const [totalNumber, setTotalNumber] = useState(0)
  const [pageInfo, setPageInfo] = useState({ ...DEFAULT_PAGE })
  const [selectedHS, setSelectedHS] = useState<ICPRHealthSystem | null>(null)
  const tableDataTokenRef = useRef<CancelTokenSource>()
  const canDeleteHs = ability.can(
    ACTIONS.DELETE,
    subject(SUBJECTS.HEALTH_SYSTEM, { ...details })
  )

  useEffect(() => {
    if (contractDetailsContext.state.needHSRefresh) {
      refreshTableData()
      contractDetailsContext.actions.setNeedHSRefresh(false)
    }
  }, [contractDetailsContext.state.needHSRefresh])

  const getTableData = useCallback(
    ({ sortField, sortOrder, page }: RequestArgs<ICPRHealthSystem>) => {
      if (!details.uuid) {
        return
      }

      dispatch(setLoading(true))

      if (tableDataTokenRef.current) {
        tableDataTokenRef.current.cancel()
      }

      const params: TQueryParams = {}

      if (sortField) {
        if (sortOrder) {
          params.ordering = `${sortOrder === 'descend' ? '-' : ''}${sortField}`
        }
        setSortParams({
          sortField,
          sortOrder
        })
      }

      const dataPage = page ? page : pageInfo

      params.limit = dataPage.pageSize
      params.offset = (dataPage.pageNumber - 1) * dataPage.pageSize
      tableDataTokenRef.current = axios.CancelToken.source()

      getCPRHealthSystems(details.uuid, {
        cancelToken: tableDataTokenRef.current?.token,
        params
      })
        .then((resp) => {
          if (!resp.data?.results) {
            return
          }
          setTableData(
            resp.data.results.map((i) => ({
              ...i,
              key: i.uuid
            }))
          )
          setTotalNumber(resp.data.count)
          if (firstLoad) {
            setFirstLoad(false)
          }
        })
        .finally(() => dispatch(setLoading(false)))
    },
    [details.uuid]
  )

  const handleTableChange = (pagination, _filters, sorter) => {
    const page = {
      pageNumber: pagination.current,
      pageSize: pagination.pageSize
    }

    getTableData({
      sortField: sorter.field,
      sortOrder: sorter.order,
      page
    })
    setPageInfo(page)
  }

  const handleChangePageSize = (pageSize) => {
    const page = {
      pageNumber: 1,
      pageSize
    }

    getTableData({
      ...sortParams,
      page
    })
    setPageInfo(page)
  }
  const refreshTableData = useCallback(async () => {
    getTableData({ ...sortParams, ...pageInfo })
  }, [getTableData, pageInfo, sortParams])

  useEffect(() => {
    getTableData({ ...sortParams, page: { ...DEFAULT_PAGE } })
    setPageInfo({ ...DEFAULT_PAGE })
  }, [details.uuid])

  useEffect(() => {
    return () => {
      if (!!tableDataTokenRef.current) {
        tableDataTokenRef.current.cancel()
      }
    }
  }, [])
  useEffect(() => {
    return () => {
      if (!!tableDataTokenRef.current) {
        tableDataTokenRef.current.cancel()
      }
    }
  }, [])
  const resetSelected = () => setSelectedHS(null)

  return (
    <div className="table-wrapper hs-list">
      {!firstLoad && (
        <Table<ICPRHealthSystem>
          dataSource={tableData}
          className="contacts-hs-list-table"
          columns={columns({
            canDeleteHs,
            setSelectedHS,
            userHsUuid: user?.health_system
          })}
          onChange={handleTableChange}
          rowSelection={undefined}
          hasSearchOrFilters={isTableHasParams('', false)}
          onChangePage={handleChangePageSize}
          pageSize={pageInfo.pageSize}
          pagination={{
            pageSize: pageInfo.pageSize,
            current: pageInfo.pageNumber,
            total: totalNumber
          }}
          createButton={undefined}
        />
      )}
      {selectedHS && (
        <DeleteModal
          contractCategoryName={details.contract_category_name}
          hsName={selectedHS.name}
          uuid={selectedHS.uuid}
          successCallback={refreshTableData}
          resetSelected={resetSelected}
        />
      )}
    </div>
  )
}

export default HealthSystemTable
