import { useMemo } from 'react'
import { useCPRDetailsContext } from '../Providers/CPRDetailsContextProvider'
import PreviewCPRDetails from './PreviewCPRDetails'
import '../../ContractDetails/ContractDetailsGeneral/styles.scss'
import ResponsibleUsers from '../Responsibles/ResponsibleUsers'
import { UpdateContractPipelineDetails } from '../UpdateContractPipelineDetails/UpdateContractPipelineDetails'

const General = () => {
  const contractDetailsContext = useCPRDetailsContext()

  const { canEditContract } = contractDetailsContext.state

  const ContractPipelineDetails = useMemo(
    () =>
      canEditContract ? (
        <UpdateContractPipelineDetails />
      ) : (
        <PreviewCPRDetails />
      ),
    [canEditContract]
  )
  return (
    <div className="contract-details-general">
      {ContractPipelineDetails}
      <div className="contract-details-general__responsible">
        <ResponsibleUsers />
      </div>
    </div>
  )
}

export default General
