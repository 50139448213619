import { FC } from 'react'
import { FormListFieldData, FormListOperation } from 'antd/lib/form/FormList'

import { LABELS } from 'constants/txt'
import { Input } from 'components/Input'
import { PROPS_INPUT_NUMBER } from 'features/ContractDetails/RebateConditions/RebateConditionsForm/constants'
import { percentageRebateValidator } from 'features/ContractDetails/RebateConditions/RebateConditionsForm/utils'
import { useRebateConditionsFormContext } from 'features/ContractDetails/RebateConditions/RebateConditionsForm/Providers/RebateConditionsFormProvider'

type Props = {
  rebate: FormListFieldData
  actions: FormListOperation
}

const PercentageRebateCondition: FC<Props> = (props) => {
  const { rebate } = props

  const { hasValidationError } = useRebateConditionsFormContext()

  const validateStatus = hasValidationError ? 'error' : undefined

  return (
    <Input.Number
      propsItem={{
        ...rebate,
        validateStatus,
        name: [rebate.name, 'percentage'],
        label: LABELS.REBATE_PERCENTAGE,
        rules: [
          {
            validator: percentageRebateValidator
          }
        ]
      }}
      propsInputNumber={PROPS_INPUT_NUMBER}
    />
  )
}

export default PercentageRebateCondition
