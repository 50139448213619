import { useMemo } from 'react'
import Popup from '../../../../components/Popup/Popup'
import { Button, BUTTON_TYPES } from '../../../../components/Button'
import { BTN_TXT } from '../../../../constants'
import {
  Typography,
  TYPOGRAPHY_WEIGHT
} from '../../../../components/Typography'
import { usePRSDetailsPopupContext } from '../Providers/PRSDetailsPopupProvider'

type TProps = {
  title: string
  onResendHandler?: () => void
}

const Popups = ({ title, onResendHandler }: TProps) => {
  const { resendPopup } = usePRSDetailsPopupContext()
  const resend = useMemo(() => {
    return (
      <Popup
        visible={resendPopup.state.visible}
        onCancel={() => resendPopup.actions.close()}
        title={title}
        width={442}
        footer={
          <>
            <Button
              onClick={() => resendPopup.actions.close()}
              type={BUTTON_TYPES.DEFAULT}
              upperCase
            >
              {BTN_TXT.BACK_TO_PRS_DETAILS}
            </Button>
            <Button
              type={BUTTON_TYPES.PRIMARY}
              upperCase
              onClick={onResendHandler}
            >
              {BTN_TXT.RESEND_PRS}
            </Button>
          </>
        }
      >
        <Typography.Headline6 weight={TYPOGRAPHY_WEIGHT.NORMAL}>
          PRS request will be resent for review to the selected Community
          responsible person. You will receive updates regarding review process.
          You will not be able to edit this request unless it is rejected.
        </Typography.Headline6>
      </Popup>
    )
  }, [resendPopup, onResendHandler])
  return <>{resend}</>
}

export default Popups
