import { notification } from 'antd'
import { ReactComponent as ErrorLabelIcon } from 'assets/svg/ErrorIcon.svg'
import { ReactComponent as Close24 } from 'assets/svg/Close24.svg'
import { TNotification } from './types'
import './notification.error.scss'

export const Error = ({
  description = '',
  duration = 5,
  top = 40,
  message = '',
  placement = 'topRight'
}: TNotification): void => {
  notification.error({
    placement,
    top,
    icon: <ErrorLabelIcon />,
    duration,
    message,
    description,
    closeIcon: <Close24 />
  })
}
