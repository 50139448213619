import { IUser } from 'redux/store/user/types'
import { TChatTypeUnion } from 'constants/types'

export const isOwnerCondition = (user: IUser) => ({
  owner_id: user.uuid
})

export const isInType = (inType: TChatTypeUnion[]) => ({
  type: {
    $in: inType
  }
})
