import { Tooltip } from '../../../components/Tooltip/Tooltip'
import { TOOLTIP } from '../../../constants'
import { Switch } from '../../../components/Switch'
import { CONTRACT_PIPELINES_STATUS } from '../../../components/Status'
import { TContractPipelineList } from './types'
import { useContractDetailsPagePopup } from '../../ContractsPipelineDetails/Providers/ContractDetailsPagePopupProvider'
import { useMemo } from 'react'

const JoinedColumn = ({
  pipeline,
  handelModal,
  userHs
}: {
  pipeline: TContractPipelineList
  handelModal: (pipeline: TContractPipelineList) => void
  userHs: string
}) => {
  const { joinPopup } = useContractDetailsPagePopup()

  const onClick = () => {
    handelModal(pipeline)
    joinPopup.actions.open()
  }
  const isJoinAvailable = useMemo(() => {
    return (
      (pipeline.led_by === 'cognus_commons' ||
        pipeline.led_by === 'community') &&
      pipeline.creator_health_system !== userHs &&
      pipeline.status !== CONTRACT_PIPELINES_STATUS.IN_PROCESS
    )
  }, [pipeline, userHs])
  return (
    <>
      <Tooltip title={TOOLTIP.JOIN_CONTRACT_PIPELINE}>
        <Switch
          checked={pipeline.joined}
          onChange={onClick}
          disabled={!isJoinAvailable}
        />
      </Tooltip>
    </>
  )
}

export default JoinedColumn
