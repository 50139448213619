import Popup from 'components/Popup/Popup'
import TermsOfServices from '../../components/TermsOfServices'
import { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { setLoading } from '../../redux/store/common/slice'
import './style.scss'
import Typography from '../../components/Typography/Typography'
import Logo from 'assets/svg/Logo.svg'
import { routes } from '../../router'
import useRouter from '../../hooks/useRouter'

const TermsOfUsePage = (): JSX.Element => {
  const dispatch = useDispatch()
  const { push } = useRouter()
  useEffect(() => {
    dispatch(setLoading(false))
  }, [])
  const handleRedirect = () => push(routes.login)
  return (
    <Popup.Terms
      visible={true}
      wrapClassName={'terms-wrap-modal'}
      footer={null}
      closable={false}
      title={
        <img
          className="sign-popup__logo terms-logo"
          onClick={handleRedirect}
          src={Logo}
          alt="Logo"
        />
      }
    >
      <div className="terms-of-use-wrapper container">
        <Typography.Headline3 className="terms-of-use-wrapper__header">
          Terms of Use
        </Typography.Headline3>
        <TermsOfServices />
      </div>
    </Popup.Terms>
  )
}

export default TermsOfUsePage
