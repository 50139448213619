import { useCallback, useEffect, useRef, useState } from 'react'
import { FormInstance } from 'antd/lib/form/hooks/useForm'
import { useDispatch } from 'react-redux'

import { VALIDATION_MESSAGES } from 'constants/txt'
import { notification } from 'components/Notification'
import { getChatAvailableMembersRequest } from 'features/ChatDetails/Members/api'
import { AddMembersForm } from 'features/ChatDetails/Members/AddMembers/types'
import { IPartialUserInfo } from 'redux/store/user/types'
import { setLoading } from 'redux/store/common/slice'
import { PopupReturnType } from 'hooks/usePopup'
import { addChatMembersAsync } from 'redux/store/chatDetails/actions'

export type UseAddMembersProps = {
  chatId: string
  form: FormInstance<AddMembersForm>
  addMembersPopup: PopupReturnType
}

const useAddMembers = (props: UseAddMembersProps) => {
  const { chatId, form, addMembersPopup } = props

  const dispatch = useDispatch<any>()

  const timeoutRef = useRef<NodeJS.Timeout>()

  const [availableMembers, setAvailableMembers] = useState<IPartialUserInfo[]>(
    []
  )
  const [search, setSearch] = useState('')
  const [isLoading, setIsLoading] = useState(false)
  const [selectedValues, setSelectedValues] = useState<string[]>([])

  const updateMembersRequestAsync = useCallback(
    async (values: AddMembersForm) => {
      try {
        dispatch(setLoading(true))

        const members = availableMembers.filter((member) =>
          values.members.includes(member.uuid)
        )

        await dispatch(addChatMembersAsync(chatId, members))

        notification.success({ message: VALIDATION_MESSAGES.SM0054 })

        addMembersPopup.actions.close()
      } catch (e) {
        console.error(e)
      } finally {
        dispatch(setLoading(false))
      }
    },
    [addMembersPopup.actions, availableMembers, chatId, dispatch]
  )

  const setChatAvailableMembersAsync = useCallback(
    async (id: string, args = {}) => {
      try {
        setIsLoading(true)

        const response = await getChatAvailableMembersRequest({
          ...args,
          chat: id
        })

        if (response?.data?.results) {
          setAvailableMembers(response.data.results)
        }
      } finally {
        setIsLoading(false)
      }
    },
    []
  )

  const updateShadowPickerListValues = useCallback(
    (uuid: string) =>
      setSelectedValues((prev) => {
        if (prev.find((item) => item === uuid)) {
          return prev.filter((item) => item !== uuid)
        } else {
          return [...prev, uuid]
        }
      }),
    []
  )

  const _listenSearchChange = useCallback(() => {
    if (search !== null) {
      setChatAvailableMembersAsync(chatId, { search })
    }
  }, [chatId, search, setChatAvailableMembersAsync])

  useEffect(() => {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current)
    }

    timeoutRef.current = setTimeout(_listenSearchChange, 500)
  }, [_listenSearchChange])

  useEffect(() => {
    return form.resetFields
  }, [form.resetFields])

  useEffect(() => {
    form.setFieldsValue({ members: selectedValues })
  }, [form, selectedValues])

  return {
    form,
    search,
    isLoading,
    availableMembers,
    updateMembersRequestAsync,
    setSearch,
    updateShadowPickerListValues
  }
}

export default useAddMembers
