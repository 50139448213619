import { useEffect, useRef, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useParams } from 'react-router-dom'
import axios, { CancelTokenSource } from 'axios'
import { CheckboxProps } from 'antd/lib/checkbox/Checkbox'
import { Popup } from 'components/Popup'
import { Button, BUTTON_TYPES } from 'components/Button'
import { BTN_TXT, PLACEHOLDERS, TOOLTIP } from 'constants/txt'
import { Table } from 'components/Table'
import { Input } from 'components/Input'
import { Tooltip } from 'components/Tooltip/Tooltip'
import { columns } from './constants'
import { useDebounce } from 'hooks/hooks'
import { setLoading } from 'redux/store/common/slice'
import { getVendorUsers } from './api'
import { updateRFP } from 'features/RFP/RFPDetails/api'
import { ReactComponent as Search16 } from 'assets/svg/Search16.svg'
import { ReactComponent as ArrowRight16 } from 'assets/svg/ArrowRight16.svg'
import { ReactComponent as ArrowDown16 } from 'assets/svg/ArrowDown16.svg'
import {
  TRFPVendorUser,
  TRFPVendorsParams,
  TRFPVendorUsersFilters,
  TRFPVendorUsersFiltersFunc,
  TRFPVendorUsersRequestData
} from '../types'
import { TAddPopup } from './types'
import './styles.scss'
import { Typography, TYPOGRAPHY_WEIGHT } from 'components/Typography'
import { DEFAULT_PAGE } from 'components/Table/constants'
import { ReactComponent as Close16 } from '../../../../assets/svg/Clos16.svg'
import { notification } from '../../../../components/Notification'

export const AddPopup = ({
  isAddPopupOpen,
  setIsAddPopupOpen,
  updateAssignedValues,
  selectedUsers
}: TAddPopup) => {
  const [tableData, setTableData] = useState<any[]>([])
  const [selectedRowKeys, setSelectedRowKeys] = useState<string[]>([])
  const [selectedRows, setSelectedRows] = useState<TRFPVendorUser[]>([])
  const [searchValue, setSearchValue] = useState('')
  const [sortParams, setSortParams] = useState<TRFPVendorUsersRequestData>()
  const [filters, setFilters] = useState<TRFPVendorUsersFilters>({})
  const [firstLoad, setFirstLoad] = useState(true)
  const [pageInfo, setPageInfo] = useState({ ...DEFAULT_PAGE })
  const timeoutRef = useRef<ReturnType<typeof setTimeout>>()
  const tableDataTokenRef = useRef<CancelTokenSource>()
  const [totalNumber, setTotalNumber] = useState(0)
  const dispatch = useDispatch()
  const { id } = useParams()
  const debouncedSearch = useDebounce(searchValue, 500)
  useEffect(() => {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current)
    }
    if (isAddPopupOpen && id) {
      timeoutRef.current = setTimeout(
        () => getTableData({ ...sortParams, ...filters }),
        firstLoad ? 0 : 500
      )
    }
    if (!isAddPopupOpen) {
      setFirstLoad(true)
    }
  }, [debouncedSearch, isAddPopupOpen, id])
  useEffect(() => {
    return () => {
      if (!!tableDataTokenRef.current) {
        tableDataTokenRef.current.cancel()
      }
    }
  }, [])
  const getTableData = ({
    sortField,
    sortOrder,
    filters,
    page
  }: TRFPVendorUsersRequestData) => {
    dispatch(setLoading(true))
    if (!!tableDataTokenRef.current) {
      tableDataTokenRef.current.cancel()
    }
    const params: TRFPVendorsParams = { ...filters }
    if (searchValue) {
      params.search = searchValue
    }
    if (sortField) {
      if (sortOrder) {
        params.ordering = `${sortOrder === 'descend' ? '-' : ''}${sortField}`
      }
      setSortParams({
        sortField,
        sortOrder
      })
    }
    const dataPage = page ? page : pageInfo
    params.limit = dataPage.pageSize
    params.offset = (dataPage.pageNumber - 1) * dataPage.pageSize
    tableDataTokenRef.current = axios.CancelToken.source()
    getVendorUsers(id as string, {
      cancelToken: tableDataTokenRef.current?.token,
      params
    })
      .then((resp) => {
        if (!resp.data?.results) {
          return
        }
        const tableData = resp.data.results.map((i) => ({
          first_name: i.name,
          name: i.name,
          uuid: i.uuid,
          key: i.uuid,
          children: i.users.map((j) => ({
            ...j,
            key: j.uuid
          }))
        }))
        setTableData(tableData)
        setTotalNumber(resp.data.count)
        if (firstLoad) {
          setFirstLoad(false)
        }
      })
      .finally(() => dispatch(setLoading(false)))
  }
  const handleSelectRow = (selectedRowKeys, selectedRow) => {
    setSelectedRowKeys(selectedRowKeys)
    setSelectedRows(selectedRow)
  }
  const handleCloseAddPopup = () => {
    setIsAddPopupOpen(false)
    setSelectedRowKeys([])
    setSelectedRows([])
  }

  useEffect(() => {
    if (!isAddPopupOpen) setSearchValue('')
  }, [isAddPopupOpen])

  const addUsers = () => {
    dispatch(setLoading(true))
    updateRFP(id as string, {
      vendor_contract_stewards: [
        ...selectedRowKeys,
        ...selectedUsers.map((i) => i.uuid)
      ]
    })
      .then(() => {
        updateAssignedValues()
        handleCloseAddPopup()
        notification.success({
          message: `Vendor${
            selectedRowKeys.length <= 1 ? ' has' : 's have'
          } been added.`
        })
      })
      .finally(() => dispatch(setLoading(false)))
  }
  const handleTableChange = (pagination, _filters, sorter) => {
    const page = {
      pageNumber: pagination.current,
      pageSize: pagination.pageSize
    }
    getTableData({
      sortField: sorter.field,
      sortOrder: sorter.order,
      filters,
      page
    })
    setPageInfo(page)
  }
  const handleChangePageSize = (pageSize) => {
    const page = {
      pageNumber: 1,
      pageSize
    }
    getTableData({
      ...sortParams,
      filters,
      page
    })
    setPageInfo(page)
  }
  const applyFilters: TRFPVendorUsersFiltersFunc = (field, appliedFilters) => {
    const newFilters = { ...filters, [field]: appliedFilters }
    setFilters(newFilters)
    getTableData({ ...sortParams, filters: newFilters })
  }
  return (
    <Popup
      visible={isAddPopupOpen}
      onCancel={handleCloseAddPopup}
      title="Add vendors"
      width={1376}
      footer={
        <>
          <Button
            type={BUTTON_TYPES.DEFAULT}
            onClick={handleCloseAddPopup}
            upperCase
          >
            {BTN_TXT.CANCEL}
          </Button>
          <Button
            type={BUTTON_TYPES.PRIMARY}
            onClick={addUsers}
            disabled={!selectedRowKeys.length}
            upperCase
          >
            {BTN_TXT.ADD}
          </Button>
        </>
      }
    >
      <div className="table-popup">
        <div className="table-wrapper__header">
          {!!selectedRowKeys.length ? (
            <Typography.Body1
              className="table-wrapper__select-title"
              weight={TYPOGRAPHY_WEIGHT.SEMI_BOLD}
            >
              Selected {selectedRowKeys.length} of {totalNumber}
            </Typography.Body1>
          ) : (
            <Input
              className="allow-clear"
              propsInput={{
                allowClear: { clearIcon: <Close16 /> },
                placeholder: PLACEHOLDERS.SEARCH_BY_VENDOR_OR_USERNAME,
                prefix: <Search16 />,
                value: searchValue,
                onChange: (e) => setSearchValue(e.target.value)
              }}
            />
          )}
        </div>
        {!firstLoad && (
          <Table<TRFPVendorUser>
            dataSource={tableData}
            className="rfp-vendor-users-table"
            columns={columns({
              applyFilters,
              filters
            })}
            pageSize={pageInfo.pageSize}
            pagination={{
              pageSize: pageInfo.pageSize,
              current: pageInfo.pageNumber,
              total: totalNumber
            }}
            onChangePage={handleChangePageSize}
            onChange={handleTableChange}
            hasSearchOrFilters={true}
            rowSelection={{
              selectedRowKeys,
              onChange: handleSelectRow,
              hideSelectAll: true,
              renderCell: (checked, record, _, node) => {
                if (
                  !checked &&
                  selectedRows.find(
                    (i) => i.vendor_name === record.vendor_name
                  ) &&
                  !selectedRowKeys.find((i) => i === record.uuid)
                ) {
                  return (
                    <Tooltip
                      title={
                        TOOLTIP.ONLY_ONE_RESPONSIBLE_CONTRACT_STEWARD_CAN_BE_SELECTED
                      }
                      overlayInnerStyle={{
                        display: 'block',
                        width: 254
                      }}
                      placement="topLeft"
                    >
                      {node}
                    </Tooltip>
                  )
                }
                return node
              },
              getCheckboxProps: (record) => {
                const checkboxProps: CheckboxProps = {}
                if (
                  selectedRows.find(
                    (i) => i.vendor_name === record.vendor_name
                  ) &&
                  !selectedRowKeys.find((i) => i === record.uuid)
                ) {
                  checkboxProps.disabled = true
                }
                return checkboxProps
              }
            }}
            expandable={{
              defaultExpandAllRows: true,
              expandIcon: ({ expanded, onExpand, record, expandable }) =>
                expandable ? (
                  expanded ? (
                    <div
                      className="expand-wrapper"
                      onClick={(e: any) => onExpand(record, e)}
                    >
                      <ArrowDown16 />
                    </div>
                  ) : (
                    <div
                      className="expand-wrapper"
                      onClick={(e: any) => onExpand(record, e)}
                    >
                      <ArrowRight16 />
                    </div>
                  )
                ) : (
                  <></>
                )
            }}
          />
        )}
      </div>
    </Popup>
  )
}
