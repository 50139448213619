import { NavLink } from 'react-router-dom'
import cn from 'classnames'
import { ILink } from './types'

export const Default = ({ className, children, to, ...props }: ILink) => (
  <NavLink
    className={cn('custom-link', className as string)}
    to={to}
    {...props}
  >
    {children}
  </NavLink>
)
