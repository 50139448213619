import cn from 'classnames'

import Loader from 'assets/svg/Loader.svg'
import './loader.spinner.scss'

type Props = {
  className?: string
}

export const Spinner = ({ className }: Props): JSX.Element | null => {
  return (
    <div className={cn('loader-spinner', className)}>
      <div className="loader-global__wrapper">
        <div className="loader-global__spinner">
          <img src={Loader} alt="Loader" />
        </div>
      </div>
    </div>
  )
}
