import { ReactComponent as ThreeDots } from 'assets/svg/Dots24.svg'
import { Dropdown } from 'antd'
import './styles.scss'
import cn from 'classnames'
import { Children, cloneElement, ReactElement, useState } from 'react'
import classNames from 'classnames'
import * as React from 'react'

export type TProps = {
  children: ReactElement
  className?: string
}
type OverlayFunc = () => React.ReactElement

export const ThreeDotsMenu = ({ children, className }: TProps) => {
  const [visible, setVisible] = useState(false)
  const modifiedChildren = Children.map(children, (child) =>
    cloneElement(child as ReactElement, { setVisible })
  )
  const overlay: ReactElement | OverlayFunc = () => (
    <div>{modifiedChildren}</div>
  )
  return (
    <Dropdown
      onVisibleChange={() => setVisible(!visible)}
      visible={visible}
      className={classNames('menu-items', className)}
      overlay={overlay}
      placement="bottomRight"
      trigger={['click']}
    >
      <div className={cn('dots-items')} onClick={(e) => e.stopPropagation()}>
        <ThreeDots />
      </div>
    </Dropdown>
  )
}
