import { ColumnsType } from 'antd/es/table'
import { SortOrder } from 'antd/es/table/interface'
import { Status, USER_STATUS_TYPES_TO_CLASSNAME } from 'components/Status'
import { Dropdown } from 'components/Dropdown'
import { Sorter } from 'components/Sorter/Sorter'
import {
  // fetchAllHospitals,
  fetchHealthSystemsList
} from 'features/HealthSystems/api'
import {
  USER_STATUSES_OPTIONS,
  COMMUNITY_ROLES_OPTIONS,
  ROLES_NAMES,
  COMMONS_ROLES_OPTIONS
} from 'constants/common'
import { ReactComponent as Filter16 } from 'assets/svg/Filter16.svg'
import { ReactComponent as FilterApplied16 } from 'assets/svg/FilterApplied16.svg'
import {
  TRFPHealthSystemUsersData,
  TRFPHealthSystemUsersFilters,
  TRFPHealthSystemUsersFiltersFunc,
  TRFPHealthSystemUsersColumnsFunc
} from './types'
import { fullFormatPhoneNumber } from 'helper/common'

export const columns = ({
  applyFilters,
  filters,
  community,
  isVendor = false
}: TRFPHealthSystemUsersColumnsFunc): ColumnsType<TRFPHealthSystemUsersData> => {
  const _columns: ColumnsType<TRFPHealthSystemUsersData> = [
    {
      dataIndex: 'first_name',
      key: 'first_name',
      title: ({ sortOrder, sortColumn }) => (
        <Sorter<TRFPHealthSystemUsersData>
          dataIndex="first_name"
          sortColumn={sortColumn}
          sortOrder={sortOrder as SortOrder}
        >
          User name
        </Sorter>
      ),
      width: 180,
      render: (_, { first_name, last_name }) => `${first_name} ${last_name}`,
      sorter: true,
      showSorterTooltip: false
    },
    {
      dataIndex: 'role',
      key: 'role',
      title: ({ sortOrder, sortColumn }) => (
        <Sorter<TRFPHealthSystemUsersData>
          dataIndex="role"
          sortColumn={sortColumn}
          sortOrder={sortOrder as SortOrder}
        >
          Role
        </Sorter>
      ),
      width: 160,
      filterDropdown: (props) => (
        <Dropdown.Filter<
          TRFPHealthSystemUsersFilters,
          TRFPHealthSystemUsersFiltersFunc,
          unknown
        >
          items={[...COMMUNITY_ROLES_OPTIONS, ...COMMONS_ROLES_OPTIONS]}
          applyFilters={applyFilters}
          appliedFilters={filters}
          field="roles"
          {...props}
        />
      ),
      render: (text) => ROLES_NAMES[text],
      filterIcon: !!filters?.roles?.length ? <FilterApplied16 /> : <Filter16 />,
      filterSearch: true,
      sorter: true,
      showSorterTooltip: false
    }
  ]

  if (!isVendor) {
    _columns.push({
      dataIndex: 'status',
      key: 'status',
      width: 130,
      title: ({ sortOrder, sortColumn }) => (
        <Sorter<TRFPHealthSystemUsersData>
          dataIndex="status"
          sortColumn={sortColumn}
          sortOrder={sortOrder as SortOrder}
        >
          Status
        </Sorter>
      ),
      filterDropdown: (props) => (
        <Dropdown.Filter<
          TRFPHealthSystemUsersFilters,
          TRFPHealthSystemUsersFiltersFunc,
          unknown
        >
          items={USER_STATUSES_OPTIONS}
          applyFilters={applyFilters}
          appliedFilters={filters}
          field="statuses"
          {...props}
        />
      ),
      render: (_, { status }) => (
        <Status.Default className={USER_STATUS_TYPES_TO_CLASSNAME[status]}>
          {status}
        </Status.Default>
      ),
      filterIcon: !!filters?.statuses?.length ? (
        <FilterApplied16 />
      ) : (
        <Filter16 />
      ),
      filterSearch: true,
      sorter: true,
      showSorterTooltip: false
    })
  }

  _columns.push({
    dataIndex: 'email',
    key: 'email',
    width: 160,
    title: ({ sortOrder, sortColumn }) => (
      <Sorter<TRFPHealthSystemUsersData>
        dataIndex="email"
        sortColumn={sortColumn}
        sortOrder={sortOrder as SortOrder}
      >
        Email
      </Sorter>
    ),
    sorter: true,
    showSorterTooltip: false
  })
  if (!isVendor)
    _columns.push({
      dataIndex: 'phone_number',
      key: 'phone_number',
      width: 170,
      title: ({ sortOrder, sortColumn }) => (
        <Sorter<TRFPHealthSystemUsersData>
          dataIndex="phone_number"
          sortColumn={sortColumn}
          sortOrder={sortOrder as SortOrder}
        >
          Phone #
        </Sorter>
      ),
      render: (_, { phone_number }) => fullFormatPhoneNumber(phone_number),
      sorter: true,
      showSorterTooltip: false
    })
  _columns.push({
    dataIndex: 'health_system',
    key: 'health_system',
    width: 170,
    title: ({ sortOrder, sortColumn }) => (
      <Sorter<TRFPHealthSystemUsersData>
        dataIndex="health_system"
        sortColumn={sortColumn}
        sortOrder={sortOrder as SortOrder}
      >
        Health system
      </Sorter>
    ),
    filterDropdown: (props) => (
      <Dropdown.Filter<
        TRFPHealthSystemUsersFilters,
        TRFPHealthSystemUsersFiltersFunc,
        unknown
      >
        applyFilters={applyFilters}
        appliedFilters={filters}
        searchCallback={() => fetchHealthSystemsList(community)}
        field="health_systems"
        asyncSearch
        {...props}
      />
    ),
    filterIcon: !!filters?.health_systems?.length ? (
      <FilterApplied16 />
    ) : (
      <Filter16 />
    ),
    filterSearch: true,
    sorter: true,
    showSorterTooltip: false
  })
  _columns.push({
    dataIndex: 'hospital',
    key: 'hospital',
    width: 180,
    title: ({ sortOrder, sortColumn }) => (
      <Sorter<TRFPHealthSystemUsersData>
        dataIndex="hospital"
        sortColumn={sortColumn}
        sortOrder={sortOrder as SortOrder}
      >
        Hospital
      </Sorter>
    ),
    filterIcon: !!filters?.hospitals?.length ? (
      <FilterApplied16 />
    ) : (
      <Filter16 />
    ),
    filterSearch: true,
    sorter: true,
    showSorterTooltip: false
  })

  return _columns
}
