import { FC, memo } from 'react'
import { TMessageGroup } from './types'
import {
  Typography,
  TYPOGRAPHY_WEIGHT
} from '../../../../components/Typography'
import MessageItem from './MessageItem'
import '../styles.scss'
import { ILRenderItem } from '../../../../components/InfiniteList/InfiniteList'
import { checkIsToday } from '../../../../helper/dates'
import { timelineDateFormat } from '../../../../components/Timelines/constants'
import { getCountOfUnreadMessages } from 'redux/store/chatDetails/getters'
import { useSelector } from 'react-redux'
import cn from 'classnames'

export const getLabel = (label: string, countOfunreadMessages: number) => {
  if (label === 'unread') {
    return countOfunreadMessages > 1 ? 'Unread messages' : 'Unread message'
  }
  return checkIsToday(label, timelineDateFormat) ? 'Today' : label
}

const MessagesGroup: FC<ILRenderItem<TMessageGroup>> = (props) => {
  const { item } = props
  const [label, chatHistory] = item
  const unreadMessages = useSelector(getCountOfUnreadMessages)
  const isAuthorBlock = (idx) => {
    return (
      chatHistory[idx]?.message.created_at?.slice(0, -4) !==
        chatHistory[idx - 1]?.message.created_at?.slice(0, -4) ||
      chatHistory[idx]?.message.author?.uuid !==
        chatHistory[idx - 1]?.message.author?.uuid
    )
  }
  return (
    <div className="messages-wrapper-group" key={label + chatHistory.length}>
      <>
        <>
          <Typography.Label
            weight={TYPOGRAPHY_WEIGHT.SEMI_BOLD}
            className="messages-wrapper-group__time"
          >
            {getLabel(label, unreadMessages)}
            <div className={cn({ 'unread-marker': label === 'unread' })} />
          </Typography.Label>
        </>

        {chatHistory.map((mess, idx) => (
          <MessageItem
            {...mess}
            key={mess.message.uuid}
            isAuthorBlock={isAuthorBlock(idx)}
          />
        ))}
      </>
    </div>
  )
}

const arePropsEqual = (
  pProps: ILRenderItem<TMessageGroup>,
  nProps: ILRenderItem<TMessageGroup>
) => {
  const [pLabel, pHistory] = pProps.item
  const [nLabel, nHistory] = nProps.item
  return pLabel === nLabel && pHistory?.length === nHistory?.length
}

export default memo(MessagesGroup, arePropsEqual)
