import TimePeriod from 'components/RadioGroup/radioGroup.timePeriod'
import RatingRange from 'components/RadioGroup/radioGroup.ratingRange'
import Default from './radioGroup.default'
import Buttons from './radioGroup.buttons'

const RadioGroup = () => 'Please, provide RadioGroup component'

RadioGroup.TimePeriod = TimePeriod
RadioGroup.RatingRange = RatingRange
RadioGroup.Default = Default
RadioGroup.Buttons = Buttons

export default RadioGroup
