import { safeString } from 'helper/common'
import { ROLES } from 'features/Permission'
import { VendorDetailsForm } from './types'
import { VendorDetails, VendorWithAdminCreationRequestData } from '../../types'

export const convertDetailsToForm = (
  details: VendorDetails,
  isStakeholder?: boolean
): VendorDetailsForm => ({
  vendor: {
    name: details.name,
    description: safeString(details.description),
    phone_number: safeString(details.phone_number),
    address: {
      street: safeString(details.address?.street),
      city: safeString(details.address?.city),
      state: safeString(details.address?.state),
      zip_code: safeString(details.address?.zip_code)
    },
    vendor_permission:
      details.vendor_permission || isStakeholder ? 'guest' : 'full_access'
  },
  contractAdmin: {
    first_name: '',
    last_name: '',
    email: '',
    phone_number: ''
  },
  contractSteward: {
    first_name: '',
    last_name: '',
    email: '',
    phone_number: ''
  }
})

export const convertFormToCreationDetails = (
  form: VendorDetailsForm
): VendorWithAdminCreationRequestData => {
  const requestData: VendorWithAdminCreationRequestData = {
    name: form.vendor.name,
    vendor_permission: form.vendor.vendor_permission
  }
  if (Object.values(form.vendor.address).find((i) => !!i)) {
    requestData.address = form.vendor.address
  }
  if (form.vendor.vendor_permission === 'full_access') {
    if (form.contractAdmin) {
      requestData.contract_admin = {
        first_name: form.contractAdmin.first_name,
        last_name: form.contractAdmin.last_name,
        email: form.contractAdmin.email,
        role: ROLES.VENDOR_CONTRACT_ADMIN
      }
    }
  } else {
    if (form.contractSteward) {
      requestData.contract_steward = {
        first_name: form.contractSteward.first_name,
        last_name: form.contractSteward.last_name,
        email: form.contractSteward.email,
        role: ROLES.VENDOR_CONTRACT_STEWARD
      }
    }
  }
  if (form.vendor.description) {
    requestData.description = form.vendor.description
  }
  if (form.vendor.phone_number) {
    const number = !form.vendor.phone_number?.toString()?.includes('+1')
      ? '+1' + form.vendor.phone_number
      : form.vendor.phone_number
    requestData.phone_number = number
  }
  if (form?.contractAdmin?.phone_number) {
    const number = !form.contractAdmin.phone_number?.toString()?.includes('+1')
      ? '+1' + form.contractAdmin.phone_number
      : form.contractAdmin.phone_number
    if (!!requestData?.contract_admin?.phone_number)
      requestData.contract_admin.phone_number = number
  }
  if (form?.contractSteward?.phone_number) {
    const number = !form.contractSteward.phone_number
      ?.toString()
      ?.includes('+1')
      ? '+1' + form.contractSteward.phone_number
      : form.contractSteward.phone_number
    if (!!requestData?.contract_steward?.phone_number)
      requestData.contract_steward.phone_number = number
  }

  return requestData
}
