import { TMessageProps } from './types'
import { FC, useMemo, memo, useRef, useEffect } from 'react'
import { Avatar } from 'components/Avatar/Avatar'
import { Typography, TYPOGRAPHY_WEIGHT } from 'components/Typography'
import '../styles.scss'
import cn from 'classnames'
import { defaultTimeFormat } from 'helper/dates'
import { useDispatch, useSelector } from 'react-redux'
import { getUser } from 'redux/store/user/getters'
import ReplyToMessage from './ReplyToMessage'
import { setMessageToReply } from 'redux/store/chatHistory/slice'
import MessageToReply from '../../MessageToReply/MessageToReply'
import { useChatContext } from '../../Providers/ChatContextProvider'
import { ReactComponent as Check16 } from 'assets/svg/Check16.svg'
import { ReactComponent as DoubleCheck16 } from 'assets/svg/DoubleCheck16.svg'
import { ReactComponent as Docs16 } from 'assets/svg/Docs16.svg'
import { AttachmentsList } from '../../../../components/AttachmentsList/AttachmentsList'

const MessageItem: FC<TMessageProps> = (props) => {
  const { message, isAuthorBlock, uuid, order_number, read_by_members } = props
  const { author } = message
  const { state } = useChatContext()
  const { historyRef, searchValue, currentSearchValue } = state
  const messageRef = useRef<HTMLDivElement>(null as any)
  const user = useSelector(getUser)

  const dispatch = useDispatch()

  const isCurrentUser = useMemo(() => {
    return user.uuid === author?.uuid
  }, [user.uuid, author?.uuid])

  const avatarProps = () => {
    return {
      name: author?.first_name.charAt(0) + author?.last_name.charAt(0),
      src: author?.avatar?.file_preview
    }
  }

  const authorFullName = () => {
    return isCurrentUser ? 'You' : author?.first_name + ' ' + author?.last_name
  }

  const hadLeReplyToMessage = () => {
    dispatch(setMessageToReply({ message, uuid, order_number }))
  }
  const regParam = useMemo(
    () =>
      new RegExp(
        `${searchValue.replace(/([.?*+^$[\]\\(){}|-])/g, '\\$1')}`,
        'i'
      ),
    [searchValue]
  )
  useEffect(() => {
    if (messageRef?.current) {
      const data = {}
      data[message.uuid] = {
        x: messageRef.current.offsetLeft,
        y: messageRef.current.offsetTop
      }
      historyRef.current = { ...historyRef.current, ...data }
    }
  }, [messageRef])

  useEffect(() => {
    if (currentSearchValue?.message.uuid === message.uuid)
      messageRef.current.scrollIntoView()
  }, [currentSearchValue])

  return (
    <div
      className={cn('message-item-container', {
        revert: isCurrentUser
      })}
      ref={messageRef}
    >
      {isAuthorBlock && (
        <div className="message-item-container__author row align-center">
          <Avatar {...avatarProps()} />
          <Typography.Label
            className="message-item-container__author__name"
            weight={TYPOGRAPHY_WEIGHT.SEMI_BOLD}
          >
            {authorFullName()}
          </Typography.Label>
          <Typography.Label className="message-item-container__author__current-time">
            {defaultTimeFormat(message.created_at)}
          </Typography.Label>
        </div>
      )}
      {message.reply && (
        <MessageToReply
          body={message.reply.body}
          uuid={message.reply.uuid}
          full_name={
            message.reply.author.first_name +
            ' ' +
            message.reply.author.last_name
          }
        />
      )}
      <div className="message-item-container__wrapper">
        {!!message.body && (
          <div className="message-item-container__message">
            <Typography.Body1>
              <p
                dangerouslySetInnerHTML={{
                  __html: message.body.replace(
                    regParam,
                    (match) => `<span class="search-param">${match}</span>`
                  )
                }}
              />
            </Typography.Body1>
          </div>
        )}
        <ReplyToMessage hadLeReplyToMessage={hadLeReplyToMessage} />
        {isCurrentUser && !!message.body && (
          <div className="read-by-members">
            {read_by_members ? <DoubleCheck16 /> : <Check16 />}
          </div>
        )}
      </div>
      {!!message?.attachments?.length && (
        <div
          className={cn('message-item-container__attachments', {
            current: isCurrentUser,
            read_by_members: read_by_members,
            none: !read_by_members
          })}
        >
          <AttachmentsList.ChatAttachment
            label=""
            icon={<Docs16 className="attachment__icon" />}
            files={message?.attachments}
            disableActions={true}
          />
        </div>
      )}
    </div>
  )
}

const arePropsEqual = (pProps: TMessageProps, nProps: TMessageProps) =>
  pProps.isAuthorBlock === nProps.isAuthorBlock

export default memo(MessageItem, arePropsEqual)
