import { IUser } from '../../../redux/store/user/types'
import { CPRStatusUnion } from '../types'

export const isSameHSCondition = (user: IUser) => ({
  creator_health_system: user.health_system
})

export const isCreatorCommons = {
  'creator.role': {
    $regex: /commons/
  }
}
export const isResponsibleCondition = (user: IUser) => ({
  'responsible.uuid': user.uuid
})

export const isInCommunity = (user: IUser) => ({
  'community.uuid': user.community
})

export const isInStatuses = (inStatuses: CPRStatusUnion[]) => ({
  status: {
    $in: inStatuses
  }
})
