import {
  createContext,
  FC,
  PropsWithChildren,
  useCallback,
  useContext,
  useEffect,
  useLayoutEffect,
  useMemo,
  useState
} from 'react'

import useSegments from 'hooks/useSegments'
import { SegmentOption } from 'constants/types'
import { INITIAL_HEADER_OPTIONS } from 'pages/ProductRequests/constants'
import { usePRSDetailsContext } from 'features/PRS/PRSDetails/Providers/PRSDetailsContextProvider'
import { useParams } from 'react-router-dom'
import useTabs, {
  TabsReturnActions,
  TabsReturnState
} from '../../../../hooks/useTabs'
import {
  initialStateForSurveyCreation,
  PRS_SURVEY_TABS
} from '../Voting/Survey/constants'
import {
  getSurvey,
  getSurveyResponseAll,
  getSurveyResponseIndividual,
  voteSurvey
} from 'pages/ProductRequests/api'
import { TQuestionsForm } from 'components/Forms/forms.surveyQuestions.d'
import { ACTIONS, SUBJECTS } from '../../Abilities'
import { subject } from '@casl/ability'
import { usePrsDetailsAbility } from './PrsDetailsAbilityProvider'
import { notification } from '../../../../components/Notification'
import { TVotes } from '../types'
import { usePRSDetailsPopupContext } from './PRSDetailsPopupProvider'
import { useDispatch, useSelector } from 'react-redux'
import { getUser } from 'redux/store/user/getters'
import { setLoading } from 'redux/store/common/slice'

type ContextProps = {
  state: {
    isVotingInProgress: boolean
    headerOptions: SegmentOption[]
    activeHeaderOptionValue: SegmentOption['value']
    votingData: any
    initialVotingData: any
    isEditMode: boolean
    isError: boolean
    isOpen: boolean
    activeTab: string
    isAbleToVote: boolean
    isCanViewSurveyResponse: boolean
    votingResponse: any
    isIndividualResponse: boolean
  } & TabsReturnState
  actions: {
    setHeaderOptions: (options: SegmentOption[]) => void
    setVotingData: (val: any) => void
    setActiveHeaderOptionValue: (value: SegmentOption['value']) => void
    getSurveyForVoting: () => void
    resetForm: () => void
    setIsError: (val: boolean) => void
    setIsOpen: (val: boolean) => void
    setActiveTab: (val: string) => void
    updateVotingData: (val: TQuestionsForm) => void
    setVoteSurvey: () => void
    setSelectedOptions: (uuid: string, data: string[]) => void
    getSurveyResponseVoting: (uuid?: string) => void
  } & TabsReturnActions
}

const PRSVotingContext = createContext<ContextProps>({
  state: null!,
  actions: null!
})

const PRSVotingContextProvider: FC<PropsWithChildren> = ({ children }) => {
  const prsDetailsContext = usePRSDetailsContext()
  const { tab } = useParams()
  const { data } = prsDetailsContext.state

  const ability = usePrsDetailsAbility()

  const dispatch = useDispatch()

  const { createSurveyPopup } = usePRSDetailsPopupContext()

  const { id } = useParams()

  const user = useSelector(getUser)

  const segmentsContext = useSegments({ options: INITIAL_HEADER_OPTIONS })

  const [activeTab, setActiveTab] = useState(PRS_SURVEY_TABS[0].key)

  const [isError, setIsError] = useState<boolean>(false)
  const [isOpen, setIsOpen] = useState(false)
  const [isIndividualResponse, setIsIndividualResponse] = useState(false)

  const { state: tabsState, actions: tabsActions } = useTabs({
    tabs: PRS_SURVEY_TABS,
    activeTab: tab || PRS_SURVEY_TABS[0].key
  })

  const isCanViewSurvey = useMemo(() => {
    return Boolean(
      ability.can(ACTIONS.CREATE, subject(SUBJECTS.SURVEY, { ...data }))
    )
  }, [data])

  const isCanViewSurveyResponse = useMemo(() => {
    return Boolean(
      ability.can(ACTIONS.VIEW, subject(SUBJECTS.VOTING_RESPONSE, { ...data }))
    )
  }, [data])

  useEffect(() => {
    setOptions(
      options.map((i) =>
        i.value === 2 ? { ...i, disabled: !isCanViewSurveyResponse } : i
      )
    )
  }, [isCanViewSurveyResponse])

  const { setTabs } = tabsActions

  const { options, activeOptionValue } = segmentsContext.state
  const { setOptions, setActiveOptionValue } = segmentsContext.actions
  const [votingData, setVotingData] = useState<TQuestionsForm>(
    initialStateForSurveyCreation
  )
  const [votingResponse, setVotingResponse] = useState<TQuestionsForm>(
    initialStateForSurveyCreation
  )
  const [initialVotingData, setInitialVotingData] = useState<TQuestionsForm>(
    initialStateForSurveyCreation
  )

  const setSelectedOptions = useCallback(
    (uuid, data) => {
      const results = votingData.questions.map((i) =>
        i.uuid === uuid
          ? {
              ...i,
              selectedOptions: data
            }
          : i
      )
      setVotingData({ questions: results })
    },
    [votingData]
  )

  const isVotingInProgress = true
  useLayoutEffect(() => {
    setTabs(
      PRS_SURVEY_TABS.map((i) => {
        const isResponseDisabled = true
        return {
          ...i,
          disabled: isResponseDisabled
        }
      })
    )
  }, [data, setTabs])

  const resetForm = () => {
    setVotingData(initialVotingData)
  }

  const isAbleToVote = useMemo(
    () => ability.can(ACTIONS.OPEN, subject(SUBJECTS.VOTING, { ...data })),
    [data]
  )
  const updateVotingData = (values) => {
    setVotingData(values)
  }

  const getSurveyForVoting = useCallback(() => {
    if (id) {
      getSurvey(id).then((res) => {
        setVotingData({
          questions: res.data.results
        })
        setInitialVotingData({
          questions: res.data.results
        })
      })
    }
  }, [id])

  const getSurveyResponseVoting = useCallback(
    (user_uuid?: string) => {
      if (id) {
        dispatch(setLoading(true))
        if (user_uuid) {
          setIsIndividualResponse(true)
          getSurveyResponseIndividual(id, user_uuid)
            .then((res) => {
              const newData = res.data.results.reduce((acc, curr) => {
                acc.set(curr.option, curr)
                return acc
              }, new Map())
              setVotingResponse(newData)
            })
            .finally(() => dispatch(setLoading(false)))
        } else {
          getSurveyResponseAll(id)
            .then((res) => {
              setIsIndividualResponse(false)
              const newData = res.data.results.reduce((acc, curr) => {
                acc.set(curr.uuid, curr)
                return acc
              }, new Map())
              setVotingResponse(newData)
            })
            .finally(() => dispatch(setLoading(false)))
        }
      }
    },
    [id]
  )

  const setVoteSurvey = useCallback(() => {
    if (
      votingData.questions.find(
        (i) => !i?.selectedOptions?.length && i.is_required
      )
    ) {
      setIsError(true)
      notification.error({
        message: 'Please, complete all required questions'
      })
    } else if (id) {
      const votes = [] as TVotes
      votingData.questions.forEach((i) => {
        i.options.forEach((option) => {
          if (option?.uuid && i.selectedOptions?.includes(option?.uuid)) {
            votes.push({ option: option?.uuid, text: option.text })
          }
        })
      })
      voteSurvey(id, { answers: votes }).then(() => {
        createSurveyPopup.actions.close()
        getSurveyResponseVoting(user.uuid)
        notification.success({
          message: 'Survey has been submitted. Thank you for voting!'
        })
      })
    }
  }, [id, votingData])

  useEffect(() => {
    if (
      isCanViewSurvey ||
      isCanViewSurveyResponse ||
      ability.can(ACTIONS.OPEN, subject(SUBJECTS.VOTING, { ...data }))
    )
      getSurveyForVoting()
  }, [id, isCanViewSurvey, ability, data, isCanViewSurveyResponse])

  useEffect(() => {
    if (isCanViewSurveyResponse) getSurveyResponseVoting()
    if (isAbleToVote) getSurveyResponseVoting(user.uuid)
  }, [id, isCanViewSurveyResponse, isAbleToVote])

  const isEditMode = useMemo(() => {
    return (
      Boolean(initialVotingData?.questions?.find((i) => !!i?.uuid)) &&
      ability.can(ACTIONS.EDIT, subject(SUBJECTS.SURVEY, { ...data }))
    )
  }, [initialVotingData])

  const state = useMemo(
    () => ({
      ...tabsState,
      headerOptions: options,
      isVotingInProgress,
      activeHeaderOptionValue: activeOptionValue,
      votingData,
      isEditMode,
      isError,
      initialVotingData,
      isOpen,
      activeTab,
      isAbleToVote,
      isCanViewSurveyResponse,
      votingResponse,
      isIndividualResponse
    }),
    [
      tabsState,
      activeOptionValue,
      isVotingInProgress,
      options,
      votingData,
      isEditMode,
      isError,
      initialVotingData,
      isOpen,
      activeTab,
      isAbleToVote,
      isCanViewSurveyResponse,
      votingResponse,
      isIndividualResponse
    ]
  )

  const actions = useMemo(
    () => ({
      ...tabsActions,
      setHeaderOptions: setOptions,
      setActiveHeaderOptionValue: setActiveOptionValue,
      setVotingData,
      getSurveyForVoting,
      setIsError,
      resetForm,
      setIsOpen,
      setActiveTab,
      updateVotingData,
      setVoteSurvey,
      setSelectedOptions,
      getSurveyResponseVoting
    }),
    [
      tabsActions,
      setActiveOptionValue,
      setOptions,
      setVotingData,
      getSurveyForVoting,
      setIsError,
      resetForm,
      setIsOpen,
      setActiveTab,
      updateVotingData,
      setVoteSurvey,
      setSelectedOptions,
      getSurveyResponseVoting
    ]
  )

  return (
    <PRSVotingContext.Provider value={{ state, actions }}>
      {children}
    </PRSVotingContext.Provider>
  )
}

export const usePRSVotingContext = () => useContext(PRSVotingContext)

export default PRSVotingContextProvider
