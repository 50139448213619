import { API_DICTIONARY, HTTP_METHODS } from 'constants/api'
import { ResponseWithPagination } from 'constants/types'
import { TQueryParams } from 'components/Table/types'
import { IPartialUserInfo } from 'redux/store/user/types'
import Fetcher from 'services/Fetcher'
import { UpdateChatMembersRequestData } from './types'

const fetcher = new Fetcher({})

export const getChatAvailableMembersRequest = async (
  params: TQueryParams & { chat: string }
) =>
  fetcher.request<unknown, ResponseWithPagination<IPartialUserInfo>>({
    url: `${API_DICTIONARY.CHATS}${API_DICTIONARY.GROUP_AVAILABLE_USERS}`,
    method: HTTP_METHODS.GET,
    params
  })

export const updateChatMembersRequest = async (
  id: string,
  data: UpdateChatMembersRequestData
) =>
  fetcher.request<UpdateChatMembersRequestData, unknown>({
    url: `${API_DICTIONARY.CHATS}${id}/members/`,
    method: HTTP_METHODS.POST,
    data
  })
