import moment from 'moment'
import { ColumnsType } from 'antd/es/table'
import { SortOrder } from 'antd/es/table/interface'
import { Status } from 'components/Status'
import { Link } from 'components/Link'
import { Dropdown } from 'components/Dropdown'
import { Sorter } from 'components/Sorter/Sorter'
import { DescriptionCell } from 'components/DescriptionCell/DescriptionCell'
import { CellWithSubtitle } from 'components/CellWithSubtitle/CellWithSubtitle'
import { fetchContractCategories } from 'pages/CRUDUser/api'
import { durationAsString } from 'helper/dates'
import {
  RFP_STATUS_TO_CLASSNAME,
  RFP_STATUSES_OPTIONS,
  RFP_STATUSES_NAMES
} from '../constants'
import { ReactComponent as Filter16 } from 'assets/svg/Filter16.svg'
import { ReactComponent as FilterApplied16 } from 'assets/svg/FilterApplied16.svg'
import {
  TRFPListColumnsFunc,
  TRFPListItem,
  TRFPListFilters,
  TRFPListFiltersFunc
} from './types'

export const columns = ({
  applyFilters,
  filters
}: TRFPListColumnsFunc): ColumnsType<TRFPListItem> => [
  {
    dataIndex: 'name',
    key: 'name',
    width: 10,
    title: ({ sortOrder, sortColumn }) => (
      <Sorter<TRFPListItem>
        dataIndex="name"
        sortColumn={sortColumn}
        sortOrder={sortOrder as SortOrder}
      >
        RFP Name
      </Sorter>
    ),
    render: (_, { name, number, uuid }) => (
      <CellWithSubtitle
        title={<Link to={`rfp/${uuid}`}>{name}</Link>}
        subtitle={number}
      />
    ),
    sorter: true,
    showSorterTooltip: false
  },
  {
    dataIndex: 'contract_categories',
    key: 'contract_categories',
    width: 240,
    title: 'Category',
    filterDropdown: (props) => (
      <Dropdown.Filter<TRFPListFilters, TRFPListFiltersFunc, unknown>
        applyFilters={applyFilters}
        appliedFilters={filters}
        searchCallback={fetchContractCategories}
        field="contract_categories"
        asyncSearch
        className="rfp-list-dropdown-table"
        {...props}
      />
    ),
    render: (_, { contract_categories }) => {
      const text = contract_categories.map((i) => i.name).join(', ')
      return <DescriptionCell content={text} text={text} />
    },
    filterIcon: !!filters?.contract_categories?.length ? (
      <FilterApplied16 />
    ) : (
      <Filter16 />
    ),
    filterSearch: true,
    showSorterTooltip: false
  },
  {
    dataIndex: 'expire_at',
    key: 'expire_at',
    title: ({ sortOrder, sortColumn }) => (
      <Sorter<TRFPListItem>
        dataIndex="expire_at"
        sortColumn={sortColumn}
        sortOrder={sortOrder as SortOrder}
      >
        Time left
      </Sorter>
    ),
    render: (_, { expire_at }) =>
      expire_at ? durationAsString(moment(), expire_at) : '–',
    sorter: true,
    showSorterTooltip: false
  },
  {
    dataIndex: 'status',
    key: 'status',
    title: ({ sortOrder, sortColumn }) => (
      <Sorter<TRFPListItem>
        dataIndex="status"
        sortColumn={sortColumn}
        sortOrder={sortOrder as SortOrder}
      >
        Status
      </Sorter>
    ),
    filterDropdown: (props) => (
      <Dropdown.Filter<TRFPListFilters, TRFPListFiltersFunc, unknown>
        items={RFP_STATUSES_OPTIONS}
        applyFilters={applyFilters}
        appliedFilters={filters}
        field="statuses"
        {...props}
      />
    ),
    render: (_, { status }) => (
      <Status.Default className={RFP_STATUS_TO_CLASSNAME[status]}>
        {RFP_STATUSES_NAMES[status]}
      </Status.Default>
    ),
    filterIcon: !!filters?.statuses?.length ? (
      <FilterApplied16 />
    ) : (
      <Filter16 />
    ),
    filterSearch: true,
    sorter: true,
    showSorterTooltip: false
  },
  {
    dataIndex: 'items_quantity',
    key: 'items_quantity',
    title: ({ sortOrder, sortColumn }) => (
      <Sorter<TRFPListItem>
        dataIndex="items_quantity"
        sortColumn={sortColumn}
        sortOrder={sortOrder as SortOrder}
      >
        Usage
      </Sorter>
    ),
    render: (_, { items_quantity }) => (items_quantity ? items_quantity : '–'),
    sorter: true,
    showSorterTooltip: false
  },
  {
    dataIndex: 'total_price',
    key: 'total_price',
    title: ({ sortOrder, sortColumn }) => (
      <Sorter<TRFPListItem>
        dataIndex="total_price"
        sortColumn={sortColumn}
        sortOrder={sortOrder as SortOrder}
      >
        Total cost
      </Sorter>
    ),
    render: (_, { total_price }) => (
      <CellWithSubtitle
        className="bidding-table-total-price-row"
        title={total_price ? total_price : '–'}
      />
    ),
    sorter: true,
    width: 150,
    showSorterTooltip: false
  }
]
