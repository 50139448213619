import { useState } from 'react'
import { useLocation } from 'react-router-dom'
import { Container } from '../../components/Container/Container'
import { Menu } from '../../components/Menu/Menu'
import { Communities } from './Communities/Communities'
import { Vendors } from './Vendors/Vendors'
import { Commons } from './Commons/Commons'
import { MY_COMMUNITY_TABS } from './constants'
import { LocationState } from './types'
import './styles.scss'

export const MyCommunity = () => {
  const { state } = useLocation()
  const locationState = (state as LocationState) || {}
  const [activeTab, setActiveTab] = useState(
    locationState.redirectTab || MY_COMMUNITY_TABS[0].key
  )

  return (
    <Container
      title="My Community"
      menu={
        <Menu
          mode="horizontal"
          onSelect={(e) => setActiveTab(e.key)}
          selectedKeys={[activeTab]}
          defaultSelectedKeys={[activeTab]}
          items={MY_COMMUNITY_TABS}
        />
      }
    >
      {activeTab === MY_COMMUNITY_TABS[0].key && <Communities />}
      {activeTab === MY_COMMUNITY_TABS[1].key && <Vendors />}
      {activeTab === MY_COMMUNITY_TABS[2].key && <Commons />}
    </Container>
  )
}
