import { DatePicker, Form } from 'antd'
import cn from 'classnames'
import './datepicker.default.scss'
import { TDatePickerDefault as Props } from './types'
const { RangePicker } = DatePicker
import './styles.scss'
import './datepicker.range.scss'
import { ReactComponent as Calendar16 } from '../../assets/svg/Calendar16.svg'
import { ReactComponent as ArrowRight16 } from '../../assets/svg/ArrowRight16.svg'
import { ReactComponent as ArrowLeft16 } from '../../assets/svg/ArrowBack16.svg'

export const Range = ({ className, ...props }: Props) => {
  return (
    <Form.Item
      className={cn('custom-datepicker datepicker-range', className)}
      colon={false}
      {...props.propsItem}
    >
      <RangePicker
        superPrevIcon={props.isMonthRange ? <ArrowLeft16 /> : false}
        superNextIcon={props.isMonthRange ? <ArrowRight16 /> : false}
        getPopupContainer={(node) => node}
        dropdownClassName="datepicker-dropdown"
        allowClear={false}
        separator={
          <div className="datepicker__separator">
            <Calendar16 className="datepicker__separator-icon" />
            <span className="datepicker__separator-line"> </span>
          </div>
        }
        {...props.propsRangeDate}
      />
    </Form.Item>
  )
}
