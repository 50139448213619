import {
  FC,
  createContext,
  PropsWithChildren,
  useContext,
  useMemo
} from 'react'

import usePopup, { PopupReturnType } from 'hooks/usePopup'

type ContextProps = {
  contactPersonsPopup: PopupReturnType
  uploadDocumentsPopup: PopupReturnType
  acceptContractPopup: PopupReturnType
  evaluateContractPopup: PopupReturnType
  initiateClinicalReviewPopup: PopupReturnType
  terminateContractPopup: PopupReturnType
  clinicalReviewPopup: PopupReturnType
}

const basePopup = (title: string) => ({
  title
})

const CognusRepositoryPopupContext = createContext<ContextProps>({
  contactPersonsPopup: null!,
  evaluateContractPopup: null!,
  uploadDocumentsPopup: null!,
  acceptContractPopup: null!,
  initiateClinicalReviewPopup: null!,
  terminateContractPopup: null!,
  clinicalReviewPopup: null!
})

const CognusRepositoryPopupProvider: FC<PropsWithChildren> = ({ children }) => {
  const contactPersonsPopupProps = usePopup(basePopup(''))
  const evaluateContractPopupProps = usePopup(basePopup(''))
  const acceptContractPopupProps = usePopup(
    basePopup('Accept contract category?')
  )
  const terminateContractPopupProps = usePopup(
    basePopup('Terminate evaluation?')
  )
  const uploadDocumentsProps = usePopup(basePopup('Upload file'))
  const initiateClinicalReviewPopupProps = usePopup(
    basePopup('Send to review?')
  )
  const clinicalReviewPopupProps = usePopup(basePopup('Clinical review'))

  const context = useMemo(
    () => ({
      contactPersonsPopup: contactPersonsPopupProps,
      evaluateContractPopup: evaluateContractPopupProps,
      uploadDocumentsPopup: uploadDocumentsProps,
      acceptContractPopup: acceptContractPopupProps,
      initiateClinicalReviewPopup: initiateClinicalReviewPopupProps,
      terminateContractPopup: terminateContractPopupProps,
      clinicalReviewPopup: clinicalReviewPopupProps
    }),
    [
      contactPersonsPopupProps,
      evaluateContractPopupProps,
      uploadDocumentsProps,
      acceptContractPopupProps,
      initiateClinicalReviewPopupProps,
      terminateContractPopupProps,
      clinicalReviewPopupProps
    ]
  )

  return (
    <CognusRepositoryPopupContext.Provider value={context}>
      {children}
    </CognusRepositoryPopupContext.Provider>
  )
}

export const useCognusRepositoryPopup = () =>
  useContext(CognusRepositoryPopupContext)

export default CognusRepositoryPopupProvider
