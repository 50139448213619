import { FC } from 'react'
import { Button, BUTTON_TYPES } from 'components/Button'
import { IContractHealthSystem } from 'features/ContractDetails/HSList/types'
import { fileNameFormatter } from 'helper/common'

type Props = Pick<IContractHealthSystem, 'amendment_document'>

const AmendmentCell: FC<Props> = (props) => {
  const { amendment_document } = props

  const handleClickLink = () => {
    if (amendment_document) {
      window.open(amendment_document)
    }
  }

  if (!amendment_document) {
    return null
  }

  return (
    <Button type={BUTTON_TYPES.LINK} onClick={handleClickLink}>
      {fileNameFormatter(amendment_document)}
    </Button>
  )
}

export default AmendmentCell
