import { useCallback, useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import cn from 'classnames'
import {
  Typography,
  TYPOGRAPHY_ALIGNMENT,
  TYPOGRAPHY_WEIGHT
} from 'components/Typography'
import { Button, BUTTON_TYPES } from 'components/Button'
import { FieldWithLabel } from 'components/FieldWithLabel/FieldWithLabel'
import { DepartmentsManagement } from 'features/DepartmentsManagement/DepartmentsManagement'
import { HSUsersListByRole } from './HSUsersListByRole'
import { IconFullName } from './IconFullName'
import { fetchDepartments } from 'pages/CRUDUser/api'
import { fetchHealthSystem, fetchHospital } from 'features/HealthSystems/api'
import { getUser } from 'redux/store/user/getters'
import { setLoading } from 'redux/store/common/slice'
import { formatAddress, fullFormatPhoneNumber } from 'helper/common'
import { ACTIONS, ROLES, SUBJECTS } from 'features/Permission/constants'
import { initialHealthSystemDetails } from './constants'
import { ReactComponent as Hospital } from 'assets/svg/Hospital.svg'
import { THealthSystemProps, THealthSystemDetails } from './types'
import './styles.scss'
import BankAccountWrapper from '../BankAccount'
import {
  TResponseDetailsAccount,
  TResponseLinkAccount
} from '../BankAccount/types'
import { getBankDetails, getLikToAccount } from '../../pages/Financials/api'
import { CollapseUsersTable } from './CollapseUsersTable/CollapseUsersTable'
import { ability } from '../Permission'

export const HospitalAdminHealthSystemDetails = ({
  hsId,
  isCommunity
}: THealthSystemProps) => {
  const [data, setData] = useState<THealthSystemDetails>(
    initialHealthSystemDetails
  )
  const [hospitalInfo, setHospitalInfo] = useState<any>()
  const [visePresident, setVisePresident] = useState({} as any)
  const [contractStewards, setContractStewards] = useState([] as any)
  const [rcas, setRcas] = useState([] as any)
  const [legals, setLegals] = useState([] as any)
  const [analysts, setAnalysts] = useState([] as any)
  const [departments, setDepartments] = useState([] as any)
  const [isCollapsed, setIsCollapsed] = useState(false)
  const [firstLoad, setFirstLoad] = useState(true)
  const user = useSelector(getUser)
  const dispatch = useDispatch()
  const currentHs = user.health_system || hsId

  const [linkAccount, setLinkAccount] = useState<TResponseLinkAccount>()
  const [dataAccount, setDataAccount] = useState<TResponseDetailsAccount>()

  const getHealthSystemDetails = useCallback(async () => {
    dispatch(setLoading(true))
    try {
      await fetchHospital(
        user.community as string,
        currentHs as string,
        user.hospital as string
      ).then((resp) => {
        setHospitalInfo(resp.data)
      })
      await fetchHealthSystem(
        user.community as string,
        currentHs as string
      ).then((resp) => {
        setData(resp.data)
      })
      await fetchDepartments(
        user.community as string,
        currentHs as string
      ).then((resp) => {
        if (!resp.data?.results) {
          return
        }

        setDepartments(resp.data.results)
      })
    } catch (e) {
      dispatch(setLoading(false))
    } finally {
      setFirstLoad(false)
    }
  }, [currentHs, dispatch, user.community])

  const onChangeCollapse = () => setIsCollapsed(!isCollapsed)

  const updateDepartmentsList = () => {
    fetchDepartments(data?.community?.uuid, data?.uuid).then((data) =>
      setDepartments(data?.data?.results)
    )
  }

  useEffect(() => {
    if (user.community && currentHs) {
      getHealthSystemDetails()
    }
  }, [currentHs, getHealthSystemDetails, user.community])

  useEffect(() => {
    if (data?.uuid) {
      setVisePresident(
        data?.users
          ? data?.users?.find((user) => user.role === ROLES.VICE_PRESIDENT)
          : []
      )
      setContractStewards(
        data?.users
          ? data?.users?.filter((user) => {
              if (user.role === ROLES.CONTRACT_STEWARD) {
                return user
              }
            })
          : []
      )
      setLegals(
        data?.users
          ? data?.users?.filter((user) => {
              if (user.role === ROLES.LEGAL) {
                return user
              }
            })
          : []
      )
      setAnalysts(
        data?.users
          ? data?.users?.filter((user) => {
              if (user.role === ROLES.ANALYST) {
                return user
              }
            })
          : []
      )
      setRcas(
        data?.users
          ? data?.users?.filter((user) => {
              if (user.role === ROLES.COMMUNITY_RCA) {
                return user
              }
            })
          : []
      )
    }
  }, [data?.users, data?.uuid])

  useEffect(() => {
    if (data.uuid && ability.can(ACTIONS.ADD, SUBJECTS.BANK_ACCOUNT)) {
      getLikToAccount({
        facility_id: hospitalInfo.uuid,
        facility_type: 'hospital'
      }).then((res) => {
        setLinkAccount(res.data)
      })
      getBankDetails({
        facility_id: hospitalInfo.uuid,
        facility_type: 'hospital'
      }).then((res) => {
        setDataAccount(res.data)
      })
    }
  }, [data.uuid])
  return (
    <div
      className={cn('hs-details', {
        'hs-details--no-padding': !isCommunity
      })}
    >
      {firstLoad && user.health_system ? null : !isCommunity && !currentHs ? (
        <div className="hs-details--empty">
          <Hospital />
          <Typography.Body1
            weight={TYPOGRAPHY_WEIGHT.BOLD}
            align={TYPOGRAPHY_ALIGNMENT.CENTER}
          >
            Health system is not assigned.
            <br />
            Please contact Community President in your profile
          </Typography.Body1>
        </div>
      ) : (
        <>
          <div className="hs-details__content">
            <div className="hs-details__title">
              {!isCommunity && (
                <Typography.Headline4>{data.name}</Typography.Headline4>
              )}
            </div>
            <Typography.Headline6 weight="semi-bold">
              General
            </Typography.Headline6>
            <div key="General" className="hs-details_general-info">
              <FieldWithLabel title="Tax Id">{data?.tax_id}</FieldWithLabel>
              <FieldWithLabel title="Email">{data?.email}</FieldWithLabel>
              <FieldWithLabel title="Phone number">
                {fullFormatPhoneNumber(data?.phone_number)}
              </FieldWithLabel>
              <FieldWithLabel title="Address">
                {formatAddress(data?.address)}
              </FieldWithLabel>
            </div>
            {isCollapsed && (
              <>
                {data?.contact_person && (
                  <>
                    <Typography.Headline6
                      className="hs-details_top-margin"
                      weight="semi-bold"
                    >
                      Contact person
                    </Typography.Headline6>
                    <div
                      key="Contact person"
                      className="hs-details_contact-info"
                    >
                      <FieldWithLabel title="Name">
                        {data?.contact_person?.first_name} &nbsp;
                        {data?.contact_person?.last_name}
                      </FieldWithLabel>
                      <FieldWithLabel title="Email">
                        {data?.contact_person?.email}
                      </FieldWithLabel>
                      <FieldWithLabel title="Position">
                        {data?.contact_person?.position}
                      </FieldWithLabel>
                      <FieldWithLabel title="Phone number">
                        {fullFormatPhoneNumber(
                          data?.contact_person?.phone_number
                        )}
                      </FieldWithLabel>
                    </div>
                  </>
                )}
                <div className="hs-details_vs-president">
                  <Typography.Headline6
                    className="hs-details_top-margin"
                    weight="semi-bold"
                  >
                    HS users
                  </Typography.Headline6>
                  <div className="hs-details_title-text_container">
                    <Typography.Label className="hs-details_title-text_container_label">
                      Vice president
                    </Typography.Label>
                    <IconFullName
                      firstName={visePresident?.first_name}
                      lastName={visePresident?.last_name}
                      src={visePresident?.small_image_url}
                      uuid={visePresident?.uuid}
                    />
                  </div>
                </div>
                <div className="hs-details_users-list_container">
                  {!!contractStewards.length && (
                    <HSUsersListByRole
                      users={contractStewards}
                      roleName="Contract Steward"
                    />
                  )}
                  {!!legals.length && (
                    <HSUsersListByRole users={legals} roleName="Legal" />
                  )}
                  {!!analysts.length && (
                    <HSUsersListByRole users={analysts} roleName="Analyst" />
                  )}
                  {!!rcas.length && (
                    <HSUsersListByRole
                      users={rcas}
                      roleName="Revenue cycle advisor"
                    />
                  )}
                </div>
                {!!departments?.length && (
                  <div className="hs-details_departments_container">
                    <div className="hs-details_departments_title-management hs-details_top-margin">
                      <Typography.Headline6 weight="semi-bold">
                        Departments
                      </Typography.Headline6>
                      <DepartmentsManagement
                        initStateDepartments={departments.filter(
                          (department) => !department.is_default
                        )}
                        communityUUID={data?.community?.uuid}
                        HSUUID={data?.uuid}
                        onClose={updateDepartmentsList}
                        HSName={data.name}
                      />
                    </div>
                    <div className="hs-details_departments">
                      {departments
                        ?.map((department) => department.name)
                        .join(', ')}
                    </div>
                  </div>
                )}
              </>
            )}
            <div className="hs-details-button-hide_container">
              <Button
                type={BUTTON_TYPES.LINK}
                className="hs-details_hospital-button-hide"
                onClick={onChangeCollapse}
              >
                {`${isCollapsed ? 'Hide' : 'Show more'} information`}
              </Button>
            </div>
            <div className="hs-details__hospital-title">
              {!isCommunity && (
                <Typography.Headline5 weight={TYPOGRAPHY_WEIGHT.SEMI_BOLD}>
                  {hospitalInfo?.name}
                </Typography.Headline5>
              )}
            </div>
            <div className="hs-details__container">
              <div className="hs-details__details-container">
                <Typography.Headline6
                  className="hs-details__title-general"
                  weight="semi-bold"
                >
                  General
                </Typography.Headline6>
                <div key="General" className="hs-details_general-info">
                  <FieldWithLabel title="Tax Id">
                    {hospitalInfo?.tax_id}
                  </FieldWithLabel>
                  <FieldWithLabel title="Email">
                    {hospitalInfo?.email}
                  </FieldWithLabel>
                  <FieldWithLabel title="Phone number">
                    {fullFormatPhoneNumber(hospitalInfo?.phone_number)}
                  </FieldWithLabel>
                  <FieldWithLabel title="Address">
                    {formatAddress(hospitalInfo?.address)}
                  </FieldWithLabel>
                </div>
                {user.role === ROLES.HOSPITAL_ADMIN && linkAccount && (
                  <div className="row mt-32">
                    <BankAccountWrapper
                      linkAccount={linkAccount}
                      dataAccount={dataAccount}
                      type="community"
                      uuid={user.community}
                      className="health-system-account"
                    />
                  </div>
                )}
              </div>
              <CollapseUsersTable
                hsId={data.uuid}
                hospitalId={hospitalInfo.uuid}
              />
            </div>
          </div>
        </>
      )}
    </div>
  )
}
