import { useCallback, useEffect, useMemo } from 'react'
import { Form } from 'antd'
import { FormListFieldData, FormListOperation } from 'antd/lib/form/FormList'

import './styles.scss'

import { ReactComponent as Plus16 } from 'assets/svg/Plus16.svg'
import { ReactComponent as Close16 } from 'assets/svg/Clos16.svg'

import { BTN_TXT, LABELS, PLACEHOLDERS } from 'constants/txt'
import { Select } from 'components/Select'
import { Button, BUTTON_TYPES } from 'components/Button'
import useRebateConditionsForm from 'features/ContractDetails/RebateConditions/RebateConditionsForm/hooks/useRebateConditionsForm'
import {
  INITIAL_REBATE_CONDITIONS,
  REBATE_CONDITIONS_TYPE,
  REBATE_CONDITIONS_TYPE_OPTIONS
} from 'features/ContractDetails/RebateConditions/RebateConditionsForm/constants'
import { IRebateConditionsForm } from 'features/ContractDetails/RebateConditions/RebateConditionsForm/types'
import FixedRebateCondition from 'features/ContractDetails/RebateConditions/RebateConditionsForm/FixedRebateCondition/FixedRebateCondition'
import PercentageRebateCondition from 'features/ContractDetails/RebateConditions/RebateConditionsForm/PercentageRebateCondition/PercentageRebateCondition'
import GrowthRebateCondition from 'features/ContractDetails/RebateConditions/RebateConditionsForm/GrowthRebateCondition/GrowthRebateCondition'
import RebateConditionsFormProvider from 'features/ContractDetails/RebateConditions/RebateConditionsForm/Providers/RebateConditionsFormProvider'
import { Popup } from '../../../../components/Popup'
import { useShowPopup } from '../../../../hooks/useShowPopup'
import { useContractDetailsContext } from '../../Providers/ContractDetailsContextProvider'
import usePopup from '../../../../hooks/usePopup'

const RebateConditionsForm = () => {
  const rebateConditionsForm = useRebateConditionsForm()

  const contractDetailsContext = useContractDetailsContext()
  const { setActiveTab } = contractDetailsContext.actions
  const discardChangesPopup = usePopup()

  const {
    form,
    type,
    canRemoveCondition,
    hasFormChanges,
    handleFinishForm,
    handleCancelForm,
    handleChangeForm,
    isChanged,
    setIsChanged
  } = rebateConditionsForm

  const [showPopup, confirmNavigation, cancelNavigation] =
    useShowPopup(isChanged)

  const cancelDiscardChanges = () => {
    discardChangesPopup.actions.close()
  }

  useEffect(() => {
    if (!showPopup) setActiveTab('rebate_conditions')
  }, [showPopup])

  const RebateConditionType = useMemo(
    () => (
      <div className="rebate-conditions-form__item">
        <Select
          options={REBATE_CONDITIONS_TYPE_OPTIONS}
          propsItem={{
            label: LABELS.REBATE_TYPE,
            name: 'rebate_type',
            rules: [
              {
                required: true
              }
            ]
          }}
          propsSelect={{
            placeholder: PLACEHOLDERS.PLEASE_SELECT,
            onChange: handleChangeForm
          }}
        />
      </div>
    ),
    [handleChangeForm]
  )

  const rebateCondition = useCallback(
    (rebate: FormListFieldData, actions: FormListOperation, index: number) => {
      switch (type) {
        case REBATE_CONDITIONS_TYPE.FIXED_REBATE:
          return <FixedRebateCondition rebate={rebate} actions={actions} />
        case REBATE_CONDITIONS_TYPE.PERCENTAGE_REBATE:
          return <PercentageRebateCondition rebate={rebate} actions={actions} />
        case REBATE_CONDITIONS_TYPE.GROWTH_REBATE:
          return (
            <GrowthRebateCondition
              index={index}
              rebate={rebate}
              actions={actions}
            />
          )
        default:
          return null
      }
    },
    [type]
  )

  const removeConditionAction = useCallback(
    (rebate: FormListFieldData, actions: FormListOperation) => {
      const handleClickButton = () => {
        actions.remove(rebate.name)

        handleChangeForm()
      }

      return (
        <Button.Icon
          className="rebate-conditions-growth__remove"
          type={BUTTON_TYPES.LINK}
          onClick={handleClickButton}
          icon={<Close16 />}
        />
      )
    },
    [handleChangeForm]
  )

  const rebateConditions = useCallback(
    (rebates: FormListFieldData[], actions: FormListOperation) =>
      rebates?.map((rebate, index) => (
        <div key={rebate.key} className="rebate-conditions-form__item">
          {rebateCondition(rebate, actions, index)}
          {canRemoveCondition && removeConditionAction(rebate, actions)}
        </div>
      )),
    [canRemoveCondition, rebateCondition, removeConditionAction]
  )

  const addConditionAction = useCallback(
    (actions: FormListOperation) => {
      const handleClickButton = () => {
        actions.add(INITIAL_REBATE_CONDITIONS[type])

        handleChangeForm()
      }

      return (
        <div className="rebate-conditions-form__item">
          <Button
            className="rebate-conditions-form__add"
            type={BUTTON_TYPES.GHOST}
            onClick={handleClickButton}
            icon={<Plus16 />}
            upperCase
          >
            {BTN_TXT.ADD_GROWTH_CONDITION}
          </Button>
        </div>
      )
    },
    [handleChangeForm, type]
  )

  const rebateConditionActions = useCallback(
    (actions: FormListOperation) => {
      if (type === REBATE_CONDITIONS_TYPE.GROWTH_REBATE) {
        return addConditionAction(actions)
      } else {
        return null
      }
    },
    [addConditionAction, type]
  )

  const FormActions = useMemo(
    () => (
      <div className="rebate-conditions-form__item">
        <div className="rebate-conditions-form__actions">
          <Button onClick={discardChangesPopup.actions.open} upperCase>
            {BTN_TXT.CANCEL}
          </Button>
          <Button
            type={BUTTON_TYPES.PRIMARY}
            form="rebate-conditions-form-id"
            htmlType="submit"
            upperCase
          >
            {BTN_TXT.SAVE}
          </Button>
        </div>
      </div>
    ),
    [handleCancelForm]
  )

  const submitCancellation = () => {
    handleCancelForm()
    discardChangesPopup.actions.close()
    setIsChanged(false)
  }

  return (
    <RebateConditionsFormProvider {...rebateConditionsForm}>
      <Form<IRebateConditionsForm>
        id="rebate-conditions-form-id"
        className="rebate-conditions-form"
        form={form}
        onChange={handleChangeForm}
        onFinish={handleFinishForm}
        onFieldsChange={() => setIsChanged(true)}
      >
        {RebateConditionType}
        <Form.List name="rebates">
          {(rebates, actions) => (
            <>
              {rebateConditions(rebates, actions)}
              {rebateConditionActions(actions)}
            </>
          )}
        </Form.List>
        {hasFormChanges && FormActions}
      </Form>
      <Popup.Discard
        visible={showPopup ? showPopup : discardChangesPopup.state.visible}
        onCancel={showPopup ? cancelNavigation : cancelDiscardChanges}
        onSubmit={showPopup ? confirmNavigation : submitCancellation}
      />
    </RebateConditionsFormProvider>
  )
}

export default RebateConditionsForm
