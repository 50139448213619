import { useEffect, useState } from 'react'
import { getOngoing, getSavings } from '../api'
import { ActualSavingsChart } from './ActualSavingsChart'
import { OngoingContactsChart } from './OngoingContractsChart'
import { OngoingRFPsChart } from './OngoingRFPs'
import {
  TDashboardParams,
  TOngoingContractsResponce,
  TOngoingRFPResponce
} from '../types'
import '../styles.scss'
import EditSavingsGoals from '../ActualSavingsModal/EditSavingsGoals'
import { useDashboardPopup } from '../Providers/DashboardPopupProvider'
import { useSelector } from 'react-redux'
import { getUser } from '../../../redux/store/user/getters'
import HistoryGoals from '../ActualSavingsModal/HistoryGoals'

export const Charts = ({
  healthSystem,
  user,
  isFiltersHidden
}: TDashboardParams) => {
  const currenUser = useSelector(getUser)
  const [savings, setSavings] = useState({
    progress: 0,
    target_sum: 0,
    total_expected_savings: 0
  } as any)
  const [ongoing, setOngoing] = useState(
    {} as {
      contract: TOngoingContractsResponce
      rfp: TOngoingRFPResponce
    }
  )
  const { editSavingsGoalsPopup, historyGoalsPopup } = useDashboardPopup()
  useEffect(() => {
    getChartData()
  }, [healthSystem, user])

  const getChartData = () => {
    const params: any = {}
    if (healthSystem) {
      params.health_system = healthSystem
    }
    if (user) {
      params.user = user
    }
    if (isFiltersHidden) {
      params.user = currenUser.uuid
    }
    getSavings({ params }).then((res) => setSavings(res?.data))
    getOngoing({ params }).then((res) => setOngoing(res?.data))
  }

  return (
    <div className="charts">
      <ActualSavingsChart
        data={savings}
        isMenuVisible={!!healthSystem && !user}
      />
      <OngoingContactsChart
        data={ongoing?.contract as TOngoingContractsResponce}
      />
      <OngoingRFPsChart data={ongoing?.rfp as TOngoingRFPResponce} />
      {editSavingsGoalsPopup?.state?.visible && (
        <EditSavingsGoals
          healthSystem={healthSystem}
          getChartData={getChartData}
        />
      )}
      {historyGoalsPopup?.state?.visible && (
        <HistoryGoals healthSystem={healthSystem} getChartData={getChartData} />
      )}
    </div>
  )
}
