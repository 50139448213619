import { setLoading } from '../common/slice'
import { setAllowedUsers } from './slice'
import { getAllowedRoles } from '../../../features/MyCommunity/api'

export const getAllowedRolesList = (): any => async (dispatch) => {
  try {
    dispatch(setLoading(true))
    const { data } = await getAllowedRoles()
    dispatch(setAllowedUsers(data.results))
  } finally {
    dispatch(setLoading(false))
  }
}
