import { useState, useEffect, useRef } from 'react'
import axios, { CancelTokenSource } from 'axios'
import { useDispatch } from 'react-redux'
import { Table } from 'components/Table'
import { Input } from 'components/Input'
import { Button, BUTTON_TYPES } from 'components/Button'
import { ACTIONS, Can, SUBJECTS } from 'features/Permission'
import { setLoading } from 'redux/store/common/slice'
import { routes } from 'router'
import { getVendorListRequest } from './api'
import { isTableHasParams } from 'helper/common'
import useRouter from 'hooks/useRouter'
import { useDebounce } from 'hooks/hooks'
import { columns } from './constants'
import { BTN_TXT } from 'constants/txt'
import { DEFAULT_PAGE } from 'components/Table/constants'
import { ReactComponent as Plus16 } from 'assets/svg/Plus16.svg'
import { ReactComponent as Search16 } from 'assets/svg/Search16.svg'
import { ReactComponent as Close16 } from 'assets/svg/Clos16.svg'
import {
  TVendorList,
  TVendorListParams,
  TVendorListRequestData,
  TVendorsListFilters,
  TVendorsListFiltersFunc
} from './types'

export const VendorsList = () => {
  const { push } = useRouter()
  const dispatch = useDispatch()

  const timeoutRef = useRef<ReturnType<typeof setTimeout>>()
  const tableDataTokenRef = useRef<CancelTokenSource>()

  const [tableData, setTableData] = useState<TVendorList[]>([])
  const [searchValue, setSearchValue] = useState('')
  const [sortParams, setSortParams] = useState<TVendorListRequestData>()
  const [firstLoad, setFirstLoad] = useState(true)
  const [totalNumber, setTotalNumber] = useState(0)
  const [pageInfo, setPageInfo] = useState({ ...DEFAULT_PAGE })

  const debouncedSearch = useDebounce(searchValue, 500)

  const [filters, setFilters] = useState<TVendorsListFilters>({})

  const applyFilters: TVendorsListFiltersFunc = (field, appliedFilters) => {
    const newFilters = { ...filters, [field]: appliedFilters }
    const newPageInfo = { ...pageInfo, pageNumber: 1 }
    setFilters(newFilters)
    setPageInfo(newPageInfo)
    getTableData({ ...sortParams, filters: newFilters, page: newPageInfo })
  }

  const getTableData = ({
    sortField,
    sortOrder,
    filters,
    page
  }: TVendorListRequestData) => {
    dispatch(setLoading(true))

    if (!!tableDataTokenRef.current) {
      tableDataTokenRef.current.cancel()
    }
    const params: TVendorListParams = { ...filters }

    if (searchValue) {
      params.search = searchValue
    }

    if (sortField) {
      if (sortOrder) {
        params.ordering = `${sortOrder === 'descend' ? '-' : ''}${sortField}`
      }
      setSortParams({
        sortField,
        sortOrder
      })
    }

    const dataPage = page ? page : pageInfo

    params.limit = dataPage.pageSize
    params.offset = (dataPage.pageNumber - 1) * dataPage.pageSize
    tableDataTokenRef.current = axios.CancelToken.source()

    getVendorListRequest({
      cancelToken: tableDataTokenRef.current?.token,
      params
    })
      .then((resp) => {
        if (!resp.data?.results) {
          return
        }
        setTableData(
          resp.data.results.map((i) => ({
            ...i,
            key: i.uuid
          }))
        )
        setTotalNumber(resp.data.count)
        if (firstLoad) {
          setFirstLoad(false)
        }
      })
      .finally(() => dispatch(setLoading(false)))
  }

  const createVendor = () => push(`${routes.myCommunity}/vendor-creation`)

  const handleTableChange = (pagination, _filters, sorter) => {
    const page = {
      pageNumber: pagination.current,
      pageSize: pagination.pageSize
    }
    getTableData({
      sortField: sorter.field,
      sortOrder: sorter.order,
      filters,
      page
    })
    setPageInfo(page)
  }

  const handleChangePageSize = (pageSize) => {
    const page = {
      pageNumber: 1,
      pageSize
    }
    getTableData({
      ...sortParams,
      filters,
      page
    })
    setPageInfo(page)
  }

  useEffect(() => {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current)
    }
    timeoutRef.current = setTimeout(
      () => {
        getTableData({ ...sortParams, filters, page: { ...DEFAULT_PAGE } })
        setPageInfo({ ...DEFAULT_PAGE })
      },
      firstLoad ? 0 : 500
    )
  }, [debouncedSearch])

  useEffect(() => {
    return () => {
      if (!!tableDataTokenRef.current) {
        tableDataTokenRef.current.cancel()
      }
    }
  }, [])

  return (
    <div className="table-wrapper community-users">
      <div className="table-wrapper__header">
        <Input
          className="allow-clear"
          propsInput={{
            allowClear: { clearIcon: <Close16 /> },
            placeholder: 'Search by Vendor or contract admin name',
            prefix: <Search16 />,
            value: searchValue,
            onChange: (e) => setSearchValue(e.target.value)
          }}
        />
        <div className="table-wrapper__header-buttons">
          <Can I={ACTIONS.CREATE} a={SUBJECTS.VENDORS}>
            <Button
              type={BUTTON_TYPES.PRIMARY}
              icon={<Plus16 />}
              onClick={createVendor}
              upperCase
            >
              {BTN_TXT.CREATE_VENDOR}
            </Button>
          </Can>
        </div>
      </div>
      {!firstLoad && (
        <Table<TVendorList>
          dataSource={tableData}
          columns={columns({ applyFilters, filters })}
          className="vendor-data-list-table"
          onChange={handleTableChange}
          hasSearchOrFilters={isTableHasParams(debouncedSearch, filters)}
          onChangePage={handleChangePageSize}
          pageSize={pageInfo.pageSize}
          pagination={{
            pageSize: pageInfo.pageSize,
            current: pageInfo.pageNumber,
            total: totalNumber
          }}
        />
      )}
    </div>
  )
}
