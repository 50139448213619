import Fetcher from 'services/Fetcher'
import { API_DICTIONARY, HTTP_METHODS } from 'constants/api'
import {
  THealthSystemResponse,
  THealthSystemRequest,
  TAddEditHealthSystemRequest
} from 'features/HealthSystems/types'
import { VALIDATION_MESSAGES } from '../../constants'

const fetcher = new Fetcher({})

export const fetchHealthSystemsList: THealthSystemRequest = async (
  community,
  params
) =>
  fetcher.request<unknown, THealthSystemResponse>({
    url: `${API_DICTIONARY.COMMUNITIES}${community}/${API_DICTIONARY.HEALTH_SYSTEMS}`,
    method: HTTP_METHODS.GET,
    params
  })

export const fetchHealthSystem = async (
  communityUUID: string,
  HSUUID: string
) =>
  fetcher.request<unknown, any>({
    url: `${API_DICTIONARY.COMMUNITIES}${communityUUID}/${API_DICTIONARY.HEALTH_SYSTEMS}${HSUUID}/`,
    method: HTTP_METHODS.GET
  })

export const fetchHospitalList = async (
  communityUUID: string,
  HSUUID: string
) =>
  fetcher.request<unknown, any>({
    url: `${API_DICTIONARY.COMMUNITIES}${communityUUID}/${API_DICTIONARY.HEALTH_SYSTEMS}${HSUUID}/${API_DICTIONARY.HOSPITALS}`,
    method: HTTP_METHODS.GET
  })

export const fetchHospital = async (
  communityUUID: string,
  HSUUID: string,
  hospitalUUID: string
) =>
  fetcher.request<unknown, any>({
    url: `${API_DICTIONARY.COMMUNITIES}${communityUUID}/${API_DICTIONARY.HEALTH_SYSTEMS}${HSUUID}/${API_DICTIONARY.HOSPITALS}${hospitalUUID}/`,
    method: HTTP_METHODS.GET
  })

export const addHealthSystem: TAddEditHealthSystemRequest = async (
  uuid,
  data
) =>
  fetcher.request<unknown, any>({
    url: `${API_DICTIONARY.COMMUNITIES}${uuid}/${API_DICTIONARY.HEALTH_SYSTEMS}`,
    method: HTTP_METHODS.POST,
    successMessage: `Health system ${VALIDATION_MESSAGES.SM0009} added.`,
    data
  })

export const editHealthSystem: TAddEditHealthSystemRequest = async (
  uuid,
  data,
  id
) =>
  fetcher.request<unknown, any>({
    url: `${API_DICTIONARY.COMMUNITIES}${uuid}/${API_DICTIONARY.HEALTH_SYSTEMS}${id}/`,
    method: HTTP_METHODS.PUT,
    successMessage: `Health system ${VALIDATION_MESSAGES.SM0009} saved.`,
    data
  })

export const fetchAllHospitals = async (
  communityUUID: string,
  health_systems?: string
) =>
  fetcher.request<unknown, any>({
    url: `${API_DICTIONARY.COMMUNITIES}${communityUUID}/${API_DICTIONARY.HOSPITALS}`,
    method: HTTP_METHODS.GET,
    params: { health_systems }
  })
