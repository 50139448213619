import { FC } from 'react'

import { BTN_TXT } from 'constants/txt'
import { Button, BUTTON_TYPES } from 'components/Button'
import { usePRSDetailsContext } from '../Providers/PRSDetailsContextProvider'

type Props = {
  onClick: VoidFunction
}

const EditSurvey: FC<Props> = (props) => {
  const { onClick } = props

  const prsCreateSurveyContext = usePRSDetailsContext()

  const { canCreateOrEditSurvey } = prsCreateSurveyContext.state

  return canCreateOrEditSurvey ? (
    <Button type={BUTTON_TYPES.DEFAULT} onClick={onClick} upperCase>
      {BTN_TXT.EDIT_SURVEY}
    </Button>
  ) : null
}

export default EditSurvey
