import cn from 'classnames'
import { FieldWithLabel } from 'components/FieldWithLabel/FieldWithLabel'
import { Attachment } from 'components/Attachment/Attachment'
import { fileNameFormatter } from 'helper/common'
import { TAttachmentsListProps } from '../types'
import './styles.scss'

const ChatAttachment = ({
  className,
  files,
  label,
  disableActions,
  onDelete,
  icon
}: TAttachmentsListProps) => {
  const deleteHandler = (uuid: string) => {
    if (onDelete) {
      onDelete(uuid)
    }
  }
  return (
    <div className={cn('attachments-list', className)}>
      <FieldWithLabel title={label}>
        <ul className="attachments-list__files chat">
          {files.map((i) => (
            <li key={i.uuid}>
              <Attachment.Block
                filename={fileNameFormatter(i.file)}
                link={i.file}
                icon={icon}
                percentage={i?.percentage}
                error={i?.error}
                disableActions={disableActions || i.isCannotBeDeleted}
                onDelete={() => deleteHandler(i.uuid)}
              />
            </li>
          ))}
        </ul>
      </FieldWithLabel>
    </div>
  )
}

export default ChatAttachment
