/* eslint-disable no-param-reassign */
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { moduleName } from './constants'
import { ITemplateDetailsState, TTemplateDetails } from './types'

const initialState: ITemplateDetailsState = {
  data: {},
  isFetching: false,
  isLoading: false,
  error: null
}

const slice = createSlice({
  name: moduleName,
  initialState,
  reducers: {
    loading(state: ITemplateDetailsState) {
      state.isLoading = true
      state.error = null
    },
    fetching(state: ITemplateDetailsState) {
      state.isFetching = true
      state.error = null
    },
    finish(state: ITemplateDetailsState) {
      state.isLoading = false
      state.isFetching = false
    },
    error(
      state: ITemplateDetailsState,
      action: PayloadAction<{ error: ITemplateDetailsState['error'] }>
    ) {
      const { error } = action.payload
      state.error = error
    },
    set(
      state: ITemplateDetailsState,
      action: PayloadAction<{ data: TTemplateDetails }>
    ) {
      const { data } = action.payload
      state.data = data
    },
    update(
      state: ITemplateDetailsState,
      action: PayloadAction<{ data: TTemplateDetails }>
    ) {
      const { data } = action.payload
      state.data = { ...state.data, ...data }
    },
    reset(state: ITemplateDetailsState) {
      Object.keys(initialState).forEach((key) => {
        state[key] = initialState[key]
      })
    }
  }
})

export default slice.reducer
export const { loading, finish, error, set, update, reset } = slice.actions
