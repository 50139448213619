import { useSelector } from 'react-redux'
import { LoadingOutlined } from '@ant-design/icons'

import './style.scss'

import { getIsLoading } from 'redux/store/chats/getters'
import InfiniteList from 'components/InfiniteList/InfiniteList'
import { useMemo } from 'react'
import { IHistoryItem } from '../types'
import { usePrsHistoryContext } from '../Providers/PrsHistoryContextProvider'
import Item from './Item'

const List = () => {
  const isLoading = useSelector(getIsLoading)

  const { state, actions } = usePrsHistoryContext()
  const { history } = state

  const Loader = useMemo(
    () => <LoadingOutlined className="chat-list-feature-list__spinner" spin />,
    []
  )

  return (
    <InfiniteList<IHistoryItem>
      items={history}
      loader={Loader}
      renderItem={Item}
      loading={isLoading}
      keyExtractor={(item) => item.uuid}
      hasNextPage={state.hasNextPage}
      onLoadMore={actions.handleLoadMore}
      className="chat-list-feature-list"
    />
  )
}

export default List
