import { useState } from 'react'
import { useDispatch } from 'react-redux'

import { BTN_TXT } from 'constants/txt'
import { Forms } from 'components/Forms'
import ShowMore from 'components/ShowMore/ShowMore'
import Typography from 'components/Typography/Typography'
import { TYPOGRAPHY_WEIGHT } from 'components/Typography'
import { IGroupChatDetails } from 'redux/store/chatDetails/types'
import { updateChatDetailsAsync } from 'redux/store/chatDetails/actions'
import { ACTIONS, chatSubject, SUBJECTS } from 'features/Chat/Abilities'
import { useChatContext } from 'features/Chat/Providers/ChatContextProvider'
import { Can } from 'features/Chat/Providers/ChatAbilityProvider'
import ChatDetails from 'features/ChatDetails/ChatDetails'
import { safeString } from 'helper/common'
import { updateChatTitle } from '../../redux/store/chats/slice'

export const GroupChatDetails = () => {
  const dispatch = useDispatch<any>()
  const chatContext = useChatContext<IGroupChatDetails>()

  const { chat } = chatContext.state

  const [isEditable, setIsEditable] = useState(false)

  const closeEditForm = () => setIsEditable(false)

  const updateChat = (values) => {
    if (!chat?.uuid) {
      throw new Error('Chat id not provided')
    }

    dispatch(updateChatDetailsAsync(chat.uuid, values)).then(() => {
      closeEditForm()
    })
    dispatch(updateChatTitle({ uuid: chat.uuid, title: values.name }))
  }

  return (
    <ChatDetails name="GC" chatName={chat?.name} hideName={isEditable}>
      {chat &&
        (!isEditable ? (
          <>
            <ShowMore className="chat-details-container__description">
              <Typography.Body1>
                {safeString(chat.description)}
              </Typography.Body1>
            </ShowMore>
            <Can I={ACTIONS.EDIT} a={chatSubject(SUBJECTS.GROUP_CHAT, chat)}>
              <Typography.Button
                className={'chat-details-container__edit-btn'}
                weight={TYPOGRAPHY_WEIGHT.MEDIUM}
                onClick={() => setIsEditable(true)}
              >
                {BTN_TXT.EDIT}
              </Typography.Button>
            </Can>
          </>
        ) : (
          <Forms.EditGroupChat
            chat={chat}
            onSubmit={updateChat}
            onCancel={closeEditForm}
          />
        ))}
    </ChatDetails>
  )
}
