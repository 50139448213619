import { Container } from 'components/Container/Container'
import { useVendorDetailsContext } from '../Providers/ContextProvider'
import { BTN_TXT } from 'constants/txt'
import { BREADCRUMBS } from '../../constants'
import { FC, PropsWithChildren } from 'react'

const ContractDetailsContainer: FC<PropsWithChildren> = ({ children }) => {
  const vendorDetailsContext = useVendorDetailsContext()

  const { details } = vendorDetailsContext.state

  const title = details?.uuid ? details.name : BTN_TXT.CREATE_VENDOR

  return (
    <Container title={title} breadcrumbs={BREADCRUMBS(title)}>
      {children}
    </Container>
  )
}

export default ContractDetailsContainer
