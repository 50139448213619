import { useCallback, useMemo, useState } from 'react'
import moment from 'moment/moment'
import { Form } from 'antd'

import { useContractDetailsContext } from 'features/ContractDetails/Providers/ContractDetailsContextProvider'
import { disabledDateSameOrBefore } from 'features/ContractDetails/ContractDetailsGeneral/ContractDetailsForm/utils'
import {
  IExtendContractDetailsForm,
  TPreviewDocument
} from 'features/ContractDetails/ExtendContract/types'
import { useContractDetailsPopup } from 'features/ContractDetails/Providers/ContractDetailsPopupProvider'

const useExtendContractForm = () => {
  const { renewFinishDatePopup } = useContractDetailsPopup()
  const contractDetailsContext = useContractDetailsContext()

  const { details } = contractDetailsContext.state
  const { extendContractDetailsAsync } = contractDetailsContext.actions

  const [form] = Form.useForm<IExtendContractDetailsForm>()

  const finishDate = Form.useWatch('finish_date', form)

  const [extensionDocument, setExtensionDocument] =
    useState<TPreviewDocument | null>(null)

  const isCurrentEarlierThanStart = moment().isSameOrAfter(
    moment(details.start_date)
  )
    ? moment()
    : moment(details.start_date)

  const disabledDateSameOrBeforeStartDate = useMemo(
    () => disabledDateSameOrBefore(isCurrentEarlierThanStart),
    []
  )

  const disableExtendContractButton = useMemo(
    () => finishDate === null || extensionDocument === null,
    [extensionDocument, finishDate]
  )

  const handleUploadDocument = useCallback(
    (file: File[]) =>
      new Promise((res) => {
        setExtensionDocument({
          file: file[0],
          uploadedAt: moment()
        })

        res(null)
      }),
    []
  )

  const handleDeleteDocument = useCallback(() => {
    setExtensionDocument(null)
  }, [])

  const handleFinishForm = useCallback(
    async (values: IExtendContractDetailsForm) => {
      if (values.finish_date === null || extensionDocument === null) {
        return
      }

      const payloadData = {
        newFinishDate: values.finish_date,
        extensionDocument
      }

      if (
        values.finish_date.isSameOrBefore(moment(details.finish_date), 'day')
      ) {
        renewFinishDatePopup.actions.open(payloadData)

        return
      }

      await extendContractDetailsAsync(payloadData)
    },
    [
      details.finish_date,
      extendContractDetailsAsync,
      extensionDocument,
      renewFinishDatePopup.actions
    ]
  )

  return {
    form,
    extensionDocument,
    handleUploadDocument,
    handleDeleteDocument,
    handleFinishForm,
    disableExtendContractButton,
    disabledDateSameOrBeforeStartDate
  }
}

export default useExtendContractForm
