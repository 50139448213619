import Fetcher from 'services/Fetcher'
import { API_DICTIONARY, HTTP_METHODS } from 'constants/api'
import { TCommunityUsersResponse, TCommunityUsersRequest } from './types'

const fetcher = new Fetcher({})

export const getCommunityUsersList: TCommunityUsersRequest = async (
  uuid,
  data
) =>
  fetcher.request<unknown, TCommunityUsersResponse>({
    url: `${API_DICTIONARY.COMMUNITIES}${uuid}/${API_DICTIONARY.USERS}`,
    method: HTTP_METHODS.GET,
    ...data
  })
