export const ACCEPT_DOCUMENT_TYPES = {
  'application/*': [
    'application/msword',
    'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
    'application/pdf'
  ]
}

export const UPLOAD_DOCUMENTS_CONFIG = {
  accept: ACCEPT_DOCUMENT_TYPES,
  multiple: false,
  maxSize: 5242880
}
