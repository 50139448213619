import { Upload, UploadProps } from 'antd'
import { getLocalAccessToken } from 'helper/common'

export type FileInputProps = UploadProps & {
  imageUrl: string
  displayedBlock: JSX.Element
}

const token = getLocalAccessToken()

export const InputFile = ({
  displayedBlock,
  ...props
}: FileInputProps): JSX.Element => {
  return (
    <Upload
      name="file"
      listType="picture-card"
      className="file-input"
      showUploadList={false}
      headers={{
        authorization: `Bearer ${token}`
      }}
      {...{ multipart: true }}
      {...props}
    >
      {displayedBlock}
    </Upload>
  )
}
