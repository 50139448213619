import Fetcher from 'services/Fetcher'
import { API_DICTIONARY, HTTP_METHODS } from 'constants/api'

const fetcher = new Fetcher({})

export const deleteDepartment = async (
  communityUUID: string,
  HSUUID: string,
  DepUUID: string
) =>
  fetcher.request<unknown, any>({
    url: `${API_DICTIONARY.COMMUNITIES}${communityUUID}/${API_DICTIONARY.HEALTH_SYSTEMS}${HSUUID}/${API_DICTIONARY.DEPARTMENTS}${DepUUID}/`,
    method: HTTP_METHODS.DELETE
  })
export const editDepartment = async (
  communityUUID: string,
  HSUUID: string,
  DepUUID: string,
  name: string
) =>
  fetcher.request<unknown, any>({
    url: `${API_DICTIONARY.COMMUNITIES}${communityUUID}/${API_DICTIONARY.HEALTH_SYSTEMS}${HSUUID}/${API_DICTIONARY.DEPARTMENTS}${DepUUID}/`,
    method: HTTP_METHODS.PATCH,
    data: name
  })
export const addDepartment = async (
  communityUUID: string,
  HSUUID: string,
  values: { name: string }
) =>
  fetcher.request<unknown, any>({
    url: `${API_DICTIONARY.COMMUNITIES}${communityUUID}/${API_DICTIONARY.HEALTH_SYSTEMS}${HSUUID}/${API_DICTIONARY.DEPARTMENTS}`,
    method: HTTP_METHODS.POST,
    data: values
  })
