import {
  CellTemplate,
  Compatible,
  Uncertain,
  Cell,
  UncertainCompatible
} from '@silevis/reactgrid'
import { timelineDateFormat } from '../../../../components/Timelines/constants'
import { Datepicker } from '../../../../components/Datepicker'
import moment from 'moment'

export interface DatepickerCellInterface extends Cell {
  type: 'date'
  text: string
  placeholder?: string
  focusCallback?: () => void
  unFocusCallback?: () => void
  isError?: boolean
}

export class CustomCellDatepickerTemplate
  implements CellTemplate<DatepickerCellInterface>
{
  getCompatibleCell(
    uncertainCell: Uncertain<DatepickerCellInterface>
  ): Compatible<DatepickerCellInterface> {
    return {
      ...uncertainCell,
      text: uncertainCell.text ?? '',
      value: parseFloat(uncertainCell.text ?? '')
    }
  }
  update(
    cell: Compatible<DatepickerCellInterface>,
    cellToMerge: UncertainCompatible<DatepickerCellInterface>
  ): Compatible<DatepickerCellInterface> {
    return this.getCompatibleCell({
      ...cell,
      text: cellToMerge.text
    })
  }

  render(
    cell: Compatible<DatepickerCellInterface>,
    _isInEditMode: boolean,
    onCellChanged: (
      cell: Compatible<DatepickerCellInterface>,
      commit: boolean
    ) => void
  ) {
    return (
      <Datepicker.DefaultNoForm
        hasCustomError={cell.isError}
        className="full-width"
        propsDate={{
          getPopupContainer: (node) => document?.body ?? node,
          format: timelineDateFormat,
          placeholder: cell.placeholder,
          value: cell.text ? moment(cell.text) : undefined,
          onChange: (selectedOption) => {
            onCellChanged(
              this.getCompatibleCell({
                ...cell,
                text: selectedOption?.format()
              }),
              true
            )
          },
          onFocus: () => cell.focusCallback?.(),
          onBlur: () => cell.unFocusCallback?.()
        }}
      />
    )
  }

  isFocusable() {
    return true
  }
}
