import { useDispatch } from 'react-redux'
import { useEffect } from 'react'
import { setLoading } from '../../redux/store/common/slice'
import Popup from '../../components/Popup/Popup'
import Logo from '../../assets/svg/Logo.svg'
import Typography from '../../components/Typography/Typography'
import PrivacyPolicyContent from '../../components/TermsOfServices/PrivacyPolicyContent'
import useRouter from '../../hooks/useRouter'
import { routes } from 'router'

const PrivacyPolicyPage = () => {
  const dispatch = useDispatch()
  const { push } = useRouter()

  useEffect(() => {
    dispatch(setLoading(false))
  }, [])

  const handleRedirect = () => push(routes.login)
  return (
    <Popup.Terms
      visible={true}
      footer={null}
      wrapClassName={'terms-wrap-modal'}
      closable={false}
      title={
        <img
          className="sign-popup__logo terms-logo"
          onClick={handleRedirect}
          src={Logo}
          alt="Logo"
        />
      }
    >
      <div className="terms-of-use-wrapper container">
        <Typography.Headline3 className="terms-of-use-wrapper__header">
          Privacy Notice
        </Typography.Headline3>
        <PrivacyPolicyContent />
      </div>
    </Popup.Terms>
  )
}

export default PrivacyPolicyPage
