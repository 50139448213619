import { Popup } from '../../components/Popup'
import { Button, BUTTON_TYPES } from '../../components/Button'
import { BTN_TXT } from '../../constants'
import Typography from '../../components/Typography/Typography'
import { useClinicalReviewPopup } from '../../pages/ClinicalReview/Providers/useClinicalReviewPopup'
import { TSubmitModalProps } from './types'

const SubmitFormModal = ({ setIsSubmit }: TSubmitModalProps) => {
  const { submitClinicalReviewPopup } = useClinicalReviewPopup()
  const { state, actions } = submitClinicalReviewPopup

  const handleClose = () => {
    actions.close()
    setIsSubmit(false)
  }

  return (
    <Popup
      visible={state.visible}
      onCancel={handleClose}
      title={state.title}
      width={490}
      footer={
        <>
          <Button type={BUTTON_TYPES.DEFAULT} onClick={handleClose} upperCase>
            {BTN_TXT.CANCEL}
          </Button>
          <Button
            type={BUTTON_TYPES.PRIMARY}
            form="clinical-review-form"
            htmlType="submit"
            name="submit"
            upperCase
          >
            {BTN_TXT.SUBMIT}
          </Button>
        </>
      }
    >
      <Typography.Body1>
        You will not be able to retract your response. Do you want to submit
        clinical review response?
      </Typography.Body1>
    </Popup>
  )
}

export default SubmitFormModal
