import './styles.scss'
import FullCalendar from '@fullcalendar/react'
import dayGridPlugin from '@fullcalendar/daygrid'
import { Props } from './types'
import { DayCellContentArg, DayHeaderContentArg } from 'fullcalendar'
import { useCallback } from 'react'
import cn from 'classnames'
import moment from 'moment'
import Typography from '../Typography/Typography'
import { TYPOGRAPHY_WEIGHT } from '../Typography'
import { useCalendarEventContext } from '../../pages/Calendar/CalendarEventsProvider'
import { useSelector } from 'react-redux'
import { getUser } from '../../redux/store/user/getters'
import { requestDateFormat } from '../../utils/moment'

export const Calendar = (props: Props) => {
  const { calendarRef, onEventClick, events, renderEventContent } = props
  const { queryEventId } = useCalendarEventContext().state
  const user = useSelector(getUser)
  const renderHeaderContent = useCallback(
    (info: DayHeaderContentArg) => (
      <div className="calendar-header__content">{info.text}</div>
    ),
    []
  )

  const compareDateWithToday = useCallback(
    (date: Date) => {
      const today = moment()
        .zone(user.timezone.offset)
        .format(requestDateFormat)
      const cellDate = moment(date).format(requestDateFormat)
      const isToday = moment(cellDate).isSame(today, 'day')
      const isPast = moment(cellDate).isBefore(today, 'day')
      return {
        isToday,
        isPast
      }
    },
    [user.timezone.offset]
  )

  const renderCellContent = useCallback((info: DayCellContentArg) => {
    return (
      <div
        className={cn('calendar-cell__num-label', {
          'calendar-cell__today': compareDateWithToday(info.date).isToday
        })}
      >
        <Typography.Body2
          className="date-label"
          weight={TYPOGRAPHY_WEIGHT.BOLD}
        >
          {info.date.getDate()}
        </Typography.Body2>
      </div>
    )
  }, [])

  const calendarCellClassnames = useCallback(
    (info: DayCellContentArg) => {
      const { isToday, isPast } = compareDateWithToday(info.date)
      const isGrayedCell = info.isOther || isPast || isToday
      return cn('calendar-cell', {
        'calendar-cell__grayed': isGrayedCell,
        'calendar-cell__active': !isGrayedCell
      })
    },
    [compareDateWithToday]
  )

  const eventClick = useCallback((e) => {
    onEventClick && onEventClick(e.event.id, e.el.id)
  }, [])

  return (
    <FullCalendar
      ref={calendarRef}
      plugins={[dayGridPlugin]}
      initialView="dayGridMonth"
      height="100%"
      eventContent={renderEventContent}
      dayHeaderContent={renderHeaderContent}
      dayCellContent={renderCellContent}
      dayCellClassNames={calendarCellClassnames}
      dayHeaderClassNames="calendar-header"
      eventClassNames="calendar-event"
      moreLinkClassNames="calendar-cell-more-link"
      moreLinkContent={(info) => `${info.num} more`}
      headerToolbar={false}
      dayMaxEvents={true}
      timeZone={user.timezone.name}
      eventClick={eventClick}
      dayPopoverFormat={{ weekday: 'short' }}
      events={events}
      eventDidMount={(info) => {
        const uniqueId = Math.random().toString(16).slice(2)
        info.el.id = uniqueId
        if (
          info.event._def.publicId === queryEventId &&
          !document.getElementsByClassName(queryEventId).length
        ) {
          info.el.classList.add(queryEventId)
          onEventClick && onEventClick(queryEventId, uniqueId)
        }
      }}
    />
  )
}
