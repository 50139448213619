import { Popup } from '../../../components/Popup'
import { Button, BUTTON_TYPES } from '../../../components/Button'
import { BTN_TXT } from '../../../constants'
import { Typography } from '../../../components/Typography'
import { usePRSDetailsPopupContext } from '../PRSDetails/Providers/PRSDetailsPopupProvider'
import { ReactComponent as Delete16 } from 'assets/svg/Delete16.svg'
import { useCallback } from 'react'
import { setLoading } from '../../../redux/store/common/slice'
import { usePRSDetailsContext } from '../PRSDetails/Providers/PRSDetailsContextProvider'
import { useDispatch } from 'react-redux'
import { deleteFacilityUsers } from '../../../pages/ProductRequests/api'
import { TPRSFacilityUsersData } from './types'

export type TProps = {
  updateAssignedValues: VoidFunction
  selected: string[]
  selectedUsers: TPRSFacilityUsersData[]
}
const DeleteAction = ({
  updateAssignedValues,
  selected,
  selectedUsers
}: TProps) => {
  const { deleteFacilityUsersPopup } = usePRSDetailsPopupContext()
  const { state, actions } = deleteFacilityUsersPopup
  const { open, close } = actions
  const { visible } = state
  const prsContext = usePRSDetailsContext()
  const dispatch = useDispatch()

  const handleDeleteUsers = useCallback(async () => {
    if (!prsContext.state.data.uuid) {
      throw new Error('PRS ID not provided')
    }

    try {
      dispatch(setLoading(true))

      await deleteFacilityUsers(
        prsContext.state.data.uuid,
        {
          facility_users: [...selected]
        },

        `User${selected.length > 1 ? 's' : ''} ${
          selected.length > 1 ? 'have' : 'has'
        } been successfully deleted.`
      )

      await updateAssignedValues()

      closeDeleteFacilityUsersPopup()
    } catch (e) {
      console.error(e)
    } finally {
      dispatch(setLoading(false))
    }
  }, [prsContext.state.data, dispatch, updateAssignedValues, selected])

  const openDeleteFacilityUsersPopup = () => open()
  const closeDeleteFacilityUsersPopup = () => close()
  return (
    <>
      {selected.length > 0 && (
        <Button
          type={BUTTON_TYPES.PRIMARY}
          onClick={openDeleteFacilityUsersPopup}
          icon={<Delete16 />}
          upperCase
          danger
        >
          {BTN_TXT.DELETE}
        </Button>
      )}
      <Popup
        visible={visible}
        onCancel={closeDeleteFacilityUsersPopup}
        title={`Delete ${
          selected.length === 1
            ? selectedUsers[0].first_name + ' ' + selectedUsers[0].last_name
            : `users`
        }?`}
        width={442}
        footer={
          <>
            <Button
              type={BUTTON_TYPES.DEFAULT}
              onClick={closeDeleteFacilityUsersPopup}
              upperCase
            >
              {BTN_TXT.CANCEL}
            </Button>
            <Button
              type={BUTTON_TYPES.PRIMARY}
              onClick={handleDeleteUsers}
              upperCase
              danger
            >
              {BTN_TXT.DELETE}
            </Button>
          </>
        }
      >
        <Typography.Body1>
          {selected.length === 1
            ? `${
                selectedUsers[0].first_name + ' ' + selectedUsers[0].last_name
              }`
            : `${selected.length} users`}{' '}
          will be deleted from the list.{' '}
          {selected.length === 1 ? 'This user ' : 'They '}will not receive
          survey and any PRS notification{selected.length === 1 ? '' : 's'}.
        </Typography.Body1>
      </Popup>
    </>
  )
}

export default DeleteAction
