import { ReactElement } from 'react'
import { TCheckInsVendorList } from '../../Providers/types'
import { RowItem } from '../ActiveCheckIn'

export const PrevContent = ({
  checkin
}: {
  checkin: TCheckInsVendorList
}): ReactElement => {
  return (
    <div className="check-ins-details-container__prev-check-ins__collapse-content column">
      <RowItem name={'Type of visit'} value={checkin.visit_type} />
      <RowItem name={'Health system'} value={checkin.health_system} />
      <RowItem name={'Department'} value={checkin.department} />
      <RowItem
        name={'Destination person'}
        value={checkin.destination_person_full_name}
      />
    </div>
  )
}
