import {
  createContext,
  FC,
  PropsWithChildren,
  useContext,
  useMemo,
  useState
} from 'react'
import { useSelector } from 'react-redux'
import { getUser } from '../../redux/store/user/getters'
import { ROLES } from '../../features/Permission'

type ContextProps = {
  state: {
    isVendor: boolean
    isVCS: boolean
    selectedId: string
  }
  actions: {
    setSelectedId: (val: string) => void
  }
}
const ProductRequestsContext = createContext<ContextProps>({
  state: null!,
  actions: null!
})

const ProductRequestsContextContextProvider: FC<PropsWithChildren> = ({
  children
}) => {
  const user = useSelector(getUser)

  const [selectedId, setSelectedId] = useState<string>('')

  const isVendor = useMemo(() => Boolean(user.vendor), [user.vendor])

  const isVCS = useMemo(
    () => Boolean(user?.role === ROLES.VENDOR_CONTRACT_STEWARD),
    [user.role]
  )

  const context = useMemo(
    () => ({
      state: {
        isVendor,
        isVCS,
        selectedId
      },
      actions: { setSelectedId }
    }),
    [isVendor, isVCS, selectedId, setSelectedId]
  )
  return (
    <ProductRequestsContext.Provider value={context}>
      {children}
    </ProductRequestsContext.Provider>
  )
}
export const useProductRequestsContext = () =>
  useContext(ProductRequestsContext)

export default ProductRequestsContextContextProvider
