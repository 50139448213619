import { useCallback, useLayoutEffect, useMemo } from 'react'
import '../styles.scss'
import { Drawer } from 'components/Drawer'
import { GroupChatDetails } from 'features/ChatDetails/GroupChatDetails'
import { ChannelDetails } from 'features/ChatDetails/ChannelDetails'
import { PrivateChatDetails } from 'features/ChatDetails/PrivateChatDetails'
import { useChatPopup } from 'features/Chat/Providers/ChatPopupProvider'
import { CHAT_TYPE } from 'constants/common'

const Sidebar = () => {
  const { sidebarDrawer } = useChatPopup()

  const sidebarType = useMemo(
    () => sidebarDrawer.state.payload?.type,
    [sidebarDrawer.state.payload?.type]
  )

  const updateTitle = useCallback(
    (title: string) => {
      sidebarDrawer.actions.setState((prevState) => ({
        ...prevState,
        title
      }))
    },
    [sidebarDrawer.actions]
  )

  const DrawerContent = useMemo(() => {
    switch (sidebarType) {
      case CHAT_TYPE.GROUP:
        return <GroupChatDetails />
      case CHAT_TYPE.CHANNEL:
        return <ChannelDetails />
      case CHAT_TYPE.PRIVATE:
        return <PrivateChatDetails />
      default:
        return null
    }
  }, [sidebarType])

  useLayoutEffect(() => {
    if (sidebarType === CHAT_TYPE.CHANNEL) {
      updateTitle('Channel details')
    } else {
      updateTitle('Chat details')
    }
  }, [sidebarType, updateTitle])

  return (
    <Drawer {...sidebarDrawer.state} className="chat-details-sidebar">
      {DrawerContent}
    </Drawer>
  )
}

export default Sidebar
