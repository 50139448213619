import {
  TTrialApprovalUsersColumnsFunc,
  TTrialApprovalUsersData,
  TTrialApprovalUsersFilters,
  TTrialApprovalUsersFiltersFunc
} from './types'
import { ColumnsType } from 'antd/es/table'
import { Sorter } from 'components/Sorter/Sorter'
import { SortOrder } from 'antd/es/table/interface'
import { TRIAL_APPROVAL_STATUSES_OPTIONS } from 'constants/index'
import { Dropdown } from 'components/Dropdown'
import {
  Status,
  TRIAL_APPROVAL_LABEL,
  TRIAL_APPROVAL_TYPES_TO_CLASSNAME
} from 'components/Status'
import { fetchHealthSystemsList } from '../../../HealthSystems/api'
import { CellWithSubtitle } from 'components/CellWithSubtitle/CellWithSubtitle'
import { ReactComponent as Filter16 } from 'assets/svg/Filter16.svg'
import { ReactComponent as FilterApplied16 } from 'assets/svg/FilterApplied16.svg'

export const columns = ({
  applyFilters,
  filters,
  community
}: TTrialApprovalUsersColumnsFunc): ColumnsType<TTrialApprovalUsersData> => {
  const _columns: ColumnsType<TTrialApprovalUsersData> = [
    {
      dataIndex: 'full_name',
      key: 'full_name',
      width: '320px',
      title: ({ sortOrder, sortColumn }) => (
        <Sorter<TTrialApprovalUsersData>
          dataIndex="full_name"
          sortColumn={sortColumn}
          sortOrder={sortOrder as SortOrder}
        >
          Stakeholder
        </Sorter>
      ),
      render: (_, { full_name }) => `${full_name}`,
      sorter: true,
      showSorterTooltip: false
    },
    {
      dataIndex: 'email',
      key: 'email',
      width: '320px',
      title: ({ sortOrder, sortColumn }) => (
        <Sorter<TTrialApprovalUsersData>
          dataIndex="email"
          sortColumn={sortColumn}
          sortOrder={sortOrder as SortOrder}
        >
          Email
        </Sorter>
      ),
      sorter: true,
      showSorterTooltip: false
    },
    {
      dataIndex: 'health_system',
      key: 'health_system',
      width: '320px',
      title: ({ sortOrder, sortColumn }) => (
        <Sorter<TTrialApprovalUsersData>
          dataIndex="health_system"
          sortColumn={sortColumn}
          sortOrder={sortOrder as SortOrder}
        >
          Health system
        </Sorter>
      ),
      filterDropdown: (props) => (
        <Dropdown.Filter<
          TTrialApprovalUsersFilters,
          TTrialApprovalUsersFiltersFunc,
          unknown
        >
          applyFilters={applyFilters}
          appliedFilters={filters}
          searchCallback={() => fetchHealthSystemsList(community)}
          field="health_systems"
          asyncSearch
          {...props}
        />
      ),
      render: (_, { health_system }) => (
        <CellWithSubtitle title={health_system} />
      ),
      filterIcon: !!filters?.health_systems?.length ? (
        <FilterApplied16 />
      ) : (
        <Filter16 />
      ),
      sorter: true,
      filterSearch: true,
      showSorterTooltip: false
    },
    {
      dataIndex: 'decision',
      key: 'decision',
      width: 176,
      title: ({ sortOrder, sortColumn }) => (
        <Sorter<TTrialApprovalUsersData>
          dataIndex="decision"
          sortColumn={sortColumn}
          sortOrder={sortOrder as SortOrder}
        >
          Trial approval
        </Sorter>
      ),
      filterDropdown: (props) => (
        <Dropdown.Filter<
          TTrialApprovalUsersFilters,
          TTrialApprovalUsersFiltersFunc,
          unknown
        >
          items={TRIAL_APPROVAL_STATUSES_OPTIONS}
          applyFilters={applyFilters}
          appliedFilters={filters}
          field="decisions"
          {...props}
        />
      ),
      render: (_, { decision }) => (
        <Status.Default className={TRIAL_APPROVAL_TYPES_TO_CLASSNAME[decision]}>
          {TRIAL_APPROVAL_LABEL[decision]}
        </Status.Default>
      ),
      filterIcon: !!filters?.decisions?.length ? (
        <FilterApplied16 />
      ) : (
        <Filter16 />
      ),
      filterSearch: true,
      sorter: true,
      showSorterTooltip: false
    }
  ]
  return _columns
}

export const CHARTS_COLORS_TXT = {
  approved: {
    color: '#62DA84',
    name: 'Approved '
  },
  no_response: {
    color: '#B3BEC6',
    name: 'No response'
  },
  rejected: {
    color: '#FF6666',
    name: 'Rejected'
  }
}
