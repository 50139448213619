import { LeadByIndicator } from '../../../components/LeadByIndicator'
import {
  TContactPersonsProps,
  TOpportunityAnalysisContactPersonsList
} from './types'
import { useCognusRepositoryPopup } from '../Providers/CognusRepositoryPopupProvider'
import Popup from '../../../components/Popup/Popup'
import { ROLES_NAMES } from '../../../constants'
import { FieldWithLabel } from '../../../components/FieldWithLabel/FieldWithLabel'
import { IconFullName } from '../../HealthSystemDetails/IconFullName'
import { useState } from 'react'
import { getLedByContactPersonsList } from '../../Contracts/api'
import { useDispatch } from 'react-redux'
import { setLoading } from '../../../redux/store/common/slice'
import Typography from '../../../components/Typography/Typography'
import { LED_BY_LABEL } from '../../../components/LeadByIndicator/constants'
import './style.scss'

export const ContactPersons = ({
  led_by,
  contract_category,
  uuid
}: TContactPersonsProps) => {
  const { contactPersonsPopup } = useCognusRepositoryPopup()
  const { actions, state } = contactPersonsPopup
  const [contactPersons, setContactPersons] = useState<
    TOpportunityAnalysisContactPersonsList[]
  >([])
  const dispatch = useDispatch()

  const getContactPerson = async () => {
    dispatch(setLoading(true))
    await getLedByContactPersonsList(uuid)
      .then((res) => {
        setContactPersons(res.data.results)
        actions.open()
      })
      .finally(() => {
        dispatch(setLoading(false))
      })
  }
  const handleOpenModal = () => {
    getContactPerson()
  }

  return (
    <div className="contact-persons-wrapper">
      <LeadByIndicator value={led_by} onClick={handleOpenModal} />
      <Popup
        visible={state.visible}
        onCancel={actions.close}
        title={`${contract_category}`}
        width={460}
        footer={null}
      >
        <>
          <FieldWithLabel
            title="Led by"
            className="contact-persons-wrapper__led-by"
          >
            <div className="contact-persons-wrapper__led-by__wrapper">
              <LeadByIndicator value={led_by} />
              <Typography.Body1>{LED_BY_LABEL[led_by]}</Typography.Body1>
            </div>
          </FieldWithLabel>
          <FieldWithLabel
            title="Contact person"
            className="contact-persons-wrapper__contacts mt-24"
          >
            {contactPersons.map((i) => {
              return (
                <IconFullName
                  className="user-form_community"
                  title=""
                  key={i.uuid}
                  firstName={i.first_name}
                  lastName={i.last_name}
                  roleName={ROLES_NAMES[i.role]}
                  src={i?.avatar?.file_preview}
                  uuid={i.uuid}
                />
              )
            })}
          </FieldWithLabel>
        </>
      </Popup>
    </div>
  )
}
