import './styles.scss'
import { Popup } from 'components/Popup'
import { Button, BUTTON_TYPES } from 'components/Button'
import { BTN_TXT } from 'constants/txt'
import { useRFPDetailsPopupContext } from 'features/RFP/RFPDetails/Providers/RFPDetailsPopupProvider'
import AddResponsibleForRFP from 'features/RFP/RFPDetails/ResponsibleForRFP/AddResponsibleForRFP/AddResponsibleForRFP'
import { useState } from 'react'
import { TResponsibleUser } from '../../types'

export default ({ isCommonsRFP }: { isCommonsRFP?: boolean }) => {
  const { addResponsibleUserPopup } = useRFPDetailsPopupContext()
  const [selectedLegalUuid, setSelectedLegal] = useState<
    TResponsibleUser['uuid'] | null
  >(null)
  const [selectedAnalystUuid, setSelectedAnalyst] = useState<
    TResponsibleUser['uuid'] | null
  >(null)
  const { visible, onCancel, title } = addResponsibleUserPopup.state
  const { close } = addResponsibleUserPopup.actions

  return (
    <Popup
      visible={visible}
      onCancel={onCancel}
      className="responsible-popup"
      title={isCommonsRFP ? 'Add responsible for RFP' : title}
      width={442}
      footer={
        <>
          <Button type={BUTTON_TYPES.DEFAULT} onClick={close} upperCase>
            {BTN_TXT.CANCEL}
          </Button>
          <Button
            htmlType="submit"
            form="rfp-add-responsible"
            type={BUTTON_TYPES.PRIMARY}
            upperCase
            disabled={!selectedLegalUuid && !selectedAnalystUuid}
          >
            {BTN_TXT.ADD}
          </Button>
        </>
      }
    >
      <AddResponsibleForRFP
        setSelectedLegal={setSelectedLegal}
        selectedLegalUuid={selectedLegalUuid}
        selectedAnalystUuid={selectedAnalystUuid}
        setSelectedAnalyst={setSelectedAnalyst}
        isCommonsRFP={isCommonsRFP}
      />
    </Popup>
  )
}
