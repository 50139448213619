import { CHAT_TYPE } from 'constants/common'
import {
  IChannelChatDetails,
  IChatDetails,
  IPrivateChatDetails
} from 'redux/store/chatDetails/types'
import { IChannelChat, IChat, IPrivateChat } from 'redux/store/chats/types'

export const chatListItemToDetailsConvertor = (
  chatListItem: IChat
): IChatDetails => {
  switch (chatListItem.type) {
    case CHAT_TYPE.CHANNEL:
      const { rfp, ...channelChatListItem } = chatListItem as IChannelChat

      return {
        ...channelChatListItem,
        rfp_number: rfp.number,
        rfp_id: rfp.uuid,
        rfp_name: rfp.name
      } as IChannelChatDetails
    case CHAT_TYPE.PRIVATE:
      const { name, pvp_user_role, pvp_user_image, ...privateChatListItem } =
        chatListItem as IPrivateChat

      return {
        ...privateChatListItem,
        role: pvp_user_role,
        full_name: name,
        avatar: { file_preview: pvp_user_image }
      } as IPrivateChatDetails
    default:
      return chatListItem
  }
}
