import {
  FC,
  PropsWithChildren,
  createContext,
  useEffect,
  useCallback,
  useContext,
  useState
} from 'react'
import { useSelector } from 'react-redux'

import Socket from 'services/Socket'
import { getUser } from 'redux/store/user/getters'
import { BASE_SOCKET_URL } from 'constants/socket'
import { getLocalAccessToken } from 'helper/common'

type ContextSocket = Socket | undefined

const AppSocketContext = createContext<ContextSocket>(undefined)

const AppSocketProvider: FC<PropsWithChildren> = ({ children }) => {
  const user = useSelector(getUser)

  const [socket, setSocket] = useState<ContextSocket>()

  const _createSocket = useCallback(() => {
    const token = getLocalAccessToken()

    if (!user.uuid || !token || socket) {
      return
    }

    const socketUrl = new URL(BASE_SOCKET_URL)

    socketUrl.searchParams.append('token', token)

    setSocket(new Socket(socketUrl.href))
  }, [socket, user.uuid])

  const _removeSocket = useCallback(() => {
    const token = getLocalAccessToken()

    if (!user.uuid || !token) {
      setSocket(undefined)
    }
  }, [user.uuid])

  useEffect(() => {
    _createSocket()
  }, [_createSocket])

  useEffect(() => {
    _removeSocket()
  }, [_removeSocket])

  return (
    <AppSocketContext.Provider value={socket}>
      {children}
    </AppSocketContext.Provider>
  )
}

export const useAppSocket = () => useContext(AppSocketContext)

export default AppSocketProvider
