import { Typography } from 'components/Typography'
import { PLACEHOLDERS } from '../../../constants'
import { ReactComponent as Search16 } from 'assets/svg/Search16.svg'
import { Input } from 'components/Input'
import { useEffect, useState } from 'react'
import { useCheckInsListContext } from '../Providers/CheckInsContextProvider'
import { CheckInsListFilterModalContainer } from './CheckInsListFilters/CheckInsListFilterModalContainer'

export const CheckInsListHeader = () => {
  const { actions } = useCheckInsListContext()

  const [searchValue, setSearchValue] = useState<string>('')

  useEffect(() => {
    actions.setSearch(searchValue)
  }, [searchValue])

  return (
    <div className="header column check-ins-container__list__header mb-24">
      <Typography.Headline3>Check-ins</Typography.Headline3>
      <div className="row mt-24 justify-end check-ins-container__list__header__filters">
        <Input
          propsInput={{
            placeholder: PLACEHOLDERS.SEARCH,
            prefix: <Search16 />,
            value: searchValue,
            onChange: (e) => setSearchValue(e.target.value)
          }}
        />
        <CheckInsListFilterModalContainer />
      </div>
    </div>
  )
}
