import { useMemo, useState } from 'react'

import { Tab } from 'constants/types'

type Props = {
  tabs: Tab[]
  activeTab?: Tab['key']
}

const useTabs = (props: Props) => {
  const { tabs: initialTabs = [], activeTab: initialActiveTab = '0' } = props

  const [tabs, setTabs] = useState(initialTabs)
  const [activeTab, setActiveTab] = useState(initialActiveTab)
  const state = useMemo(
    () => ({
      tabs,
      activeTab
    }),
    [activeTab, tabs]
  )
  const actions = useMemo(() => ({ setTabs, setActiveTab }), [])

  return { state, actions }
}

export type TabsReturnType = ReturnType<typeof useTabs>
export type TabsReturnState = ReturnType<typeof useTabs>['state']
export type TabsReturnActions = ReturnType<typeof useTabs>['actions']

export default useTabs
