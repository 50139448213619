import Popup from '../../../../components/Popup/Popup'
import { Button, BUTTON_TYPES } from '../../../../components/Button'
import { BTN_TXT } from '../../../../constants'
import {
  Typography,
  TYPOGRAPHY_WEIGHT
} from '../../../../components/Typography'
import FormClinicalSponsor from './FormClinicalSponsor'
import { useEffect, useState } from 'react'
import { usePRSDetailsPopupContext } from '../Providers/PRSDetailsPopupProvider'
import { usePRSDetailsContext } from '../Providers/PRSDetailsContextProvider'

const AssignClinicalSponsorModal = () => {
  const { assignClinicalSponsorPopup } = usePRSDetailsPopupContext()
  const detailsContext = usePRSDetailsContext()
  const { data } = detailsContext.state
  const { state, actions } = assignClinicalSponsorPopup
  const { visible } = state
  const { close } = actions
  const [isChanged, setIsChanged] = useState(false)

  useEffect(() => {
    if (!visible) setIsChanged(false)
  }, [visible])

  return (
    <Popup
      visible={visible}
      onCancel={close}
      className="responsible-popup"
      title={'Clinical Sponsor user'}
      width={442}
      footer={
        <>
          <Button type={BUTTON_TYPES.DEFAULT} onClick={close} upperCase>
            {BTN_TXT.CANCEL}
          </Button>
          <Button
            htmlType="submit"
            form="prs-add-clinical-sponsor"
            type={BUTTON_TYPES.PRIMARY}
            upperCase
            disabled={!isChanged}
          >
            {!!data.clinical_sponsor_stakeholder
              ? BTN_TXT.REASSIGN_CLINICAL_SPONSOR
              : BTN_TXT.ASSIGN_CLINICAL_SPONSOR}
          </Button>
        </>
      }
    >
      <Typography.Body1 weight={TYPOGRAPHY_WEIGHT.NORMAL}>
        Usually Clinical Sponsor is user that has stakeholder role.
      </Typography.Body1>
      <FormClinicalSponsor setIsChanged={setIsChanged} />
    </Popup>
  )
}

export default AssignClinicalSponsorModal
