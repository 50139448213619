// Depends
import { ErrorInfo } from 'react'
import * as Sentry from '@sentry/react'

export const severityLevels = {
  error: 'error',
  warning: 'warning'
} as const

interface ISettings {
  severityLevel?: keyof typeof severityLevels
}

class Logger {
  severityLevel: keyof typeof severityLevels

  constructor({ severityLevel = severityLevels.error }: ISettings) {
    this.severityLevel = severityLevel
  }

  error(error: Error, errorInfo: ErrorInfo) {
    if (this.severityLevel === severityLevels.error) {
      Sentry.captureException({ ...error, ...errorInfo })
      console.error(error, errorInfo)
    }
  }
}

export default Logger
