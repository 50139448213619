import { FC, useMemo } from 'react'
import cn from 'classnames'

import './style.scss'
import { ILRenderItem } from 'components/InfiniteList/InfiniteList'
import { IHistoryItem } from '../types'
import { useSelector } from 'react-redux'
import { getUser } from '../../../../redux/store/user/getters'
import { HISTORY_COMMENT_TYPE, HISTORY_TITLES } from './constants'
import { Avatar } from 'components/Avatar/Avatar'
import { Status } from 'components/Status'
import {
  PRS_STATUSES,
  PRS_STATUSES_CLASSNAME,
  PRS_STATUSES_NAME
} from 'pages/ProductRequests/constants'
import { ReactComponent as ArrowRight24 } from 'assets/svg/ArrowRight24.svg'
import Typography from '../../../../components/Typography/Typography'
import { defaultDateFormat, defaultTimeFormat } from '../../../../helper/dates'
import { TYPOGRAPHY_WEIGHT } from '../../../../components/Typography'

const Item: FC<ILRenderItem<IHistoryItem>> = (props) => {
  const { item } = props
  const { uuid } = item

  const user = useSelector(getUser)
  const isCurrentUser = user.uuid === item?.changed_by?.uuid

  const { changed_by, action_type, changes } = item
  const { to, from } = changes

  const capitalCharName = `${changed_by?.first_name?.charAt(0) || ''}${
    changed_by?.last_name?.charAt(0) || ''
  }`.toUpperCase()

  const canSeeToStatus = useMemo(() => {
    return (
      to?.trial_status &&
      to?.trial_status !== PRS_STATUSES.TRIAL_COMPLETED &&
      to.status === PRS_STATUSES.IN_REVIEW
    )
  }, [to])

  const canSeeFromStatus = useMemo(() => {
    return (
      from?.trial_status &&
      from?.trial_status !== PRS_STATUSES.TRIAL_COMPLETED &&
      from.status === PRS_STATUSES.IN_REVIEW
    )
  }, [from])

  return (
    <div {...props} className={cn('prs-history-item column')} key={uuid}>
      <div className="row prs-history-item__acton-title">
        <Avatar
          name={capitalCharName}
          src={changed_by?.avatar?.file_preview || ''}
          alt="user image"
        />
        <Typography.Body1
          className="prs-history-item__acton-title__user"
          weight={TYPOGRAPHY_WEIGHT.SEMI_BOLD}
        >
          {isCurrentUser
            ? 'You'
            : `${changed_by?.first_name} ${changed_by?.last_name}`}
        </Typography.Body1>
        <Typography.Body1 weight={TYPOGRAPHY_WEIGHT.SEMI_BOLD}>
          &nbsp;{isCurrentUser ? 'have ' : 'has '}
          {HISTORY_TITLES[action_type]}
        </Typography.Body1>
      </div>
      <div className="row prs-history-item__status-wrapper mt-8 align-center">
        {from?.status && !canSeeFromStatus && (
          <Status.Default className={PRS_STATUSES_CLASSNAME[from?.status]}>
            {PRS_STATUSES_NAME[from?.status]}
          </Status.Default>
        )}
        {!from.status && (
          <Typography.Label weight={TYPOGRAPHY_WEIGHT.SEMI_BOLD}>
            None
          </Typography.Label>
        )}
        {from?.trial_status &&
          from.trial_status !== PRS_STATUSES.TRIAL_COMPLETED && (
            <Status.Default
              className={PRS_STATUSES_CLASSNAME[from?.trial_status]}
            >
              {PRS_STATUSES_NAME[from?.trial_status]}
            </Status.Default>
          )}
        <ArrowRight24 />
        {to?.status && !canSeeToStatus && (
          <Status.Default className={PRS_STATUSES_CLASSNAME[to?.status]}>
            {PRS_STATUSES_NAME[to?.status]}
          </Status.Default>
        )}
        {to?.trial_status && (
          <Status.Default className={PRS_STATUSES_CLASSNAME[to?.trial_status]}>
            {PRS_STATUSES_NAME[to?.trial_status]}
          </Status.Default>
        )}
      </div>
      {item.comment && (
        <div className="column mt-8 prs-history-item__comment">
          <Typography.Label
            className="prs-history-item__comment__label"
            weight={TYPOGRAPHY_WEIGHT.SEMI_BOLD}
          >
            Comment
          </Typography.Label>
          {!!HISTORY_COMMENT_TYPE[item.action_type] && (
            <Typography.Body2 className="prs-history-item__comment__type mt-4">
              {HISTORY_COMMENT_TYPE[item.action_type]}
            </Typography.Body2>
          )}
          <Typography.Caption className="mt-4">
            {item.comment}
          </Typography.Caption>
        </div>
      )}
      <div className="row prs-history-item__date-wrapper mt-8 align-center">
        <Typography.Caption className="message-item-container__author__current-time">
          {defaultDateFormat(item.created_at)}
        </Typography.Caption>
        <Typography.Caption className="message-item-container__author__current-time">
          {defaultTimeFormat(item.created_at)}
        </Typography.Caption>
      </div>
    </div>
  )
}

export default Item
