import { useState, useEffect, useRef } from 'react'
import { useDispatch } from 'react-redux'
import axios, { CancelTokenSource } from 'axios'
import { Table } from 'components/Table'
import { Input } from 'components/Input'
import { setLoading } from 'redux/store/common/slice'
import { useDebounce } from 'hooks/hooks'
import {
  handleFilesErrors,
  isTableHasParams,
  validateFileTypes
} from 'helper/common'
import { BTN_TXT } from 'constants/txt'
import { DEFAULT_PAGE } from 'components/Table/constants'
import { ReactComponent as Search16 } from 'assets/svg/Search16.svg'
import { useContractDetailsContext } from 'features/ContractDetails/Providers/ContractDetailsContextProvider'
import {
  CONTRACT_ITEM_LIST_TYPES,
  DOCUMENT_TYPE
} from 'components/FileUpload/constants'
import { FileUpload } from 'components/FileUpload/FileUpload'
import { AttachmentsList } from 'components/AttachmentsList/AttachmentsList'
import { getContractItemsTableData, uploadContractItems } from './api'
import {
  TContractItemsListParams,
  TContractItemsListRecord,
  TContractItemsRequestData
} from './types'
import './styles.scss'
import { CONTRACT_STATUS } from '../ContractDetails/constants'
import { ReactComponent as Close16 } from '../../assets/svg/Clos16.svg'
import {
  ACTIONS,
  contractSubject,
  SUBJECTS
} from '../ContractDetails/Abilities'
import {
  Can,
  useContractDetailsAbility
} from 'features/ContractDetails/Providers/ContractDetailsAbilityProvider'
import { TDocumentsErrors } from '../../constants'
import { useColumns } from './hooks/useColumns'

export const ContractItemsTable = () => {
  const contractDetailsContext = useContractDetailsContext()

  const { details, isVCAOrVFO, isVCS } = contractDetailsContext.state

  const [tableData, setTableData] = useState<TContractItemsListRecord[]>([])
  const [searchValue, setSearchValue] = useState('')
  const [sortParams, setSortParams] = useState<TContractItemsRequestData>()
  const [firstLoad, setFirstLoad] = useState(true)
  const [totalNumber, setTotalNumber] = useState(0)
  const [pageInfo, setPageInfo] = useState({ ...DEFAULT_PAGE })

  const timeoutRef = useRef<ReturnType<typeof setTimeout>>()
  const tableDataTokenRef = useRef<CancelTokenSource>()
  const dispatch = useDispatch()
  const debouncedSearch = useDebounce(searchValue, 500)

  const { columns } = useColumns(details.uuid, tableData)

  const ability = useContractDetailsAbility()

  const getTableData = ({
    sortField,
    sortOrder,
    page
  }: TContractItemsRequestData) => {
    dispatch(setLoading(true))
    if (!!tableDataTokenRef.current) {
      tableDataTokenRef.current.cancel()
    }
    const params: TContractItemsListParams = {}
    if (searchValue) {
      params.search = searchValue
    }
    if (sortField) {
      if (sortOrder) {
        params.ordering = `${sortOrder === 'descend' ? '-' : ''}${sortField}`
      }
      setSortParams({
        sortField,
        sortOrder
      })
    }
    const dataPage = page ? page : pageInfo
    params.limit = dataPage.pageSize
    params.offset = (dataPage.pageNumber - 1) * dataPage.pageSize
    tableDataTokenRef.current = axios.CancelToken.source()
    details.uuid &&
      getContractItemsTableData(
        {
          cancelToken: tableDataTokenRef.current?.token,
          params
        },
        details.uuid
      )
        .then((resp) => {
          if (!resp.data?.results) {
            return
          }
          setTableData(
            resp.data.results.map((i) => {
              return {
                ...i.record,
                key: i.record.uuid
              }
            })
          )
          setTotalNumber(resp.data.count)
          if (firstLoad) {
            setFirstLoad(false)
          }
        })
        .finally(() => dispatch(setLoading(false)))
  }

  const handleTableChange = (pagination, _filters, sorter) => {
    const page = {
      pageNumber: pagination.current,
      pageSize: pagination.pageSize
    }
    getTableData({
      sortField: sorter.field,
      sortOrder: sorter.order,
      page
    })
    setPageInfo(page)
  }
  const handleChangePageSize = (pageSize) => {
    const page = {
      pageNumber: 1,
      pageSize
    }
    getTableData({
      ...sortParams,
      page
    })
    setPageInfo(page)
  }

  const handleUploadFile = async (files: File[], documentType: string) => {
    dispatch(setLoading(true))

    const formData = new FormData()
    formData.append(documentType, files[0], files[0].name)

    try {
      const response = await uploadContractItems(
        {
          document_type: documentType,
          file: formData.get(documentType)
        },
        details.uuid
      )
      if (response) {
        await getTableData({ ...sortParams })
      }
    } catch (e) {
      handleFilesErrors(e as TDocumentsErrors)
    } finally {
      dispatch(setLoading(false))
    }
  }

  const UploadBtn = () =>
    details.status !== CONTRACT_STATUS.TERMINATED &&
    details.status !== CONTRACT_STATUS.DECLINED && (
      <div className="table-wrapper__header-buttons">
        <FileUpload
          documentType={DOCUMENT_TYPE.QUESTIONS}
          uploadBtnText={BTN_TXT.UPLOAD}
          handleUploadFile={handleUploadFile}
          uploadButtonText={
            tableData?.length
              ? BTN_TXT.RENEW_ITEM_LIST
              : BTN_TXT.UPLOAD_ITEM_LIST
          }
          uploadFilesProps={{
            multiple: false,
            maxSize: 5242880,
            onDropAccepted: (file) => handleUploadFile(file, 'questions'),
            accept: {
              ...CONTRACT_ITEM_LIST_TYPES
            },
            validator: (file) =>
              validateFileTypes(CONTRACT_ITEM_LIST_TYPES, file)
          }}
        />
      </div>
    )

  useEffect(() => {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current)
    }
    timeoutRef.current = setTimeout(
      () => getTableData({ ...sortParams }),
      firstLoad ? 0 : 500
    )
  }, [debouncedSearch, details])

  useEffect(() => {
    return () => {
      if (!!tableDataTokenRef.current) {
        tableDataTokenRef.current.cancel()
      }
    }
  }, [])

  return (
    <div className="table-wrapper community-users">
      <Can I={ACTIONS.UPLOAD} a={contractSubject(SUBJECTS.ITEM_LIST, details)}>
        {!isVCAOrVFO && !isVCS && (
          <AttachmentsList
            label="Template"
            files={details.contract_document_templates.filter(
              (d) => d.document_type === 'item_list'
            )}
            disableActions
            className="items-list_template"
          />
        )}
      </Can>

      <div className="table-wrapper__header">
        <Input
          className="allow-clear"
          propsInput={{
            allowClear: { clearIcon: <Close16 /> },
            placeholder: 'Search',
            prefix: <Search16 />,
            value: searchValue,
            onChange: (e) => setSearchValue(e.target.value)
          }}
        />
        <Can
          I={ACTIONS.UPLOAD}
          a={contractSubject(SUBJECTS.ITEM_LIST, details)}
        >
          {!isVCAOrVFO && !isVCS && UploadBtn()}
        </Can>
      </div>
      {!firstLoad && (
        <Table<TContractItemsListRecord>
          dataSource={tableData}
          className="contacts-item-list-table"
          columns={columns}
          onChange={handleTableChange}
          hasSearchOrFilters={isTableHasParams(debouncedSearch, false)}
          onChangePage={handleChangePageSize}
          pageSize={pageInfo.pageSize}
          pagination={{
            pageSize: pageInfo.pageSize,
            current: pageInfo.pageNumber,
            total: totalNumber
          }}
          renderButton={
            ability.can(
              ACTIONS.UPLOAD,
              contractSubject(SUBJECTS.ITEM_LIST, details)
            ) && UploadBtn()
          }
        />
      )}
    </div>
  )
}
