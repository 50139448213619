import { routes } from 'router'

export const PUBLIC_URLS = [
  routes.setPassword,
  routes.resetPassword,
  routes.confirmEmail,
  routes.faNumber,
  routes.faCode,
  routes.verifyEmail,
  routes.pendingApproval,
  routes.termsOfUse,
  routes.privacyPolicy,
  routes.landing,
  routes.selfSignUp,
  routes.completeProfile
] as const

export const DEFAULT_PUBLIC_URLS = [
  routes.faCode,
  routes.faNumber,
  routes.verifyEmail,
  routes.pendingApproval,
  routes.landing
]
