import { Can } from '../Providers/PrsDetailsAbilityProvider'
import { ACTIONS, SUBJECTS } from '../../Abilities'
import { subject } from '@casl/ability'
import { Button, BUTTON_TYPES } from '../../../../components/Button'
import { BTN_TXT } from '../../../../constants'
import {
  Typography,
  TYPOGRAPHY_WEIGHT
} from '../../../../components/Typography'
import Popup from '../../../../components/Popup/Popup'
import { usePRSDetailsPopupContext } from '../Providers/PRSDetailsPopupProvider'
import { usePRSDetailsContext } from '../Providers/PRSDetailsContextProvider'
import { PRS_STATUSES } from '../../../../pages/ProductRequests/constants'

const CancelPrs = () => {
  const prsDetailsContext = usePRSDetailsContext()

  const { data } = prsDetailsContext.state
  const { cancelPRSPopup } = usePRSDetailsPopupContext()

  const { state, actions } = cancelPRSPopup
  const openCancelModal = () => actions.open()

  const onSend = () => {
    prsDetailsContext.actions.handleChangePrsStatus(PRS_STATUSES.CANCELED)
  }
  return (
    <>
      <Can I={ACTIONS.CANCEL} a={subject(SUBJECTS.PRS, { ...data })}>
        <Button
          type={BUTTON_TYPES.DEFAULT}
          onClick={openCancelModal}
          danger
          upperCase
        >
          {BTN_TXT.CANCEL_PRS}
        </Button>
      </Can>
      <Popup
        visible={state.visible}
        onCancel={actions.close}
        title={`Cancel ${data.name}?`}
        width={442}
        footer={
          <>
            <Button
              onClick={actions.close}
              type={BUTTON_TYPES.DEFAULT}
              upperCase
            >
              {BTN_TXT.BACK_TO_PRS_DETAILS}
            </Button>
            <Button
              danger
              type={BUTTON_TYPES.PRIMARY}
              upperCase
              onClick={onSend}
            >
              {BTN_TXT.CANCEL_PRS}
            </Button>
          </>
        }
      >
        <Typography.Headline6 weight={TYPOGRAPHY_WEIGHT.NORMAL}>
          All the PRS progress will be lost. Do you really want to cancel PRS?
        </Typography.Headline6>
      </Popup>
    </>
  )
}

export default CancelPrs
