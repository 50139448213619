import { BidResendReason } from '../BidResendReason'
import { useRFPDetailsContext } from '../Providers/RFPDetailsContextProvider'
import AssignRfpBanner from './AssignRfpBanner'

const ReasonComponent = () => {
  const { state } = useRFPDetailsContext()
  const { data, canSeeAssignBanner, canSeeBidResendReason } = state

  return (
    <>
      {canSeeBidResendReason && <BidResendReason bidResend={data.resend} />}
      {canSeeAssignBanner && <AssignRfpBanner />}
    </>
  )
}

export default ReasonComponent
