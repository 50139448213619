import { Tooltip as TooltipAntd } from 'antd'
import { Props } from './types'
import './styles.scss'
import cn from 'classnames'

export const Tooltip = ({ children, ...props }: Props) => (
  <TooltipAntd
    overlayClassName={cn('tooltip-default', props.className)}
    {...props}
  >
    {children}
  </TooltipAntd>
)
