import './styles.scss'

import { Avatar } from 'components/Avatar/Avatar'
import { Typography } from 'components/Typography'
import { FieldWithLabel } from 'components/FieldWithLabel/FieldWithLabel'
import { useVendorDetailsContext } from 'features/VendorDetails/Providers/ContextProvider'
import { getNameInitials } from 'helper/common'
import { useMemo } from 'react'

const ContractAdminPreview = () => {
  const vendorDetailsContext = useVendorDetailsContext()

  const { contract_admin, vendor_permission, contract_steward } =
    vendorDetailsContext.state.details

  const user = useMemo(() => {
    if (vendor_permission === 'guest') {
      return contract_steward
    } else return contract_admin
  }, [vendor_permission, contract_steward, contract_admin])

  const fullName = user ? [user.first_name, user.last_name].join(' ') : ''

  return (
    <FieldWithLabel
      title={
        vendor_permission === 'guest' ? 'Contract Steward' : 'Contract Admin'
      }
    >
      <div className="vendor-details-preview__contract-admin">
        <Avatar
          name={getNameInitials(fullName)}
          alt="Responsible user image"
          src={user?.avatar?.file_preview}
        />
        <Typography.Body1 weight="semi-bold">{fullName}</Typography.Body1>
      </div>
    </FieldWithLabel>
  )
}

export default ContractAdminPreview
