import { ReactComponent as Filter16 } from '../../../../assets/svg/Filter16.svg'
import { Button, BUTTON_TYPES } from '../../../../components/Button'
import { BTN_TXT, LABELS } from '../../../../constants'
import { useCheckInsPopup } from '../../Providers/CheckInsPopupProvider'
import Popup from '../../../../components/Popup/Popup'
import { useCallback } from 'react'
import { Select } from '../../../../components/Select'
import { useFilters } from './hooks/useFilters'
import { useCheckInsListContext } from '../../Providers/CheckInsContextProvider'
import { ReactComponent as FilterApplied16 } from '../../../../assets/svg/FilterApplied16.svg'

export const CheckInsListFilterModalContainer = () => {
  const { filtersPopup } = useCheckInsPopup()

  const { state: listState } = useCheckInsListContext()

  const filtersApplied = Object.keys(listState.filters).find(
    (f) => listState.filters[f].length > 0
  )

  return (
    <Button
      upperCase
      icon={filtersApplied ? <FilterApplied16 /> : <Filter16 />}
      type={BUTTON_TYPES.DEFAULT}
      className="check-ins-container__list__header__filter"
      onClick={filtersPopup.actions.open}
    >
      {BTN_TXT.FILTERS}
    </Button>
  )
}

export const CheckInsListFilterModal = () => {
  const { filtersPopup } = useCheckInsPopup()

  const { actions: listActions } = useCheckInsListContext()

  const { state, actions } = filtersPopup
  const {
    hsOptions,
    hospitalsOptions,
    departmentsOptions,
    vendorOrgNameOptions,
    visitorNameOptions,
    modalFilters,
    setModalFilters
  } = useFilters()

  const onOk = useCallback(() => {
    listActions.applyFilters(modalFilters)
    actions.close()
  }, [listActions, actions, modalFilters])

  const onReset = useCallback(() => {
    listActions.resetFilters()
    actions.close()
  }, [listActions, actions, modalFilters])

  return (
    <Popup
      {...state}
      onCancel={actions.close}
      className="check-ins__filter-modal"
      width={440}
      footer={
        <>
          <Button type={BUTTON_TYPES.DEFAULT} onClick={onReset} upperCase>
            {BTN_TXT.RESET_FILTERS}
          </Button>
          <Button type={BUTTON_TYPES.PRIMARY} onClick={onOk} upperCase>
            {BTN_TXT.APPLY}
          </Button>
        </>
      }
    >
      <div className="flex gap-12 column">
        <Select.Multi
          options={hsOptions}
          propsSelect={{
            onChange: (values) =>
              setModalFilters({
                ...modalFilters,
                health_systems: values
              }),
            defaultValue: modalFilters.health_systems,
            allowClear: true
          }}
          propsItem={{
            name: 'health_system',
            label: LABELS.HEALTH_SYSTEMS
          }}
        />
        <Select.Multi
          options={hospitalsOptions}
          propsSelect={{
            onChange: (values) =>
              setModalFilters({
                ...modalFilters,
                hospitals: values
              }),
            defaultValue: modalFilters.hospitals,
            allowClear: true
          }}
          propsItem={{
            name: 'hospitals',
            label: LABELS.HOSPITALS
          }}
        />
        <Select.Multi
          options={departmentsOptions}
          propsSelect={{
            onChange: (values) =>
              setModalFilters({
                ...modalFilters,
                departments: values
              }),
            defaultValue: modalFilters.departments,
            allowClear: true
          }}
          propsItem={{
            name: 'departments',
            label: LABELS.DEPARTMENTS
          }}
        />
        <Select.Multi
          options={vendorOrgNameOptions}
          propsSelect={{
            onChange: (values) =>
              setModalFilters({
                ...modalFilters,
                vendors: values
              }),
            defaultValue: modalFilters.vendors,
            allowClear: true
          }}
          propsItem={{
            name: 'vendors',
            label: LABELS.VENDOR_ORG_NAME
          }}
        />
        <Select.Multi
          options={visitorNameOptions}
          propsSelect={{
            onChange: (values) =>
              setModalFilters({
                ...modalFilters,
                visitors: values
              }),
            defaultValue: modalFilters.visitors,
            allowClear: true
          }}
          propsItem={{
            name: 'visitors',
            label: LABELS.VISITOR_NAME
          }}
        />
      </div>
    </Popup>
  )
}
