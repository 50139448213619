import Typography from '../../../components/Typography/Typography'
import { Menu } from 'antd'
import { ActualSavingsMenuItems } from './constants'
import { useDashboardPopup } from '../Providers/DashboardPopupProvider'
import { TActualMenuProps } from './types'

const ActualSavingMenu = ({ setVisible }: TActualMenuProps) => {
  const { editSavingsGoalsPopup, historyGoalsPopup } = useDashboardPopup()
  const handleClick = (key: string) => {
    !!setVisible && setVisible(false)
    switch (key) {
      case 'edit':
        return editSavingsGoalsPopup.actions.open()
      case 'history':
        return historyGoalsPopup.actions.open()
      default:
        return
    }
  }
  return (
    <Menu className="menu-items-wrapper actual-savings-menu">
      {ActualSavingsMenuItems.map((i) => (
        <Menu.Item key={i.key} onClick={() => handleClick(i.key)}>
          <Typography.Body1>{i.label}</Typography.Body1>
        </Menu.Item>
      ))}
    </Menu>
  )
}

export default ActualSavingMenu
