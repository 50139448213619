import { API_DICTIONARY, HTTP_METHODS } from '../../constants'
import Fetcher from '../../services/Fetcher'
import { TCompleteProfileToken } from '../../features/Auth/types'

const fetcher = new Fetcher({})

export const getToken = async (data: TCompleteProfileToken) =>
  fetcher.request<unknown, TCompleteProfileToken & { email: string }>({
    url: `${API_DICTIONARY.AUTH}${API_DICTIONARY.SELF_SIGN_UP}${API_DICTIONARY.CONFIRM_EMAIL}`,
    method: HTTP_METHODS.POST,
    data
  })
