import { Form } from 'antd'
import { Select } from '../../../../components/Select'
import { LABELS, PLACEHOLDERS } from '../../../../constants'
import { setLoading } from '../../../../redux/store/common/slice'
import { ReactComponent as RemoveIcon } from 'assets/svg/Clos16.svg'
import { updatePRS } from '../../../../pages/ProductRequests/api'
import { usePRSDetailsContext } from '../Providers/PRSDetailsContextProvider'
import { useDispatch } from 'react-redux'
import { useParams } from 'react-router-dom'
import { useEffect, useState } from 'react'
import { TOption } from '../../../../components/Select/types'
import { fetchHealthSystemsList } from '../../../HealthSystems/api'
import { formatOptionNameValue } from '../../../../helper/optionsFormatters'
import { notification } from '../../../../components/Notification'

const HSForm = () => {
  const [hssForm] = Form.useForm()

  const { actions, state } = usePRSDetailsContext()

  const dispatch = useDispatch()

  const { id } = useParams()

  const [healthSystems, setHealthSystems] = useState<TOption[]>([])
  const [fields, setFields] = useState<any>([])
  const getHSOptions = async () => {
    await fetchHealthSystemsList(state.data.community as string).then(
      (resp) => {
        if (!resp.data?.results) {
          return
        }
        setHealthSystems(resp.data.results.map(formatOptionNameValue))
      }
    )
  }

  useEffect(() => {
    if (state.data) {
      const formattedData = {}
      for (const key in state.data) {
        if (key === 'health_systems') {
          formattedData[key] = state.data[key].map((i) => i?.uuid || i)
        }
      }
      const fields = Object.keys(formattedData).map((i) => ({
        name: i,
        value: formattedData[i]
      }))
      setFields(fields)
    }
  }, [state.data])

  useEffect(() => {
    if (!!state.data.community) {
      dispatch(setLoading(true))
      getHSOptions().finally(() => dispatch(setLoading(false)))
    }
  }, [state.data.community])
  const onSubmit = async (formData) => {
    dispatch(setLoading(true))
    const prsData = {
      health_systems: formData.health_systems
        ? typeof formData.health_systems[0] === 'object'
          ? formData.health_systems.map((i) => i.value)
          : formData.health_systems
        : undefined
    }
    await updatePRS(
      id as string,
      { ...prsData },
      'PRS has been successfully saved.'
    )
      .then(() => {
        actions.handleGetPRSData()
      })
      .catch((err) => {
        dispatch(setLoading(false))
        notification.error({ message: err?.data.detail })
      })
  }
  return (
    <Form
      id="hs-participants-form"
      form={hssForm}
      onFinish={onSubmit}
      onFieldsChange={() => actions.setSaveDisabled(false)}
      fields={fields}
      layout="vertical"
    >
      <Select.Multi
        options={healthSystems.map((i) =>
          i.value === state.communityRespHs ? { ...i, disabled: true } : i
        )}
        propsItem={{
          name: 'health_systems',
          label: LABELS.HEALTH_SYSTEMS,
          rules: [{ required: true }]
        }}
        propsSelect={{
          placeholder: PLACEHOLDERS.PLEASE_SELECT,
          allowClear: true,
          clearIcon: <RemoveIcon />
        }}
      />
    </Form>
  )
}

export default HSForm
