import Error from './banner.error'
import { Warning } from './banner.warning'
import { ErrorWithTitle } from './banner.errorWithTitle'
import { BannerProps } from './types'
import './styles.scss'

export const Banner = (props: BannerProps) => <Warning {...props} />

Banner.Warning = Warning
Banner.Error = Error
Banner.ErrorWithTitle = ErrorWithTitle
