import { Can } from '../Providers/PrsDetailsAbilityProvider'
import { ACTIONS, SUBJECTS } from '../../Abilities'
import { subject } from '@casl/ability'
import { Button, BUTTON_TYPES } from 'components/Button'
import { usePRSDetailsContext } from '../Providers/PRSDetailsContextProvider'
import { ApprovePrsOptions, BTN_TXT } from 'constants/txt'
import { PRS_STATUSES } from 'pages/ProductRequests/constants'
import { useEffect, useState } from 'react'
import './style.scss'
import Popup from 'components/Popup/Popup'
import { Typography, TYPOGRAPHY_WEIGHT } from 'components/Typography'
import { usePRSDetailsPopupContext } from '../Providers/PRSDetailsPopupProvider'
import { Checkbox } from 'components/Checkbox'
import { notification } from '../../../../components/Notification'

const ApproveRequestCS = () => {
  const { state, actions } = usePRSDetailsContext()
  const { data, clinicalRationaleResponse } = state
  const { handleChangePrsStatus } = actions

  const popup = usePRSDetailsPopupContext()
  const { approveRequestCsPopup } = popup
  const { visible } = approveRequestCsPopup.state
  const { close, open } = approveRequestCsPopup.actions

  const [selected, setSelected] = useState([] as any)

  const onOpen = () => {
    if (!clinicalRationaleResponse?.size) {
      notification.error({
        message: 'Please, fill in clinical information on ‘Facilities’ tab.'
      })
    } else {
      open()
    }
  }
  const onSend = () => {
    handleChangePrsStatus(PRS_STATUSES.IN_REVIEW)
  }

  useEffect(() => {
    setSelected([])
  }, [visible])

  return (
    <>
      <Can
        I={ACTIONS.APPROVE}
        a={subject(SUBJECTS.PRS_FROM_CLINICAL_SPONSOR, { ...data })}
      >
        <Button
          type={BUTTON_TYPES.PRIMARY}
          onClick={onOpen}
          className="send-prs-to-clinical-sponsor-btn"
          upperCase
        >
          {BTN_TXT.APPROVE_REQUEST}
        </Button>
        <Popup
          visible={visible}
          onCancel={close}
          title={`Approve PRS request?`}
          width={676}
          className="approve-prs-from-stakeholder"
          footer={
            <>
              <Button onClick={close} type={BUTTON_TYPES.DEFAULT} upperCase>
                {BTN_TXT.CANCEL}
              </Button>
              <Button
                type={BUTTON_TYPES.PRIMARY}
                disabled={selected.length !== 4}
                upperCase
                onClick={onSend}
              >
                {BTN_TXT.APPROVE_REQUEST}
              </Button>
            </>
          }
        >
          <>
            <Typography.Body1 weight={TYPOGRAPHY_WEIGHT.NORMAL}>
              Before approving the request, please, confirm that:
            </Typography.Body1>
            <div className="approval-options-prs">
              <Checkbox.Group
                className="create-group-chat_users-list"
                propsGroupCheckbox={{
                  value: selected,
                  onChange: setSelected,
                  options: ApprovePrsOptions.map((option) => ({
                    value: option.value,
                    label: <Typography.Body2>{option.label}</Typography.Body2>
                  }))
                }}
              />
            </div>
          </>
        </Popup>
      </Can>
    </>
  )
}

export default ApproveRequestCS
