import { Ability, AbilityBuilder, AbilityClass } from '@casl/ability'
import { IUser } from 'redux/store/user/types'
import {
  ACTIONS,
  SUBJECTS,
  Conditions,
  VendorAbility,
  PossibleAbilities,
  VendorAbilityBuilder
} from '../Abilities'

const VendorAbilityClass = Ability as AbilityClass<VendorAbility>

export const ability = new Ability<PossibleAbilities, Conditions>()

export default (_: IUser) => {
  const builder = new AbilityBuilder(VendorAbilityClass)

  defineAnonymousAbilities(builder)

  return new Ability(builder.rules) as VendorAbility
}

const defineAnonymousAbilities = (builder: VendorAbilityBuilder) => {
  builder.can(ACTIONS.VIEW, SUBJECTS.VENDOR)
}
