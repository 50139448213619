import { ICheckIn, ICheckInWithHeaderCondition } from './types'

export const formatCheckInsToShowHeader = (
  checkIns: ICheckIn[]
): ICheckInWithHeaderCondition[] => {
  return checkIns.map((checkIn, index) => {
    return {
      ...checkIn,
      showHeader:
        index === 0 ||
        (checkIns[index - 1] &&
          checkIns[index - 1].check_in_status !== checkIn.check_in_status)
    }
  })
}
