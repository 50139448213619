import { useCallback, useMemo } from 'react'

import { ITimeline, ITimelineValidation } from 'components/Timelines/types'
import {
  calculateDuration,
  disabledDateBeforeNow,
  mergeTimelineDateTime
} from 'components/Timelines/utils'
import { Moment } from 'moment'

export type UseTimelineProps<TKey extends Record<string, string>> = {
  hasChanges: boolean
  validation: ITimelineValidation | null
  prevTimeline: ITimeline<TKey> | null
  update: (value: Partial<ITimeline<TKey>>) => void
  reset: VoidFunction
  disabled?: boolean
  disabledDateForPrs?: Moment | undefined
} & ITimeline<TKey>

const useTimeline = <TKey extends Record<string, string>>(
  props: UseTimelineProps<TKey>
) => {
  const {
    title,
    date,
    timeLabel,
    validation,
    prevTimeline,
    hasChanges,
    disabled,
    update: _update,
    reset,
    disabledDateForPrs
  } = props

  const updateDate = useCallback(
    (value) => {
      _update({ date: value })
    },
    [_update]
  )

  const updateTime = useCallback(
    (value) => {
      _update({ timeLabel: value })
    },
    [_update]
  )

  const duration = useMemo(() => {
    const dateTime = mergeTimelineDateTime(date, timeLabel)

    if (prevTimeline === null) {
      return calculateDuration(dateTime)
    }

    const prevDateTime = mergeTimelineDateTime(
      prevTimeline?.date,
      prevTimeline?.timeLabel
    )

    return dateTime && prevDateTime
      ? calculateDuration(dateTime, prevDateTime)
      : null
  }, [date, prevTimeline, timeLabel])

  const disabledDate = useCallback(
    (current) =>
      disabledDateBeforeNow(
        disabledDateForPrs ? disabledDateForPrs : prevTimeline?.date,
        !!disabledDateForPrs
      )(current),
    [prevTimeline?.date, disabledDateForPrs]
  )

  const state = useMemo(
    () => ({
      title,
      date,
      timeLabel,
      validation,
      hasChanges,
      duration,
      disabledDate,
      disabled
    }),
    [
      date,
      disabled,
      disabledDate,
      duration,
      hasChanges,
      timeLabel,
      title,
      validation
    ]
  )

  const actions = useMemo(
    () => ({
      updateDate,
      updateTime,
      resetState: reset
    }),
    [reset, updateDate, updateTime]
  )

  return { state, actions }
}

export default useTimeline
