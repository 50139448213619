import { FC, PropsWithChildren } from 'react'

import { BTN_TXT } from 'constants/txt'
import { Container } from 'components/Container/Container'
import { BREADCRUMBS } from 'features/VendorDetails/constants'
import { useVendorDetailsContext } from 'features/VendorDetails/Providers/ContextProvider'

const ContractDetailsContainer: FC<PropsWithChildren> = ({ children }) => {
  const vendorDetailsContext = useVendorDetailsContext()

  const { details } = vendorDetailsContext.state

  const title = details?.uuid ? details.name : BTN_TXT.CREATE_VENDOR

  return (
    <Container title={title} breadcrumbs={BREADCRUMBS(title)}>
      {children}
    </Container>
  )
}

export default ContractDetailsContainer
