import { State } from '../../type'
import { moduleName } from './constants'
import { ICheckInsState } from './types'

const getState = (state: State): ICheckInsState => state[moduleName]

export const getCheckIns = (state: State) => {
  const stateSlice = getState(state)
  return stateSlice.checkIns
}

export const getSearch = (state: State) => {
  const stateSlice = getState(state)
  return stateSlice.search
}

export const getTotal = (state: State) => {
  const stateSlice = getState(state)
  return stateSlice.total
}

export const getIsLoading = (state: State) => {
  const stateSlice = getState(state)
  return stateSlice.isLoading
}
