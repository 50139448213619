import Fetcher from 'services/Fetcher'
import { API_DICTIONARY, HTTP_METHODS } from 'constants/api'
import {
  TDashboardHistoryRequest,
  TDashboardQuerySetActualSavingsParams,
  TDashboardRequest,
  TDashboardSetActualSavingsRequest,
  TOngoingContractsResponce,
  TSavingsResponce
} from './types'
import { ResponseWithPagination } from '../../constants'
import { TActualSavingsModalList } from './ActualSavingsModal/types'

const fetcher = new Fetcher({})

export const getSavings: TDashboardRequest = (data) =>
  fetcher.request<unknown, TSavingsResponce>({
    url: `${API_DICTIONARY.CONTRACTS}${API_DICTIONARY.DASHBOARD}${API_DICTIONARY.SAVINGS_DASHBOARD}`,
    method: HTTP_METHODS.GET,
    ...data
  })
export const getHistorySavings: TDashboardHistoryRequest = (data) =>
  fetcher.request<unknown, ResponseWithPagination<TSavingsResponce>>({
    url: `${API_DICTIONARY.CONTRACTS}${API_DICTIONARY.DASHBOARD}${API_DICTIONARY.SAVINGS_DASHBOARD}${API_DICTIONARY.SAVINGS_GOAL}${API_DICTIONARY.HISTORY}`,
    method: HTTP_METHODS.GET,
    ...data
  })

export const getOngoing: TDashboardRequest = (data) =>
  fetcher.request<unknown, TOngoingContractsResponce>({
    url: `${API_DICTIONARY.CONTRACTS}${API_DICTIONARY.DASHBOARD}${API_DICTIONARY.ONGOING_DASHBOARD}`,
    method: HTTP_METHODS.GET,
    ...data
  })

export const getExpiringRFPSteps: TDashboardRequest = async (data) =>
  fetcher.request<unknown, any>({
    url: `${API_DICTIONARY.CONTRACTS}${API_DICTIONARY.DASHBOARD}${API_DICTIONARY.EXPIRING_RFP_STEPS}`,
    method: HTTP_METHODS.GET,
    ...data
  })

export const getExpiringContractsSteps: TDashboardRequest = async (data) =>
  fetcher.request<unknown, any>({
    url: `${API_DICTIONARY.CONTRACTS}${API_DICTIONARY.DASHBOARD}${API_DICTIONARY.EXPIRING_CONTRACT_STEPS}`,
    method: HTTP_METHODS.GET,
    ...data
  })

export const getExpiringContractList: TDashboardRequest = async (data) =>
  fetcher.request<unknown, any>({
    url: `${API_DICTIONARY.CONTRACTS}${API_DICTIONARY.DASHBOARD}${API_DICTIONARY.EXPIRING_CONTRACT}`,
    method: HTTP_METHODS.GET,
    ...data
  })

export const getActualSavingsList: TDashboardRequest = async (data) =>
  fetcher.request<unknown, ResponseWithPagination<TActualSavingsModalList>>({
    url: `${API_DICTIONARY.CONTRACTS}${API_DICTIONARY.DASHBOARD}${API_DICTIONARY.SAVINGS_DASHBOARD}${API_DICTIONARY.SAVINGS_GOAL}`,
    method: HTTP_METHODS.GET,
    ...data
  })
export const setActualSavingsList: TDashboardSetActualSavingsRequest = async (
  data
) =>
  fetcher.request<
    TDashboardQuerySetActualSavingsParams,
    ResponseWithPagination<TActualSavingsModalList>
  >({
    url: `${API_DICTIONARY.CONTRACTS}${API_DICTIONARY.DASHBOARD}${API_DICTIONARY.SAVINGS_DASHBOARD}${API_DICTIONARY.SAVINGS_GOAL}`,
    method: HTTP_METHODS.PUT,
    data
  })
