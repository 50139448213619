import cn from 'classnames'
import { Typography, TYPOGRAPHY_WEIGHT } from 'components/Typography'
import {
  RFP_ACCEPTANCE_STATUSES,
  RFP_ACCEPTANCE_STATUSES_NAMES
} from 'features/RFP/constants'
import { TVendorAcceptanceStatusProps } from './status.vendorAcceptance.d'
import './status.vendorAcceptance.scss'

export const VendorAcceptance = ({
  acceptanceStatus
}: TVendorAcceptanceStatusProps) => (
  <Typography.Body1
    className={cn('acceptance-status', {
      'acceptance-status--accepted':
        acceptanceStatus === RFP_ACCEPTANCE_STATUSES.ACCEPTED,
      'acceptance-status--declined':
        acceptanceStatus === RFP_ACCEPTANCE_STATUSES.DECLINED
    })}
    weight={TYPOGRAPHY_WEIGHT.SEMI_BOLD}
  >
    {RFP_ACCEPTANCE_STATUSES_NAMES[acceptanceStatus]}
  </Typography.Body1>
)
