export const UploadDocumentsOptions = [
  {
    label: 'Share only with Community ',
    value: '1'
  },
  {
    label: 'Share only with Cognus Commons',
    value: '2'
  }
] as const
