import moment from 'moment/moment'

import { ITimeline, TTimelines } from 'components/Timelines/types'
import {
  mergeTimelineDateTime,
  roundHoursUp,
  timelineLabelFormat
} from 'components/Timelines/utils'
import {
  TRFPTimelineRequestData,
  TRFPTimelineResponseData
} from 'features/RFP/RFPDetails/types'
import { RFPTimelinesKeyTitleDictionary } from 'features/RFP/RFPTimelines/constants'
import { RFP_STATUSES } from 'features/RFP/constants'

export const timelinesRequestDataConverter = (
  data: ITimeline<typeof RFP_STATUSES>[]
): TRFPTimelineRequestData[] =>
  Object.values(data).map((v) => ({
    status: v.key,
    expire_at: mergeTimelineDateTime(v.date, v.timeLabel).format()
  }))

export const timelinesResponseDataConverter = (
  data: TRFPTimelineResponseData[]
): TTimelines<typeof RFP_STATUSES> =>
  data?.reduce((timelineForm, responseTimeline, index) => {
    const { status, expire_at } = responseTimeline

    const timelineDate = roundHoursUp(moment(expire_at))

    return {
      ...timelineForm,
      [status]: {
        order: index,
        key: status,
        title: RFPTimelinesKeyTitleDictionary[status],
        date: timelineDate,
        timeLabel: timelineLabelFormat(timelineDate),
        hasChanges: false
      }
    }
  }, {} as TTimelines<typeof RFP_STATUSES>)
