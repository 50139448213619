import Fetcher from 'services/Fetcher'
import { API_DICTIONARY, HTTP_METHODS } from 'constants/api'
import { TBulkRequestPayloadData, TBulkRequestResponse } from './types'

const fetcher = new Fetcher({})

export const bulkContractsCreate = async (data: TBulkRequestPayloadData) =>
  fetcher.request<TBulkRequestPayloadData, TBulkRequestResponse>({
    url: `${API_DICTIONARY.CONTRACTS}${API_DICTIONARY.BULK_CREATION}`,
    method: HTTP_METHODS.POST,
    data
  })
