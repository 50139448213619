import { ReactComponent as ComplianceDashboard } from 'assets/svg/ComplianceDashboard.svg'
import { ReactComponent as ProjectsDashboard } from 'assets/svg/ProjectsDashboard.svg'
import { ReactComponent as FinancialsDashboard } from 'assets/svg/FinancialsDashboard.svg'
import { ReactComponent as PODashboard } from 'assets/svg/PODashboard.svg'
import { ReactComponent as ProductRequestsDashboard } from 'assets/svg/ProductRequestsDashboard.svg'
import { ReactComponent as Chats } from 'assets/svg/Chats.svg'
import { EMPTY_STATES_TYPES } from 'constants/common'
import './styles.scss'

export const EmptyState = ({ type }: { type?: string }) => {
  switch (type) {
    case EMPTY_STATES_TYPES.COMPLIANCE:
      return <ComplianceDashboard className="empty-state_svg" />
    case EMPTY_STATES_TYPES.PROJECTS:
      return <ProjectsDashboard className="empty-state_svg" />
    case EMPTY_STATES_TYPES.FINANCIALS:
      return <FinancialsDashboard className="empty-state_svg" />
    case EMPTY_STATES_TYPES.PURCHASE_ORDERS:
      return <PODashboard className="empty-state_svg" />
    case EMPTY_STATES_TYPES.CHATS:
      return <Chats className="empty-state_svg" />
    case EMPTY_STATES_TYPES.PRODUCT_REQUESTS:
      return <ProductRequestsDashboard className="empty-state_svg" />
    default:
      return (
        <div className="empty-state">
          <div>Coming soon</div>
        </div>
      )
  }
}
