import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Typography, TYPOGRAPHY_WEIGHT } from 'components/Typography'
import { Table } from 'components/Table'
import { getUser } from 'redux/store/user/getters'
import { setLoading } from 'redux/store/common/slice'
import { columns } from './constants'
import { fullFormatPhoneNumber } from 'helper/common'
import { DEFAULT_PAGE } from 'components/Table/constants'
import {
  TTableUser,
  TUsersFilters,
  TUsersFiltersFunc,
  TUsersParams,
  TUsersTableRequestData
} from '../UsersTable/types'
import { getHospitalUsers } from '../UsersTable/api'
import { THospitalUser } from '../HospitalsTable/types'
import cn from 'classnames'
import { ROLES } from '../../Permission'
import '../styles.scss'

export const CollapseUsersTable = ({ hsId, hospitalId }: THospitalUser) => {
  const [tableData, setTableData] = useState<TTableUser[]>([])
  const [filters, setFilters] = useState<TUsersFilters>()
  const [sortParams, setSortParams] = useState<TUsersTableRequestData>()
  const [totalNumber, setTotalNumber] = useState(0)
  const [pageInfo, setPageInfo] = useState({ ...DEFAULT_PAGE })
  const user = useSelector(getUser)
  const dispatch = useDispatch()

  useEffect(() => {
    if (user.uuid && hsId) {
      getTableData({ ...sortParams, filters })
    }
  }, [user.uuid, hsId])
  const getTableData = ({
    sortField,
    sortOrder,
    filters,
    page
  }: TUsersTableRequestData) => {
    dispatch(setLoading(true))
    const params: TUsersParams = { ...filters }
    if (sortField) {
      if (sortOrder) {
        params.ordering = `${sortOrder === 'descend' ? '-' : ''}${sortField}`
      }
      setSortParams({
        sortField,
        sortOrder
      })
    }
    const dataPage = page ? page : pageInfo
    params.limit = dataPage.pageSize
    params.offset = (dataPage.pageNumber - 1) * dataPage.pageSize
    getHospitalUsers(user.uuid, hsId, hospitalId, params)
      .then((resp) => {
        if (!resp.data?.results) {
          return
        }
        setTableData(
          resp.data.results.map((i) => ({
            ...i,
            phone_number: fullFormatPhoneNumber(i.phone_number),
            key: i.uuid
          }))
        )
        setTotalNumber(resp.data.count)
      })
      .finally(() => dispatch(setLoading(false)))
  }
  const handleTableChange = (pagination, _filters, sorter) => {
    const page = {
      pageNumber: pagination.current,
      pageSize: pagination.pageSize
    }
    getTableData({
      sortField: sorter.field,
      sortOrder: sorter.order,
      filters,
      page
    })
    setPageInfo(page)
  }
  const handleChangePageSize = (pageSize) => {
    const page = {
      pageNumber: 1,
      pageSize
    }
    getTableData({
      ...sortParams,
      filters,
      page
    })
    setPageInfo(page)
  }

  const applyFilters: TUsersFiltersFunc = (field, appliedFilters) => {
    const newFilters = { ...filters, [field]: appliedFilters }
    setFilters(newFilters)
    getTableData({ ...sortParams, filters: newFilters })
  }
  return (
    <>
      <div className="hs-details__tables-menu">
        <Typography.Headline6
          className={cn('hs-details__table-title', {
            'hs-details__title-ha':
              user.role === ROLES.HOSPITAL_ADMIN ||
              user.role === ROLES.INFLUENCER ||
              user.role === ROLES.STAKEHOLDER
          })}
          weight={TYPOGRAPHY_WEIGHT.BOLD}
        >
          Users
        </Typography.Headline6>
      </div>
      <div className="table-wrapper hs-details__users-table">
        <Table<TTableUser>
          dataSource={tableData}
          onChange={handleTableChange}
          columns={columns({
            applyFilters,
            filters
          })}
          pageSize={pageInfo.pageSize}
          pagination={{
            pageSize: pageInfo.pageSize,
            current: pageInfo.pageNumber,
            total: totalNumber
          }}
          onChangePage={handleChangePageSize}
        />
      </div>
    </>
  )
}
