import { FieldWithLabel } from '../../../components/FieldWithLabel/FieldWithLabel'
import { LABELS } from '../../../constants'
import { Typography } from '../../../components/Typography'
import { defaultDateFormat } from '../../../helper/dates'
import { useCPRDetailsContext } from '../Providers/CPRDetailsContextProvider'
import './style.scss'
import { Button, BUTTON_TYPES } from '../../../components/Button'
import useRouter from '../../../hooks/useRouter'
import { useCallback } from 'react'
import { routes } from '../../../router'

const AdditionalInfo = () => {
  const contractDetailsContext = useCPRDetailsContext()
  const { push } = useRouter()

  const { details, canSeeRfpLink } = contractDetailsContext.state

  const handleClickRfpLink = useCallback(() => {
    push(`${routes.bidding}${routes.rfp}/${details.rfp}`)
  }, [details.rfp, push])
  return (
    <div className="cpr-details-preview">
      <div className="cpr-details-preview__container">
        <div className="contract-details-general__details">
          <div className="cpr-details-preview__grid">
            {!!details.rfp && canSeeRfpLink && (
              <div className="cpr-details-preview__row">
                <FieldWithLabel
                  title="RFP"
                  className="contract-details__preview-item"
                >
                  <Button type={BUTTON_TYPES.LINK} onClick={handleClickRfpLink}>
                    {details.rfp_name}
                  </Button>
                </FieldWithLabel>
              </div>
            )}
            <div className="cpr-details-preview__row">
              <FieldWithLabel title={LABELS.PORTFOLIO}>
                <Typography.Body1>{details.portfolio}</Typography.Body1>
              </FieldWithLabel>
            </div>
            <div className="cpr-details-preview__row">
              <FieldWithLabel title={LABELS.CURRENT_VENDOR}>
                <Typography.Body1>
                  {details?.vendor_name ? details.vendor_name : '-'}
                </Typography.Body1>
              </FieldWithLabel>
            </div>
            <div className="cpr-details-preview__row">
              <FieldWithLabel title={LABELS.EXPIRATION_DATE}>
                <Typography.Body1>
                  {details.expiration_date
                    ? defaultDateFormat(details.expiration_date)
                    : '-'}
                </Typography.Body1>
              </FieldWithLabel>
            </div>
            <div className="cpr-details-preview__row">
              <FieldWithLabel title={LABELS.REBATES}>
                <Typography.Body1>
                  {details.rebates ? 'Yes' : 'No'}
                </Typography.Body1>
              </FieldWithLabel>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default AdditionalInfo
