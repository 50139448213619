import { createContext, FC, PropsWithChildren, useMemo } from 'react'
import { useSelector } from 'react-redux'
import { createContextualCan, useAbility } from '@casl/react'

import { getUser } from 'redux/store/user/getters'
import { ability, defineAbility, PRSItemAbility } from 'features/PRS/Abilities'

export const PrsDetailsAbilityContext = createContext<PRSItemAbility>(ability)

const PrsDetailsAbilityProvider: FC<PropsWithChildren> = ({ children }) => {
  const user = useSelector(getUser)

  const ability = useMemo(() => defineAbility(user), [user])

  return (
    <PrsDetailsAbilityContext.Provider value={ability}>
      {children}
    </PrsDetailsAbilityContext.Provider>
  )
}

export const usePrsDetailsAbility = () =>
  useAbility<PRSItemAbility>(PrsDetailsAbilityContext)

export const Can = createContextualCan<any>(PrsDetailsAbilityContext.Consumer)

export default PrsDetailsAbilityProvider
