import { useMemo } from 'react'
import { Checkbox, Form } from 'antd'
import { LoadingOutlined } from '@ant-design/icons'

import './styles.scss'

import { ReactComponent as Search24 } from 'assets/svg/Search24.svg'

import { Input } from 'components/Input'
import { AddMembersForm } from 'features/ChatDetails/Members/AddMembers/types'
import useAddMembers, {
  UseAddMembersProps
} from 'features/ChatDetails/Members/AddMembers/hooks/useAddMembers'
import Member from 'features/ChatDetails/Members/Member/Member'
import { safeString } from 'helper/common'
import { ReactComponent as Close16 } from '../../../../assets/svg/Clos16.svg'

const AddMembers = (props: UseAddMembersProps) => {
  const {
    form,
    search,
    availableMembers,
    isLoading,
    updateMembersRequestAsync,
    setSearch,
    updateShadowPickerListValues
  } = useAddMembers(props)

  const handleChangeSearch = (event) => {
    setSearch(event.target.value)
  }

  const Members = useMemo(
    () =>
      availableMembers.map((member) => (
        <Checkbox
          value={member.uuid}
          onChange={(control) =>
            updateShadowPickerListValues(control.target.value)
          }
          key={member.uuid}
          className="add-chat-members__member"
        >
          <Member user={member} />
        </Checkbox>
      )),
    [availableMembers]
  )

  return (
    <Form<AddMembersForm>
      id="add-chat-members-form"
      form={form}
      onFinish={updateMembersRequestAsync}
    >
      <Input
        className="mt-16 allow-clear"
        propsInput={{
          allowClear: { clearIcon: <Close16 /> },
          placeholder: 'Search users',
          prefix: <Search24 />,
          value: safeString(search),
          onChange: handleChangeSearch
        }}
      />
      {isLoading ? (
        <div className="chat-add-member__container">
          <LoadingOutlined className="chat-add-members__spinner" />
        </div>
      ) : (
        <Form.Item name="members" className="mt-24">
          <Checkbox.Group className="chat-add-members-group">
            {Members}
          </Checkbox.Group>
        </Form.Item>
      )}
    </Form>
  )
}

export default AddMembers
