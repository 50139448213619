import Fetcher from 'services/Fetcher'
import { API_DICTIONARY, HTTP_METHODS } from 'constants/api'
import { VALIDATION_MESSAGES } from '../../constants'

const fetcher = new Fetcher({})

export const updateUserProfile = async (data: any) =>
  fetcher.request<any, any>({
    url: `${API_DICTIONARY.USERS}${API_DICTIONARY.CURRENT}`,
    method: HTTP_METHODS.PATCH,
    successMessage: `Profile ${VALIDATION_MESSAGES.SM0009} saved.`,
    data
  })
