import moment from 'moment'

import {
  timelineDateFormat,
  timelineTimeFormat
} from 'components/Timelines/constants'
import { requestDateFormat } from '../utils/moment'
import { DurationInputArg1 } from 'moment/moment'

export const durationAsString = (start, end) => {
  const duration = moment.duration(moment(end).diff(moment(start)))
  const years = Math.floor(duration.asYears())
  const days = Math.floor(duration.asDays())
  const hours = duration.hours()
  const timeArray: string[] = []
  years > 0 && timeArray.push(`${years}y`)
  days > 0 && timeArray.push(`${days}d`)
  hours > 0 && timeArray.push(`${hours}h`)
  return !!timeArray.length ? timeArray.join(' ') : '0d 0h'
}

export const defaultDateFormat = (date: string | null) =>
  date ? moment(date).format(timelineDateFormat) : ''

export const defaultTimeFormat = (date: string | null) =>
  date ? moment(date).format(timelineTimeFormat) : ''

export const checkIsToday = (date: string, format?: string) => {
  return moment().diff(moment(date, format), 'days') === 0
}

export const checkIsBefore = (date: string | null) => {
  return date && moment(date, requestDateFormat).isBefore(moment())
}

export const getLastMessageSentDate = (date: string) => {
  const isToday = checkIsToday(defaultDateFormat(date), timelineDateFormat)
  return isToday ? defaultTimeFormat(date) : defaultDateFormat(date)
}

export const getTimeDifferenceInHoursAndMinutes = (
  startDate: string,
  endDate: string,
  offset: string
) => {
  //get time difference within one day (diff between start and end could be more than 1 day )
  const start = moment(startDate).utcOffset(offset)
  const end = moment(endDate).utcOffset(offset).isSame(start, 'day')
    ? moment(endDate).utcOffset(offset)
    : start.clone().startOf('day').add(1, 'day')

  const duration = moment.duration(end.diff(start))

  const hours = Math.floor(duration.asHours())
  const minutes = duration.minutes()

  let formattedDifference = ''
  if (hours !== 0) {
    formattedDifference += `${hours}h `
  }
  if (minutes !== 0) {
    formattedDifference += `${minutes}min`
  }
  if (minutes === 0 && hours === 0) {
    formattedDifference += `0min`
  }

  return formattedDifference.trim()
}

export const getDurationHm = (value: DurationInputArg1) => {
  const duration = moment.duration(value)
  const hours = Math.floor(duration.asHours())
  const minutes = duration.minutes()
  return [hours ? `${hours} h` : '', minutes ? `${minutes} min` : '']
    .filter(Boolean)
    .join(' ')
}
