import { ReactElement, useState } from 'react'
import { Forms } from 'components/Forms'
import SignPopup from 'features/SignPopup/SignPopup'

const SignUp = (): ReactElement => {
  const [email, setEmail] = useState('')
  const emailChange = (e) => setEmail(e.target.value)
  return (
    <SignPopup email={email}>
      <Forms.SignUp emailChange={emailChange} />
    </SignPopup>
  )
}

export default SignUp
