import CalendarEventsContextProvider from './CalendarEventsProvider'
import { CalendarContainer } from './CalendarContainer'
import CalendarPopupProvider from './Provider/CalendarPopupProvider'

export const Calendar = () => {
  return (
    <CalendarEventsContextProvider>
      <CalendarPopupProvider>
        <CalendarContainer />
      </CalendarPopupProvider>
    </CalendarEventsContextProvider>
  )
}
