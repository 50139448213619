import { Collapse as CollapseAntd, Form } from 'antd'
const { Panel } = CollapseAntd
import { Collapse } from 'components/Collapse'
import { useEffect, useRef, useState } from 'react'
import { Table } from '../../components/Table'
import {
  TOpportunityAnalysisListFilters,
  TOpportunityAnalysisListFiltersFunc,
  TOpportunityAnalysisListParams,
  TOpportunityAnalysisRequestData
} from '../CognusRepository/OpportunityAnalysis/types'
import { isTableHasParams } from '../../helper/common'
import { columns } from './constants'
import { TRequestFAHsList, TSavingResponse } from './types'
import { DEFAULT_PAGE } from '../../components/Table/constants'
import axios, { CancelTokenSource } from 'axios'
import { useDispatch, useSelector } from 'react-redux'
import { useDebounce } from '../../hooks/hooks'
import { setLoading } from '../../redux/store/common/slice'
import {
  getHealthSystemDetails,
  getSavingsDetails,
  setSavingsDataRequest
} from '../../pages/RequestForAnalysisDetaisPage/api'
import useRouter from '../../hooks/useRouter'
import { TotalSavings } from '../CognusRepository/OpportunityAnalysis/TotalSavings'
import { getUser } from '../../redux/store/user/getters'
import { ROLES } from '../Permission'
import { SupportInformation } from '../CognusRepository/RequestForAnalysis/SupportInformation/SupportInformation'

export const RequestForAnalysisDetails = () => {
  const [form] = Form.useForm()
  const user = useSelector(getUser)
  const { query } = useRouter()
  const [activeKey, setActiveKey] = useState('')
  const [firstLoad, setFirstLoad] = useState(true)
  const [tableData, setTableData] = useState<TRequestFAHsList[]>([])
  const [sortParams, setSortParams] =
    useState<TOpportunityAnalysisRequestData>()
  const [filters, setFilters] = useState<TOpportunityAnalysisListFilters>({})
  const [totalNumber, setTotalNumber] = useState(0)
  const [pageInfo, setPageInfo] = useState({ ...DEFAULT_PAGE })
  const timeoutRef = useRef<ReturnType<typeof setTimeout>>()
  const tableDataTokenRef = useRef<CancelTokenSource>()
  const [editingKey, setEditingKey] = useState('')
  const edit = (
    record: Partial<TRequestFAHsList> & {
      uuid: string
      saving_estimates: number
      current_compliance: number
    }
  ) => {
    form.setFieldsValue({
      ...record
    })
    setEditingKey(record.uuid)
  }
  const cancelEdit = () => {
    setEditingKey('')
  }

  const isEditing = (record: TRequestFAHsList) => record?.uuid === editingKey

  const dispatch = useDispatch()
  const debouncedSearch = useDebounce('', 500)
  const [savings, setSavings] = useState<TSavingResponse>({
    total_savings: 0,
    saving_goals: 0,
    opportunity_analysis_savings: 0
  })

  useEffect(() => {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current)
    }
    timeoutRef.current = setTimeout(
      () => {
        getTableData({ ...sortParams, ...filters, page: { ...DEFAULT_PAGE } })
        setPageInfo({ ...DEFAULT_PAGE })
      },
      firstLoad ? 0 : 500
    )
  }, [debouncedSearch])

  useEffect(() => {
    return () => {
      if (!!tableDataTokenRef.current) {
        tableDataTokenRef.current.cancel()
      }
    }
  }, [])

  const getTableData = ({
    sortField,
    sortOrder,
    filters,
    page
  }: TOpportunityAnalysisRequestData) => {
    dispatch(setLoading(true))
    if (!!tableDataTokenRef.current) {
      tableDataTokenRef.current.cancel()
    }
    const params: TOpportunityAnalysisListParams = { ...filters }

    if (sortField) {
      if (sortOrder) {
        params.ordering = `${sortOrder === 'descend' ? '-' : ''}${sortField}`
      }
      setSortParams({
        sortField,
        sortOrder
      })
    }
    const dataPage = page ? page : pageInfo
    params.limit = dataPage.pageSize
    params.offset = (dataPage.pageNumber - 1) * dataPage.pageSize
    tableDataTokenRef.current = axios.CancelToken.source()
    getHealthSystemDetails(query.id, {
      cancelToken: tableDataTokenRef.current?.token,
      params
    })
      .then((resp) => {
        if (!resp.data?.results) {
          return
        }
        setTableData(
          resp.data.results.map((i) => ({
            ...i,
            key: i.uuid
          }))
        )
        setTotalNumber(resp.data.count)
        if (firstLoad) {
          setFirstLoad(false)
        }
      })
      .finally(() => dispatch(setLoading(false)))
    getSavingsDetails(query.id, {
      cancelToken: tableDataTokenRef.current?.token,
      params
    }).then((res) => {
      if (res?.data) setSavings(res?.data)
    })
  }

  const onSubmit = () => {
    dispatch(setLoading(true))
    setSavingsDataRequest(query.id, editingKey, {
      current_compliance: form.getFieldValue('current_compliance'),
      saving_estimates: form.getFieldValue('saving_estimates')
    })
      .then(() => {
        setEditingKey('')
        getTableData({ ...sortParams, ...filters, page: { ...DEFAULT_PAGE } })
      })
      .finally(() => {
        dispatch(setLoading(false))
      })
  }

  const handleTableChange = (pagination, _filters, sorter) => {
    const page = {
      pageNumber: pagination.current,
      pageSize: pagination.pageSize
    }
    getTableData({
      sortField: sorter.field,
      sortOrder: sorter.order,
      filters,
      page
    })
    setPageInfo(page)
  }
  const handleChangePageSize = (pageSize) => {
    const page = {
      pageNumber: 1,
      pageSize
    }
    getTableData({
      ...sortParams,
      filters,
      page
    })
    setPageInfo(page)
  }

  const applyFilters: TOpportunityAnalysisListFiltersFunc = (
    field,
    appliedFilters
  ) => {
    const newFilters = { ...filters, [field]: appliedFilters }
    const newPageInfo = { ...pageInfo, pageNumber: 1 }
    setFilters(newFilters)
    setPageInfo(newPageInfo)
    getTableData({ ...sortParams, filters: newFilters, page: newPageInfo })
  }
  const onChange = (uuid) => setActiveKey(uuid)

  const isTotalAndGoalsHidden =
    user.role.includes('community') ||
    user.role === ROLES.COMMONS_CONTRACT_STEWARD ||
    user.role === ROLES.COMMONS_ANALYST
  return (
    <Collapse activeKey={activeKey} onChange={onChange} className="mt-16">
      <Panel key={'support_information'} header={'Support information'}>
        <SupportInformation />
      </Panel>
      <Panel key={'evaluating'} header={'Evaluating'}>
        <div className="table-wrapper community-users available-for-evaluation">
          {!firstLoad && (
            <>
              <Form form={form} component={false}>
                <Table<TRequestFAHsList>
                  dataSource={tableData}
                  columns={columns({
                    applyFilters,
                    filters,
                    isEditing: isEditing,
                    edit,
                    cancelEdit,
                    onSubmit,
                    user
                  })}
                  className="contracts-table"
                  onChange={handleTableChange}
                  hasSearchOrFilters={isTableHasParams<TOpportunityAnalysisListFilters>(
                    debouncedSearch,
                    filters
                  )}
                  onChangePage={handleChangePageSize}
                  pageSize={pageInfo.pageSize}
                  pagination={{
                    pageSize: pageInfo.pageSize,
                    current: pageInfo.pageNumber,
                    total: totalNumber
                  }}
                />
              </Form>
              <TotalSavings
                savings={savings}
                isTotalHidden={isTotalAndGoalsHidden}
                isGoalsHidden={isTotalAndGoalsHidden}
              />
            </>
          )}
        </div>
      </Panel>
    </Collapse>
  )
}
