import CommonResponse from './CommonResponse'
import { usePRSVotingContext } from '../../Providers/PRSVotingContext'
import IndividualResponse from './IndividualResponse'
import Typography from '../../../../../components/Typography/Typography'

const Response = () => {
  const { state } = usePRSVotingContext()
  const { votingData, isIndividualResponse, votingResponse } = state

  return (
    <div className="response-container">
      {!!votingResponse?.size ? (
        votingData?.questions?.map((question) => {
          return !isIndividualResponse ? (
            <CommonResponse question={question} />
          ) : (
            <IndividualResponse question={question} />
          )
        })
      ) : (
        <Typography.Headline6>No responses yet.</Typography.Headline6>
      )}
      <div />
    </div>
  )
}

export default Response
