import cn from 'classnames'
import { Button, BUTTON_TYPES } from 'components/Button'
import { ReactComponent as Attachment16 } from 'assets/svg/Attachment16.svg'
import { ReactComponent as Delete16 } from 'assets/svg/Delete16.svg'
import { TAttachmentProps } from './types'
import './styles.scss'
import { useMemo } from 'react'
import { Typography } from 'components/Typography'
import { defaultDateFormat, defaultTimeFormat } from 'helper/dates'
import { Avatar } from 'components/Avatar/Avatar'
import { Tooltip } from 'components/Tooltip/Tooltip'

export const Default = ({
  filename,
  onDelete,
  className,
  disableActions,
  link,
  createdAt,
  user,
  showDetails,
  icon
}: TAttachmentProps) => {
  const CreatedAt = useMemo(
    () =>
      createdAt && (
        <div className="attachment__created_at">
          <Typography.Caption>
            {defaultDateFormat(createdAt)}
          </Typography.Caption>
          <Typography.Caption>
            {defaultTimeFormat(createdAt)}
          </Typography.Caption>
        </div>
      ),
    [createdAt]
  )

  const User = useMemo(() => {
    if (!user) {
      return null
    }
    const capitalCharName = `${user.first_name?.charAt(0) || ''}${
      user.last_name?.charAt(0) || ''
    }`.toUpperCase()

    const name = user.first_name + ' ' + user.last_name

    return (
      <div className="attachment__user">
        <Tooltip title={name}>
          <Avatar
            name={capitalCharName}
            alt="User avatar"
            src={user.avatar?.file_preview}
          />
        </Tooltip>
      </div>
    )
  }, [user])

  return (
    <div className={cn('attachment', className)}>
      <div className="attachment__content">
        <Button
          type={BUTTON_TYPES.LINK}
          icon={icon ?? <Attachment16 className="attachment__icon" />}
          onClick={() => window.open(link)}
        >
          {filename}
        </Button>
        {showDetails && CreatedAt}
      </div>
      {showDetails && User}
      {onDelete && !disableActions && (
        <div className="attachment__actions">
          <Button.Icon
            type={BUTTON_TYPES.LINK}
            icon={<Delete16 />}
            onClick={onDelete}
            small
          />
        </div>
      )}
    </div>
  )
}
