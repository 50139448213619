import { CommonsUsers } from './CommonsUsers/CommonsUsers'
import { CommonsDetails } from './CommonsDetails/CommonsDetails'

export const Commons = () => {
  return (
    <>
      <CommonsDetails />
      <CommonsUsers />
    </>
  )
}
