import { State } from '../../type'
import { moduleName } from './constants'
import { IChatsState } from './types'

const getState = (state: State): IChatsState => state[moduleName]

export const getChats = (state: State) => {
  const stateSlice = getState(state)
  return stateSlice.chats
}

export const getSearch = (state: State) => {
  const stateSlice = getState(state)
  return stateSlice.search
}

export const getType = (state: State) => {
  const stateSlice = getState(state)
  return stateSlice.type
}

export const getTotal = (state: State) => {
  const stateSlice = getState(state)
  return stateSlice.total
}

export const getChat = (uuid: string) => (state: State) => {
  const stateSlice = getState(state)
  return stateSlice.chats.find((p) => p.uuid === uuid)
}

export const getError = (state: State) => {
  const stateSlice = getState(state)
  return stateSlice.error
}

export const getIsLoaded = (state: State): boolean => {
  const stateSlice = getState(state)
  return stateSlice.isLoaded
}

export const getIsLoading = (state: State): boolean => {
  const stateSlice = getState(state)
  return stateSlice.isLoading
}
