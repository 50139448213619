import { FC, PropsWithChildren, useEffect, useMemo } from 'react'

import useRouter from 'hooks/useRouter'
import {
  CONTARCT_PIPELINES_STATUS_TYPES_TO_CLASSNAME,
  CONTRACT_PIPELINES_STATUS,
  CONTRACT_PIPELINES_STATUS_NAME,
  Status
} from 'components/Status'
import { Container } from 'components/Container/Container'
import { Menu } from 'components/Menu/Menu'
import { CONTRACT_DETAILS_BREADCRUMBS } from 'features/ContractDetails/constants'
import {
  ACTIONS,
  contractSubject,
  SUBJECTS
} from 'features/ContractsPipelineDetails/Abilities'
import { Can } from 'features/ContractsPipelineDetails/Providers/ContractPipelineDetailsAbilityProvider'
import { safeString } from '../../../helper/common'
import { useParams } from 'react-router-dom'
import { useCPRDetailsContext } from '../Providers/CPRDetailsContextProvider'
import { contractPageRoute } from '../../../pages/Contracts/constants'
import { ContractPipelineDetailsActions } from '../ContractPipelineDetailsActions/ContractPipelineDetailsActions'

const ContractPipelineDetailsContainer: FC<PropsWithChildren> = ({
  children
}) => {
  const router = useRouter()
  const { tab } = useParams()

  const contractDetailsContext = useCPRDetailsContext()

  const { details, activeTab, tabs } = contractDetailsContext.state
  const { setActiveTab } = contractDetailsContext.actions

  const title = details.contract_category_name

  useEffect(() => {
    setActiveTab(safeString(tab || 'general'))
  }, [tab])

  const RenderedStatus = useMemo(
    () => (
      <Status.Default
        className={
          CONTARCT_PIPELINES_STATUS_TYPES_TO_CLASSNAME[
            details.status || CONTRACT_PIPELINES_STATUS.FUTURE
          ]
        }
      >
        {
          CONTRACT_PIPELINES_STATUS_NAME[
            details.status || CONTRACT_PIPELINES_STATUS.FUTURE
          ]
        }
      </Status.Default>
    ),
    [details.status]
  )

  const RenderedActions = useMemo(() => <ContractPipelineDetailsActions />, [])

  const RenderedMenu = useMemo(() => {
    const handleSelectTab = (e) => {
      setActiveTab(e.key)
      router.push(e.key)
    }

    return (
      <Menu
        mode="horizontal"
        onSelect={handleSelectTab}
        selectedKeys={[activeTab]}
        defaultSelectedKeys={[activeTab]}
        items={tabs}
      />
    )
  }, [activeTab, router, setActiveTab, tabs])

  return (
    <Can
      I={ACTIONS.VIEW}
      a={contractSubject(SUBJECTS.CONTRACT_PIPELINE, details)}
    >
      <Container
        title={title}
        subtitle={details.contract_pipeline_id}
        breadcrumbs={CONTRACT_DETAILS_BREADCRUMBS(title, {
          ...router.query,
          type: contractPageRoute.contractPipeline
        })}
        status={RenderedStatus}
        actions={RenderedActions}
        menu={RenderedMenu}
      >
        {children}
      </Container>
    </Can>
  )
}

export default ContractPipelineDetailsContainer
