import Fetcher from '../../../services/Fetcher'
import { API_DICTIONARY, HTTP_METHODS } from '../../../constants'
import { CommunitiesRequest, CommunitiesResponse } from './types'

const fetcher = new Fetcher({})

export const getCommunitiesList: CommunitiesRequest = async (params) =>
  fetcher.request<unknown, CommunitiesResponse>({
    url: `${API_DICTIONARY.MY_COMMUNITY}${API_DICTIONARY.COMMUNITIES}`,
    method: HTTP_METHODS.GET,
    params
  })
