import { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { setLoading } from '../../../redux/store/common/slice'
import Typography from '../../../components/Typography/Typography'
import { getOpportunityAnalysisCommunity } from '../../Contracts/api'
import { TRequestsForAnalysisCommunityList } from '../../Contracts/types'
import './style.scss'
import useRouter from '../../../hooks/useRouter'
import { routes } from '../../../router'

export const RequestForAnalysis = () => {
  const [data, setData] = useState<TRequestsForAnalysisCommunityList[]>([])
  const { push } = useRouter()

  const dispatch = useDispatch()

  const getData = async () => {
    dispatch(setLoading(true))
    try {
      const res = await getOpportunityAnalysisCommunity()
      setData(res.data.results)
    } catch (err) {
      await Promise.reject(err)
    } finally {
      dispatch(setLoading(false))
    }
  }
  const handleRedirect = (id) => {
    push(routes.contracts + routes.cognusRepository + '/' + id)
  }

  useEffect(() => {
    getData()
  }, [])
  return (
    <div className="request-for-analysis-container mt-16">
      {!!data?.length ? (
        data.map((hs) => (
          <div
            className="request-for-analysis-container__community-wrapper row space-between"
            key={hs.health_system}
            onClick={() => handleRedirect(hs.health_system)}
          >
            <Typography.Body1 className="health-system-name">
              {hs.health_system_name}
            </Typography.Body1>
            <div className="contract-category-wrapper">
              <Typography.Label>
                {hs.groups_count + ' '} contract category
              </Typography.Label>
            </div>
          </div>
        ))
      ) : (
        <div className="request-for-analysis-container__community-empty-state">
          <Typography.Headline6>
            There are no active requests for analysis
          </Typography.Headline6>
        </div>
      )}
    </div>
  )
}
