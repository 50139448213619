import { useCallback, useEffect, useMemo } from 'react'
import { useDispatch } from 'react-redux'
import { useParams } from 'react-router-dom'

import './style.scss'

import { Typography } from 'components/Typography'
import Member from '../Member/Member'
import AddMembers from '../AddMembers'
import { ACTIONS, chatSubject, SUBJECTS } from 'features/Chat/Abilities'
import { Can } from 'features/Chat/Providers/ChatAbilityProvider'
import { useChatContext } from 'features/Chat/Providers/ChatContextProvider'
import { safeNumber } from 'helper/common'
import { CHAT_TYPE } from 'constants/common'
import { setChatMembersAsync } from 'redux/store/chatDetails/actions'

export const Members = (): JSX.Element => {
  const dispatch = useDispatch<any>()
  const params = useParams()

  const chatContext = useChatContext()

  const { chat, members } = chatContext.state

  const setChatMembers = useCallback(() => {
    if (params.id) {
      dispatch(setChatMembersAsync(params.id))
    }
  }, [dispatch, params.id])

  useEffect(() => {
    setChatMembers()
  }, [setChatMembers])

  const isMoreMembers = useMemo(() => {
    return (
      chat &&
      chat?.type === CHAT_TYPE.CHANNEL &&
      !!members.length &&
      chat?.members_count !== members.length &&
      chat.members_count - 1 > 0
    )
  }, [chat, members])

  return (
    <div className="members-container">
      <Typography.Headline6 className="members-container__count">
        {safeNumber(chat?.members_count)} members
      </Typography.Headline6>
      <Can I={ACTIONS.EDIT} a={chatSubject(SUBJECTS.GROUP_CHAT, chat)}>
        <AddMembers />
      </Can>
      <div className="members-container__list">
        {chat?.type !== CHAT_TYPE.PRIVATE &&
          members.map((user) => <Member user={user} key={user.uuid} canEdit />)}
        {isMoreMembers && !!chat?.members_count && (
          <Typography.Body2 className="members-container__list__more">
            + {safeNumber(chat.members_count) - 1} more members
          </Typography.Body2>
        )}
      </div>
    </div>
  )
}
