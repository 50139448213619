import { Container } from 'components/Container/Container'
import './styles.scss'
import { ProductRequestsTable } from '../../features/ProductRequests/ProductRequestsTable'

export const ProductRequestContainer = () => {
  return (
    <Container
      className="purchase-order-container product-request-container"
      title="Product Requests"
    >
      <ProductRequestsTable />
    </Container>
  )
}
