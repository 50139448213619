import process from 'process'
import { Store } from 'redux'
import { ReactElement, useEffect } from 'react'
import { Provider } from 'react-redux'
import { BrowserRouter as Router } from 'react-router-dom'
import { Helmet } from 'react-helmet'
import { Loader } from './components/Loader'
import ErrorBoundary from 'features/ErrorBoundary/ErrorBoundary'
import AppSocketProvider from 'features/AppSocket/AppSocketProvider'
import { AbilityContext, ability } from 'features/Permission'
import { AuthCheck } from 'features/Auth'
import Logger from 'services/Logger'
import AppRoutes from 'router'
import BottomNotificationsContextProvider from './features/BottomNotificationProvider/BottomNotificationsContextProvider'
import 'antd/dist/antd.min.css'

export type AppProps = {
  store: Store
  logger: Logger
}

const hasServiceWorker = !!navigator.serviceWorker

const App = ({ store, logger }: AppProps): ReactElement => {
  useEffect(() => {
    window.addEventListener('resize', () => {
      document.documentElement.style.setProperty(
        '--inner-height',
        `${window.innerHeight}px`
      )
    })
  }, [])

  return (
    <AbilityContext.Provider value={ability}>
      <Helmet>
        <meta
          httpEquiv="Content-Security-Policy"
          content={`
            script-src 'self' https://*.googleapis.com; 
            object-src 'none'; 
            font-src 'self' https://fonts.gstatic.com data:; 
            ${
              hasServiceWorker
                ? 'worker-src blob: http://localhost:* https://*.cognushealth.com; '
                : ''
            }
            form-action 'self' https://zap ; 
            style-src 'self' 'unsafe-inline' https://fonts.googleapis.com; 
            frame-src self https://zap ; 
            default-src 'self' ${
              process.env.REACT_APP_S3_BUCKET
            } https://*.cognushealth.com wss://*.cognushealth.com https://*.googleapis.com;
          `}
        />
      </Helmet>
      <Router>
        <ErrorBoundary logger={logger}>
          <Provider store={store}>
            <AppSocketProvider>
              <BottomNotificationsContextProvider>
                <AuthCheck>
                  <AppRoutes />
                </AuthCheck>
              </BottomNotificationsContextProvider>
            </AppSocketProvider>
            <Loader />
          </Provider>
        </ErrorBoundary>
      </Router>
    </AbilityContext.Provider>
  )
}

export default App
