import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { moduleName } from './constants'
import { NotificationsState } from './types'

const initialState: NotificationsState = {
  isModalOpen: false,
  token: '',
  unreadNotifications: 0
}

const slice = createSlice({
  name: moduleName,
  initialState,
  reducers: {
    setIsModalOpen(state: NotificationsState, action: PayloadAction<boolean>) {
      state.isModalOpen = action.payload
    },
    setUnreadNotificationsAmount(
      state: NotificationsState,
      action: PayloadAction<number>
    ) {
      state.unreadNotifications = action.payload
    },
    setToken(state: NotificationsState, action: PayloadAction<string>) {
      state.token = action.payload
    }
  }
})

export default slice.reducer
export const { setIsModalOpen, setUnreadNotificationsAmount, setToken } =
  slice.actions
