import { State } from '../../type'
import { moduleName } from './constants'
import { RejectedInvoicesState } from './types'

const getState = (state: State): RejectedInvoicesState => state[moduleName]

export const getChangedLines = (state: State) => {
  const stateSlice = getState(state)
  return stateSlice.changedLines
}
