import moment from 'moment'

import {
  roundHoursUp,
  mergeTimelineDateTime,
  timelineLabelFormat
} from 'components/Timelines/utils'
import { ITimeline, TTimelines } from 'components/Timelines/types'
import {
  CONTRACT_STATUS,
  CONTRACT_STATUS_NAME
} from 'features/ContractDetails/constants'
import {
  IContractTimeline,
  SetupContractDetailsTimeRequestData
} from 'features/ContractDetails/types'

export const timelinesRequestDataConverter = (
  data: ITimeline<typeof CONTRACT_STATUS>[]
): SetupContractDetailsTimeRequestData =>
  Object.values(data).map((v) => ({
    status: v.key,
    expire_at: mergeTimelineDateTime(v.date, v.timeLabel).format()
  }))

export const timelinesResponseDataConverter = (
  data: IContractTimeline[]
): TTimelines<typeof CONTRACT_STATUS> =>
  data?.reduce((timelineForm, responseTimeline, index) => {
    const { status, expire_at } = responseTimeline

    const timelineDate = roundHoursUp(moment(expire_at))

    return {
      ...timelineForm,
      [status]: {
        order: index,
        key: status,
        title: CONTRACT_STATUS_NAME[status],
        date: timelineDate,
        timeLabel: timelineLabelFormat(timelineDate),
        hasChanges: false
      }
    }
  }, {} as TTimelines<typeof CONTRACT_STATUS>)
