import { useCallback, useEffect, useLayoutEffect, useState } from 'react'
import { Form } from 'antd'
import { TOption } from 'components/Select/types'
import { useContractDetailsContext } from 'features/ContractDetails/Providers/ContractDetailsContextProvider'
import {
  getVendorContractStewardsRequestAsync,
  getVendorsRequestAsync
} from 'features/ContractDetails/api'
import { fetchContractCategories } from 'pages/CRUDUser/api'
import { formatOptionNameValue } from 'helper/optionsFormatters'
import { Props } from './types'

const useAdditionalInfo = (props: Props) => {
  const { form } = props

  const contractDetailsContext = useContractDetailsContext()

  const { isCreation } = contractDetailsContext.state

  const _vendor = Form.useWatch('vendor', form)
  const _contract_category = Form.useWatch('contract_category', form)

  const [vendorOptions, setVendorOptions] = useState<TOption[]>([])
  const [categoryOptions, setCategoryOptions] = useState<TOption[]>([])
  const [contractStewardOptions, setContractStewardOptions] = useState<
    TOption[]
  >([])

  const vendorContractStewardDisabled = !_vendor || !_contract_category

  const _getRequiredCreationData = useCallback(() => {
    Promise.all([fetchContractCategories(), getVendorsRequestAsync()]).then(
      ([contractCategoryResponse, vendorsResponse]) => {
        if (vendorsResponse?.data?.results) {
          setVendorOptions(
            vendorsResponse.data.results.map(formatOptionNameValue)
          )
        }
        if (contractCategoryResponse?.data?.results) {
          setCategoryOptions(
            contractCategoryResponse.data.results.map(formatOptionNameValue)
          )
        }
      }
    )
  }, [])

  useEffect(() => {
    if (isCreation) {
      _getRequiredCreationData()
    }
  }, [_getRequiredCreationData, isCreation])

  useEffect(() => {
    if (_vendor && _contract_category) {
      getVendorContractStewardsRequestAsync({
        vendors: [_vendor],
        contract_categories: [_contract_category]
      }).then((response) => {
        if (response?.data?.results) {
          setContractStewardOptions(
            response.data.results.map((option) => ({
              ...option,
              label: [option.first_name, option.last_name].join(' '),
              value: option.uuid
            }))
          )
        }
      })
    }
  }, [_vendor, _contract_category, form])

  useLayoutEffect(() => {
    if (contractStewardOptions.length) {
      const values = form.getFieldsValue()

      form.setFieldsValue({
        ...values,
        vendor_contract_steward: contractStewardOptions
          .find((c) => c.value === values.vendor_contract_steward)
          ?.value?.toString()
      })

      return
    }

    form.resetFields(['vendor_contract_steward'])
  }, [contractStewardOptions, form])

  return {
    isCreation,
    vendorOptions,
    categoryOptions,
    contractStewardOptions,
    vendorContractStewardDisabled
  }
}

export default useAdditionalInfo
