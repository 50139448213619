import { useCallback, FC } from 'react'
import { Form } from 'antd'
import HSTable from 'features/ContractDetails/HSList/HSTable/HSTable'
import { useContractDetailsPopup } from 'features/ContractDetails/Providers/ContractDetailsPopupProvider'
import { useHSTableContext } from 'features/ContractDetails/HSList/Providers/HSTableProvider'
import { useContractDetailsContext } from 'features/ContractDetails/Providers/ContractDetailsContextProvider'
import { columnsFullView as columns } from '../HSTable/constants'
import { AddHealthSystemProps } from './types'
import './styles.scss'

const AddHealthSystem: FC<AddHealthSystemProps> = ({ ...props }) => {
  const contractDetailsContext = useContractDetailsContext()
  const { addHealthSystemPopup, addAmendmentPopup } = useContractDetailsPopup()

  const { addHealthSystemAsync } = contractDetailsContext.actions

  const { refreshTableData } = useHSTableContext()

  const handleFinishForm = useCallback(async () => {
    if (!props.selectedRowKeys.length) {
      return
    }

    const successCallback = async () => {
      await refreshTableData()
      addHealthSystemPopup.actions.close()
    }

    if (props.isContractSigned) {
      addAmendmentPopup.actions.open({
        hsTableSelectedRowKeys: props.selectedRowKeys
      })
      return
    }

    await addHealthSystemAsync(
      {
        healthSystemIds: props.selectedRowKeys.map((item) => item.toString())
      },
      successCallback
    )
  }, [
    addAmendmentPopup.actions,
    addHealthSystemAsync,
    addHealthSystemPopup.actions,
    props.isContractSigned,
    props.selectedRowKeys,
    refreshTableData
  ])

  return (
    <Form onFinish={handleFinishForm} id="add-health-system-form-id">
      <div className="add-health-system-form__popup">
        <HSTable canSelectRows height={360} columns={columns} {...props} />
      </div>
    </Form>
  )
}

export default AddHealthSystem
