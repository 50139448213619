import { Button, BUTTON_TYPES } from '../../../../components/Button'
import { useCallback } from 'react'
import { BTN_TXT, FIREBASE_STATUSES, TOOLTIP } from '../../../../constants'
import {
  TNotificationType,
  TNotificationTypeName,
  useBottomNotificationsContext
} from '../../../BottomNotificationProvider/BottomNotificationsContextProvider'
import { Tooltip } from '../../../../components/Tooltip/Tooltip'
import {
  exportRejectedInvoices,
  getCurrentStatusRejectedInvoices
} from '../api'
import { useSelector } from 'react-redux'
import { getToken } from '../../../../redux/store/notifications/getters'
import { usePolling } from '../../../../hooks/usePolling'
import { downloadFile } from '../../../../helper/common'

export const ExportRejectedInvoices = () => {
  const {
    actions: { openNotification, onChangeNotification }
  } = useBottomNotificationsContext()

  const registrationId = useSelector(getToken)

  const { poll } = usePolling()

  const successCallback = useCallback(
    (data) => {
      onChangeNotification(
        {
          id: data?.task_id,
          type:
            data?.status === FIREBASE_STATUSES.COMPLETED
              ? TNotificationType.SUCCESS
              : TNotificationType.ERROR
        },
        TNotificationTypeName.EXPORT_REJECTED_INVOICES
      )
      if (data?.status === FIREBASE_STATUSES.COMPLETED && data?.file_url) {
        downloadFile(data.file_url)
      }
    },
    [onChangeNotification]
  )

  const exportInvoices = useCallback(async () => {
    await exportRejectedInvoices(
      registrationId
        ? {
            firebase_registration_id: registrationId
          }
        : undefined
    ).then((resp) => {
      openNotification(
        {
          id: resp.data.task_id,
          numberOfItems: resp.data.rejected_invoice_line_count,
          type: TNotificationType.INFO,
          date: new Date().toISOString()
        },
        TNotificationTypeName.EXPORT_REJECTED_INVOICES,
        true
      )
      if (!registrationId) {
        poll({
          request: async () =>
            getCurrentStatusRejectedInvoices({ task_id: resp.data.task_id }),
          successCallback
        })
      }
    })
  }, [openNotification, registrationId, exportRejectedInvoices])

  return (
    <Tooltip title={TOOLTIP.EXPORT_REJECTED_INVOICES}>
      <Button type={BUTTON_TYPES.DEFAULT} onClick={exportInvoices} upperCase>
        {BTN_TXT.EXPORT_INVOICES}
      </Button>
    </Tooltip>
  )
}
