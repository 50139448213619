import { useState, useEffect, ReactElement } from 'react'
import { useDispatch } from 'react-redux'
import { Form } from 'antd'
import cn from 'classnames'
import { Input } from 'components/Input'
import { Button, BUTTON_TYPES } from 'components/Button'
import './forms.contactUsLanding.scss'
import {
  TFormContactUsLandingForm,
  TFormContactUsLanding
} from './forms.contactUsLanding.d'
import { BTN_TXT, LABELS } from 'constants/txt'
import { setLoading } from 'redux/store/common/slice'
import { contactLanding } from 'features/SignPopup/api'

import {
  formatName,
  formatPhoneNumber,
  validateMessages
} from '../../helper/common'
import { Popup } from '../Popup'
import { Typography } from '../Typography'
import { contactUsLandingInitialValues } from './constants'
export const ContactUsLanding: TFormContactUsLanding = ({
  className
}): ReactElement => {
  const [error, setError] = useState({} as TFormContactUsLandingForm)
  const [isPhoneNumberValid, setIsPhoneNumberValid] = useState<boolean>(false)
  const [isOpen, setIsOpen] = useState<boolean>(false)

  const dispatch = useDispatch()
  const [form] = Form.useForm()

  const phone = Form.useWatch('phone_number', form)

  useEffect(() => {
    dispatch(setLoading(false))
  }, [])

  const handleContact = (values) => {
    if (!values.phone_number) {
      values.phone_number = ''
    }

    dispatch(setLoading(true))
    contactLanding(values)
      .then(() => {
        form.resetFields()
        setIsOpen(true)
      })
      .catch((e) => setError(e.data.email[0]))
      .finally(() => dispatch(setLoading(false)))
  }

  return (
    <>
      <Form<TFormContactUsLandingForm>
        className={cn('forms-contact-us-landing', className)}
        layout="vertical"
        requiredMark={'optional'}
        onFinish={handleContact}
        validateMessages={validateMessages}
        initialValues={contactUsLandingInitialValues}
        form={form}
        onValuesChange={(_, all) => {
          all?.phone_number?.toString().length !== 13
            ? setIsPhoneNumberValid(true)
            : setIsPhoneNumberValid(false)
        }}
      >
        <div className="forms-contact-us-landing__form column mt-40">
          <div className="row two-columns">
            <Input
              propsItem={{
                label: LABELS.FIRST_NAME,
                name: 'first_name',
                help: error?.first_name ? error?.first_name[0] : undefined,
                validateStatus: error?.first_name ? 'error' : undefined,
                rules: [
                  {
                    required: true,
                    pattern: /^[-\w\s]+$/,
                    max: 100
                  }
                ],
                normalize: formatName
              }}
              propsInput={{
                maxLength: 100
              }}
            />
            <Input
              propsItem={{
                label: LABELS.LAST_NAME,
                name: 'last_name',
                help: error?.last_name ? error?.last_name[0] : undefined,
                validateStatus: error?.last_name ? 'error' : undefined,
                rules: [
                  {
                    required: true,
                    pattern: /^[-\w\s]+$/,
                    max: 100
                  }
                ],
                normalize: formatName
              }}
              propsInput={{
                maxLength: 100
              }}
            />
          </div>
          <div className="row two-columns">
            <Input
              propsItem={{
                label: LABELS.EMAIL,
                name: 'email',
                help: error?.email ? error?.email[0] : undefined,
                validateStatus: error?.email ? 'error' : undefined,
                validateTrigger: 'onBlur',
                rules: [
                  {
                    required: true,
                    type: 'email',
                    validateTrigger: 'onSubmit'
                  }
                ]
              }}
              propsInput={{
                maxLength: 100
              }}
            />
            <Input.Phone
              propsItem={{
                label: LABELS.PHONE_NUMBER,
                name: 'phone_number',
                help: error?.phone_number ? error?.phone_number[0] : undefined,
                validateStatus: error?.phone_number ? 'error' : undefined,
                rules: [
                  {
                    pattern: /^[0-9]{10}$/,
                    validateTrigger: isPhoneNumberValid
                      ? ['onSubmit']
                      : ['onBlur']
                  }
                ]
              }}
              propsInputNumber={{
                prefix: '+1',
                controls: false,
                formatter: formatPhoneNumber,
                className: phone ? '' : 'empty'
              }}
            />
          </div>
          <div className="row two-columns">
            <Input
              propsItem={{
                label: LABELS.ORGANIZATION,
                name: 'organization_name',
                help: error?.organization_name
                  ? error?.organization_name[0]
                  : undefined,
                validateStatus: error?.organization_name ? 'error' : undefined,
                rules: [
                  {
                    required: true,
                    max: 100
                  }
                ]
              }}
              propsInput={{
                maxLength: 100
              }}
            />
          </div>
          <div className="row">
            <Input.TextArea
              propsItem={{
                label: LABELS.YOUR_INQUIRY,
                name: 'text',
                help: error?.text ? error?.text[0] : undefined,
                validateStatus: error?.text ? 'error' : undefined,
                rules: [{ required: true, max: 2000 }]
              }}
              propsTextArea={{
                showCount: true,
                maxLength: 2000
              }}
            />
          </div>
        </div>
        <div className="row mt-40 contact-us-landing-btn justify-center">
          <Form.Item noStyle shouldUpdate>
            {() => {
              return (
                <Button
                  small
                  className="forms-contact-us-landing__contact-us"
                  type={BUTTON_TYPES.PRIMARY}
                  htmlType="submit"
                  upperCase
                >
                  {BTN_TXT.CONTACT_US}
                </Button>
              )
            }}
          </Form.Item>
        </div>
      </Form>
      <Popup footer={null} visible={isOpen} onCancel={() => setIsOpen(false)}>
        <Typography.Headline4 className="forms-contact-us-landing__title">
          Your message has been submitted!
        </Typography.Headline4>
        <Typography.Body1>
          Thank you for submitting the form. We will contact you soon!
        </Typography.Body1>
      </Popup>
    </>
  )
}
