import {
  createContext,
  FC,
  PropsWithChildren,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState
} from 'react'

import { setLoading } from 'redux/store/common/slice'
import {
  IRFPVendor,
  RFPVendorRating,
  RFPVendorValue
} from 'features/RFP/RFPSurvey/types'
import { useRFPDetailsContext } from 'features/RFP/RFPDetails/Providers/RFPDetailsContextProvider'
import { useDispatch, useSelector } from 'react-redux'
import {
  createOrUpdateSurveyRequestAsync,
  getRfpVendorListRequestAsync,
  getSurveyRequestAsync,
  sendSurveyRequestAsync,
  submitSurveyVotingRequestAsync
} from 'features/RFP/RFPSurvey/api'
import { useRFPDetailsPopupContext } from 'features/RFP/RFPDetails/Providers/RFPDetailsPopupProvider'
import { notification } from 'components/Notification'
import { NOTIFICATIONS, VALIDATION_MESSAGES } from 'constants/txt'
import { getUser } from 'redux/store/user/getters'
import { RFP_STATUSES } from 'features/RFP/constants'
import { getStakeholderVoteRequestAsync } from 'features/RFP/RFPDetails/api'
import { ROLES } from '../../../Permission'

type ContextProps = {
  state: {
    vendors: IRFPVendor[]
    vendorsRating: RFPVendorRating
    selectedVendors: IRFPVendor[]
    selectedVendorValues: RFPVendorValue[]
    isSurveyCreated: boolean
    isSurveySubmitted: boolean
  }
  actions: {
    handleVendorsChange: (vendorValues: RFPVendorValue[]) => void
    handleVendorsRateChange: (rate: RFPVendorRating) => void
    createSurveyAsync: (vendorValues: RFPVendorValue[]) => void
    submitSurveyVotingAsync: (vendorsRating: RFPVendorRating) => void
    sendSurveyAsync: VoidFunction
    getRfpAcceptedVendorListAsync: () => Promise<void>
    getStakeholderVoteAsync: () => Promise<void>
  }
}

const RFPSurveyContext = createContext<ContextProps>({
  state: {} as ContextProps['state'],
  actions: {} as ContextProps['actions']
})

const RFPSurveyContextProvider: FC<PropsWithChildren> = ({ children }) => {
  const dispatch = useDispatch()

  const rfpDetailsContext = useRFPDetailsContext()
  const { createSurveyPopup, submitSurveyPopup } = useRFPDetailsPopupContext()
  const user = useSelector(getUser)

  const [vendors, _setVendors] = useState<IRFPVendor[]>([])
  const [selectedVendorValues, _setSelectedVendorValues] = useState<
    RFPVendorValue[]
  >([])
  const [vendorsRating, _setVendorsRating] = useState<RFPVendorRating>({})

  const selectedVendors = useMemo(
    () =>
      vendors
        ? vendors.filter((vendor) => selectedVendorValues.includes(vendor.uuid))
        : [],
    [selectedVendorValues, vendors]
  )

  const isSurveyCreated = useMemo(
    () => selectedVendors.length > 0,
    [selectedVendors]
  )

  const isSurveySubmitted = useMemo(
    () =>
      selectedVendors.length > 0 &&
      Object.keys(vendorsRating).length === selectedVendors.length,
    [selectedVendors.length, vendorsRating]
  )

  const handleVendorsChange = useCallback((vendorValues: RFPVendorValue[]) => {
    _setSelectedVendorValues(vendorValues)
  }, [])

  const handleVendorsRateChange = useCallback((rating: RFPVendorRating) => {
    _setVendorsRating(rating)
  }, [])

  const getRfpAcceptedVendorListAsync = useCallback(async () => {
    if (!rfpDetailsContext.state.data.uuid) {
      throw new Error('RFP ID not provided')
    }

    try {
      dispatch(setLoading(true))

      if (
        user?.role !== ROLES.STAKEHOLDER &&
        user?.role !== ROLES.COMMONS_STAKEHOLDER
      ) {
        const rfpVendorListResponse = await getRfpVendorListRequestAsync(
          rfpDetailsContext.state.data.uuid
        )

        if (rfpVendorListResponse.data?.results) {
          _setVendors(rfpVendorListResponse.data?.results)
        }
      }
    } catch (e) {
      console.error(e)
    } finally {
      dispatch(setLoading(false))
    }
  }, [dispatch, rfpDetailsContext.state.data.uuid])

  const getRfpSurveyAsync = useCallback(async () => {
    if (!rfpDetailsContext.state.data.uuid) {
      throw new Error('RFP ID not provided')
    }

    try {
      dispatch(setLoading(true))

      const rfpSurveyResponse = await getSurveyRequestAsync(
        rfpDetailsContext.state.data.uuid
      )

      if (rfpSurveyResponse.data?.results) {
        _setSelectedVendorValues(
          rfpSurveyResponse.data.results.map((v) => v.uuid)
        )
        _setVendors(rfpSurveyResponse.data.results)
      }
    } catch (e) {
      console.error(e)
    } finally {
      dispatch(setLoading(false))
    }
  }, [dispatch, rfpDetailsContext.state.data.uuid])

  const getStakeholderVoteAsync = useCallback(async () => {
    if (!rfpDetailsContext.state.data.uuid) {
      throw new Error('RFP ID not provided')
    }

    try {
      dispatch(setLoading(true))

      const response = await getStakeholderVoteRequestAsync(
        rfpDetailsContext.state.data.uuid
      )

      if (response?.data?.results?.length > 0) {
        const rating = response.data.results.reduce(
          (obj, vote) => ({
            ...obj,
            [vote.vendor.uuid]: vote.grade
          }),
          {}
        )

        _setVendorsRating(rating)
      }
    } catch (e) {
      console.error(e)
    } finally {
      dispatch(setLoading(false))
    }
  }, [dispatch, rfpDetailsContext.state.data.uuid])

  const createSurveyAsync = useCallback(
    async (vendorValues: RFPVendorValue[]) => {
      if (!rfpDetailsContext.state.data.uuid) {
        throw new Error('RFP ID not provided')
      }

      try {
        dispatch(setLoading(true))

        await createOrUpdateSurveyRequestAsync(
          rfpDetailsContext.state.data.uuid,
          { vendors: vendorValues }
        )

        createSurveyPopup.actions.close()
        handleVendorsChange(vendorValues)
        notification.success({ message: NOTIFICATIONS.SURVEY_CREATED })
      } catch (e) {
        console.error(e)
      } finally {
        dispatch(setLoading(false))
      }
    },
    [
      createSurveyPopup.actions,
      dispatch,
      handleVendorsChange,
      rfpDetailsContext.state.data.uuid
    ]
  )

  const sendSurveyAsync = useCallback(async () => {
    if (!rfpDetailsContext.state.data.uuid) {
      throw new Error('RFP ID not provided')
    }

    try {
      dispatch(setLoading(true))

      await sendSurveyRequestAsync(rfpDetailsContext.state.data.uuid)
      await rfpDetailsContext.actions.handleGetRfpData()
    } catch (e) {
      console.error(e)
    } finally {
      dispatch(setLoading(false))
    }
  }, [dispatch, rfpDetailsContext.actions, rfpDetailsContext.state.data.uuid])

  const submitSurveyVotingAsync = useCallback(
    async (vendorsRating: RFPVendorRating) => {
      if (!rfpDetailsContext.state.data.uuid) {
        throw new Error('RFP ID not provided')
      }

      const submitSurveyVotingData = Object.entries(vendorsRating).map(
        ([vendor, grade]) => ({
          vendor,
          grade
        })
      )

      try {
        dispatch(setLoading(true))

        await submitSurveyVotingRequestAsync(
          rfpDetailsContext.state.data.uuid,
          submitSurveyVotingData
        )

        await rfpDetailsContext.actions.handleGetRfpData()

        handleVendorsRateChange(vendorsRating)
        submitSurveyPopup.actions.close()

        notification.success({ message: VALIDATION_MESSAGES.SM0040 })
      } catch (e) {
        console.error(e)
      } finally {
        dispatch(setLoading(false))
      }
    },
    [
      dispatch,
      handleVendorsRateChange,
      rfpDetailsContext.actions,
      rfpDetailsContext.state.data.uuid,
      submitSurveyPopup.actions
    ]
  )

  useEffect(() => {
    const status = rfpDetailsContext.state.data.status

    if (
      !user.vendor &&
      (status === RFP_STATUSES.PRICE_FILES_ANALYSIS ||
        status === RFP_STATUSES.VOTING_IN_PROGRESS ||
        status === RFP_STATUSES.VENDORS_SELECTION ||
        status === RFP_STATUSES.ARCHIVED)
    ) {
      getRfpSurveyAsync()
    }
  }, [
    getRfpSurveyAsync,
    getStakeholderVoteAsync,
    rfpDetailsContext.state.data.status,
    user.vendor
  ])

  const state = useMemo(
    () => ({
      vendors,
      vendorsRating,
      selectedVendors,
      selectedVendorValues,
      isSurveyCreated,
      isSurveySubmitted
    }),
    [
      vendors,
      vendorsRating,
      selectedVendors,
      selectedVendorValues,
      isSurveyCreated,
      isSurveySubmitted
    ]
  )

  const actions = useMemo(
    () => ({
      handleVendorsChange,
      handleVendorsRateChange,
      submitSurveyVotingAsync,
      createSurveyAsync,
      sendSurveyAsync,
      getStakeholderVoteAsync,
      getRfpAcceptedVendorListAsync
    }),
    [
      sendSurveyAsync,
      createSurveyAsync,
      submitSurveyVotingAsync,
      handleVendorsRateChange,
      handleVendorsChange,
      getStakeholderVoteAsync,
      getRfpAcceptedVendorListAsync
    ]
  )
  return (
    <RFPSurveyContext.Provider value={{ state, actions }}>
      {children}
    </RFPSurveyContext.Provider>
  )
}
export const useRFPSurveyContext = () => useContext(RFPSurveyContext)

export default RFPSurveyContextProvider
