import ContractsContainer from './ContractsTable'
import ContractPageContextProvider from './Providers/ContractPageContextProvider'
import ContractPagePopupProvider from './Providers/ContractPagePopupProvider'

export const Contacts = () => {
  return (
    <ContractPageContextProvider>
      <ContractPagePopupProvider>
        <ContractsContainer />
      </ContractPagePopupProvider>
    </ContractPageContextProvider>
  )
}
