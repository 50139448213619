import { Can } from '../Providers/PrsDetailsAbilityProvider'
import { ACTIONS, SUBJECTS } from '../../Abilities'
import { subject } from '@casl/ability'
import { Button, BUTTON_TYPES } from '../../../../components/Button'
import { BTN_TXT } from '../../../../constants'
import { Popup } from '../../../../components/Popup'
import { Form } from 'antd'
import {
  Typography,
  TYPOGRAPHY_WEIGHT
} from '../../../../components/Typography'
import Input from '../../../../components/Input/Input'
import { usePRSDetailsContext } from '../Providers/PRSDetailsContextProvider'
import { usePRSDetailsPopupContext } from '../Providers/PRSDetailsPopupProvider'
import { TDeclineValue } from '../../../../pages/Financials/types'
import { setLoading } from '../../../../redux/store/common/slice'
import { updatePRS } from '../../../../pages/ProductRequests/api'
import {
  PRS_STATUSES_MESSAGES,
  RCA_DECISION
} from '../../../../pages/ProductRequests/constants'
import { notification } from '../../../../components/Notification'
import { useDispatch } from 'react-redux'

const RcaApproveDecline = () => {
  const { state, actions } = usePRSDetailsContext()
  const { approveFromRcaPopup, declineFromRcaPopup } =
    usePRSDetailsPopupContext()
  const { data } = state
  const { handleGetPRSData } = actions

  const dispatch = useDispatch()

  const [declineForm] = Form.useForm()

  const rca_decline_reason = Form.useWatch('rca_decline_reason', declineForm)

  const onDeclineSubmit = (value: TDeclineValue) => {
    dispatch(setLoading(true))
    updatePRS(data.uuid, { rca_decision: RCA_DECISION.RCA_DECLINED, ...value })
      .then(() => {
        handleGetPRSData()
        notification.success({
          message: `${PRS_STATUSES_MESSAGES.rca_declined}`
        })
        declineForm.setFieldsValue({ rca_decline_reason: '' })
      })
      .catch((err) => {
        notification.error({
          message: err.data?.details[0]
        })
      })
      .finally(() => {
        dispatch(setLoading(false))
        closeDeclineModal()
      })
  }
  const onApproveSubmit = () => {
    dispatch(setLoading(true))
    updatePRS(data.uuid, { rca_decision: RCA_DECISION.RCA_APPROVED })
      .then(() => {
        handleGetPRSData()
        notification.success({
          message: `${PRS_STATUSES_MESSAGES.rca_approved}`
        })
        closeApproveModal()
      })
      .catch((err) => {
        notification.error({
          message: err.data?.details[0]
        })
      })
      .finally(() => {
        dispatch(setLoading(false))
        closeDeclineModal()
      })
  }

  const openDeclineModal = () => declineFromRcaPopup.actions.open()
  const closeDeclineModal = () => declineFromRcaPopup.actions.close()
  const closeApproveModal = () => approveFromRcaPopup.actions.close()
  const openApproveModal = () => approveFromRcaPopup.actions.open()
  return (
    <>
      <Can I={ACTIONS.RCA} a={subject(SUBJECTS.DECLINE, { ...data })}>
        <Button
          type={BUTTON_TYPES.DEFAULT}
          onClick={openDeclineModal}
          danger
          upperCase
        >
          {BTN_TXT.DECLINE}
        </Button>
      </Can>
      <Can I={ACTIONS.RCA} a={subject(SUBJECTS.APPROVE, { ...data })}>
        <Button
          type={BUTTON_TYPES.PRIMARY}
          onClick={openApproveModal}
          upperCase
        >
          {BTN_TXT.APPROVE}
        </Button>
      </Can>
      <Popup
        visible={declineFromRcaPopup.state.visible}
        onCancel={closeDeclineModal}
        title="Decline the PRS request?"
        width={442}
        footer={
          <>
            <Button
              onClick={closeDeclineModal}
              type={BUTTON_TYPES.DEFAULT}
              upperCase
            >
              {BTN_TXT.CANCEL}
            </Button>
            <Button
              form="decline-contract-form"
              disabled={!rca_decline_reason?.trim()}
              htmlType="submit"
              type={BUTTON_TYPES.PRIMARY}
              upperCase
              danger
            >
              {BTN_TXT.DECLINE}
            </Button>
          </>
        }
      >
        <Form
          id="decline-contract-form"
          form={declineForm}
          onFinish={onDeclineSubmit}
          layout="vertical"
        >
          <Typography.Headline6
            weight={TYPOGRAPHY_WEIGHT.NORMAL}
            className="financials-table__subtitle"
          >
            Please, provide a reason of the decline:
          </Typography.Headline6>
          <Input.TextArea
            propsItem={{
              label: 'Reason',
              name: 'rca_decline_reason',
              rules: [
                {
                  max: 500
                }
              ]
            }}
            propsTextArea={{
              autoSize: {
                maxRows: 12
              },
              showCount: true,
              maxLength: 500
            }}
          />
        </Form>
      </Popup>
      <Popup
        visible={approveFromRcaPopup.state.visible}
        onCancel={closeApproveModal}
        title="Approve the PRS request?"
        width={442}
        footer={
          <>
            <Button
              onClick={closeApproveModal}
              type={BUTTON_TYPES.DEFAULT}
              upperCase
            >
              {BTN_TXT.CANCEL}
            </Button>
            <Button
              form="decline-contract-form"
              onClick={onApproveSubmit}
              type={BUTTON_TYPES.PRIMARY}
              upperCase
            >
              {BTN_TXT.APPROVE_PRS}
            </Button>
          </>
        }
      >
        <Typography.Body1
          weight={TYPOGRAPHY_WEIGHT.NORMAL}
          className="financials-table__subtitle"
        >
          Revenue analysis has been passed. Response will be sent back to{' '}
          {data.community_user?.first_name +
            ' ' +
            data.community_user?.last_name}
          .
        </Typography.Body1>
      </Popup>
    </>
  )
}

export default RcaApproveDecline
