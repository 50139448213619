import {
  createContext,
  FC,
  PropsWithChildren,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState
} from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams, useLocation } from 'react-router-dom'

import { setLoading } from 'redux/store/common/slice'
import { getUser } from 'redux/store/user/getters'
import {
  VendorDetailsWithContractAdmin,
  VendorWithAdminCreationRequestData
} from '../types'
import {
  createVendorWithAdminRequestAsync,
  getVendorDetailsRequestAsync
} from '../api'
import { CREATION_ROUTE, INITIAL_VENDOR_DETAILS } from '../constants'
import { AxiosResponse } from 'axios'
import { getObjValueByQuery } from 'helper/common'

type ContextProps = {
  state: {
    details: VendorDetailsWithContractAdmin
    isVendor: boolean
    isCreation: boolean
  }
  actions: {
    getError: (query: string[]) => string | undefined
    setVendorDetailsAsync: () => Promise<void>
    createVendorWithAdminAsync: (
      details: VendorWithAdminCreationRequestData
    ) => Promise<string | undefined>
  }
}

const VendorDetailsContext = createContext<ContextProps>({
  state: null!,
  actions: null!
})

const prepareRouteId = (routeId?: string) =>
  routeId ? (routeId === CREATION_ROUTE ? null : routeId) : null

const ContextProvider: FC<PropsWithChildren> = ({ children }) => {
  const dispatch = useDispatch()
  const { id: routeId } = useParams()
  const location = useLocation()

  const user = useSelector(getUser)

  const [id, setId] = useState<string | null>(prepareRouteId(routeId))
  const [details, _setDetails] = useState<VendorDetailsWithContractAdmin>(
    INITIAL_VENDOR_DETAILS
  )
  const [errors, setErrors] = useState<any>(null)

  const isCreation = useMemo(
    () => location.pathname.includes('vendor-creation'),
    [location.pathname]
  )

  const isVendor = useMemo(() => Boolean(user.vendor), [user.vendor])

  const getError = useCallback(
    (query: string[]) => {
      return getObjValueByQuery(errors, query)
    },
    [errors]
  )

  const setVendorDetailsAsync = useCallback(async () => {
    if (!id) {
      throw new Error('Contract ID not provided')
    }

    try {
      dispatch(setLoading(true))

      const response = await getVendorDetailsRequestAsync(id)

      if (response?.data) {
        _setDetails(response?.data)
      }
    } catch (e) {
      console.error(e)
    } finally {
      dispatch(setLoading(false))
    }
  }, [dispatch, id])

  const createVendorWithAdminAsync = useCallback(
    async (details: VendorWithAdminCreationRequestData) => {
      setErrors(null)

      try {
        dispatch(setLoading(true))

        const response = await createVendorWithAdminRequestAsync(details)

        return response?.data?.uuid
      } catch (response) {
        const typedResponse = response as AxiosResponse

        if (typedResponse?.data) {
          setErrors(typedResponse.data)
        }
      } finally {
        dispatch(setLoading(false))
      }
    },
    [dispatch]
  )

  useEffect(() => {
    if (!isCreation) {
      setVendorDetailsAsync()
    }
  }, [isCreation, setVendorDetailsAsync])

  useEffect(() => {
    setId(prepareRouteId(routeId))
  }, [routeId])

  const context = useMemo(
    () => ({
      state: {
        details,
        errors,
        isVendor,
        isCreation
      },
      actions: {
        getError,
        setVendorDetailsAsync,
        createVendorWithAdminAsync
      }
    }),
    [
      details,
      errors,
      isVendor,
      isCreation,
      getError,
      setVendorDetailsAsync,
      createVendorWithAdminAsync
    ]
  )

  return (
    <VendorDetailsContext.Provider value={context}>
      {children}
    </VendorDetailsContext.Provider>
  )
}

export const useVendorDetailsContext = () => useContext(VendorDetailsContext)

export default ContextProvider
