import { FieldWithLabel } from '../../../../components/FieldWithLabel/FieldWithLabel'
import { LABELS } from '../../../../constants'
import { fullFormatPhoneNumber, safeString } from '../../../../helper/common'
import { Chips } from '../../../../components/Chips/Chips'
import Typography from '../../../../components/Typography/Typography'
import './style.scss'
import { usePRSDetailsContext } from '../Providers/PRSDetailsContextProvider'
import {
  usePrsDetailsAbility,
  Can
} from '../Providers/PrsDetailsAbilityProvider'
import { ACTIONS, SUBJECTS } from 'features/PRS/Abilities'
import { subject } from '@casl/ability'
import HSForm from './HSForm'
import ReassignWrapper from '../ReassignActions/ReassignWrapper'
import AddClinicalSponsor from './AddClinicalSponsor'
import { Button, BUTTON_TYPES } from '../../../../components/Button'
import { ReactComponent as ReassignIcon } from 'assets/svg/Reassign.svg'
import AssignClinicalSponsorModal from './AssignClinicalSponsorModal'
import { usePRSDetailsPopupContext } from '../Providers/PRSDetailsPopupProvider'
import UserItem from 'constants/UserItem'
import { useMemo } from 'react'

const ParticipantsDetails = () => {
  const { state } = usePRSDetailsContext()
  const { data } = state
  const ability = usePrsDetailsAbility()
  const { assignClinicalSponsorPopup } = usePRSDetailsPopupContext()

  const canReassign = useMemo(() => {
    return ability.can(
      ACTIONS.REASSIGN,
      subject(SUBJECTS.COMMUNITY_OWNER, { ...data })
    )
  }, [data])
  const canReassignRca = useMemo(() => {
    return ability.can(ACTIONS.REASSIGN, subject(SUBJECTS.RCA, { ...data }))
  }, [data])
  return (
    <div className="prs-details-participants-container">
      <div className="prs-details-participants__left__part row">
        <div className="prs-details-participants__left__part__wrapper">
          <div className="row">
            <FieldWithLabel title={LABELS.COMMUNITY}>
              {safeString(data.community_name)}
            </FieldWithLabel>
          </div>
          <div className="row full-width mt-24">
            {ability.can(
              ACTIONS.EDIT,
              subject(SUBJECTS.HEALTH_SYSTEMS, { ...data })
            ) ? (
              <div className="rfp-details-general__chips-wrapper full-width">
                <HSForm />
              </div>
            ) : (
              <FieldWithLabel
                title={LABELS.HEALTH_SYSTEMS}
                className="rfp-details-general__chips-wrapper full-width"
              >
                {data.health_systems.map((i) => (
                  <Chips key={i.uuid}>{i.name}</Chips>
                ))}
              </FieldWithLabel>
            )}
          </div>
        </div>
        <div className="full-width prs-details-participants__left__community-user ml-24">
          {!!data?.community_user?.uuid && (
            <FieldWithLabel
              title={LABELS.RESPONSIBLE_COMMUNITY_USER}
              className="responsible-prs-users-wrapper"
            >
              <ReassignWrapper
                canReassignOwner={canReassign}
                user={data?.community_user}
              />
              {data?.rca_user?.uuid && (
                <ReassignWrapper
                  canReassignOwner={canReassignRca}
                  isRca
                  user={data?.rca_user}
                />
              )}
            </FieldWithLabel>
          )}
        </div>
      </div>
      <div className="row clinical-sponsor-info">
        <div className="prs-details-participants__left">
          {data.clinical_sponsor && (
            <>
              <div className="row full-width mt-24">
                <Typography.Headline6 className="clinic-sponsor-title">
                  Clinical sponsor
                </Typography.Headline6>
              </div>
              <div className="row two-columns mt-24 word-break">
                <FieldWithLabel title={LABELS.HEALTH_SYSTEM}>
                  {safeString(data.clinical_sponsor.health_system_name)}
                </FieldWithLabel>
                <FieldWithLabel title={LABELS.REQUESTER_NAME}>
                  {safeString(data.clinical_sponsor.name)}
                </FieldWithLabel>
              </div>
              <div className="row two-columns mt-24">
                <FieldWithLabel title={LABELS.EMAIL}>
                  {safeString(data.clinical_sponsor.email)}
                </FieldWithLabel>
                <FieldWithLabel title={LABELS.PHONE_NUMBER}>
                  {fullFormatPhoneNumber(
                    '+1' + data.clinical_sponsor.phone_number
                  )}
                </FieldWithLabel>
              </div>
            </>
          )}
        </div>
        <div className="full-width prs-details-participants__left__community-user clinical ml-24 column">
          <div className="clinical-sponsor-action">
            <FieldWithLabel title={LABELS.CLINICAL_SPONSOR_USER}>
              {!!data?.clinical_sponsor_stakeholder ? (
                <div className="row space-between align-center">
                  <UserItem
                    key={data?.clinical_sponsor_stakeholder.uuid}
                    user={data.clinical_sponsor_stakeholder}
                  />
                  <Can
                    I={ACTIONS.REASSIGN}
                    a={subject(SUBJECTS.CLINICAL_SPONSOR, { ...data })}
                  >
                    <Button.Icon
                      type={BUTTON_TYPES.LINK}
                      icon={<ReassignIcon />}
                      onClick={assignClinicalSponsorPopup.actions.open}
                      small
                    />
                  </Can>
                </div>
              ) : (
                <AddClinicalSponsor />
              )}
            </FieldWithLabel>
          </div>
        </div>
      </div>
      <AssignClinicalSponsorModal />
    </div>
  )
}

export default ParticipantsDetails
