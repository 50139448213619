import { ColumnsType } from 'antd/es/table'
import { SortOrder } from 'antd/es/table/interface'
import { Sorter } from 'components/Sorter/Sorter'
import { AddressCell } from 'components/AddressCell/AddressCell'
import { ICPRHealthSystem, TColumnsProps } from './types'
import { CellWithSubtitle } from '../../../components/CellWithSubtitle/CellWithSubtitle'
import { Chips } from '../../../components/Chips/Chips'
import DeleteButton from './DeleteButton'

export const columns = ({
  canDeleteHs,
  setSelectedHS,
  userHsUuid
}: TColumnsProps): ColumnsType<ICPRHealthSystem> => {
  const _columns: ColumnsType<ICPRHealthSystem> = [
    {
      dataIndex: 'name',
      key: 'name',
      width: 272,
      title: ({ sortOrder, sortColumn }) => (
        <Sorter<ICPRHealthSystem>
          dataIndex="name"
          sortColumn={sortColumn}
          sortOrder={sortOrder as SortOrder}
        >
          Health system
        </Sorter>
      ),
      sorter: true,
      showSorterTooltip: false,
      render: (_, { name, badges }) => (
        <>
          <CellWithSubtitle title={name} />
          {badges.map((badge) => {
            return (
              <Chips className="mr-4" key={badge.id} closable={false} grey>
                {badge.value}
              </Chips>
            )
          })}
        </>
      )
    },
    {
      dataIndex: 'tax_id',
      key: 'tax_id',
      width: 165,
      title: ({ sortOrder, sortColumn }) => (
        <Sorter<ICPRHealthSystem>
          dataIndex="tax_id"
          sortColumn={sortColumn}
          sortOrder={sortOrder as SortOrder}
        >
          Tax ID
        </Sorter>
      ),
      sorter: true,
      showSorterTooltip: false
    },
    {
      dataIndex: 'email',
      key: 'email',
      width: 203,
      title: ({ sortOrder, sortColumn }) => (
        <Sorter<ICPRHealthSystem>
          dataIndex="email"
          sortColumn={sortColumn}
          sortOrder={sortOrder as SortOrder}
        >
          Email
        </Sorter>
      ),
      sorter: true,
      showSorterTooltip: false
    },
    {
      dataIndex: 'phone_number',
      key: 'phone_number',
      width: 162,
      title: ({ sortOrder, sortColumn }) => (
        <Sorter<ICPRHealthSystem>
          dataIndex="phone_number"
          sortColumn={sortColumn}
          sortOrder={sortOrder as SortOrder}
        >
          Phone #
        </Sorter>
      ),
      sorter: true,
      showSorterTooltip: false
    },
    {
      dataIndex: 'address',
      key: 'address',
      width: 290,
      title: ({ sortOrder, sortColumn }) => (
        <Sorter<ICPRHealthSystem>
          dataIndex="address"
          sortColumn={sortColumn}
          sortOrder={sortOrder as SortOrder}
        >
          Address
        </Sorter>
      ),
      render: (_, { address }) => <AddressCell address={address} />,
      sorter: true,
      showSorterTooltip: false
    }
  ]
  if (canDeleteHs) {
    _columns.push({
      dataIndex: '',
      key: 'actions',
      title: '',
      width: 48,
      render: (_, healthSystem) => (
        <DeleteButton
          setSelectedHS={setSelectedHS}
          healthSystem={healthSystem}
          userHsUuid={userHsUuid}
        />
      ),
      showSorterTooltip: false
    })
  }
  return _columns
}
