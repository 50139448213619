import Fetcher from 'services/Fetcher'
import { API_DICTIONARY, HTTP_METHODS } from 'constants/api'
import { CommonsDetailsData } from './types'

const fetcher = new Fetcher({})

export const getCommonsDetails = async () =>
  fetcher.request<unknown, CommonsDetailsData>({
    url: `${API_DICTIONARY.COMMONS}`,
    method: HTTP_METHODS.GET
  })
