import {
  createContext,
  FC,
  PropsWithChildren,
  useCallback,
  useContext,
  useMemo,
  useState
} from 'react'
import { useDispatch } from 'react-redux'

import { VALIDATION_MESSAGES } from 'constants/txt'
import { notification } from 'components/Notification'
import { setLoading } from 'redux/store/common/slice'

import { acceptContract, terminateContract } from 'features/ContractDetails/api'

type ContextProps = {
  state: {
    selectedId: string
    selectedCategoryName: string
    selectedLedBy: string
  }
  actions: {
    handleAcceptContract: (callback: VoidFunction) => Promise<void>
    handleTerminateContract: (callback: VoidFunction) => Promise<void>
    setSelectedId: (uuid: string) => void
    setSelectedCategoryName: (uuid: string) => void
    setSelectedLedBy: (value: string) => void
  }
}

const CognusRepositoryContext = createContext<ContextProps>({
  state: null!,
  actions: null!
})

const CognusRepositoryActions: FC<PropsWithChildren> = ({ children }) => {
  const dispatch = useDispatch()
  const [selectedId, setSelectedId] = useState('')
  const [selectedCategoryName, setSelectedCategoryName] = useState('')
  const [selectedLedBy, setSelectedLedBy] = useState('')

  const handleAcceptContract = useCallback(
    async (callback) => {
      if (!selectedId) {
        throw new Error('Contract ID not provided')
      }

      try {
        dispatch(setLoading(true))

        await acceptContract(selectedId, {})
        notification.success({
          message: VALIDATION_MESSAGES.SM00136
        })
        callback()
      } catch (e: any) {
        console.error(e)
      } finally {
        dispatch(setLoading(false))
      }
    },
    [dispatch, selectedId]
  )
  const handleTerminateContract = useCallback(
    async (callback) => {
      if (!selectedId) {
        throw new Error('Contract ID not provided')
      }

      try {
        dispatch(setLoading(true))

        await terminateContract(selectedId, {})
        notification.success({
          message: `Evaluation for ${selectedCategoryName} has been terminated`
        })
        callback()
      } catch (e: any) {
        console.error(e)
      } finally {
        dispatch(setLoading(false))
      }
    },
    [dispatch, selectedId, selectedCategoryName]
  )

  const context = useMemo(
    () => ({
      state: { selectedId, selectedCategoryName, selectedLedBy },
      actions: {
        handleAcceptContract,
        handleTerminateContract,
        setSelectedId,
        setSelectedCategoryName,
        setSelectedLedBy
      }
    }),
    [
      handleAcceptContract,
      selectedId,
      setSelectedId,
      handleTerminateContract,
      setSelectedCategoryName,
      selectedCategoryName,
      setSelectedLedBy,
      selectedLedBy
    ]
  )

  return (
    <CognusRepositoryContext.Provider value={context}>
      {children}
    </CognusRepositoryContext.Provider>
  )
}

export const useCognusRepositoryContext = () =>
  useContext(CognusRepositoryContext)

export default CognusRepositoryActions
