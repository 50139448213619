import { Link } from '../../../../components/Link'
import { routes } from '../../../../router'
import { InvoiceHistoryDocument, InvoiceHistoryUpdatedBy } from '../types'

export const getUploader = (data: InvoiceHistoryUpdatedBy) => (
  <Link to={`${routes.settings}${routes.profile}/${data.uuid}`}>
    {data.first_name} {data.last_name}
  </Link>
)

export const getUploadFile = (file?: InvoiceHistoryDocument) =>
  file ? (
    <Link.External to={file.file}>{file.file_name}</Link.External>
  ) : (
    'manually.'
  )
