import { BTN_TXT } from 'constants/txt'
import { Button, BUTTON_TYPES } from 'components/Button'
import { usePRSDetailsPopupContext } from '../Providers/PRSDetailsPopupProvider'
import Popup from 'components/Popup/Popup'
import { usePRSDetailsContext } from '../Providers/PRSDetailsContextProvider'
import Response from './Responses/Response'
import './style.scss'

const OpenMyResults = () => {
  const { viewSurveyFUPopup } = usePRSDetailsPopupContext()
  const { state } = usePRSDetailsContext()
  const { data } = state
  const onClick = () => viewSurveyFUPopup.actions.open()
  return (
    <>
      <Button
        onClick={onClick}
        upperCase
        type={BUTTON_TYPES.DEFAULT}
        className="open-my-results"
      >
        {BTN_TXT.OPEN_MY_RESULTS}
      </Button>
      <Popup
        visible={viewSurveyFUPopup.state.visible}
        onCancel={viewSurveyFUPopup.actions.close}
        title={`${data?.name} survey`}
        width={680}
        footer={null}
        className="prs-modal-create-survey"
      >
        <Response />
      </Popup>
    </>
  )
}

export default OpenMyResults
