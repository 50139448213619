import { AppThunk } from '../../type'

import { error, finish, loading, set, bulk } from './slice'

import {
  GetCheckInsListRequestArgs,
  getCheckInsListRequestAsync
} from 'features/CheckIns/api'

export const getCheckInsListActionAsync =
  (args: GetCheckInsListRequestArgs): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(loading())

      const { data } = await getCheckInsListRequestAsync(args)

      if (data) {
        if (args?.offset && Number(args.offset) !== 0) {
          dispatch(bulk(data.results))
          return
        }

        dispatch(
          set({
            ...data,
            search: args.search || null
          })
        )
      }

      await Promise.resolve()
    } catch (err) {
      dispatch(error({ error: err }))
      await Promise.reject(err)
    } finally {
      dispatch(finish())
    }
  }
