import { TOption } from '../../components/Select/types'
import { ContractCategoryItem } from './types'

export const returnSelectOptions = (
  arr: ContractCategoryItem[],
  selectedIds: string[]
): TOption[] => {
  if (arr?.length) {
    return arr
      ?.filter((i) => !selectedIds.includes(i.uuid))
      .map((item) => ({ label: item?.name, value: item?.uuid }))
  }
  return []
}
