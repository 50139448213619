import { ACTIONS, Can, SUBJECTS } from '../../features/Permission'
import { subject } from '@casl/ability'
import { Button, BUTTON_TYPES } from '../../components/Button'
import { BTN_TXT } from '../../constants'
import { useSelector } from 'react-redux'
import { getUser } from '../../redux/store/user/getters'
import { useInvoiceDataContext } from './Providers/InvoiceDataContextProvider'

export const UploadTemplate = () => {
  const user = useSelector(getUser)
  const { state } = useInvoiceDataContext()
  const download = () => window.open(state.files[0].file)
  return (
    <Can I={ACTIONS.UPLOAD} a={subject(SUBJECTS.INVOICE_DATA, { ...user })}>
      <Button onClick={download} type={BUTTON_TYPES.DEFAULT} upperCase>
        {BTN_TXT.DOWNLOAD_TEMPLATE}
      </Button>
    </Can>
  )
}
