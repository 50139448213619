import ContractBulkScreen from './ContractBulkContainer/ContractBulkScreen'
import ContractBulkTableConfigContextProvider from './Providers/ContractBulkTableConfigProvider'

const ContractBulkCreation = () => {
  return (
    <ContractBulkTableConfigContextProvider>
      <ContractBulkScreen />
    </ContractBulkTableConfigContextProvider>
  )
}

export default ContractBulkCreation
