import { CheckInsList } from './CheckInsList/CheckInsList'
import { CheckInsDetails } from './CheckInsDetails/CheckInsDetails'
import './styles.scss'

export const CheckInsContainer = () => {
  return (
    <div className="check-ins-container">
      <CheckInsList />
      <CheckInsDetails />
    </div>
  )
}
