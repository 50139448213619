import { ReactElement, useState } from 'react'
import { Forms } from 'components/Forms'
import SignPopup from 'features/SignPopup/SignPopup'

const FACode = (): ReactElement => {
  const [email, setEmail] = useState('')
  const emailChange = (e) => setEmail(e.target.value)
  return (
    <SignPopup isBack email={email}>
      <Forms.FACode emailChange={emailChange} />
    </SignPopup>
  )
}

export default FACode
