import './styles.scss'

import { Input } from 'components/Input'
import { Typography } from 'components/Typography'
import { Button, BUTTON_TYPES } from 'components/Button'
import { ReactComponent as CreatChat16 } from 'assets/svg/CreatChat16.svg'
import { ReactComponent as Search16 } from 'assets/svg/Search16.svg'
import { useChatListContext } from 'features/ChatList/Providers/ChatListContextProvider'
import Tabs from 'features/ChatList/Tabs/Tabs'
import { safeString } from 'helper/common'
import { BTN_TXT, PLACEHOLDERS } from 'constants/txt'
import { SIDEBAR_TYPE, SidebarTypeUnion } from 'features/ChatList/constants'
import { useChatListPopup } from 'features/ChatList/Providers/ChatListPopupProvider'
import { ReactComponent as Close16 } from '../../../assets/svg/Clos16.svg'

const Header = () => {
  const chatListContext = useChatListContext()
  const { sidebarDrawer } = useChatListPopup()

  const handleChangeSearch = (event) => {
    chatListContext.actions.setSearch(event.target.value)
  }

  const handleClick = (type: SidebarTypeUnion) => () => {
    sidebarDrawer.actions.open({ type })
  }

  return (
    <div className="header column">
      <div className="row space-between align-center">
        <Typography.Headline3 weight="semi-bold" className="header__title">
          Chat
        </Typography.Headline3>
        <Button.Menu
          className="create-chat-button"
          type={BUTTON_TYPES.GHOST}
          icon={<CreatChat16 />}
          options={[
            {
              text: BTN_TXT.START_PRIVATE_CHAT,
              onClick: handleClick(SIDEBAR_TYPE.NEW_PRIVATE)
            },
            {
              text: BTN_TXT.CREATE_GROUP_CHAT,
              onClick: handleClick(SIDEBAR_TYPE.NEW_GROUP)
            }
          ]}
          upperCase
        />
      </div>

      <Input
        className="mt-24 allow-clear"
        propsInput={{
          allowClear: { clearIcon: <Close16 /> },
          placeholder: PLACEHOLDERS.SEARCH_BY_CHAT_NAME,
          prefix: <Search16 />,
          value: safeString(chatListContext.state.search),
          onChange: handleChangeSearch
        }}
      />

      <div className="mt-16 chat-list-tabs">
        <Tabs />
      </div>
    </div>
  )
}

export default Header
