import {
  createContext,
  FC,
  PropsWithChildren,
  useCallback,
  useContext,
  useMemo
} from 'react'

import { useRFPDetailsPopupContext } from 'features/RFP/RFPDetails/Providers/RFPDetailsPopupProvider'
import { useRFPSurveyContext } from 'features/RFP/RFPDetails/Providers/RFPSurveyContextProvider'
import { useRFPVotingContext } from 'features/RFP/RFPDetails/Providers/RFPVotingContextProvider'
import { notification } from 'components/Notification'
import { VALIDATION_MESSAGES } from 'constants/txt'
import { useRFPDetailsContext } from 'features/RFP/RFPDetails/Providers/RFPDetailsContextProvider'

type ContextProps = {
  handleSendRfp: VoidFunction
  handleSendToCommons: VoidFunction
  handleSendSurvey: VoidFunction
  handleStartVendorsSelection: () => Promise<void>
  handleAwardVendors: VoidFunction
}

const RFPDetailsActionsContext = createContext<ContextProps>({
  handleSendRfp: () => {},
  handleSendToCommons: () => {},
  handleSendSurvey: () => {},
  handleStartVendorsSelection: () => new Promise(() => {}),
  handleAwardVendors: () => {}
})

const RFPDetailsActionsProvider: FC<PropsWithChildren> = ({ children }) => {
  const { sendRFPPopup, awardVendorsPopup, sendRFPToCommonsPopup } =
    useRFPDetailsPopupContext()

  const detailsContext = useRFPDetailsContext()
  const votingContext = useRFPVotingContext()
  const surveyContext = useRFPSurveyContext()

  const handleSendRfp = useCallback(() => {
    sendRFPPopup.actions.open()
  }, [sendRFPPopup.actions])
  const handleSendToCommons = useCallback(() => {
    sendRFPToCommonsPopup.actions.open()
  }, [sendRFPPopup.actions])

  const handleAwardVendors = useCallback(() => {
    if (detailsContext.actions.checkIsDeadlineMissed()) {
      sendRFPPopup.actions.open()
      return
    }

    awardVendorsPopup.actions.open()
  }, [awardVendorsPopup.actions, detailsContext.actions, sendRFPPopup.actions])

  const handleSendSurvey = useCallback(() => {
    const { summaryPriceFiles } = votingContext.state
    const { isSurveyCreated } = surveyContext.state

    if (detailsContext.actions.checkIsDeadlineMissed()) {
      sendRFPPopup.actions.open()
      return
    }

    if (!isSurveyCreated) {
      notification.error({ message: VALIDATION_MESSAGES.SM0029 })
      return
    }

    if (summaryPriceFiles.length <= 0) {
      notification.error({ message: VALIDATION_MESSAGES.SM0032 })
      return
    }

    surveyContext.actions.sendSurveyAsync()
  }, [
    detailsContext.actions,
    sendRFPPopup.actions,
    surveyContext.actions,
    surveyContext.state,
    votingContext.state
  ])

  const handleStartVendorsSelection = useCallback(async () => {
    if (detailsContext.actions.checkIsDeadlineMissed()) {
      sendRFPPopup.actions.open()
      return
    }

    await detailsContext.actions.startVendorsSelectionAsync()
  }, [detailsContext.actions, sendRFPPopup.actions])

  const context = useMemo(
    () => ({
      handleSendRfp,
      handleSendSurvey,
      handleStartVendorsSelection,
      handleAwardVendors,
      handleSendToCommons
    }),
    [
      handleSendRfp,
      handleSendSurvey,
      handleStartVendorsSelection,
      handleAwardVendors,
      handleSendToCommons
    ]
  )

  return (
    <RFPDetailsActionsContext.Provider value={context}>
      {children}
    </RFPDetailsActionsContext.Provider>
  )
}

export const useRFPDetailsActions = () => useContext(RFPDetailsActionsContext)

export default RFPDetailsActionsProvider
