import { useState } from 'react'
import { Popup } from 'components/Popup'
import { Typography } from 'components/Typography'
import { Forms } from 'components/Forms'
import { Button, BUTTON_TYPES } from 'components/Button'
import Logo from 'assets/svg/Logo.svg'
import { ReactComponent as VectorLogo } from 'assets/svg/ArrowLeft.svg'
import './styles.scss'
import useRouter from '../../hooks/useRouter'
import TermsOfUseAction from '../../components/TermsOfServices/TermsOfUseAction'
import PrivacyAction from '../../components/TermsOfServices/PrivacyAction'
import cn from 'classnames'

const SignPopup = ({
  children,
  email,
  isBack = false,
  footer = false,
  className = '',
  newLogo = ''
}): JSX.Element => {
  const [openHelpPopup, setOpenHelpPopup] = useState(false)
  const { navigate } = useRouter()
  const handleBack = () => {
    navigate(-1)
  }
  return (
    <Popup
      className={cn('sign-popup', className)}
      wrapClassName="sign-popup-wrap"
      footer={null}
      width={442}
      closable={false}
      visible
    >
      {isBack && (
        <div className="sign-popup-wrap-back" onClick={handleBack}>
          <VectorLogo />
        </div>
      )}
      <div className="sign-popup-logo-wrapper">
        <img
          className="sign-popup__logo"
          src={!!newLogo ? newLogo : Logo}
          alt="Logo"
        />
      </div>

      {children}
      <Popup
        visible={openHelpPopup}
        className="contact-us-popup"
        onCancel={() => setOpenHelpPopup(false)}
        title="Contact us"
        footer={null}
        width={442}
        maskTransitionName="modal-mask-auth-pages"
      >
        <Forms.ContactUs setOpenHelpPopup={setOpenHelpPopup} email={email} />
      </Popup>
      <footer>
        <Typography.Body1 className="contact-us-wrapper">
          If you need assistance, please&nbsp;
          <Button
            type={BUTTON_TYPES.LINK}
            onClick={() => setOpenHelpPopup(true)}
            className="contact-us-footer"
          >
            contact us
          </Button>
        </Typography.Body1>
        {footer && (
          <Typography.Body2 className="terms-links row">
            <TermsOfUseAction />
            <div className="terms-links__separator" />
            <PrivacyAction />
          </Typography.Body2>
        )}
      </footer>
    </Popup>
  )
}

export default SignPopup
