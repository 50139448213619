import { useLayoutEffect, useMemo } from 'react'
import { BTN_TXT } from 'constants/txt'
import { Popup } from 'components/Popup'
import { Typography } from 'components/Typography'
import { Button, BUTTON_TYPES } from 'components/Button'
import { useContractDetailsContext } from 'features/ContractDetails/Providers/ContractDetailsContextProvider'
import { useContractDetailsPopup } from 'features/ContractDetails/Providers/ContractDetailsPopupProvider'
import { CONTRACT_STATUS } from '../../constants'

export default () => {
  const contractDetailsContext = useContractDetailsContext()
  const { declineContractPopup } = useContractDetailsPopup()
  const { updateContractDetailsStatusAsync } = contractDetailsContext.actions

  const { details } = contractDetailsContext.state

  const { setState, close } = declineContractPopup.actions

  const terminateContractHandler = () => {
    updateContractDetailsStatusAsync(CONTRACT_STATUS.DECLINED).finally(() =>
      declineContractPopup.actions.close()
    )
  }

  const Footer = useMemo(
    () => (
      <>
        <Button type={BUTTON_TYPES.DEFAULT} onClick={close} upperCase>
          {BTN_TXT.BACK_TO_DETAILS}
        </Button>
        <Button
          onClick={terminateContractHandler}
          type={BUTTON_TYPES.PRIMARY}
          danger
          upperCase
        >
          {BTN_TXT.DECLINE_CONTRACT}
        </Button>
      </>
    ),
    [close, terminateContractHandler]
  )

  useLayoutEffect(() => {
    setState((prevState) => ({
      ...prevState,
      title: `Decline ${details.name}?`
    }))
  }, [details.name, setState])

  return (
    <Popup {...declineContractPopup.state} width={442} footer={Footer}>
      <Typography.Body1>
        All the contract progress will be stopped. All the related users will be
        notified. Do you really want to decline it?
      </Typography.Body1>
    </Popup>
  )
}
