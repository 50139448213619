import cn from 'classnames'
import { Typography } from 'components/Typography'
import Loader from 'assets/svg/Loader.svg'
import './loader.global.scss'
import { useSelector } from 'react-redux'
import { getIsLoading, getLoaderProps } from '../../redux/store/common/getters'

export const Global = (): JSX.Element | null => {
  const isLoading = useSelector(getIsLoading)
  const loaderProps = useSelector(getLoaderProps)

  return isLoading ? (
    <div
      className={cn('loader-global', {
        'loader-global--smooth': loaderProps?.isFileUpload
      })}
    >
      <div className="loader-global__wrapper">
        <div className="loader-global__spinner">
          <img src={Loader} alt="Loader" />
        </div>
        {loaderProps?.message && (
          <Typography.Headline6 className="loader-global__message">
            {loaderProps.message}
          </Typography.Headline6>
        )}
      </div>
    </div>
  ) : null
}
