import moment from 'moment'

import {
  roundHoursUp,
  mergeTimelineDateTime,
  timelineLabelFormat
} from 'components/Timelines/utils'
import { ITimeline, TTimelines } from 'components/Timelines/types'
import {
  PRS_STATUSES,
  PRS_STATUSES_NAME_TIMERS,
  PRS_STATUSES_TIMERS
} from '../../../pages/ProductRequests/constants'
import {
  IPrsTimeline,
  SetupPrsDetailsTimeRequestData
} from '../PRSDetails/types'

export const timelinesRequestDataConverter = (
  data: ITimeline<typeof PRS_STATUSES_TIMERS>[]
): SetupPrsDetailsTimeRequestData =>
  Object.values(data).map((v) => ({
    status: v.key,
    time_duration: mergeTimelineDateTime(v.date, v.timeLabel).format()
  }))

export const timelinesResponseDataConverter = (
  data: IPrsTimeline[]
): TTimelines<typeof PRS_STATUSES_TIMERS> =>
  data?.reduce((timelineForm, responseTimeline, index) => {
    const { status, time_duration } = responseTimeline

    const timelineDate = roundHoursUp(moment(time_duration))

    return {
      ...timelineForm,
      [status]: {
        order: index,
        key: status,
        title: PRS_STATUSES_NAME_TIMERS[status],
        date: timelineDate,
        timeLabel: timelineLabelFormat(timelineDate),
        hasChanges: false
      }
    }
  }, {} as TTimelines<typeof PRS_STATUSES_TIMERS>)

export const getDisabledPrsTimeline = (keyState, data) => {
  switch (keyState.key) {
    case PRS_STATUSES_TIMERS.OVERALL:
      return false
    case PRS_STATUSES_TIMERS.CLINICAL_SPONSOR_APPROVAL:
      return data.status !== PRS_STATUSES.CLINICAL_SPONSOR_APPROVAL
    case PRS_STATUSES_TIMERS.REVENUE_ASSESSMENT:
      return !(
        (data.status === PRS_STATUSES.CLINICAL_SPONSOR_APPROVAL ||
          data.status === PRS_STATUSES.IN_REVIEW ||
          data.status === PRS_STATUSES.REVENUE_ASSESSMENT) &&
        !data.rca_decision
      )
    case PRS_STATUSES_TIMERS.TRIAL_PRE_APPROVAL:
      return (
        !(
          data.status === PRS_STATUSES.CLINICAL_SPONSOR_APPROVAL ||
          ((data.status === PRS_STATUSES.IN_REVIEW ||
            data.status === PRS_STATUSES.REVENUE_ASSESSMENT) &&
            !data.trial) ||
          data?.trial?.status === PRS_STATUSES.TRIAL_PRE_APPROVAL
        ) || data?.trial?.status === PRS_STATUSES.TRIAL_COMPLETED
      )
    case PRS_STATUSES_TIMERS.TRIAL_REQUESTED:
      return (
        !(
          data.status === PRS_STATUSES.CLINICAL_SPONSOR_APPROVAL ||
          ((data.status === PRS_STATUSES.IN_REVIEW ||
            data.status === PRS_STATUSES.REVENUE_ASSESSMENT) &&
            !data.trial) ||
          data?.trial?.status === PRS_STATUSES.TRIAL_PRE_APPROVAL ||
          data?.trial?.status === PRS_STATUSES.TRIAL_REQUESTED
        ) || data?.trial?.status === PRS_STATUSES.TRIAL_COMPLETED
      )
    case PRS_STATUSES_TIMERS.TRIAL_IN_PROGRESS:
      return (
        !(
          data.status === PRS_STATUSES.CLINICAL_SPONSOR_APPROVAL ||
          ((data.status === PRS_STATUSES.IN_REVIEW ||
            data.status === PRS_STATUSES.REVENUE_ASSESSMENT) &&
            !data.trial) ||
          data?.trial?.status === PRS_STATUSES.TRIAL_PRE_APPROVAL ||
          data?.trial?.status === PRS_STATUSES.TRIAL_REQUESTED ||
          data?.trial?.status === PRS_STATUSES.TRIAL_IN_PROGRESS
        ) || data?.trial?.status === PRS_STATUSES.TRIAL_COMPLETED
      )
    case PRS_STATUSES_TIMERS.SURVEY_CREATION:
      return data.status === PRS_STATUSES.VOTING_IN_PROGRESS
    case PRS_STATUSES_TIMERS.VOTING_IN_PROGRESS:
      return data.status === PRS_STATUSES.DECISION_MAKING
    default:
      return false
  }
}
