import cn from 'classnames'

import './styles.scss'
import { TAttachmentsListWithHistoryProps } from '../types'
import { fileNameFormatter } from 'helper/common'
import { Attachment } from 'components/Attachment/Attachment'
import { FieldWithLabel } from 'components/FieldWithLabel/FieldWithLabel'
import { Container } from 'components/Container/Container'

const WithHistory = (props: TAttachmentsListWithHistoryProps) => {
  const { className, files, label, hideSeparator, ...versionHistoryProps } =
    props

  const lastFile = files[0]
  const hasVersionHistory = files.length > 1

  return (
    <Container.WithHistory
      className={cn(
        'attachments-list',
        {
          'attachments-list--with-separator': !hideSeparator && lastFile
        },
        className
      )}
      hasVersionHistory={hasVersionHistory}
      {...versionHistoryProps}
    >
      <FieldWithLabel title={label}>
        <div className="content">
          {lastFile && (
            <Attachment
              createdAt={lastFile.created_at}
              user={lastFile.creator}
              link={lastFile.file}
              filename={fileNameFormatter(lastFile.file)}
              disableActions
              showDetails
              icon={versionHistoryProps?.icon}
            />
          )}
        </div>
      </FieldWithLabel>
    </Container.WithHistory>
  )
}

export default WithHistory
