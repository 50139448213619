import './style.scss'
import Typography from 'components/Typography/Typography'
import { TYPOGRAPHY_WEIGHT } from 'components/Typography/index'
import { useEffect, useRef, useState } from 'react'
import { useParams } from 'react-router-dom'
import { getTrialStatistics } from '../../../../pages/ProductRequests/api'
import { TStatistics } from './types'
import { CHARTS_COLORS_TXT } from './constants'

const PreApprovalChart = () => {
  const ref = useRef<null | HTMLDivElement>(null)
  const [data, setData] = useState<TStatistics | null>(null)
  const { id } = useParams()

  useEffect(() => {
    if (id) getTrialStatistics(id).then((res) => setData(res?.data))
  }, [id])
  return (
    <div className="trial-pre-approval-chart-wrapper row">
      <div className="trial-pre-approval-chart-wrapper__left column">
        <Typography.Headline5
          className="title"
          weight={TYPOGRAPHY_WEIGHT.SEMI_BOLD}
        >
          Total participants
        </Typography.Headline5>
        <Typography.Headline1
          className="mt-12 amount"
          weight={TYPOGRAPHY_WEIGHT.MEDIUM}
        >
          {data?.total_count}
        </Typography.Headline1>
      </div>
      <div className="trial-pre-approval-chart-wrapper__right" ref={ref}>
        <div
          className="trial-pre-approval-chart-wrapper__right__chart row"
          ref={ref}
        >
          {data &&
            Object.keys(data).map((key) => {
              if (key !== 'total_count' && +data[key] > 0) {
                return (
                  <div
                    className="charts_ongoing-contracts_chart-part"
                    key={key}
                    style={{
                      backgroundColor: CHARTS_COLORS_TXT[key].color,
                      width: `${
                        (+(ref?.current?.offsetWidth || 842) / 100) * +data[key]
                      }px`
                    }}
                  />
                )
              }
            })}
        </div>
        <div className="trial-pre-approval-chart-wrapper__right__agenda row">
          {data &&
            Object.keys(data).map((key) => {
              if (key !== 'total_count' && +data[key] > 0) {
                return (
                  <div
                    className="charts_ongoing-contracts_convention-wrapper"
                    key={key}
                  >
                    <div className="charts_ongoing-contracts_convention">
                      <div className="charts_ongoing-contracts_convention_color-count">
                        <div
                          className="charts_ongoing-contracts_convention_color"
                          style={{
                            backgroundColor: CHARTS_COLORS_TXT[key]?.color
                          }}
                        />
                        <div className="charts_ongoing-contracts_convention_name">
                          {CHARTS_COLORS_TXT[key]?.name}
                        </div>
                        <div className="charts_ongoing-contracts_convention_count">
                          {data[key]}%
                        </div>
                      </div>
                    </div>
                  </div>
                )
              }
            })}
        </div>
      </div>
    </div>
  )
}

export default PreApprovalChart
