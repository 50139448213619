import { TCreatorCell } from './types'
import Typography from '../../components/Typography/Typography'
import { IconFullName } from '../HealthSystemDetails/IconFullName'
import { ROLES_NAMES } from '../../constants'

const CreatorCell = ({ creator }: TCreatorCell) => {
  return (
    <div className="creator-cell">
      <Typography.Body1>{creator.health_system}</Typography.Body1>
      <IconFullName
        className="user-form_community"
        title=""
        key={creator.uuid}
        firstName={creator.first_name}
        lastName={creator.last_name}
        roleName={ROLES_NAMES[creator.role]}
        src={creator?.avatar?.file_preview}
        uuid={creator.uuid}
      />
    </div>
  )
}

export default CreatorCell
