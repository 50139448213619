import { Ability, AbilityBuilder, AbilityClass } from '@casl/ability'

import { IUser } from 'redux/store/user/types'
import { ROLES } from 'features/Permission'
import {
  Conditions,
  ContractAbility,
  ContractAbilityBuilder,
  PossibleAbilities
} from 'features/ContractsPipelineDetails/Abilities/types'
import {
  ACTIONS,
  SUBJECTS
} from 'features/ContractsPipelineDetails/Abilities/constants'
import {
  isCreatorCommons,
  isInCommunity,
  isResponsibleCondition,
  isSameHSCondition,
  isInStatuses
} from './utils'
import { CONTRACT_PIPELINES_STATUS } from '../../../components/Status'

const ContractAbilityClass = Ability as AbilityClass<ContractAbility>

export const ability = new Ability<PossibleAbilities, Conditions>()

export default (user: IUser) => {
  const builder = new AbilityBuilder(ContractAbilityClass)

  switch (user.role) {
    case ROLES.VICE_PRESIDENT:
    case ROLES.CONTRACT_STEWARD:
      defineCommunityAbilities(builder, user)
      break
    case ROLES.COMMONS_VICE_PRESIDENT:
    case ROLES.COMMONS_CONTRACT_STEWARD:
      defineCognusCommonsAbilities(builder, user)
      break
  }

  return new Ability(builder.rules) as ContractAbility
}

const defineCommunityAbilities = (
  builder: ContractAbilityBuilder,
  user: IUser
) => {
  builder.can(ACTIONS.VIEW, SUBJECTS.CONTRACT_PIPELINE)
  if (user.role === ROLES.VICE_PRESIDENT) {
    builder.can(ACTIONS.DELETE, SUBJECTS.HEALTH_SYSTEM, isSameHSCondition(user))
    builder.can(ACTIONS.REASSIGN, SUBJECTS.CONTRACT_PIPELINE, {
      ...isInCommunity(user),
      ...isInStatuses([
        CONTRACT_PIPELINES_STATUS.FUTURE,
        CONTRACT_PIPELINES_STATUS.ACTIVE
      ])
    })
    builder.can(
      ACTIONS.DELETE,
      SUBJECTS.CONTRACT_PIPELINE,
      isResponsibleCondition(user)
    )
    builder.can(ACTIONS.CREATE_RFP, SUBJECTS.CONTRACT_PIPELINE, {
      ...isInCommunity(user),
      ...isSameHSCondition(user)
    })
    builder.can(ACTIONS.VIEW, SUBJECTS.RFP_LINK)
    builder.can(
      ACTIONS.EDIT,
      SUBJECTS.CONTRACT_PIPELINE,
      isResponsibleCondition(user)
    )
  }
  if (user.role === ROLES.CONTRACT_STEWARD) {
    builder.can(
      ACTIONS.DELETE,
      SUBJECTS.CONTRACT_PIPELINE,
      isResponsibleCondition(user)
    )
    builder.can(ACTIONS.VIEW, SUBJECTS.RFP_LINK, isResponsibleCondition(user))
    builder.can(
      ACTIONS.EDIT,
      SUBJECTS.CONTRACT_PIPELINE,
      isResponsibleCondition(user)
    )
    builder.can(
      ACTIONS.DELETE,
      SUBJECTS.HEALTH_SYSTEM,
      isResponsibleCondition(user)
    )
    builder.can(
      ACTIONS.CREATE_RFP,
      SUBJECTS.CONTRACT_PIPELINE,
      isResponsibleCondition(user)
    )
  }
}

const defineCognusCommonsAbilities = (
  builder: ContractAbilityBuilder,
  user: IUser
) => {
  builder.can(ACTIONS.VIEW, SUBJECTS.CONTRACT_PIPELINE)
  if (user.role === ROLES.COMMONS_VICE_PRESIDENT) {
    builder.can(
      ACTIONS.DELETE,
      SUBJECTS.CONTRACT_PIPELINE,
      isResponsibleCondition(user)
    )
    builder.can(ACTIONS.VIEW, SUBJECTS.RFP_LINK)
    builder.can(ACTIONS.REASSIGN, SUBJECTS.CONTRACT_PIPELINE, {
      ...isInStatuses([
        CONTRACT_PIPELINES_STATUS.FUTURE,
        CONTRACT_PIPELINES_STATUS.ACTIVE
      ])
    })
    builder.can(
      ACTIONS.EDIT,
      SUBJECTS.CONTRACT_PIPELINE,
      isResponsibleCondition(user)
    )
    builder.can(ACTIONS.DELETE, SUBJECTS.HEALTH_SYSTEM, isCreatorCommons)
    builder.can(
      ACTIONS.CREATE_RFP,
      SUBJECTS.CONTRACT_PIPELINE,
      isCreatorCommons
    )
  }
  if (user.role === ROLES.COMMONS_CONTRACT_STEWARD) {
    builder.can(
      ACTIONS.DELETE,
      SUBJECTS.CONTRACT_PIPELINE,
      isResponsibleCondition(user)
    )
    builder.can(ACTIONS.VIEW, SUBJECTS.RFP_LINK, isResponsibleCondition(user))
    builder.can(
      ACTIONS.EDIT,
      SUBJECTS.CONTRACT_PIPELINE,
      isResponsibleCondition(user)
    )
    builder.can(
      ACTIONS.DELETE,
      SUBJECTS.HEALTH_SYSTEM,
      isResponsibleCondition(user)
    )
    builder.can(
      ACTIONS.CREATE_RFP,
      SUBJECTS.CONTRACT_PIPELINE,
      isResponsibleCondition(user)
    )
  }
}
