import { useCallback, useMemo } from 'react'
import { Radio, RadioChangeEvent } from 'antd'
import cn from 'classnames'

import { Typography } from 'components/Typography/index'
import { Props as BaseProps } from 'components/RadioGroup/types'

import './radioGroup.ratingRange.scss'

type Props = {
  range: number
  onRateChange: (rate: number) => void
  rate?: number
  leastLabel?: string
  mostLabel?: string
  disabled?: boolean
  invalid?: boolean
} & Omit<BaseProps, 'options'>

const RatingRange = ({
  className,
  leastLabel,
  mostLabel,
  onRateChange,
  rate,
  range,
  disabled,
  invalid,
  ...props
}: Props) => {
  const rangeArray = useMemo(
    () => Array.from({ length: range }, (_, i) => i + 1),
    [range]
  )

  const handleChange = useCallback(
    (e: RadioChangeEvent) => {
      onRateChange(e.target.value)
    },
    [onRateChange]
  )

  return (
    <div className="radio-group-rating-range">
      {leastLabel && <Typography.Body1>{leastLabel}</Typography.Body1>}
      <Radio.Group
        className={cn('radio-group-range', className)}
        size="large"
        {...props}
        value={rate}
        onChange={handleChange}
      >
        {rangeArray.map((rate, index) => (
          <div key={index} className="radio-group-rate">
            <label htmlFor={`radio-${rate}`}>
              <Typography.Body2>{rate}</Typography.Body2>
            </label>
            <Radio
              className={invalid ? 'radio-group__radio--error' : ''}
              id={`radio-${rate}`}
              disabled={disabled}
              value={rate}
            />
          </div>
        ))}
      </Radio.Group>
      {mostLabel && <Typography.Body1>{mostLabel}</Typography.Body1>}
    </div>
  )
}

export default RatingRange
