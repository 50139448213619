import { notification } from 'antd'
import { ReactComponent as SuccessIcon } from 'assets/svg/SuccessIcon.svg'
import { TNotification } from './types'
import './notification.success.scss'
import { ReactComponent as Close24 } from '../../assets/svg/Close24.svg'

export const Success = ({
  description,
  duration = 5,
  top = 40,
  message,
  placement = 'topRight'
}: TNotification): void => {
  notification.success({
    placement,
    top,
    icon: <SuccessIcon />,
    duration,
    message,
    description,
    closeIcon: <Close24 />
  })
}
