import { ColumnsType } from 'antd/es/table'
import {
  TNonPOInvoice,
  TNonPOInvoiceFilters,
  TNonPOInvoiceFiltersFunc,
  TNonPOInvoicesFiltersFunc
} from './types'
import { Datepicker } from '../../../components/Datepicker'
import { ReactComponent as FilterApplied16 } from '../../../assets/svg/FilterApplied16.svg'
import { ReactComponent as Filter16 } from '../../../assets/svg/Filter16.svg'
import { Sorter } from '../../../components/Sorter/Sorter'
import { SortOrder } from 'antd/es/table/interface'
import CreatorCell from '../CreatorCell'
import { Dropdown } from '../../../components/Dropdown'
import moment from 'moment'
import { timelineDateFormat } from '../../../components/Timelines/constants'
import {
  getNonPOInvoicesCreatorOptions,
  getNonPOInvoicesHospitalsOptions
} from './api'
import { currencyFormatter } from '../../ContractDetails/ContractDetailsGeneral/ContractDetailsForm/utils'

export const columns = ({
  applyFilters,
  filters
}: {
  applyFilters: TNonPOInvoicesFiltersFunc
  filters: TNonPOInvoiceFilters | undefined
}): ColumnsType<TNonPOInvoice> => {
  return [
    {
      dataIndex: 'document_key',
      key: 'document_key',
      width: 160,
      title: 'Document key',
      className: 'vertical-align-middle'
    },
    {
      dataIndex: 'ship_to_entity_hospital',
      key: 'ship_to_entity_hospital',
      width: 330,
      className: 'vertical-align-middle',
      sorter: true,
      title: ({ sortOrder, sortColumn }) => (
        <Sorter<TNonPOInvoice>
          dataIndex="ship_to_entity_hospital"
          sortColumn={sortColumn}
          sortOrder={sortOrder as SortOrder}
        >
          Ship to Entity (Hospital)
        </Sorter>
      ),
      showSorterTooltip: false,
      filterDropdown: (props) => {
        return (
          <Dropdown.Filter<
            TNonPOInvoiceFilters,
            TNonPOInvoicesFiltersFunc,
            unknown
          >
            searchCallback={getNonPOInvoicesHospitalsOptions}
            applyFilters={applyFilters}
            appliedFilters={filters}
            field="ship_to_entity_hospital"
            asyncSearch
            {...props}
          />
        )
      },
      filterIcon: !!filters?.ship_to_entity_hospital?.length ? (
        <FilterApplied16 />
      ) : (
        <Filter16 />
      ),
      filterSearch: true
    },
    {
      dataIndex: 'invoice_date',
      key: 'invoice_date',
      width: 160,
      title: ({ sortOrder, sortColumn }) => (
        <Sorter<TNonPOInvoice>
          dataIndex="invoice_date"
          sortColumn={sortColumn}
          sortOrder={sortOrder as SortOrder}
        >
          Invoice Date
        </Sorter>
      ),
      className: 'vertical-align-middle',
      sorter: true,
      showSorterTooltip: false,
      filterIcon: !!filters?.invoice_date?.length ? (
        <FilterApplied16 />
      ) : (
        <Filter16 />
      ),
      render: (_, { invoice_date }) =>
        invoice_date ? moment(invoice_date).format(timelineDateFormat) : '-',
      filterSearch: true,
      filterDropdown: (props) => {
        return (
          <div className="filter-dropdown-table-range">
            <Datepicker.Filter<
              TNonPOInvoiceFilters,
              TNonPOInvoiceFiltersFunc,
              unknown
            >
              applyFilters={applyFilters}
              appliedFilters={filters}
              field="invoice_date"
              {...props}
              prefixCls="range-picker-custom ant-picker"
              open={props.visible}
              rangeClassName="filter-dropdown-table-range-picker"
              placement="bottomLeft"
            />
          </div>
        )
      }
    },
    {
      dataIndex: 'creator',
      className: 'vertical-align-middle',
      key: 'creator',
      width: 504,
      title: ({ sortOrder, sortColumn }) => (
        <Sorter<TNonPOInvoice>
          dataIndex="creator"
          sortColumn={sortColumn}
          sortOrder={sortOrder as SortOrder}
        >
          Uploaded by
        </Sorter>
      ),
      render: (_, { creator }) => {
        return <CreatorCell creator={creator} />
      },
      filterDropdown: (props) => {
        return (
          <Dropdown.Filter<
            TNonPOInvoiceFilters,
            TNonPOInvoicesFiltersFunc,
            unknown
          >
            searchCallback={getNonPOInvoicesCreatorOptions}
            applyFilters={applyFilters}
            appliedFilters={filters}
            field="creator"
            asyncSearch
            optionFormatter={(option) => ({
              value: option.uuid,
              label: option.value
            })}
            {...props}
          />
        )
      },
      filterIcon: !!filters?.creator?.length ? (
        <FilterApplied16 />
      ) : (
        <Filter16 />
      ),
      filterSearch: true,
      sorter: true,
      showSorterTooltip: false
    },
    {
      dataIndex: 'invoice_amount',
      key: 'invoice_amount',
      className: 'vertical-align-middle',
      width: 135,
      sorter: true,
      title: ({ sortOrder, sortColumn }) => (
        <Sorter<TNonPOInvoice>
          dataIndex="invoice_amount"
          sortColumn={sortColumn}
          sortOrder={sortOrder as SortOrder}
        >
          Invoice Amount, $
        </Sorter>
      ),
      showSorterTooltip: false,
      render: (_, { invoice_amount }) => currencyFormatter(invoice_amount)
    }
  ]
}
