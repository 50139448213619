import cn from 'classnames'
import { ReactComponent as ArrowForwardIcon } from 'assets/svg/ArrowForward.svg'
import { Breadcrumb as BreadcrumbAntd } from 'antd'
import { TBreadcrumb } from './types'
import './styles.scss'
import { NavLink } from 'react-router-dom'

const Breadcrumb = ({ ...props }: TBreadcrumb) => (
  <BreadcrumbAntd
    className={cn('breadcrumb-default', props.className)}
    separator={<ArrowForwardIcon />}
  >
    {props.items.map((item, ind) => {
      if (item?.href) {
        return (
          <BreadcrumbAntd.Item key={ind}>
            <NavLink to={item.href} state={item.state} key={ind}>
              {item.children}
            </NavLink>
          </BreadcrumbAntd.Item>
        )
      }
      return (
        <BreadcrumbAntd.Item
          className="breadcrumb-current"
          children={item.children}
          key={ind}
        />
      )
    })}
  </BreadcrumbAntd>
)

//[NOTE] if it's needed export other types of breadcrubs like Breadcrubs.Smth here

export default Breadcrumb
