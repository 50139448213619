import { useCallback, useState } from 'react'
import { Form } from 'antd'
import { notification } from 'components/Notification'
import { VALIDATION_MESSAGES } from '../../../../constants'
import { TFormClinicalReview } from '../types'
import { TClinicalReviewSetDataList } from '../../../../pages/ClinicalReview/types'
import { useClinicalReviewPopup } from '../../../../pages/ClinicalReview/Providers/useClinicalReviewPopup'
const useForm = (
  setClinicalReviewForStakeholder: (values: TClinicalReviewSetDataList) => void
) => {
  const [form] = Form.useForm()
  const { submitClinicalReviewPopup } = useClinicalReviewPopup()
  const [hasFormChanges, setHasFormChanges] = useState(false)
  const [isActive, setIsActive] = useState(false)
  const [isSubmit, setIsSubmit] = useState(false)
  const triggerHasFormChanges = useCallback(() => {
    if (!hasFormChanges) {
      setHasFormChanges(true)
    }
  }, [hasFormChanges])

  const handleOpenSubmitModal = () => {
    submitClinicalReviewPopup.actions.open()
  }

  const handleFinishForm = useCallback(
    async (values: TFormClinicalReview) => {
      setIsActive(false)
      if (values.results.find((value) => value.decision === undefined)) {
        notification.error({ message: VALIDATION_MESSAGES.SM0039 })
      } else {
        if (isSubmit) {
          setHasFormChanges(false)
          const dataForRequest = values.results as TClinicalReviewSetDataList
          await setClinicalReviewForStakeholder(dataForRequest)
        } else {
          handleOpenSubmitModal()
          setIsSubmit(true)
        }
      }
    },
    [isSubmit, form, setClinicalReviewForStakeholder]
  )

  const handleCancelForm = useCallback(() => {
    form.resetFields()
    setIsActive(false)
    setHasFormChanges(false)
  }, [form])

  const handleChangeForm = useCallback(() => {
    triggerHasFormChanges()
  }, [triggerHasFormChanges])

  return {
    form,
    hasFormChanges,
    handleFinishForm,
    handleCancelForm,
    handleChangeForm,
    isActive,
    setIsActive,
    setIsSubmit
  }
}

export default useForm
