import { Forms } from '../../../../components/Forms'
import { usePRSDetailsContext } from '../Providers/PRSDetailsContextProvider'
import { Button, BUTTON_TYPES } from '../../../../components/Button'
import { BTN_TXT } from '../../../../constants'
import useRouter from '../../../../hooks/useRouter'
import { routes } from '../../../../router'
import ParticipantsDetails from './Details'
import './style.scss'
import ClinicalRationale from './ClinicalRationale'

const Participants = () => {
  const { state, actions } = usePRSDetailsContext()
  const {
    data,
    form,
    isSaveDisabled,
    isNew,
    isCanEdit,
    isAbleToEditCommunityResp
  } = state
  const { handleGetPRSData, onValueChange, setShowButtons } = actions

  const { push } = useRouter()

  const cancelHandler = () => {
    push(routes.productRequests)
  }
  return (
    <div className="prs-details-participants">
      {isCanEdit || isNew ? (
        <Forms.PRSParticipants
          form={form}
          data={data}
          callback={handleGetPRSData}
          onValueChange={onValueChange}
          setShowButtons={setShowButtons}
        />
      ) : (
        <ParticipantsDetails />
      )}
      <ClinicalRationale />
      {(isCanEdit || isNew || isAbleToEditCommunityResp) && (
        <div className="rfp-details-general__buttons mt-32">
          <Button onClick={cancelHandler} upperCase>
            {BTN_TXT.CANCEL}
          </Button>
          <Button
            type={BUTTON_TYPES.PRIMARY}
            disabled={isSaveDisabled}
            form={
              isAbleToEditCommunityResp
                ? 'hs-participants-form'
                : 'prs-participants'
            }
            htmlType="submit"
            upperCase
          >
            {BTN_TXT.SAVE}
          </Button>
        </div>
      )}
    </div>
  )
}

export default Participants
