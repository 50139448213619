import { TDashboardParams } from '../types'
import '../styles.scss'
import { Container } from 'components/Container/Container'
import { Menu } from 'components/Menu/Menu'
import { useState } from 'react'
import { DASHBOARD_TABS } from '../constants'
import { DashboardTable } from './DashboardTable'

export const Expirings = ({ healthSystem, user }: TDashboardParams) => {
  const [activeTab, setActiveTab] = useState('contracts')

  return (
    <>
      <div className="charts_title">Expirings</div>
      <div>
        <Container
          className="rfp-create-survey"
          menu={
            <Menu
              mode="horizontal"
              onSelect={(e) => {
                setActiveTab(e.key)
              }}
              defaultSelectedKeys={[activeTab]}
              items={DASHBOARD_TABS}
            />
          }
        >
          <DashboardTable
            activeTab={activeTab}
            healthSystem={healthSystem}
            user={user}
            collapsingTopMargin={true}
          />
        </Container>
      </div>
    </>
  )
}
