import { isValidUrl } from 'helper/isURLValid'
import { TLinkHighlighterText } from './types'
import { Link } from '../Link'

export const LinkHighlighter = ({
  text,
  linkClassName
}: TLinkHighlighterText) => {
  const onLinkClick = (url: string) => {
    window.open(url, '_blank', 'noreferrer')
  }

  if (isValidUrl(text))
    return (
      <Link to={''} className={linkClassName} onClick={() => onLinkClick(text)}>
        {text}
      </Link>
    )

  return <>{text}</>
}
