import { ReactComponent as SortingDefault16 } from 'assets/svg/SortingDefault16.svg'
import { ReactComponent as SortingUp16 } from 'assets/svg/SortingUp16.svg'
import { ReactComponent as SortingDown16 } from 'assets/svg/SortingDown16.svg'
import { TSorter } from './types'
import './styles.scss'

export const Sorter = <T,>({
  sortOrder,
  sortColumn,
  dataIndex,
  children
}: TSorter<T>) => (
  <>
    <span className="table-sorter-title">{children}</span>
    <div tabIndex={0} className="table-sorter">
      {dataIndex === sortColumn?.dataIndex ? (
        sortOrder === 'descend' ? (
          <SortingDown16 />
        ) : sortOrder === 'ascend' ? (
          <SortingUp16 />
        ) : (
          <SortingDefault16 />
        )
      ) : (
        <SortingDefault16 />
      )}
    </div>
  </>
)
