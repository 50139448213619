import { Container } from 'components/Container/Container'
import { useContractPageContext } from './Providers/ContractPageContextProvider'
import { useEffect, useMemo } from 'react'
import { Menu } from '../../components/Menu/Menu'
import { useParams } from 'react-router-dom'
import { safeString } from '../../helper/common'
import useRouter from '../../hooks/useRouter'
import ContractPageRouter from './ContractPageRouter'
import { routes } from '../../router'
import { contractPageRoute } from './constants'

const ContractsContainer = () => {
  const { state, actions } = useContractPageContext()
  const { push, query } = useRouter()
  const { isCanSeeMyContracts, activeTab, tabs } = state
  const { setActiveTab } = actions
  const params = useParams()
  const { tab } = params

  const RenderedMenu = useMemo(() => {
    const handleSelectTab = (e) => {
      setActiveTab(e.key)
      push(routes.contracts + '/' + e.key)
    }

    return (
      <Menu
        mode="horizontal"
        onSelect={handleSelectTab}
        selectedKeys={[activeTab]}
        defaultSelectedKeys={[activeTab]}
        items={tabs}
      />
    )
  }, [activeTab, push, setActiveTab, tabs])
  useEffect(() => {
    if (isCanSeeMyContracts) {
      if (!query?.type) setActiveTab(safeString(tab || 'my_contracts'))
      else setActiveTab(safeString(query?.type))
    }
  }, [tab])

  useEffect(() => {
    if (!!params?.type?.length) {
      setActiveTab(safeString(params?.type))
    } else {
      setActiveTab(contractPageRoute.contracts)
    }
  }, [params?.type])

  useEffect(() => {
    if (isCanSeeMyContracts && !query?.type) {
      push(routes.contracts + '/my_contracts')
    }
  }, [])

  return (
    <Container
      title="Contracts"
      menu={!isCanSeeMyContracts ? null : RenderedMenu}
    >
      <ContractPageRouter />
    </Container>
  )
}

export default ContractsContainer
