/* eslint-disable no-param-reassign */
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { moduleName } from './constants'
import { ITemplateState, TTemplate } from './types'

const initialState: ITemplateState = {
  data: [],
  isFetching: false,
  isLoading: false,
  error: null
}

const slice = createSlice({
  name: moduleName,
  initialState,
  reducers: {
    loading(state: ITemplateState) {
      state.isLoading = true
      state.error = null
    },
    fetching(state: ITemplateState) {
      state.isFetching = true
      state.error = null
    },
    finish(state: ITemplateState) {
      state.isLoading = false
      state.isFetching = false
    },
    error(
      state: ITemplateState,
      action: PayloadAction<{ error: ITemplateState['error'] }>
    ) {
      const { error } = action.payload
      state.error = error
    },
    set(state: ITemplateState, action: PayloadAction<{ data: TTemplate[] }>) {
      const { data } = action.payload
      state.data = data
    },
    add(state: ITemplateState, action: PayloadAction<{ data: TTemplate }>) {
      const { data } = action.payload
      state.data.push(data)
    },
    update(state: ITemplateState, action: PayloadAction<{ data: TTemplate }>) {
      const { data } = action.payload
      state.data = state.data.map((i) =>
        i.uuid === data.uuid ? { ...i, ...data } : i
      )
    },
    remove(state: ITemplateState, action: PayloadAction<{ uuid: string }>) {
      const { uuid } = action.payload
      state.data = state.data.filter((i) => i.uuid !== uuid)
    },
    reset(state: ITemplateState) {
      Object.keys(initialState).forEach((key) => {
        state[key] = initialState[key]
      })
    }
  }
})

export default slice.reducer
export const { loading, finish, error, set, add, update, remove, reset } =
  slice.actions
