import { FC } from 'react'

import { Button, BUTTON_TYPES } from 'components/Button'
import { ReactComponent as Send16 } from 'assets/svg/Send16.svg'

type Props = {
  disabled: boolean
}

const Send: FC<Props> = ({ disabled }) => (
  <Button.Icon
    htmlType="submit"
    form="chat-message-input"
    className="chat-input__send"
    type={BUTTON_TYPES.PRIMARY}
    disabled={disabled}
    icon={<Send16 />}
  />
)

export default Send
