import { ColumnsType } from 'antd/es/table'
import { SortOrder } from 'antd/es/table/interface'
import { Dropdown } from 'components/Dropdown'
import { CellWithSubtitle } from 'components/CellWithSubtitle/CellWithSubtitle'
import { Sorter } from 'components/Sorter/Sorter'
import { ReactComponent as Filter16 } from 'assets/svg/Filter16.svg'
import { ReactComponent as FilterApplied16 } from 'assets/svg/FilterApplied16.svg'
import { fetchContractCategories } from 'pages/CRUDUser/api'
import {
  TContractPipelineList,
  TContractPipelineListColumnsFunc,
  TContractPipelineListFilters,
  TContractPipelineListFiltersFunc
} from './types'
import { formatOptionPortfolio } from '../../../helper/optionsFormatters'
import moment from 'moment'
import { timelineDateFormat } from '../../../components/Timelines/constants'
import { LeadByIndicator } from '../../../components/LeadByIndicator'
import {
  CONTARCT_PIPELINES_STATUS_TYPES_TO_CLASSNAME,
  CONTRACT_PIPELINES_STATUS_NAME,
  Status
} from '../../../components/Status'
import { Link } from '../../../components/Link'
import { routes } from '../../../router'
import JoinedColumn from './JoinedColumn'

export const columns = ({
  applyFilters,
  filters,
  isCommunity,
  handelModal,
  userHs
}: TContractPipelineListColumnsFunc): ColumnsType<TContractPipelineList> => {
  const _columns: ColumnsType<TContractPipelineList> = [
    {
      dataIndex: 'contract_category_name',
      key: 'contract_category_name',
      width: 300,
      title: ({ sortOrder, sortColumn }) => (
        <Sorter<TContractPipelineList>
          dataIndex="contract_category_name"
          sortColumn={sortColumn}
          sortOrder={sortOrder as SortOrder}
        >
          Contract pipeline name
        </Sorter>
      ),
      render: (_, { contract_category_name, contract_pipeline_id, uuid }) => (
        <CellWithSubtitle
          title={
            <Link to={`${routes.contracts}${routes.contractPipeline}/${uuid}`}>
              {contract_category_name}
            </Link>
          }
          subtitle={contract_pipeline_id}
        />
      ),
      sorter: true,
      showSorterTooltip: false
    }
  ]
  if (isCommunity) {
    _columns.push({
      dataIndex: 'joined',
      key: 'joined',
      width: 98,
      title: 'Join',
      render: (_, pipeline) => (
        <JoinedColumn
          pipeline={pipeline}
          key={pipeline.uuid}
          handelModal={handelModal}
          userHs={userHs}
        />
      ),
      sorter: true,
      showSorterTooltip: false
    })
  }

  _columns.push(
    {
      dataIndex: 'led_by',
      key: 'led_by',
      width: 98,
      title: ({ sortOrder, sortColumn }) => (
        <Sorter<TContractPipelineList>
          dataIndex="led_by"
          sortColumn={sortColumn}
          sortOrder={sortOrder as SortOrder}
        >
          Led by
        </Sorter>
      ),
      render: (led_by) => <LeadByIndicator value={led_by} />,
      sorter: true,
      showSorterTooltip: false
    },
    {
      dataIndex: 'portfolio',
      key: 'portfolio',
      width: 143,
      title: ({ sortOrder, sortColumn }) => (
        <Sorter<TContractPipelineList>
          dataIndex={'portfolio'}
          sortColumn={sortColumn}
          sortOrder={sortOrder as SortOrder}
        >
          Portfolio
        </Sorter>
      ),
      filterDropdown: (props) => (
        <Dropdown.Filter<
          TContractPipelineListFilters,
          TContractPipelineListFiltersFunc,
          unknown
        >
          applyFilters={applyFilters}
          appliedFilters={filters}
          searchCallback={fetchContractCategories}
          optionFormatter={formatOptionPortfolio}
          field={'portfolio'}
          asyncSearch
          formatCallback={(data: { value: string; label: string }[]) => {
            const newData = Array.from(
              new Set(
                data
                  .filter((i) => i.value && i.label)
                  .map((i) => JSON.stringify(i))
              )
            )
            return newData.map((i) => JSON.parse(i))
          }}
          {...props}
        />
      ),
      filterIcon:
        !!filters?.portfolio?.length || !!filters?.portfolio?.length ? (
          <FilterApplied16 />
        ) : (
          <Filter16 />
        ),
      filterSearch: true,
      sorter: true,
      showSorterTooltip: false
    },
    {
      dataIndex: 'contract_category_name',
      key: 'contract_category_name',
      width: 234,
      title: ({ sortOrder, sortColumn }) => (
        <Sorter<TContractPipelineList>
          dataIndex={'contract_category_name'}
          sortColumn={sortColumn}
          sortOrder={sortOrder as SortOrder}
        >
          Contract category
        </Sorter>
      ),
      filterDropdown: (props) => (
        <Dropdown.Filter<
          TContractPipelineListFilters,
          TContractPipelineListFiltersFunc,
          unknown
        >
          applyFilters={applyFilters}
          appliedFilters={filters}
          searchCallback={fetchContractCategories}
          field="contract_category"
          asyncSearch
          {...props}
        />
      ),
      render: (_, { contract_category_name }) => (
        <CellWithSubtitle title={contract_category_name} />
      ),
      filterIcon: !!filters?.contract_category?.length ? (
        <FilterApplied16 />
      ) : (
        <Filter16 />
      ),
      filterSearch: true,
      sorter: true,
      showSorterTooltip: false
    },
    {
      dataIndex: 'responsible',
      key: 'responsible',
      title: 'Responsible',
      width: 184,
      render: (_, { responsible_name }) => (
        <span className="separate-item">
          {responsible_name}
          <br />
        </span>
      )
    },
    {
      dataIndex: 'current_vendor',
      key: 'current_vendor',
      title: 'Current vendor',
      width: 233,
      render: (_, { vendor_name }) => (
        <span className="separate-item">{vendor_name || '-'}</span>
      )
    },
    {
      dataIndex: 'bid_start_date',
      key: 'bid_start_date',
      width: 158,
      title: ({ sortOrder, sortColumn }) => (
        <Sorter<TContractPipelineList>
          dataIndex="bid_start_date"
          sortColumn={sortColumn}
          sortOrder={sortOrder as SortOrder}
        >
          Estimated bid start date
        </Sorter>
      ),
      render: (_, { bid_start_date }) => (
        <span className="separate-item">
          {bid_start_date
            ? moment(bid_start_date).format(timelineDateFormat)
            : '-'}
          <br />
        </span>
      ),
      sorter: true,
      showSorterTooltip: false
    },
    {
      dataIndex: 'expiration_date',
      key: 'expiration_date',
      title: 'Expiration date',
      width: 128,
      render: (_, { expiration_date }) => (
        <span className="separate-item">
          {expiration_date
            ? moment(expiration_date).format(timelineDateFormat)
            : '-'}
          <br />
        </span>
      ),
      showSorterTooltip: false
    },
    {
      dataIndex: 'estimated_savings',
      key: 'estimated_savings',
      title: 'Estimated savings',
      width: 86,
      render: (_, row) => (
        <span className="separate-item">
          <CellWithSubtitle
            title={
              row.estimated_savings !== null ? row.estimated_savings + '%' : '-'
            }
          />
        </span>
      )
    },
    {
      dataIndex: 'rebates',
      key: 'rebates',
      width: 71,
      title: 'Rebates',
      render: (_, { rebates }) => (
        <CellWithSubtitle title={!rebates ? 'No' : 'Yes'} />
      ),
      showSorterTooltip: false
    },
    {
      dataIndex: 'participants',
      key: 'participants',
      width: 94,
      title: 'Participants',
      render: (_, { count_of_joined_health_systems }) => (
        <CellWithSubtitle title={count_of_joined_health_systems} />
      ),
      showSorterTooltip: false
    },
    {
      dataIndex: 'status',
      key: 'status',
      title: 'Status',
      width: 120,
      render: (_, { status }) => (
        <Status.Default
          className={CONTARCT_PIPELINES_STATUS_TYPES_TO_CLASSNAME[status]}
        >
          {CONTRACT_PIPELINES_STATUS_NAME[status]}
        </Status.Default>
      ),
      showSorterTooltip: false
    }
  )

  return _columns
}
