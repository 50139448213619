import Fetcher from '../../../services/Fetcher'
import { API_DICTIONARY, HTTP_METHODS } from '../../../constants'
import {
  ExportRejectedInvoicesRequest,
  ExportRejectedInvoicesResponse,
  RejectedInvoiceLine,
  RejectedInvoicesRequest,
  RejectedInvoicesResponse,
  StatusRejectedInvoicesRequest,
  StatusRejectedInvoicesResponse
} from './types'

const fetcher = new Fetcher({})

export const getRejectedInvoices: RejectedInvoicesRequest = async (data) =>
  fetcher.request<unknown, RejectedInvoicesResponse>({
    url: `${API_DICTIONARY.FINANCES}${API_DICTIONARY.INVOICES}${API_DICTIONARY.REJECTED}`,
    method: HTTP_METHODS.GET,
    ...data
  })

export const exportRejectedInvoices: ExportRejectedInvoicesRequest = async (
  data
) =>
  fetcher.request<unknown, ExportRejectedInvoicesResponse>({
    url: `${API_DICTIONARY.FINANCES}${API_DICTIONARY.INVOICES}${API_DICTIONARY.REJECTED}${API_DICTIONARY.DOWNLOAD}`,
    method: HTTP_METHODS.POST,
    data
  })

export const getCurrentStatusRejectedInvoices: StatusRejectedInvoicesRequest =
  async ({ task_id }) =>
    fetcher.request<unknown, StatusRejectedInvoicesResponse>({
      url: `${API_DICTIONARY.FINANCES}${API_DICTIONARY.INVOICES}${API_DICTIONARY.REJECTED}${API_DICTIONARY.DOWNLOAD}${API_DICTIONARY.STATUS}${task_id}/`,
      method: HTTP_METHODS.GET
    })

export const sendEditedLines = async (lines: RejectedInvoiceLine[]) =>
  fetcher.request<unknown, unknown>({
    url: `${API_DICTIONARY.FINANCES}${API_DICTIONARY.INVOICES}${API_DICTIONARY.REJECTED}`,
    method: HTTP_METHODS.POST,
    successMessage: 'Invoice data has been updated',
    data: {
      lines
    }
  })
