import { TBidResend } from './types'
import Typography from '../../../components/Typography/Typography'
import { capitalizeFirstLetter } from '../../../utils/common'
import { InfoLine } from '../../../components/InfoLine/InfoLine'

export const BidResendReason = ({ bidResend }: { bidResend?: TBidResend }) => {
  if (!bidResend) return null

  const { first_name, last_name } = bidResend.creator

  return (
    <InfoLine text="The bid has been resent" className="rfp-resend-bid-reason">
      <Typography.Caption className="rfp-resend-bid-reason__content__text mt-8">
        {capitalizeFirstLetter(first_name)} {capitalizeFirstLetter(last_name)}{' '}
        has resent the bid for the following reason: {bidResend.reason.value}.
      </Typography.Caption>
    </InfoLine>
  )
}
