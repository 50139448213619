import { TCalendarEventDetails } from '../../../pages/Calendar/types'
import { initialStateAddEventForm } from './constants'
import moment, { Moment } from 'moment'
import { TAddEventFormType } from './types'
import { TDropdownItem } from '../../../components/Dropdown/types'
import { TOption } from '../../../components/Select/types'

export const getInitialValues = (
  data: TCalendarEventDetails | null,
  offset: string
) => {
  if (!data) return initialStateAddEventForm(offset)

  const startDate = transformTimeWithoutOffset(
    data.start_date,
    data.start_time,
    offset
  )
  const endDate = transformTimeWithoutOffset(
    data.end_date,
    data.end_time,
    offset
  )
  return {
    name: data.name,
    event_type:
      data?.event_type?.id === 'other'
        ? data?.event_type?.value
        : data?.event_type?.id,
    location: data.location,
    start_date: startDate.date,
    start_time: startDate.time,
    end_date: endDate.date,
    end_time: endDate.time,
    participants: data.participants.map((user) => ({
      key: user.uuid,
      value: user.uuid,
      label: {
        props: {
          firstName: user.first_name,
          lastName: user.last_name,
          roleName: user.role,
          src: user.avatar?.file_preview || null,
          healthSystemName: user.health_system
        }
      }
    })),
    summary: data.summary,
    uuid: data.uuid
  }
}

export const parseDataForEvent = (
  values: TAddEventFormType,
  typesSelectOptions: TOption[],
  offset: string
) => {
  const startDate = transformTimeWithOffset(
    values.start_date,
    values.start_time,
    offset
  )
  const endDate = transformTimeWithOffset(
    values.end_date,
    values.end_time,
    offset
  )

  const resultedData = {
    ...values,
    start_date: startDate.date,
    end_date: endDate.date,
    participants: values.participants.map((item: TDropdownItem) => item?.value),
    start_time: startDate.time,
    end_time: endDate.time,
    location: !!values.location ? values.location : null,
    summary: !!values.summary ? values.summary : null
  }
  const type = typesSelectOptions.find(
    (option) => option.value === values.event_type
  )?.label
  if (values.event_type === 'other' || !type) {
    resultedData['event_type_other_value'] =
      typesSelectOptions.find((option) => option.value === 'other')?.label || ''
    resultedData['event_type'] = 'other'
  }
  return resultedData
}

export const transformTimeWithOffset = (
  dateString: Moment | null,
  timeString: string | null,
  offset: string
) => {
  if (!dateString || !timeString) return { date: '', time: '' }
  const timeStringFull = moment(timeString, 'hh:mm A').format('HH:mm')
  const date = moment(dateString).format('YYYY-MM-DD')
  const resultedDate = moment(
    date + ' ' + timeStringFull + ' ' + offset,
    'YYYY-MM-DD HH:mm Z'
  ).utc()

  return {
    time: resultedDate.format('HH:mm'),
    date: resultedDate.format('YYYY-MM-DD')
  }
}

export const transformTimeWithoutOffset = (
  dateString: string,
  timeString: string,
  offset: string
) => {
  if (!dateString || !timeString) return { date: '', time: '' }
  const resultedDate = moment(
    dateString + ' ' + timeString + ' +00:00',
    'YYYY-MM-DD HH:mm:ss Z'
  ).zone(offset)

  return {
    time: resultedDate.format('hh:mm A'),
    date: resultedDate,
    dateFormatted: resultedDate.format('YYYY-MM-DD')
  }
}
