import { useEffect, useState } from 'react'
import { getTableMetadata } from '../api'
import { Sorter } from '../../../components/Sorter/Sorter'
import { SortOrder } from 'antd/es/table/interface'
import { ColumnsType } from 'antd/es/table'
import { TContractItemsListRecord } from '../types'

export const useColumns = (
  uuid: string,
  tableData: TContractItemsListRecord[]
) => {
  const [columns, setColumns] = useState<ColumnsType<any>>([])

  const setColumnsFromRequest = async () => {
    if (!uuid) return
    getTableMetadata(uuid).then((resp) => {
      if (!resp.data?.results) {
        return
      }
      setColumns(
        resp.data.results.map((i) => ({
          dataIndex: i.key,
          key: i.key,
          title: ({ sortOrder, sortColumn }) => (
            <Sorter<TContractItemsListRecord>
              dataIndex={i.key}
              sortColumn={sortColumn}
              sortOrder={sortOrder as SortOrder}
            >
              {i.value}
            </Sorter>
          ),
          sorter: true,
          showSorterTooltip: false
        }))
      )
    })
  }
  useEffect(() => {
    setColumnsFromRequest()
  }, [uuid, tableData])

  return {
    columns
  }
}
