import { Typography } from '../../../components/Typography'
import { ReactComponent as EmptyBox } from 'assets/svg/EmptyBox.svg'

export const EmptyState = ({
  text,
  showIcon
}: {
  text: string
  showIcon?: boolean
}) => {
  return (
    <div className="check-ins-container__list-empty flex justify-center align-center">
      {showIcon && (
        <EmptyBox className="check-ins-container__list-empty__icon" />
      )}
      <Typography.Headline6>{text}</Typography.Headline6>
    </div>
  )
}
