import { useContractDetailsContext } from '../../Providers/ContractDetailsContextProvider'
import { useContractDetailsPopup } from '../../Providers/ContractDetailsPopupProvider'
import { Can } from '../../Providers/ContractDetailsAbilityProvider'
import { ACTIONS, contractSubject, SUBJECTS } from '../../Abilities'
import { Button, BUTTON_TYPES } from '../../../../components/Button'
import { BTN_TXT } from '../../../../constants'

export default () => {
  const contractDetailsContext = useContractDetailsContext()
  const { declineContractPopup } = useContractDetailsPopup()

  const { details } = contractDetailsContext.state

  return (
    <Can I={ACTIONS.DECLINE} a={contractSubject(SUBJECTS.CONTRACT, details)}>
      <Button
        type={BUTTON_TYPES.DEFAULT}
        onClick={declineContractPopup.actions.open}
        danger
        upperCase
      >
        {BTN_TXT.DECLINE}
      </Button>
    </Can>
  )
}
