import { useState, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import Typography from '../../../../components/Typography/Typography'
import { Button, BUTTON_TYPES } from '../../../../components/Button'
import { IconFullName } from '../../../HealthSystemDetails/IconFullName'
import { setLoading } from '../../../../redux/store/common/slice'
import { getCommonsDetails } from './api'
import { formatPhoneNumber } from '../../../../helper/common'
import { onStartChat } from '../../utils'
import { initialCommonsData } from './constants'
import { ROLES_NAMES } from '../../../../constants'
import { CommonsDetailsData } from './types'

export const CommonsDetails = () => {
  const [isCollapsed, setIsCollapsed] = useState(true)
  const [data, setData] = useState<CommonsDetailsData>(initialCommonsData)
  const dispatch = useDispatch()

  useEffect(() => {
    getDetails()
  }, [])

  const getDetails = async () => {
    try {
      dispatch(setLoading(true))
      const { data } = await getCommonsDetails()
      setData(data)
    } finally {
      dispatch(setLoading(false))
    }
  }

  const onChangeCollapse = () => setIsCollapsed((prev) => !prev)

  return (
    <>
      <Typography.Headline6>General</Typography.Headline6>
      {!isCollapsed && (
        <IconFullName
          className="mt-16"
          firstName={data?.vice_president?.first_name}
          lastName={data?.vice_president?.last_name}
          roleName={ROLES_NAMES[data?.vice_president?.role]}
          phone={formatPhoneNumber(data?.vice_president?.phone_number || '')}
          email={data?.vice_president?.email}
          src={data?.vice_president?.avatar_logo?.file_preview}
          uuid={data?.vice_president?.uuid}
          onStartChat={onStartChat}
        />
      )}
      <Button
        className="mt-16"
        type={BUTTON_TYPES.LINK}
        onClick={onChangeCollapse}
        inline
      >
        {`${isCollapsed ? 'Show more' : 'Hide'} information`}
      </Button>
    </>
  )
}
