import { Drawer } from 'antd'
import cn from 'classnames'
import { ReactComponent as Close24 } from 'assets/svg/Close24.svg'
import { DrawerProps } from './types'
import './drawer.default.scss'

const Default = ({
  children,
  className,
  footer,
  closable = true,
  ...props
}: DrawerProps): JSX.Element => {
  return (
    <Drawer
      closeIcon={<Close24 />}
      closable={closable}
      getContainer="#root"
      className={cn('drawer-default', className, {
        'drawer-default--closable': closable,
        'drawer-default--with-footer': !!footer
      })}
      footer={footer}
      destroyOnClose
      {...props}
    >
      {children}
    </Drawer>
  )
}

export default Default
