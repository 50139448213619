import cn from 'classnames'
import { Typography } from 'components/Typography'
import { TCellWithSubtitleProps } from './types'
import './styles.scss'

export const CellWithSubtitle = ({
  title,
  subtitle,
  className,
  disabled = false
}: TCellWithSubtitleProps) => (
  <div className={cn('cell-with-subtitle', className, { disabled: disabled })}>
    <div className="cell-with-subtitle__title">
      <Typography.Body1>{title}</Typography.Body1>
    </div>
    {subtitle && (
      <div className="cell-with-subtitle__subtitle">
        <Typography.Caption>{subtitle}</Typography.Caption>
      </div>
    )}
  </div>
)
