import Fetcher from 'services/Fetcher'
import { API_DICTIONARY, HTTP_METHODS } from 'constants/api'
import { TRFPHealthSystemUsersRequest } from './types'

const fetcher = new Fetcher({})

export const getAssignedUsers: TRFPHealthSystemUsersRequest = async (
  id,
  data
) =>
  fetcher.request({
    url: `${API_DICTIONARY.RFP}${id}/${API_DICTIONARY.RFP_HS_USERS}`,
    method: HTTP_METHODS.GET,
    ...data
  })
