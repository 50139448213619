import { TCommunityInfoForm } from 'components/Forms/forms.chapterInfo.d'
import Fetcher from 'services/Fetcher'
import { API_DICTIONARY, HTTP_METHODS } from 'constants/api'
import { notification } from 'components/Notification'
import { NOTIFICATIONS } from '../../constants'

const fetcher = new Fetcher({})

export const updateCommunityInfo = async (
  data: TCommunityInfoForm,
  uuid: string
) =>
  fetcher.request<unknown, TCommunityInfoForm>({
    url: `${API_DICTIONARY.COMMUNITIES}${uuid}/`,
    method: HTTP_METHODS.PATCH,
    data: data,
    notification: notification,
    successMessage: NOTIFICATIONS.COMMUNITY_SAVED
  })

export const getCommunityInfo = async (uuid: string) =>
  fetcher.request<unknown, TCommunityInfoForm>({
    url: `${API_DICTIONARY.COMMUNITIES}${uuid}/`,
    method: HTTP_METHODS.GET
  })
