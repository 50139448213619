import Typography from '../../../components/Typography/Typography'
import './styles.scss'
import { ReactComponent as Plus16 } from 'assets/svg/Plus16.svg'
import { ReactComponent as ArrowUp24 } from 'assets/svg/ArrowUp24.svg'
import { ReactComponent as ArrowDown24 } from 'assets/svg/ArrowDown24.svg'
import { TYPOGRAPHY_WEIGHT } from '../../../components/Typography'
import { Button, BUTTON_TYPES } from '../../../components/Button'
import { BTN_TXT } from '../../../constants'
import RadioGroup from '../../../components/RadioGroup/RadioGroup'
import { CALENDAR_MODE_SWITCH_OPTIONS } from './constants'
import { useCalendarPopup } from '../../../pages/Calendar/Provider/CalendarPopupProvider'
import { useCalendarEventContext } from '../../../pages/Calendar/CalendarEventsProvider'

export const CalendarHeader = () => {
  const { addEventPopup } = useCalendarPopup()
  const openAddEventPopup = () => addEventPopup.actions.open()
  const { state, actions } = useCalendarEventContext()

  return (
    <div className="row space-between mb-24">
      <div className="row align-center">
        <Typography.Headline3
          weight={TYPOGRAPHY_WEIGHT.NORMAL}
          className="mr-72"
        >
          Calendar
        </Typography.Headline3>
        <Button
          type={BUTTON_TYPES.DEFAULT}
          onClick={actions.goToToday}
          disabled={state.isTodayDisabled}
          upperCase
        >
          {BTN_TXT.TODAY}
        </Button>
        <Button.Icon
          key="prevMonthButton"
          type={BUTTON_TYPES.LINK}
          icon={<ArrowDown24 />}
          className="ml-16 icon-rotate-90-deg"
          onClick={actions.goToPrevMonth}
        />
        <Button.Icon
          key="nextMonthButton"
          type={BUTTON_TYPES.LINK}
          icon={<ArrowUp24 />}
          className="icon-rotate-90-deg"
          onClick={actions.goToNextMonth}
        />
        <Typography.Headline5
          weight={TYPOGRAPHY_WEIGHT.NORMAL}
          className="ml-24"
        >
          {state.currentMonthLabel}
        </Typography.Headline5>
      </div>
      <div className="row align-center">
        <RadioGroup.Buttons
          options={CALENDAR_MODE_SWITCH_OPTIONS}
          className="mt-0 mr-24"
          defaultValue={state.calendarView}
          onChange={(value) => {
            actions.changeCalendarView(value)
          }}
        />
        <Button
          type={BUTTON_TYPES.PRIMARY}
          icon={<Plus16 />}
          onClick={openAddEventPopup}
          upperCase
        >
          {BTN_TXT.ADD_EVENT}
        </Button>
      </div>
    </div>
  )
}
