import Fetcher from '../../../services/Fetcher'
import { API_DICTIONARY, HTTP_METHODS } from '../../../constants'
import { InvoiceHistoryDataResponse } from './types'

const fetcher = new Fetcher({})

export const getInvoiceHistoryData = async (id: string) =>
  fetcher.request<unknown, InvoiceHistoryDataResponse>({
    url: `${API_DICTIONARY.FINANCES}${API_DICTIONARY.INVOICES}${id}/${API_DICTIONARY.HISTORY}`,
    method: HTTP_METHODS.GET
  })
