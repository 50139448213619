import {
  createContext,
  FC,
  PropsWithChildren,
  useContext,
  useMemo
} from 'react'

import usePopup, { PopupReturnType } from 'hooks/usePopup'

type ContextProps = {
  sendPrsPopup: PopupReturnType
  cancelPRSPopup: PopupReturnType
  changeRFPResponsePopup: PopupReturnType
  createSurveyPopup: PopupReturnType
  resendPopup: PopupReturnType
  declinePopup: PopupReturnType
  addFacilityUsersPopup: PopupReturnType
  deleteFacilityUsersPopup: PopupReturnType
  assignClinicalSponsorPopup: PopupReturnType
  sendToClinicalSponsorPopup: PopupReturnType
  approveRequestCsPopup: PopupReturnType
  viewSurveyFUPopup: PopupReturnType
  requestTrialPopup: PopupReturnType
  openRationalePopup: PopupReturnType
  viewRationaleResponsePopup: PopupReturnType
  createContractPopup: PopupReturnType
  approveFromRcaPopup: PopupReturnType
  declineFromRcaPopup: PopupReturnType
  respondPrsPopup: PopupReturnType
  runNewStepPopup: PopupReturnType
  confirmTrialPopup: PopupReturnType
  historyPopup: PopupReturnType
  sendToVendorPopup: PopupReturnType
  startRevenueAssessmentPopup: PopupReturnType
  createContractForVendorPopup: PopupReturnType
  prsStepsTimePopup: PopupReturnType
}

const PRSDetailsPopupContext = createContext<ContextProps>({
  sendPrsPopup: null!,
  cancelPRSPopup: null!,
  changeRFPResponsePopup: null!,
  createSurveyPopup: null!,
  resendPopup: null!,
  declinePopup: null!,
  assignClinicalSponsorPopup: null!,
  sendToClinicalSponsorPopup: null!,
  approveRequestCsPopup: null!,
  deleteFacilityUsersPopup: null!,
  addFacilityUsersPopup: null!,
  requestTrialPopup: null!,
  viewSurveyFUPopup: null!,
  openRationalePopup: null!,
  viewRationaleResponsePopup: null!,
  createContractPopup: null!,
  respondPrsPopup: null!,
  approveFromRcaPopup: null!,
  declineFromRcaPopup: null!,
  runNewStepPopup: null!,
  confirmTrialPopup: null!,
  historyPopup: null!,
  sendToVendorPopup: null!,
  startRevenueAssessmentPopup: null!,
  createContractForVendorPopup: null!,
  prsStepsTimePopup: null!
})

const basePopup = (title: string) => ({
  title
})

const PRSDetailsPopupProvider: FC<PropsWithChildren> = ({ children }) => {
  const changeRFPResponsePopupProps = usePopup(
    basePopup('Change RFP response?')
  )
  const sendPrsPopupProps = usePopup(basePopup('Send'))
  const cancelRFPPopupProps = usePopup(basePopup('Cancel'))
  const createSurveyPopupProps = usePopup(basePopup('Create survey'))
  const resendPopupProps = usePopup(basePopup('Resend'))
  const addFacilityUsersProps = usePopup(basePopup('Add Facility Users'))
  const deleteFacilityUsersProps = usePopup(basePopup('Delete Facility Users'))
  const assignClinicalSponsorProps = usePopup(basePopup('Assign'))
  const sendToClinicalSponsorProps = usePopup(
    basePopup('Send to clinical sponsor')
  )
  const declinePopupProps = usePopup(basePopup('Decline'))
  const approveRequestCsProps = usePopup(basePopup('Approve request'))
  const viewSurveyFUProps = usePopup(basePopup('View survey'))
  const requestTrialProps = usePopup(basePopup('Request trial'))
  const openRationaleProps = usePopup(basePopup('Open survey'))
  const viewRationaleResponseProps = usePopup(
    basePopup('View rationale response')
  )
  const respondPrsProps = usePopup(basePopup('Respond prs'))
  const approveFromRcaProps = usePopup(basePopup('Approve from rca'))
  const declineFromRcaProps = usePopup(basePopup('Decline from rca'))
  const runNewStepProps = usePopup(basePopup('Run new step'))
  const confirmTrialProps = usePopup(basePopup('Confirm trial'))
  const sendToVendorProps = usePopup(basePopup('Send to vendor'))
  const historyProps = usePopup(basePopup('History'))
  const startRevenueAssessmentProps = usePopup(
    basePopup('Start revenue assessment')
  )

  const createContractProps = usePopup(basePopup('Create contract'))
  const createContractForVendorProps = usePopup(basePopup('Create contract'))
  const prsStepsTimeProps = usePopup(basePopup('PRS deadline'))

  const context = useMemo(
    () => ({
      sendPrsPopup: sendPrsPopupProps,
      cancelPRSPopup: cancelRFPPopupProps,
      changeRFPResponsePopup: changeRFPResponsePopupProps,
      createSurveyPopup: createSurveyPopupProps,
      resendPopup: resendPopupProps,
      addFacilityUsersPopup: addFacilityUsersProps,
      deleteFacilityUsersPopup: deleteFacilityUsersProps,
      assignClinicalSponsorPopup: assignClinicalSponsorProps,
      declinePopup: declinePopupProps,
      sendToClinicalSponsorPopup: sendToClinicalSponsorProps,
      approveRequestCsPopup: approveRequestCsProps,
      viewSurveyFUPopup: viewSurveyFUProps,
      requestTrialPopup: requestTrialProps,
      openRationalePopup: openRationaleProps,
      viewRationaleResponsePopup: viewRationaleResponseProps,
      createContractPopup: createContractProps,
      respondPrsPopup: respondPrsProps,
      approveFromRcaPopup: approveFromRcaProps,
      declineFromRcaPopup: declineFromRcaProps,
      runNewStepPopup: runNewStepProps,
      confirmTrialPopup: confirmTrialProps,
      historyPopup: historyProps,
      sendToVendorPopup: sendToVendorProps,
      startRevenueAssessmentPopup: startRevenueAssessmentProps,
      createContractForVendorPopup: createContractForVendorProps,
      prsStepsTimePopup: prsStepsTimeProps
    }),
    [
      sendPrsPopupProps,
      cancelRFPPopupProps,
      changeRFPResponsePopupProps,
      createSurveyPopupProps,
      resendPopupProps,
      addFacilityUsersProps,
      deleteFacilityUsersProps,
      assignClinicalSponsorProps,
      sendToClinicalSponsorProps,
      declinePopupProps,
      approveRequestCsProps,
      viewSurveyFUProps,
      openRationaleProps,
      viewRationaleResponseProps,
      requestTrialProps,
      respondPrsProps,
      approveFromRcaProps,
      declineFromRcaProps,
      runNewStepProps,
      createContractProps,
      confirmTrialProps,
      historyProps,
      sendToVendorProps,
      prsStepsTimeProps,
      startRevenueAssessmentProps,
      createContractForVendorProps
    ]
  )

  return (
    <PRSDetailsPopupContext.Provider value={context}>
      {children}
    </PRSDetailsPopupContext.Provider>
  )
}

export const usePRSDetailsPopupContext = () =>
  useContext(PRSDetailsPopupContext)

export default PRSDetailsPopupProvider
