import { IChatHistory, TGroupedChatHistory } from './types'
import { defaultDateFormat } from 'helper/dates'

export const groupChatHistoryByDate = (
  data: IChatHistory[]
): TGroupedChatHistory => {
  return data.reduce<TGroupedChatHistory>((grouped, chatHistory) => {
    const label = defaultDateFormat(chatHistory.message.created_at)
    return {
      ...grouped,
      [label]: [chatHistory, ...(grouped[label] || [])]
    }
  }, {})
}

export const chatHistoryDivider = (
  data: IChatHistory[],
  countOfUnreadMessages: number
): [IChatHistory[], IChatHistory[]] => {
  const unreadMessages = data.slice(0, countOfUnreadMessages)
  const readedMessages = data.slice(countOfUnreadMessages)
  return [unreadMessages, readedMessages]
}
