import { FC } from 'react'
import Typography from '../../../../components/Typography/Typography'
import { FieldWithLabel } from '../../../../components/FieldWithLabel/FieldWithLabel'
import { Banner } from '../../../../components/Banner/Banner'
import { IconFullName } from '../../../HealthSystemDetails/IconFullName'
import { RejectedInvoiceLines } from './RejectedInvoiceLines/RejectedInvoiceLines'
import { currencyFormatter } from '../../../ContractDetails/ContractDetailsGeneral/ContractDetailsForm/utils'
import { ROLES_NAMES } from '../../../../constants'
import { RejectedInvoiceDetailsProps } from './types'
import './styles.scss'

export const RejectedInvoiceDetails: FC<RejectedInvoiceDetailsProps> = ({
  data
}) => {
  const errorsAmount = data?.lines.filter(
    (i) => !!Object.keys(i.errors).length
  ).length

  return (
    <div className="rejected-invoice-details column">
      <div className="rejected-invoice-details__info">
        <FieldWithLabel title="Uploaded by">
          <div className="column gap-4">
            <Typography.Body1>{data?.creator?.health_system}</Typography.Body1>
            <IconFullName
              firstName={data?.creator?.first_name}
              lastName={data?.creator?.last_name}
              roleName={ROLES_NAMES[data?.creator?.role]}
            />
          </div>
        </FieldWithLabel>
        <FieldWithLabel title="Supplier name">
          {data.supplier_name.join(', ')}
        </FieldWithLabel>
        <FieldWithLabel title="Amount">
          ${currencyFormatter(Number(data?.invoice_amount).toFixed(2))}
        </FieldWithLabel>
        <FieldWithLabel title="Supplier invoice number">
          {data.supplier_invoice_number.join(', ')}
        </FieldWithLabel>
      </div>
      {!!errorsAmount && (
        <Banner.ErrorWithTitle
          title={`${errorsAmount} lines contain errors`}
          text="Please correct the value by clicking on the highlighted cells."
        />
      )}
      <RejectedInvoiceLines lines={data.lines} />
    </div>
  )
}
