import { Segmented } from '../../../../components/Segmented'
import { usePRSVotingContext } from '../Providers/PRSVotingContext'
import Select from '../../../../components/Select/Select'
import './style.scss'
import { ACTIONS, SUBJECTS } from '../../Abilities'
import { Can } from '../Providers/PrsDetailsAbilityProvider'
import { subject } from '@casl/ability'
import { usePRSDetailsContext } from '../Providers/PRSDetailsContextProvider'
import { IRFPStakeholder } from '../../../RFP/RFPSurvey/types'
import { TOption } from '../../../../components/Select/types'
import { useEffect, useState } from 'react'
import { setLoading } from '../../../../redux/store/common/slice'
import { useParams } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { getVoteUsers } from '../../../../pages/ProductRequests/api'
const VotingHeader = () => {
  const dispatch = useDispatch()

  const prsVotingContext = usePRSVotingContext()
  const prsDetailsContext = usePRSDetailsContext()
  const { data } = prsDetailsContext.state

  const [selectedUser, setSelectedUsers] = useState(null)

  const { id } = useParams()

  const [votedUsers, setVotedUsers] = useState([])

  const {
    headerOptions,
    activeHeaderOptionValue,
    isCanViewSurveyResponse,
    votingResponse
  } = prsVotingContext.state
  const { setActiveHeaderOptionValue, getSurveyResponseVoting } =
    prsVotingContext.actions

  useEffect(() => {
    if (id && isCanViewSurveyResponse) {
      dispatch(setLoading(true))
      getVoteUsers(id)
        .then((res) => {
          setVotedUsers(res?.data?.results)
        })
        .finally(() => dispatch(setLoading(false)))
    }
  }, [id, isCanViewSurveyResponse])

  const returnSelectOptions = (arr: IRFPStakeholder[]): TOption[] => {
    if (arr?.length) {
      return [
        ...[{ label: `All responses (${arr.length})`, value: 'all' }],
        ...arr.map((item) => ({
          label: `${item.first_name} ${item.last_name}`,
          value: item.uuid
        }))
      ]
    }
    return []
  }

  const onChange = (e) => {
    setSelectedUsers(e)
    if (e === 'all') {
      getSurveyResponseVoting()
    } else {
      getSurveyResponseVoting(e)
    }
  }
  return (
    <div className="prs-voting-header row space-between">
      <Can I={ACTIONS.MANAGE} a={subject(SUBJECTS.SURVEY, { ...data })}>
        <Segmented
          options={headerOptions || []}
          value={activeHeaderOptionValue}
          onChange={setActiveHeaderOptionValue}
        />
      </Can>
      <Can I={ACTIONS.VIEW} a={subject(SUBJECTS.VOTING_RESPONSE, { ...data })}>
        {activeHeaderOptionValue == 2 && !!votingResponse?.size && (
          <Select
            options={returnSelectOptions(votedUsers)}
            className="prs-voting-header__select"
            propsSelect={{
              defaultActiveFirstOption: true,
              loading: !votedUsers?.length,
              onChange: onChange,
              value: selectedUser || returnSelectOptions(votedUsers)[0],
              placeholder: `All responses (${votedUsers?.length})`
            }}
          />
        )}
      </Can>
    </div>
  )
}

export default VotingHeader
