import { Default } from './link.default'
import { External } from './link.external'
import { ILink } from './types'
import './styles.scss'

const Link = (props: ILink) => <Default {...props} />

Link.Default = Default
Link.External = External

export default Link
