import Forms from '../../../components/Forms/Forms'
import Typography from '../../../components/Typography/Typography'
import { TYPOGRAPHY_WEIGHT } from '../../../components/Typography'

const ContactUs = () => {
  return (
    <div className="landing-page__contact-us" id="contact-us">
      <Typography.Headline2
        className="landing-page__contact-us__title"
        weight={TYPOGRAPHY_WEIGHT.MEDIUM}
      >
        Contact us
      </Typography.Headline2>
      <Typography.Body1 className="landing-page__contact-us__subtitle">
        For more information about how CognusHealth can support your needs,
        please contact us directly.
      </Typography.Body1>
      <Forms.ContactUsLanding />
    </div>
  )
}

export default ContactUs
