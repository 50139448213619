import { FC, useMemo } from 'react'
import { useParams } from 'react-router-dom'
import cn from 'classnames'

import './styles.scss'

import { Avatar } from 'components/Avatar/Avatar'
import { CHAT_TYPE, ROLES_NAMES } from 'constants/common'
import { Typography, TYPOGRAPHY_WEIGHT } from 'components/Typography'
import { IChannelChat, IChat, IPrivateChat } from 'redux/store/chats/types'
import { getNameInitials, safeString, toCapitalizeChart } from 'helper/common'
import useRouter from 'hooks/useRouter'
import { routes } from 'router'
import { ILRenderItem } from '../../../components/InfiniteList/InfiniteList'
import { getLastMessageSentDate } from '../../../helper/dates'

const ListItem: FC<ILRenderItem<IChat>> = (props) => {
  const { item } = props
  const {
    uuid,
    type,
    name,
    members_count,
    unread_messages_count,
    last_message_sent_at
  } = item

  const params = useParams()
  const router = useRouter()
  const isActive = params.id === uuid

  const handleClickItem = () => {
    router.navigate(`${routes.chat}/${uuid}`, { replace: true })
  }

  const avatarProps = useMemo(() => {
    switch (type) {
      case CHAT_TYPE.PRIVATE:
        const privateChatItem = item as IPrivateChat

        return {
          name: privateChatItem?.name
            ? getNameInitials(privateChatItem.name)
            : 'PC',
          src: (item as IPrivateChat).pvp_user_image
        }
      case CHAT_TYPE.CHANNEL:
        return { name: 'Ch' }
      case CHAT_TYPE.GROUP:
        return { name: 'GC' }
    }
  }, [props, type])

  const captionText = useMemo(() => {
    switch (type) {
      case CHAT_TYPE.PRIVATE:
        return ROLES_NAMES[(item as IPrivateChat).pvp_user_role]
      case CHAT_TYPE.CHANNEL:
        return (item as IChannelChat).rfp.number
      case CHAT_TYPE.GROUP:
        return `${members_count} member${members_count !== 1 ? 's' : ''}`
    }
  }, [members_count, props, type, item])

  const Chip = useMemo(
    () => (
      <div className="chat-list-feature-item__chip">
        <Typography.Caption className="chat-list-feature-item__chip-text">
          {toCapitalizeChart(type)}
        </Typography.Caption>
      </div>
    ),
    [type]
  )

  return (
    <div
      {...props}
      className={cn(
        'chat-list-feature-item row space-between',
        `chat-list-feature-item--${type}`,
        {
          'chat-list-feature-item--active': isActive
        }
      )}
      onClick={handleClickItem}
    >
      <div className="row chat-list-feature-item__row space-between">
        <div className="column space-between chat-list-feature-item__row__wrapper">
          <div className="row">
            <div className="mr-12">
              <Avatar {...avatarProps} />
            </div>
            <div className="column space-between chat-list-feature-item__row__description">
              <div className="row">
                <div className="column">
                  <Typography.Headline6
                    className="chat-list-feature-item__name"
                    weight={TYPOGRAPHY_WEIGHT.SEMI_BOLD}
                  >
                    {safeString(name)}
                  </Typography.Headline6>
                  <Typography.Caption className="chat-list-feature-item__caption">
                    {safeString(captionText)}
                  </Typography.Caption>
                </div>
              </div>
              <div className="row">{Chip}</div>
            </div>
          </div>
        </div>
        <div className="column space-between align-end last-data-label">
          {!!last_message_sent_at && (
            <div className="row">
              <Typography.Label
                weight={TYPOGRAPHY_WEIGHT.SEMI_BOLD}
                className="chat-list-feature-item__last-send"
              >
                {getLastMessageSentDate(last_message_sent_at)}
              </Typography.Label>
            </div>
          )}
          {!!unread_messages_count && (
            <div className="row">
              <div className="chat-list-feature-item__unread-messages">
                {unread_messages_count > 99 ? '99+' : unread_messages_count}
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  )
}

export default ListItem
