import RadioGroup from 'components/RadioGroup/RadioGroup'
import { usePRSVotingContext } from '../../Providers/PRSVotingContext'
import { FormInstance } from 'antd/lib/form/hooks/useForm'
import { TQuestionsForm } from 'components/Forms/forms.surveyQuestions.d'
type TProps = {
  options: { label: string; value: string }[]
  isOther: boolean
  name: number
  option?: string | null
  question?: string
  vote?: boolean
  form: FormInstance<TQuestionsForm>
}
const SingleType = ({
  options,
  isOther,
  name,
  option,
  question,
  vote,
  form
}: TProps) => {
  const { state } = usePRSVotingContext()
  const { isOpen, isCanViewSurveyResponse } = state
  return (
    <div className="column">
      <RadioGroup.Default
        disabled={!vote && (!isOpen || isCanViewSurveyResponse)}
        options={options}
        isOther={isOther}
        number={name}
        optionValue={option || null}
        question={question}
        vote={vote}
        form={form}
      />
    </div>
  )
}

export default SingleType
