import { usePRSDetailsContext } from '../Providers/PRSDetailsContextProvider'
import General from '../General/General'
import Participants from '../Participants/Participants'
import { useShowPopup } from '../../../../hooks/useShowPopup'
import { Popup } from '../../../../components/Popup'

const FormsWrapper = () => {
  const { state } = usePRSDetailsContext()
  const { activeTab, isShowButtons, changedValues, isNew } = state

  const [showPopup, confirmNavigation, cancelNavigation] = useShowPopup(
    isNew ? isShowButtons : !!Object.values(changedValues).find((i) => !!i),
    true
  )

  return (
    <>
      {activeTab === '0' ? <General /> : <Participants />}
      <Popup.Discard
        visible={showPopup}
        onCancel={cancelNavigation}
        onSubmit={confirmNavigation}
      />
    </>
  )
}

export default FormsWrapper
