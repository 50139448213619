import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { moduleName } from './constants'
import { ICommonState, TLoaderProps } from './types'

const initialState: ICommonState = {
  isLoading: false,
  loaderProps: {
    isFileUpload: false,
    message: ''
  }
}

const slice = createSlice({
  name: moduleName,
  initialState,
  reducers: {
    setLoading(state: ICommonState, action: PayloadAction<boolean>) {
      state.isLoading = action.payload
    },
    setLoaderProps(state: ICommonState, action: PayloadAction<TLoaderProps>) {
      state.loaderProps = action.payload
    }
  }
})

export default slice.reducer
export const { setLoading, setLoaderProps } = slice.actions
