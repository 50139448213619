import { State } from '../../type'
import { moduleName } from './constants'
import { IChatHistoryState } from './types'

const getState = (state: State): IChatHistoryState => state[moduleName]

export const getError = (state: State) => {
  const stateSlice = getState(state)
  return stateSlice.error
}

export const getIsLoaded = (state: State): boolean => {
  const stateSlice = getState(state)
  return stateSlice.isLoaded
}

export const getIsLoading = (state: State): boolean => {
  const stateSlice = getState(state)
  return stateSlice.isLoading
}

export const getChatHistory = (state: State) => {
  const stateSlice = getState(state)
  return stateSlice.chatHistory
}

export const getMessageToReply = (state: State) => {
  const stateSlice = getState(state)
  return stateSlice.messageToReply
}

export const getTotal = (state: State): number => {
  const stateSlice = getState(state)
  return stateSlice.total
}

export const getChatHistoryFromSearch = (state: State) => {
  const stateSlice = getState(state)
  return stateSlice.searchMessages
}
