import { ColumnsType } from 'antd/es/table'
import { SortOrder } from 'antd/es/table/interface'
import { Dropdown } from 'components/Dropdown'
import { Sorter } from 'components/Sorter/Sorter'
import { Status } from 'components/Status'
import { DescriptionCell } from 'components/DescriptionCell/DescriptionCell'
import { AttachmentsList } from 'components/AttachmentsList/AttachmentsList'
import { DOCUMENT_TYPE } from 'components/FileUpload/constants'
import { fileNameFormatter, fullFormatPhoneNumber } from 'helper/common'
import { getVendorsRequestAsync } from '../../ContractDetails/api'
import { ReactComponent as Filter16 } from 'assets/svg/Filter16.svg'
import { ReactComponent as FilterApplied16 } from 'assets/svg/FilterApplied16.svg'
import {
  TRFPVendorUser,
  TRFPVendorUsersFilters,
  TRFPVendorUsersColumnsFunc,
  TRFPVendorUsersFiltersFunc
} from './types'
import {
  RFP_ACCEPTANCE_STATUS_TO_CLASSNAME,
  RFP_ACCEPTANCE_STATUSES_NAMES
} from '../constants'
import { ResendBidContainer } from './ResendBidContainer'

export const columns = ({
  applyFilters,
  filters,
  canResendBid,
  status,
  setVendorId
}: TRFPVendorUsersColumnsFunc): ColumnsType<TRFPVendorUser> => {
  const columns: ColumnsType<TRFPVendorUser> = [
    {
      dataIndex: 'vendor_contract_steward_name',
      key: 'vendor_contract_steward_name',
      title: ({ sortOrder, sortColumn }) => (
        <Sorter<TRFPVendorUser>
          dataIndex="vendor_contract_steward_name"
          sortColumn={sortColumn}
          sortOrder={sortOrder as SortOrder}
        >
          Vendor Contract Steward
        </Sorter>
      ),
      sorter: true,
      showSorterTooltip: false
    },
    {
      dataIndex: 'vendor_name',
      key: 'vendor_name',
      title: ({ sortOrder, sortColumn }) => (
        <Sorter<TRFPVendorUser>
          dataIndex="vendor_name"
          sortColumn={sortColumn}
          sortOrder={sortOrder as SortOrder}
        >
          Vendor
        </Sorter>
      ),
      filterDropdown: (props) => (
        <Dropdown.Filter<
          TRFPVendorUsersFilters,
          TRFPVendorUsersFiltersFunc,
          unknown
        >
          searchCallback={getVendorsRequestAsync}
          applyFilters={applyFilters}
          appliedFilters={filters}
          field="vendors"
          asyncSearch
          {...props}
        />
      ),
      filterIcon: !!filters?.vendors?.length ? (
        <FilterApplied16 />
      ) : (
        <Filter16 />
      ),
      filterSearch: true,
      sorter: true,
      showSorterTooltip: false
    },
    {
      dataIndex: 'email',
      key: 'email',
      title: ({ sortOrder, sortColumn }) => (
        <Sorter<TRFPVendorUser>
          dataIndex="email"
          sortColumn={sortColumn}
          sortOrder={sortOrder as SortOrder}
        >
          Email
        </Sorter>
      ),
      sorter: true,
      showSorterTooltip: false
    },
    {
      dataIndex: 'vendor_contract_steward_phone_number',
      key: 'vendor_contract_steward_phone_number',
      title: ({ sortOrder, sortColumn }) => (
        <Sorter<TRFPVendorUser>
          dataIndex="vendor_contract_steward_phone_number"
          sortColumn={sortColumn}
          sortOrder={sortOrder as SortOrder}
        >
          Phone number
        </Sorter>
      ),
      render: (_, { vendor_contract_steward_phone_number }) =>
        fullFormatPhoneNumber(vendor_contract_steward_phone_number),
      sorter: true,
      showSorterTooltip: false
    },
    {
      dataIndex: 'acceptance_status',
      key: 'acceptance_status',
      title: ({ sortOrder, sortColumn }) => (
        <Sorter<TRFPVendorUser>
          dataIndex="acceptance_status"
          sortColumn={sortColumn}
          sortOrder={sortOrder as SortOrder}
        >
          Acceptance
        </Sorter>
      ),
      render: (_, { acceptance_status }) => (
        <Status.Default
          className={RFP_ACCEPTANCE_STATUS_TO_CLASSNAME[acceptance_status]}
        >
          {RFP_ACCEPTANCE_STATUSES_NAMES[acceptance_status]}
        </Status.Default>
      ),
      sorter: true,
      showSorterTooltip: false
    },
    {
      dataIndex: 'documents',
      key: 'documents',
      title: ({ sortOrder, sortColumn }) => (
        <Sorter<TRFPVendorUser>
          dataIndex="documents"
          sortColumn={sortColumn}
          sortOrder={sortOrder as SortOrder}
        >
          Submitted documents
        </Sorter>
      ),
      render: (_, { documents }) => {
        const attachments = documents.filter(
          (i) => i.document_type === DOCUMENT_TYPE.ATTACHMENTS
        )
        return (
          <DescriptionCell
            text={documents.map((i) => fileNameFormatter(i.file)).join(', ')}
            content={
              documents.length && (
                <div className="description-cell__files">
                  <AttachmentsList
                    label="Submitted files"
                    files={documents.filter(
                      (i) => i.document_type !== DOCUMENT_TYPE.ATTACHMENTS
                    )}
                    hideSeparator
                    disableActions
                  />
                  {!!attachments.length && (
                    <AttachmentsList
                      label="Attachments"
                      files={attachments}
                      hideSeparator
                      disableActions
                    />
                  )}
                </div>
              )
            }
          />
        )
      },
      sorter: true,
      showSorterTooltip: false
    }
  ]
  if (canResendBid) {
    columns.push({
      dataIndex: 'action',
      key: 'action',
      title: 'Action',
      width: 130,
      render: (_, row) => (
        <ResendBidContainer
          setVendorId={setVendorId}
          status={status}
          uuid={row.uuid}
          data={row}
        />
      ),
      showSorterTooltip: false
    })
  }
  return columns
}
