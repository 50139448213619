import { useCognusRepositoryPopup } from '../../Providers/CognusRepositoryPopupProvider'
import Popup from '../../../../components/Popup/Popup'
import Typography from '../../../../components/Typography/Typography'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { useCognusRepositoryContext } from '../../Providers/CognusRepositoryActions'
import { LED_BY_LABEL } from '../../../../components/LeadByIndicator/constants'
import { useDispatch } from 'react-redux'
import { setLoading } from '../../../../redux/store/common/slice'
import {
  getClinicalReviewResults,
  getClinicalReviewStakeholdersResults
} from '../../../ContractDetails/api'
import { getChartDataForItem } from './helper'
import { TClinicalReview, TClinicalReviewResults } from '../types'
import { emptyStateClinicalResults } from './constants'
import ChartStakeholderInfo from '../ChartInfo/ChartStakeholderInfo'
import ChartItem from '../ChartInfo/ChartItem'

const ClinicalReviewModal = () => {
  const dispatch = useDispatch()

  const { clinicalReviewPopup } = useCognusRepositoryPopup()
  const { state, actions } = clinicalReviewPopup
  const { selectedCategoryName, selectedLedBy, selectedId } =
    useCognusRepositoryContext().state

  const [reviewers, setReviewers] = useState<TClinicalReviewResults>(
    emptyStateClinicalResults
  )
  const [chart, setChart] = useState<TClinicalReview>([])

  const getChartData = useCallback(async () => {
    if (!selectedId) {
      throw new Error('Contract ID not provided')
    }
    dispatch(setLoading(true))
    Promise.all([
      getClinicalReviewResults(selectedId),
      getClinicalReviewStakeholdersResults(selectedId)
    ])
      .then((reviewResults) => {
        if (!!reviewResults[0].data.length) setChart(reviewResults[0].data)
        if (!!reviewResults[1].data.results?.length) {
          const mappedData =
            reviewResults[1].data.results.reduce<TClinicalReviewResults>(
              (result, stakeholder) => {
                result[stakeholder.decision].push(stakeholder.stakeholder)
                return result
              },
              { not_reviewed: [], accepted: [], declined: [] }
            )
          setReviewers(mappedData)
        }
      })
      .finally(() => dispatch(setLoading(false)))
  }, [dispatch, selectedId])

  useEffect(() => {
    getChartData()
  }, [selectedId])

  const chartData = useMemo(() => {
    return getChartDataForItem(chart)
  }, [chart])

  return (
    <Popup
      visible={state.visible}
      onCancel={actions.close}
      title={state.title}
      width={962}
      footer={null}
      className="clinical-review-modal-container"
    >
      <div className="clinical-review-modal-container__assigned mt-4 gap-16 row">
        <div className="row clinical-review-modal-container__assigned__row">
          <Typography.Body2 className="clinical-review-modal-container__assigned__row__role">
            Contract category:&nbsp;
          </Typography.Body2>
          <Typography.Body2>{selectedCategoryName}</Typography.Body2>
        </div>
        <div className="row clinical-review-modal-container__assigned__row">
          <Typography.Body2 className="clinical-review-modal-container__assigned__row__role">
            Led by:&nbsp;
          </Typography.Body2>
          <Typography.Body2>{LED_BY_LABEL[selectedLedBy]}</Typography.Body2>
        </div>
      </div>
      <div className="clinical-review-modal-container__legend row mt-16">
        <div className="legend__description">
          <div className="legend__rectangle legend__rectangle_color_accept" />
          <Typography.Body2>Accept</Typography.Body2>
        </div>
        <div className="legend__description">
          <div className="legend__rectangle legend__rectangle_color_decline" />
          <Typography.Body2>Decline</Typography.Body2>
        </div>
        <div className="legend__description">
          <div className="legend__rectangle legend__rectangle_color_no-response" />
          <Typography.Body2>No response</Typography.Body2>
        </div>
      </div>
      <div className="clinical-review-modal-container__chart row mt-16">
        <ChartItem color={'#62DA84'} chartData={chartData.accepted} />
        <ChartItem color={'#FF6666'} chartData={chartData.declined} />
        <ChartItem color={'#E8EBED'} chartData={chartData.not_reviewed} />
      </div>
      <div className="clinical-review-modal-container__list mt-24">
        <Typography.Headline5>Details</Typography.Headline5>
        <div className="clinical-review-modal-container__columns row mt-16">
          <ChartStakeholderInfo
            title={'Accepted by'}
            users={reviewers.accepted}
          />
          <ChartStakeholderInfo
            title={'Declined by'}
            users={reviewers.declined}
          />
          <ChartStakeholderInfo
            title={'No response from'}
            users={reviewers.not_reviewed}
          />
        </div>
      </div>
    </Popup>
  )
}

export default ClinicalReviewModal
