import Typography from '../../../../components/Typography/Typography'
import { IconFullName } from '../../../HealthSystemDetails/IconFullName'
import { TChartStakeholderInfo } from './types'

const ChartStakeholderInfo = ({ title, users }: TChartStakeholderInfo) => {
  return (
    <div className="clinical-review-modal-container__columns__item column">
      <Typography.Caption
        className={'clinical-review-modal-container__columns__item__title'}
      >
        {title}
      </Typography.Caption>
      {users?.map((st) => (
        <IconFullName
          className="user-form_community"
          title=""
          key={st.uuid}
          firstName={st.first_name}
          lastName={st.last_name}
          roleName={''}
          src={st?.avatar?.file_preview}
          uuid={st.uuid}
        />
      ))}
    </div>
  )
}

export default ChartStakeholderInfo
