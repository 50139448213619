import { Route, Routes } from 'react-router-dom'

import { contractPipelineDetailsRoutes } from './routes'
import General from '../General/General'

const ContractPipelineDetailsRouter = () => {
  return (
    <Routes>
      {contractPipelineDetailsRoutes.map((route, index) => (
        <Route key={index} path={route.path} element={<route.component />} />
      ))}
      <Route index element={<General />} />
    </Routes>
  )
}

export default ContractPipelineDetailsRouter
