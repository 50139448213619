import { FC } from 'react'
import { subject } from '@casl/ability'

import { BTN_TXT } from 'constants/txt'
import { Button, BUTTON_TYPES } from 'components/Button'
import { Can, ACTIONS, SUBJECTS } from 'features/Permission'
import { useRFPDetailsContext } from 'features/RFP/RFPDetails/Providers/RFPDetailsContextProvider'

type Props = {
  onClick: VoidFunction
}

const OpenSurveyButton: FC<Props> = (props) => {
  const { onClick } = props

  const rfpDetailsContext = useRFPDetailsContext()

  const { data } = rfpDetailsContext.state

  return (
    <Can I={ACTIONS.VOTE} a={subject(SUBJECTS.SURVEY, { ...data })}>
      <Button type={BUTTON_TYPES.PRIMARY} onClick={onClick} upperCase>
        {BTN_TXT.OPEN_SURVEY}
      </Button>
    </Can>
  )
}

export default OpenSurveyButton
