import { Can } from '../Providers/PrsDetailsAbilityProvider'
import { ACTIONS, SUBJECTS } from '../../Abilities'
import { subject } from '@casl/ability'
import { Button, BUTTON_TYPES } from '../../../../components/Button'
import { BTN_TXT } from '../../../../constants'
import { usePRSDetailsPopupContext } from '../Providers/PRSDetailsPopupProvider'
import { usePRSDetailsContext } from '../Providers/PRSDetailsContextProvider'
import { useMemo } from 'react'
import GuestContract from '../ContractCreation/GuestContract'
import FullAccessContract from '../ContractCreation/FullAccessContract'

const CreateContract = () => {
  const prsDetailsContext = usePRSDetailsContext()

  const { data } = prsDetailsContext.state
  const { createContractPopup, createContractForVendorPopup } =
    usePRSDetailsPopupContext()

  const { actions } = createContractPopup
  const openCancelModal = () => {
    if (isGuestVendor) {
      createContractForVendorPopup.actions.open()
    } else {
      actions.open()
    }
  }

  const isGuestVendor = useMemo(() => {
    return data.vendor?.vendor_permission === 'guest'
  }, [data])

  return (
    <>
      <Can I={ACTIONS.CREATE} a={subject(SUBJECTS.CONTRACT, { ...data })}>
        <Button type={BUTTON_TYPES.PRIMARY} onClick={openCancelModal} upperCase>
          {BTN_TXT.CREATE_CONTRACT}
        </Button>
      </Can>
      <FullAccessContract />
      <GuestContract />
    </>
  )
}

export default CreateContract
