import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { Form } from 'antd'
import cn from 'classnames'
import { Input } from 'components/Input'
import { Button, BUTTON_TYPES } from 'components/Button'
import { getCommunityInfo, getError } from 'redux/store/chapterInfo/getters'
import { BTN_TXT, LABELS } from 'constants/txt'
import { validateMessages } from 'helper/common'
import { TCommunityInfoForm, TCommunityInfoProps } from './forms.chapterInfo.d'
import './forms.chapterInfo.scss'

export const CommunityInfo = ({
  className,
  onSubmit
}: TCommunityInfoProps): JSX.Element => {
  const [form] = Form.useForm()
  const initialState = useSelector(getCommunityInfo)
  const [isActive, setIsActive] = useState(false)
  const error = useSelector(getError)
  useEffect(() => {
    form.resetFields()
    setIsActive(false)
  }, [initialState])
  const onSubmitForm = (values) => {
    onSubmit(values)
    setIsActive(false)
  }
  const resetFields = () => {
    form.resetFields()
    setIsActive(false)
  }
  return (
    <Form<TCommunityInfoForm>
      form={form}
      className={cn('community-info-form', className)}
      layout="vertical"
      onFinish={onSubmitForm}
      initialValues={initialState}
      validateMessages={validateMessages}
      onFieldsChange={() => setIsActive(true)}
    >
      <Input
        propsItem={{
          label: LABELS.COMMUNITY_NAME,
          name: 'name',
          help: error,
          rules: [
            {
              max: 100,
              required: true
            }
          ]
        }}
        propsInput={{
          showCount: true,
          maxLength: 100
        }}
      />
      <Input.TextArea
        propsItem={{
          label: LABELS.DESCRIPTION,
          name: 'description',
          rules: [
            {
              max: 250
            }
          ]
        }}
        propsTextArea={{
          showCount: true,
          maxLength: 250
        }}
      />
      <div
        className={cn(
          'community-info-form_buttons',
          isActive || 'community-info-form_buttons_hide'
        )}
      >
        <Button onClick={resetFields} type={BUTTON_TYPES.DEFAULT} upperCase>
          {BTN_TXT.CANCEL}
        </Button>
        <Button type={BUTTON_TYPES.PRIMARY} htmlType="submit" upperCase>
          {BTN_TXT.SAVE}
        </Button>
      </div>
    </Form>
  )
}
